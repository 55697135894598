import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Text,
  Field,
  Button,
  Checkbox,
  Loader,
  Seemore,
  Panel,
  Radio,
  RadioGroup,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../../../Components/Select";
import { withToastManager } from "react-toast-notifications";

@observer
class UsersCopyConfPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCopying: false,
      radioValue: 1,
      groups: this.getGroupsAll(),
      accounts: this.getAccounts(),
      originUserId: null,
      selected: [],
      preferences: [],
    };
  }

  handleOriginChange = (value) => {
    const originId = value && value.value;
    if (originId) {
      const sUsers = this.state.selected.filter((v) => v.value !== originId);
      this.setState({ originUserId: originId, selected: sUsers });
    } else {
      this.setState({ originUserId: null });
    }
  };

  handleSelectedChange = (value) => {
    if (!value) {
      this.setState({ selected: [] });
    } else {
      this.setState({ selected: value });
    }
  };

  handlePreferecesChange = (sender, value, name) => {
    let preferences = this.state.preferences;
    if (value) {
      preferences.push(name);
    } else {
      preferences = preferences.filter((i) => name !== i);
    }

    this.setState({ preferences: preferences });
  };

  handleChangeRadio = (sender, value) => {
    this.setState({ messageError: null, radioValue: value, selected: [] });
  };

  getGroupsAll = () => {
    return this.props.store.groups.fetchAll();
  };

  getAccounts = () => {
    return this.props.store.users.fetchAccounts();
  };

  getOptions = (accounts) => {
    return accounts.toArray().map((a) => ({ value: a.id, label: a.username }));
  };

  getOptionsGroups(obj) {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  }

  clearForm() {
    this.options = this.getOptions(this.state.accounts);
    this.setState({
      accounts: this.getAccounts(),
      originUserId: null,
      selected: [],
      preferences: [],
      radioValue: 1,
      isCopying: false,
    });
  }

  handleCopyConfigs = () => {
    const { toastManager } = this.props;
    const { preferences, originUserId, selected, radioValue } = this.state;
    const params = {
      idOrigin: originUserId,
      pk: selected.map((s) => s.value),
      radioValue: radioValue,
      copyName: preferences,
    };
    this.setState({ isCopying: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/users_preferences/copy", params)
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label221, {
              appearance: "success",
              autoDismiss: true,
            });
            this.clearForm();
          },
          (error) => {
            toastManager.add(this.props.store.language.label222, {
              appearance: "error",
              autoDismiss: true,
            });
            this.setState({ isCopying: false });
          }
        )
        .catch((error) => {
          toastManager.add(this.props.store.language.label222, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderClone = () => {
    const {
      accounts,
      groups,
      originUserId,
      preferences,
      selected,
      radioValue,
    } = this.state;
    const options = this.getOptions(accounts);
    const multiOptions = options.filter((o) => o.value !== originUserId);
    const multiOptionsGroups = this.getOptionsGroups(groups);
    return (
      <React.Fragment>
        <Columns isCentered isGapless className="is-multiline is-mobile">
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
              lineBreak
              upper
            >
              {this.props.store.language.label19}
            </Text>
            <div
              style={{
                maxWidth: "718px",
              }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Seemore
                textMore={this.props.store.language.label43}
                textLess={this.props.store.language.label44}
              >
                {this.props.store.language.label97}
              </Seemore>
            </div>

            <Panel
              className="my-1"
              color="gray"
              padding={!this.props.store.viewMobile && "24px 22px"}
            >
              <Text
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="strong"
                lineBreak
                upper
              >
                {this.props.store.language.label17}
              </Text>
              <br />
              <Field
                marginH={this.props.store.viewMobile && "10px"}
                label={this.props.store.language.label223}
                labelNote={this.props.store.language.label76}
                labelNoteOption={this.props.store.language.label99}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                upper
              >
                <Select
                  onChange={this.handleOriginChange}
                  isClearable
                  placeholder={this.props.store.language.label98}
                  options={options}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </Field>
              <br />
              <RadioGroup
                size={this.props.store.viewMobile ? "md" : "sm"}
                onChange={this.handleChangeRadio}
                value={radioValue}
              >
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="1"
                  class="radio"
                  invert
                >
                  <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                    {this.props.store.language.label100}
                  </Text>
                </Radio>
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="2"
                  class="radio"
                  invert
                >
                  <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                    {this.props.store.language.label101}
                  </Text>
                </Radio>
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="3"
                  class="radio"
                  invert
                >
                  <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                    {this.props.store.language.label102}
                  </Text>
                </Radio>
              </RadioGroup>
              <br />
              {radioValue == 3 ? null : (
                <Field
                  marginH={this.props.store.viewMobile && "10px"}
                  label={
                    radioValue == 1
                      ? this.props.store.language.label103
                      : this.props.store.language.label104
                  }
                  labelNote={this.props.store.language.label76}
                  labelNoteOption={
                    radioValue == 1
                      ? this.props.store.language.label105
                      : this.props.store.language.label106
                  }
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  upper
                >
                  <Select
                    onChange={this.handleSelectedChange}
                    isMulti
                    isClearable
                    placeholder={
                      radioValue == 1
                        ? this.props.store.language.label107
                        : this.props.store.language.label108
                    }
                    defaultValue={selected}
                    value={selected}
                    options={
                      radioValue == 1 ? multiOptions : multiOptionsGroups
                    }
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    isMobile={!this.props.store.viewMobile}
                  />
                </Field>
              )}
            </Panel>
            <Panel
              className="my-1"
              color="gray"
              lassName="my-1"
              padding={!this.props.store.viewMobile && "24px 22px"}
            >
              <Text
                upper
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="strong"
                lineBreak
              >
                {this.props.store.language.label109}
              </Text>
              <br />
              <Checkbox
                invert
                key={"preference"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handlePreferecesChange}
                isChecked={preferences.includes("preference")}
                value="preference"
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                  {this.props.store.language.label23}
                </Text>
              </Checkbox>
              <Checkbox
                invert
                key={"personalization"}
                size="lg"
                onChange={this.handlePreferecesChange}
                isChecked={preferences.includes("personalization")}
                value="personalization"
              >
                <Text upper size="lg">
                  {this.props.store.language.label38}
                </Text>
              </Checkbox>
              <Checkbox
                invert
                key={"playlist_plans"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handlePreferecesChange}
                isChecked={preferences.includes("playlist_plans")}
                value="playlist_plans"
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                  {this.props.store.language.label37}
                </Text>
              </Checkbox>
              {this.props.store.getUserRadio() ? null : (
                <Checkbox
                  invert
                  key={"advertisemsents_plans"}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isChecked={preferences.includes("advertisemsents_plans")}
                  onChange={this.handlePreferecesChange}
                  value="advertisemsents_plans"
                >
                  <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                    {this.props.store.language.label39}
                  </Text>
                </Checkbox>
              )}
              {/*<Checkbox
                                disabled={true}
                                invert
                                key={'content_manager'}
                                size="lg"
                                isChecked={preferences.includes("content_manager")}
                                onChange={this.handlePreferecesChange}
                                value="content_manager">
                                <Text size="lg">{this.props.store.language.label110.toUpperCase()} <span style={{color:'#999999'}}>{this.props.store.language.label111}</span></Text> 
                            </Checkbox>*/}
            </Panel>
            {this.state.isCopying ? (
              <Button
                upper
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="is-pulled-right px-3"
                disabled
                icon="spinner"
                pulse
              >
                {this.props.store.language.label113}
              </Button>
            ) : (
              <Button
                upper
                size={this.props.store.viewMobile ? "lg" : "md"}
                disabled={
                  (preferences && preferences.length === 0) ||
                  !originUserId ||
                  (radioValue == 3 ? false : selected && selected.length === 0)
                }
                className="is-pulled-right px-3 my-2"
                onClick={this.handleCopyConfigs}
              >
                <strong>{this.props.store.language.label112}</strong>
              </Button>
            )}
          </Column>
        </Columns>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { accounts } = this.state;
    if (!accounts) return null;
    return !accounts.isOk() ? this.renderLoader() : this.renderClone();
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withStore(withToastManager(UsersCopyConfPage));
