import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import {
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Paragraph,
} from "ks_storybook";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";
import { PPCalendarClocks } from "../../models";
import moment from "moment";

@observer
class PPAssignNormalClockModal extends Component {
  @observable ppCalendarClocksNew;

  constructor(props) {
    super(props);

    this.state = {
      messageError: null,
      isSaving: false,
      blocks: this.getBlocks(),
    };

    this.grid = 4;
    this.ppCalendarClocksNew = this.newPPCalendarClocks();
  }

  newPPCalendarClocks = () => {
    const ppCalendarClocksStore = this.props.store.ppCalendarClocks;
    const attrs = {
      blocks: [],
      name: "NORMAL",
      start_date: moment(new Date()).format("DD/MM/YYYY"),
      end_date: "31/12/2099",
      exact_day: null,
      repetitions: 0,
      day_type: 1,
      origin_type: 1,
      deleted: 0,
    };
    return new PPCalendarClocks(attrs, ppCalendarClocksStore);
  };

  valid = (num) => {
    switch (num) {
      case 0:
        return "00";
      case 1:
        return "01";
      case 2:
        return "02";
      case 3:
        return "03";
      case 4:
        return "04";
      case 5:
        return "05";
      case 6:
        return "06";
      case 7:
        return "07";
      case 8:
        return "08";
      case 9:
        return "09";
      default:
        return num;
    }
  };

  getBlocks = () => {
    let items = [];
    for (let i = 0; i <= 23; i++) {
      items.push({
        start_hours: this.valid(i) + ":00",
        end_hours: this.valid(i) + ":59",
        pp_clocks_id: 0,
      });
    }
    return items;
  };

  getOptions(obj) {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.ppCalendarClocksNew.blocks = JSON.stringify(this.state.blocks);
    this.setState({ isSaving: true }, () => {
      this.ppCalendarClocksNew.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label456, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add(this.props.store.language.label457, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.onSave && this.props.onSave();
        }
      });
    });
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "500px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  getListStyle = () => ({
    background: "#e8e8e8",
    padding: this.grid,
  });

  getItemStyle = () => ({
    userSelect: "none",
    position: "static",
    padding: "12px 24px",
    margin: "0 0 8px 0",
    alignItems: "flex-start",
    alignContent: "flex-start",
    borderRadius: this.grid * 2,
    background: "#e8e8e8",
  });

  handleChangeFiled = (sender, value, name, validation) => {
    this.setState({ messageError: null, name: value });
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label128}
            </Button>
          ) : (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              {this.props.store.language.label127}
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderHeader = () => {
    const { blocks } = this.state;
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {blocks.map((item, index) => (
            <div style={this.getItemStyle()}>
              {
                <Columns isMultiline>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                        {item.start_hours + " - " + item.end_hours}
                      </Text>
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                    <Select
                      onChange={(value, sender) => {
                        const val = !value ? 0 : value.value;
                        const newBlocks = blocks;
                        newBlocks[index].pp_clocks_id = parseInt(val);
                        this.setState({ blocks: newBlocks });
                      }}
                      isClearable
                      options={this.getOptions(this.props.ppClocks)}
                      className="is-fullwidth"
                      backgroundColor="#FFFFFF"
                      separatorColor="#FFFFFF"
                      iconColor="black"
                      textColor="black"
                      placeholderColor="black"
                      placeholder={this.props.store.language.label455}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      isMobile={!this.props.store.viewMobile}
                    />
                  </Column>
                </Columns>
              }
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "50%" : "90%"}
        height={this.props.store.viewMobile ? "90%" : "auto"}
        invert
      >
        <ModalHeader>
          <Text
            upper
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
          >
            {this.props.store.language.label202}
          </Text>
        </ModalHeader>
        <ModalContent>
          <div style={{ maxWidth: "540px" }} className="my-1">
            <Text size={this.props.store.viewMobile ? "xl" : "lg"} lineBreak>
              {this.props.store.language.label454}
            </Text>
          </div>
          {this.renderHeader()}

          {this.state.messageError && (
            <>
              <br />
              <Panel color="error" className="mt-2" invert>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  multiline
                >
                  {this.state.messageError}
                </Text>
              </Panel>
            </>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}
PPAssignNormalClockModal.propTypes = {
  ppClocks: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PPAssignNormalClockModal.defaultProps = {
  ppClocks: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PPAssignNormalClockModal));
