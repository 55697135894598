import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Field,
  TextInput,
  Button,
  Panel,
  Columns,
  Column,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import UserPersonalizationDeletedModal from "./UserPersonalizationDeletedModal";
import { withToastManager } from "react-toast-notifications";
import { UserPersonalization } from "../../../../models";
import moment from "moment";
import { Screen } from "../../../../Components/Screen";

@observer
class UsersPersonalizationModal extends Component {
  @observable userPersonalization;

  constructor(props) {
    super(props);

    this.state = {
      currentUserPersonalization: this.getUserPerson(this.props.account.id),
      isNull: true,
      isSaving: false,
      messageError: null,
      userPersonalizationEdit: null,
      showDeletedModal: false,
      file: null,
      name: "",
      size: 0,
    };
    this.personalization = null;
    this.userPersonalization = this.newUserPersonalization();
  }

  newUserPersonalization() {
    const userPersonalizationStore = this.props.store.userPersonalization;
    const attrs = {
      user_id: this.props.account.id,
      company_name: "",
      text_one: "",
      text_two: "",
      text_three: "",
      avatar_path: "",
      origin_type: 2,
      deleted: 0,
    };
    return new UserPersonalization(attrs, userPersonalizationStore);
  }

  getUserPerson = (value) => {
    if (value)
      return this.props.store.userPersonalization.getPersonalizationUser(value);
    return null;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDeletedOpen = () => {
    this.setState({ showDeletedModal: true });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.userPersonalization = this.newUserPersonalization();
    this.props.store.loadUserPersonalization();
    this.setState({
      isNull: true,
      isSaving: false,
      messageError: null,
      userPersonalizationEdit: this.getUserPerson(this.props.account.id),
      showDeletedModal: false,
      file: null,
      name: "",
      size: 0,
    });
    this.personalization = null;
  };

  getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  handleFileChange = (e) => {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      //const user = this.props.store.loggedInUser;
      const fileName = file.name;
      const ext = this.getExtension(fileName);
      if (
        ext != "jpeg" &&
        ext != "JPEG" &&
        ext != "jpg" &&
        ext != "JPG" &&
        ext != "png" &&
        ext != "PNG"
      ) {
        this.setState({ messageError: this.props.store.language.label234 });
        return;
      }
      const lastDot = fileName.lastIndexOf(".");
      const name = fileName.substring(0, lastDot);
      let momenStr = moment(new Date()).format("YYYYMMDDHHmmss");
      this.userPersonalization.avatar_path = new File(
        [file],
        `${this.props.account.id}-IMG-${momenStr}.${ext}`,
        { type: file.type }
      );
      const size = (file.size / 1048576).toFixed(4);
      this.setState({
        size: size,
        isNull: false,
        name: name,
        file: URL.createObjectURL(this.userPersonalization.avatar_path),
      });
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.userPersonalization.saveFormData().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label234, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add(this.props.store.language.label233, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.store.loadUserPersonalization();
          this.setState({
            isNull: true,
            isSaving: false,
            messageError: null,
            userPersonalizationEdit: this.getUserPerson(this.props.account.id),
            showDeletedModal: false,
            file: null,
            name: "",
            size: 0,
          });
          this.userPersonalization = this.newUserPersonalization();
        }
      });
    });
  };

  handleSaveEdit = () => {
    const params = {
      user_id: this.props.account.id,
      company_name: this.personalization[0].company_name
        ? this.personalization[0].company_name
        : "",
      text_one: this.personalization[0].text_one
        ? this.personalization[0].text_one
        : "",
      text_two: this.personalization[0].text_two
        ? this.personalization[0].text_two
        : "",
      text_three: this.personalization[0].text_three
        ? this.personalization[0].text_three
        : "",
      avatar_path: this.userPersonalization.avatar_path,
      origin_type: 2,
      deleted: 0,
    };
    const userPersonalizationStore = this.props.store.userPersonalization;
    const temp = new UserPersonalization(params, userPersonalizationStore);
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      temp.saveFormData().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label234, {
            appearance: "error",
            autoDismiss: true,
          });
          this.setState({ isSaving: false });
        } else {
          toastManager.add(this.props.store.language.label233, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.store.loadUserPersonalization();
          this.setState({
            isNull: true,
            isSaving: false,
            messageError: null,
            userPersonalizationEdit: this.getUserPerson(this.props.account.id),
            showDeletedModal: false,
            file: null,
            name: "",
            size: 0,
          });
          this.userPersonalization = this.newUserPersonalization();
        }
      });
    });
  };

  handleSaveEditInfo = () => {
    const params = {
      user_id: this.props.account.id,
      company_name: this.personalization[0].company_name,
      text_one: this.personalization[0].text_one,
      text_two: this.personalization[0].text_two,
      text_three: this.personalization[0].text_three,
      origin_type: 2,
      deleted: 0,
    };
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .putParams("/users_personalization/put_users_personalization", params)
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label233, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.store.loadUserPersonalization();
            this.setState({
              isNull: true,
              isSaving: false,
              messageError: null,
              userPersonalizationEdit: this.getUserPerson(
                this.props.account.id
              ),
              showDeletedModal: false,
              file: null,
              name: "",
              size: 0,
            });
            this.userPersonalization = this.newUserPersonalization();
          },
          (error) => {
            toastManager.add(this.props.store.language.label234, {
              appearance: "error",
              autoDismiss: true,
            });
            this.setState({ isSaving: false });
          }
        );
    });
  };

  handleChange = (sender, value, name, validation) => {
    switch (name) {
      case "companyName":
        this.userPersonalization.company_name = value;
        this.setState({ isNull: false });
        break;
      case "web":
        this.userPersonalization.text_one = value;
        this.setState({ isNull: false });
        break;
      case "textTwo":
        this.userPersonalization.text_two = value;
        this.setState({ isNull: false });
        break;
      case "textThree":
        this.userPersonalization.text_three = value;
        this.setState({ isNull: false });
        break;
      default:
        break;
    }
  };

  handleChangeEdit = (sender, value, name, validation) => {
    switch (name) {
      case "companyName":
        this.personalization[0].company_name = value;
        this.setState({ isNull: false });
        break;
      case "web":
        this.personalization[0].text_one = value;
        this.setState({ isNull: false });
        break;
      case "textTwo":
        this.personalization[0].text_two = value;
        this.setState({ isNull: false });
        break;
      case "textThree":
        this.personalization[0].text_three = value;
        this.setState({ isNull: false });
        break;
      default:
        break;
    }
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  @computed
  get canisNull() {
    if (this.state.isNull) return false;
    return true;
  }

  renderButtons() {
    return (
      <>
        {this.state.isSaving ? (
          <Button
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "mt-2" : "is-fullwidth my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            {this.props.store.language.label128}
          </Button>
        ) : (
          <Button
            disabled={!this.canSave || !this.canisNull}
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "mt-2" : "is-fullwidth my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onClick={this.handleSave}
          >
            <strong>{this.props.store.language.label127}</strong>
          </Button>
        )}
      </>
    );
  }

  renderButtonsEdit() {
    return (
      <>
        {this.state.isSaving ? (
          <Button
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "mt-2" : "is-fullwidth my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            {this.props.store.language.label81}
          </Button>
        ) : (
          <Button
            disabled={!this.canSave || !this.canisNull}
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "mt-2" : "is-fullwidth my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onClick={
              this.userPersonalization.avatar_path == "" ||
              this.userPersonalization.avatar_path == null
                ? this.handleSaveEditInfo
                : this.handleSaveEdit
            }
          >
            {this.props.store.language.label80}
          </Button>
        )}
      </>
    );
  }

  renderLogoEmpty = () => {
    return (
      <>
        {this.props.store.viewMobile ? (
          <div
            style={{
              minWidth: "140px",
              height: "140px",
              background: "#4A4949",
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderRadius: "7px",
            }}
          >
            {/* <Text color="special" lineBreak size="md" lead weight="strong">
              {this.props.store.language.label131}
            </Text> */}
          </div>
        ) : (
          <div
            style={{
              minWidth: "80px",
              height: "80px",
              background: "#4A4949",
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderRadius: "7px",
            }}
          >
            {/* <Text
              upper
              color="white"
              lineBreak
              size={this.props.store.viewMobile ? "lg" : "md"}
              weight="strong"
            >
              {this.props.store.language.label131}
            </Text> */}
          </div>
        )}
      </>
    );
  };

  renderImg = (src) => {
    if (src === null) return this.renderLogoEmpty();
    return (
      <>
        {this.props.store.viewMobile ? (
          <img src={this.props.store.replaceBucketUrl(src)} style={{ width: "130px", height: "130px" }} />
        ) : (
          <img
            src={this.props.store.replaceBucketUrl(src)}
            style={{ width: "90px", height: "90px", borderRadius: 16 }}
          />
        )}
      </>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPanelForm = (children) => {
    return this.props.store.viewMobile ? (
      <Panel
        className="my-1"
        color="gray"
        padding={this.props.store.viewMobile ? "20px 28px" : "12px"}
      >
        {children}
      </Panel>
    ) : (
      <div className="my-1">{children}</div>
    );
  };

  renderPersonalization = () => {
    const { currentUserPersonalization } = this.state;
    if (currentUserPersonalization.length == 1)
      this.personalization = currentUserPersonalization.toArray();
    else this.personalization = null;
    return (
      <React.Fragment>
        <Columns isCentered isGapless className="is-multiline is-mobile">
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: this.props.store.viewMobile ? "100%" : "65%",
                  flex: !this.props.store.viewMobile && "1",
                }}
              >
                <Panel
                  className="my-1"
                  color="gray"
                  padding={this.props.store.viewMobile ? "20px 28px" : "12px"}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <div className="flex">
                        {!this.props.store.viewMobile && (
                          <div
                            style={{
                              height: "auto",
                            }}
                            className="mr-1"
                          >
                            <Panel color="gray" padding="0px">
                              {this.state.file
                                ? this.renderImg(this.state.file)
                                : this.personalization
                                ? this.renderImg(
                                    this.personalization[0].avatar_path
                                  )
                                : this.renderLogoEmpty()}
                            </Panel>
                          </div>
                        )}
                        <div>
                          <Text
                            size={this.props.store.viewMobile ? "md" : "xxl"}
                            weight="strong"
                            lead={this.props.store.viewMobile}
                            lineBreak
                          >
                            {"Logo"}
                          </Text>
                          <div className="my-1">
                            <Field
                              size={this.props.store.viewMobile ? "xl" : "md"}
                              // label={this.props.store.language.label116}
                              // labelNoteOption={this.props.store.language.label117}
                              color="special"
                            >
                              <Text
                                size={this.props.store.viewMobile ? "xl" : "md"}
                                lineBreak
                                color="special"
                              >
                                {this.props.store.language.label116}
                              </Text>
                              <Text
                                size={this.props.store.viewMobile ? "xl" : "md"}
                                lineBreak
                                color="special"
                              >
                                {this.props.store.language.label118}
                              </Text>
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div
                        className="is-row mt-1"
                        style={{
                          flexWrap: "wrap",
                          gap: 6,
                        }}
                      >
                        <div
                          style={{
                            margin: this.props.store.viewMobile
                              ? "0 20px 0 0"
                              : "0 0 10px 0",
                          }}
                        >
                          <Button
                            className="px-3"
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            kind="outline"
                            color="basic"
                          >
                            <label htmlFor="field">
                              <strong>
                                {this.props.store.language.label119}
                              </strong>
                            </label>
                            <input
                              name="field"
                              className="ks--fileUpload--input"
                              type="file"
                              onChange={this.handleFileChange}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                          </Button>
                        </div>
                        {this.personalization ? (
                          <div>
                            <Button
                              onClick={this.handleDeletedOpen}
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              className="px-3"
                              kind="outline"
                              color="basic"
                            >
                              <strong>
                                {this.props.store.language.label120}
                              </strong>
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {this.props.store.viewMobile && (
                      <>
                        {this.state.file
                          ? this.renderImg(this.state.file)
                          : this.personalization
                          ? this.renderImg(this.personalization[0].avatar_path)
                          : this.renderLogoEmpty()}
                      </>
                    )}
                  </div>
                </Panel>
              </div>
            </div>

            {/* <Panel
              className="my-1"
              color="gray"
              padding={this.props.store.viewMobile ? "28px" : "20px"}
            > */}
            {this.renderPanelForm(
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: this.props.store.viewMobile ? "center" : "",
                    flexDirection: this.props.store.viewMobile
                      ? "row"
                      : "column",
                  }}
                  className="mb-1"
                >
                  <Text
                    size={this.props.store.viewMobile ? "md" : "xxl"}
                    weight="strong"
                    lead={this.props.store.viewMobile}
                    lineBreak
                  >
                    {this.props.store.language.label121}
                  </Text>
                  {/* <Text
                    className={this.props.store.viewMobile && "px-1"}
                    color="special"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lineBreak
                  >
                    {this.props.store.language.label122}
                  </Text> */}
                </div>

                <Field
                  marginH={this.props.store.viewMobile && "4px"}
                  label={this.props.store.language.label123}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNoteOption={this.props.store.language.label78}
                  weight="medium"
                >
                  <TextInput
                    backgroundColor={
                      this.props.store.viewMobile ? "blackDark" : "black"
                    }
                    borderColor="black"
                    className="is-fullwidth my-1"
                    paddingInput="20px"
                    autocomplete="nope"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    name="companyName"
                    value={
                      this.personalization
                        ? this.personalization[0].company_name
                        : ""
                    }
                    onChange={
                      this.personalization
                        ? this.handleChangeEdit
                        : this.handleChange
                    }
                  />
                </Field>
                <Field
                  marginH={this.props.store.viewMobile && "4px"}
                  label={this.props.store.language.label124}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNoteOption={this.props.store.language.label78}
                  weight="medium"
                >
                  <TextInput
                    backgroundColor={
                      this.props.store.viewMobile ? "blackDark" : "black"
                    }
                    borderColor="black"
                    className="is-fullwidth my-1"
                    paddingInput="20px"
                    autocomplete="nope"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    name="web"
                    value={
                      this.personalization
                        ? this.personalization[0].text_one
                        : ""
                    }
                    onChange={
                      this.personalization
                        ? this.handleChangeEdit
                        : this.handleChange
                    }
                  />
                </Field>
                <Field
                  marginH={this.props.store.viewMobile && "4px"}
                  label={this.props.store.language.label125 + " 1"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNoteOption={this.props.store.language.label126}
                  weight="medium"
                >
                  <TextInput
                    backgroundColor={
                      this.props.store.viewMobile ? "blackDark" : "black"
                    }
                    borderColor="black"
                    className="is-fullwidth my-1"
                    paddingInput="20px"
                    autocomplete="nope"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    name="textTwo"
                    value={
                      this.personalization
                        ? this.personalization[0].text_two
                        : ""
                    }
                    onChange={
                      this.personalization
                        ? this.handleChangeEdit
                        : this.handleChange
                    }
                  />
                </Field>
                <Field
                  marginH={this.props.store.viewMobile && "4px"}
                  label={this.props.store.language.label125 + " 2"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNoteOption={this.props.store.language.label126}
                  weight="medium"
                >
                  <TextInput
                    backgroundColor={
                      this.props.store.viewMobile ? "blackDark" : "black"
                    }
                    borderColor="black"
                    className="is-fullwidth my-1"
                    paddingInput="20px"
                    autocomplete="nope"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    name="textThree"
                    value={
                      this.personalization
                        ? this.personalization[0].text_three
                        : ""
                    }
                    onChange={
                      this.personalization
                        ? this.handleChangeEdit
                        : this.handleChange
                    }
                  />
                </Field>
              </>
            )}
            <div className="is-pulled-right">
              {this.personalization
                ? this.renderButtonsEdit()
                : this.renderButtons()}
            </div>
          </Column>
        </Columns>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { currentUserPersonalization } = this.state;
    if (!currentUserPersonalization) return this.renderEmptyContent();
    return !currentUserPersonalization.isOk()
      ? this.renderLoader()
      : this.renderPersonalization();
  };

  renderScreen = () => {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label38}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  };

  render() {
    const { account } = this.props;
    const { showDeletedModal } = this.state;
    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "750px" : "90%"}
            height={this.props.store.viewMobile ? "640px" : "auto"}
          >
            <ModalHeader>
              <div className="flex items-center" style={{ flexWrap: "wrap" }}>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  lead
                  weight="black"
                  className="mr-2"
                >
                  {this.props.store.language.label38}
                </Text>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="regular"
                  className="mr-2"
                >
                  {account.username}
                </Text>
              </div>
            </ModalHeader>
            <ModalContent>{this.renderHeader()}</ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {showDeletedModal && (
          <UserPersonalizationDeletedModal
            currentUserPerson={this.personalization[0]}
            onClose={this.handleDeleteConfirmationModalClose}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={this.props.store.language.label132}
            messageError={this.props.store.language.label133}
            title={this.props.store.language.label134}
            subtitle={this.props.store.language.label135}
            all={false}
          />
        )}
      </React.Fragment>
    );
  }
}

UsersPersonalizationModal.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
};

UsersPersonalizationModal.defaultProps = {
  account: null,
  onClose: null,
};

export default withStore(withToastManager(UsersPersonalizationModal));
