import React, { Component, useRef, useLayoutEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Paragraph,
  Toggle,
  Text,
  Checkbox,
  Columns,
  Column,
} from "ks_storybook";
import { Pagination } from "../../Components/Common";
import { Select } from "../../Components/Select";
import { withToastManager } from "react-toast-notifications";
import { UserPlaylists } from "../../models";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";

function getStyle({ provided, style, isDragging, columnId }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const columnOne = columnId == "column-1";

  const grid = 8;
  const marginBottom = 5;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    background: isDragging ? "#12C889" : columnOne ? "#2E2E2E" : "#1F1F1F",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${grid * 3}px`,
    borderRadius: grid,
    marginBottom,
  };
  return withSpacing;
}

const Item = ({
  provided,
  item,
  style,
  isDragging,
  index,
  columnId,
  func,
  currentSongPath,
  isPlaying,
  showPlayer,
  handleTogglePlay,
  handleStartPlayingClick,
}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging, columnId })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      <div
        className="is-row is-fullwidth"
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="is-row ml-1" style={{ alignItems: "center" }}>
          <Button
            className="mr-3"
            icon={
              currentSongPath && item.song_path == currentSongPath && isPlaying
                ? "pause"
                : "play"
            }
            kind="link"
            color={
              currentSongPath && item.song_path == currentSongPath
                ? "primary"
                : "basic"
            }
            size="xl"
            id="play-button"
            onClick={
              currentSongPath && item.song_path == currentSongPath && showPlayer
                ? () => {
                    handleTogglePlay(item.content, item.song_path);
                  }
                : () => {
                    handleStartPlayingClick(item.content, item.song_path);
                  }
            }
            lead
            space
          />
          <Text size="xl" multiline>
            {currentSongPath && item.song_path == currentSongPath ? (
              <span style={{ fontWeight: "bold", color: "#FF3F1F" }}>
                {item.content}
              </span>
            ) : (
              item.content
            )}
          </Text>
        </div>
      </div>

      {columnId == "column-1" ? (
        <div className="is-row ml-1" style={{ alignItems: "center" }}>
          <Button
            kind="link"
            color="basic"
            size="xl"
            iconKasimu="trash"
            onClick={() => {
              func(item.key);
            }}
            space
            className="ml-1"
          />
        </div>
      ) : null}
    </div>
  );
};

const Row = (props) => {
  const { data, index, style } = props;
  const item = data.items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.key}`} index={index} key={item.key}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          columnId={data.columnId}
          func={data.func}
          currentSongPath={data.currentSongPath}
          isPlaying={data.isPlaying}
          showPlayer={data.showPlayer}
          handleTogglePlay={data.handleTogglePlay}
          handleStartPlayingClick={data.handleStartPlayingClick}
        />
      )}
    </Draggable>
  );
};

const ItemList = ({
  column,
  index,
  func,
  currentSongPath,
  isPlaying,
  showPlayer,
  handleTogglePlay,
  handleStartPlayingClick,
}) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          columnId={column.id}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        return (
          <FixedSizeList
            height={420}
            itemCount={itemCount}
            itemSize={60}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={{
              items: column.items,
              columnId: column.id,
              func: func,
              currentSongPath: currentSongPath,
              isPlaying: isPlaying,
              showPlayer: showPlayer,
              handleTogglePlay: handleTogglePlay,
              handleStartPlayingClick: handleStartPlayingClick,
            }}
            className="task-list"
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
};

const Empty = ({ label }) => {
  return (
    <div style={{ position: "absolute" }}>
      <Text upper size="xl" weight="strong" lineBreak color="gray13">
        <span style={{ color: "#ccc" }}>{label}</span>
      </Text>
    </div>
  );
};

class ColumnDraggable extends Component {
  getColumnStyle = (index) => ({
    border: index == 1 && "2px solid #e8e8e8",
    borderRadius: "8px",
    ".item": {
      background: "red",
    },
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  render() {
    const {
      column,
      index,
      func,
      currentSongPath,
      isPlaying,
      showPlayer,
      handleTogglePlay,
      handleStartPlayingClick,
      label1,
      label2,
    } = this.props;
    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <div
            className={`ks--column is-6-desktop is-12-tablet is-12-mobile px-1 py-1`}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={this.getColumnStyle(index)}
          >
            <div {...provided.dragHandleProps} />
            {index == 0 && column.items.length == 0 && <Empty label={label2} />}
            {index == 1 && (
              <div
                style={{
                  position: "absolute",
                  border: "2px dashed #ccc",
                  padding: "10px 20px",
                  borderRadius: "16px",
                }}
              >
                <Text upper size="xl" weight="strong" lineBreak color="gray13">
                  <span style={{ color: "#ccc" }}>{label1}</span>
                </Text>
              </div>
            )}
            <ItemList
              column={column}
              index={index}
              func={func}
              currentSongPath={currentSongPath}
              isPlaying={isPlaying}
              showPlayer={showPlayer}
              handleTogglePlay={handleTogglePlay}
              handleStartPlayingClick={handleStartPlayingClick}
            />
          </div>
        )}
      </Draggable>
    );
  }
}

@observer
class PlaylistManualNewModal extends Component {
  @observable userPlaylistsNew;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      totalPages: 0,
      currentPage: 0,
      count: 0,
      currentContent: null,
      currentSongPath: null,
      page: 1,
      per_page: 25,
      messageError: null,
      isPlaying: false,
      filtersStyleKasimu: false,
      filtersStyleUser: false,
      filtersMood: false,
      filtersExplicit: false,
      filtersUserCriteria: false,
      filters: {
        styles_id: null,
        user_styles_id: null,
        mood_id: null,
        explicit: null,
        user_criteria: null,
      },
      state: {
        columns: {
          "column-0": {
            id: "column-0",
            title: "First column",
            items: [],
          },
          "column-1": {
            id: "column-1",
            title: "Second column",
            items: [],
          },
        },
        columnOrder: ["column-0", "column-1"],
      },
    };

    this.search = null;
    this.grid = 4;
    this.userPlaylistsNew = this.newUserPlaylists();
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  componentDidMount() {
    let songs = this.getSongsAll(
      this.state.per_page,
      null,
      null,
      this.props.store.getFreeRights(),
      this.search
    );
    songs.andThen((res) => {
      this.resultSongs(res, 1);
    });
  };

  newUserPlaylists = () => {
    const userPlaylistsStore = this.props.store.userPlaylists;
    const attrs = {
      playlist_id: 0,
      song_lenguages_id: 1,
      name: this.props.name,
      description: this.props.description,
      free_rights: this.props.store.getFreeRights(),
      static: 1,
      explicit: 0,
      random: 0,
      num_blocks: 0,
      origin_type: 0,
      deleted: 0,
      playlist_songs: [],
    };

    return new UserPlaylists(attrs, userPlaylistsStore);
  };

  handleStartPlayingClick(content, song_path) {
    var that = this;
    that.props.store.ui.startPlaying(song_path, 3, 0);
    const newSate = this.state.state;
    this.setState({
      state: newSate,
      messageError: null,
      isPlaying: true,
      currentContent: content,
      currentSongPath: song_path,
    });
  }

  handleTogglePlay(content, song_path) {
    var that = this;
    let isPlaying = false;
    if (song_path) {
      if (that.props.store.ui.currentSongPath) {
        if (song_path != that.props.store.ui.currentSongPath) {
          that.props.store.ui.currentSongPath = song_path;
          if (that.props.store.ui.loadCurrentSong()) {
            that.props.store.ui.play();
            isPlaying = true;
          }
        } else {
          if (!this.props.store.ui.isPlaying) {
            this.props.store.ui.play();
            isPlaying = true;
          } else {
            this.props.store.ui.pause();
            isPlaying = false;
          }
        }
      }
      const newSate = this.state.state;
      this.setState({
        state: newSate,
        messageError: null,
        isPlaying: isPlaying,
        currentContent: content,
        currentSongPath: song_path,
      });
    }
  }

  getSongsAll = (per_page, filters, orders, free_rights, search) => {
    return this.props.store.songs.fetchAllParams(
      per_page,
      JSON.stringify(filters),
      null,
      free_rights,
      search,
      "_not_blocked_singers"
    );
  };

  getItemsDataObj = (obj) => {
    var arr = [];
    obj &&
      obj.map((a) =>
        arr.push({
          key: uuidv4(),
          id: a.id,
          song_path: a.song_path,
          content: a.users_songs_edit
            ? a.users_songs_edit.song_name + " - " + a.singer.name
            : a.name + " - " + a.singer.name,
        })
      );
    return arr;
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  handleChangeToggle = (sender, name, value) => {
    const val = value ? 1 : 0;
    this.userPlaylistsNew[name] = val;
  };

  resultSongs = (songs, page) => {
    const newSate = this.state.state;
    const arrayItems = this.getItemsDataObj(songs.toArray());
    newSate.columns["column-0"].items = arrayItems;
    this.setState({
      state: newSate,
      totalPages: songs.totalPages,
      currentPage: songs.currentPage,
      page: page,
      count: songs.count,
    });
  };

  onPageChange = (page) => {
    const { per_page, filters } = this.state;
    let songs = this.props.store.songs.fetchPage(
      per_page,
      page,
      JSON.stringify(filters),
      null,
      this.props.store.getFreeRights(),
      this.search,
      "_not_blocked_singers"
    );
    songs.andThen((res) => {
      this.resultSongs(res, page);
    });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { filters, per_page } = this.state;
    let newFilters = null;
    if (this.search && this.search != " ") {
      if (
        !filters.styles_id &&
        !filters.user_styles_id &&
        !filters.mood_id &&
        !filters.explicit &&
        !filters.user_criteria
      ) {
        newFilters = null;
      } else {
        newFilters = filters;
      }
      this.props.store.ui.destroy();
      this.setState({ page: 1 });
      let songs = this.getSongsAll(
        per_page,
        newFilters,
        null,
        this.props.store.getFreeRights(),
        this.search
      );
      songs.andThen((res) => {
        this.resultSongs(res, 1);
      });
    }
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.userPlaylistsNew[name] = value;
    this.setState({ messageError: null });
  };

  handleClose = () => {
    this.props.store.ui.destroy();
    this.props.onClose && this.props.onClose();
  };

  validLength = (value) => {
    if (
      value.length >= 1 &&
      value.length <=
        this.props.store.loggedInUser.ks_users_settings.limit_songs_playlist
    ) {
      return true;
    } else {
      return false;
    }
  };

  validLengthName = (value) => {
    if (value.length >= 1 && value.length <= 79) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { state } = this.state;
    if (this.validLength(state.columns["column-1"].items)) {
      if (this.validLengthName(this.userPlaylistsNew.name)) {
        this.userPlaylistsNew.playlist_songs = state.columns[
          "column-1"
        ].items.map((s) => s.id);
        this.setState({ isSaving: true }, () => {
          this.userPlaylistsNew.save().andThen((res, responseError) => {
            if (responseError) {
              toastManager.add(this.props.store.language.label622, {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              toastManager.add(this.props.store.language.label623, {
                appearance: "success",
                autoDismiss: true,
              });
              this.props.store.ui.destroy();
              this.props.onSave && this.props.onSave();
            }
          });
        });
      } else {
        this.setState({ messageError: this.props.store.language.label614 });
      }
    } else {
      this.setState({
        messageError:
          this.props.store.language.label623 +
          " " +
          this.props.store.loggedInUser.ks_users_settings.limit_songs_playlist +
          ".",
      });
    }
  };

  deleted = (key) => {
    const lists = this.state.state.columns["column-1"].items;
    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    lists.splice(index, 1);

    const newSate = this.state.state;
    newSate.columns["column-1"].items = lists;

    this.setState({
      state: newSate,
      messageError: null,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];
    destClone.splice(droppableDestination.index, 0, { ...item, key: uuidv4() });
    return destClone;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    const { state } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = this.reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      this.setState({
        state: {
          ...state,
          columnOrder,
        },
      });
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = this.reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      this.setState({ state: newState });
      return;
    }

    // moving between lists
    const sourceColumn = state.columns[result.source.droppableId];
    const destinationColumn = state.columns[result.destination.droppableId];
    const { source, destination } = result;

    // eliminado de la lista
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case "column-0":
        const result = this.copy(
          sourceColumn.items,
          destinationColumn.items,
          source,
          destination
        );

        const newSate = this.state.state;
        newSate.columns["column-1"].items = result;

        this.setState({
          state: newSate,
          messageError: null,
        });
        break;
    }
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              disabled={
                this.state.state.columns["column-1"].items.length > 0
                  ? false
                  : true
              }
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderDragDrop = () => {
    const { state, isPlaying, currentSongPath } = this.state;
    return (
      <React.Fragment>
        {state && state.columnOrder && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="app">
              <Droppable
                droppableId="all-droppables"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="ks--columns"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.columnOrder.map((columnId, index) => (
                      <>
                        <ColumnDraggable
                          key={columnId}
                          column={state.columns[columnId]}
                          index={index}
                          func={this.deleted}
                          currentSongPath={currentSongPath}
                          isPlaying={isPlaying}
                          showPlayer={this.props.store.ui.showPlayer}
                          handleTogglePlay={this.handleTogglePlay}
                          handleStartPlayingClick={this.handleStartPlayingClick}
                          label1={this.props.store.language.label621}
                          label2={this.props.store.language.label89}
                        />
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </React.Fragment>
    );
  };

  getOptionsShow = () => {
    const options = [
      { value: 1, label: this.props.store.language.label543 },
      { value: 2, label: this.props.store.language.label544 },
    ];
    return options;
  };

  handleFiltersChange = (sender, value, name) => {
    const {
      filtersStyleKasimu,
      filtersStyleUser,
      filtersMood,
      filtersExplicit,
      filtersUserCriteria,
    } = this.state;
    let newFiltersStyleKasimu = filtersStyleKasimu;
    let newFiltersStyleUser = filtersStyleUser;
    let newFiltersMood = filtersMood;
    let newFiltersExplicit = filtersExplicit;
    let newFiltersUserCriteria = filtersUserCriteria;
    const newFilters = this.state.filters;
    if (name == "filtersStyleKasimu") {
      newFilters.styles_id = null;
      newFiltersStyleKasimu = value;
    }
    if (name == "filtersStyleUser") {
      newFilters.user_styles_id = null;
      newFiltersStyleUser = value;
    }
    if (name == "filtersMood") {
      newFilters.mood_id = null;
      newFiltersMood = value;
    }
    if (name == "filtersExplicit") {
      newFilters.explicit = null;
      newFiltersExplicit = value;
    }
    if (name == "filtersUserCriteria") {
      newFilters.user_criteria = null;
      newFiltersUserCriteria = value;
    }
    this.setState({
      filtersStyleKasimu: newFiltersStyleKasimu,
      filtersStyleUser: newFiltersStyleUser,
      filtersMood: newFiltersMood,
      filtersExplicit: newFiltersExplicit,
      filtersUserCriteria: newFiltersUserCriteria,
      filters: newFilters,
    });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    const newFilters = this.state.filters;
    switch (sender.name) {
      case "selectStyleKasimu":
        newFilters.styles_id = val;
        break;
      case "selectStyleUser":
        newFilters.user_styles_id = val;
        break;
      case "selectMood":
        newFilters.mood_id = val;
        break;
      case "selectExplicit":
        newFilters.explicit = val;
        break;
      case "selectUserCriteria":
        newFilters.user_criteria = val;
        break;
      default:
        break;
    }
    this.setState({ filters: newFilters });
  };

  handleFilters = () => {
    const {
      per_page,
      filters,
      filtersStyleKasimu,
      filtersStyleUser,
      filtersMood,
      filtersExplicit,
      filtersUserCriteria,
    } = this.state;

    let newFilters = filters;

    if (!filtersStyleKasimu) {
      newFilters.styles_id = null;
    }
    if (!filtersStyleUser) {
      newFilters.user_styles_id = null;
    }
    if (!filtersMood) {
      newFilters.mood_id = null;
    }
    if (!filtersExplicit) {
      newFilters.explicit = null;
    }
    if (!filtersUserCriteria) {
      newFilters.user_criteria = null;
    }

    this.props.store.ui.destroy();
    this.setState({ page: 1 });
    let songs = this.getSongsAll(
      per_page,
      newFilters,
      null,
      this.props.store.getFreeRights(),
      this.search
    );
    songs.andThen((res) => {
      this.resultSongs(res);
    });
  };

  renderPaginator = () => {
    const { totalPages, currentPage } = this.state;
    if (totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  getOptionsMood = () => {
    const options = [
      { value: 1, label: this.props.store.language.mood[1] },
      { value: 2, label: this.props.store.language.mood[2] },
      { value: 3, label: this.props.store.language.mood[3] },
      { value: 4, label: this.props.store.language.mood[4] },
      { value: 5, label: this.props.store.language.mood[5] },
    ];
    return options;
  };

  render() {
    const {
      currentContent,
      currentSongPath,
      filtersStyleKasimu,
      filtersStyleUser,
      filtersMood,
      filtersExplicit,
      filtersUserCriteria,
    } = this.state;
    const { stylesOptions, stylesUserOptions } = this.props;
    const optionsMood = this.getOptionsMood();
    const explicitOptions = [
      { value: 2, label: "Canciones no explicitas" },
      { value: 1, label: "Canciones explicitas" },
    ];
    const optionsUserCriteria = this.getOptionsShow();
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "1000px" : "90%"}
        height={this.props.store.viewMobile ? "640px" : "100%"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label592}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "lg" : "md"}
            lineBreak
          >
            {this.props.store.language.label619}
          </Text>
          <br />
          <Columns className="mb-2">
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <Columns isMultiline>
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                  <div
                    className="is-row"
                    style={{ flexWrap: "wrap", alignItems: "center" }}
                  >
                    <div>
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        className="mr-2"
                        weight="medium"
                      >
                        {this.props.store.language.label620}{" "}
                      </Text>
                    </div>
                    <div>
                      <Text
                        size={this.props.store.viewMobile ? "md" : "sm"}
                        maxLength={this.props.mobile ? 50 : 250}
                      >
                        {this.props.store.language.label642 + this.state.count}
                      </Text>
                    </div>
                  </div>
                  <div className="flex">
                    <TextInput
                      invert
                      backgroundColor="blackDark"
                      iconKasimu="search"
                      autocomplete="nope"
                      className="is-fullwidth my-1"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      placeholderColor="gray"
                      onKeyPress={this.handleKeyPress}
                      onChange={this.handleChangeSearch}
                      placeholder={this.props.store.language.label539}
                      value={this.search && this.search}
                      paddingInput={this.props.store.viewMobile && "20px"}
                    />
                    <Button
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className={`ml-1 px-3`}
                      onClick={this.onSearch}
                    >
                      <strong>{this.props.store.language.label540}</strong>
                    </Button>
                  </div>
                </Column>
              </Columns>
              <Field
                size={this.props.store.viewMobile ? "xl" : "lg"}
                label={this.props.store.language.label405}
                weight="medium"
              >
                <Columns style={{ gap: 0 }}>
                  <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                    <Checkbox
                      invert
                      key={"styleKasimu"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      onChange={this.handleFiltersChange}
                      isChecked={filtersStyleKasimu}
                      value="filtersStyleKasimu"
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {this.props.store.language.label602 +
                          ", " +
                          this.props.store.getServiceName()}
                      </Text>
                    </Checkbox>
                    {filtersStyleKasimu && (
                      <Field size={this.props.store.viewMobile ? "xl" : "lg"}>
                        <Select
                          name="selectStyleKasimu"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={this.props.store.language.label647}
                          options={stylesOptions}
                          defaultValue={
                            this.state.filters.styles_id
                              ? stylesOptions[
                                  stylesOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.styles_id)
                                ]
                              : stylesOptions[-1]
                          }
                          backgroundColor="black"
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          isMobile={!this.props.store.viewMobile}
                        />
                      </Field>
                    )}
                    <Checkbox
                      invert
                      key={"mood"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      onChange={this.handleFiltersChange}
                      isChecked={filtersMood}
                      value="filtersMood"
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {this.props.store.language.label551}
                      </Text>
                    </Checkbox>
                    {filtersMood && (
                      <Field size={this.props.store.viewMobile ? "xl" : "lg"}>
                        <div className="is-row">
                          <Select
                            name="selectMood"
                            onChange={this.handleSelectChange}
                            className="is-fullwidth"
                            placeholder={this.props.store.language.label649}
                            options={optionsMood}
                            defaultValue={
                              this.state.filters.mood_id
                                ? optionsMood[
                                    optionsMood
                                      .map(function (e) {
                                        return e.value;
                                      })
                                      .indexOf(this.state.filters.mood_id)
                                  ]
                                : optionsMood[-1]
                            }
                            backgroundColor="black"
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            isMobile={!this.props.store.viewMobile}
                          />
                        </div>
                      </Field>
                    )}
                    <Checkbox
                      invert
                      key={"user_criteria"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      onChange={this.handleFiltersChange}
                      isChecked={filtersUserCriteria}
                      value="filtersUserCriteria"
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {this.props.store.language.label652}
                      </Text>
                    </Checkbox>
                    {filtersUserCriteria && (
                      <Field size={this.props.store.viewMobile ? "xl" : "lg"}>
                        <div className="is-row">
                          <Select
                            name="selectUserCriteria"
                            onChange={this.handleSelectChange}
                            className="is-fullwidth"
                            placeholder={this.props.store.language.label385}
                            options={optionsUserCriteria}
                            defaultValue={
                              this.state.filters.user_criteria != null
                                ? optionsUserCriteria[
                                    optionsUserCriteria
                                      .map(function (e) {
                                        return e.value;
                                      })
                                      .indexOf(this.state.filters.user_criteria)
                                  ]
                                : optionsUserCriteria[-1]
                            }
                            backgroundColor="black"
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            isMobile={!this.props.store.viewMobile}
                          />
                        </div>
                      </Field>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                    <Checkbox
                      invert
                      key={"styleUser"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      onChange={this.handleFiltersChange}
                      isChecked={filtersStyleUser}
                      value="filtersStyleUser"
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {this.props.store.language.label653}
                      </Text>
                    </Checkbox>
                    {filtersStyleUser && (
                      <Field size={this.props.store.viewMobile ? "xl" : "lg"}>
                        <Select
                          name="selectStyleUser"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={this.props.store.language.label648}
                          options={stylesUserOptions}
                          defaultValue={
                            this.state.filters.user_styles_id
                              ? stylesUserOptions[
                                  stylesUserOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.user_styles_id)
                                ]
                              : stylesUserOptions[-1]
                          }
                          backgroundColor="black"
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          isMobile={!this.props.store.viewMobile}
                        />
                      </Field>
                    )}
                    <Checkbox
                      invert
                      key={"explicit"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      onChange={this.handleFiltersChange}
                      isChecked={filtersExplicit}
                      value="filtersExplicit"
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {this.props.store.language.label650}
                      </Text>
                    </Checkbox>
                    {filtersExplicit && (
                      <Field size={this.props.store.viewMobile ? "xl" : "lg"}>
                        <div className="is-row">
                          <Select
                            name="selectExplicit"
                            onChange={this.handleSelectChange}
                            className="is-fullwidth"
                            placeholder={this.props.store.language.label651}
                            options={explicitOptions}
                            defaultValue={
                              this.state.filters.explicit != null
                                ? explicitOptions[
                                    explicitOptions
                                      .map(function (e) {
                                        return e.value;
                                      })
                                      .indexOf(this.state.filters.explicit)
                                  ]
                                : explicitOptions[-1]
                            }
                            backgroundColor="black"
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            isMobile={!this.props.store.viewMobile}
                          />
                        </div>
                      </Field>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Level isMobile>
                      <LevelLeft />
                      <LevelRight>
                        <Button
                          icon={"filter"}
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          className={`mt-1 px-2 is-pulled-right`}
                          onClick={this.handleFilters}
                          disabled={
                            !(
                              filtersStyleKasimu ||
                              filtersStyleUser ||
                              filtersMood ||
                              filtersExplicit ||
                              filtersUserCriteria
                            )
                          }
                        >
                          <strong>{this.props.store.language.label643}</strong>
                        </Button>
                      </LevelRight>
                    </Level>
                  </Column>
                </Columns>
              </Field>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <Field
                label={this.props.store.language.label53}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                <TextInput
                  className="is-fullwidth my-1"
                  name="name"
                  value={this.props.name}
                  onChange={this.handleChangeFiled}
                  required
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  invert
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
              <Field
                label={this.props.store.language.label79}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNote={this.props.store.language.label78}
                weight="medium"
              >
                <TextInput
                  className="is-fullwidth my-1"
                  name="description"
                  value={this.props.description}
                  onChange={this.handleChangeFiled}
                  required
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  invert
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
              <div className="is-row my-1" style={{ alignItems: "center" }}>
                <div style={{ marginRight: "20px" }}>
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    weight="medium"
                  >
                    {this.props.store.language.label324 + ":"}
                  </Text>
                </div>
                <Toggle
                  name={"random"}
                  noIcons
                  onChange={this.handleChangeToggle}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                  {this.state.state.columns["column-1"].items.length +
                    "/" +
                    this.props.store.loggedInUser.ks_users_settings
                      .limit_songs_playlist}
                </Text>
              </div>
            </Column>
          </Columns>
          <Columns style={{ marginTop: 0, marginBottom: 0 }}>
            <Column
              isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
              style={{ paddingBottom: 0 }}
            >
              <div
                className="is-row"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {this.props.store.ui.showPlayer && (
                  <div className="is-row">
                    <Button
                      icon={this.props.store.ui.isPlaying ? "pause" : "play"}
                      kind="link"
                      color="primary"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      onClick={() =>
                        this.handleTogglePlay(currentContent, currentSongPath)
                      }
                      id="play-button"
                      space
                    />
                    <div
                      className={`${
                        this.props.store.viewMobile ? "ml-4" : "ml-2"
                      } is-row`}
                      style={{ alignItems: "center" }}
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {currentContent && (
                        <Text
                          multiline
                          weight="normal"
                          lead={this.props.store.viewMobile}
                          size={this.props.store.viewMobile ? "sm" : "xl"}
                          color={"primary"}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {currentContent}
                          </span>
                        </Text>
                      )}
                    </div>
                  </div>
                )}
                {this.props.store.ui.showPlayer && (
                  <div>
                    <Text
                      size={this.props.store.viewMobile ? "md" : "sm"}
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentDuration == "NaN:NaN"
                        ? "00:00 / 00:00"
                        : this.props.store.ui.currentDuration &&
                          this.props.store.ui.currentProgressTime &&
                          this.props.store.ui.currentProgressTime +
                            " / " +
                            this.props.store.ui.currentDuration}
                    </Text>
                  </div>
                )}
              </div>
            </Column>
          </Columns>
          <div
            id={"waveform"}
            style={{ height: this.props.store.ui.showPlayer ? 100 : 0 }}
          />
          {this.renderDragDrop()}
          <br />
          {this.renderPaginator()}
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

PlaylistManualNewModal.propTypes = {
  stylesOptions: PropTypes.Array,
  stylesUserOptions: PropTypes.Array,
  name: PropTypes.string,
  description: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PlaylistManualNewModal.defaultProps = {
  stylesOptions: [],
  stylesUserOptions: [],
  name: "",
  description: "",
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PlaylistManualNewModal));
