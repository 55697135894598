import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import {
  Text,
  Button,
  Toggle,
  Field,
  Loader,
  Panel,
  CardEvents,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import { PinModal, HibernationModal } from "../../../../Components/Preference";
import { Select } from "../../../../Components/Select";
import { withToastManager } from "react-toast-notifications";

@observer
class UserPreferences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: this.getAccounts(),
      userId: this.props.store.getSingleUser()
        ? this.props.store.loggedInUser.id
        : null,
      showModal: false,
      showModalHibernation: false,
      preferences: this.props.store.getSingleUser()
        ? this.getPreferences(this.props.store.loggedInUser.id)
        : null,
      settings: this.props.store.getSingleUser()
        ? this.getSettings(this.props.store.loggedInUser.id)
        : null,
      disabledValue: true,
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label23;
  }

  getAccounts() {
    return this.props.store.users.fetchAccounts();
  }

  getOptions(accounts) {
    return accounts
      .toArray()
      .map((account) => ({ value: account.id, label: account.username }));
  }

  handleUserChange = (value) => {
    const originId = value && value.value;
    if (originId) {
      this.setState({ userId: originId });
      this.setState({ preferences: this.getPreferences(originId) });
      this.setState({ settings: this.getSettings(originId) });
    } else {
      this.setState({ userId: null });
      this.setState({ preferences: null });
      this.setState({ settings: null });
    }
  };

  handleSelectChangeVolume = (value, sender) => {
    const { toastManager } = this.props;
    let messageSuccess = this.props.store.language.label671;
    let messageError = this.props.store.language.label672;
    const val = !value ? 0 : value.value;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_volume_app_user",
        "?user_id=" + this.state.userId + "&volume=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeMute = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label677
        : this.props.store.language.label678;
    const messageError =
      val == 1
        ? this.props.store.language.label679
        : this.props.store.language.label680;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_show_favorites_user",
        "?user_id=" + this.state.userId + "&show_favorites=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeBlockInterface = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label269
        : this.props.store.language.label270;
    const messageError =
      val == 1
        ? this.props.store.language.label271
        : this.props.store.language.label272;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_block_interface_user",
        "?user_id=" + this.state.userId + "&block_interface=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeLockStart = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label273
        : this.props.store.language.label274;
    const messageError =
      val == 1
        ? this.props.store.language.label275
        : this.props.store.language.label276;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_lock_start_user",
        "?user_id=" + this.state.userId + "&lock_start=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeButtonsInterfaceOn = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label277
        : this.props.store.language.label278;
    const messageError =
      val == 1
        ? this.props.store.language.label279
        : this.props.store.language.label280;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_buttons_interface_on",
        "?user_id=" + this.state.userId + "&buttons_interface_on=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeInappropriateLanguageOn = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label281
        : this.props.store.language.label282;
    const messageError =
      val == 1
        ? this.props.store.language.label283
        : this.props.store.language.label284;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_inappropriate_language_on",
        "?user_id=" + this.state.userId + "&inappropriate_language_on=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeStatusHibernation = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label285
        : this.props.store.language.label286;
    const messageError =
      val == 1
        ? this.props.store.language.label287
        : this.props.store.language.label288;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_settings/put_status",
        "?user_id=" + this.state.userId + "&status=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleReload = () => {
    this.setState({ preferences: this.getPreferences(this.state.userId) });
    this.setState({ settings: this.getSettings(this.state.userId) });
  };

  handleSave = () => {
    this.setState({
      preferences: this.getPreferences(this.state.userId),
      showModal: false,
    });
  };

  getEveryDay() {
    var Settings = this.state.settings.toArray();
    return Settings[0].mon == 1 &&
      Settings[0].tues == 1 &&
      Settings[0].wed == 1 &&
      Settings[0].thurs == 1 &&
      Settings[0].fri == 1 &&
      Settings[0].sat == 1 &&
      Settings[0].sun == 1
      ? true
      : false;
  }

  handleModalHibernationOpenEdit = () => {
    this.setState({
      showModalHibernation: true,
      disabledValue: this.getEveryDay(),
    });
  };

  handleModalHibernationOpen = () => {
    this.setState({ showModalHibernation: true });
  };

  handleModalHibernationClose = () => {
    this.setState({ showModalHibernation: false });
  };

  handleSaveHibernation = () => {
    this.setState({ showModalHibernation: false });
    this.handleReload();
  };

  getPreferences(value) {
    return this.props.store.preferences.getUserPreference(value);
  }

  getSettings = (value) => {
    return this.props.store.userSettings.getUserSettings(value);
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeaderOk = () => {
    const { preferences, settings } = this.state;
    if (!preferences) return null;
    const arrayPreferences = preferences.toArray();
    const arraySettings = settings.toArray();
    return (
      <React.Fragment>
        {arrayPreferences.length === 0 && <EmptyContent />}
        {arrayPreferences.length > 0 && arraySettings.length > 0
          ? this.renderPreferencesAndSettings(
              arrayPreferences[0],
              arraySettings[0]
            )
          : arrayPreferences.length > 0 &&
            this.renderPreferencesAndSettings(arrayPreferences[0], null)}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { preferences, settings } = this.state;
    if (!preferences || !settings) return this.renderEmptyContent();
    if (!settings)
      return !preferences.isOk() && !settings.isOk()
        ? this.renderLoader()
        : this.renderHeaderOk();
    return !preferences.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getVolumen = () => {
    let items = [];
    for (let i = 100; i >= 0; i--) {
      items.push({
        value: i,
        label: i,
      });
    }
    return items;
  };

  renderSelectVolumen = (value) => {
    const options = this.getVolumen();
    return (
      <Field isSize={25}>
        <Select
          onChange={this.handleSelectChangeVolume}
          name="vol"
          required
          autocomplete="nope"
          options={options}
          defaultValue={
            options[
              options
                .map(function (e) {
                  return e.value;
                })
                .indexOf(value)
            ]
          }
          backgroundColor="transparent"
          textColor="#ffffff"
          isSearchable={false}
          className="is-fullwidth"
          space
          size={this.props.store.viewMobile ? "lg" : "md"}
          isMobile={!this.props.store.viewMobile}
        />
      </Field>
    );
  };

  renderPreferencesAndSettings = (preferences, settings) => {
    return (
      <div
        className="flex flex-col"
        style={{ gap: this.props.store.viewMobile ? "10px" : "3px" }}
      >
        <CardEvents
          name={
            preferences.pin == 0
              ? this.props.store.language.label237
              : this.props.store.language.label238
          }
          description={
            preferences.pin == 0
              ? this.props.store.language.label239
              : this.props.store.language.label240 + ": " + preferences.pin
          }
          pdEvent={this.props.store.viewMobile ? "20px" : "10px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            kind="outline"
            color="basic"
            onClick={this.handleModalOpen}
          >
            <strong>
              {preferences.pin == 0
                ? this.props.store.language.label241
                : this.props.store.language.label242}
            </strong>
          </Button>
        </CardEvents>
        <CardEvents
          name={this.props.store.language.label243}
          description={
            preferences.pin == 0
              ? this.props.store.language.label244
              : this.props.store.language.label245
          }
          pdEvent={this.props.store.viewMobile ? "20px" : "10px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Toggle
            noIcons
            onChange={this.handleChangeBlockInterface}
            checked={preferences.block_interface ? true : false}
            disabled={preferences.pin == 0 ? true : false}
          />
        </CardEvents>
        <CardEvents
          name={this.props.store.language.label246}
          description={
            preferences.pin == 0
              ? this.props.store.language.label247
              : this.props.store.language.label248
          }
          pdEvent={this.props.store.viewMobile ? "20px" : "10px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Toggle
            noIcons
            onChange={this.handleChangeLockStart}
            checked={preferences.lock_start ? true : false}
            disabled={preferences.pin == 0 ? true : false}
          />
        </CardEvents>
        <CardEvents
          name={this.props.store.language.label253}
          description={this.props.store.language.label254}
          pdEvent={this.props.store.viewMobile ? "20px" : "10px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Toggle
            noIcons
            onChange={this.handleChangeInappropriateLanguageOn}
            checked={preferences.inappropriate_language_on ? true : false}
          />
        </CardEvents>
        {this.props.store.getUserRadio() ? null : (
          <CardEvents
            name={
              preferences.show_favorites
                ? this.props.store.language.label674
                : this.props.store.language.label673
            }
            description={
              preferences.show_favorites
                ? this.props.store.language.label676
                : this.props.store.language.label675
            }
            pdEvent={this.props.store.viewMobile ? "20px" : "10px"}
            isMobile={!this.props.store.viewMobile}
          >
            <Toggle
              noIcons
              onChange={this.handleChangeMute}
              checked={preferences.show_favorites ? true : false} //mutear
            />
          </CardEvents>
        )}
        <CardEvents
          name={this.props.store.language.label669}
          description={this.props.store.language.label670}
          pdEvent={this.props.store.viewMobile ? "20px" : "10px"}
          isMobile={!this.props.store.viewMobile}
        >
          <div style={{ width: "120px" }}>
            {this.renderSelectVolumen(preferences.volume)}
          </div>
        </CardEvents>
        {/* <CardEvents
          name={this.props.store.language.label255.toUpperCase()}
          description={
            settings
              ? this.props.store.language.label256
              : this.props.store.language.label257
          }
          pdEvent={this.props.store.viewMobile ? "20px" : "10px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Button
            upper
            size={this.props.store.viewMobile ? "md" : "sm"}
            className="px-2"
            kind="outline"
            color="basic"
            onClick={
              settings
                ? this.handleModalHibernationOpenEdit
                : this.handleModalHibernationOpen
            }
          >
            {settings
              ? this.props.store.language.label140
              : this.props.store.language.label258}
          </Button>
          {settings && (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="ml-2"
            >
              <Toggle
                noIcons
                onChange={this.handleChangeStatusHibernation}
                checked={settings.status ? true : false}
              />
            </div>
          )}
        </CardEvents> */}
      </div>
    );
  };

  renderSelect = (optionsSelect) => {
    return (
      <React.Fragment>
        <Panel
          className={this.props.store.viewMobile ? "my-2" : "my-1"}
          color="gray"
          padding={!this.props.store.viewMobile && "23px 20px"}
        >
          <div
            className="is-row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ width: this.state.userId ? "90%" : "100%" }}>
              <Field
                upper
                marginH={this.props.store.viewMobile && "10px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                label={this.props.store.language.label17}
                labelNote={this.props.store.language.label53}
              >
                <Select
                  onChange={this.handleUserChange}
                  placeholder={this.props.store.language.label175}
                  isClearable
                  options={optionsSelect}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </Field>
            </div>
            {this.state.userId ? (
              <div
                className="is-pulled-right mt-2"
                style={{
                  marginLeft: "25px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  kind="link"
                  color="orange"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  icon="redo-alt"
                  onClick={this.handleReload}
                  space={!this.props.store.viewMobile}
                />
              </div>
            ) : null}
          </div>
        </Panel>
      </React.Fragment>
    );
  };

  render() {
    const {
      accounts,
      userId,
      preferences,
      settings,
      disabledValue,
      showModal,
      showModalHibernation,
    } = this.state;
    const optionsSelect = this.getOptions(accounts);
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="strong"
                className="pb-2"
              >
                {this.props.store.language.label23}
              </Text>
            )}
            {/* <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className={this.props.store.viewMobile ? "my-2" : "my-1"}
            >
              <div style={{ maxWidth: "718px" }}>
                <Paragraph size="lg" lineBreak>
                  {this.props.store.language.label174}
                </Paragraph>
              </div>
            </div> */}
            {this.props.store.getSingleUser()
              ? null
              : this.renderSelect(optionsSelect)}

            {this.renderHeader()}
          </Column>
        </Columns>
        {showModal && (
          <PinModal
            preferences={preferences}
            userId={userId}
            onClose={this.handleModalClose}
            onSave={this.handleSave}
          />
        )}
        {showModalHibernation && (
          <HibernationModal
            settings={settings}
            disabledValue={disabledValue}
            userId={userId}
            onClose={this.handleModalHibernationClose}
            onSave={this.handleSaveHibernation}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withToastManager(withStore(UserPreferences));
