import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Field,
  Button,
  Checkbox,
  Panel,
  Radio,
  RadioGroup,
} from "ks_storybook";
import { Select } from "../../../../Components/Select";
import { EmptyContent } from "../../../../Components/Common";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../../../Components/Screen";

@observer
class UsersCloneModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCopying: false,
      radioValue: 1,
      groups: this.getGroupsAll(),
      accounts: this.getAccounts(),
      originUserId: this.props.account.id,
      selected: [],
      preferences: [],
    };
  }

  getGroupsAll = () => {
    return this.props.store.groups.fetchAll();
  };

  getAccounts = () => {
    return this.props.store.users.fetchAccounts();
  };

  handleSelectedChange = (value) => {
    if (!value) {
      this.setState({ selected: [] });
    } else {
      this.setState({ selected: value });
    }
  };

  handlePreferecesChange = (sender, value, name) => {
    let preferences = this.state.preferences;
    if (value) {
      preferences.push(name);
    } else {
      preferences = preferences.filter((i) => name !== i);
    }

    this.setState({ preferences: preferences });
  };

  handleChangeRadio = (sender, value) => {
    this.setState({ messageError: null, radioValue: value, selected: [] });
  };

  getOptions = (accounts) => {
    return accounts.toArray().map((a) => ({ value: a.id, label: a.username }));
  };

  getOptionsGroups(obj) {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  }

  clearForm() {
    this.options = this.getOptions(this.state.accounts);
    this.setState({
      accounts: this.getAccounts(),
      originUserId: this.props.account.id,
      selected: [],
      preferences: [],
      radioValue: 1,
      isCopying: false,
    });
  }

  handleCopyConfigs = () => {
    const { toastManager } = this.props;
    const { preferences, originUserId, selected, radioValue } = this.state;
    const params = {
      idOrigin: originUserId,
      pk: selected.map((s) => s.value),
      radioValue: radioValue,
      copyName: preferences,
    };
    this.setState({ isCopying: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/users_preferences/copy", params)
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label221, {
              appearance: "success",
              autoDismiss: true,
            });
            this.clearForm();
          },
          (error) => {
            toastManager.add(this.props.store.language.label222, {
              appearance: "error",
              autoDismiss: true,
            });
            this.setState({ isCopying: false });
          }
        )
        .catch((error) => {
          toastManager.add(this.props.store.language.label222, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderClone = () => {
    const {
      accounts,
      groups,
      originUserId,
      preferences,
      selected,
      radioValue,
    } = this.state;
    const options = this.getOptions(accounts);
    const multiOptions = options.filter((o) => o.value !== originUserId);
    const multiOptionsGroups = this.getOptionsGroups(groups);
    return (
      <React.Fragment>
        <Columns isCentered isGapless className="is-multiline is-mobile">
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {/* <div
              style={{
                maxWidth: "718px",
              }}
              className={this.props.store.viewMobile ? "mb-2" : "mb-1"}
            >
              <Seemore
                textMore={this.props.store.language.label43}
                textLess={this.props.store.language.label44}
              >
                {this.props.store.language.label97}
              </Seemore>
            </div> */}
            {this.props.store.viewMobile ? (
              <Panel
                className={this.props.store.viewMobile && "my-1"}
                color="gray"
                padding={
                  this.props.store.viewMobile ? "28px 20px" : "22px 15px"
                }
                style={{
                  marginBottom: !this.props.store.viewMobile && "6px",
                }}
              >
                {/* <Text
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="strong"
                lineBreak
                upper
              >
                {this.props.store.language.label17}
              </Text> */}

                <div
                  className="flex"
                  style={{ gap: "2px", flexDirection: "column" }}
                >
                  <RadioGroup
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    onChange={this.handleChangeRadio}
                    value={radioValue}
                  >
                    <Radio
                      size={this.props.store.viewMobile ? "sm" : "sm"}
                      value="1"
                      class="radio"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight={Number(radioValue) === 1 ? "regular" : "medium"}
                        color={Number(radioValue) === 1 ? "white" : "gray"}
                      >
                        {this.props.store.language.label100}
                      </Text>
                    </Radio>
                    <Radio
                      size={this.props.store.viewMobile ? "sm" : "sm"}
                      value="2"
                      class="radio"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight={Number(radioValue) === 2 ? "regular" : "medium"}
                        color={Number(radioValue) === 2 ? "white" : "gray"}
                      >
                        {this.props.store.language.label101}
                      </Text>
                    </Radio>
                    <Radio
                      size={this.props.store.viewMobile ? "sm" : "sm"}
                      value="3"
                      class="radio"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight={Number(radioValue) === 3 ? "regular" : "medium"}
                        color={Number(radioValue) === 3 ? "white" : "gray"}
                      >
                        {this.props.store.language.label102}
                      </Text>
                    </Radio>
                  </RadioGroup>
                </div>
                {radioValue == 3 ? null : (
                  <Field
                    label={
                      radioValue == 1
                        ? this.props.store.language.label103
                        : this.props.store.language.label104
                    }
                    labelNote={this.props.store.language.label76}
                    // labelNoteOption={
                    //   radioValue == 1
                    //     ? this.props.store.language.label105
                    //     : this.props.store.language.label106
                    // }
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    className="pt-1"
                    weight="medium"
                  >
                    <Select
                      onChange={this.handleSelectedChange}
                      isMulti
                      isClearable
                      placeholder={
                        radioValue == 1
                          ? this.props.store.language.label107
                          : this.props.store.language.label108
                      }
                      defaultValue={selected}
                      value={selected}
                      options={
                        radioValue == 1 ? multiOptions : multiOptionsGroups
                      }
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      isMobile={!this.props.store.viewMobile}
                      backgroundColor="#141414"
                      className="pt-1"
                    />
                  </Field>
                )}
              </Panel>
            ) : (
              <>
                <div
                  className="flex"
                  style={{
                    gap: "3px",
                    marginBottom: "3px",
                    flexDirection: "column",
                  }}
                >
                  <RadioGroup
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    onChange={this.handleChangeRadio}
                    value={radioValue}
                  >
                    <Radio
                      size={this.props.store.viewMobile ? "sm" : "sm"}
                      value="1"
                      class="radio"
                      style={{
                        padding: "10px 22px",
                        backgroundColor: "#373636",
                        borderRadius: "10px",
                        backgroundColor:
                          Number(radioValue) === 1 ? "#373636" : "#1F1F1F",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight={Number(radioValue) === 1 ? "regular" : "medium"}
                        color={Number(radioValue) === 1 ? "white" : "gray"}
                        className="ml-2"
                      >
                        {this.props.store.language.label100}
                      </Text>
                    </Radio>
                    <Radio
                      size={this.props.store.viewMobile ? "sm" : "sm"}
                      value="2"
                      class="radio"
                      style={{
                        padding: "10px 22px",
                        backgroundColor:
                          Number(radioValue) === 2 ? "#373636" : "#1F1F1F",
                        borderRadius: "10px",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight={Number(radioValue) === 2 ? "regular" : "medium"}
                        color={Number(radioValue) === 2 ? "white" : "gray"}
                        className="ml-2"
                      >
                        {this.props.store.language.label101}
                      </Text>
                    </Radio>
                    <Radio
                      size={this.props.store.viewMobile ? "sm" : "sm"}
                      value="3"
                      class="radio"
                      style={{
                        padding: "10px 22px",
                        backgroundColor: "#373636",
                        borderRadius: "10px",
                        backgroundColor:
                          Number(radioValue) === 3 ? "#373636" : "#1F1F1F",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight={Number(radioValue) === 3 ? "regular" : "medium"}
                        color={Number(radioValue) === 3 ? "white" : "gray"}
                        className="ml-2"
                      >
                        {this.props.store.language.label102}
                      </Text>
                    </Radio>
                  </RadioGroup>
                </div>
                {radioValue == 3 ? null : (
                  <Panel
                    className={this.props.store.viewMobile && "my-1"}
                    color="gray"
                    padding={
                      this.props.store.viewMobile ? "28px 20px" : "18px 12px"
                    }
                    style={{
                      marginBottom: !this.props.store.viewMobile && "6px",
                    }}
                  >
                    <Field
                      label={
                        radioValue == 1
                          ? this.props.store.language.label103
                          : this.props.store.language.label104
                      }
                      labelNote={this.props.store.language.label76}
                      // labelNoteOption={
                      //   radioValue == 1
                      //     ? this.props.store.language.label105
                      //     : this.props.store.language.label106
                      // }
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                    >
                      <Select
                        onChange={this.handleSelectedChange}
                        isMulti
                        isClearable
                        placeholder={
                          radioValue == 1
                            ? this.props.store.language.label107
                            : this.props.store.language.label108
                        }
                        defaultValue={selected}
                        value={selected}
                        options={
                          radioValue == 1 ? multiOptions : multiOptionsGroups
                        }
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        isMobile={!this.props.store.viewMobile}
                        backgroundColor="#141414"
                        className="pt-1"
                      />
                    </Field>
                  </Panel>
                )}
              </>
            )}
            <Panel
              className={this.props.store.viewMobile && "my-1"}
              color="gray"
              padding={this.props.store.viewMobile ? "28px 20px" : "25px 20px"}
              style={{
                marginBottom: !this.props.store.viewMobile && "6px",
              }}
            >
              <Text
                size={this.props.store.viewMobile ? "md" : "xxl"}
                lead={this.props.store.viewMobile}
                weight="strong"
                lineBreak
                className="mb-1"
              >
                {this.props.store.language.label109}
              </Text>

              <Checkbox
                invert
                key={"preference"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handlePreferecesChange}
                isChecked={preferences.includes("preference")}
                value="preference"
                className="items-center"
              >
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="regular"
                >
                  {this.props.store.language.label23}
                </Text>
              </Checkbox>
              <Checkbox
                invert
                key={"personalization"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handlePreferecesChange}
                isChecked={preferences.includes("personalization")}
                value="personalization"
                className="items-center"
              >
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="regular"
                >
                  {this.props.store.language.label38}
                </Text>
              </Checkbox>
              <Checkbox
                invert
                key={"playlist_plans"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handlePreferecesChange}
                isChecked={preferences.includes("playlist_plans")}
                value="playlist_plans"
                className="items-center"
              >
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="regular"
                >
                  {this.props.store.language.label37}
                </Text>
              </Checkbox>
              <Checkbox
                  invert
                  key={"advertisemsents_plans"}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isChecked={preferences.includes("advertisemsents_plans")}
                  onChange={this.handlePreferecesChange}
                  value="advertisemsents_plans"
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="regular"
                  >
                    {this.props.store.language.label39}
                  </Text>
              </Checkbox>
              <Checkbox
                  invert
                  key={"user_permissions"}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isChecked={preferences.includes("user_permissions")}
                  onChange={this.handlePreferecesChange}
                  value="user_permissions"
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="regular"
                  >
                    {this.props.store.language.label768}
                  </Text>
              </Checkbox>
            </Panel>
            <div className="is-pulled-right">
              {this.state.isCopying ? (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  disabled
                  icon="spinner"
                  pulse
                >
                  {this.props.store.language.label113}
                </Button>
              ) : (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  disabled={
                    (preferences && preferences.length === 0) ||
                    !originUserId ||
                    (radioValue == 3
                      ? false
                      : selected && selected.length === 0)
                  }
                  className={
                    this.props.store.viewMobile
                      ? "px-3 mt-2"
                      : "is-fullwidth px-3"
                  }
                  onClick={this.handleCopyConfigs}
                >
                  <strong>{this.props.store.language.label112}</strong>
                </Button>
              )}
            </div>
          </Column>
        </Columns>
      </React.Fragment>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeader = () => {
    const { accounts } = this.state;
    if (!accounts) return null;
    return !accounts.isOk() ? this.renderLoader() : this.renderClone();
  };

  renderScreen = () => {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label112}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  };

  render() {
    const { account } = this.props;
    return this.props.store.viewMobile ? (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "540px" : "auto"}
      >
        <ModalHeader>
          <div className="flex items-center" style={{ flexWrap: "wrap" }}>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "lg" : "md"}
              lead
              weight="black"
              className="mr-2"
            >
              {this.props.store.language.label112}
            </Text>

            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="regular"
              className="mr-2"
            >
              {account.username}
            </Text>
          </div>
        </ModalHeader>
        <ModalContent>{this.renderHeader()}</ModalContent>
        <ModalFooter />
      </Modal>
    ) : (
      this.renderScreen()
    );
  }
}

UsersCloneModal.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
};

UsersCloneModal.defaultProps = {
  account: null,
  onClose: null,
};

export default withStore(withToastManager(UsersCloneModal));
