import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { Select } from "../Select";
import { UsersSupervisors } from "../../models";
import { observable, computed } from "mobx";
import { withToastManager } from "react-toast-notifications";

@observer
class SupervisorNewModal extends Component {
  @observable usersSupervisors;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      password_confirm: null,
      messageError: null,
      eye: false,
      confirmEye: false,
    };

    this.usersSupervisors = this.newUsersSupervisor();
  }

  newUsersSupervisor = () => {
    const usersSupervisorsStore = this.props.store.usersSupervisors;
    const attrs = {
      username: "",
      password: "",  
      group_id: [],
      observations: "",
    };
    return new UsersSupervisors(attrs, usersSupervisorsStore);
  };

  validLengthPwsOusername = (value) => {
    if (value.length >= 6 && value.length <= 40) {
      return true;
    } else {
      return false;
    }
  };

  validNoEquals = (pws, pwsConfi) => {
    if (pws === pwsConfi) {
      return true;
    } else {
      return false;
    }
  };

  validChar = (username, char) => {
    if (username.indexOf(char) === -1) return true;
    return false;
  };

  handleChange = (sender, value, name, validation) => {
    switch (name) {
      case "username":
        this.setState({ messageError: null });
          if (this.validChar(value, " ")) {
            if (this.validChar(value, "#")) {
              this.setState({ messageError: null });
            } else {
              this.setState({
                messageError: this.props.store.language.label218,
              });
            }
          } else {
            this.setState({ messageError: this.props.store.language.label210 });
          }
          this.usersSupervisors[name] = value;
        break;
      case "password":
        this.setState({ messageError: null });
        if (this.validChar(value, " ")) {
          this.setState({ messageError: null });
        } else {
          this.setState({ messageError: this.props.store.language.label766 });
        }
        this.usersSupervisors[name] = value;
        break;
      case "password_confirm":
        if (this.validChar(value, " ")) {
          this.setState({ messageError: null });
        } else {
          this.setState({ messageError: this.props.store.language.label766 });
        }
        this.setState({ password_confirm: value });
        break;
      case "groups":
        this.usersSupervisors[name] = value;
        break;
      case "observations":
        this.usersSupervisors[name] = value;
        break;
      default:
        break;
    }
  };

  handleChangeGroups = (value) => {
    this.usersSupervisors.group_id = value ? `[${value.value}]` : "[]";
  };

  getErrorMessager(answer_code) {
    const messager = {
      1: this.props.store.language.label858,
      2: this.props.store.language.label578,
      3: this.props.store.language.label859,
      4: this.props.store.language.label860
    };
    return messager[answer_code];
  }

  handleSave = () => {
    const { toastManager } = this.props;
    const { password_confirm } = this.state;

    if (this.usersSupervisors.username != "") {
      if (this.validChar(this.usersSupervisors.username, " ")) {
        if (this.validChar(this.usersSupervisors.username, "#")) {
          if (this.usersSupervisors.password != "") {
            if (password_confirm != "") {
              if (this.validLengthPwsOusername(this.usersSupervisors.username)) {
                if (this.validLengthPwsOusername(this.usersSupervisors.password)) {
                  if (this.validNoEquals(this.usersSupervisors.password, password_confirm)) {
                      this.setState({ messageError: null });
                      this.usersSupervisors.saveAsSupervisor().andThen((res, responseError) => {
                        if (responseError) {
                          let str = responseError.toString();
                          str = str.replace("Error: ", "");
                          let mydatas = JSON.parse(str);
                          this.setState({ messageError: this.getErrorMessager(mydatas['answer_code']) });
                        } else {
                          toastManager.add(this.props.store.language.label213, {
                            appearance: "success",
                            autoDismiss: true,
                          });
                          this.props.onSave && this.props.onSave();
                        }
                      });
                  } else {
                    this.setState({ messageError: this.props.store.language.label214 });
                  }
                } else {
                  this.setState({ messageError: this.props.store.language.label212 });
                }
              } else {
                this.setState({ messageError: this.props.store.language.label211 });
              }
            } else {
              this.setState({ messageError: this.props.store.language.label767 });
            }
          } else {
            this.setState({ messageError: this.props.store.language.label215 });
          }
        } else {
          this.setState({ messageError: this.props.store.language.label218 });
        }
      } else {
        this.setState({ messageError: this.props.store.language.label210 });
      }
    } else {
      this.setState({ messageError: this.props.store.language.label216 });
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label83}
            </Button>
          ) : (
            <Button
              disabled={!this.canSave}
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label82}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { groups } = this.props;

    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "540px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label861}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            color="special"
            multiline
            lineBreak
          >
            {this.props.store.language.label75}
          </Text>
          <br />
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label53}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              name="username"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label8}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type={this.state.eye ? "text" : "password"}
              name="password"
              onChange={this.handleChange}
              paddingInput={"20px 50px 20px 20px !important"}
              iconDouble={this.state.eye ? "eye-slash" : "eye"}
              onClickIcon={() => this.setState({ eye: !this.state.eye })}
              double
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label77}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type={this.state.confirmEye ? "text" : "password"}
              name="password_confirm"
              onChange={this.handleChange}
              paddingInput={"20px 50px 20px 20px !important"}
              iconDouble={this.state.confirmEye ? "eye-slash" : "eye"}
              onClickIcon={() =>
                this.setState({ confirmEye: !this.state.confirmEye })
              }
              double
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label55}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <Select
              placeholder
              isClearable
              onChange={this.handleChangeGroups}
              name="groups"
              options={groups}
              backgroundColor="black"
              separatorColor="black"
              size={this.props.store.viewMobile ? "lg" : "md"}
              isMobile={!this.props.store.viewMobile}
              className="my-1"
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label857}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              name="observations"
              type="text"
              onChange={this.handleChange}
            />
          </Field>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

SupervisorNewModal.propTypes = {
  groups: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

SupervisorNewModal.defaultProps = {
  groups: [],
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(SupervisorNewModal));
