import { Store } from "ks_storybook";
import { UsersSongsEdit } from "../models";

class UsersSongsEditStore extends Store {
  model = UsersSongsEdit;

  fetchAll() {
    return this.search(
      { per_page: 10000 },
      "users_songs_edit_all",
      true,
      "/users_songs_edit"
    );
  }
}

export default UsersSongsEditStore;
