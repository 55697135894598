import React, { Component } from "react";
import { Columns, Column } from "bloomer";
import { Text } from "ks_storybook";
import PropTypes from "prop-types";
import withStore from "../../../hocs/withStore";

class EmptyContent extends Component {
  render() {
    return (
      <Columns>
        <Column className="has-text-centered">
          <Text
            weigth="strong"
            lead
            size={this.props.store.viewMobile ? "md" : "sm"}
          >
            {" "}
            {this.props.store.language.label89}{" "}
          </Text>
        </Column>
      </Columns>
    );
  }
}

EmptyContent.propTypes = {
  message: PropTypes.string,
};

EmptyContent.defaultProps = {
  message: null,
};

export default withStore(EmptyContent);
