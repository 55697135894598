import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import IndividualUserActivityPage from "./Users/Activities/IndividualUserActivityPage";
import UsersReportsPage from "./Users/Reports/UsersReportsPage";
import UsersListPage from "./Users/List/UsersListPage";
import GroupsListPage from "./Groups/List/GroupsListPage";
import UsersPreferences from "./Users/Config/UsersPreferences";
import UserPersonalizationPage from "./Users/Config/UserPersonalizationPage";
import UsersPermissionsPage from "./Users/Config/UsersPermissionsPage";
import UsersCopyConfPage from "./Users/Config/UsersCopyConfPage";
import PlaylistsPlanner from "./Playlists/Planner/PlaylistsPlanner";
import PlaylistsListPage from "./Playlists/PlaylistsListPage";
import PlaylistsFavorites from "./Playlists/PlaylistsFavorites";
import PlaylistsManageStyles from "./Playlists/PlaylistsManageStyles";
import PlaylistsManageSong from "./Playlists/PlaylistsManageSong";
import AdsPlanner from "./Ads/Planner/AdsPlanner";
import AdsListPage from "./Ads/List/AdsListPage";
import PPMediaManager from "./Ads/Planner/PPMediaManager";
import PPCalendarManager from "./Ads/Planner/PPCalendarManager";
import SupportPage from "./Support/SupportPage";
import DownloadsPage from "./Support/DownloadsPage";
import CertificatePage from "./Support/CertificatePage";
import BlockedSingers from "./Singers/BlockedSingers";
import withStore from "../../hocs/withStore";

class ControlPanelRouter extends Component {
  componentDidMount() {
    this.props.store.ui.showMainSide = true;
  }

  render() {
    const path = this.props.match.path;
    return (
      <div className="ks--container">
        <Switch>
          <Route
            exact
            path={`${path}/controlpanel/users/activities`}
            component={IndividualUserActivityPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/users/reports`}
            component={UsersReportsPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/users/preferences`}
            component={UsersPreferences}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/users/personalization`}
            component={UserPersonalizationPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/users/permissions`}
            component={UsersPermissionsPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/users/clone`}
            component={UsersCopyConfPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/users/accounts`}
            component={UsersListPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/groups/list`}
            component={GroupsListPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/ads/list`}
            component={AdsListPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/playlist_plans`}
            component={PlaylistsPlanner}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/ads_plans`}
            component={AdsPlanner}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/pp_media_manager`}
            component={PPMediaManager}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/calendar_manager`}
            component={PPCalendarManager}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/music/list`}
            component={PlaylistsListPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/music/favorites`}
            component={PlaylistsFavorites}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/music/manage_styles`}
            component={PlaylistsManageStyles}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/music/songs`}
            component={PlaylistsManageSong}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/music/blocked_singers`}
            component={BlockedSingers}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/support/support`}
            component={SupportPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/support/downloads`}
            component={DownloadsPage}
          />
          ,
          <Route
            exact
            path={`${path}/controlpanel/support/certificate`}
            component={CertificatePage}
          />
          ,
          {this.props.store.getSingleUser() ? (
            <Redirect
              to={`${path}/controlpanel/users/activities`}
              component={IndividualUserActivityPage}
            />
          ) : (
            <Redirect
              to={`${path}/controlpanel/users/accounts`}
              component={UsersListPage}
            />
          )}
        </Switch>
      </div>
    );
  }
}

export default withRouter(withStore(ControlPanelRouter));
