import _logo from "../assets/images/audioplayer.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const AUDIOPLAYER = {
    logo: _logo,
    name: 'AudioPlayer',
    favicon: AWS_BUCKET+'/favicon/audioplayer.ico',
    web: 'www.audioplayer.me',
    supportContact: 'soporte@audioplayer.me',
    url_downloads: null,
    url_web_player_p: 'https://webplayer.audioplayer.me',
    url_web_player_t: 'https://webplayertest.megaplayer.me/session/signIn/1',
    url_install_win: AWS_BUCKET+'/installers/install_audioplayer_',
    url_install_macos: null,
    url_google_play_store: 'https://play.google.com/store/apps/details?id=com.audioplayer.audioplayer',
    url_aptoide: null,
    url_apk_x32: AWS_BUCKET+'/installers/app_audioplayer_x32.apk',
    url_apk_x64: AWS_BUCKET+'/installers/app_audioplayer_x64.apk',
    url_support_i: 'https://audioplayer.me/soporte/',
    url_support_m: 'https://audioplayer.me/soporte/',
    url_whatsapp: null,
    url_certificate:  AWS_BUCKET+'/ks_certificate/ap_certificado_freedom.pdf'
}

export default AUDIOPLAYER;