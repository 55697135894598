const it_IT = {
  media_type: {
    1: `Canzone`,
    2: `ID della radio`,
    3: `Tra canzoni`,
    4: `Spot pubblicitario`,
    5: `Inizio spot pubblicitario`,
    6: `Fine spot pubblicitario`,
  },
  mood: {
    1: "Molto tranquille",
    2: "Tranquille",
    3: "Normali",
    4: "Energiche",
    5: "Molto energiche",
    6: "Molto tranquille e Tranquille",
    7: "Tranquille e Normali",
    8: "Tranquille, Normali e Energetiche",
    9: "Normali e Energetiche",
    10: "Tutte",
  },
  languages: {
    1: "Spagnolo", 
    2: "Inglese",       
    3: "Francese",
    4: "Portoghese",
    5: "Estone",
    6: "Tedesco",
    7: "Italiano"
  },  
  label1: "Lingue",
  label2: "Canzone eliminata dal fornitore di servizi, modifica la playlist ed eliminala",
  label3: "Canzone rimossa dal fornitore di servizi, rimuovila da questo elenco",
  label4: "Riproduzione",
  label5: "Caricamento dell'Applicazione...",
  label6: "Pannello di Controllo",
  label7: "Utente (Non utilizzare l'email)",
  label8: "Password",
  label9: "Accedi",
  label10: "Errore, per favore fornisci nome utente e password.",
  label11: "Errore, Questo account non è amministratore.",
  label12: "Errore, Utente sospeso! Contattare ",
  label13: "Errore, Nome utente o password errati.",
  label14: "Errore, la tua iscrizione è scaduta. Vai a ",
  label15: "Errore, impossibile connettersi al server, controlla la tua connessione internet.",
  label16: "Pannello \n di\n Controllo",
  label17: "Utenti",
  label18: "Crea, modifica e controlla \n utenti (locali)",
  label19: "Clona configurazione \n da un utente ad altri",
  label20: "Personalizza il logo \n e i dati del programma",
  label21: "Crea e modifica \n gruppi di utenti",
  label22: "Scarica \n report di utilizzo",
  label23: "Preferenze",
  label24: "Crea e modifica preferenze \n di un utente",
  label25: "Crea e modifica \n playlist degli utenti",
  label26: "Assegna playlist o spegnimenti a ogni \n orario di ogni giorno",
  label27: "Gestisci stili musicali",
  label28: "Gestisci canzoni",
  label29: "Pubblicità",
  label30: "Carica e gestisci media",
  label31: "Crea, modifica e configura orologi",
  label32: "Carica e gestisci annunci",
  label33: "Crea, modifica e configura linee guida",
  label34: "Attività",
  label35: "Crea e modifica preferenze",
  label36: "Gruppi di utenti",
  label37: "Programmatore di playlist",
  label38: "Personalizzazione",
  label39: "Programmatore di annunci",
  label40: "Clonazione",
  label41: "Esci",
  label42: "Caricamento Dati...",
  label43: "Leggi di più",
  label44: "Leggi meno",
  label45: 'In questa sezione puoi creare utenti. È necessario avere un utente per ogni punto vendita, e ciascun utente può essere utilizzato in un solo luogo alla volta. Puoi anche cancellare o modificare gli utenti (cambiare nome utente, password, ecc.).\nOgni utente può essere assegnato a uno o più gruppi di utenti, per facilitare la configurazione di massa degli utenti. I gruppi di utenti li crei tu stesso nella sezione "CREA E MODIFICA GRUPPI DI UTENTI".\nPuoi anche controllare ciò che viene ascoltato in ogni punto vendita da questa sezione.\nPer assegnare playlist o linee guida pubblicitarie a un utente, devi andare alla sezione corrispondente ("PLAYLIST" o "ANNUNCI")',
  label46: "Utenti assunti",
  label47: "Utenti attivi",
  label48: "Utenti disponibili",
  label49: "Crea utente",
  label50: "Inserisci utenti o gruppi di utenti da cercare...",
  label51: "Filtra per...",
  label52: "Ordina per...",
  label53: "Nome",
  label54: "Email",
  label55: "Gruppo",
  label56: "Connessi",
  label57: "Disconnessi",
  label58: "Mai connessi",
  label59: "Gennaio, Febbraio, Marzo, Aprile, Maggio, Giugno, Luglio, Agosto, Settembre, Ottobre, Novembre, Dicembre",
  label60: "Gen, Feb, Mar, Apr, Mag, Giu, Lug, Ago, Set, Ott, Nov, Dic",
  label61: "Domenica, Lunedì, Martedì, Mercoledì, Giovedì, Venerdì, Sabato",
  label62: "Dom, Lun, Mar, Mer, Gio, Ven, Sab",
  label63: "Do, Lu, Ma, Me, Gi, Ve, Sa",
  label64: "Connesso",
  label65: "Disconnesso",
  label66: "Utente mai connesso",
  label67: "Sospeso dal provider di servizi",
  label68: "Attivo",
  label69: "Riproduzione della playlist",
  label70: "Playlist riprodotta",
  label71: "Da",
  label72: "Gruppo(i)",
  label73: "Non assegnato a un gruppo",
  label74: "Crea \n utente",
  label75: "I nomi utente devono includere il simbolo @. \n Se non lo includi, verrà aggiunto automaticamente all'inizio del nome utente.",
  label76: "Obbligatorio",
  label77: "Conferma password",
  label78: "Opzionale",
  label79: "Descrizione",
  label80: "Salva modifiche",
  label81: "Salvataggio modifiche...",
  label82: "Crea",
  label83: "Creazione...",
  label84: "I nomi utente devono includere il simbolo @.\n Se non lo includi, verrà aggiunto automaticamente all'inizio del nome utente.",
  label85: "Sì",
  label86: "No",
  label87: "Informazioni su",
  label88: "Utente",
  label89: "Nessun dato al momento",
  label90: "Utente creato",
  label91: "PIN di blocco",
  label92: "Nessun PIN assegnato",
  label93: "Ultimi cinque (5) accessi",
  label94: "Disconnesso",
  label95: "In pausa",
  label96: "Ibernazione",
  label97: 'Puoi clonare le impostazioni di un utente su un altro o su altri utenti, o su un gruppo o su più gruppi. Scegli l\'utente di origine e poi l\'utente o gli utenti di destinazione, o il gruppo o i gruppi di destinazione. Selezioni le impostazioni da clonare e premi il pulsante "CLONA".\n IMPORTANTE: Le impostazioni clonate sovrascriveranno le impostazioni degli utenti di destinazione. Ad esempio, se cloniamo le playlist dell\'utente, verranno eliminate tutte le playlist create dall\'utente di destinazione o dal gruppo di utenti di destinazione.',
  label98: "Scegli utente di origine...",
  label99: "(Seleziona l'utente da cui clonare le impostazioni selezionate qui sotto)",
  label100: "Seleziona utenti",
  label101: "Seleziona gruppi",
  label102: "Tutti gli utenti",
  label103: "Utente o utenti di destinazione",
  label104: "Gruppo o gruppi di destinazione",
  label105: "(Seleziona l'utente o gli utenti a cui clonare le impostazioni selezionate qui sotto)",
  label106: "(Seleziona il gruppo o i gruppi a cui clonare le impostazioni selezionate qui sotto)",
  label107: "Scegli utente o utenti di destinazione...",
  label108: "Scegli gruppo o gruppi di destinazione...",
  label109: "Impostazioni da clonare",
  label110: "Gestore contenuti",
  label111: "(Stili dell'utente e modifiche alle canzoni)",
  label112: "Clona",
  label113: "Clonando...",
  label114: "Personalizza il logo \n e i dati del programma",
  label115: 'Puoi personalizzare l\'applicazione con il tuo logo e i tuoi dati. Il logo apparirà nella schermata di accesso e nel menu, mentre i tuoi dati saranno visibili nella sezione "Informazioni su questa applicazione".',
  label116: "Seleziona l'immagine",
  label117: "Opzionale (Sostituisce il logo dell'applicazione)",
  label118: "Carica un'immagine quadrata in formato .jpg o .png.",
  label119: "Carica immagine",
  label120: "Ripristina",
  label121: "Dati",
  label122: '(Saranno mostrati nella sezione "Informazioni su questa applicazione" del programma)',
  label123: "Nome dell'azienda o del negozio",
  label124: "Sito web",
  label125: "Testo",
  label126: "Opzionale (Ad esempio, telefono o nome del contatto)",
  label127: "Salva",
  label128: "Salvataggio in corso...",
  label129: "Elimina",
  label130: "Eliminazione...",
  label131: "Logo \n dell' \n utente",
  label132: "La personalizzazione è stata ripristinata con successo.",
  label133: "Errore, qualcosa è andato storto nel ripristino della personalizzazione.",
  label134: "Vuoi ripristinare la personalizzazione?",
  label135: "La personalizzazione verrà eliminata e ripristinata ai valori predefiniti.",
  label136: 'Crea i gruppi e poi, dalla sezione "CREA, CONFIGURA E CONTROLLA UTENTI (LOCALI)", assegna gli utenti al gruppo o ai gruppi desiderati. Un utente può appartenere a più gruppi. I gruppi di utenti servono per semplificare la gestione di un gran numero di utenti assegnando configurazioni come piani di playlist, linee guida pubblicitarie, ecc.',
  label137: "Inserisci il nome del gruppo da cercare...",
  label138: "Crea gruppo",
  label139: "Crea \n gruppo",
  label140: "Modifica",
  label141: "Puoi scaricare i report delle playlist utilizzate e degli annunci pubblicati secondo le date che desideri.",
  label142: "Report degli annunci",
  label143: "Report delle playlist",
  label144: "(Verranno mostrati solo i report relativi agli annunci pubblicitari)",
  label145: "Tipo di report",
  label146: "Scegli un tipo di report...",
  label147: "Mezzo",
  label148: "Scegli un mezzo...",
  label149: "Report generale (Dettagliato)",
  label150: "Report suddiviso per selezione del mezzo (Riepilogo)",
  label151: "Report suddiviso per selezione del mezzo (Dettagliato)",
  label152: "Data iniziale",
  label153: "Obbligatorio (da)",
  label154: "Data finale",
  label155: "Obbligatorio (fino a)",
  label156: "(Verranno mostrati solo i report relativi all'uso delle playlist)",
  label157: "Scarica",
  label158: "Scaricamento...",
  label159: "Errore, qualcosa è andato storto nella generazione del report.",
  label160: "Il report è stato generato con successo.",
  label161: "Errore, il report non è stato generato, non ci sono registrazioni in quell'intervallo di tempo.",
  label162: "Report_di_",
  label163: "_(riepilogo)",
  label164: "_(dettagliato)",
  label165: "Nome utente",
  label166: "Nome del mezzo",
  label167: "Nome del file",
  label168: "Orario da",
  label169: "Orario a",
  label170: "Data di riproduzione",
  label171: "Numero di riproduzioni",
  label172: "Intervallo da",
  label173: "Intervallo a",
  label174: "Assegnare il PIN di blocco,\n Rimuovere brani con linguaggio inappropriato,\nConfigurare l'ibernazione.",
  label175: "Scegli un utente...",
  label176: `Puoi configurare diverse playlist o ibernazioni per i diversi intervalli orari di ogni giorno della settimana. Seleziona un utente e poi premi il simbolo "+" che si trova all'estrema destra di ogni giorno per effettuare le tue configurazioni.`,
  label177: "Errore, non hai account attivi, contatta il fornitore del servizio.",
  label178: 'In questa sezione puoi caricare (upload) annunci e cancellare o modificare i tuoi annunci già caricati. Successivamente, in "CREA, MODIFICA E CONFIGURA LINEE GUIDA" puoi creare le tue linee guida pubblicitarie per far suonare gli annunci nei locali.',
  label179: "Inserisci il nome dell'annuncio da cercare...",
  label180: "Carica annunci",
  label181: "Crea, modifica \n e configura linee guida",
  label182: "Puoi aggiungere linee guida pubblicitarie dei tuoi annunci (già caricati) per farli suonare nei locali.",
  label183: "Aggiungi linee guida",
  label184: 'Crea i gruppi e poi, dalla sezione "CREA, CONFIGURA E CONTROLLA UTENTI (LOCALI)", assegna gli utenti al gruppo o ai gruppi desiderati. Un utente può appartenere a più gruppi.',
  label185: "Carica mezzo",
  label186: "Pubblicitario",
  label187: "Marchio o prodotto",
  label188: "Utente (Locale)",
  label189: "Gruppo di utenti",
  label190: "Priorità",
  label191: "Quantità di pubblicazioni",
  label192: "Tipo di mezzo",
  label193: "Fino a",
  label194: "Posizioni",
  label195: "Versioni",
  label196: "Illimitata",
  label197: "Crea, modifica \n e configura orologi",
  label198: "Puoi aggiungere linee guida pubblicitarie dei tuoi annunci (già caricati).",
  label199: "Orologi",
  label200: "Calendario",
  label201: "Gestisci orologi",
  label202: "Assegna orologi a giorni normali",
  label203: "Assegna orologi a giorni speciali",
  label204: "Calendario attivato",
  label205: "Calendario disattivato",
  label206: "Utente eliminato con successo.",
  label207: "Errore, qualcosa è andato storto nell'eliminare l'utente.",
  label208: "Vuoi eliminare l'utente",
  label209: "L'utente verrà eliminato",
  label210: "Errore, il nome utente contiene spazi vuoti (non consentiti).",
  label211: "Errore, l'utente deve essere lungo 6-40 caratteri.",
  label212: "Errore, la password deve essere lunga 6-40 caratteri.",
  label213: "L'utente è stato creato con successo.",
  label214: "Errore, la password e la sua conferma non corrispondono.",
  label215: "Errore, la password è obbligatoria.",
  label216: "Errore, il nome utente è obbligatorio.",
  label217: "Modifica utente",
  label218: "Errore, il nome utente contiene il carattere # (non consentito).",
  label219: "L'utente è stato modificato con successo.",
  label220: "Errore, qualcosa è andato storto nell'editing dell'utente.",
  label221: "Il clonaggio è stato eseguito con successo.",
  label222: "Errore, qualcosa è andato storto nel clonaggio.",
  label223: "Utente di origine",
  label224: "Errore, qualcosa è andato storto nel salvare il gruppo.",
  label225: "Il Gruppo è stato salvato con successo.",
  label226: "Errore, qualcosa è andato storto nell'editing del gruppo.",
  label227: "Il Gruppo è stato modificato con successo.",
  label228: "Il gruppo",
  label229: "è stato eliminato con successo.",
  label230: "Errore, qualcosa è andato storto durante l'eliminazione del gruppo",
  label231: "Vuoi eliminare il gruppo",
  label232: "Il gruppo verrà eliminato",
  label233: "La personalizzazione è stata salvata con successo.",
  label234: "Errore, qualcosa è andato storto durante il salvataggio della personalizzazione.",
  label235: "Mostra preferiti",
  label236: "Mostra o nasconde la barra dei preferiti (barra inferiore)",
  label237: "Aggiungi il PIN di blocco",
  label238: "Cambia il PIN di blocco",
  label239: "Non hai un PIN di blocco",
  label240: "Il tuo PIN di blocco è",
  label241: "Aggiungi PIN",
  label242: "Modifica PIN",
  label243: "Blocca ora",
  label244: "Per bloccare l'applicazione ora è richiesto un PIN",
  label245: "Blocca l'applicazione ora",
  label246: "Blocca all'avvio dell'applicazione",
  label247: "Per avviare sempre l'applicazione bloccata è richiesto un PIN",
  label248: "L'applicazione si avvierà sempre bloccata",
  label249: "Cambia lingua",
  label250: "Cambia la lingua dell'applicazione",
  label251: "Mostra pulsanti in riproduzione",
  label252: "Mostra o nasconde i pulsanti (non programmare, programmare sempre, modificare, aggiungere)",
  label253: "Attiva restrizione a contenuto esplicito",
  label254: "La programmazione delle canzoni con contenuto inappropriato o abusivo è limitata",
  label255: "Modalità ibernazione",
  label256: "Modifica le impostazioni di ibernazione",
  label257: "Aggiungi una configurazione di ibernazione",
  label258: "Aggiungi",
  label259: "La lingua dell'applicazione è stata cambiata con successo in Inglese.",
  label260: "Errore, qualcosa è andato storto durante il cambio della lingua dell'applicazione in Inglese.",
  label261: "La lingua dell'applicazione è stata cambiata con successo in Spagnolo.",
  label262: "Errore, qualcosa è andato storto durante il cambio della lingua dell'applicazione in Spagnolo.",
  label263: "La lingua dell'applicazione è stata cambiata con successo in Francese.",
  label264: "Errore, qualcosa è andato storto durante il cambio della lingua dell'applicazione in Francese.",
  label265: "Barra dei preferiti attivata con successo.",
  label266: "Barra dei preferiti disattivata con successo.",
  label267: "Errore, qualcosa è andato storto durante l'attivazione della barra dei preferiti.",
  label268: "Errore, qualcosa è andato storto durante la disattivazione della barra dei preferiti.",
  label269: "L'applicazione è stata bloccata con successo.",
  label270: "L'applicazione è stata sbloccata con successo.",
  label271: "Errore, qualcosa è andato storto durante il blocco dell'applicazione.",
  label272: "Errore, qualcosa è andato storto durante lo sblocco dell'applicazione.",
  label273: "Blocco all'avvio dell'applicazione attivato con successo.",
  label274: "Blocco all'avvio dell'applicazione disattivato con successo.",
  label275: "Errore, qualcosa è andato storto durante l'attivazione del blocco all'avvio dell'applicazione.",
  label276: "Errore, qualcosa è andato storto durante la disattivazione del blocco all'avvio dell'applicazione.",
  label277: "Pulsanti in riproduzione attivati.",
  label278: "Pulsanti in riproduzione disattivati.",
  label279: "Errore, qualcosa è andato storto durante l'attivazione dei pulsanti in riproduzione.",
  label280: "Errore, qualcosa è andato storto durante la disattivazione dei pulsanti in riproduzione.",
  label281: "Attivata la restrizione alla programmazione di canzoni con contenuto inappropriato o abusivo.",
  label282: "Disattivata la restrizione alla programmazione di canzoni con contenuto inappropriato o abusivo.",
  label283: "Errore, qualcosa è andato storto durante l'attivazione della restrizione a contenuto esplicito.",
  label284: "Errore, qualcosa è andato storto durante la disattivazione della restrizione a contenuto esplicito.",
  label285: "Modalità ibernazione attivata.",
  label286: "Modalità ibernazione disattivata.",
  label287: "Errore, qualcosa è andato storto durante l'attivazione della modalità ibernazione.",
  label288: "Errore, qualcosa è andato storto durante la disattivazione della modalità ibernazione.",
  label289: "Aggiungi \n PIN",
  label290: "Modifica \n PIN",
  label291: "PIN Attuale",
  label292: "Nuovo PIN",
  label293: "Obbligatorio (il PIN deve essere di 4 cifre)",
  label294: "Conferma il tuo nuovo PIN",
  label295: "Errore, nel PIN sono consentiti solo numeri e 0000 non è consentito",
  label296: "Errore, il PIN deve essere di 4 cifre.",
  label297: "Errore, la conferma non corrisponde al nuovo PIN.",
  label298: "Errore, il PIN è identico a quello precedente. Inserisci un nuovo PIN, per favore.",
  label299: "Il PIN è stato salvato con successo.",
  label300: "Il PIN è stato modificato con successo.",
  label301: "Errore, qualcosa è andato storto durante il salvataggio del PIN.",
  label302: "Errore, qualcosa è andato storto durante la modifica del PIN.",
  label303: "Aggiungi una configurazione \n di ibernazione",
  label304: "Modifica una configurazione \n di ibernazione",
  label305: "Stato",
  label306: "Orario di ibernazione",
  label307: "In",
  label308: "Ogni giorno",
  label309: "Giorni specificati",
  label310: "La configurazione di ibernazione è stata salvata con successo.",
  label311: "Errore, qualcosa è andato storto durante il salvataggio della configurazione di ibernazione.",
  label312: "La configurazione di ibernazione è stata modificata con successo.",
  label313: "Errore, qualcosa è andato storto durante la modifica della configurazione di ibernazione.",
  label314: "Pianificatore attivato con successo.",
  label315: "Pianificatore disattivato con successo.",
  label316: "Errore, qualcosa è andato storto durante l'attivazione del pianificatore.",
  label317: "Errore, qualcosa è andato storto durante la disattivazione del pianificatore.",
  label318: "Pianificatore Attivato",
  label319: "Pianificatore Disattivato",
  label320: "Aggiungi pianificazione",
  label323: "Le mie playlist",
  label324: "Casuale",
  label325: "Il piano è stato salvato con successo.",
  label326: "Errore, qualcosa è andato storto durante il salvataggio del piano.",
  label327: 'Errore, l\'ora "Da" è inferiore all\'ora "A".',
  label328: "Errore, esiste già un piano in quell'intervallo orario.",
  label329: "Carica \n annuncio",
  label330: "Il piano è stato eliminato con successo.",
  label331: "Errore, qualcosa è andato storto durante l'eliminazione del piano.",
  label332: "Casuale attivato con successo.",
  label333: "Casuale disattivato con successo.",
  label334: "Errore, qualcosa è andato storto durante l'attivazione della modalità casuale.",
  label335: "Errore, qualcosa è andato storto durante la disattivazione della modalità casuale.",
  label336: "Trascina \n il tuo annuncio \n o clicca \n in questa area",
  label337: "Dimensione",
  label338: "(Solo mp3. Dimensione massima 5 MB).",
  label339: "Carica annuncio",
  label340: "Caricamento annuncio...",
  label341: "Errore, (SOLO IN MP3).",
  label342: "Il nome dell'annuncio deve contenere da 1 a 50 caratteri.",
  label343: "Errore, qualcosa è andato storto durante il caricamento dell'annuncio, verifica che sia un file MP3 e che la sua dimensione non superi i 5 MB.",
  label344: "L'annuncio è stato caricato con successo.",
  label345: "L'annuncio",
  label346: "Errore, qualcosa è andato storto durante l'eliminazione dell'annuncio",
  label347: "Vuoi eliminare l'annuncio",
  label348: "L'annuncio verrà eliminato",
  label349: "Ogni giorno",
  label350: "Lunedì a venerdì",
  label351: "Sabato e domenica",
  label352: "Giorno esatto",
  label353: "Ora esatta",
  label354: "Intervallo",
  label355: "Ogni",
  label356: "Frequenza",
  label357: "canzoni",
  label358: "Limite di annunci per blocco",
  label359: "Inizio della pubblicità",
  label360: "Fine della pubblicità",
  label361: "Aggiungi \n pubblicità",
  label362: "Giorni",
  label363: "Orario",
  label364: "Intervallo di tempo",
  label365: "Limite di annunci per blocco",
  label366: "Ogni x minuti",
  label367: "Ogni x canzoni",
  label368: "Giorno o giorni",
  label369: "Ora",
  label370: "Giorno",
  label371: "Definisci l'inizio",
  label372: "Definisci la fine",
  label373: "Errore, esiste già una pubblicità con quel nome.",
  label374: "Errore, esiste già una pubblicità in quell'intervallo di tempo.",
  label375: "Errore, esiste già una pubblicità per quell'ora precisa.",
  label376: "Il mezzo",
  label377: "Errore, qualcosa è andato storto eliminando il mezzo",
  label378: "Vuoi eliminare il mezzo",
  label379: "Il mezzo sarà eliminato",
  label380: "fino a",
  label381: "Alta",
  label382: "Normale",
  label383: "Puoi caricare qualsiasi tipo di mezzo (spot, annuncio, tra le canzoni, ID radio) \n o creare il tuo tipo. Scegli il file da caricare, dai un nome, indica il tipo di mezzo, \n assegna tutti i valori, se ci sono versioni, puoi caricarle e indica le posizioni dove suonerà.",
  label384: "Definisci inizio e fine",
  label385: "Scegli...",
  label386: "Inizio",
  label387: "Fine",
  label388: "Marchio o prodotto",
  label389: "Azienda pubblicitaria",
  label390: "Valori",
  label391: "Infiniti",
  label392: "Luoghi in cui verrà pubblicato",
  label393: "Tutti gli utenti (locali)",
  label394: "Seleziona utenti (locali) o gruppi",
  label395: "Utenti (Locali)",
  label396: "Gruppi",
  label397: "Errore, qualcosa è andato storto durante il caricamento del mezzo.",
  label398: "Il mezzo è stato salvato con successo.",
  label399: `Errore, devi selezionare una priorità.`,
  label400: `Errore, non hai selezionato alcuna posizione per la pubblicazione.`,
  label401: `Errore, devi selezionare un marchio o prodotto.`,
  label402: `Errore, devi selezionare un inserzionista.`,
  label403: `Errore, devi selezionare un tipo di mezzo.`,
  label404: `Errore, devi aggiungere un nome per il mezzo.`,
  label405: `Filtra per`,
  label406: `Trascina qui \n utenti o gruppi`,
  label407: `Errore, qualcosa è andato storto durante la modifica del mezzo.`,
  label408: `Il mezzo è stato modificato con successo.`,
  label409: `Aggiungi \n tipo di mezzo`,
  label410: `Errore, qualcosa è andato storto durante il salvataggio del tipo di mezzo.`,
  label411: `Il tipo di mezzo è stato salvato con successo.`,
  label412: `Errore, il nome del tipo di mezzo deve contenere da 1 a 50 caratteri.`,
  label413: `Aggiungi \n inserzionista`,
  label414: `Errore, qualcosa è andato storto durante il salvataggio dell'inserzionista.`,
  label415: `L'inserzionista è stato salvato con successo.`,
  label416: `Errore, il nome dell'inserzionista deve contenere da 1 a 50 caratteri.`,
  label417: `Aggiungi \n marchio o prodotto`,
  label418: `Scrivi qui per cercare...`,
  label419: `Errore, qualcosa è andato storto durante il salvataggio del marchio o prodotto.`,
  label420: `Il marchio o prodotto è stato salvato con successo.`,
  label421: `Errore, il nome del marchio o prodotto deve contenere da 1 a 50 caratteri.`,
  label422: `Carica \n versioni a`,
  label423: `La Versione`,
  label424: `Errore, qualcosa è andato storto eliminando la versione.`,
  label425: `Vuoi eliminare la versione`,
  label426: `La versione sarà eliminata`,
  label427: `Annulla`,
  label428: `Errore, qualcosa è andato storto durante il caricamento della versione.`,
  label429: `La versione è stata caricata con successo.`,
  label430: `Errore. non è stato possibile caricare la versione, chiudi la finestra e riprova.`,
  label431: `Calendario attivato con successo.`,
  label432: `Calendario disattivato con successo.`,
  label433: `Errore, qualcosa è andato storto durante l'attivazione del calendario.`,
  label434: `Errore, qualcosa è andato storto durante la disattivazione del calendario.`,
  label435: `Crea orologio`,
  label436: `Per creare un orologio, devi trascinare i media che compaiono a sinistra verso destra. Poi puoi trascinarli su o giù per ordinarli. Il mezzo "COMMERCIALE" ha di default 16 spot pubblicitari. Il resto ne ha solo 1 di default. In entrambi i casi, è possibile modificare questo numero premendo sulla matita.`,
  label437: `Cercatore di mezzi`,
  label438: `Trascina i \n mezzi qui`,
  label439: `Errore, il nome dell'orologio deve contenere da 1 a 50 caratteri.`,
  label440: `Errore, qualcosa è andato storto durante il salvataggio dell'orologio.`,
  label441: `L'orologio è stato salvato con successo.`,
  label442: `Errore, non hai aggiunto alcun mezzo.`,
  label443: `Gestisci \n orologi`,
  label444: `Modifica o elimina, se elimini l'orologio scomparirà dal calendario.`,
  label445: `L'orologio`,
  label446: `Errore, qualcosa è andato storto eliminando l'orologio.`,
  label447: `Vuoi eliminare l'orologio`,
  label448: `L'orologio sarà eliminato`,
  label449: `Quantità`,
  label450: `Blocchi di`,
  label451: `Errore, qualcosa è andato storto durante l'editing di`,
  label452: `Per modificare un orologio, devi trascinare i media che compaiono a sinistra verso destra. Poi puoi trascinarli su o giù per ordinarli. Il mezzo "COMMERCIALE" ha di default 16 spot pubblicitari. Il resto ne ha solo 1 di default. In entrambi i casi, è possibile modificare questo numero premendo sulla matita.`,
  label453: `L'orologio è stato modificato con successo.`,
  label454: `Assegna un orologio a ciascun intervallo orario. Puoi lasciare gli intervalli senza un orologio assegnato, in tal caso, verrà riprodotta solo musica (senza mezzi pubblicitari).`,
  label455: `Nessun orologio assegnato`,
  label456: `Errore, qualcosa è andato storto durante il salvataggio dell'orologio in un giorno normale.`,
  label457: `L'orologio in un giorno normale è stato salvato con successo.`,
  label458: `Assegna orologi a giorni speciali`,
  label459: `Ripetizioni`,
  label460: `Giorno specifico`,
  label461: `Intervallo di giorni`,
  label462: `Errore, il nome del giorno speciale deve contenere da 1 a 50 caratteri.`,
  label463: `L'orologio nel giorno speciale è stato salvato con successo.`,
  label464: `Errore, qualcosa è andato storto durante il salvataggio dell'orologio in un giorno speciale.`,
  label465: `Errore, esiste già un orologio assegnato a quel giorno specifico e la sua ripetizione coincide o è all'interno di un altro intervallo.`,
  label466: `Errore, esiste già un orologio assegnato in quell'intervallo.`,
  label467: `Errore, la data di fine è successiva alla data di inizio.`,
  label468: `Modifica o elimina \n la struttura del giorno`,
  label469: `Modifica o elimina, cambiando qualsiasi valore dei selettori verrà modificato automaticamente, se elimini il giorno scomparirà dal calendario.`,
  label470: `Questa intervallo nella struttura del giorno è stata aggiornata correttamente.`,
  label471: `Errore. qualcosa è andato storto durante l'editing di questa intervallo.`,
  label472: `Ultimo collegamento`,
  label473: `Connesso il `,
  label474: `Termina`,
  label475: `Gestisci \n media`,
  label476: `Gestisci \n inserzionisti`,
  label477: `Gestisci \n marchi o prodotti`,
  label478: `Modifica o elimina, non puoi eliminare un tipo di mezzo già assegnato a un mezzo già caricato.`,
  label479: `Modifica o elimina, non puoi eliminare un inserzionista già assegnato a un mezzo già caricato.`,
  label480: `Modifica o elimina, non puoi eliminare un marchio o prodotto già assegnato a un mezzo già caricato.`,
  label481: `Tipo di mezzo`,
  label482: `Inserzionista`,
  label483: `Marchio o prodotto`,
  label484: `Errore, qualcosa è andato storto eliminando il tipo di mezzo.`,
  label485: `Errore, qualcosa è andato storto eliminando l'inserzionista.`,
  label486: `Errore, qualcosa è andato storto eliminando il marchio o prodotto.`,
  label487: `Vuoi eliminare il tipo di mezzo`,
  label488: `Vuoi eliminare l'inserzionista`,
  label489: `Vuoi eliminare il marchio o prodotto`,
  label490: `Il tipo di mezzo sarà eliminato`,
  label491: `L'inserzionista sarà eliminato`,
  label492: `Il marchio o prodotto sarà eliminato`,
  label493: `Ok`,
  label494: `Avviso`,
  label495: `Non è possibile eliminare questo tipo di mezzo, poiché è assegnato a uno o più media già caricati; se desideri eliminarlo, devi prima eliminare i media ad esso associati.`,
  label496: `Non è possibile eliminare questa azienda o inserzionista, poiché è assegnata a uno o più media già caricati; se desideri eliminarla, devi prima eliminare i media ad essa associati.`,
  label497: `Non è possibile eliminare questo marchio o prodotto, poiché è assegnato a uno o più media già caricati; se desideri eliminarlo, devi prima eliminare i media ad esso associati.`,
  label498: `Hai superato il limite di media caricati disponibili per il tuo abbonamento, contattaci per ampliare le limitazioni del tuo abbonamento.`,
  label499: `Hai superato il limite di versioni per mezzo disponibili per il tuo abbonamento, contattaci per ampliare le limitazioni del tuo abbonamento.`,
  label500: `Hai superato il limite di dimensioni per annuncio disponibili per il tuo abbonamento, contattaci per ampliare le limitazioni del tuo abbonamento.`,
  label501: `Hai superato il limite di tipi di media che puoi creare disponibili per il tuo abbonamento, contattaci per ampliare le limitazioni del tuo abbonamento.`,
  label502: `Attualmente il tuo limite di creazione di tipi di media è di: `,
  label503: `Attualmente il tuo limite di caricamento di media è di: `,
  label504: `Attualmente il tuo limite di caricamento di versioni è di: `,
  label505: `Attualmente il tuo limite di dimensioni per annuncio è di: `,
  label506: `Vuoi eliminare la pauta`,
  label507: `La pauta sarà eliminata`,
  label508: `Aggiungi / rimuovi`,
  label509: `Numero di annunci caricati`,
  label510: `Aggiungi o rimuovi annunci`,
  label511: `Aggiungi o rimuovi annunci, devi trascinare gli annunci che compaiono a sinistra verso destra. Poi puoi trascinarli su o giù per ordinarli.`,
  label512: `Annunci per la pauta`,
  label513: `Cercatore di annunci`,
  label514: `Trascina gli \n annunci qui`,
  label515: `Errore, qualcosa è andato storto durante il salvataggio della pauta.`,
  label516: `La pauta è stata salvata con successo.`,
  label517: `Errore, qualcosa è andato storto durante la modifica della pauta.`,
  label518: `La pauta è stata modificata con successo.`,
  label519: `Rapporto discriminato per selezione di mezzo già cancellato (Sommario)`,
  label520: `Rapporto discriminato per selezione di mezzo già cancellato (Dettagliato)`,
  label521: `Mezzo già cancellato`,
  label522: `I miei Stili`,
  label523: `Puoi creare i tuoi stili musicali per poi andare su "GESTISCI BRANI" e assegnarli alle canzoni che vuoi che facciano parte di quel o quegli stili.`,
  label524: `Scrivi il nome dello stile da cercare...`,
  label525: `Lo stile`,
  label526: `Errore, qualcosa è andato storto durante l'eliminazione dello stile`,
  label527: `Vuoi eliminare lo stile`,
  label528: `Lo stile sarà eliminato`,
  label529: `Crea \n stile`,
  label530: `Crea stile`,
  label531: `Errore, qualcosa è andato storto durante il salvataggio dello stile.`,
  label532: `Lo stile è stato salvato con successo.`,
  label533: `Errore, il nome dello stile deve contenere da 1 a 50 caratteri.`,
  label534: `Errore, qualcosa è andato storto durante la modifica dello stile.`,
  label535: `Lo stile è stato modificato con successo.`,
  label536: `Modifica \n stile`,
  label537: `Gestore delle canzoni`,
  label538: `Puoi cambiare lo stile di una canzone con uno creato dall'utente, puoi cambiarne il nome, indicarla come esplicita (linguaggio inappropriato) o contrassegnarla come preferita o indesiderata. Puoi anche assegnarla a una playlist manuale.`,
  label539: `Scrivi qui il nome della canzone o dell'interprete da cercare...`,
  label540: `Cerca`,
  label541: `Mostra`,
  label542: `Tutte`,
  label543: `Solo modificate`,
  label544: `Preferite`,
  label545: `Non desiderate`,
  label546: `Modifica \n canzone`,
  label547: `Predefinito`,
  label548: `Errore, i campi nome e password sono obbligatori.`,
  label549: `Nome originale`,
  label550: `Stile musicale`,
  label551: `Umore`,
  label552: `Preferenza`,
  label553: `Canzone esplicita`,
  label554: `(Contenuto inappropriato o abusivo)`,
  label555: `Errore, qualcosa è andato storto durante la modifica della canzone.`,
  label556: `La canzone è stata modificata con successo.`,
  label557: `Autorizzazioni per utilizzare l'app musicale, premere il pulsante Salva per applicare le modifiche.`,
  label558: `Vuoi togliere il segno di "preferita" da questa canzone`,
  label559: `Vuoi segnare questa canzone come preferita`,
  label560: `non sarà più inclusa sempre, solo normalmente.`,
  label561: `sarà sempre inclusa nelle playlist associate allo stile di questa canzone.`,
  label562: `Errore, qualcosa è andato storto durante la rimozione del segno di "preferita" da questa canzone.`,
  label563: `Errore, qualcosa è andato storto durante il segnare questa canzone come "preferita".`,
  label564: `La canzone è stata tolta come "preferita" con successo.`,
  label565: `La canzone è stata segnata come "preferita" con successo.`,
  label566: `Vuoi togliere il segno di "non desiderata" da questa canzone`,
  label567: `Vuoi segnare questa canzone come "non desiderata"`,
  label568: `verrà riprodotta nuovamente normalmente.`,
  label569: `non verrà più riprodotta.`,
  label570: `Errore, qualcosa è andato storto durante la rimozione del segno di "non desiderata" da questa canzone.`,
  label571: `Errore, qualcosa è andato storto durante il segnare questa canzone come "non desiderata".`,
  label572: `La canzone è stata tolta come "non desiderata" con successo.`,
  label573: `La canzone è stata segnata come "non desiderata" con successo.`,
  label574: `Scegli una playlist...`,
  label575: `Paese`,
  label576: `Città`,
  label577: `Provincia`,
  label578: `Errore, il nome è già in uso, scegli un altro nome.`,
  label579: `Puoi creare le tue playlist in due modi diversi. Automatico: scegli gli stili e il programma sceglie le canzoni. Manuale: scegli le canzoni una per una. Puoi anche eliminare playlist o modificarne le esistenti.`,
  label580: `La playlist`,
  label581: `Errore, qualcosa è andato storto durante l'eliminazione della playlist.`,
  label582: `Vuoi eliminare la playlist`,
  label583: `La playlist sarà eliminata`,
  label584: `Playlist Manuale`,
  label585: `Playlist Automatica`,
  label586: `Scrivi qui il nome della playlist da cercare...`,
  label587: `Crea playlist`,
  label588: `Annunci della pauta`,
  label589: `Crea \n playlist`,
  label590: `Scegli un'opzione.`,
  label591: `Automatico`,
  label592: `Manuale`,
  label593: `scegli gli stili e il programma sceglie le canzoni.`,
  label594: `scegli le canzoni una per una.`,
  label595: `Seleziona il tipo di playlist da creare...`,
  label596: `Nome della nuova playlist (obbligatorio)...`,
  label597: `Descrizione (opzionale)...`,
  label598: `Avanti`,
  label599: `Trascina da sinistra a destra gli stili che desideri. Puoi trascinare uno stile più volte in modo che \n vengano programmate più canzoni di quel genere. Successivamente, nel pannello di destra, puoi trascinare su \n e giù gli stili per ordinarli. \n Puoi anche modificare le impostazioni dello stile in modo che programmi canzoni più tranquille o movimentate, ecc.`,
  label600: `Inserisci qui il nome dello stile da cercare...`,
  label601: `Cerca stili musicali`,
  label602: `Stili`,
  label603: `I miei stili`,
  label604: `Durata (in ore)`,
  label605: `Limitare esplicite`,
  label606: `Trascina gli stili qui`,
  label607: `Errore, qualcosa è andato storto durante la creazione della playlist automatica.`,
  label608: `La playlist automatica è stata creata con successo.`,
  label609: `Errore, nessuno stile è selezionato per creare la playlist.`,
  label610: `Modifica \n playlist automatica`,
  label611: `Trascina da sinistra a destra gli stili che desideri. Puoi trascinare uno stile più volte in modo che \n vengano programmate più canzoni di quel genere. Successivamente, nel pannello di destra, puoi trascinare su \n e giù gli stili per ordinarli. \n Puoi anche modificare le impostazioni dello stile in modo che programmi canzoni più tranquille o movimentate, ecc.`,
  label612: `Errore, qualcosa è andato storto durante la modifica della playlist automatica.`,
  label613: `La playlist automatica è stata modificata con successo.`,
  label614: `Errore, il nome della playlist deve contenere almeno un carattere.`,
  label615: `Modifica`,
  label616: `Solo successi`,
  label617: `Successi e raccomandate`,
  label618: `Successi, raccomandate e varie`,
  label619: `Cerca le canzoni che desideri, per nome o per nome dell'artista. Quindi trascina \n ogni canzone da sinistra a destra. Nel pannello di destra, puoi trascinare su \n e giù le canzoni per ordinarle.`,
  label620: `Cerca canzoni`,
  label621: `Trascina le canzoni qui`,
  label622: `Errore, qualcosa è andato storto durante la creazione della playlist manuale.`,
  label623: `La playlist manuale è stata creata con successo.`,
  label624: `Errore, il limite di canzoni per playlist è`,
  label625: `Modifica \n playlist manuale`,
  label626: `Errore, qualcosa è andato storto durante la modifica della playlist manuale.`,
  label627: `La playlist manuale è stata modificata con successo.`,
  label629: `Numero di canzoni`,
  label630: `(Amministratore) - `,
  label631: `(Utente) - `,
  label632: `a playlist manuali`,
  label633: `Puoi aggiungere solo a playlist manuali create da te.`,
  label634: `Playlist di destinazione`,
  label635: `Aggiunta in corso...`,
  label636: `La canzone è stata aggiunta con successo.`,
  label637: `Errore, qualcosa è andato storto durante l'aggiunta della canzone.`,
  label638: `Scegli un gruppo...`,
  label639: `Tipo di connessione`,
  label640: `Scegli un tipo di connessione...`,
  label641: `Ricerca specifica`,
  label642: `Risultati: `,
  label643: `Applica filtro`,
  label644: `Inserisci qui il nome utente da cercare...`,
  label645: `Canzoni non esplicite`,
  label646: `Canzoni esplicite`,
  label647: `Scegli uno stile...`,
  label648: `Scegli uno stile dell'utente...`,
  label649: `Scegli un umore...`,
  label650: `Contenuto esplicito`,
  label651: `Scegli tipo di contenuto...`,
  label652: `Criterio dell'utente`,
  label653: `Stili dell'Utente`,
  label654: `Errore nel file audio.`,
  label655: `Password Attuale`,
  label656: `Paese`,
  label657: `Provincia`,
  label658: `Città`,
  label659: `Ibernazione`,
  label660: `Errore, l'ora di inizio deve essere successiva all'ora di fine.`,
  label661: `Errore, la differenza tra le ore deve essere almeno di 5 minuti.`,
  label662: `Errore, devi selezionare almeno un giorno.`,
  label663: `Errore, ci sono (`,
  label664: `) piano/i che interferiscono con l'intervallo orario che stai cercando di creare nel/i giorno/i selezionato/i.`,
  label665: `Tutti i piani per il giorno, `,
  label666: `, sono stati eliminati.`,
  label667: `Errore, qualcosa è andato storto durante l'eliminazione di tutti i piani per il giorno, `,
  label668: `vuoi eliminare tutti i piani per il giorno?`,
  label669: `Stima del volume generale dell'app`,
  label670: `La scala del volume dell'app va da 0 a 100`,
  label671: `Il volume dell'app è stato aggiornato con successo.`,
  label672: `Errore nell'aggiornamento del volume dell'app.`,
  label673: `Spegni l'audio dell'app`,
  label674: `Accendi l'audio dell'app`,
  label675: `Disattiva il suono dell'app`,
  label676: `Attiva il suono dell'app`,
  label677: "L'app è stata messa in modalità silenziosa con successo.",
  label678: "L'app è stata attivata con successo.",
  label679: "Errore, qualcosa è andato storto durante il silenziamento dell'app.",
  label680: "Errore, qualcosa è andato storto durante l'attivazione del suono dell'app.",
  label681: "Ripristina tutto",
  label682: "La personalizzazione dei tuoi utenti è stata ripristinata con successo.",
  label683: "Errore, qualcosa è andato storto durante il ripristino della personalizzazione dei tuoi utenti.",
  label684: "Vuoi ripristinare la personalizzazione dei tuoi utenti?",
  label685: "La personalizzazione dei tuoi utenti verrà eliminata e verrà ripristinata quella predefinita per ciascuno di essi.",
  label686: "Nessuna descrizione",
  root: {
    1: "GOD",
    2: "SUPER-ROOT",
    3: "ROOT",
    4: "SUPER-AMMINISTRATORE",
    5: "AMMINISTRATORE",
    6: "SUPERVISORE",
    7: "UTENTE-AMMINISTRATO",
    8: "UTENTE-INDIVIDUALE",
  },
  label687: "Accounto",
  label688: "Gestione dell'account",
  label689: "Cambia password",
  label690: "Modifica dati dell'account (Nome azienda, indirizzo, P. IVA/Codice Fiscale, ecc.)",
  label691: "Scarica fatture o aggiorna dati della carta di debito/credito",
  label692: "Cambia o annulla l'abbonamento",
  label693: "Cambio password dell'account attuale",
  label694: "Nuova password",
  label695: "La password è stata modificata con successo.",
  label696: "Errore, qualcosa è andato storto durante la modifica della password in amember.",
  label697: "Errore, qualcosa è andato storto durante la modifica della password.",
  label698: "Installare con privilegi di amministratore. Su Windows 10, inserire semplicemente il nome utente e la password dell'amministratore durante l'installazione del programma. Su Windows 7, l'account su cui viene eseguita l'installazione deve essere un amministratore. Se si desidera installare su un account standard (non amministratore), è necessario trasformare tale utente in amministratore, installare il programma e successivamente tornare a trasformarlo in utente standard. In ogni caso, verificare che nessun programma blocchi l'applicazione l'accesso a Internet (antivirus, firewall, ecc.).",
  label699: 'Per installare il programma su un computer Mac OS, esegui le seguenti operazioni: Vai su Preferenze di Sistema > Sicurezza e Privacy. Fai clic sul lucchetto in basso a sinistra e inserisci la password dell\'utente per sbloccare il tuo Mac. Seleziona la casella "Qualsiasi sito" nell\'area "Consenti applicazioni di terze parti".',
  label700: "Consigliamo di installare l'app e attivare gli aggiornamenti automatici direttamente da Google Play Store; nel caso di installazione al di fuori del negozio, gli aggiornamenti dell'app saranno gestiti da te, verificando l'ultima versione dell'app pubblicata su questa pagina.",
  label701: "Download",
  label702: "Importante",
  label703: "Questa area è pubblica, non è necessaria una password per accedervi. Copia il link di questa pagina qui sotto e condividilo con tutti gli utenti che hanno bisogno di scaricare il programma.",
  label704: "Ultima versione dell'applicazione",
  label705: "Vai",
  label706: "Istruzioni",
  label707: "Requisiti tecnici",
  label708: `Sistema operativo Windows 7 o successivo (Windows 10 consigliato); nella versione Windows N, l'applicazione non potrà essere utilizzata in quanto manca il "Media Feature Pack", consulta l\'area di supporto in questo caso.`,
  label709: "16 GB di spazio disponibile sull'hard disk (consigliati 32 GB o più)",
  label710: "4 GB di memoria RAM o superiore",
  label711: "Scheda audio",
  label712: "Connessione a Internet",
  label713: "Architettura del processore ARM o ARM64",
  label714: "2 GB di memoria RAM (consigliati 4 GB o più)",
  label715: "Copia URL dell'area download",
  label716: "Copia URL del player web",
  label717: "Supporto",
  label718: "Per ottenere <font color=#FFFFFFF'>istruzioni</font> su come utilizzare la piattaforma e <font color=#FFFFFFF'>domande frequenti</font>, per favore",
  label719: "Aiuto e Domande frequenti",
  label720: "Ulteriori opzioni",
  label721: "Ulteriori informazioni",
  label722: "Certificato",
  label723: "URL dell'area download copiato con successo.",
  label724: "URL del player web copiato con successo.",
  label725: "Aggiorna, annulla modifiche",
  label726: "Ripristina la personalizzazione per tutti gli utenti",
  label727: "Scarica certificato",
  label728: "certificato_freedom",
  label729: "Informazioni",
  label730: "Sistema operativo Mac OS Catalina (10.15.7) o successivo (compatibile anche con versioni precedenti a Catalina ma con aggiornamento manuale del programma)",
  label731: "Musica",
  label732: "Vuoi effettuare il logout?",
  label733: "Contattaci tramite ticket",
  label734: "Contattaci su WhatsApp",
  label735: "Utente sospeso dall'amministratore",
  label736: "Sospendi",
  label737: "Attiva",
  label738: "Sospeso dall'amministratore",
  label739: "Utente attivato con successo",
  label740: "Utente sospeso con successo",
  label741: "Errore, qualcosa è andato storto nell'attivazione dell'utente",
  label742: "Errore, qualcosa è andato storto nella sospensione dell'utente",
  label743: "Playlist preferite",
  label744: "Sospendi",
  label745: "Attiva",
  label746: "Vuoi sospendere temporaneamente questo utente?",
  label747: "Vuoi attivare questo utente?",
  label748: "Autorizzazioni dell'app",
  label749: "Permesso attivato correttamente",
  label750: "Permesso disattivato correttamente",
  label751: "Errore nell'attivazione del permesso",
  label752: "Errore nella disattivazione del permesso",
  label753: "Richiedi PIN attivato",
  label754: "Richiedi PIN disattivato",
  label755: "Errore nell'attivazione della richiesta di PIN",
  label756: "Errore nella disattivazione della richiesta di PIN",
  label757: "Richiedi PIN, Attivato",
  label758: "Richiedi PIN, Disattivato",
  label759: "Aggiungi",
  label760: "La playlist è stata aggiunta ai preferiti con successo",
  label761: "Non è stata aggiunta perché la playlist è già presente nei preferiti",
  label762: "Errore, qualcosa è andato storto nell'aggiunta della playlist ai preferiti",
  label763: "Aggiunta in corso...",
  label764: "Aggiungi alle playlist preferite",
  label765: "Alias",
  label766: "Errore, la password contiene spazi vuoti (non consentiti).",
  label767: "Errore, è obbligatorio confermare la password.",
  label768: "Permessi dell'applicazione",
  label769: "Licenze",
  label770: "Utenti sospesi",
  label771: "La lingua dell'applicazione è stata cambiata con successo in Portoghese.",
  label772: "Errore, qualcosa è andato storto nel cambiare la lingua dell'applicazione in Portoghese.",
  label773: "Interpreti Bloccati",
  label774: "Aggiungi agli interpreti bloccati",
  label775: "Inserisci il nome dell'interprete da cercare...",
  label776: "L'interprete è stato aggiunto con successo agli interpreti bloccati",
  label777: "L'interprete non è stato aggiunto, è già presente negli interpreti bloccati",
  label778: "Errore, qualcosa è andato storto nell'aggiungere l'interprete agli interpreti bloccati",
  label779: "L'interprete",
  label780: "è stato eliminato con successo dagli interpreti bloccati",
  label781: "Errore, qualcosa è andato storto nell'eliminare l'interprete da questa lista",
  label782: "Vuoi eliminare dagli interpreti bloccati",
  label783: "Sarà eliminato l'interprete",
  label784: "dagli interpreti bloccati",
  label785: "Impostazioni",
  label786: "Abbonamento non valido, contattare il fornitore del servizio",
  label787: `Errore, hai raggiunto il limite degli account contrattati.`,
  label788: `Errore, qualcosa è andato storto durante la creazione dell'utente.`,
  label789: "Non hai il permesso di creare, modificare o eliminare utenti.",
  label790: "Non hai il permesso di accedere o visualizzare le licenze.",
  label791: "Non hai il permesso di ripristinare la personalizzazione per tutti gli utenti.",
  label792: "Non hai il permesso di visualizzare la playlist preferita di un utente.",
  label793: "Non hai il permesso di aggiungere o rimuovere la playlist preferita di un utente.",
  label794: "Non hai il permesso di visualizzare il pianificatore di playlist di un utente.",
  label795: "Non hai il permesso di aggiungere, modificare o eliminare playlist nel pianificatore di playlist di un utente.",
  label796: "Non hai il permesso di visualizzare il pianificatore di annunci di un utente.",
  label797: "Non hai il permesso di aggiungere, modificare o eliminare annunci nel pianificatore di annunci di un utente.",
  label798: "Non hai il permesso di clonare utenti.",
  label799: "Non hai il permesso di accedere alle preferenze dell'utente.",
  label800: "Non hai il permesso di accedere alla personalizzazione dell'utente.",
  label801: "Non hai il permesso di accedere ai permessi delle app dell'utente.",
  label802: "Non hai il permesso di sospendere un utente.",
  label803: "Non hai il permesso di visualizzare le playlist dell'amministratore.",
  label804: "Non hai il permesso di creare, modificare o eliminare le playlist dell'amministratore.",
  label805: "Non hai il permesso di visualizzare le playlist preferite dell'amministratore.",
  label806: "Non hai il permesso di aggiungere o rimuovere le playlist preferite dell'amministratore.",
  label807: "Non hai il permesso di visualizzare gli stili dell'amministratore.",
  label808: "Non hai il permesso di creare, modificare o eliminare gli stili dell'amministratore.",
  label809: "Non hai il permesso di accedere al gestore delle canzoni.",
  label810: "Non hai il permesso di visualizzare gli interpreti bloccati.",
  label811: "Non hai il permesso di aggiungere o rimuovere interpreti bloccati.",
  label812: "Non hai il permesso di visualizzare gli annunci caricati.",
  label813: "Non hai il permesso di caricare o eliminare annunci.",
  label814: "Non hai il permesso di accedere al supporto e ai download.",
  label815: "Modifica selezione",
  label816: "Seleziona tutto",
  label817: "Supervisori",
  label818: "Crea o modifica i supervisori e limita le loro azioni.",
  label819: "Le autorizzazioni sono state salvate con successo.",
  label820: "Errore, si è verificato un problema durante il salvataggio delle autorizzazioni.",
  label821: "Supervisore sospeso dall'amministratore.",
  label822: "Lo stile è stato cambiato per le canzoni selezionate.",
  label823: "Le canzoni selezionate sono state cambiate allo stile predefinito.",
  label824: "Le canzoni selezionate sono state aggiunte alle playlist selezionate.",
  label825: "Le canzoni selezionate sono state contrassegnate come esplicite con successo.",
  label826: "Le canzoni selezionate sono state rimosse come esplicite con successo.",
  label827: "Le canzoni selezionate sono state contrassegnate come 'mi piace' con successo.",
  label828: "Le canzoni selezionate sono state rimosse come 'mi piace' con successo.",
  label829: "Le canzoni selezionate sono state contrassegnate come 'non desiderate' con successo.",
  label830: "Le modifiche alle canzoni selezionate sono state eliminate.",
  label831: "Errore, si è verificato un problema durante il cambio di stile delle canzoni.",
  label832: "Errore, si è verificato un problema durante il cambio delle canzoni allo stile predefinito.",
  label833: "Errore, si è verificato un problema durante l'aggiunta delle canzoni alle playlist.",
  label834: "Errore, si è verificato un problema durante la contrassegnazione delle canzoni come esplicite.",
  label835: "Errore, si è verificato un problema durante la rimozione della contrassegnazione delle canzoni come esplicite.",
  label836: "Errore, si è verificato un problema durante la contrassegnazione delle canzoni come 'mi piace'.",
  label837: "Errore, si è verificato un problema durante la rimozione della contrassegnazione delle canzoni come 'mi piace'.",
  label838: "Errore, si è verificato un problema durante la contrassegnazione delle canzoni come 'non desiderate'.",
  label839: "Errore, si è verificato un problema durante l'eliminazione delle modifiche alle canzoni.",
  label840: "Scegli un'azione...",
  label841: "Applicazione...",
  label842: "Applica",
  label843: "Cambia a uno stile utente",
  label844: "Cambia allo stile predefinito",
  label845: "Aggiungi a una playlist manuale",
  label846: "Contrassegna come esplicito",
  label847: "Rimuovi contrassegno esplicito",
  label848: "Contrassegna come mi piace",
  label849: "Rimuovi contrassegno mi piace",
  label850: "Contrassegna come non desiderato",
  label851: "Elimina modifiche",
  label852: "Canzoni selezionate",
  label853: "Azione da eseguire",
  label854: "Permessi",
  label855: "Errore, qualcosa è andato storto durante la modifica del supervisore",
  label856: "Modifica il supervisore",
  label857: "Osservazione",
  label858: "Errore, il campo del nome utente è obbligatorio",
  label859: "Errore, hai raggiunto il limite di supervisori consentiti.",
  label860: "Errore, qualcosa è andato storto durante la creazione del supervisore.",
  label861: "Crea supervisore",
  label862: "Permessi per l'utilizzo del pannello di controllo, premi il pulsante di salvataggio per applicare le modifiche.",
  label863: "Il limite di supervisori che puoi creare è di 5.",
  label864: "Digita il nome del supervisore da cercare...",
  label865: "Errore, qualcosa è andato storto durante l'eliminazione del supervisore",
  label866: "Vuoi eliminare il supervisore",
  label867: "Il supervisore sarà eliminato",
  label868: "Vuoi sospendere temporaneamente questo supervisore?",
  label869: "Vuoi attivare questo supervisore?",
  label870: `Preparazione dell'annuncio...` 
};

export default it_IT;