import _logo from "../assets/images/mysound.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const MYSOUND = {
    logo: _logo,
    name: 'MySound',
    favicon: AWS_BUCKET+'/favicon/mysound.ico',
    web: 'www.mysound.ee',
    supportContact: 'info@mysound.ee',
    url_downloads: null,
    url_web_player_p: 'https://webplayer.mysound.ee',
    url_web_player_t: 'https://webplayertest.megaplayer.me/session/signIn/4',
    url_install_win: AWS_BUCKET+'/installers/install_mysound_',
    url_install_macos: null,
    url_google_play_store: 'https://play.google.com/store/apps/details?id=com.mysound.mysound',
    url_aptoide: null,
    url_apk_x32: AWS_BUCKET+'/installers/app_mysound_x32.apk',
    url_apk_x64: AWS_BUCKET+'/installers/app_mysound_x64.apk',
    url_support_i: 'https://mysound.ee/kontaktuus/',
    url_support_m: 'https://mysound.ee/kontaktuus/',
    url_whatsapp: null,
    url_certificate: AWS_BUCKET+'/ks_certificate/mysound_certificado_freedom.pdf'
}

export default MYSOUND;