import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Button,
  Text,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { UserGroup } from "../../models";
import { withRouter } from "react-router";
import { withToastManager } from "react-toast-notifications";
import { observable } from "mobx";

@observer
class GroupsNewModal extends Component {
  @observable invalid = [];
  @observable group;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.group = this.newGroup();
  }

  clearForm = () => {
    this.group = this.newGroup();
  };

  newGroup = () => {
    const grupoStore = this.props.store.groups;
    const attrs = {
      name: "",
      description: "",
      deleted: 0,
    };
    return new UserGroup(attrs, grupoStore);
  };

  handleChange = (sender, value, name, validation) => {
    this.group[name] = value;
    if (validation) {
      if (validation.type === "error") {
        let found = this.invalid.find((i) => i.name === name);
        if (!found) {
          this.invalid.push({ name: name, ...validation });
        }
      } else {
        this.invalid = this.invalid.filter((i) => i.name !== name);
      }
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.group.save().andThen((res, responseError) => {
      if (responseError) {
        toastManager.add(this.props.store.language.label224, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        toastManager.add(this.props.store.language.label225, {
          appearance: "success",
          autoDismiss: true,
        });
        this.props.onSave && this.props.onSave();
        this.handleClose();
      }
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mb-2"
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label83}
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.invalid.length > 0 || this.group.name === ""}
              className="is-pulled-right px-3 mb-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label82}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "340px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            className="mb-1"
          >
            {this.props.store.language.label139}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            label={this.props.store.language.label53}
            labelNote={this.props.store.language.label76}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "4px"}
            weight="medium"
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput="20px"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              name="name"
              value={this.group.name}
              validate={(value) => value.length > 0}
              required
              onChange={this.handleChange}
            />
          </Field>
          <Field
            label={this.props.store.language.label79}
            labelNoteOption={this.props.store.language.label78}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "4px"}
            weight="medium"
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput="20px"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type="text"
              value={this.group.description}
              name="description"
              onChange={this.handleChange}
            />
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

GroupsNewModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

GroupsNewModal.defaultProps = {
  onSave: null,
  onClose: null,
};

export default withRouter(withStore(withToastManager(GroupsNewModal)));
