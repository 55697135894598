import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Text,
  Paragraph,
} from "ks_storybook";
import { EmptyContent, DeleteConfirmationModal } from "../Common";
import { PPClocksRow, PPBlocksModal, PPClocksEditModal } from ".";
import { withToastManager } from "react-toast-notifications";

@observer
class PPClocksManagerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: null,
      currentPPClock: null,
      showDeletedModal: false,
      showEditModal: false,
      showEyeModal: false,
    };
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleModalBlocksOpen = (ppClocks) => {
    this.setState({ showEyeModal: true, currentPPClock: ppClocks });
  };

  handleModalBlocksClose = () => {
    this.setState({ showEyeModal: false, currentPPClock: null });
  };

  handleModalEditOpen = (ppClocks) => {
    this.setState({ showEditModal: true, currentPPClock: ppClocks });
  };

  handleModalEditClose = () => {
    this.setState({ showEditModal: false, currentPPClock: null });
  };

  handleModalEditSave = () => {
    this.setState({ showEditModal: false, currentPPClock: null });
    this.props.onReload && this.props.onReload();
  };

  handleShowDeleteConfirmationModal = (ppClocks) => {
    this.setState({ showDeletedModal: true, currentPPClock: ppClocks });
  };

  handleCloseDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentPPClock: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentPPClock: null });
    this.props.onReload && this.props.onReload();
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  filterItems = (array, search) => {
    if (search != null) {
      array = array.filter(
        (el) => el.content.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return array;
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  renderPPClocksRow = () => {
    const { search } = this.state;
    const arrayPPClocks = this.filterItems(
      this.props.ppClocks.toArray(),
      search
    );
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {arrayPPClocks.length !== 0
            ? arrayPPClocks.map((ppClocks) => (
                <PPClocksRow
                  ppClocks={ppClocks}
                  onDeleteClick={this.handleShowDeleteConfirmationModal}
                  onEditClick={this.handleModalEditOpen}
                  onEyeClick={this.handleModalBlocksOpen}
                />
              ))
            : this.renderEmptyContent()}
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { ppClocks } = this.props;
    if (!ppClocks) return this.renderEmptyContent();
    return !ppClocks.isOk() ? this.renderLoader() : this.renderPPClocksRow();
  };

  render() {
    const { currentPPClock, showDeletedModal, showEyeModal, showEditModal } =
      this.state;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "50%" : "auto"}
          invert
        >
          <ModalHeader>
            <Text
              upper
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {this.props.store.language.label443}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="my-1"
              lineBreak
            >
              {this.props.store.language.label444}
            </Text>
            {this.renderHeader()}
          </ModalContent>
          <ModalFooter />
        </Modal>
        {showDeletedModal && (
          <DeleteConfirmationModal
            currentObj={currentPPClock}
            onClose={this.handleCloseDeleteConfirmationModal}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={
              this.props.store.language.label445 +
              " " +
              currentPPClock.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label446 +
              " " +
              currentPPClock.name +
              "."
            }
            title={
              this.props.store.language.label447 +
              " " +
              currentPPClock.name +
              "?"
            }
            subtitle={
              this.props.store.language.label448 +
              " " +
              currentPPClock.name +
              "."
            }
          />
        )}
        {showEyeModal && (
          <PPBlocksModal
            currentPPClock={currentPPClock}
            onClose={this.handleModalBlocksClose}
          />
        )}
        {showEditModal && (
          <PPClocksEditModal
            ppClocks={currentPPClock}
            mediaTypes={this.props.mediaTypes}
            onSave={this.handleModalEditSave}
            onClose={this.handleModalEditClose}
          />
        )}
      </React.Fragment>
    );
  }
}
PPClocksManagerModal.propTypes = {
  ppClocks: PropTypes.object,
  mediaTypes: PropTypes.Array,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
};

PPClocksManagerModal.defaultProps = {
  ppClocks: null,
  mediaTypes: [],
  onClose: null,
  onReload: null,
};

export default withToastManager(withStore(PPClocksManagerModal));
