import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Text,
  Panel,
  Button,
  Toggle,
  Field,
  Loader,
  Paragraph,
  Columns,
  Column,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import {
  AdPlanRow,
  AdPlanModal,
  AdBatchesEyeModal,
  AdDeletedPlansModal,
  AdPlanEditModal,
} from "../../../../Components/Plans";
import { Select } from "../../../../Components/Select";
import { withToastManager } from "react-toast-notifications";
import moment from "moment";

@observer
class AdsPlanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: this.getAccounts(),
      ads: this.getAdAll(),
      userId: this.props.store.getSingleUser()
        ? this.props.store.loggedInUser.id
        : null,
      showModal: false,
      showAdBatchesEyeModel: false,
      showAdDeletedPlansModal: false,
      showAdPlanEditModal: false,
      currentAdPlans: null,
      adsPlans: this.props.store.getSingleUser()
        ? this.getAdsPlans(this.props.store.loggedInUser.id)
        : null,
      datesSelected: [],
    };

    this.weekdays = this.props.store.language.label61.split(",");
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label39;
  }

  getAdAll = () => {
    return this.props.store.ads.fetchAll();
  };

  getAccounts = () => {
    return this.props.store.users.fetchAccounts();
  };

  getOptions = (accounts) => {
    return accounts
      .toArray()
      .map((account) => ({ value: account.id, label: account.username }));
  };

  handleUserChange = (value) => {
    const originId = value && value.value;
    if (originId) {
      this.setState({ userId: originId });
      this.setState({ adsPlans: this.getAdsPlans(originId) });
    } else {
      this.setState({ userId: null });
      this.setState({ adsPlans: null });
    }
    this.setState({ datesSelected: [] });
  };

  handleChange = (sender, name, value) => {
    const actionPlaner = value ? 1 : 0;
    const messageSuccess =
      actionPlaner == 1
        ? this.props.store.language.label314
        : this.props.store.language.label315;
    const messageError =
      actionPlaner == 1
        ? this.props.store.language.label316
        : this.props.store.language.label317;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_ads_planner_on_user",
        "?user_id=" + this.state.userId + "&ads_planner_on=" + actionPlaner
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleEditModalSave = () => {
    this.setState({ showAdPlanEditModal: false, currentAdPlans: null });
    this.handleReload();
  };

  handleEditModalOpen = (plan) => {
    this.setState({ showAdPlanEditModal: true, currentAdPlans: plan });
  };

  handleEditModalClose = () => {
    this.setState({ showAdPlanEditModal: false });
  };

  handleEyeBatchesModelOpen = (plan) => {
    this.setState({ showAdBatchesEyeModel: true, currentAdPlans: plan });
  };

  handleEyeBatchesModelClose = () => {
    this.setState({ showAdBatchesEyeModel: false, currentAdPlans: null });
  };

  handleAdDeletedPlansModalOpen = (plan) => {
    this.setState({ showAdDeletedPlansModal: true, currentAdPlans: plan });
  };

  handleAdDeletedPlansModalClose = () => {
    this.setState({ showAdDeletedPlansModal: false, currentAdPlans: null });
  };

  handleAdDeletedPlansModalAccept = () => {
    this.setState({ showAdDeletedPlansModal: false, currentAdPlans: null });
    this.handleReload();
  };

  handleModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  dateToKasimuDate = (wd) => {
    return wd == 0 ? 7 : wd;
  };

  isWeekDay = (plan, weekDay) => {
    if (plan.week_day && plan.week_day == weekDay) {
      return true;
    }
    if (plan.exact_day) {
      const wd = moment(plan.exact_day, "DD/MM/YYYY");
      const d = wd.day();
      return this.dateToKasimuDate(d) == weekDay;
    }
    return false;
  };

  handleReload = () => {
    this.setState({ adsPlans: this.getAdsPlans(this.state.userId) });
  };

  handleSave = () => {
    this.setState({
      adsPlans: this.getAdsPlans(this.state.userId),
      showModal: false,
    });
  };

  getAdsPlans = (value) => {
    return this.props.store.advertisingGuidelines.getGuidelinesUser(value);
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderToogle = (plan) => {
    return (
      <React.Fragment>
        <div
          className={this.props.store.viewMobile ? "py-2" : "py-1"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="flex items-center">
            <Toggle
              noIcons
              onChange={this.handleChange}
              checked={plan.ads_planner_on ? true : false}
            />
            <div className="ml-2 mr-2">
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                color="special"
              >
                {plan.ads_planner_on
                  ? this.props.store.language.label318
                  : this.props.store.language.label319}
              </Text>
            </div>
          </div>
          {this.props.store.getSingleUser() && this.renderButton()}
        </div>
      </React.Fragment>
    );
  };

  renderButton = () => {
    return (
      <React.Fragment>
        <div className="is-pulled-right my-1">
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            onClick={this.handleModalOpen}
          >
            <strong>{this.props.store.language.label183}</strong>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  renderHeaderOk = () => {
    const { adsPlans } = this.state;
    const options = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const arrayPlans = adsPlans.toArray();
    return (
      <React.Fragment>
        {arrayPlans.length === 0 && (
          <div className="pb-2">{this.renderButton()}</div>
        )}

        {arrayPlans.length > 0 && this.renderToogle(arrayPlans[0])}

        <Panel
          color="gray"
          padding={this.props.store.viewMobile ? "23px 20px" : "12px 10px"}
        >
          {arrayPlans.length === 0 && this.renderEmptyContent()}
          {arrayPlans.length > 0 &&
            options.map((i) => this.renderFrame(arrayPlans, i))}
        </Panel>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { adsPlans, ads } = this.state;
    if (!adsPlans && ads) return this.renderEmptyContent();
    return !adsPlans.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getTitleForWeekDay = (weekDay) => {
    const titles = {
      1: this.weekdays[1],
      2: this.weekdays[2],
      3: this.weekdays[3],
      4: this.weekdays[4],
      5: this.weekdays[5],
      6: this.weekdays[6],
      7: this.weekdays[0],
      8: this.props.store.language.label349,
      9: this.props.store.language.label350,
      10: this.props.store.language.label351,
    };
    return titles[weekDay];
  };

  renderFrame = (plans, weekDay) => {
    return (
      <React.Fragment>
        <Panel
          color="grayDark"
          className="mt-1"
          padding={this.props.store.viewMobile ? "10px 25px" : "5px 15px"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Text
                weight="strong"
                size={this.props.store.viewMobile ? "xl" : "lg"}
              >
                <strong className="has-text-light">
                  {this.getTitleForWeekDay(weekDay)}
                </strong>
              </Text>
            </div>
            <Button
              name="arrow-down"
              kind="link"
              color="basic"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              icon={
                this.state.datesSelected.some((e) => e == weekDay)
                  ? "chevron-down"
                  : "chevron-up"
              }
              space
              onClick={() => {
                let valid = this.state.datesSelected.indexOf(weekDay);

                if (valid >= 0) {
                  this.setState({
                    datesSelected: this.state.datesSelected.filter(
                      (i) => i !== weekDay
                    ),
                  });
                } else {
                  this.setState({
                    datesSelected: [...this.state.datesSelected, weekDay],
                  });
                }
              }}
            />
          </div>
        </Panel>
        {this.state.datesSelected.some((e) => e == weekDay)
          ? null
          : plans
              .filter((p) => this.isWeekDay(p, weekDay))
              .map((p) => (
                <AdPlanRow
                  plan={p}
                  onDeleteClick={this.handleAdDeletedPlansModalOpen}
                  onEyeClick={this.handleEyeBatchesModelOpen}
                  onEditClick={this.handleEditModalOpen}
                />
              ))}
      </React.Fragment>
    );
  };

  renderSelect = () => {
    const { accounts } = this.state;
    const optionsSelect = this.getOptions(accounts);
    return (
      <React.Fragment>
        <Panel
          className="my-2"
          color="gray"
          padding={!this.props.store.viewMobile && "22px 19px"}
        >
          <Columns isMultiline>
            <Column
              isSize={{
                mobile: 12,
                tablet: 12,
                desktop: this.state.userId ? 8 : 12,
              }}
            >
              <Field
                upper
                marginH={this.props.store.viewMobile && "10px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                label={this.props.store.language.label17}
                labelNote={this.props.store.language.label53}
              >
                <Select
                  onChange={this.handleUserChange}
                  placeholder={this.props.store.language.label175}
                  isClearable
                  options={optionsSelect}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </Field>
            </Column>
            {this.state.userId ? (
              <Column
                isSize={{ mobile: 12, tablet: 12, desktop: 4 }}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  paddingTop: !this.props.store.viewMobile && "0px",
                }}
              >
                <div className="is-row">
                  <Button
                    upper
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-3"
                    onClick={this.handleModalOpen}
                  >
                    <strong>{this.props.store.language.label183}</strong>
                  </Button>
                  <Button
                    space
                    kind="link"
                    color="orange"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    icon="redo-alt"
                    onClick={this.handleReload}
                  />
                </div>
              </Column>
            ) : null}
          </Columns>
        </Panel>
      </React.Fragment>
    );
  };

  render() {
    const {
      userId,
      ads,
      showModal,
      showAdBatchesEyeModel,
      showAdDeletedPlansModal,
      showAdPlanEditModal,
      currentAdPlans,
    } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
              >
                {this.props.store.language.label39}
              </Text>
            )}
            {/* <div
              style={{ maxWidth: "600px" }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label182}
              </Paragraph>
            </div> */}
            {this.props.store.getSingleUser() ? null : this.renderSelect()}
            {this.renderHeader()}
          </Column>
        </Columns>
        {showModal && (
          <AdPlanModal
            userId={userId}
            ads={ads.toArray()}
            onClose={this.handleModalClose}
            onSave={this.handleSave}
          />
        )}
        {showAdBatchesEyeModel && (
          <AdBatchesEyeModal
            currentAdPlans={currentAdPlans}
            onClose={this.handleEyeBatchesModelClose}
          />
        )}
        {showAdPlanEditModal && (
          <AdPlanEditModal
            currentAdPlans={currentAdPlans}
            userId={userId}
            ads={ads.toArray()}
            onClose={this.handleEditModalClose}
            onSave={this.handleEditModalSave}
          />
        )}
        {showAdDeletedPlansModal && (
          <AdDeletedPlansModal
            currentAdPlans={currentAdPlans}
            onClose={this.handleAdDeletedPlansModalClose}
            onAccept={this.handleAdDeletedPlansModalAccept}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withToastManager(withStore(AdsPlanner));
