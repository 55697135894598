import { Store } from "ks_storybook";
import { PPTrademarks } from "../models";

class PPTrademarksStore extends Store {
  model = PPTrademarks;

  fetchAll() {
    return this.search({}, "pp_trademarks_all", true, "/pp_trademarks");
  }
}

export default PPTrademarksStore;
