import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Layout,
  LayoutNavbar,
  LayoutNavbarStart,
  LayoutNavbarEnd,
  LayoutContent,
  Sidebar,
} from "ks_storybook";
import { SignIn } from "../Session";
import { ControlPanelRouter, ControlPanelMenu } from "../ControlPanel";
import LoggedUserDropdown from "./components/LoggedUserDropdown";
import Toast from "./components/Toast";

// remove this later, when all routes exist -----------
//const FakeContent = withRouter(props => <div>{props.location.pathname}</div>);

@observer
class AppRouter extends Component {
  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.checkInformation();
    }, this.props.store.refreshTime);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  checkInformation = () => {
    if (!this.props.store.failsafeMode) {
      const supervisors = this.props.store.getSupervisors();
      let supervisor_id = 0;
      if (supervisors) supervisor_id = supervisors.id;
      this.props.store.kasimuAPIClient
        .search("/ks_connection_log_webpanel/check_information_webpanel", {
          supervisor_id: supervisor_id,
        })
        .then(
          (res) => {
            if (res.results.answer_code > 1) {
              this.handleSignOut();
            } else {
              this.props.store.loadPermissions();
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleSignOut = () => {
    this.props.store.signOut();
  };

  render() {
    const path = this.props.match.path;
    const root = "/controlpanel/";

    if (this.props.store.isLoggedIn || this.props.store.isLoggingIn) {
      const uiSettings = this.props.store.ui.settings;

      if (uiSettings.isEmpty()) return null;

      const user = this.props.store.loggedInUser;

      return (
        <React.Fragment>
          <Layout
            showSide={this.props.store.ui.showMainSide}
            noNavbar
            style={{ background: "#121212" }}
          >
            {/* <LayoutSide> */}
            <Sidebar>
              <Switch>
                <Route path={`${path}${root}`} component={ControlPanelMenu} />
              </Switch>
            </Sidebar>
            {/* </LayoutSide> */}
            <LayoutContent>
              <LayoutNavbar
                logo={this.props.store.getLogo()}
                style={{ background: "#121212" }}
              >
                <LayoutNavbarStart fillWidth justifyContent />
                <LayoutNavbarEnd fillWidth>
                  <LoggedUserDropdown />
                  {/* <div>
                    <Text size="md" className="mr-6">{'Soporte técnico: +34661951132 -- Email: soporte@kasimu.com'}</Text>
                  </div>
                  <div>
                    <Text size="sm" lead>
                      {user.username}
                    </Text>
                  </div>
                  <div>
                  <LoggedUserDropdown />
                  </div> */}
                </LayoutNavbarEnd>
              </LayoutNavbar>
              <Switch>
                <Route
                  exact
                  path={`${path}${root}`}
                  component={ControlPanelRouter}
                />
                <ControlPanelRouter defaultComponent={ControlPanelRouter} />
                <Redirect
                  to={`${path}${root}`}
                  component={ControlPanelRouter}
                />
              </Switch>
            </LayoutContent>
          </Layout>
          <Toast />
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Redirect to={`/session/signIn`} component={SignIn} />
      </Switch>
    );
  }
}

export default withStore(withRouter(AppRouter));
