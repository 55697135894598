import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import { Text, Panel, Button, Loader } from "ks_storybook";
import { UserLog } from "../../../../Components/Activity";
import { EmptyContent } from "../../../../Components/Common";
import { array } from "prop-types";

@observer
class IndividualUserActivityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      lastConnection: this.getLastConnection(),
      seeMore: true,
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label34;
  }

  getLastConnection = () => {
    return this.props.store.users.fetchAccountLastConnection(this.props.store.lang);
  };

  handleUpdating = () => {
    this.setState({ isUpdating: true }, () => {
      this.setState({ lastConnection: this.getLastConnection() });
      this.state.lastConnection.andThen(() => {
        this.setState({ isUpdating: false });
      });
    });
    this.props.store.ui.destroy();
  };

  getPanel = () => {
    return (
        <Columns>
          <Column>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                size={this.props.store.viewMobile ? "sm" : "xl"}
                lead={this.props.store.viewMobile}
                weight="strong"
                multiline
              >
                {this.props.store.language.label472}
              </Text>
              {this.getButtomUpdate()}
            </div>
          </Column>
        </Columns>
    );
  };

  getButtomUpdate = () => {
    return (
      <Button
        className="is-pulled-right"
        kind="link"
        color="basic"
        size={this.props.store.viewMobile ? "xl" : "lg"}
        iconKasimu="refresh"
        onClick={this.handleUpdating}
        space
      />
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLastConnection = () => {
    const { lastConnection } = this.state;
    const array = lastConnection.toArray()[0]
    return (
      <div className={this.props.store.viewMobile ? "my-2" : "my-1"}>
        <UserLog account={array} />
      </div>
    );
  };

  renderHeader = () => {
    const { lastConnection } = this.state;
    if (!lastConnection) return this.renderEmptyContent();
    return !lastConnection.isOk() ? this.renderLoader() : this.renderLastConnection();
  };

  render() {
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
              >
                {this.props.store.language.label34}
              </Text>
            )}
            {this.getPanel()}
            <br />
            {this.renderHeader()}
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(IndividualUserActivityPage);
