import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Panel,
  Button,
  Columns,
  Column,
  TextInput,
} from "ks_storybook";
import { EmptyContent } from "../../../Components/Common";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../../Components/Screen";
import { PlaylistsFavoriteRow, PlaylistsFavoriteNewModal } from "../../../Components/Playlists";

@observer
class PlaylistsFavoriteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
      favoritePlaylists: this.getUserFavoritePlaylists(this.props.account.id),
      search: "",
    };
  }

  getUserFavoritePlaylists = (user_id) => {
    return this.props.store.userFavoritePlaylists.fetchAllParams(
      this.props.store.lang,
      user_id,
      false
    );
  };

  handleShowNewModal = () => {
    this.setState({
      showNewModal: true,
    });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleSaveNewModal = () => {
    this.handleReload();
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDeletePlaylistFavorite = (currentPlaylist) => {
    const { toastManager } = this.props;
    let messageSuccess = this.props.store.language.label580+" " +currentPlaylist.categories_playlists.alias +" "+this.props.store.language.label229;
    let messageError= this.props.store.language.label581+" " +currentPlaylist.categories_playlists.alias;
    this.props.store.kasimuAPIClient
      .delete(
        "/users_favorite_playlists/deleted_playlist_favorite/",
        "?id=" + currentPlaylist.id
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  getSearch = (obj, search) => {
    var arr = [];
    if (obj.length != 0) {
      arr = obj
      .toArray()
      .filter((e) =>
        e &&
        e.categories_playlists &&
        e.categories_playlists.alias &&
        e.categories_playlists.alias.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
      arr.sort(function (a, b) {
        var A = a.categories_playlists.alias.toUpperCase(); // Ignorar mayúsculas y minúsculas
        var B = b.categories_playlists.alias.toUpperCase(); // Ignorar mayúsculas y minúsculas
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        // los nombres son iguales
        return 0;
      });
    }
    return arr;
  };

  handleReload = () => {
    this.setState({
      showNewModal: false,
      favoritePlaylists: this.getUserFavoritePlaylists(this.props.account.id)
    });
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getPanel = () => {
    return (
      <Columns
        className={this.props.store.viewMobile && "my-2"}
        isMultiline
        style={{
          margin: !this.props.viewMobile && "6px 0px",
        }}
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile && "mr-2"}
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: !this.props.store.viewMobile && "6px",
            }}
          >
            <TextInput
              onChange={this.handleChangeSearch}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              placeholder={this.props.store.language.label586}
            />
          </div>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleShowNewModal}
            onClick={() =>
              this.props.store.getPermission(27, 27, () => {
                this.handleShowNewModal();
              })
            }
          >
            <strong>{this.props.store.language.label759}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.handleReload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderHeaderOk = () => {
    const { favoritePlaylists, search } = this.state;
    const arrayFavorites = this.getSearch(favoritePlaylists, search);
    return (
      <React.Fragment>
        {this.getPanel()}
        {arrayFavorites.length === 0 ? (
          <Panel
            color="gray"
            padding={this.props.store.viewMobile ? "23px 20px" : "12px 10px"}
          >
            {this.renderEmptyContent()}
          </Panel>
        ) : (
          <>
            {arrayFavorites.map((playlist) => (
              <PlaylistsFavoriteRow
                playlist={playlist}
                onDeleteClick={(...e) =>
                  this.props.store.getPermission(27, 27, () => {
                    this.handleDeletePlaylistFavorite(...e);
                  })
                }
              />
            ))}
          </>
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { favoritePlaylists } = this.state;
    if (!favoritePlaylists) return this.renderEmptyContent();
    return !favoritePlaylists.isOk()
      ? this.renderLoader()
      : this.renderHeaderOk();
  };

  renderScreen() {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label743}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  }

  render() {
    const { showNewModal } = this.state;
    const { account } = this.props;

    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "800px" : "90%"}
            height={this.props.store.viewMobile ? "640px" : "auto"}
          >
            <ModalHeader>
              <div className="flex items-center" style={{ flexWrap: "wrap" }}>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="black"
                  className="mr-2"
                >
                  {this.props.store.language.label743}
                </Text>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="regular"
                  className="mr-2"
                >
                  {account.username}
                </Text>
              </div>
            </ModalHeader>
            <ModalContent>{this.renderHeader()}</ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {showNewModal && (
          <PlaylistsFavoriteNewModal
            user_id={account.id}
            url={"add_playlist_to_favorite_for_user"}
            onClose={this.handleCloseNewModal}
            onSave={this.handleSaveNewModal}
          />
        )}
      </React.Fragment>
    );
  }
}

PlaylistsFavoriteModal.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
};

PlaylistsFavoriteModal.defaultProps = {
  account: null,
  onClose: null,
};

export default withStore(withToastManager(PlaylistsFavoriteModal));
