import { Store } from "ks_storybook";
import { PPAdvertisementsLog } from "../models";

class PPAdvertisementsLogStore extends Store {
  model = PPAdvertisementsLog;

  fetchRank(start_date, end_date, type_report, advertisements_id) {
    return this.search(
      {
        per_page: 10000,
        start_date: start_date,
        end_date: end_date,
        type_report: type_report,
        advertisements_id: advertisements_id,
      },
      "pp_advertisements_log_rank",
      true,
      "/pp_advertisements_log/get_rank_datetime"
    );
  }
}

export default PPAdvertisementsLogStore;
