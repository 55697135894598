import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  Panel,
  TextInput,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  GroupCard,
  GroupEditModal,
  GroupNewModal,
} from "../../../Components/Group";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../../Components/Screen";

@observer
class GroupsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      currentGroup: null,
      search: "",
    };
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleShowEditModal = (group) => {
    this.setState({ showEditModal: true, currentGroup: group });
  };

  handleReload = () => {
    this.props.onReload && this.props.onReload();
  };

  handleHideEditModal = () => {
    this.setState({ showEditModal: false, currentGroup: null });
  };

  handleShowDeleteModal = (group) => {
    this.setState({ showDeleteModal: true, currentGroup: group });
  };

  handleHideDeleteModal = () => {
    this.setState({ showDeleteModal: false, currentGroup: null });
  };

  handleDeleteGroup = () => {
    this.handleReload();
    this.setState({ showEditModal: false });
    this.handleHideDeleteModal();
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  getSearchGroup = (groups, search) => {
    var arr = [];
    if (groups.length != 0) {
      arr = groups.filter(
        (e) => e.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  getPanel = () => {
    return (
      <>
        <Columns isMultiline isVCentered className="my-1">
          <Column
            isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
            className="flex"
          >
            <div
              className={this.props.store.viewMobile && "mr-2"}
              style={{
                maxWidth: "700px",
                width: "100%",
                marginRight: !this.props.store.viewMobile && "6px",
              }}
            >
              <TextInput
                backgroundColor="grayDark"
                className="is-fullwidth"
                paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
                onChange={this.handleChangeSearch}
                placeholder={this.props.store.language.label137}
                name="search"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="search"
                colorIcon="gray"
                invert
              />
            </div>

            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className={`px-3`}
              onClick={this.handleShowNewModal}
            >
              <strong>{this.props.store.language.label82}</strong>
            </Button>
          </Column>
        </Columns>
      </>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getContainerStyle = () =>
    this.props.store.viewMobile
      ? {
          width: "100%",
          minHeight: "400px",
          overflowY: "auto",
          overflowX: "hidden",
          borderRadius: "16px",
        }
      : {};

  renderPaginator = (obj) => {
    if (obj.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={obj.totalPages}
            currentPage={obj.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderRowGroups = () => {
    const { groups } = this.props;
    const { search } = this.state;
    const arrayGroups = this.getSearchGroup(groups.toArray(), search);
    return (
      <React.Fragment>
        <div
          style={this.getContainerStyle()}
          className={this.props.store.viewMobile && "mt-2"}
        >
          {arrayGroups.length > 0
            ? arrayGroups.map((obj, idx) => (
                <GroupCard
                  group={obj}
                  onEditClick={this.handleShowEditModal}
                  onDeleteClick={this.handleShowDeleteModal}
                />
                // <div
                //   style={{
                //     padding: this.props.store.viewMobile
                //       ? "25px 30px"
                //       : "15px 20px",
                //     borderRadius: "10px",
                //     background: "#1F1F1F",
                //     marginBottom: "8px",
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "space-between",
                //     overflowWrap: "anywhere",
                //   }}
                // >
                //   <Columns isMobile isVCentered isMarginless isGapless>
                //     <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                //       <div className="flex" style={{ gap: 5 }}>
                //         <Text
                //           weight="medium"
                //           size={this.props.store.viewMobile ? "xl" : "lg"}
                //           lineBreak
                //         >
                //           {idx + 1}
                //         </Text>
                //         <Text
                //           color="special"
                //           size={this.props.store.viewMobile ? "xl" : "lg"}
                //           lineBreak
                //         >
                //           |
                //         </Text>
                //         <Text
                //           color="special"
                //           size={this.props.store.viewMobile ? "xl" : "lg"}
                //           lineBreak
                //         >
                //           {obj.name}
                //         </Text>
                //         {obj.description && (
                //           <>
                //             <Text
                //               color="special"
                //               size={this.props.store.viewMobile ? "xl" : "lg"}
                //               lineBreak
                //             >
                //               |
                //             </Text>
                //             <Text
                //               color="gray"
                //               size={this.props.store.viewMobile ? "xl" : "lg"}
                //               lineBreak
                //             >
                //               {obj.description}
                //             </Text>
                //           </>
                //         )}
                //       </div>
                //     </Column>
                //   </Columns>
                //   <div style={{ display: "flex" }}>
                //     <Button
                //       className="is-pulled-right ml-3"
                //       iconKasimu="pen"
                //       kind="link"
                //       color="basic"
                //       size={this.props.store.viewMobile ? "xl" : "lg"}
                //       lead
                //       space
                //       onClick={() => {
                //         this.handleShowEditModal(obj);
                //       }}
                //     />
                //     <Button
                //       data-for="trash"
                //       className="is-pulled-right ml-2"
                //       iconKasimu="trash"
                //       kind="link"
                //       color="basic"
                //       size={this.props.store.viewMobile ? "xl" : "lg"}
                //       lead
                //       space
                //       onClick={() => {
                //         this.handleShowDeleteModal(obj);
                //       }}
                //     />
                //   </div>
                // </div>
              ))
            : this.renderEmptyContent()}
        </div>
        {/* {
          <Text
            className="is-pulled-right mt-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            lineBreak
          >
            {this.props.store.language.label642 + groups.count}
          </Text>
        } */}

        {this.renderPaginator(groups)}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { groups } = this.props;
    if (!groups) return this.renderEmptyContent();
    return !groups.isOk() ? this.renderLoader() : this.renderRowGroups();
  };

  renderScreen() {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label36}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.getPanel()}
        {this.renderHeader()}
      </Screen>
    );
  }

  render() {
    const { showEditModal, showDeleteModal, currentGroup, showNewModal } =
      this.state;
    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "800px" : "90%"}
            height={this.props.store.viewMobile ? "540px" : "auto"}
          >
            <ModalHeader>
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="black"
              >
                {this.props.store.language.label36}
              </Text>
            </ModalHeader>
            <ModalContent>
              {this.getPanel()}
              {this.renderHeader()}
            </ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {showEditModal && (
          <GroupEditModal
            group={currentGroup}
            onSave={this.handleReload}
            onClose={this.handleHideEditModal}
          />
        )}
        {showDeleteModal && (
          <DeleteConfirmationModal
            currentObj={currentGroup}
            onClose={this.handleHideDeleteModal}
            onAccept={this.handleDeleteGroup}
            messageSuccess={
              this.props.store.language.label228 +
              " " +
              currentGroup.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label230 + " " + currentGroup.name
            }
            title={
              this.props.store.language.label231 + " " + currentGroup.name + "?"
            }
            subtitle={
              this.props.store.language.label232 + " " + currentGroup.name + "."
            }
          />
        )}
        {showNewModal && (
          <GroupNewModal
            onSave={this.handleReload}
            onClose={this.handleCloseNewModal}
          />
        )}
      </React.Fragment>
    );
  }
}

GroupsModal.propTypes = {
  groups: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
};

GroupsModal.defaultProps = {
  groups: null,
  onReload: null,
  onClose: null,
};

export default withStore(withToastManager(GroupsModal));
