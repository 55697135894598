import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelLeft, LevelRight } from "bloomer";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { UsersSongsEdit } from "../../models";

@observer
class FavClickModal extends Component {
  @observable usersSongsEditNew;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: false,
    };

    this.usersSongsEditNew = this.props.songs.users_songs_edit
      ? this.editUsersSongsEdit()
      : this.newUsersSongsEdit();
  }

  newUsersSongsEdit = () => {
    const usersSongsEditStore = this.props.store.usersSongsEdit;
    const attrs = {
      song_id: this.props.songs.id,
      singer_id: this.props.songs.singer.id,
      song_mood_id: this.props.songs.song_mood_id,
      song_styles_id: this.props.songs.song_styles_id,
      song_language_id: this.props.songs.song_lenguages_id,
      song_name: this.props.songs.name,
      bpm: this.props.songs.bpm,
      preference: this.props.songs.preference,
      remix: this.props.songs.remix,
      explicit: this.props.songs.explicit,
      origin_type: this.props.store.getSingleUser() ? 1 : 0,
      deleted: 0,
    };

    return new UsersSongsEdit(attrs, usersSongsEditStore);
  };

  editUsersSongsEdit = () => {
    const usersSongsEditStore = this.props.store.usersSongsEdit;
    const attrs = {
      id: this.props.songs.users_songs_edit.id,
      user_id: this.props.songs.users_songs_edit.user_id,
      song_id: this.props.songs.users_songs_edit.song_id,
      singer_id: this.props.songs.users_songs_edit.singer_id,
      song_mood_id: this.props.songs.users_songs_edit.song_mood_id,
      song_styles_id: this.props.songs.users_songs_edit.song_styles_id,
      song_language_id: this.props.songs.users_songs_edit.song_lenguages_id,
      song_name: this.props.songs.users_songs_edit.song_name,
      bpm: this.props.songs.users_songs_edit.bpm,
      preference: this.props.songs.users_songs_edit.preference,
      remix: this.props.songs.users_songs_edit.remix,
      explicit: this.props.songs.users_songs_edit.explicit,
      origin_type: this.props.store.getSingleUser() ? 1 : 0,
      deleted: 0,
    };

    return new UsersSongsEdit(attrs, usersSongsEditStore);
  };

  getUserFav = () => {
    if (this.usersSongsEditNew.preference == 0) return true;
    return false;
  };

  handleAccept = () => {
    const { toastManager } = this.props;
    this.usersSongsEditNew["preference"] = this.getUserFav()
      ? this.props.songs.preference
      : 0;
    this.setState({ isSaving: true }, () => {
      this.usersSongsEditNew.save().andThen((response, responseError) => {
        if (responseError) {
          toastManager.add(
            this.getUserFav()
              ? this.props.store.language.label562
              : this.props.store.language.label563,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        } else {
          toastManager.add(
            this.getUserFav()
              ? this.props.store.language.label564
              : this.props.store.language.label565,
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
          this.props.onAccept && this.props.onAccept();
        }
      });
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "540px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            lineBreak
          >
            {this.getUserFav()
              ? this.props.store.language.label558
              : this.props.store.language.label559}
            ?
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            lineBreak
            className="my-1"
          >
            {this.usersSongsEditNew.song_name}
            {this.getUserFav()
              ? ", " + this.props.store.language.label560
              : ", " + this.props.store.language.label561}
          </Text>
        </ModalContent>
        <ModalFooter>
          <Level isMobile>
            <LevelLeft />
            <LevelRight className="mb-2">
              {this.state.isSaving ? null : (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  onClick={this.handleClose}
                >
                  <strong>{this.props.store.language.label427}</strong>
                </Button>
              )}
              {this.state.isSaving ? (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  disabled
                  icon="spinner"
                  pulse
                >
                  <strong>{this.props.store.language.label81}</strong>
                </Button>
              ) : (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  onClick={this.handleAccept}
                >
                  <strong>{this.props.store.language.label493}</strong>
                </Button>
              )}
            </LevelRight>
          </Level>
        </ModalFooter>
      </Modal>
    );
  }
}

FavClickModal.propTypes = {
  songs: PropTypes.object,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
};

FavClickModal.defaultProps = {
  songs: null,
  onAccept: null,
  onClose: null,
};

export default withStore(withToastManager(FavClickModal));
