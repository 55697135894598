import { Model } from "ks_storybook";
import { computed, observable, action } from "mobx";

class User extends Model {
  urlRoot = "/users";

  configs;

  @action
  afterSetData() {
    if (!this.configs && this.preference) {
      this.configs = this._store.appStore.preferences.getFromData(
        this.preference,
        this._store.appStore.preferences
      );
    }
  }

  @computed
  get isMultiAccount() {
    if (!!this.franquisor_user_id) return false;

    return [38, 39].includes(this.suscription.product_id);
  }

  @computed
  get isFreeRights() {}

  saveAsAccount() {
    return this._store.saveAsAccount(this);
  }

  getRolName() {
    if (this.ks_rol) {
      return this.ks_rol.ks_rol.name;
    }
    return "";
  }

  getRolId() {
    if (this.ks_rol) {
      return this.ks_rol.ks_rol.id;
    }
    return "";
  }
}

export default User;
