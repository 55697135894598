import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import withStore from "../../hocs/withStore";
import {
  Text,
  Button,
  Panel,
  Loader,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  Columns,
  Column,
  Img,
} from "ks_storybook";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Screen } from "../../Components/Screen";
import MediaUser from "./MediaUser";

@observer
class UserLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: {
        actions: false,
      },
      titleScreen: "",
      titelScreenActions: "",
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label17;
  }

  componentDidUpdate() {
    const wavesurfer = this.props.store.ui.wavesurfer;
    if (wavesurfer) {
      wavesurfer.drawer.containerWidth =
        wavesurfer.drawer.container.clientWidth;
      wavesurfer.drawBuffer();
    }
  }

  componentWillUnmount() {
    this.props.store.ui.destroy();
  }

  handleStartPlayingClick = (indice, song_path) => {
    var that = this;
    that.props.store.ui.startPlaying(song_path, 1, indice);
  };

  handleTogglePlay = (song_path) => {
    var that = this;
    if (song_path) {
      if (that.props.store.ui.currentSongPath) {
        if (!this.props.store.ui.isPlaying) {
          this.props.store.ui.play();
        } else {
          this.props.store.ui.pause();
        }
      }
    }
  };

  getIconStatus = (status) => {
    const array = { 1: "user-check", 2: "user-slash" };
    return array[status];
  };

  getColorStatus = (status) => {
    const colors = { 1: "statusPublished", 2: "statusModified" };
    return colors[status];
  };

  getPlaylistsName = (account) => {
    var playlist_owner = null;
    var last_playlist_listened = null;
    if (account.cache_last_connection_app) {
      playlist_owner = account.cache_last_connection_app.playlist_owner
      last_playlist_listened = account.cache_last_connection_app.last_playlist_listened
    } else if (account.last_activity) {
      playlist_owner = account.last_activity.playlist_owner
      last_playlist_listened = account.last_activity.last_playlist_listened[0]
    }
    const name = {
      1: last_playlist_listened.alias,
      2: this.props.store.language.label631 + last_playlist_listened.name,
      3: this.props.store.language.label630 + last_playlist_listened.name,
    };
    return name[playlist_owner];
  };

  getIconPath = (account) => {
    var playlist_owner = null;
    var last_playlist_listened = null;
    if (account.cache_last_connection_app) {
      playlist_owner = account.cache_last_connection_app.playlist_owner
      last_playlist_listened = account.cache_last_connection_app.last_playlist_listened
    } else if (account.last_activity) {
      playlist_owner = account.last_activity.playlist_owner
      last_playlist_listened = account.last_activity.last_playlist_listened[0]
    }
    const icon_path = {
      1: last_playlist_listened.icon_path,
      2:
        last_playlist_listened.static == 1
          ? this.props.store.getBucketUrl() + "images/cp-m.png"
          : this.props.store.getBucketUrl() + "images/cp-a.png",
      3:
        last_playlist_listened.static == 1
          ? this.props.store.getBucketUrl() + "images/cp-m.png"
          : this.props.store.getBucketUrl() + "images/cp-a.png",
    };
    return this.props.store.replaceBucketUrl(icon_path[playlist_owner]);
  };

  getStatus = (account) => {
    var msg = "";
    if (account.cache_last_connection_app) {
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status === 2
        ? (msg = (
            <span className="danger">{this.props.store.language.label67}</span>
          ))
        : connection_type != null
        ? connection_type === 4
          ? (msg = (
              <span className="hibernating">{`${this.props.store.language.label96} `}</span>
            ))
          : connection_type === 3
          ? account.cache_last_connection_app.offline
            ? (msg = <span className="hibernating">{`${"Offline"}`}</span>)
            : (msg = (
                <span className="success">{`${this.props.store.language.label64} `}</span>
              ))
          : (msg = (
              <span className="danger">{`${this.props.store.language.label65} `}</span>
            ))
        : (msg = (
            <span className="">{`${this.props.store.language.label66} `}</span>
          ));
    } else {
      var conexion_type = account.last_activity && account.last_activity.conexion_type;
      account.status === 2
        ? (msg = (
            <span className="danger">{this.props.store.language.label67}</span>
          ))
        : conexion_type != null
        ? conexion_type === 4
          ? (msg = (
              <span className="hibernating">{`${this.props.store.language.label96} `}</span>
            ))
          : conexion_type === 1 || conexion_type === 3
          ? account.last_activity.offline
            ? (msg = <span className="hibernating">{`${"Offline"}`}</span>)
            : (msg = (
                <span className="success">{`${this.props.store.language.label64} `}</span>
              ))
          : (msg = (
              <span className="danger">{`${this.props.store.language.label65} `}</span>
            ))
        : (msg = (
            <span className="">{`${this.props.store.language.label66} `}</span>
          ));
    }


    return msg;
  };

  getStatusColor = (account) => {
    var color = "basic";
    if (account.cache_last_connection_app) {
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status >= 2
      ? (color = "orange")
      : connection_type != null
      ? connection_type === 4
        ? (color = "internal")
        : connection_type === 3
        ? account.cache_last_connection_app.offline
          ? (color = "warning")
          : (color = "confirm")
        : (color = "special")
      : (color = "basic");
    } else {
      var conexion_type = account.last_activity && account.last_activity.conexion_type;
      account.status >= 2
      ? (color = "orange")
      : conexion_type != null
      ? conexion_type === 4
        ? (color = "internal")
        : conexion_type === 1 || conexion_type === 3
        ? account.last_activity.offline
          ? (color = "warning")
          : (color = "confirm")
        : (color = "special")
      : (color = "basic");
    }
    return color;
  };

  getTooltipMsg = (account) => {
    var msg = "";
    if (account.cache_last_connection_app) {
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status >= 2
      ? (msg =
          account.status === 2
            ? this.props.store.language.label67
            : this.props.store.language.label738)
      : connection_type != null
      ? connection_type === 4
        ? (msg = this.props.store.language.label96)
        : connection_type === 3
        ? account.cache_last_connection_app.offline
          ? (msg = "Offline")
          : (msg = this.props.store.language.label64)
        : (msg = this.props.store.language.label65)
      : (msg = this.props.store.language.label66);
    } else {
      var conexion_type =
      account.last_activity && account.last_activity.conexion_type;
      account.status >= 2
      ? (msg =
          account.status === 2
            ? this.props.store.language.label67
            : this.props.store.language.label738)
      : conexion_type != null
      ? conexion_type === 4
        ? (msg = this.props.store.language.label96)
        : conexion_type === 1 || conexion_type === 3
        ? account.last_activity.offline
          ? (msg = "Offline")
          : (msg = this.props.store.language.label64)
        : (msg = this.props.store.language.label65)
      : (msg = this.props.store.language.label66);
    }
    return msg;
  };
  
  getStatusMsg = (account) => {
    var msg = "";
    if (account.cache_last_connection_app) {      
      var OS = ['','Windows','Android','WebPlayer'];
      var currentTime = moment.utc(account.cache_last_connection_app.updated_at).format();
      var userTimeZone = moment.tz.guess();
      var localTime = moment.tz(currentTime, userTimeZone).format('dddd, DD MMMM YYYY, h:mm:ss a');      
      var log = localTime+ " | " +OS[account.cache_last_connection_app.os_number]+", "+this.props.store.companies[account.cache_last_connection_app.reseller_companies].name+" "+account.cache_last_connection_app.version_app_str;
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status >= 2
      ? (msg =
          account.status === 2
            ? this.props.store.language.label67
            : this.props.store.language.label738 + " | " + log)
      : connection_type != null
      ? connection_type === 4
        ? (msg = this.props.store.language.label96 + " | " + log)
        : connection_type === 3
        ? account.cache_last_connection_app.offline
          ? (msg = "Offline")
          : (msg = this.props.store.language.label64 + " | " + log)
        : (msg = this.props.store.language.label65 + " | " + log)
      : (msg = this.props.store.language.label66);
    } else {
      if (account.last_activity) {
        var currentTime = moment.utc(account.last_activity.updated_at).format();
        var userTimeZone = moment.tz.guess();
        var localTime = moment.tz(currentTime, userTimeZone).format('dddd, DD MMMM YYYY, h:mm:ss a'); 
        var log = localTime+" | " +account.last_activity.os;
        var conexion_type = account.last_activity && account.last_activity.conexion_type;
        account.status >= 2
        ? (msg =
            account.status === 2
              ? this.props.store.language.label67
              : this.props.store.language.label738 + " | " + log)
        : conexion_type != null
        ? conexion_type === 4
          ? (msg = this.props.store.language.label96 + " | " + log)
          : conexion_type === 1 || conexion_type === 3
          ? account.last_activity.offline
            ? (msg = "Offline")
            : (msg = this.props.store.language.label64 + " | " + log)
          : (msg = this.props.store.language.label65 + " | " + log)
        : (msg = this.props.store.language.label66);
      }
    }
    return msg;
  };

  getGrupName = (values) => {
    return values.map((o) => o.name).join(", ");
  };

  getLastPlaylistListened = (account) => {
    if (account.cache_last_connection_app) {
        return account.cache_last_connection_app.last_playlist_listened
    }
    return account.last_activity && account.last_activity.last_playlist_listened[0];
  };

  getLastSongListened= (account) => {
    if (account.cache_last_connection_app) {
        return account.cache_last_connection_app.last_song_listened
    }
    if (account.last_activity) 
    return account.last_activity && account.last_activity.last_song_listened;
  };

  getPlaybackStatus = (account) => {
    if (account.cache_last_connection_app) {
      if (account.cache_last_connection_app.playback_status == 1)
        return <span className="success">{`${this.props.store.language.label4} `}</span>
      else if (account.cache_last_connection_app.playback_status == 2)
        return <span className="danger">{`${this.props.store.language.label95} `}</span>
    }
    return null;
  };

  renderItemsRow(account, indice) {
    var last_playlist_listened = this.getLastPlaylistListened(account);
    var last_song_listened = this.getLastSongListened(account);
    var playback_status = this.getPlaybackStatus(account);
    const currentSongPath = this.props.store.ui.currentSongPath;
    const active =
      currentSongPath && last_song_listened
        ? last_song_listened.song.song_path == currentSongPath
        : null;
    const { handleStartPlayingClick, handleTogglePlay } = this;

    return (
      <Panel
        id={indice}
        color={"gray"}
        padding={
          this.props.store.viewMobile
            ? active
              ? "16px 30px 0"
              : "16px 30px"
            : "14px 10px"
        }
        style={{ borderRadius: !this.props.store.viewMobile && 5 }}
      >
        <Columns isMobile isVCentered isMarginless isGapless>
          <Column isSize={{ mobile: 10, desktop: 10, tablet: 10 }}>
            <Columns>
              <Column isSize={{ mobile: 12, desktop: 6, tablet: 6 }}>
                <div className="flex">
                  {this.props.store.viewMobile ? (
                    <div
                      className="flex items-center"
                      data-tip
                      data-for={`user-${account.id}`}
                    >
                      <Button
                        iconKasimu="user-solid"
                        kind="link"
                        color={this.getStatusColor(account)}
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        padding="0"
                        className="mr-2"
                      />
                      {this.props.store.viewMobile && (
                        <ReactTooltip
                          id={`user-${account.id}`}
                          place="bottom"
                          type="dark"
                          effect="float"
                        >
                          <span>{this.getTooltipMsg(account)}</span>
                        </ReactTooltip>
                      )}
                    </div>
                  ) : (
                    <div
                      className="flex items-center"
                      style={{
                        borderRadius: 5,
                        marginRight: 12,
                      }}
                    >
                      <Button
                        iconKasimu="user-solid"
                        kind="link"
                        color={this.getStatusColor(account)}
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        padding="6px 8px"
                      />
                    </div>
                  )}
                  <div>
                    <Text
                        color="whiteLight"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="regular"
                        multiline
                      >
                        {account.description && this.props.store.language.label765+": "+account.description}
                      </Text>
                    <div
                      className="is-row is-fullwidth"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        color="whiteLight"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="regular"
                        multiline
                      >
                        {this.getStatusMsg(account)}
                      </Text>
                    </div>
                    <div
                      className="is-row is-fullwidth"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        color="white"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="regular"
                        multiline
                      >
                        {account.groups.length === 0
                          ? null
                          : this.getGrupName(account.groups)}
                      </Text>
                    </div>
                  </div>
                </div>
              </Column>
              {this.props.store.viewMobile && (
                <Column isSize={{ mobile: 12, desktop: 6, tablet: 6 }}>
                  {last_playlist_listened && (
                    <div
                      className="flex"
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="flex items-center"
                        style={{ marginBottom: 4 }}
                      >
                        {/* <Button
                          iconKasimu="playlist-solid"
                          kind="link"
                          color="basic"
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          padding="0"
                        /> */}
                        <Img
                          height="25px"
                          width="25px"
                          src={this.getIconPath(account)}
                        />
                        <Text
                          className="ml-2"
                          color="white"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                          multiline
                        >
                          {this.getPlaylistsName(account)}
                        </Text>
                      </div>
                      {last_song_listened && (
                        <div className="flex items-center">
                          <Button
                            icon={
                              active && this.props.store.ui.isPlaying
                                ? "pause"
                                : "play"
                            }
                            kind="link"
                            color={active ? "primary" : "basic"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            padding="0"
                            onClick={
                              active && this.props.store.ui.showPlayer
                                ? () =>
                                    handleTogglePlay(
                                      last_song_listened.song.song_path
                                    )
                                : () =>
                                    handleStartPlayingClick(
                                      indice,
                                      last_song_listened.song.song_path
                                    )
                            }
                          />
                          <Text
                            className="ml-2"
                            color="special"
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            weight="medium"
                            multiline
                          >
                            {last_song_listened.song.name +
                              " - " +
                              last_song_listened.song.singer.name}
                          </Text>
                        </div>
                      )}
                      {playback_status && (
                        <div className="flex items-center">
                          <Text
                            color="special"
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            weight="medium"
                            multiline
                          >
                            {playback_status}
                          </Text>
                        </div>
                      )}
                    </div>
                  )}
                </Column>
              )}
            </Columns>
          </Column>
          <Column isSize={{ mobile: 2, desktop: 2, tablet: 2 }}>
            <div className="is-row" style={{ float: "right" }}>
              {this.props.store.viewMobile ? null : (
                <Button
                  iconKasimu="arrow-right"
                  kind="link"
                  color="special"
                  size={this.props.store.viewMobile ? "lg" : "lg"}
                  lead
                  space
                  onClick={() =>
                    this.setState({
                      screen: { actions: true },
                      indice: indice,
                      titleScreen: (
                        <div>
                          <div
                            className="is-row is-fullwidth"
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              color="white"
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="regular"
                              multiline
                            >
                              {this.getStatus(account)}
                            </Text>
                          </div>
                        </div>
                      ),
                    })
                  }
                />
              )}
            </div>
          </Column>
          {this.props.store.viewMobile && (
            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              {active && this.props.store.ui.showPlayer && (
                <div
                  className={"mx-1 mt-2"}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentProgressTime}
                  </Text>

                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentDuration}
                  </Text>
                </div>
              )}
              <div
                id={`waveform` + indice}
                style={{
                  height: active && this.props.store.ui.showPlayer ? 100 : 0,
                }}
              />
            </Column>
          )}
        </Columns>
      </Panel>
    );
  }

  renderScreenActions(account, indice) {
    var last_playlist_listened = this.getLastPlaylistListened(account);
    var last_song_listened = this.getLastSongListened(account);
    var playback_status = this.getPlaybackStatus(account);
    const currentSongPath = this.props.store.ui.currentSongPath;
    const active =
      currentSongPath && last_song_listened
        ? last_song_listened.song.song_path == currentSongPath
        : null;
    const { handleStartPlayingClick, handleTogglePlay } = this;

    return (
      <Screen
        onBack={() => {
          this.setState({ screen: { ...this.state.screen, actions: false } });
          this.props.store.ui.destroy();
        }}
      >
        <div
          className="flex flex-col my-1"
          style={{ gap: this.props.store.viewMobile ? 6 : 3 }}
        >
          <Panel
            color={"gray"}
            padding={
              this.props.store.viewMobile
                ? active
                  ? "16px 30px 0"
                  : "16px 30px"
                : active
                ? "14px 10px 0"
                : "14px 10px"
            }
            style={{ borderRadius: !this.props.store.viewMobile && 5 }}
          >
            {last_playlist_listened && (
              <div
                className="flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Img
                    height="35px"
                    width="35px"
                    src={this.getIconPath(account)}
                  />
                </div>

                <div
                  className="flex"
                  style={{ flexDirection: "column", flex: "auto" }}
                >
                  <Text
                    className="ml-2"
                    color="white"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                    multiline
                  >
                    {this.getPlaylistsName(account)}
                  </Text>

                  {last_song_listened && (
                    <Text
                      className="ml-2"
                      color="special"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      multiline
                    >
                      {last_song_listened.song.name +
                        " - " +
                        last_song_listened.song.singer.name}
                    </Text>
                  )}
                </div>
                {last_song_listened && (
                  <Button
                    icon={
                      active && this.props.store.ui.isPlaying ? "pause" : "play"
                    }
                    kind="link"
                    color={active ? "primary" : "basic"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    padding="0"
                    onClick={
                      active && this.props.store.ui.showPlayer
                        ? () =>
                            handleTogglePlay(last_song_listened.song.song_path)
                        : () =>
                            handleStartPlayingClick(
                              indice,
                              last_song_listened.song.song_path
                            )
                    }
                  />
                )}
                {playback_status && (
                  <div className="flex items-center">
                    <Text
                      color="special"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      multiline
                      >
                      {playback_status}
                    </Text>
                  </div>
                )}
              </div>
            )}

            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              {active && this.props.store.ui.showPlayer && (
                <div
                  className={"mx-1 mt-1"}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentProgressTime}
                  </Text>

                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentDuration}
                  </Text>
                </div>
              )}
              <div
                id={`waveform` + indice}
                style={{
                  height: active && this.props.store.ui.showPlayer ? 100 : 0,
                }}
              />
            </Column>
          </Panel>
        </div>
      </Screen>
    );
  }

  render() {
    const { account } = this.props;
    moment.updateLocale("es", {
      months: this.props.store.language.label59.split(","),
      monthsShort: this.props.store.language.label60.split(","),
      weekdays: this.props.store.language.label61.split(","),
      weekdaysShort: this.props.store.language.label62.split(","),
      weekdaysMin: this.props.store.language.label63.split(","),
    });

    return (
      <React.Fragment>
        {this.state.screen.actions && this.renderScreenActions(account, 1)}
        {this.renderItemsRow(account, 1)}
      </React.Fragment>
    );
  }
}

UserLog.propTypes = {
  account: PropTypes.object,
};

UserLog.defaultProps = {
  account: null,
};

export default withStore(UserLog);
