import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class GroupEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };

    this.group = props.group.clone();
  }

  handleChange = (sender, value, name) => {
    this.group[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.group.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label226, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add(this.props.store.language.label227, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.onSave && this.props.onSave();
          this.handleClose();
        }
      });
    });
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mb-2"
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label81}
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.group.name === ""}
              className="is-pulled-right px-3 mb-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "340px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            className="mb-1"
          >
            {this.props.store.language.label140}
            {"\n"}
            {this.group.name}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            label={this.props.store.language.label53}
            labelNote={this.props.store.language.label53}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "4px"}
            weight="medium"
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput="20px"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              name="name"
              onChange={this.handleChange}
              value={this.group.name}
              required
            />
          </Field>
          <Field
            label={this.props.store.language.label79}
            labelNoteOption={this.props.store.language.label78}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            marginH={this.props.store.viewMobile && "4px"}
            NoteSize="xs"
            weight="medium"
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput="20px"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type="text"
              name="description"
              onChange={this.handleChange}
              value={this.group.description}
            />
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

GroupEditModal.propTypes = {
  group: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

GroupEditModal.defaultProps = {
  group: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(GroupEditModal));
