import React, { Component } from "react";
import PropTypes from "prop-types";
import { RectSkeleton, Button, CardEvents, Text, Img } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class PlaylistsFavoriteRow extends Component {
  state = {
    open: false,
  };

  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDelete = () => {
    const { playlist, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(playlist);
  };

  render() {
    const { playlist } = this.props;

    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <CardEvents
          name={playlist.categories_playlists.alias}
          description={playlist.categories_playlists.description}
          isMobile={!this.props.store.viewMobile}
          padding={!this.props.store.viewMobile && "22px 20px"}
          style={{
            height: 65,
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
          content={
            this.state.open ? (
              <div
                style={{
                  display: "flex",
                  gap: 35,
                }}
              >
                <div>
                  <Button
                    disabled={!playlist.remove}
                    kind="link"
                    color="basic"
                    size={"xl"}
                    iconKasimu={
                      this.props.store.viewMobile ? "trash" : "trash-solid"
                    }
                    onClick={this.handleDelete}
                    space
                  />
                </div>
              </div>
            ) : (
              <div className="flex">
                <div className="flex items-center">
                  <Img
                    height={this.props.store.viewMobile ? "40px" : "35px"}
                    width={this.props.store.viewMobile ? "40px" : "35px"}
                    src={this.props.store.replaceBucketUrl(playlist.categories_playlists.icon_path)}
                    style={{ maxWidth: "initial" }}
                  />
                </div>
                <div className={this.props.store.viewMobile ? "ml-2" : "ml-1"}>
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                    lineBreak
                  >
                    {playlist.categories_playlists.alias}
                  </Text>
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "md"}
                    color="special"
                    lineBreak
                  >
                    <p>{playlist.categories_playlists.description}</p>
                  </Text>
                </div>
              </div>
            )
          }
        >
          {!this.props.store.viewMobile ? (
            <>
              {this.state.open ? (
                <Button
                  kind="link"
                  color="basic"
                  size={"lg"}
                  iconKasimu={"arrow-left"}
                  space
                  onClick={() => this.setState({ open: false })}
                />
              ) : (
                <Button
                  kind="link"
                  color="special"
                  size={"lg"}
                  iconKasimu={"arrow-right"}
                  space
                  onClick={() => this.setState({ open: true })}
                />
              )}
            </>
          ) : (
            <div playlist={{ display: "flex" }} className="ml-2">
              <Button
                disabled={!playlist.remove}
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="trash"
                onClick={this.handleDelete}
                space
              />
            </div>
          )}
        </CardEvents>
      </div>
    );
  }
}

PlaylistsFavoriteRow.propTypes = {
  playlist: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

PlaylistsFavoriteRow.defaultProps = {
  playlist: null,
  onEditClick: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(PlaylistsFavoriteRow));
