import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardUser, Loader } from "ks_storybook";
import withStore from "../../hocs/withStore";
import moment from "moment";

class AccountCard extends Component {
  handleEditClick = () => {
    const { account, onEditClick } = this.props;
    onEditClick && onEditClick(account);
  };

  handleDeleteClick = () => {
    const { account, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(account);
  };

  handleEyeOpen = () => {
    const { onEyeClick, account } = this.props;
    onEyeClick && onEyeClick(account);
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  getPlaylistsName = (playlist_owner, playlist) => {
    switch (playlist_owner) {
      case 1:
        return playlist[0].alias;
      case 2:
        return this.props.store.language.label631 + playlist[0].name;
      case 3:
        return this.props.store.language.label630 + playlist[0].name;
    }
  };

  render() {
    moment.updateLocale("es", {
      months: this.props.store.language.label59.split(","),
      monthsShort: this.props.store.language.label60.split(","),
      weekdays: this.props.store.language.label61.split(","),
      weekdaysShort: this.props.store.language.label62.split(","),
      weekdaysMin: this.props.store.language.label63.split(","),
    });
    const { account, activity } = this.props;
    var playlist = activity && activity.playlist;
    var conexion_type = activity && activity.conexion_type;
    return (
      <React.Fragment>
        <CardUser
          connection={conexion_type == null ? 2077 : activity.conexion_type}
          playlist={
            playlist && this.getPlaylistsName(activity.playlist_owner, playlist)
          }
          date={
            activity &&
            moment
              .utc(activity.conexion_date)
              .format("dddd, DD MMMM YYYY, h:mm:ss a")
          }
          from={activity && activity.os}
          arrayText={[
            this.props.store.language.label64,
            this.props.store.language.label65,
            this.props.store.language.label66,
            this.props.store.language.label67,
            this.props.store.language.label68,
            this.props.store.language.label69,
            this.props.store.language.label70,
            this.props.store.language.label71,
            this.props.store.language.label72,
            this.props.store.language.label73,
            this.props.store.language.label54,
            this.props.store.language.label96,
          ]}
          userName={account.username}
          email={account.email}
          status={account.status}
          description={account.description}
          groups={account.groups}
          iconEdit="pen"
          iconDeleted="trash"
          editClick={this.handleEditClick}
          deletedClick={this.handleDeleteClick}
          eyeClick={this.handleEyeOpen}
          isMobile={!this.props.store.viewMobile}
        />
      </React.Fragment>
    );
  }
}

AccountCard.propTypes = {
  account: PropTypes.object,
  activity: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onEyeClick: PropTypes.func,
};

AccountCard.defaultProps = {
  account: null,
  activity: null,
  onDeleteClick: null,
  onEditClick: null,
  onEyeClick: null,
};

export default withStore(AccountCard);
