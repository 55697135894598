import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
} from "ks_storybook";
import { Select } from "../../Components/Select";
import { withToastManager } from "react-toast-notifications";

@observer
class CreatePlaylistsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      name: null,
      description: "",
      playlistType: 0,
      messageError: null,
    };
  }

  handleChange = (sender, value, name, validation) => {
    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      default:
        break;
    }
  };

  getOptions = () => {
    const options = [
      { value: 1, label: this.props.store.language.label591 },
      { value: 2, label: this.props.store.language.label592 },
    ];
    return options;
  };

  handleChangeSelect = (event) => {
    this.setState({ playlistType: event.value });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 79) {
      return true;
    } else {
      return false;
    }
  };

  handleNext = () => {
    switch (this.state.playlistType) {
      case 1:
        this.props.onNext && this.props.onNext(this.state);
        break;
      case 2:
        this.props.onNext && this.props.onNext(this.state);
        break;
      default:
        break;
    }
  };

  @computed
  get canSave() {
    if (this.state.name) return false;
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          <Button
            disabled={this.canSave}
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="is-pulled-right px-3 mb-2"
            onClick={this.handleNext}
          >
            <strong>{this.props.store.language.label598}</strong>
          </Button>
        </LevelRight>
      </Level>
    );
  };

  render() {
    const options = this.getOptions();
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "340px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label589}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            multiline
            lineBreak
            color="special"
          >
            {this.props.store.language.label590}
          </Text>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            multiline
            lineBreak
            color="special"
          >
            <strong>{"- " + this.props.store.language.label591 + ": "}</strong>
            {this.props.store.language.label593}
          </Text>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            multiline
            lineBreak
            color="special"
          >
            <strong>{"- " + this.props.store.language.label592 + ": "}</strong>
            {this.props.store.language.label594}
          </Text>
          <br />
          <Select
            options={options}
            onChange={this.handleChangeSelect}
            backgroundColor="black"
            placeholder={this.props.store.language.label595}
            className="my-1"
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
            paddingInput={this.props.store.viewMobile && "20px"}
          />
          <TextInput
            name="name"
            onChange={this.handleChange}
            invert
            backgroundColor="blackDark"
            autocomplete="nope"
            className="is-fullwidth my-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            placeholderColor="gray"
            placeholder={this.props.store.language.label596}
            paddingInput={this.props.store.viewMobile && "20px"}
          />
          <TextInput
            name="description"
            onChange={this.handleChange}
            invert
            backgroundColor="blackDark"
            autocomplete="nope"
            className="is-fullwidth my-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            placeholderColor="gray"
            placeholder={this.props.store.language.label597}
            paddingInput={this.props.store.viewMobile && "20px"}
          />
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

CreatePlaylistsModal.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

CreatePlaylistsModal.defaultProps = {
  onNext: null,
  onClose: null,
};

export default withToastManager(withStore(CreatePlaylistsModal));
