import React, { Component } from "react";
import PropTypes from "prop-types";
import { Columns, Column, Text, Button } from "ks_storybook";
import withStore from "../../hocs/withStore";

class GroupCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleEditClick = () => {
    const { group, onEditClick } = this.props;
    onEditClick && onEditClick(group);
  };

  handleDeleteClick = () => {
    const { group, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(group);
  };

  render() {
    const { group } = this.props;
    if (group.isEmpty()) return null;
    return (
      <React.Fragment>
        <div
          style={{
            padding: this.props.store.viewMobile ? "25px 30px" : "15px 20px",
            background: "#1F1F1F",
            marginBottom: this.props.store.viewMobile ? "8px" : "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            overflowWrap: "anywhere",
            height: 65,
            backgroundColor: this.state.open ? "#D33F20" : "#1F1F1F",
            borderRadius: this.props.store.viewMobile ? "10px" : "5px",
          }}
        >
          <Columns isMobile isVCentered isMarginless isGapless>
            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              {this.state.open ? (
                <div
                  style={{
                    display: "flex",
                    gap: 35,
                  }}
                >
                  <div>
                    <Button
                      kind="link"
                      color="basic"
                      size={"xl"}
                      iconKasimu={
                        this.props.store.viewMobile ? "pen" : "pen-solid"
                      }
                      onClick={this.handleEditClick}
                      space
                    />
                  </div>
                  <div>
                    <Button
                      kind="link"
                      color="basic"
                      size={"xl"}
                      iconKasimu={
                        this.props.store.viewMobile ? "trash" : "trash-solid"
                      }
                      onClick={this.handleDeleteClick}
                      space
                    />
                  </div>
                </div>
              ) : (
                <div className="flex" style={{ gap: 5 }}>
                  <Text
                    color="special"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    lineBreak
                  >
                    {group.name}
                  </Text>
                  {group.description && (
                    <>
                      <Text
                        color="special"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        |
                      </Text>
                      <Text
                        color="gray"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {group.description}
                      </Text>
                    </>
                  )}
                </div>
              )}
            </Column>
          </Columns>
          {!this.props.store.viewMobile ? (
            <>
              {this.state.open ? (
                <Button
                  kind="link"
                  color="basic"
                  size={"lg"}
                  iconKasimu={"arrow-left"}
                  space
                  onClick={() => this.setState({ open: false })}
                />
              ) : (
                <Button
                  kind="link"
                  color="special"
                  size={"lg"}
                  iconKasimu={"arrow-right"}
                  space
                  onClick={() => this.setState({ open: true })}
                />
              )}
            </>
          ) : (
            <div style={{ display: "flex" }}>
              <Button
                className="is-pulled-right ml-3"
                iconKasimu="pen"
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                lead
                space
                onClick={this.handleEditClick}
              />
              <Button
                data-for="trash"
                className="is-pulled-right ml-2"
                iconKasimu="trash"
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                lead
                space
                onClick={this.handleDeleteClick}
              />
            </div>
          )}
        </div>
        {/* <CardGroup
          name={group.name.toUpperCase()}
          label={this.props.store.language.label79}
          description={
            group.description
              ? group.description
              : this.props.store.language.label89
          }
          iconEdit="pen"
          iconDeleted="trash"
          editClick={this.handleEditClick}
          deletedClick={this.handleDeleteClick}
          isMobile={!this.props.store.viewMobile}
        /> */}
      </React.Fragment>
    );
  }
}

GroupCard.propTypes = {
  group: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

GroupCard.defaultProps = {
  group: null,
  onDeleteClick: null,
  onEditClick: null,
};

export default withStore(GroupCard);
