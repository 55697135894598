import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Text,
  Loader,
  Toggle,
  Checkbox,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../../../Components/Screen";

class CardPermission extends Component {
  handleClick(e) {
    if (this.props.disabled) return;
    this.props.onClick &&
      this.props.onClick(this, this.props.value, this.props.name, e);
  }

  render() {
    const {
      name,
      description,
      children,
      pdEvent,
      upper,
      isMobile,
      style,
      padding,
      renderBottom,
    } = this.props;
    return (
      <Panel
        color="gray"
        padding={padding || (isMobile ? "16px" : "20px 32px")}
        style={{
          flexDirection: "column",
          justifyContent: "center",
          ...style,
        }}
      >
        <div className="flex" style={{ width: "100%", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <Text
              size={isMobile ? "lg" : "xl"}
              upper={upper}
              weight="medium"
              lineBreak
            >
              {name}
            </Text>
            <Text size={isMobile ? "md" : "xl"} color="special" lineBreak>
              <p>{description}</p>
            </Text>
          </div>
          <div style={{ display: "flex", padding: `0px ${pdEvent || "0px"}` }}>
            {children}
          </div>
        </div>

        {renderBottom}
      </Panel>
    );
  }
}

@observer
class UsersPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.store.getSingleUser()
        ? this.props.store.loggedInUser.id
        : null,
      ksPermissions: this.props.store.getSingleUser()
        ? this.getKSPermissions(this.props.store.loggedInUser.id)
        : null,
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label748;
  }

  getKSPermissions = (child_user_id) => {
    return this.props.store.ksPermissions.fetchAllParams(
      this.props.store.lang,
      child_user_id,
      2,
      false
    );
  };

  handleReload = () => {
    this.setState({
      ksPermissions: this.getKSPermissions(this.state.userId),
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  notPin = (id) => {
    const arrayPermissionsIds = [4, 15, 17];
    return !arrayPermissionsIds.includes(id);
  };

  getPermissions = (ksPermissions) => {
    return ksPermissions.toArray().map((permission) => ({
      id: permission.id,
      name: permission.translation.name,
      description: permission.translation.description,
      apply_in: permission.apply_in,
      isCheckedCheckbox: permission.user_permission.permission_activated,
      isCheckedToggle: permission.user_permission.requires_pin,
      isShowToggle: this.notPin(permission.id),
    }));
  };

  renderHeaderOk = () => {
    const { ksPermissions } = this.state;
    const arrayPermissions = this.getPermissions(ksPermissions);
    return this.renderPermissions(arrayPermissions);
  };

  handleCheckboxChange = (sender, value, name) => {
    const { toastManager } = this.props;
    let messageSuccess = value
      ? this.props.store.language.label749
      : this.props.store.language.label750;
    let messageError = value
      ? this.props.store.language.label751
      : this.props.store.language.label752;
    let deleted = value ? 0 : 1;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_ks_permissions/change_status_for_user",
        "?user_id=" +
          this.state.userId +
          "&ks_permissions_id=" +
          name +
          "&deleted=" +
          deleted
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeToggle = (sender, name, value) => {
    const { toastManager } = this.props;
    let messageSuccess = value
      ? this.props.store.language.label753
      : this.props.store.language.label754;
    let messageError = value
      ? this.props.store.language.label755
      : this.props.store.language.label756;
    let permit_type = value ? 1 : 0;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_ks_permissions/change_pin_requirement_for_user",
        "?user_id=" +
          this.state.userId +
          "&ks_permissions_id=" +
          name +
          "&permit_type=" +
          permit_type
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  renderPermissions = (arrayPermissions) => {
    return (
      <div
        className="flex flex-col my-1"
        style={{ gap: this.props.store.viewMobile ? "10px" : "3px" }}
      >
        <Columns style={{ gap: 10 }}>
          {arrayPermissions.map((permssion) => (
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <CardPermission
                name={permssion.name}
                description={permssion.description}
                isMobile={!this.props.store.viewMobile}
                padding={"18px 22px"}
                style={{ height: "100%" }}
                renderBottom={
                  permssion.isShowToggle && this.renderToogle(permssion)
                }
              >
                <Checkbox
                  value={permssion.id}
                  className="pl-1"
                  size="xxl"
                  isChecked={permssion.isCheckedCheckbox}
                  onChange={this.handleCheckboxChange}
                  invert
                />
              </CardPermission>
            </Column>
          ))}
        </Columns>
      </div>
    );
  };

  renderToogle = (permssion) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: 10,
          }}
        >
          <div>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              weight="medium"
            >
              {permssion.isCheckedToggle
                ? this.props.store.language.label757
                : this.props.store.language.label758}
            </Text>
          </div>
          <Toggle
            name={permssion.id}
            disabled={!permssion.isCheckedCheckbox}
            onChange={this.handleChangeToggle}
            checked={permssion.isCheckedToggle}
            noIcons
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { ksPermissions } = this.state;
    if (!ksPermissions) return this.renderEmptyContent();
    return !ksPermissions.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  renderScreen = () => {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label748}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="strong"
                className="pb-2"
              >
                {this.props.store.language.label748}
              </Text>
            )}
            {this.renderHeader()}
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(withToastManager(UsersPermissions));
