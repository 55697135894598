import { Store } from "ks_storybook";
import { ConexionLog } from "../models";

class ConexionsLogsStore extends Store {
  model = ConexionLog;

  fethAccountsLogs(lang) {
    return this.search(
      { lang: lang },
      "account-logs",
      true,
      "/conexions_logs/last_accounts_log"
    );
  }

  fethAccountLog(lang) {
    return this.search(
      { lang: lang },
      "account",
      true,
      "/conexions_logs/last_user_activity"
    );
  }

  fetchActivityAll(perPage, userId, lang) {
    return this.search(
      { per_page: perPage, user_id: userId, lang: lang },
      "get_activity_all",
      true,
      "/conexions_logs/get_activity_all"
    );
  }

  fetchUsersActivityLast(lang) {
    return this.search(
      { per_page: 10000, lang: lang },
      "get_users_activity_last",
      true,
      "/conexions_logs/get_users_activity_last"
    );
  }
}

export default ConexionsLogsStore;
