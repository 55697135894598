import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  RectSkeleton,
  Button,
  Text,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  Panel,
} from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class AdPlanRowModal extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleEyeBatchesModelOpen = () => {
    const { onEyeClick, plan } = this.props;
    onEyeClick && onEyeClick(plan);
  };

  handleEditPlanModalOpen = () => {
    const { onEditClick, plan } = this.props;
    onEditClick && onEditClick(plan);
  };

  handleDeletePlanModalOpen = () => {
    const { onDeleteClick, plan } = this.props;
    onDeleteClick && onDeleteClick(plan);
  };

  renderSwitch = (plan) => {
    switch (plan.priority) {
      case 1:
        return (
          <small className="">
            <small className="">
              {this.props.store.language.label352}: {plan.exact_day}
            </small>
            <br />
            {this.props.store.language.label353}: {plan.exact_hour}
          </small>
        );
      case 2:
        return (
          <small className="">
            {this.props.store.language.label353}: {plan.exact_hour}
          </small>
        );
      case 3:
        return (
          <small className="">
            {this.props.store.language.label354}: {plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label356}:{" "}
            {this.props.store.language.label355} {plan.frequency_songs}{" "}
            {this.props.store.language.label357}
          </small>
        );
      case 4:
        return (
          <small className="">
            {this.props.store.language.label354}: {plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label356}:{" "}
            {this.props.store.language.label355} {plan.frequency_minutes}{" "}
            {" min"}
          </small>
        );
      case 5:
        return (
          <small className="">
            {this.props.store.language.label354}:{plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label356}:{" "}
            {this.props.store.language.label355} {plan.frequency_songs}{" "}
            {this.props.store.language.label357}
          </small>
        );
      case 6:
        return (
          <small className="">
            {this.props.store.language.label354}: {plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label356}:{" "}
            {this.props.store.language.label355} {plan.frequency_minutes}{" "}
            {" min"}
          </small>
        );
    }
  };

  renderDesktop = (plan) => {
    return (
      <div className="ks--plan">
        <article
          className="ks--media"
          style={{
            alignItems: "center",
            justifyContent: "space-beetwen",
            padding: !this.props.store.viewMobile && "10px 15px",
          }}
        >
          <div className="media-content">
            <div className="content" style={{ lineBreak: "anywhere" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                lineBreak
                weight="strong"
              >
                {plan.name}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="gray"
                lineBreak
                weight="regular"
              >
                <small className="">
                  {this.props.store.language.label358}: {plan.launcher_limiter}
                </small>
                <br />
                {plan.start_date == "NULL" &&
                plan.finish_date == "NULL" ? null : (
                  <div>
                    <small className="">
                      {this.props.store.language.label359} {plan.start_date}{" "}
                      {"- " + this.props.store.language.label360}{" "}
                      {plan.finish_date}
                    </small>
                  </div>
                )}
                {this.renderSwitch(plan)}
              </Text>
            </div>
          </div>
          {!this.props.store.viewMobile ? (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <Dropdown direction="right-center" space>
                <DropdownToggle disabledArrow>
                  <Button
                    kind="link"
                    color="basic"
                    size={!this.props.store.viewMobile ? "lg" : "xl"}
                    iconKasimu={"three-dots"}
                    space
                  />
                </DropdownToggle>
                <DropdownPanel className="ks--dropdown-menu" color="black">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "4px 10px",
                    }}
                  >
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        iconKasimu="eye"
                        onClick={this.handleEyeBatchesModelOpen}
                        space
                      />
                    </div>
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        iconKasimu="pen"
                        //onClick={this.handleEditPlanModalOpen}
                        onClick={() =>
                          this.props.store.getPermission(31, 31, () => {
                            this.handleEditPlanModalOpen()
                          })
                        }
                        space
                      />
                    </div>
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        iconKasimu="trash"
                        //onClick={this.handleDeletePlanModalOpen}
                        onClick={() =>
                          this.props.store.getPermission(31, 31, () => {
                            this.handleDeletePlanModalOpen()
                          })
                        }
                        space
                      />
                    </div>
                  </div>
                </DropdownPanel>
              </Dropdown>
            </div>
          ) : (
            <div
              className="media-right"
              style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
            >
              <p
                className="py-1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Button
                  kind="link"
                  color="basic"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="eye"
                  onClick={this.handleEyeBatchesModelOpen}
                  space
                />
                <Button
                  kind="link"
                  color="basic"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="pen"
                  //onClick={this.handleEditPlanModalOpen}
                  onClick={() =>
                    this.props.store.getPermission(31, 31, () => {
                      this.handleEditPlanModalOpen()
                    })
                  }
                  space
                />
                <Button
                  kind="link"
                  color="basic"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="trash"
                  //onClick={this.handleDeletePlanModalOpen}
                  onClick={() =>
                    this.props.store.getPermission(31, 31, () => {
                      this.handleDeletePlanModalOpen()
                    })
                  }
                  space
                />
              </p>
            </div>
          )}
        </article>
      </div>
    );
  };

  renderMobile = (plan) => {
    return (
      <Panel
        color="gray"
        padding="18px"
        style={{ marginTop: 3, borderRadius: 7 }}
      >
        <article
          className="ks--media"
          style={{
            alignItems: "center",
            justifyContent: "space-beetwen",
            padding: 0,
          }}
        >
          <div className="media-content">
            <div className="content" style={{ lineBreak: "anywhere" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                lineBreak
                weight="strong"
              >
                {plan.name}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="gray"
                lineBreak
                weight="regular"
              >
                <span style={{ color: "#808080" }}>
                  <small className="">
                    {this.props.store.language.label358}:{" "}
                    {plan.launcher_limiter}
                  </small>
                  <br />
                  {plan.start_date == "NULL" &&
                  plan.finish_date == "NULL" ? null : (
                    <div>
                      <small className="">
                        {this.props.store.language.label359} {plan.start_date}{" "}
                        {"- " + this.props.store.language.label360}{" "}
                        {plan.finish_date}
                      </small>
                    </div>
                  )}
                  {this.renderSwitch(plan)}
                </span>
              </Text>
            </div>
          </div>
          {!this.props.store.viewMobile ? (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <Dropdown direction="right-center" space>
                <DropdownToggle disabledArrow>
                  <Button
                    kind="link"
                    color="basic"
                    size={!this.props.store.viewMobile ? "lg" : "xl"}
                    iconKasimu={"three-dots"}
                    space
                  />
                </DropdownToggle>
                <DropdownPanel className="ks--dropdown-menu" color="black">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "4px 10px",
                    }}
                  >
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        iconKasimu="eye"
                        onClick={this.handleEyeBatchesModelOpen}
                        space
                      />
                    </div>
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        iconKasimu="pen"
                        //onClick={this.handleEditPlanModalOpen}
                        onClick={() =>
                          this.props.store.getPermission(31, 31, () => {
                            this.handleEditPlanModalOpen()
                          })
                        }
                        space
                      />
                    </div>
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        iconKasimu="trash"
                        //onClick={this.handleDeletePlanModalOpen}
                        onClick={() =>
                          this.props.store.getPermission(31, 31, () => {
                            this.handleDeletePlanModalOpen()
                          })
                        }
                        space
                      />
                    </div>
                  </div>
                </DropdownPanel>
              </Dropdown>
            </div>
          ) : (
            <div
              className="media-right"
              style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
            >
              <p
                className="py-1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Button
                  kind="link"
                  color="basic"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="eye"
                  onClick={this.handleEyeBatchesModelOpen}
                  space
                />
                <Button
                  kind="link"
                  color="basic"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="pen"
                  //onClick={this.handleEditPlanModalOpen}
                  onClick={() =>
                    this.props.store.getPermission(31, 31, () => {
                      this.handleEditPlanModalOpen()
                    })
                  }
                  space
                />
                <Button
                  kind="link"
                  color="basic"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="trash"
                  //onClick={this.handleDeletePlanModalOpen}
                  onClick={() =>
                    this.props.store.getPermission(31, 31, () => {
                      this.handleDeletePlanModalOpen()
                    })
                  }
                  space
                />
              </p>
            </div>
          )}
        </article>
      </Panel>
    );
  };

  render() {
    const { plan } = this.props;
    if (plan.isEmpty()) {
      return this.renderSkelenton();
    }

    return this.props.store.viewMobile
      ? this.renderDesktop(plan)
      : this.renderMobile(plan);
  }
}

AdPlanRowModal.propTypes = {
  plan: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onEyeClick: PropTypes.func,
};

AdPlanRowModal.defaultProps = {
  plan: null,
  onDeleteClick: null,
  onEditClick: null,
  onEyeClick: null,
};

export default withStore(withToastManager(AdPlanRowModal));
