import React, { Component } from 'react';

class PublicRouter extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    return (
        <div>PublicRouter</div>
    );

  }
}

export default PublicRouter;