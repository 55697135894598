import { Store } from "ks_storybook";
import { Ad } from "../models";

class AdsStore extends Store {
  model = Ad;

  fetchSearch(value) {
    return this.search({ search: value }, "ads", true);
  }

  fetchAll() {
    return this.search(
      {per_page: 10000},
      "advertisements_all",
      true,
      "/advertisements/get_all_ads"
    );
  }
}

export default AdsStore;
