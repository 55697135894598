import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { User } from "../../models";
import { observable, computed } from "mobx";
import { withToastManager } from "react-toast-notifications";

@observer
class AccountPasswordChange extends Component {
  @observable user;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      password_confirm: null,
      messageError: null,
    };

    this.newPassword = this.newPass();
  }

  newPass = () => {
    const params = {
      password: null,
    };
    return params;
  };

  validLengthPwsOusername = (value) => {
    if (value.length >= 6 && value.length <= 19) {
      return true;
    } else {
      return false;
    }
  };

  validNoEquals = (pws, pwsConfi) => {
    if (pws === pwsConfi) {
      return true;
    } else {
      return false;
    }
  };

  handleChange = (sender, value, name, validation) => {
    switch (name) {
      case "password":
        if (this.validLengthPwsOusername(value)) {
          this.setState({ messageError: null });
          this.newPassword[name] = value;
        } else {
          this.setState({ messageError: this.props.store.language.label212 });
        }
        break;
      case "password_confirm":
        this.setState({ messageError: null });
        this.setState({ password_confirm: value });
        break;
    }
  };

  getErrorMessager(codError) {
    const messager = {
      1: this.props.store.language.label696,
      2: this.props.store.language.label697,
    };
    return messager[codError];
  }

  handleSave = () => {
    const { toastManager } = this.props;
    const { password_confirm } = this.state;

    if (this.newPassword.password && password_confirm) {
      if (this.validNoEquals(this.newPassword.password, password_confirm)) {
        this.setState({ messageError: null });
        this.setState({ isSaving: true }, () => {
          this.props.store.kasimuAPIClient
            .putParams("/users/put_change_password", this.newPassword)
            .then(
              (res) => {
                toastManager.add(this.props.store.language.label695, {
                  appearance: "success",
                  autoDismiss: true,
                });
                this.props.onClose && this.props.onClose();
              },
              (error) => {
                let str = error.toString();
                str = str.replace("Error: ", "");
                let mydatas = JSON.parse(str);
                this.setState({
                  messageError: this.getErrorMessager(mydatas["codeResponse"]),
                });
                this.setState({ isSaving: false });
              }
            )
            .catch((error) => {
              toastManager.add(this.props.store.language.label220, {
                appearance: "error",
                autoDismiss: true,
              });
            });
        });
      } else {
        this.setState({ messageError: this.props.store.language.label214 });
      }
    } else {
      this.setState({ messageError: this.props.store.language.label215 });
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              disabled={!this.canSave}
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "340px" : "auto"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            className="mb-1"
          >
            {this.props.store.language.label689}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            multiline
            lineBreak
            color="special"
          >
            {this.props.store.language.label693}
          </Text>
          <br />
          <Field
            label={this.props.store.language.label694}
            labelNote={this.props.store.language.label76}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "4px"}
            weight="medium"
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput="20px"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              autocomplete="nope"
              type="password"
              name="password"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            label={this.props.store.language.label77}
            labelNote={this.props.store.language.label76}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "4px"}
            weight="medium"
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput="20px"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type="password"
              name="password_confirm"
              onChange={this.handleChange}
            />
          </Field>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

AccountPasswordChange.propTypes = {
  onClose: PropTypes.func,
};

AccountPasswordChange.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(AccountPasswordChange));
