import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  CardEvents,
  Button,
  Panel,
} from "ks_storybook";
import { Level, LevelLeft, LevelRight } from "bloomer";
import { withToastManager } from "react-toast-notifications";

@observer
class AdsBatchesEyeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAd: null,
    };
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  handleStartPlayingClick(id, ad_path) {
    var that = this;
    var ad = {
      id: id,
      ad_path: ad_path,
    };
    that.props.store.ui.startPlayingAds(ad, 2, false);
    this.setState({
      currentAd: ad,
    });
  }

  handleTogglePlay() {
    if (!this.props.store.ui.isPlaying) {
      this.props.store.ui.play();
    } else {
      this.props.store.ui.pause();
    }
  }

  handleClose = () => {
    this.props.store.ui.destroy();
    this.props.onClose && this.props.onClose();
  };

  getContainerStyle = () => ({
    width: "100%",
    minHeight: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  renderHeader = (currentAdPlans) => {
    const { currentAd } = this.state;
    return (
      <div style={this.getContainerStyle()}>
        {currentAdPlans.ad_batches.map((a) => (
          <Panel
            color="gray"
            padding="0px"
            className={this.props.store.viewMobile && "mb-1"}
            style={{
              marginBottom: !this.props.store.viewMobile && "6px",
              borderRadius: !this.props.store.viewMobile && "5px",
            }}
          >
            <div
              className="is-row is-fullwidth"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: this.props.store.viewMobile
                  ? "20px 32px"
                  : "22px 25px",
                height: 65,
              }}
            >
              <div
                className="is-row is-fullwidth"
                style={{ alignItems: "center" }}
              >
                <Button
                  className={this.props.store.viewMobile ? "mr-3" : "mr-2"}
                  icon={
                    currentAd &&
                    currentAd.id == a.advertisements.id &&
                    this.props.store.ui.isPlaying
                      ? "pause"
                      : "play"
                  }
                  kind="link"
                  color={
                    currentAd && currentAd.id == a.advertisements.id
                      ? "primary"
                      : "basic"
                  }
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  onClick={
                    currentAd &&
                    currentAd.id == a.advertisements.id &&
                    this.props.store.ui.showPlayer
                      ? () => this.handleTogglePlay()
                      : () =>
                          this.handleStartPlayingClick(
                            a.advertisements.id,
                            a.advertisements.ad_path
                          )
                  }
                  id="play-button"
                  lead
                  space
                />
                <div
                  className="is-fullwidth"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text
                    size={this.props.store.viewMobile ? "sm" : "lg"}
                    weight="normal"
                    multiline
                    lead={this.props.store.viewMobile}
                  >
                    {currentAd && a.advertisements.id == currentAd.id ? (
                      <span style={{ fontWeight: "bold", color: "#FF3F1F" }}>
                        {a.advertisements.name}
                      </span>
                    ) : (
                      <span style={{ fontWeight: "bold" }}>
                        {a.advertisements.name}
                      </span>
                    )}
                  </Text>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: currentAd
                  ? this.props.store.viewMobile
                    ? "0px 32px"
                    : "0px 25px"
                  : 0,
              }}
            >
              {currentAd &&
                currentAd.id == a.advertisements.id &&
                this.props.store.ui.showPlayer && (
                  <div
                    className={"mx-1 mt-1"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Text
                      size={this.props.store.viewMobile ? "md" : "sm"}
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentDuration == "NaN:NaN"
                        ? "00:00 / 00:00"
                        : this.props.store.ui.currentDuration &&
                          this.props.store.ui.currentProgressTime &&
                          this.props.store.ui.currentProgressTime}
                    </Text>

                    <Text
                      size={this.props.store.viewMobile ? "md" : "sm"}
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentDuration == "NaN:NaN"
                        ? "00:00 / 00:00"
                        : this.props.store.ui.currentDuration &&
                          this.props.store.ui.currentProgressTime &&
                          this.props.store.ui.currentDuration}
                    </Text>
                  </div>
                )}

              <div
                id={`waveAds` + a.advertisements.id}
                style={{
                  height:
                    currentAd &&
                    currentAd.id == a.advertisements.id &&
                    this.props.store.ui.showPlayer
                      ? 100
                      : 0,
                }}
              />
            </div>
          </Panel>
        ))}
      </div>
    );
  };

  render() {
    const { currentAdPlans } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "50%" : "auto"}
        >
          <ModalHeader>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {this.props.store.language.label588}
            </Text>

            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
            >
              {currentAdPlans.name}
            </Text>
          </ModalHeader>
          <ModalContent>
            <br />
            {this.renderHeader(currentAdPlans)}
          </ModalContent>
          <ModalFooter />
        </Modal>
      </React.Fragment>
    );
  }
}
AdsBatchesEyeModal.propTypes = {
  currentAdPlans: PropTypes.object,
  onClose: PropTypes.func,
};

AdsBatchesEyeModal.defaultProps = {
  currentAdPlans: null,
  onClose: null,
};

export default withToastManager(withStore(AdsBatchesEyeModal));
