import { Store } from "ks_storybook";
import { UsersPlaylistSongs } from "../models";

class UsersPlaylistSongsStore extends Store {
  model = UsersPlaylistSongs;

  fetchSongsPlaylists(playlist_id) {
    return this.search(
      { per_page: 500, playlist_id: playlist_id },
      "users_playlists_songs",
      true,
      "/users_playlist_songs/get_playlists_songs"
    );
  }
}

export default UsersPlaylistSongsStore;
