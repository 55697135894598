import { Store } from "ks_storybook";
import { AppConnectionHistory } from "../models";

class AppConnectionHistoryStore extends Store {
    model = AppConnectionHistory;

    fetchAppConnectionHistory(perPage, userId) {
        return this.search(
          { per_page: perPage, user_id: userId },
          "get_app_connection_history",
          true,
          "/app_connection_history/get_app_connection_history"
        );
      }
}

export default AppConnectionHistoryStore;