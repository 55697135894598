import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalFooter,
  ModalContent,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class UsersStylesEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: false,
    };

    this.styles = props.styles.clone();
  }

  handleChange = (sender, value, name) => {
    this.styles[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    if (this.validLength(this.styles.name)) {
      this.setState({ isSaving: true }, () => {
        this.styles.save().andThen((res, responseError) => {
          if (responseError) {
            toastManager.add(this.props.store.language.label534, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            toastManager.add(this.props.store.language.label535, {
              appearance: "success",
              autoDismiss: true,
            });
          }
          this.handleClose();
        });
      });
    } else {
      this.setState({ messageError: this.props.store.language.label533 });
    }
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.styles.name === ""}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginRight: "10px" }}>
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="black"
              >
                {this.props.store.language.label536}
              </Text>
            </div>
            <div style={{ width: "80%" }}>
              <Field
                label={this.props.store.language.label53}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                marginH={this.props.store.viewMobile && "4px"}
                NoteSize="xs"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                <TextInput
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  name="name"
                  value={this.props.styles.name}
                  onChange={this.handleChange}
                  required
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  invert
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </div>
          </div>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

UsersStylesEditModal.propTypes = {
  styles: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

UsersStylesEditModal.defaultProps = {
  styles: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(UsersStylesEditModal));
