import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import { Text, Panel, Button, Toggle, Loader, Paragraph } from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import {
  PPClocksModal,
  PPClocksManagerModal,
  PPAssignNormalClockModal,
  PPAssignSpecialClockModal,
  PPDayManagerModal,
} from "../../../../Components/Ads";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { withToastManager } from "react-toast-notifications";
import moment from "moment";

@observer
class PPCalendarManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ppArrayClocks: null,
      mediaTypes: this.getPPMediaTypeAll(),
      ppClocks: this.getPPClocksAll(),
      ppCalendarClocks: this.getPPCalendarClocksAll(),
      ads_planner_on: this.props.store.loggedInUser.configs.ads_planner_on,
      showPPClocksModal: false,
      showPPClocksManagerModal: false,
      showPPAssignNormalClockModal: false,
      showPPAssignSpecialClockModal: false,
      showPPDayManagerModal: false,
      currentPPCalendarClocks: null,
    };
  }

  getPPCalendarClocksAll = () => {
    return this.props.store.ppCalendarClocks.fetchAll();
  };

  getEndRecur = (exact_day, repetitions) => {
    if (repetitions > 0) {
      var new_date = moment(exact_day, "DD/MM/YYYY").add(
        repetitions * 7 + 1,
        "days"
      );
      return moment(new_date).format("YYYY-MM-DD");
    } else {
      var new_date = moment(exact_day, "DD/MM/YYYY").add(1, "days");
      return moment(new_date).format("YYYY-MM-DD");
    }
  };

  getEvents = (obj) => {
    let items = [];
    obj.toArray().map((o) =>
      o.day_type == 1
        ? items.push({
            id: o.id,
            title: o.name,
            start: moment(o.start_date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            end: moment(o.end_date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            color: "green",
            allDay: true,
          })
        : o.exact_day
        ? items.push({
            id: o.id,
            title: o.name,
            daysOfWeek: [
              new Date(
                moment(o.exact_day, "DD/MM/YYYY").format("YYYY-MM-DD")
              ).getDay(),
            ],
            startRecur: moment(o.exact_day, "DD/MM/YYYY").format("YYYY-MM-DD"),
            endRecur: this.getEndRecur(o.exact_day, o.repetitions),
            color: "red",
            allDay: true,
          })
        : items.push({
            id: o.id,
            title: o.name,
            start: moment(o.start_date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            end: moment(o.end_date, "DD/MM/YYYY")
              .add(1, "days")
              .format("YYYY-MM-DD"),
            color: "red",
            allDay: true,
          })
    );
    return items;
  };

  getDayNormal = (obj) => {
    let items = [];
    obj.toArray().map((o) => (o.day_type == 1 ? items.push(o) : null));
    return items;
  };

  getSpecialDays = () => {
    let items = [];
    this.state.ppCalendarClocks
      .toArray()
      .map((o) => (o.day_type != 1 ? items.push(o) : null));
    return items;
  };

  getPPMediaTypeAll = () => {
    return this.props.store.ppMediaType.fetchAll();
  };

  getPPClocksAll = () => {
    return this.props.store.ppClocks.fetchAll();
  };

  reloadPPClocks = () => {
    this.setState({ ppClocks: this.getPPClocksAll() });
  };

  handlePPClocksModalOpen = () => {
    this.setState({ showPPClocksModal: true });
  };

  handlePPClocksModalSave = () => {
    this.setState({ showPPClocksModal: false });
    this.reloadPPClocks();
  };

  handlePPClocksModalClose = () => {
    this.setState({ showPPClocksModal: false });
  };

  handlePPClocksManagerModalOpen = () => {
    this.setState({ showPPClocksManagerModal: true });
  };

  handlePPClocksManagerModalClose = () => {
    this.setState({ showPPClocksManagerModal: false });
  };

  handlePPAssignNormalClockModalOpen = () => {
    this.setState({ showPPAssignNormalClockModal: true });
  };

  handlePPAssignNormalClockModalClose = () => {
    this.setState({ showPPAssignNormalClockModal: false });
  };

  handlePPAssignNormalClockModalSave = () => {
    this.autoActivator(1);
    this.setState({
      showPPAssignNormalClockModal: false,
      ppCalendarClocks: this.getPPCalendarClocksAll(),
    });
  };

  handlePPAssignSpecialClockModalOpen = () => {
    this.setState({ showPPAssignSpecialClockModal: true });
  };

  handlePPAssignSpecialClockModalClose = () => {
    this.setState({ showPPAssignSpecialClockModal: false });
  };

  handlePPAssignSpecialClockModalSave = () => {
    this.autoActivator(1);
    this.setState({
      showPPAssignSpecialClockModal: false,
      ppCalendarClocks: this.getPPCalendarClocksAll(),
    });
  };

  getCurrentCalendar = (obj, id) => {
    obj = obj.toArray().filter((f) => f.id == id);
    return obj[0];
  };

  handlePPDayManagerModalOpen = (currentDayId) => {
    this.setState({
      showPPDayManagerModal: true,
      currentPPCalendarClocks: this.getCurrentCalendar(
        this.state.ppCalendarClocks,
        currentDayId
      ),
    });
  };

  handlePPDayManagerModalClose = () => {
    this.setState({
      showPPDayManagerModal: false,
      currentPPCalendarClocks: null,
    });
  };

  reloadPPCalendarClocks = () => {
    this.setState({ ppCalendarClocks: this.getPPCalendarClocksAll() });
  };

  autoActivator = (value) => {
    const actionPlaner = value ? 1 : 0;
    const messageSuccess =
      actionPlaner == 1
        ? this.props.store.language.label431
        : this.props.store.language.label432;
    const messageError =
      actionPlaner == 1
        ? this.props.store.language.label433
        : this.props.store.language.label434;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_ads_planner_on_all",
        "?user_id=" +
          this.props.store.loggedInUser.id +
          "&ads_planner_on=" +
          actionPlaner
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.reloadPPCalendarClocks();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChange = (sender, name, value) => {
    this.autoActivator(value);
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderCalendar = () => {
    const { ppCalendarClocks } = this.state;
    if (!ppCalendarClocks) return this.renderEmptyContent();
    return !ppCalendarClocks.isOk()
      ? this.renderLoader()
      : this.renderHeader(ppCalendarClocks);
  };

  getlanguageCalendar = (val) => {
    if (val == 0) return "en-EN";
    if (val == 1) return "es-ES";
    if (val == 2) return "fr-FR";
  };

  renderFullCalendar = (ppCalendarClocks) => {
    return (
      <div className="">
        <FullCalendar
          locale={this.getlanguageCalendar(this.props.store.lang)}
          defaultView="dayGridMonth"
          header={{ left: "title" }}
          eventClick={(info) => {
            this.handlePPDayManagerModalOpen(info.event.id);
          }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          events={this.getEvents(ppCalendarClocks)}
        />
      </div>
    );
  };

  renderToogle = (ppCalendarClocks) => {
    const array = ppCalendarClocks.toArray();
    return (
      <React.Fragment>
        <Panel color="gray" className="ks--panel--day my-1" padding="0px 24px">
          <div
            className="is-pulled-right"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "",
            }}
          >
            <div className="mr-2">
              <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                {array[0].ads_planner_on
                  ? this.props.store.language.label204
                  : this.props.store.language.label205}
              </Text>
            </div>
            <Toggle
              noIcons
              onChange={this.handleChange}
              checked={array[0].ads_planner_on ? true : false}
            />
          </div>
        </Panel>
        <br />
      </React.Fragment>
    );
  };

  renderHeader = (ppCalendarClocks) => {
    const normal = this.getDayNormal(ppCalendarClocks);
    return (
      <React.Fragment>
        <Panel
          className="my-2"
          color="gray"
          padding={!this.props.store.viewMobile && "22px 19px"}
        >
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
              <Text
                upper
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="strong"
              >
                {this.props.store.language.label199}
              </Text>
              <div className="my-1" />
              <Columns isMultiline>
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                  <Button
                    upper
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className={`px-4 ${
                      this.props.store.viewMobile && "is-fullwidth"
                    }`}
                    onClick={this.handlePPClocksModalOpen}
                  >
                    <strong>{this.props.store.language.label82}</strong>
                  </Button>
                </Column>
              </Columns>
              <Columns>
                <Column
                  style={{ paddingTop: !this.props.store.viewMobile && "0px" }}
                >
                  <Button
                    upper
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className={`px-2 ${
                      this.props.store.viewMobile && "is-fullwidth"
                    }`}
                    onClick={this.handlePPClocksManagerModalOpen}
                  >
                    <strong>{this.props.store.language.label201}</strong>
                  </Button>
                </Column>
              </Columns>
            </Column>

            <Column isSize={{ mobile: 12, tablet: 12, desktop: 5 }}>
              <Text
                upper
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="strong"
              >
                {this.props.store.language.label200}
              </Text>
              <div className="my-1" />
              {normal.length == 0 ? (
                <Columns>
                  <Column
                    style={{
                      paddingBottom: !this.props.store.viewMobile && "0px",
                    }}
                  >
                    <Button
                      upper
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className={`px-2 ${
                        this.props.store.viewMobile && "is-fullwidth"
                      }`}
                      onClick={this.handlePPAssignNormalClockModalOpen}
                    >
                      <strong>{this.props.store.language.label202}</strong>
                    </Button>
                  </Column>
                </Columns>
              ) : null}
              <Columns>
                <Column>
                  <Button
                    upper
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className={`px-2 ${
                      this.props.store.viewMobile && "is-fullwidth"
                    }`}
                    onClick={this.handlePPAssignSpecialClockModalOpen}
                  >
                    <strong>{this.props.store.language.label203}</strong>
                  </Button>
                </Column>
              </Columns>
            </Column>
            <Column>
              {ppCalendarClocks.length == 0
                ? null
                : this.renderToogle(ppCalendarClocks)}
            </Column>
          </Columns>
        </Panel>
        <br />
        <div style={{ "background-color": "#242424", "border-radius": "8px" }}>
          {this.renderFullCalendar(ppCalendarClocks)}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      showPPClocksModal,
      showPPClocksManagerModal,
      mediaTypes,
      showPPAssignNormalClockModal,
      showPPAssignSpecialClockModal,
      showPPDayManagerModal,
      ppClocks,
      currentPPCalendarClocks,
    } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Text
              upper
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
              lineBreak
            >
              {this.props.store.language.label197}
            </Text>
            <div
              style={{
                maxWidth: "580px",
              }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label198}
              </Paragraph>
            </div>
            {this.renderCalendar()}
          </Column>
        </Columns>
        {showPPClocksModal && (
          <PPClocksModal
            mediaTypes={mediaTypes.toArray()}
            onSave={this.handlePPClocksModalSave}
            onClose={this.handlePPClocksModalClose}
          />
        )}
        {showPPClocksManagerModal && (
          <PPClocksManagerModal
            ppClocks={ppClocks}
            mediaTypes={mediaTypes.toArray()}
            onClose={this.handlePPClocksManagerModalClose}
            onReload={this.reloadPPClocks}
          />
        )}
        {showPPAssignNormalClockModal && (
          <PPAssignNormalClockModal
            ppClocks={ppClocks}
            onClose={this.handlePPAssignNormalClockModalClose}
            onSave={this.handlePPAssignNormalClockModalSave}
          />
        )}
        {showPPAssignSpecialClockModal && (
          <PPAssignSpecialClockModal
            specialDays={this.getSpecialDays()}
            ppClocks={ppClocks}
            onClose={this.handlePPAssignSpecialClockModalClose}
            onSave={this.handlePPAssignSpecialClockModalSave}
          />
        )}
        {showPPDayManagerModal && (
          <PPDayManagerModal
            currentPPCalendarClocks={currentPPCalendarClocks}
            ppClocks={ppClocks}
            onClose={this.handlePPDayManagerModalClose}
            onReload={this.reloadPPCalendarClocks}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStore(withToastManager(PPCalendarManager));
