import React, { Component } from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import {
  Text,
  Paragraph,
  Panel,
  Field,
  Button,
  Radio,
  RadioGroup,
  DateTimePicker,
  Loader,
} from "ks_storybook";
import { Select } from "../../../../Components/Select";
import { EmptyContent } from "../../../../Components/Common";
import { withToastManager } from "react-toast-notifications";
import moment from "moment";

@observer
class UsersReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ppAds: this.getPPAdsAll(),
      isDownloading: false,
      radioValue: 1,
      type_report: null,
      advertisements_id: null,
      start_date: moment(new Date(), "DD/MM/YYYY").toDate(),
      end_date: moment(new Date(), "DD/MM/YYYY").add(1, "days").toDate(),
    };
  }

  getPPAdsAll = () => {
    return this.props.store.ppAds.fetchAllAndDeleted();
  };

  getOptionsPPAds = (obj, deleted) => {
    return obj
      .toArray()
      .filter((e) => e.deleted == deleted)
      .map((o) => ({ value: o.id, label: o.name }));
  };

  getHeaderPPAdsGeneralReport = () => {
    const headers = {
      username: this.props.store.language.label165.toUpperCase(),
      media_name: this.props.store.language.label166.toUpperCase(),
      name_path: this.props.store.language.label167.toUpperCase(),
      schedule_from: this.props.store.language.label168.toUpperCase(),
      schedule_to: this.props.store.language.label169.toUpperCase(),
      play_date: this.props.store.language.label170.toUpperCase(),
    };
    return headers;
  };

  getArrayPPAdsGeneralReport = (obj) => {
    return obj.toArray().map((o) => ({
      username: o.user.username,
      media_name: o.advertisements.name,
      name_path: o.versions_ads.name,
      schedule_from: o.advertisements.schedule_from,
      schedule_to: o.advertisements.schedule_to,
      play_date: moment(o.play_date)
        .subtract(1, "hours")
        .format("DD/MM/YYYY HH:mm:ss"),
    }));
  };

  getPPAds = (id) => {
    var arr = [];
    arr = this.state.ppAds.toArray().filter((e) => e.id == id);
    return arr[0];
  };

  getHeaderPPAdsTypeReport2 = () => {
    const headers = {
      media_name: this.props.store.language.label166.toUpperCase(),
      number_reproductions: this.props.store.language.label171.toUpperCase(),
      rank_from: this.props.store.language.label172.toUpperCase(),
      rank_to: this.props.store.language.label173.toUpperCase(),
    };
    return headers;
  };

  getArrayPPAdsTypeReport2 = (obj, strStartDate, strEnddate) => {
    return obj.map((o) => ({
      media_name: this.getPPAds(o.advertisements_id).name,
      number_reproductions: o.count,
      rank_from: strStartDate,
      rank_to: strEnddate,
    }));
  };

  handleChangeDownloading = () => {
    this.setState({ isDownloading: false });
  };

  downloadFileAds = () => {
    console.log("reportes ads en construccion");
  };

  downloadFilePPAds = () => {
    const { toastManager } = this.props;
    const { start_date, end_date, type_report, advertisements_id } = this.state;

    var strStartDate = moment(start_date).format("DD/MM/YYYY") + "_00:00";
    var strEnddate = moment(end_date).format("DD/MM/YYYY") + "_23:59";

    switch (type_report) {
      case 1:
        this.setState({ isDownloading: true }, () => {
          this.props.store.ppAdsLog
            .fetchRank(strStartDate, strEnddate, type_report)
            .andThen((res, responseError) => {
              if (responseError) {
                toastManager.add(this.props.store.language.label159, {
                  appearance: "error",
                  autoDismiss: true,
                });
                this.handleChangeDownloading();
              } else {
                if (res.length != 0) {
                  toastManager.add(this.props.store.language.label160, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  this.props.store.exportCSVFile(
                    this.getHeaderPPAdsGeneralReport(),
                    this.getArrayPPAdsGeneralReport(res),
                    this.props.store.language.label149
                  );
                } else {
                  toastManager.add(this.props.store.language.label161, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
                this.handleChangeDownloading();
              }
            });
        });
        break;
      case 2:
        this.setState({ isDownloading: true }, () => {
          this.props.store.kasimuAPIClient
            .search("/pp_advertisements_log/get_rank_datetime", {
              per_page: 10000,
              start_date: strStartDate,
              end_date: strEnddate,
              type_report: type_report,
              advertisements_id: advertisements_id,
            })
            .then(
              (res) => {
                if (res.results.length != 0) {
                  toastManager.add(this.props.store.language.label160, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  this.props.store.exportCSVFile(
                    this.getHeaderPPAdsTypeReport2(),
                    this.getArrayPPAdsTypeReport2(
                      res.results,
                      strStartDate,
                      strEnddate
                    ),
                    this.props.store.language.label162 +
                      this.getPPAds(advertisements_id).name +
                      this.props.store.language.label163
                  );
                  this.handleChangeDownloading();
                } else {
                  toastManager.add(this.props.store.language.label161, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                  this.handleChangeDownloading();
                }
              },
              (error) => {
                toastManager.add(this.props.store.language.label159, {
                  appearance: "error",
                  autoDismiss: true,
                });
                this.handleChangeDownloading();
              }
            );
        });
        break;
      case 3:
        this.setState({ isDownloading: true }, () => {
          this.props.store.ppAdsLog
            .fetchRank(strStartDate, strEnddate, type_report, advertisements_id)
            .andThen((res, responseError) => {
              if (responseError) {
                toastManager.add(this.props.store.language.label159, {
                  appearance: "error",
                  autoDismiss: true,
                });
                this.handleChangeDownloading();
              } else {
                if (res.length != 0) {
                  toastManager.add(this.props.store.language.label160, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  this.props.store.exportCSVFile(
                    this.getHeaderPPAdsGeneralReport(),
                    this.getArrayPPAdsGeneralReport(res),
                    this.props.store.language.label162 +
                      this.getPPAds(advertisements_id).name +
                      this.props.store.language.label164
                  );
                } else {
                  toastManager.add(this.props.store.language.label161, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
                this.handleChangeDownloading();
              }
            });
        });
        break;
      case 4:
        this.setState({ isDownloading: true }, () => {
          this.props.store.kasimuAPIClient
            .search("/pp_advertisements_log/get_rank_datetime", {
              per_page: 10000,
              start_date: strStartDate,
              end_date: strEnddate,
              type_report: type_report,
              advertisements_id: advertisements_id,
            })
            .then(
              (res) => {
                if (res.results.length != 0) {
                  toastManager.add(this.props.store.language.label160, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  this.props.store.exportCSVFile(
                    this.getHeaderPPAdsTypeReport2(),
                    this.getArrayPPAdsTypeReport2(
                      res.results,
                      strStartDate,
                      strEnddate
                    ),
                    this.props.store.language.label162 +
                      this.getPPAds(advertisements_id).name +
                      this.props.store.language.label163
                  );
                  this.handleChangeDownloading();
                } else {
                  toastManager.add(this.props.store.language.label161, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                  this.handleChangeDownloading();
                }
              },
              (error) => {
                toastManager.add(this.props.store.language.label159, {
                  appearance: "error",
                  autoDismiss: true,
                });
                this.handleChangeDownloading();
              }
            );
        });
        break;
      case 5:
        this.setState({ isDownloading: true }, () => {
          this.props.store.ppAdsLog
            .fetchRank(strStartDate, strEnddate, type_report, advertisements_id)
            .andThen((res, responseError) => {
              if (responseError) {
                toastManager.add(this.props.store.language.label159, {
                  appearance: "error",
                  autoDismiss: true,
                });
                this.handleChangeDownloading();
              } else {
                if (res.length != 0) {
                  toastManager.add(this.props.store.language.label160, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  this.props.store.exportCSVFile(
                    this.getHeaderPPAdsGeneralReport(),
                    this.getArrayPPAdsGeneralReport(res),
                    this.props.store.language.label162 +
                      this.getPPAds(advertisements_id).name +
                      this.props.store.language.label164
                  );
                } else {
                  toastManager.add(this.props.store.language.label161, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
                this.handleChangeDownloading();
              }
            });
        });
        break;
      default:
        break;
    }
  };

  downloadFilePlaylists = () => {
    console.log("reportes playlists en construccion");
  };

  handleChangeRadio = (sender, value) => {
    this.setState({ radioValue: value, type_report: null });
  };

  handleChangeSelect = (value, sender) => {
    const val = !value ? null : value.value;
    switch (sender.name) {
      case "type_report":
        this.setState({ advertisements_id: null });
        this.setState({ type_report: val });
        break;
      case "media":
        this.setState({ advertisements_id: val });
        break;
      default:
        break;
    }
  };

  handleChangeFiled = (sender, value, name, validation) => {
    switch (name) {
      case "start_date":
        this.setState({ start_date: value });
        break;
      case "end_date":
        this.setState({ end_date: value });
        break;
      default:
        break;
    }
  };

  getOptionsReportPlaylists = () => {
    const options = [{ value: 1, label: this.props.store.language.label149 }];
    return options;
  };

  getOptionsReportAds = () => {
    const options = [{ value: 1, label: this.props.store.language.label149 }];
    return options;
  };

  getOptionsReportPPAds = () => {
    const options = [
      { value: 1, label: this.props.store.language.label149 },
      { value: 2, label: this.props.store.language.label150 },
      { value: 3, label: this.props.store.language.label151 },
      { value: 4, label: this.props.store.language.label519 },
      { value: 5, label: this.props.store.language.label520 },
    ];
    return options;
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  @computed
  get canDownload() {
    if (!this.state.type_report) return false;
    if (!this.state.advertisements_id && this.state.type_report > 1)
      return false;
    return true;
  }

  renderButtons = () => {
    const { radioValue, isDownloading } = this.state;
    return (
      <>
        {isDownloading ? (
          <Button
            upper
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "my-2" : "my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            {this.props.store.language.label158}
          </Button>
        ) : (
          <Button
            upper
            disabled={!this.canDownload}
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "my-2" : "my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onClick={
              radioValue == 1
                ? this.props.store.getUserRadio()
                  ? this.downloadFilePPAds
                  : this.downloadFileAds
                : this.downloadFilePlaylists
            }
          >
            {this.props.store.language.label157}
          </Button>
        )}
      </>
    );
  };

  renderObjSelect = (name, options, label1, label2) => {
    if (name == "media")
      return (
        <Field
          label={label1}
          labelNoteOption={this.props.store.language.label76}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          upper
          marginH={this.props.store.viewMobile && "8px"}
        >
          <Select
            name={name}
            onChange={this.handleChangeSelect}
            isClearable
            options={options}
            value={
              this.state.advertisements_id != null
                ? options[
                    options
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(this.state.advertisements_id)
                  ]
                : label2
            }
            placeholder={label2}
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </Field>
      );
    else
      return (
        <Field
          label={label1}
          labelNoteOption={this.props.store.language.label76}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          upper
          marginH={this.props.store.viewMobile && "8px"}
        >
          <Select
            name={name}
            onChange={this.handleChangeSelect}
            isClearable
            options={options}
            placeholder={label2}
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </Field>
      );
  };

  renderDateTimePicker = (label1, label2, name, value) => {
    return (
      <Field
        label={label1}
        labelNoteOption={label2}
        size={this.props.store.viewMobile ? "xl" : "lg"}
        upper
        marginH={this.props.store.viewMobile && "8px"}
      >
        <DateTimePicker
          name={name}
          value={value}
          dateFormat="DD/MM/YYYY"
          onChange={this.handleChangeFiled}
          iconKasimu="arrow-down"
          reversed
          invert
          backgroundColor="black"
          colorIcon="white"
          color="white"
          fullWidth
          size="xl"
          className="is-fullwidth"
        />
      </Field>
    );
  };

  renderBody = () => {
    const { radioValue, start_date, end_date, ppAds, type_report } = this.state;
    switch (radioValue) {
      case 1:
        return (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                alignItems: this.props.store.viewMobile ? "center" : "",
                flexDirection: this.props.store.viewMobile ? "row" : "column",
              }}
            >
              <Text
                upper
                size={this.props.store.viewMobile ? "md" : "sm"}
                weight="strong"
                lead
                lineBreak
              >
                {this.props.store.language.label142}
              </Text>
              <Text
                className={this.props.store.viewMobile && "px-1"}
                color="special"
                size={this.props.store.viewMobile ? "lg" : "md"}
                lineBreak
              >
                {this.props.store.language.label144}
              </Text>
            </div>
            <br />
            {this.renderObjSelect(
              "type_report",
              this.props.store.getUserRadio()
                ? this.getOptionsReportPPAds()
                : this.getOptionsReportAds(),
              this.props.store.language.label145,
              this.props.store.language.label146
            )}
            {this.props.store.getUserRadio()
              ? type_report > 1
                ? this.renderObjSelect(
                    "media",
                    type_report > 3
                      ? this.getOptionsPPAds(ppAds, 1)
                      : this.getOptionsPPAds(ppAds, 0),
                    type_report > 3
                      ? this.props.store.language.label521
                      : this.props.store.language.label147,
                    this.props.store.language.label148
                  )
                : null
              : null}
            {this.renderDateTimePicker(
              this.props.store.language.label152,
              this.props.store.language.label153,
              "start_date",
              start_date
            )}
            {this.renderDateTimePicker(
              this.props.store.language.label154,
              this.props.store.language.label155,
              "end_date",
              end_date
            )}
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                alignItems: this.props.store.viewMobile ? "center" : "",
                flexDirection: this.props.store.viewMobile ? "row" : "column",
              }}
            >
              <Text
                upper
                size={this.props.store.viewMobile ? "md" : "sm"}
                weight="strong"
                lead
                lineBreak
              >
                {this.props.store.language.label143}
              </Text>
              <Text
                className={this.props.store.viewMobile && "px-1"}
                color="special"
                size={this.props.store.viewMobile ? "lg" : "md"}
              >
                {this.props.store.language.label156}
              </Text>
            </div>
            <br />
            {this.renderObjSelect(
              "type_report",
              this.getOptionsReportPlaylists(),
              this.props.store.language.label145,
              this.props.store.language.label146
            )}
            {this.renderDateTimePicker(
              this.props.store.language.label152,
              this.props.store.language.label153,
              "start_date",
              start_date
            )}
            {this.renderDateTimePicker(
              this.props.store.language.label154,
              this.props.store.language.label155,
              "end_date",
              end_date
            )}
          </React.Fragment>
        );
    }
  };

  renderHeader = () => {
    const { ppAds } = this.state;
    if (!ppAds) return this.renderEmptyContent();
    return !ppAds.isOk() ? this.renderLoader() : this.renderBody();
  };

  render() {
    const { radioValue } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless className="is-multiline is-mobile">
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              upper
              lead
              weight="strong"
              lineBreak
            >
              {this.props.store.language.label22}
            </Text>
            <div
              style={{
                maxWidth: "718px",
              }}
              className={this.props.store.viewMobile ? "my-2" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label141}
              </Paragraph>
            </div>
            <Panel
              className={this.props.store.viewMobile ? "my-2" : "my-1"}
              color="gray"
              padding={!this.props.store.viewMobile && "23px 20px"}
            >
              <RadioGroup
                size={this.props.store.viewMobile ? "md" : "sm"}
                onChange={this.handleChangeRadio}
                value={radioValue}
              >
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value={1}
                  class="radio"
                  invert
                >
                  <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                    {this.props.store.language.label142}
                  </Text>
                </Radio>
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value={2}
                  class="radio"
                  invert
                >
                  <Text size={this.props.store.viewMobile ? "lg" : "md"} upper>
                    {this.props.store.language.label143}
                  </Text>
                </Radio>
              </RadioGroup>
              <br />
              {this.renderHeader()}
            </Panel>
            <br />
            {this.renderButtons()}
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withToastManager(withStore(UsersReportsPage));
