import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import { Text, Button, Paragraph, Img } from "ks_storybook";
import WhatsappLogo from "../../../assets/images/whatsapp.png";

@observer
class SupportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label717;
  }

  openLink = (link, type) => {
    return window.open(link, type);
  };

  open = () => {
    const { currentCompany } = this.props.store;
    this.props.store.getSingleUser()
    ? this.openLink(currentCompany.url_support_i,"_target")
    : this.openLink(currentCompany.url_support_m,"_target");
  };

  getAutologInLink = (redirect) => {
    const user = this.props.store.loggedInUser;
    this.props.store.kasimuAPIClient
      .search("/users/get_autologinlink_amember", {
        external_id: user.external_id,
      })
      .then((res) => {
        if (res.results.ok) {
          this.openLink(res.results.autologinlink+'?amember_redirect_url='+redirect, "_target");
        }
      });
  };

  openHelpDesk = () => {
    this.getAutologInLink("%2Famember%2Fhelpdesk");
  };

  openWhatsapp = () => {
    const { currentCompany } = this.props.store;
    this.openLink(currentCompany.url_whatsapp, "_target");
  };

  render() {
    const { currentCompany } = this.props.store;
    const desktopStyle = {
      maxWidth: "730px",
    };

    const mobileStyle = {
      maxWidth: "350px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 auto",
    };

    return (      
      <React.Fragment>
        <Columns>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label717}
              </Text>
            )}
            <div
              class="col"
              style={this.props.store.viewMobile ? desktopStyle : mobileStyle}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <div
                className="flex items-center"
                style={{
                  flexWrap: "wrap",
                  gap: "25px",
                  width: "100%",
                }}
              >
                {/* <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.store.language.label718,
                    }}
                    style={{
                      display: "inline",
                      marginRight: "4px",
                      lineHeight: "22px",
                    }}
                  ></div> */}

                <Button
                  className={
                    this.props.store.viewMobile ? "px-3" : "is-fullwidth"
                  }
                  onClick={() => {
                    this.open();
                  }}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                >
                  <strong>{this.props.store.language.label719}</strong>
                </Button>

                {this.props.store.showContent("kasimu") && (
                  <Button
                    className={
                      this.props.store.viewMobile
                        ? "px-3 mx-0"
                        : "is-fullwidth mx-0"
                    }
                    onClick={this.openHelpDesk}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                  >
                    <strong>{this.props.store.language.label733}</strong>
                  </Button>
                )}
              </div>

              <br />
              {currentCompany.url_whatsapp && (
                <Button
                  className={
                    this.props.store.viewMobile ? "px-3" : "is-fullwidth"
                  }
                  onClick={this.openWhatsapp}
                  color="confirm"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                >
                  <div className="flex items-center" style={{ gap: 8 }}>
                    <Img src={WhatsappLogo} width={24} />
                    <strong>{this.props.store.language.label734}</strong>
                  </div>
                </Button>
              )}
            </div>
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(SupportPage);
