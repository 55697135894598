import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import {
  Text,
  Button,
  Paragraph,
  Panel,
  TextInput,
  Loader,
} from "ks_storybook";
import {
  EmptyContent,
  DeleteConfirmationModal,
} from "../../../../Components/Common/";
import {
  GroupCard,
  GroupEditModal,
  GroupNewModal,
} from "../../../../Components/Group";

@observer
class GroupsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: this.getGroups(),
      showNewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      currentGroup: null,
      search: "",
    };
  }

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false, groups: this.getGroups() });
  };

  handleShowEditModal = (group) => {
    this.setState({ showEditModal: true, currentGroup: group });
  };

  handleHideEditModal = () => {
    this.setState({ showEditModal: false, currentGroup: null });
  };

  handleShowDeleteModal = (group) => {
    this.setState({ showDeleteModal: true, currentGroup: group });
  };

  handleHideDeleteModal = () => {
    this.setState({ showDeleteModal: false, currentGroup: null });
  };

  handleDeleteGroup = () => {
    this.setState({ groups: this.getGroups(), showEditModal: false });
    this.handleHideDeleteModal();
  };

  getGroups = () => {
    return this.props.store.groups.fetchGroups();
  };

  getSearchGroup = (groups, search) => {
    var arr = [];
    if (groups.length != 0) {
      arr = groups.filter(
        (e) => e.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  getPanel = () => {
    return (
      <Panel
        className="my-1"
        color="gray"
        padding={!this.props.store.viewMobile && "23px 20px"}
      >
        <Columns isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 9 }}>
            <TextInput
              onChange={this.handleChangeSearch}
              placeholder={this.props.store.language.label137}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="black"
              colorIcon="gray"
              invert
              reversed
            />
          </Column>
          <Column
            isSize={{ mobile: 12, tablet: 12, desktop: 3 }}
            style={{ paddingTop: !this.props.store.viewMobile && "0px" }}
          >
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className={`px-3`}
              onClick={this.handleShowNewModal}
            >
              <strong>{this.props.store.language.label138}</strong>
            </Button>
          </Column>
        </Columns>
      </Panel>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderHeader = () => {
    const { groups } = this.state;
    if (!groups) return this.renderEmptyContent();
    return !groups.isOk() ? this.renderLoader() : this.renderRowGroups();
  };

  renderRowGroups = () => {
    const { groups, search } = this.state;
    const arrayGroups = this.getSearchGroup(groups.toArray(), search);
    return (
      <React.Fragment>
        {arrayGroups.length === 0 ? (
          <EmptyContent />
        ) : (
          arrayGroups.map((group) => (
            <div style={{ lineBreak: "anywhere" }}>
              <GroupCard
                group={group}
                onEditClick={this.handleShowEditModal}
                onDeleteClick={this.handleShowDeleteModal}
              />
            </div>
          ))
        )}
      </React.Fragment>
    );
  };

  render() {
    const { showEditModal, showDeleteModal, currentGroup, showNewModal } =
      this.state;
    return (
      <React.Fragment>
        <Columns>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Text
              upper
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
              lineBreak
            >
              {this.props.store.language.label21}
            </Text>
            <div
              style={{ maxWidth: "730px" }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label136}
              </Paragraph>
            </div>
            {this.getPanel()}
            {this.renderHeader()}
          </Column>
        </Columns>
        {showEditModal && (
          <GroupEditModal
            group={currentGroup}
            onClose={this.handleHideEditModal}
          />
        )}
        {showDeleteModal && (
          <DeleteConfirmationModal
            currentObj={currentGroup}
            onClose={this.handleHideDeleteModal}
            onAccept={this.handleDeleteGroup}
            messageSuccess={
              this.props.store.language.label228 +
              " " +
              currentGroup.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label230 + " " + currentGroup.name
            }
            title={
              this.props.store.language.label231 + " " + currentGroup.name + "?"
            }
            subtitle={
              this.props.store.language.label232 + " " + currentGroup.name + "."
            }
          />
        )}
        {showNewModal && <GroupNewModal onClose={this.handleCloseNewModal} />}
      </React.Fragment>
    );
  }
}

export default withStore(GroupsListPage);
