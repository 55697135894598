import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Panel,
} from "ks_storybook";
import { EmptyContent } from "../Common/";
import { withToastManager } from "react-toast-notifications";
import moment from "moment-timezone";
import { Screen } from "../Screen";

@observer
class AccountEyeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userActivity: this.getUsersActivityAll(this.props.account.id),
    };
  }

  getUsersActivityAll = (userId) => {
    return this.props.store.appConnectionHistory.fetchAppConnectionHistory(
      5,
      userId,
    );
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getGroups = (values) => {
    return values.map((o) => o.name).join(", ");
  };

  getConnectionType = (value) => {
    switch (value) {
      case 0:
        return (
          <span className="danger mr-1">
            {this.props.store.language.label94.toUpperCase()}
          </span>
        );
      case 1:
        return (
          <span className="hibernating mr-1">
            {this.props.store.language.label94.toUpperCase()}
          </span>
        );
      case 2:
        return (
          <span className="danger mr-1">
            {this.props.store.language.label65.toUpperCase()}
          </span>
        );
      case 3:
        return (
          <span className="success mr-1">
            {this.props.store.language.label64.toUpperCase()}
          </span>
        );
      case 4:
        return (
          <span className="hibernating mr-1">
            {this.props.store.language.label96.toUpperCase()}
          </span>
        );
    }
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  });

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getInfoConnectionLog = (connection_log) => {
    var OS = ['','Windows','Android','WebPlayer'];
    var currentTime = moment.utc(connection_log.updated_at).format();
    var userTimeZone = moment.tz.guess();
    var localTime = moment.tz(currentTime, userTimeZone).format('dddd, DD MMMM YYYY, h:mm:ss a');  
    var log = localTime+ " | " +OS[connection_log.os_number]+", "+this.props.store.companies[connection_log.reseller_companies].name+" "+connection_log.version_app_str;
    return log;
  };

  renderUserActivity = (activity) => {
    return (
      <>
        {activity && activity.length !== 0 ? (
          activity.map((connection_log) => (
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              {this.getConnectionType(connection_log.connection_type)}
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {this.getInfoConnectionLog(connection_log)}
              </Text>
            </div>
          ))
        ) : (
          <Text className="has-text-centered mt-5" size="sm" lead>
            {this.props.store.language.label66}
          </Text>
        )}
      </>
    );
  };

  renderHeader = () => {
    const { userActivity } = this.state;
    if (!userActivity) return this.renderEmptyContent();
    return !userActivity.isOk()
      ? this.renderLoader()
      : this.renderUserActivity(userActivity.toArray());
  };

  getAccountCreatedAt = (account) => {
    if (account.created_at) {
      var currentTime = moment.utc(account.created_at).format();
      var userTimeZone = moment.tz.guess();
      var localTime = moment.tz(currentTime, userTimeZone).format('dddd, DD MMMM YYYY, h:mm:ss a');  
      return localTime;
    } 
    return null;
  };

  renderScreen = () => {
    const { account } = this.props;
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label729}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        <Panel color="gray" className="my-1" padding={"32px 18px"}>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label88 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.username}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label765 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.description === ""
                ? this.props.store.language.label89
                : account.description}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label655 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.encrypted_password}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label54 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.email === ""
                ? this.props.store.language.label89
                : account.email}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label656 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.country === "" || !account.country
                ? this.props.store.language.label89
                : account.country}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label657 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.province === "" || !account.province
                ? this.props.store.language.label89
                : account.province}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label658 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.city === "" || !account.city
                ? this.props.store.language.label89
                : account.city}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label72 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.groups.length === 0
                ? this.props.store.language.label73
                : this.getGroups(account.groups)}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label90 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {this.getAccountCreatedAt(account)}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label91 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.locking_pin === null
                ? this.props.store.language.label92
                : account.locking_pin === 0
                ? this.props.store.language.label92
                : account.locking_pin}
            </Text>
          </div>
          <br />
          <Text
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            upper
            multiline
          >
            {this.props.store.language.label93 + ": "}
          </Text>
          {this.renderHeader()}
        </Panel>
      </Screen>
    );
  };

  render() {
    moment.updateLocale("es", {
      months: this.props.store.language.label59.split(","),
      monthsShort: this.props.store.language.label60.split(","),
      weekdays: this.props.store.language.label61.split(","),
      weekdaysShort: this.props.store.language.label62.split(","),
      weekdaysMin: this.props.store.language.label63.split(","),
    });
    const { account } = this.props;
    return this.props.store.viewMobile ? (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "750px" : "90%"}
        height={this.props.store.viewMobile ? "500px" : "auto"}
      >
        <ModalHeader>
          <div className="flex items-center" style={{ flexWrap: "wrap" }}>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              className="mr-2"
            >
              {this.props.store.language.label87}
            </Text>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="regular"
              className="mr-2"
            >
              {account.username}
            </Text>
          </div>
        </ModalHeader>
        <ModalContent>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label88 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.username}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label765 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.description === ""
                ? this.props.store.language.label89
                : account.description}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label655 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.encrypted_password}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label54 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.email === ""
                ? this.props.store.language.label89
                : account.email}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label656 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.country === "" || !account.country
                ? this.props.store.language.label89
                : account.country}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label657 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.province === "" || !account.province
                ? this.props.store.language.label89
                : account.province}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label658 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.city === "" || !account.city
                ? this.props.store.language.label89
                : account.city}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label72 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.groups.length === 0
                ? this.props.store.language.label73
                : this.getGroups(account.groups)}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label90 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {this.getAccountCreatedAt(account)}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {this.props.store.language.label91 + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.locking_pin === null
                ? this.props.store.language.label92
                : account.locking_pin === 0
                ? this.props.store.language.label92
                : account.locking_pin}
            </Text>
          </div>
          <br />
          <Text
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            upper
            multiline
          >
            {this.props.store.language.label93 + ": "}
          </Text>
          <div style={this.getContainerStyle()}>{this.renderHeader()}</div>
        </ModalContent>
        <ModalFooter />
      </Modal>
    ) : (
      this.renderScreen()
    );
  }
}

AccountEyeModal.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
};

AccountEyeModal.defaultProps = {
  account: null,
  onClose: null,
};

export default withStore(withToastManager(AccountEyeModal));
