import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Columns,
  Column,
  Text,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
} from "ks_storybook";
import ReactTooltip from "react-tooltip";
import withStore from "../../hocs/withStore";

class SupervisorCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleEditClick = () => {
    const { supervisor, onEditClick } = this.props;
    onEditClick && onEditClick(supervisor);
  };

  handleDeleteClick = () => {
    const { supervisor, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(supervisor);
  };

  handlePermissionsClick = () => {
    const { supervisor, onPermissionsClick } = this.props;
    onPermissionsClick && onPermissionsClick(supervisor);
  };

  handleSuspendClick = () => {
    const { supervisor, onSuspendClick } = this.props;
    onSuspendClick && onSuspendClick(supervisor);
  };

  getNameGroups = (group_id, groups) => {
    if (groups.length > 0 && group_id) {
      try {
        let arr = JSON.parse(group_id);
        let name = groups.find((e) => e.value == arr[0]);
        return name ? name.label : null;
      } catch (e) {
        return null;
      }
    }
    return null;
  };

  getStatusColor = (supervisor) => {
    var color = "basic";
    if (supervisor.status == 1) {
      color = "confirm";
    } else {
      color = "warning";
    }

    return color;
  };

  getTooltipMsg = (supervisor) => {
    var msg = "";
    if (supervisor.status == 1) {
      msg = this.props.store.language.label68;
    } else {
      msg = this.props.store.language.label738;
    }
    return msg;
  };

  render() {
    const { supervisor, groups } = this.props;
    if (supervisor.isEmpty()) return null;
    return (
      <React.Fragment>
        <div
          style={{
            padding: this.props.store.viewMobile ? "25px 30px" : "15px 20px",
            background: "#1F1F1F",
            marginBottom: this.props.store.viewMobile ? "8px" : "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 75,
            backgroundColor: this.state.open ? "#D33F20" : "#1F1F1F",
            borderRadius: this.props.store.viewMobile ? "10px" : "5px",
          }}
        >
          <Columns isMobile isVCentered isMarginless isGapless>
            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              <div className="flex">
                {this.state.open ? (
                  <div
                    style={{
                      display: "flex",
                      gap: 35,
                    }}
                  >
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={"xl"}
                        iconKasimu={
                          this.props.store.viewMobile ? "pen" : "pen-solid"
                        }
                        onClick={this.handleEditClick}
                        space
                      />
                    </div>
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={"xl"}
                        iconKasimu={
                          this.props.store.viewMobile ? "trash" : "trash-solid"
                        }
                        onClick={this.handleDeleteClick}
                        space
                      />
                    </div>
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={"xl"}
                        icon="ban"
                        onClick={this.handlePermissionsClick}
                        space
                      ></Button>
                    </div>
                    <div>
                      <Button
                        kind="link"
                        color="basic"
                        size={"xl"}
                        icon={supervisor.status === 1 ? "user-slash" : "user"}
                        onClick={this.handleSuspendClick}
                        space
                      ></Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div
                      className="flex items-center"
                      data-tip
                      data-for={`supervisor-${supervisor.id}`}
                    >
                      <Button
                        iconKasimu="user-solid"
                        kind="link"
                        color={this.getStatusColor(supervisor)}
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        padding="0"
                        className="mr-2"
                      />

                      {this.props.store.viewMobile && (
                        <ReactTooltip
                          id={`supervisor-${supervisor.id}`}
                          place="bottom"
                          type="dark"
                          effect="float"
                        >
                          <span>{this.getTooltipMsg(supervisor)}</span>
                        </ReactTooltip>
                      )}
                    </div>
                    <div>
                      <Text
                        weight="medium"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {supervisor.username}
                      </Text>
                      {supervisor.observations && (
                        <>
                          <Text
                            color="special"
                            size={this.props.store.viewMobile ? "xl" : "md"}
                            lineBreak
                          >
                            {supervisor.observations}
                          </Text>
                        </>
                      )}
                      <Text
                        color="special"
                        size={this.props.store.viewMobile ? "xl" : "md"}
                        lineBreak
                      >
                        {this.getNameGroups(supervisor.group_id, groups)}
                      </Text>
                      {/* <Text
                      color="special"
                      size={this.props.store.viewMobile ? "xl" : "md"}
                      lineBreak
                    >
                      {supervisor.status == 1
                        ? this.props.store.language.label68
                        : this.props.store.language.label738}
                    </Text> */}
                    </div>
                  </div>
                )}
              </div>
            </Column>
          </Columns>
          {!this.props.store.viewMobile ? (
            <>
              {this.state.open ? (
                <Button
                  kind="link"
                  color="basic"
                  size={"lg"}
                  iconKasimu={"arrow-left"}
                  space
                  onClick={() => this.setState({ open: false })}
                />
              ) : (
                <Button
                  kind="link"
                  color="special"
                  size={"lg"}
                  iconKasimu={"arrow-right"}
                  space
                  onClick={() => this.setState({ open: true })}
                />
              )}
            </>
          ) : (
            <div style={{ display: "flex" }}>
              <Button
                className="ml-3"
                iconKasimu="pen"
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                lead
                space
                onClick={this.handleEditClick}
              />
              <Button
                data-for="trash"
                className="ml-2"
                iconKasimu="trash"
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                lead
                space
                onClick={this.handleDeleteClick}
              />
              <div className="dropdown-buttons flex flex-col my-1">
                <Dropdown direction="right-center" space disabled={false}>
                  <DropdownToggle disabledArrow>
                    <Button
                      className="ml-2"
                      kind="link"
                      color={"basic"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      iconKasimu={"three-dots"}
                      space
                      disabled={false}
                    />
                  </DropdownToggle>
                  <DropdownPanel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "4px 10px",
                      }}
                    >
                      <div>
                        <Button
                          className="mt-1"
                          // icon="sliders-h"
                          kind="link"
                          color="basic"
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          lead
                          space
                          onClick={this.handlePermissionsClick}
                          disabled={false}
                        >
                          {this.props.store.language.label854}
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="mt-1"
                          // icon="sliders-h"
                          kind="link"
                          color="basic"
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          lead
                          space
                          onClick={this.handleSuspendClick}
                          disabled={false}
                        >
                          {supervisor.status === 1
                            ? this.props.store.language.label736
                            : this.props.store.language.label737}
                        </Button>
                      </div>
                    </div>
                  </DropdownPanel>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

SupervisorCard.propTypes = {
  supervisor: PropTypes.object,
  groups: PropTypes.Array,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onPermissionsClick: PropTypes.func,
  onSuspendClick: PropTypes.func,
};

SupervisorCard.defaultProps = {
  supervisor: null,
  groups: [],
  onDeleteClick: null,
  onEditClick: null,
  onPermissionsClick: null,
  onSuspendClick: null,
};

export default withStore(SupervisorCard);
