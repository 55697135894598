import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  RectSkeleton,
  Button,
  CardEvents,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
} from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class UsersStylesRow extends Component {
  state = {
    open: false,
  };

  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDelete = () => {
    const { style, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(style);
  };

  handleEdit = () => {
    const { style, onEditClick } = this.props;
    onEditClick && onEditClick(style);
  };

  render() {
    const { style } = this.props;
    if (style.isEmpty()) {
      return this.renderSkelenton();
    }
    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <CardEvents
          name={style.name}
          isMobile={!this.props.store.viewMobile}
          padding={!this.props.store.viewMobile && "22px 20px"}
          style={{
            height: 65,
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
          content={
            this.state.open && (
              <div
                style={{
                  display: "flex",
                  gap: 35,
                }}
              >
                <div>
                  <Button
                    kind="link"
                    color="basic"
                    size={"xl"}
                    iconKasimu={
                      this.props.store.viewMobile ? "pen" : "pen-solid"
                    }
                    onClick={this.handleEdit}
                    space
                  />
                </div>
                <div>
                  <Button
                    kind="link"
                    color="basic"
                    size={"xl"}
                    iconKasimu={
                      this.props.store.viewMobile ? "trash" : "trash-solid"
                    }
                    onClick={this.handleDelete}
                    space
                  />
                </div>
              </div>
            )
          }
        >
          {!this.props.store.viewMobile ? (
            <>
              {this.state.open ? (
                <Button
                  kind="link"
                  color="basic"
                  size={"lg"}
                  iconKasimu={"arrow-left"}
                  space
                  onClick={() => this.setState({ open: false })}
                />
              ) : (
                <Button
                  kind="link"
                  color="special"
                  size={"lg"}
                  iconKasimu={"arrow-right"}
                  space
                  onClick={() => this.setState({ open: true })}
                />
              )}
            </>
          ) : (
            <div style={{ display: "flex" }} className="ml-2">
              <Button
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="pen"
                onClick={this.handleEdit}
                space
              />
              <Button
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="trash"
                onClick={this.handleDelete}
                space
              />
            </div>
          )}
        </CardEvents>
      </div>
    );
  }
}

UsersStylesRow.propTypes = {
  style: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

UsersStylesRow.defaultProps = {
  style: null,
  onEditClick: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(UsersStylesRow));
