import _logo from "../assets/images/bitproximity.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const BITPROXIMITY = {
    logo: _logo,
    name: 'Bitproximity',
    favicon: AWS_BUCKET+'/favicon/bitproximity.ico',
    web: 'www.bitproximity.com',
    supportContact: 'mario@bitproximity.com',
    url_downloads: null,
    url_web_player_p: 'https://webplayer.bitproximity.com',
    url_web_player_t: 'https://webplayertest.megaplayer.me/session/signIn/8',
    url_install_win: AWS_BUCKET+'/installers/install_bitproximity_',
    url_install_macos: null,
    url_google_play_store: 'https://play.google.com/store/apps/details?id=com.bitproximity.bitproximity',
    url_aptoide: null,
    url_apk_x32: AWS_BUCKET+'/installers/app_bitproximity_x32.apk',
    url_apk_x64: AWS_BUCKET+'/installers/app_bitproximity_x64.apk',
    url_support_i: 'https://bitproximity.com/contacto',
    url_support_m: 'https://bitproximity.com/contacto',
    url_whatsapp: null,
    url_certificate:  AWS_BUCKET+'/ks_certificate/bitproximity_certificado_freedom.pdf'
}

export default BITPROXIMITY;