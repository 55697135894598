import React, { Component } from "react";
import PropTypes from "prop-types";
import { RectSkeleton, Button, CardEvents } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class PPClocksRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDeleteClock = () => {
    const { ppClocks, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(ppClocks);
  };

  handleModalBlocksOpen = () => {
    const { ppClocks, onEyeClick } = this.props;
    onEyeClick && onEyeClick(ppClocks);
  };

  handleModalPPClocksEdit = () => {
    const { ppClocks, onEditClick } = this.props;
    onEditClick && onEditClick(ppClocks);
  };

  render() {
    const { ppClocks } = this.props;
    if (!ppClocks) return null;
    if (ppClocks.isEmpty()) {
      return this.renderSkelenton();
    }

    return (
      <React.Fragment>
        <CardEvents name={ppClocks.name} invert>
          <div style={{ padding: "0px 10px" }}>
            <Button
              kind="link"
              color="special"
              size="xl"
              iconKasimu="eye"
              onClick={this.handleModalBlocksOpen}
              space
            />
            <Button
              kind="link"
              color="black"
              size="xl"
              iconKasimu="pen"
              onClick={this.handleModalPPClocksEdit}
              space
            />
            <Button
              kind="link"
              color="black"
              size="xl"
              iconKasimu="trash"
              onClick={this.handleDeleteClock}
              space
            />
          </div>
        </CardEvents>
      </React.Fragment>
    );
  }
}

PPClocksRow.propTypes = {
  ppClocks: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onEyeClick: PropTypes.func,
};

PPClocksRow.defaultProps = {
  ppClocks: null,
  onDeleteClick: null,
  onEditClick: null,
  onEyeClick: null,
};

export default withStore(withToastManager(PPClocksRow));
