import { Store } from "ks_storybook";
import { UserSettings } from "../models";

class UserSettingsStore extends Store {
  model = UserSettings;

  getUserSettings(value) {
    return this.search(
      { per_page: 1000 },
      "users_settings",
      true,
      `/users_settings/${value}/get_settings_user`
    );
  }
}

export default UserSettingsStore;
