import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import {
  Text,
  Panel,
  Button,
  Toggle,
  Field,
  Loader,
  Paragraph,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import {
  PlaylistPlanRow,
  PlaylistPlanModal,
  DeleteAllDayPlansConfirmationModal,
} from "../../../../Components/Plans";
import { Select } from "../../../../Components/Select";
import { withToastManager } from "react-toast-notifications";

@observer
class PlaylistsPlanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: this.getAccounts(),
      userId: this.props.store.getSingleUser()
        ? this.props.store.loggedInUser.id
        : null,
      showModal: false,
      showDeletedAllModal: false,
      weekDay: 1,
      playlistsPlans: this.props.store.getSingleUser()
        ? this.getPlaylistsPlans(this.props.store.loggedInUser.id)
        : null,
      hibernationPlans: this.props.store.getSingleUser()
        ? this.getHibernationPlans(this.props.store.loggedInUser.id)
        : null,
      datesSelected: [],
    };
    this.plans = [];
    this.weekdays = this.props.store.language.label61.split(",");
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label37;
  }

  getAccounts = () => {
    return this.props.store.users.fetchAccounts();
  };

  getPPlans = (plans) => {
    return plans.toArray().map((plan) => ({
      id: plan.id,
      day: plan.day,
      start_hour: plan.start_hour,
      finish_hour: plan.finish_hour,
      categories_playlists: plan.categories_playlists,
      playlist_type: plan.playlist_type,
      playlist_planner_on: plan.playlist_planner_on,
      owner: plan.owner,
      type: 1,
    }));
  };

  getHPlans = (plans) => {
    return plans.toArray().map((plan) => ({
      id: plan.id,
      day: plan.day,
      start_hour: plan.start_hour,
      finish_hour: plan.finish_hour,
      categories_playlists: null,
      playlist_type: null,
      playlist_planner_on: plan.playlist_planner_on,
      owner: null,
      type: 2,
    }));
  };

  getOptions = (accounts) => {
    return accounts
      .toArray()
      .map((account) => ({ value: account.id, label: account.username }));
  };

  handleUserChange = (value) => {
    const originId = value && value.value;
    if (originId) {
      this.setState({ userId: originId });
      this.setState({
        playlistsPlans: this.getPlaylistsPlans(originId),
        hibernationPlans: this.getHibernationPlans(originId),
      });
    } else {
      this.setState({ userId: null });
      this.setState({ playlistsPlans: null });
    }
    this.setState({ datesSelected: [] });
  };

  handleChange = (sender, name, value) => {
    const actionPlaner = value ? 1 : 0;
    const messageSuccess =
      actionPlaner == 1
        ? this.props.store.language.label314
        : this.props.store.language.label315;
    const messageError =
      actionPlaner == 1
        ? this.props.store.language.label316
        : this.props.store.language.label317;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_playlist_planner_on_user",
        "?user_id=" + this.state.userId + "&playlist_planner_on=" + actionPlaner
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleModalOpen = (sender) => {
    this.setState({ showModal: true, weekDay: sender.props.name });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleDeleteAllModalOpen = (sender) => {
    this.setState({ showDeletedAllModal: true, weekDay: sender.props.name });
  };

  handleDeleteAllModalClose = () => {
    this.setState({ showDeletedAllModal: false });
  };

  handleReload = () => {
    this.setState({
      playlistsPlans: this.getPlaylistsPlans(this.state.userId),
      hibernationPlans: this.getHibernationPlans(this.state.userId),
      showDeletedAllModal: false,
    });
  };

  handleSave = () => {
    this.setState({
      playlistsPlans: this.getPlaylistsPlans(this.state.userId),
      hibernationPlans: this.getHibernationPlans(this.state.userId),
      showModal: false,
    });
  };

  validHours = (startHour, finishHour, start_hour, finish_hour) => {
    if (start_hour <= finishHour && finish_hour >= startHour) return true;
    return false;
  };

  handleValid = (days, start_hour, finish_hour) => {
    let arr = this.plans.filter((plan) => days.includes(plan.day));
    let res = arr.filter((plan) =>
      this.validHours(
        start_hour,
        finish_hour,
        plan.start_hour,
        plan.finish_hour
      )
        ? plan
        : null
    );
    return res;
  };

  validLength = (day) => {
    let arr = this.plans.filter((plan) => plan.day === day);
    if (arr.length === 0) return true;
    return false;
  };

  handleUpdating = () => {
    this.setState({
      playlistsPlans: this.getPlaylistsPlans(this.state.userId),
      hibernationPlans: this.getHibernationPlans(this.state.userId),
    });
  };

  getPlaylistsPlans = (value) => {
    return this.props.store.playlistsPlans.getPlaylistPlansUser(
      value,
      this.props.store.lang
    );
  };

  getHibernationPlans = (value) => {
    return this.props.store.userHibernation.getHibernationPlansUser(value);
  };

  renderToogleActivatePlanner = (plan) => {
    return (
      <React.Fragment>
        <div class="column py-3">
          <Text
            upper
            color={plan.playlist_planner_on ? "green" : "red"}
            size="lg"
          >
            {plan.playlist_planner_on
              ? this.props.store.language.label318
              : this.props.store.language.label319}
          </Text>
        </div>
        <div class="column py-2">
          <Toggle
            onChange={this.handleChange}
            checked={plan.playlist_planner_on ? true : false}
          />
        </div>
      </React.Fragment>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderToogle = (plan) => {
    return (
      <React.Fragment>
        <div
          className={`is-pulled-left ${
            this.props.store.viewMobile ? "py-2" : "py-1"
          }`}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Toggle
            noIcons
            onChange={this.handleChange}
            checked={plan.playlist_planner_on ? true : false}
          />

          <div className="ml-2">
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              weight="medium"
            >
              {plan.playlist_planner_on
                ? this.props.store.language.label318
                : this.props.store.language.label319}
            </Text>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderFrame = (plans, weekDay) => {
    return (
      <React.Fragment>
        <Panel
          color="grayDark"
          className="mt-1"
          padding={this.props.store.viewMobile ? "10px 25px" : "5px 15px"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                name="arrow-down"
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                icon={
                  this.state.datesSelected.some((e) => e == weekDay)
                    ? "chevron-down"
                    : "chevron-up"
                }
                space
                onClick={() => {
                  let valid = this.state.datesSelected.indexOf(weekDay);

                  if (valid >= 0) {
                    this.setState({
                      datesSelected: this.state.datesSelected.filter(
                        (i) => i !== weekDay
                      ),
                    });
                  } else {
                    this.setState({
                      datesSelected: [...this.state.datesSelected, weekDay],
                    });
                  }
                }}
              />
              <div className="ml-2">
                <Text
                  weight="strong"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                >
                  {this.getTitleForWeekDay(weekDay)}
                </Text>
              </div>
            </div>
            <div className="flex">
              <Button
                name={weekDay}
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="sum"
                space
                onClick={this.handleModalOpen}
              />
              <Button
                disabled={this.validLength(weekDay)}
                name={weekDay}
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="trash"
                onClick={this.handleDeleteAllModalOpen}
                space
              />
            </div>
          </div>
        </Panel>
        {this.state.datesSelected.some((e) => e == weekDay) ? null : (
          <>
            {plans.length > 0
              ? plans
                  .filter((p) => p.day === weekDay)
                  .map((p) => (
                    <PlaylistPlanRow plan={p} onDeleted={this.handleReload} />
                  ))
              : null}
          </>
        )}
      </React.Fragment>
    );
  };

  renderHeaderOk = () => {
    const { playlistsPlans, hibernationPlans } = this.state;
    const options = ["1", "2", "3", "4", "5", "6", "7"];
    const pplans = this.getPPlans(playlistsPlans);
    const hplans = this.getHPlans(hibernationPlans);
    const arrayPlans = pplans.concat(hplans);
    arrayPlans.sort(function (a, b) {
      return parseInt(a.start_hour) - parseInt(b.start_hour);
    });
    this.plans = arrayPlans;
    return (
      <React.Fragment>
        {this.plans.length > 0 && this.renderToogle(this.plans[0])}
        {options.map((i) => this.renderFrame(this.plans, i))}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { playlistsPlans } = this.state;
    if (!playlistsPlans) return this.renderEmptyContent();
    return !playlistsPlans.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getTitleForWeekDay = (weekDay) => {
    const titles = {
      1: this.weekdays[1],
      2: this.weekdays[2],
      3: this.weekdays[3],
      4: this.weekdays[4],
      5: this.weekdays[5],
      6: this.weekdays[6],
      7: this.weekdays[0],
    };
    return titles[weekDay];
  };

  renderSelect = (optionsSelect) => {
    return (
      <React.Fragment>
        <Panel
          className={this.props.store.viewMobile ? "my-2" : "my-1"}
          color="gray"
          padding={!this.props.store.viewMobile && "23px 20px"}
        >
          <div
            className="is-row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "100%" }}>
              <Field
                upper
                marginH={this.props.store.viewMobile && "10px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                label={this.props.store.language.label17}
                labelNote={this.props.store.language.label53}
              >
                <Select
                  onChange={this.handleUserChange}
                  placeholder={this.props.store.language.label175}
                  isClearable
                  options={optionsSelect}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </Field>
            </div>
            {this.state.userId ? (
              <div
                className="is-pulled-right mt-3 ml-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Button
                  kind="link"
                  color="orange"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  icon="redo-alt"
                  onClick={this.handleReload}
                  space={!this.props.store.viewMobile}
                />
              </div>
            ) : null}
          </div>
        </Panel>
      </React.Fragment>
    );
  };

  render() {
    const { accounts, userId, weekDay } = this.state;
    const optionsSelect = this.getOptions(accounts);
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                className="mr-2"
              >
                {this.props.store.language.label37}
              </Text>
            )}
            {/* <div
              style={{
                maxWidth: "650px",
              }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label176}
              </Paragraph>
            </div> */}
            {this.props.store.getSingleUser()
              ? null
              : this.renderSelect(optionsSelect)}
            {this.renderHeader()}
          </Column>
        </Columns>
        {this.state.showModal && (
          <PlaylistPlanModal
            weekDay={weekDay}
            userId={userId}
            onClose={this.handleModalClose}
            onSave={this.handleSave}
            onValid={this.handleValid}
          />
        )}
        {this.state.showDeletedAllModal && (
          <DeleteAllDayPlansConfirmationModal
            weekDay={weekDay}
            userId={userId}
            messageSuccess={
              this.props.store.language.label665 +
              this.getTitleForWeekDay(weekDay) +
              "."
            }
            messageError={
              this.props.store.language.label666 +
              this.getTitleForWeekDay(weekDay) +
              "."
            }
            title={
              this.getTitleForWeekDay(weekDay) +
              ", " +
              this.props.store.language.label667
            }
            subtitle={
              this.props.store.language.label668 +
              this.getTitleForWeekDay(weekDay) +
              "."
            }
            onAccept={this.handleReload}
            onClose={this.handleDeleteAllModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withToastManager(withStore(PlaylistsPlanner));
