import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import withStore from "../../hocs/withStore";
import { Button, Text, Panel, Checkbox } from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class SongsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleEditClick = () => {
    const { editClick, song } = this.props;
    editClick && editClick(song);
  };

  handleAddClick = () => {
    const { addClick, song } = this.props;
    addClick && addClick(song);
  };

  handleFavClick = () => {
    const { favClick, song } = this.props;
    favClick && favClick(song);
  };

  handleBanClick = () => {
    const { banClick, song } = this.props;
    banClick && banClick(song);
  };

  renderCheckbox = (value, song) => {
    return (
      <>
        {value ? (
          () => ({})
        ) : (
          <Checkbox
            className={this.props.store.viewMobile ? "ml-3" : "ml-2"}
            size="lg"
            onChange={(sender, value) => {
              if (value) {
                this.props.arraySongId.push(song.id);
                this.props.arrayClick(this.props.arraySongId);
              } else {
                const index = this.props.arraySongId.indexOf(song.id);
                if (index > -1) {
                  this.props.arraySongId.splice(index, 1);
                  this.props.arrayClick(this.props.arraySongId);
                }
              }
            }}
            isChecked={value}
          />
        )}
        {value && (
          <Checkbox
            className="ml-3"
            size="lg"
            onChange={(sender, value) => {
              if (value) {
                this.props.arraySongId.push(song.id);
              } else {
                const index = this.props.arraySongId.indexOf(song.id);
                if (index > -1) {
                  this.props.arraySongId.splice(index, 1);
                }
              }
            }}
            isChecked={value}
          />
        )}
      </>
    );
  };

  render() {
    const {
      song,
      arraySongId,
      active,
      indice,
      handleStartPlayingClick,
      handleTogglePlay,
      userEdit,
      userFav,
      userBan,
    } = this.props;
    return (
      <Panel
        color="gray"
        padding={0}
        className={this.props.store.viewMobile && "mb-1"}
        style={{
          marginBottom: !this.props.store.viewMobile && "3px",
          backgroundColor: this.state.open && "#D33F20",
        }}
      >
        <div
          className="is-row is-fullwidth"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            height: "65px",
            padding: this.props.store.viewMobile ? "20px 32px" : "22px 20px",
          }}
        >
          <div className="is-row" style={{ alignItems: "center" }}>
            {this.state.open ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 35,
                }}
              >
                <div>
                  <Button
                    kind="link"
                    color={"basic"}
                    size={this.props.store.viewMobile ? "xl" : "xl"}
                    iconKasimu={
                      this.props.store.viewMobile ? "pen" : "pen-solid"
                    }
                    onClick={this.handleEditClick}
                    space
                  />
                </div>
                <div>
                  <Button
                    kind="link"
                    color="basic"
                    size={this.props.store.viewMobile ? "xl" : "xl"}
                    iconKasimu={
                      this.props.store.viewMobile ? "sum" : "sum-round"
                    }
                    onClick={this.handleAddClick}
                    space
                  />
                </div>
                <div>
                  <Button
                    kind="link"
                    color={"basic"}
                    size={this.props.store.viewMobile ? "xl" : "xl"}
                    iconKasimu={
                      this.props.store.viewMobile ? "heart" : "heart-solid"
                    }
                    onClick={this.handleFavClick}
                    space
                  />
                </div>
                <div>
                  <Button
                    kind="link"
                    color={"basic"}
                    size={this.props.store.viewMobile ? "xl" : "xl"}
                    iconKasimu={
                      this.props.store.viewMobile ? "ban" : "ban-round"
                    }
                    onClick={this.handleBanClick}
                    space
                  />
                </div>
              </div>
            ) : (
              <>
                <Button
                  className={this.props.store.viewMobile ? "mr-3" : "mr-2"}
                  icon={
                    active && this.props.store.ui.isPlaying ? "pause" : "play"
                  }
                  kind="link"
                  color={active ? "primary" : "basic"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  onClick={
                    active && this.props.store.ui.showPlayer
                      ? () => handleTogglePlay(song.song_path)
                      : () => handleStartPlayingClick(indice, song.song_path)
                  }
                  id="play-button"
                  lead
                  space
                />
                <div
                  className="is-fullwidth"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text
                    multiline
                    weight="normal"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    color={active ? "primary" : "white"}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {song.users_songs_edit
                        ? song.users_songs_edit.song_name
                        : song.name}{" "}
                      -{" "}
                    </span>
                    <span style={{ fontWeight: "lighter", color: "#999999" }}>
                      {song.singer.name}
                    </span>
                  </Text>
                </div>
              </>
            )}
          </div>
          <div className="flex items-center">
            {!this.props.store.viewMobile ? (
              <>
                <Button
                  kind="link"
                  color={this.state.open ? "basic" : "special"}
                  size={!this.props.store.viewMobile ? "lg" : "xl"}
                  iconKasimu={this.state.open ? "arrow-left" : "arrow-right"}
                  onClick={() => this.setState({ open: !this.state.open })}
                  space
                />
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                }}
              >
                <Button
                  kind="link"
                  color={userEdit ? "primary" : "basic"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="pen"
                  onClick={this.handleEditClick}
                  space
                />
                <Button
                  kind="link"
                  color="basic"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="sum"
                  onClick={this.handleAddClick}
                  space
                />
                <Button
                  kind="link"
                  color={userFav ? "primary" : "basic"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="heart"
                  onClick={this.handleFavClick}
                  space
                />
                <Button
                  kind="link"
                  color={userBan ? "primary" : "basic"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  iconKasimu="ban"
                  onClick={this.handleBanClick}
                  space
                />
              </div>
            )}
            {this.renderCheckbox(arraySongId.includes(song.id), song)}
          </div>
        </div>
        <div
          style={{
            padding: active
              ? this.props.store.viewMobile
                ? "0px 32px"
                : "0px 20px"
              : 0,
          }}
        >
          {active && this.props.store.ui.showPlayer && (
            <div
              className={"mx-1 mt-1"}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                {this.props.store.ui.currentDuration == "NaN:NaN"
                  ? "00:00 / 00:00"
                  : this.props.store.ui.currentDuration &&
                    this.props.store.ui.currentProgressTime &&
                    this.props.store.ui.currentProgressTime}
              </Text>

              <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                {this.props.store.ui.currentDuration == "NaN:NaN"
                  ? "00:00 / 00:00"
                  : this.props.store.ui.currentDuration &&
                    this.props.store.ui.currentProgressTime &&
                    this.props.store.ui.currentDuration}
              </Text>
            </div>
          )}

          <div
            id={`waveform` + indice}
            style={{
              height: active && this.props.store.ui.showPlayer ? 100 : 0,
            }}
          />
        </div>
      </Panel>
    );
  }
}

SongsCard.propTypes = {
  song: PropTypes.object,
  arraySongId: PropTypes.array,
  userEdit: PropTypes.bool,
  userFav: PropTypes.bool,
  userBan: PropTypes.bool,
  editClick: PropTypes.func,
  addClick: PropTypes.func,
  favClick: PropTypes.func,
  banClick: PropTypes.func,
  arrayClick: PropTypes.func,
};

SongsCard.defaultProps = {
  song: null,
  arraySongId: [],
  userEdit: false,
  userFav: false,
  userBan: false,
  editClick: null,
  addClick: null,
  favClick: null,
  banClick: null,
  arrayClick: null,
};

export default withStore(withToastManager(SongsCard));
