import React, { Component } from "react";
import PropTypes from "prop-types";
import { RectSkeleton, CardMedios } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class PPAdsRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDeleteAd = () => {
    const { ppAd, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(ppAd);
  };

  handleModalVersionsOpen = () => {
    const { ppAd, onVersionsClick } = this.props;
    onVersionsClick && onVersionsClick(ppAd);
  };

  handleEdit = () => {
    const { ppAd, onEditClick } = this.props;
    onEditClick && onEditClick(ppAd);
  };

  getPriority = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label381;
      default:
        return this.props.store.language.label382;
    }
  };

  getLocations = (values) => {
    return values
      .map((o) => (o.group_id !== null ? o.info.name : o.info.name))
      .join(", ");
  };

  getPosts = (value) => {
    if (value == -1) return this.props.store.language.label196;
    else return value;
  };

  render() {
    const { ppAd } = this.props;
    if (!ppAd) return null;
    if (ppAd.isEmpty()) {
      return this.renderSkelenton();
    }

    return (
      <React.Fragment>
        <CardMedios
          name={ppAd.name}
          companies={ppAd.companies.name}
          trademarks={ppAd.trademarks.name}
          media_type={ppAd.media_type.name}
          priority={this.getPriority(ppAd.priority)}
          schedule_from={ppAd.schedule_from}
          schedule_to={ppAd.schedule_to}
          posts={this.getPosts(ppAd.posts)}
          locations={
            ppAd.locations_type == 2
              ? this.getLocations(ppAd.locations)
              : this.props.store.language.label102
          }
          versions_ads={ppAd.versions_ads.length}
          deletedClick={this.handleDeleteAd}
          editClick={this.handleEdit}
          versionsClick={this.handleModalVersionsOpen}
          responsive={!this.props.store.viewMobile}
          arrayText={[
            this.props.store.language.label186,
            this.props.store.language.label187,
            this.props.store.language.label192,
            this.props.store.language.label190,
            this.props.store.language.label168,
            this.props.store.language.label380,
            this.props.store.language.label191,
            this.props.store.language.label194,
            this.props.store.language.label195,
          ]}
          isMobile={!this.props.store.viewMobile}
        />
      </React.Fragment>
    );
  }
}

PPAdsRow.propTypes = {
  ppAd: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onVersionsClick: PropTypes.func,
};

PPAdsRow.defaultProps = {
  ppAd: null,
  onEditClick: null,
  onDeleteClick: null,
  onVersionsClick: null,
};

export default withStore(withToastManager(PPAdsRow));
