import React, { Component, useRef, useLayoutEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  RadioGroup,
  Radio,
  Toggle,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { UserPlaylists } from "../../models";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";

function getStyle({ provided, style, isDragging, columnId }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const columnOne = columnId == "column-1";

  const grid = 8;
  const marginBottom = 5;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    background: isDragging ? "#12C889" : columnOne ? "#2E2E2E" : "#1F1F1F",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${grid * 3}px`,
    borderRadius: grid,
    marginBottom,
  };
  return withSpacing;
}

const Item = ({
  provided,
  item,
  style,
  isDragging,
  index,
  columnId,
  func,
  func1,
}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging, columnId })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      <Text size="xl" multiline>
        {item.content}
      </Text>

      {columnId == "column-1" ? (
        <div className="is-row ml-1" style={{ alignItems: "center" }}>
          {item.edit && (
            <Button
              kind="link"
              color="basic"
              size="xl"
              iconKasimu="pen"
              onClick={() => {
                func1(item.key);
              }}
              space
              className="ml-1 mr-1"
            />
          )}
          <Button
            kind="link"
            color="basic"
            size="xl"
            iconKasimu="trash"
            onClick={() => {
              func(item.key);
            }}
            space
            className="ml-1"
          />
        </div>
      ) : null}
    </div>
  );
};

const Row = (props) => {
  const { data, index, style } = props;
  const item = data.items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.key}`} index={index} key={item.key}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          columnId={data.columnId}
          func={data.func}
          func1={data.func1}
        />
      )}
    </Draggable>
  );
};

const ItemList = ({ column, index, func, func1 }) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          columnId={column.id}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        return (
          <FixedSizeList
            height={420}
            itemCount={itemCount}
            itemSize={60}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={{
              items: column.items,
              columnId: column.id,
              func: func,
              func1: func1,
            }}
            className="task-list"
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
};

const Empty = ({ label }) => {
  return (
    <div style={{ position: "absolute" }}>
      <Text upper size="xl" weight="strong" lineBreak color="gray13">
        <span style={{ color: "#ccc" }}>{label}</span>
      </Text>
    </div>
  );
};

class ColumnDraggable extends Component {
  getColumnStyle = (index) => ({
    border: index == 1 && "2px solid #e8e8e8",
    borderRadius: "8px",
    ".item": {
      background: "red",
    },
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  render() {
    const { column, index, func, func1, label1, label2 } = this.props;
    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <div
            className={`ks--column is-6-desktop is-12-tablet is-12-mobile px-1 py-1`}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={this.getColumnStyle(index)}
          >
            <div {...provided.dragHandleProps} />
            {index == 0 && column.items.length == 0 && <Empty label={label2} />}
            {index == 1 && (
              <div
                style={{
                  position: "absolute",
                  border: "2px dashed #ccc",
                  padding: "10px 20px",
                  borderRadius: "16px",
                }}
              >
                <Text upper size="xl" weight="strong" lineBreak color="gray13">
                  <span style={{ color: "#ccc" }}>{label1}</span>
                </Text>
              </div>
            )}
            <ItemList column={column} index={index} func={func} func1={func1} />
          </div>
        )}
      </Draggable>
    );
  }
}

@observer
class PlaylistAutomaticNewModal extends Component {
  @observable userPlaylistsNew;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      search: "",
      radioValue: 1,
      messageError: null,
      items: this.getItemsDataKasimu(),
      state: {
        columns: {
          "column-0": {
            id: "column-0",
            title: "First column",
            items: [],
          },
          "column-1": {
            id: "column-1",
            title: "Second column",
            items: [],
          },
        },
        columnOrder: ["column-0", "column-1"],
      },
    };

    this.grid = 4;
    this.userPlaylistsNew = this.newUserPlaylists();
  }

  componentDidMount() {
    const { items, search } = this.state;
    const arrayItems = this.filterItems(items, search);
    const state = {
      columns: {
        "column-0": {
          id: "column-0",
          title: "First column",
          items: arrayItems,
        },
        "column-1": {
          id: "column-1",
          title: "Second column",
          items: [],
        },
      },
      columnOrder: ["column-0", "column-1"],
    };
    this.setState({ state: state });
  }

  newUserPlaylists = () => {
    const userPlaylistsStore = this.props.store.userPlaylists;
    const attrs = {
      playlist_id: 0,
      song_lenguages_id: 1,
      name: this.props.name,
      description: this.props.description,
      free_rights: this.props.store.getFreeRights(),
      static: 0,
      explicit: 0,
      random: 0,
      num_blocks: 0,
      origin_type: 0,
      deleted: 0,
      duration: 10,
      blocks: [],
    };

    return new UserPlaylists(attrs, userPlaylistsStore);
  };

  getItemsDataKasimu = () => {
    const { songsStyles } = this.props;
    var arr = [];
    songsStyles &&
      songsStyles.map((a) =>
        arr.push({
          key: uuidv4(),
          id: a.id,
          content: a.translation ? a.translation[0].description : a.description,
          mood_pre: a.mood_pre,
          mood_min: a.mood_min,
          mood_max: a.mood_max,
          bpm_min: 50,
          bpm_max: 200,
          preference: 4,
          edit: true,
        })
      );
    return arr;
  };

  getItemsDataMyStyles = () => {
    const { usersStyles } = this.props;
    var arr = [];
    usersStyles &&
      usersStyles.map((a) =>
        arr.push({
          key: uuidv4(),
          id: a.style_id,
          content: a.name,
          mood_pre: a.mood_pre,
          bpm_min: 50,
          bpm_max: 200,
          preference: 9,
          edit: false,
        })
      );
    return arr;
  };

  filterItems = (array, search) => {
    array = array.filter(
      (el) => el.content.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
    return array;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validLength = (value) => {
    if (value.length >= 1) {
      return true;
    } else {
      return false;
    }
  };

  validLengthName = (value) => {
    if (value.length >= 1 && value.length <= 79) {
      return true;
    } else {
      return false;
    }
  };

  handleChangeToggle = (sender, name, value) => {
    const val = value ? 1 : 0;
    this.userPlaylistsNew[name] = val;
  };

  handleChangeFiled = (sender, value, name, validation) => {
    if (name == "duration") {
      if (parseInt(value) > 24) {
        this.userPlaylistsNew[name] = 24;
      } else {
        this.userPlaylistsNew[name] = parseInt(value);
      }
    } else {
      this.userPlaylistsNew[name] = value;
    }
    this.setState({ messageError: null });
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { state } = this.state;
    if (this.validLength(state.columns["column-1"].items)) {
      if (this.validLengthName(this.userPlaylistsNew.name)) {
        this.userPlaylistsNew.num_blocks =
          state.columns["column-1"].items.length;
        this.userPlaylistsNew.blocks = state.columns["column-1"].items.map(
          (i) => i
        );
        this.setState({ isSaving: true }, () => {
          this.userPlaylistsNew.save().andThen((res, responseError) => {
            if (responseError) {
              toastManager.add(this.props.store.language.label607, {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              toastManager.add(this.props.store.language.label608, {
                appearance: "success",
                autoDismiss: true,
              });
              this.props.onSave && this.props.onSave();
            }
          });
        });
      } else {
        this.setState({ messageError: this.props.store.language.label614 });
      }
    } else {
      this.setState({ messageError: this.props.store.language.label609 });
    }
  };

  handleChangeRadio = (sender, value) => {
    const newSate = this.state.state;
    switch (value) {
      case 1:
        var arrayItems = this.filterItems(
          this.getItemsDataKasimu(),
          this.state.search
        );
        newSate.columns["column-0"].items = arrayItems;
        this.setState({
          state: newSate,
          radioValue: 1,
          items: this.getItemsDataKasimu(),
        });
        break;
      case 2:
        var arrayItems = this.filterItems(
          this.getItemsDataMyStyles(),
          this.state.search
        );
        newSate.columns["column-0"].items = arrayItems;
        this.setState({
          state: newSate,
          radioValue: 2,
          items: this.getItemsDataMyStyles(),
        });
        break;
    }
  };

  handleChangeSearch = (sender, value, name) => {
    const newSate = this.state.state;
    const arrayItems = this.filterItems(this.state.items, value);
    newSate.columns["column-0"].items = arrayItems;
    this.setState({ state: newSate, search: value });
  };

  modifyStyle = (key) => {
    const lists = this.state.state.columns["column-1"].items;
    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);
    this.props.onOpend && this.props.onOpend(lists[index]);
  };

  deleted = (key) => {
    const lists = this.state.state.columns["column-1"].items;
    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    lists.splice(index, 1);

    const newSate = this.state.state;
    newSate.columns["column-1"].items = lists;

    this.setState({
      state: newSate,
      messageError: null,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];
    destClone.splice(droppableDestination.index, 0, { ...item, key: uuidv4() });
    return destClone;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    const { state } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = this.reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      this.setState({
        state: {
          ...state,
          columnOrder,
        },
      });
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = this.reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      this.setState({ state: newState });
      return;
    }

    // moving between lists
    const sourceColumn = state.columns[result.source.droppableId];
    const destinationColumn = state.columns[result.destination.droppableId];
    const { source, destination } = result;

    // eliminado de la lista
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case "column-0":
        const result = this.copy(
          sourceColumn.items,
          destinationColumn.items,
          source,
          destination
        );

        const newSate = this.state.state;
        newSate.columns["column-1"].items = result;

        this.setState({
          state: newSate,
          messageError: null,
        });
        break;
    }
  };

  renderDragDrop = () => {
    const { state } = this.state;
    return (
      <React.Fragment>
        {state && state.columnOrder && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="app">
              <Droppable
                droppableId="all-droppables"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="ks--columns"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.columnOrder.map((columnId, index) => (
                      <>
                        <ColumnDraggable
                          key={columnId}
                          column={state.columns[columnId]}
                          index={index}
                          func={this.deleted}
                          func1={this.modifyStyle}
                          label1={this.props.store.language.label606}
                          label2={this.props.store.language.label89}
                        />
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </React.Fragment>
    );
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              upper
              disabled={
                this.state.state.columns["column-1"].items.length > 0
                  ? false
                  : true
              }
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "940px" : "90%"}
        height={this.props.store.viewMobile ? "640px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label591}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "lg" : "md"}
            lineBreak
          >
            {this.props.store.language.label599}
          </Text>
          <br />
          <Field
            label={this.props.store.language.label53}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            labelNote={this.props.store.language.label76}
            weight="medium"
          >
            <TextInput
              className="is-fullwidth my-1"
              name="name"
              value={this.props.name}
              onChange={this.handleChangeFiled}
              required
              autocomplete="nope"
              backgroundColor="blackDark"
              invert
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
          <Field
            label={this.props.store.language.label79}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            labelNote={this.props.store.language.label78}
            weight="medium"
          >
            <TextInput
              className="is-fullwidth my-1"
              name="description"
              value={this.props.description}
              onChange={this.handleChangeFiled}
              required
              autocomplete="nope"
              backgroundColor="blackDark"
              colorIcon="gray"
              color="black"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              invert
            />
          </Field>
          <Columns className="mb-1">
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <Field
                label={this.props.store.language.label601}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
              >
                <RadioGroup
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  group="sizes"
                  onChange={this.handleChangeRadio}
                  value={this.state.radioValue}
                >
                  <Radio
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    value={1}
                  >
                    <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                      {this.props.store.language.label602 +
                        ", " +
                        this.props.store.getServiceName()}
                    </Text>
                  </Radio>
                  <Radio
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    value={2}
                  >
                    <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                      {this.props.store.language.label603}
                    </Text>
                  </Radio>
                </RadioGroup>
              </Field>
              <TextInput
                name="search"
                iconKasimu="search"
                onChange={this.handleChangeSearch}
                invert
                backgroundColor="blackDark"
                autocomplete="nope"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                placeholder={this.props.store.language.label600}
                paddingInput={this.props.store.viewMobile && "20px"}
              />
            </Column>
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <div className="is-row" style={{ alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    weight="medium"
                  >
                    {this.props.store.language.label604 + ":"}
                  </Text>
                </div>
                <div style={{ width: "100px" }}>
                  <TextInput
                    type="number"
                    className="is-fullwidth"
                    name="duration"
                    value={10}
                    onChange={this.handleChangeFiled}
                    minlength={1}
                    maxlength={24}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    invert
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </div>
              </div>
              <div className="is-row my-1" style={{ alignItems: "center" }}>
                <div style={{ marginRight: "20px" }}>
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    weight="medium"
                  >
                    {this.props.store.language.label324 + ":"}
                  </Text>
                </div>
                <Toggle
                  name={"random"}
                  noIcons
                  onChange={this.handleChangeToggle}
                />
              </div>
              <div
                className="is-row my-1"
                style={{ alignItems: "center" }}
                weight="medium"
              >
                <div style={{ marginRight: "20px" }}>
                  <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                    {this.props.store.language.label605 + ":"}
                  </Text>
                </div>
                <Toggle
                  name={"explicit"}
                  noIcons
                  onChange={this.handleChangeToggle}
                />
              </div>
            </Column>
          </Columns>
          {this.renderDragDrop()}
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
          <div className="my-1"></div>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

PlaylistAutomaticNewModal.propTypes = {
  songsStyles: PropTypes.Array,
  usersStyles: PropTypes.Array,
  name: PropTypes.string,
  description: PropTypes.string,
  onOpend: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PlaylistAutomaticNewModal.defaultProps = {
  songsStyles: [],
  usersStyles: [],
  name: null,
  description: null,
  onOpend: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PlaylistAutomaticNewModal));
