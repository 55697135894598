import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import { Level, LevelLeft, LevelRight } from "bloomer";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";

@observer
class AdDeletedPlansModal extends Component {
  constructor(props) {
    super(props);
  }

  handleAccept = () => {
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .delete(
        "/advertising_guidelines/deleted_guidelines_panel/",
        "?id=" + this.props.currentAdPlans.id
      )
      .then(
        (res) => {
          toastManager.add(this.props.store.language.label330, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.onAccept && this.props.onAccept();
        },
        (error) => {
          toastManager.add(this.props.store.language.label331, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(this.props.store.language.label331, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { currentAdPlans } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "100%"}
        height={this.props.store.viewMobile ? "240px" : "40%"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              lineBreak
            >
              {this.props.store.language.label506} {currentAdPlans.name}?
            </Text>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              multiline
              lineBreak
              color="special"
              className="pt-2 pb-3"
            >
              {this.props.store.language.label507} {currentAdPlans.name}.
            </Text>

            <div>
              <Button
                className="px-3"
                onClick={this.handleAccept}
                size={this.props.store.viewMobile ? "lg" : "md"}
              >
                <strong>{this.props.store.language.label129}</strong>
              </Button>
              <Button
                className="px-3"
                onClick={this.handleClose}
                size={this.props.store.viewMobile ? "lg" : "md"}
              >
                <strong>{this.props.store.language.label427}</strong>
              </Button>
            </div>
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

AdDeletedPlansModal.propTypes = {
  currentAdPlans: PropTypes.object,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
};

AdDeletedPlansModal.defaultProps = {
  currentAdPlans: null,
  onAccept: null,
  onClose: null,
};

export default withStore(withToastManager(AdDeletedPlansModal));
