import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";

@observer
class AccountDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };
  }

  handleAccept = () => {
    const user = this.props.store.loggedInUser;
    const { account } = this.props;
    const { toastManager } = this.props;
    const messageSuccess = this.props.store.language.label206;
    const messageError = this.props.store.language.label207;
    this.setState({ isDeleting: true }, () => {
      this.props.store.kasimuAPIClient
        .putOne(
          "/users/put_deleted_user",
          "?user_main_id=" + user.id + "&user_id=" + account.id
        )
        .then(
          (res) => {
            toastManager.add(messageSuccess, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onAccept && this.props.onAccept();
          },
          (error) => {
            toastManager.add(messageError, {
              appearance: "error",
              autoDismiss: true,
            });
            this.handleClose();
          }
        )
        .catch((error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
          this.handleClose();
        });
    });
  };
  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { account } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "240px" : "auto"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              lineBreak
            >
              {this.props.store.language.label208} {account.username}?
            </Text>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              multiline
              lineBreak
              color="special"
              className="pt-2 pb-3"
            >
              {this.props.store.language.label209} {account.username}
            </Text>
            <div>
              {this.state.isDeleting ? (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  disabled
                  icon="spinner"
                  pulse
                >
                  <strong>{this.props.store.language.label130}</strong>
                </Button>
              ) : (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  onClick={this.handleAccept}
                >
                  <strong>{this.props.store.language.label129}</strong>
                </Button>
              )}
              {this.state.isDeleting ? null : (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  onClick={this.handleClose}
                  kind="outline"
                  color="basic"
                >
                  <strong>{this.props.store.language.label427}</strong>
                </Button>
              )}
            </div>
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

AccountDeleteModal.propTypes = {
  account: PropTypes.object,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
};

AccountDeleteModal.defaultProps = {
  account: null,
  onAccept: null,
  onClose: null,
};

export default withStore(withToastManager(AccountDeleteModal));
