import { Model } from "ks_storybook";
import moment from "moment";

class AdPlan extends Model {
  urlRoot = "/actions_advertisements_plans";

  /*     { value: "8", label: "todos los dias" },
      { value: "9", label: "lunes a viernes" },
      { value: "10", label: "sabado y domingo" }, */

  isTodayAd = () => {
    const todayWeekday = this.dateToKasimuDate(new Date().getDay());

    if (!!this.exact_day && moment().isSame(this.exact_day, "day")) return true;
    if (!this.week_day) return false;
    if (this.week_day == 8) return true;
    if (this.week_day == 10 && [6, 7].includes(parseInt(todayWeekday)))
      return true;
    if (this.week_day == 9 && [1, 2, 3, 4, 5].includes(parseInt(todayWeekday)))
      return true;
    if (this.week_day == todayWeekday) return true;

    return false;
  };

  isWeekDay = (weekDay) => {
    if (this.week_day && this.week_day == weekDay) {
      return true;
    }
    if (this.exact_day) {
      let wd = new Date(this.exact_day).getDay();

      return this.dateToKasimuDate(wd) == weekDay;
    }

    return false;
  };

  dateToKasimuDate(wd) {
    return wd == 0 ? "7" : wd;
  }
}

export default AdPlan;
