import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import { Select } from "../Select";
import {
  Field,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Toggle,
  Text,
  Checkbox,
  Radio,
  RadioGroup,
  Panel,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class PlaylistPlanModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radioValue: 1,
      playlists_kasimu: [],
      my_playlists: [],
      isSaving: false,
      user_id: 0,
      categories_playlists_id: null,
      description: "",
      s_hours: "00",
      s_min: "00",
      f_hours: "23",
      f_min: "59",
      days: [this.props.weekDay.toString()],
      playlist_type: 0,
      owner: 0,
      _static: 0,
      random: 0,
      messageError: null,
    };
    this.weekdays = this.props.store.language.label61.split(",");
    this.user_id = this.props.userId;
  }

  handleSelectChange = (value, sender) => {
    const name = sender.name;
    const val = !value ? null : value.value;
    const description = !value ? null : value.description;
    const owner = !value ? null : value.owner;
    const _static = !value ? null : value._static;

    switch (name) {
      case "playlist":
        this.setState({
          categories_playlists_id: val,
          description: description,
          owner: owner,
          _static: _static,
          messageError: null,
        });
        break;
      case "s_hours":
        this.setState({ s_hours: val, messageError: null });
        break;
      case "s_min":
        this.setState({ s_min: val, messageError: null });
        break;
      case "f_hours":
        this.setState({ f_hours: val, messageError: null });
        break;
      case "f_min":
        this.setState({ f_min: val, messageError: null });
        break;
      default:
        break;
    }
  };

  getPlaylistTypeNumber = (value) => {
    switch (value) {
      case 0:
        if (this.state.random == 1) return 5;
        else return 4;
      case 1:
        if (this.state.random == 1) return 7;
        else return 6;
    }
  };

  handleChangeRandom = (sender, name, value) => {
    if (value) this.setState({ random: 1, messageError: null });
    else this.setState({ random: 0, messageError: null });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validHours = (start_hour, finish_hour) => {
    if (finish_hour <= start_hour) return true;
    return false;
  };

  validLimitMin = (start_hour, finish_hour) => {
    var start_min = start_hour
      .split(":")
      .reduce((p, c) => parseInt(p) * 60 + parseInt(c));
    var finish_min = finish_hour
      .split(":")
      .reduce((p, c) => parseInt(p) * 60 + parseInt(c));
    var difference = finish_min - start_min;
    if (difference < 5) return true;
    return false;
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const {
      categories_playlists_id,
      s_hours,
      s_min,
      f_hours,
      f_min,
      days,
      radioValue,
      owner,
      _static,
      random,
    } = this.state;
    const params = {
      user_id: this.user_id,
      categories_playlists_id: categories_playlists_id,
      days: days,
      start_hour: s_hours + ":" + s_min,
      finish_hour: f_hours + ":" + f_min,
      playlist_type:
        radioValue == 1
          ? random == 1
            ? 1
            : 0
          : radioValue == 2
          ? this.getPlaylistTypeNumber(_static)
          : 0,
      owner: owner,
      type: radioValue,
    };
    if (this.validHours(params.start_hour, params.finish_hour)) {
      this.setState({ messageError: this.props.store.language.label660 });
      return;
    }

    if (this.validLimitMin(params.start_hour, params.finish_hour)) {
      this.setState({ messageError: this.props.store.language.label661 });
      return;
    }

    if (params.days.length === 0) {
      this.setState({ messageError: this.props.store.language.label662 });
      return;
    }
    let res =
      this.props.onValid &&
      this.props.onValid(days, params.start_hour, params.finish_hour);
    if (res.length !== 0) {
      this.setState({
        messageError:
          this.props.store.language.label663 +
          res.length +
          this.props.store.language.label664,
      });
      return;
    }

    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/actions_playlist_plans/save_playlist_plan_user", params)
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label325, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          },
          (error) => {
            let str = error.toString();
            str = str.replace("Error: ", "");
            let mydatas = JSON.parse(str);
            toastManager.add(this.getErrorMessager(mydatas["codError"]), {
              appearance: "error",
              autoDismiss: true,
            });
            this.handleClose();
          }
        )
        .catch((error) => {
          toastManager.add(this.props.store.language.label326, {
            appearance: "error",
            autoDismiss: true,
          });
          this.handleClose();
        });
    });
  };

  getErrorMessager = (codError) => {
    const messager = {
      1: this.props.store.language.label327,
      2: this.props.store.language.label328,
    };
    return messager[codError];
  };

  loadOptionsKasimu = (inputValue, callback) => {
    if (inputValue == "") {
      var category_id = this.props.store.getKasimuCategorie();
      return this.props.store.categoriesPlaylists
        .fetchSearchId(category_id, this.props.store.lang)
        .andThen((res, error) => {
          if (res) {
            const options = res.toArray().map((o) => ({
              value: o.id,
              label: o.alias,
              description: o.description,
              owner: 1,
              _static: 0,
            }));
            this.setState({ playlists_kasimu: options, messageError: null });
            callback(options);
          }
        });
    } else {
      const options = this.state.playlists_kasimu
        .filter(
          (el) => el.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )
        .map((o) => ({
          value: o.value,
          label: o.label,
          description: o.description,
          owner: o.owner,
          _static: 0,
        }));
      callback(options);
    }
  };

  loadOptionsUserPlaylists = (inputValue, callback) => {
    if (inputValue == "") {
      return this.props.store.userPlaylists
        .getUserPlaylists(this.user_id, this.props.store.getSingleUser())
        .andThen((res, error) => {
          if (res) {
            const options = res.toArray().map((o) => ({
              value: o.playlist_id,
              label:
                o.user_id == this.user_id
                  ? this.props.store.language.label631 + o.name
                  : this.props.store.language.label630 + o.name,
              description: o.description
                ? o.description
                : this.props.store.language.label686,
              owner: o.user_id == this.user_id ? 2 : 3,
              _static: o.static,
            }));
            this.setState({ my_playlists: options, messageError: null });
            callback(options);
          }
        });
    } else {
      const options = this.state.my_playlists
        .filter(
          (el) => el.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )
        .map((o) => ({
          value: o.value,
          label: o.label,
          description: o.description
            ? o.description
            : this.props.store.language.label686,
          owner: o.owner,
          _static: o._static,
        }));
      callback(options);
    }
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong> {this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              disabled={!this.canSave}
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  valid = (num) => {
    switch (num) {
      case 0:
        return "00";
      case 1:
        return "01";
      case 2:
        return "02";
      case 3:
        return "03";
      case 4:
        return "04";
      case 5:
        return "05";
      case 6:
        return "06";
      case 7:
        return "07";
      case 8:
        return "08";
      case 9:
        return "09";
      default:
        return num;
    }
  };

  getInterval = (e) => {
    let items = [];
    for (let i = 0; i <= e; i++) {
      items.push({
        value: this.valid(i),
        label: this.valid(i),
      });
    }
    return items;
  };

  renderSelectHours = (name, options, index) => {
    return (
      <Select
        className="is-fullwidth"
        onChange={this.handleSelectChange}
        name={name}
        required
        autocomplete="nope"
        options={options}
        defaultValue={options[index]}
        isSearchable={false}
        isClearable={false}
        backgroundColor="black"
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
      />
    );
  };

  renderHourRangeSelect = () => {
    return (
      <Columns>
        <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <Field
            label={this.props.store.language.label71}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
          >
            <div
              className="is-row is-fullwidth"
              style={{
                alignItems: "center",
                marginTop: "5px",
                //flexWrap: !this.props.store.viewMobile && "wrap",
              }}
            >
              {this.renderSelectHours("s_hours", this.getInterval(23), 0)}
              <div style={{ marginLeft: "5px", minWidth: "10px" }}>
                <Text
                  upper
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="strong"
                >
                  {":"}
                </Text>
              </div>
              {this.renderSelectHours("s_min", this.getInterval(59), 0)}
            </div>
          </Field>
        </Column>
        <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <Field
            label={this.props.store.language.label193}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
          >
            <div
              className="is-row is-fullwidth"
              style={{
                alignItems: "center",
                marginTop: "5px",
                //flexWrap: !this.props.store.viewMobile && "wrap",
              }}
            >
              {this.renderSelectHours("f_hours", this.getInterval(23), 23)}
              <div style={{ marginLeft: "5px", minWidth: "10px" }}>
                <Text
                  upper
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="strong"
                >
                  {":"}
                </Text>
              </div>
              {this.renderSelectHours("f_min", this.getInterval(59), 59)}
            </div>
          </Field>
        </Column>
      </Columns>
    );
  };

  handleChangeRadio = (sender, value) => {
    this.setState({
      radioValue: value,
      categories_playlists_id: null,
      messageError: null,
      description: "",
    });
    /*if (value=="1")
            this.setState({ playlist_type: 0 });  
        else
            this.setState({ playlist_type: 4 }); */
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    if (this.state.radioValue != 3) {
      if (
        !this.state.categories_playlists_id ||
        !(
          this.state.s_hours &&
          this.state.s_min &&
          this.state.f_hours &&
          this.state.f_min
        )
      )
        return false;
    }
    return true;
  }

  renderSelectPlaylists = (options) => {
    return (
      <Select
        isAsync
        isClearable
        cacheOptions
        defaultOptions
        required
        onChange={this.handleSelectChange}
        name="playlist"
        autocomplete="nope"
        loadOptions={options}
        backgroundColor="#141414"
        className="pt-1"
        placeholder={this.props.store.language.label574}
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
      />
    );
  };

  getTitleWeekDay = (value) => {
    var day = "";
    switch (value) {
      case "1":
        day = this.weekdays[1];
        break;
      case "2":
        day = this.weekdays[2];
        break;
      case "3":
        day = this.weekdays[3];
        break;
      case "4":
        day = this.weekdays[4];
        break;
      case "5":
        day = this.weekdays[5];
        break;
      case "6":
        day = this.weekdays[6];
        break;
      case "7":
        day = this.weekdays[0];
        break;
    }
    return day.toUpperCase();
  };

  handleCheckboxChange = (sender, value, name) => {
    let days = this.state.days;
    if (value) days.push(name);
    else days = days.filter((i) => name !== i);

    this.setState({ days: days, messageError: null });
  };

  renderCheckboxDays = () => {
    return (
      <Columns isMultiline>
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
          <Columns isGapless isMultiline isMobile>
            <Column isSize={{ mobile: 6, tablet: 3, desktop: 3 }}>
              <Checkbox
                key={"1"}
                value="1"
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disabledOn}
                isChecked={this.props.weekDay == 1 ? true : false}
                className="items-center"
                invert
              >
                {this.weekdays[1]}
              </Checkbox>
            </Column>
            <Column isSize={{ mobile: 6, tablet: 3, desktop: 3 }}>
              <Checkbox
                key={"2"}
                value="2"
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disabledOn}
                isChecked={this.props.weekDay == 2 ? true : false}
                className="items-center"
                invert
              >
                {this.weekdays[2]}
              </Checkbox>
            </Column>
            <Column isSize={{ mobile: 6, tablet: 3, desktop: 3 }}>
              <Checkbox
                key={"3"}
                value="3"
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disabledOn}
                isChecked={this.props.weekDay == 3 ? true : false}
                className="items-center"
                invert
              >
                {this.weekdays[3]}
              </Checkbox>
            </Column>
            <Column isSize={{ mobile: 6, tablet: 3, desktop: 3 }}>
              <Checkbox
                key={"4"}
                value="4"
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disabledOn}
                isChecked={this.props.weekDay == 4 ? true : false}
                className="items-center"
                invert
              >
                {this.weekdays[4]}
              </Checkbox>
            </Column>
          </Columns>
          <Columns isGapless isMultiline isMobile>
            <Column isSize={{ mobile: 6, tablet: 3, desktop: 3 }}>
              <Checkbox
                key={"5"}
                value="5"
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disabledOn}
                isChecked={this.props.weekDay == 5 ? true : false}
                className="items-center"
                invert
              >
                {this.weekdays[5]}
              </Checkbox>
            </Column>
            <Column isSize={{ mobile: 6, tablet: 3, desktop: 3 }}>
              <Checkbox
                key={"6"}
                value="6"
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disabledOn}
                isChecked={this.props.weekDay == 6 ? true : false}
                className="items-center"
                invert
              >
                {this.weekdays[6]}
              </Checkbox>
            </Column>
            <Column isSize={{ mobile: 6, tablet: 3, desktop: 3 }}>
              <Checkbox
                key={"7"}
                value="7"
                size={this.props.store.viewMobile ? "lg" : "md"}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disabledOn}
                isChecked={this.props.weekDay == 7 ? true : false}
                className="items-center"
                invert
              >
                {this.weekdays[0]}
              </Checkbox>
            </Column>
          </Columns>
        </Column>
      </Columns>
    );
  };

  render() {
    const { radioValue } = this.state;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label320}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Panel
            className={this.props.store.viewMobile && "my-1"}
            color="gray"
            padding={this.props.store.viewMobile ? "28px 20px" : "22px 15px"}
            style={{
              marginBottom: !this.props.store.viewMobile && "6px",
            }}
          >
            <div
              className="flex"
              style={{
                gap: "2px",
                flexDirection: "column",
              }}
            >
              <RadioGroup
                group="sizes"
                size={this.props.store.viewMobile ? "md" : "sm"}
                onChange={this.handleChangeRadio}
                value={radioValue}
                classNames=" group"
              >
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="1"
                  class="radio"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight={Number(radioValue) === 1 ? "regular" : "medium"}
                    color={Number(radioValue) === 1 ? "white" : "gray"}
                  >
                    {"Playlists, " + this.props.store.getServiceName()}
                  </Text>
                </Radio>
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="2"
                  class="radio"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight={Number(radioValue) === 2 ? "regular" : "medium"}
                    color={Number(radioValue) === 2 ? "white" : "gray"}
                  >
                    {this.props.store.language.label323}
                  </Text>
                </Radio>
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="3"
                  class="radio"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight={Number(radioValue) === 3 ? "regular" : "medium"}
                    color={Number(radioValue) === 3 ? "white" : "gray"}
                  >
                    {this.props.store.language.label659}
                  </Text>
                </Radio>
              </RadioGroup>
            </div>

            {this.state.radioValue != 3 ? (
              <Field
                label="Playlist"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                className="pt-1"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                {this.state.radioValue == 1 &&
                  this.renderSelectPlaylists(this.loadOptionsKasimu)}
                {this.state.radioValue == 2 &&
                  this.renderSelectPlaylists(this.loadOptionsUserPlaylists)}
              </Field>
            ) : null}
            {this.state.radioValue != 3 && this.state.description != "" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="pt-1"
              >
                <small>
                  {this.state.description &&
                    this.props.store.language.label79 +
                      ": " +
                      this.state.description}
                </small>
              </div>
            ) : null}
            {this.state.radioValue != 3 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="pt-2"
              >
                <Text
                  weight="medium"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="pr-2"
                >
                  {this.props.store.language.label324}
                </Text>

                <Toggle
                  onChange={this.handleChangeRandom}
                  checked={false}
                  noIcons
                />
              </div>
            ) : null}
          </Panel>
          <Panel
            className={this.props.store.viewMobile && "my-1"}
            color="gray"
            padding={this.props.store.viewMobile ? "28px 20px" : "25px 20px"}
            style={{
              marginBottom: !this.props.store.viewMobile && "6px",
            }}
          >
            {this.renderHourRangeSelect()}
            <Field
              label="Dias"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              NoteSize="xs"
              labelNote={this.props.store.language.label76}
              className="pt-1"
              weight="medium"
            >
              {this.renderCheckboxDays()}
            </Field>
            {this.state.messageError && (
              <Panel color="error" className="mt-2" invert>
                <Text multiline>{this.state.messageError}</Text>
              </Panel>
            )}
          </Panel>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

PlaylistPlanModal.propTypes = {
  weekDay: PropTypes.number,
  userId: PropTypes.number,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onValid: PropTypes.func,
};

PlaylistPlanModal.defaultProps = {
  weekDay: 0,
  userId: null,
  onSave: null,
  onClose: null,
  onValid: null,
};

export default withToastManager(withStore(PlaylistPlanModal));
