import { Store } from "ks_storybook";
import { UsersBlocks } from "../models";

class UsersBlocksStore extends Store {
  model = UsersBlocks;

  fetchBlocksPlaylists(playlist_id, lang) {
    return this.search(
      { playlist_id: playlist_id, lang: lang },
      "users_blocks",
      true,
      "/users_blocks/get_playlists_blocks"
    );
  }
}

export default UsersBlocksStore;
