import { Store } from "ks_storybook";
import { KSPermissions } from "../models";

class KSPermissionsStore extends Store {
  model = KSPermissions;

  fetchAllParams(lang, child_user_id, apply_in, supervisor) {
    return this.search(
      {
        per_page: 1000,
        lang: lang,
        child_user_id: child_user_id,
        apply_in: apply_in,
        supervisor: supervisor,
      },
      "get_all_for_user_params",
      true,
      "/ks_permissions/get_all_for_user_params"
    );
  }

  fetchAllBySupervisor(supervisor_id, apply_in) {
    return this.search(
      {
        supervisor_id: supervisor_id,
        apply_in: apply_in,
      },
      "get_all_for_user_params",
      true,
      "/ks_permissions/get_permission_supervisor"
    );
  }
}

export default KSPermissionsStore;
