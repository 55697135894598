import { Store } from "ks_storybook";
import { UsersSupervisors } from "../models";

class UsersSupervisorsStore extends Store {
  model = UsersSupervisors;

  fetchFilters(filters = {}) {
    return this.search(filters, "users_supervisors", true, "/users_supervisors");
  }

  fetchAll() {
    return this.search({per_page: 1000}, "users_supervisors_all", true, "/users_supervisors");
  }

  saveAsSupervisor(model) {
    var str = model.username;
    var pos = str.indexOf("@");
    if (pos === -1) {
      model.username = `@${model.username}`;
    }

    return this.save(model, "/users_supervisors/create_supervisor");
  }
}

export default UsersSupervisorsStore;