import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  TextInput,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  SupervisorCard,
  SupervisorNewModal,
  SupervisorEditModal,
  SupervisorPermissionsModal,
} from "../../../Components/Supervisors";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../../Components/Screen";
import ConfirmationModal from "../../../Components/Common/Modal/ConfirmationModal";

@observer
class SupervisorsListModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supervisors: this.getSupervisors(),
      showNewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      showPermissionsModal: false,
      showConfirmationStatusModal: false,
      currentSupervisor: null,
      search: "",
    };
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.handleReload();
    this.setState({ showNewModal: false });
  };

  handleShowEditModal = (supervisor) => {
    this.setState({ showEditModal: true, currentSupervisor: supervisor });
  };

  handleReload = () => {
    this.setState({ supervisors: this.getSupervisors() });
  };

  handleHideEditModal = () => {
    this.setState({ showEditModal: false, currentSupervisor: null });
  };

  handleHideEditModalSave = () => {
    this.handleHideEditModal();
    this.handleReload();
  };

  handleShowDeleteModal = (supervisor) => {
    this.setState({ showDeleteModal: true, currentSupervisor: supervisor });
  };

  handleHideDeleteModal = () => {
    this.setState({ showDeleteModal: false, currentSupervisor: null });
  };

  handleDeleteSupervisor = () => {
    this.handleHideDeleteModal();
    this.handleReload();
    this.setState({ showEditModal: false });
  };

  handlePermissionsModalOpen = (supervisor) => {
    this.setState({
      showPermissionsModal: true,
      currentSupervisor: supervisor,
    });
  };

  handleConfirmationStatusModalClose = () => {
    this.setState({
      showConfirmationStatusModal: false,
      currentSupervisor: null,
    });
  };

  handlePermissionsModalClose = () => {
    this.setState({ showPermissionsModal: false });
  };

  handleUpdateStatusSupervisor = () => {
    this.handlePermissionsModalClose();
    this.handleReload();
    this.setState({ showEditModal: false });
  };

  updateStatus = (status) => {
    const { currentSupervisor } = this.state;
    const { toastManager } = this.props;
    this.setState({ showConfirmationStatusModal: false }, () => {
      this.props.store.kasimuAPIClient
        .putOne(
          "/users_supervisors/change_status_supervisor",
          "?id=" + currentSupervisor.id + "&status=" + status
        )
        .then(
          (res) => {
            toastManager.add(
              status == 1
                ? this.props.store.language.label739
                : this.props.store.language.label740,
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
            this.handleUpdateStatusSupervisor();
          },
          (error) => {
            toastManager.add(
              status == 1
                ? this.props.store.language.label741
                : this.props.store.language.label742,
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ currentSupervisor: null });
          }
        )
        .catch((error) => {
          toastManager.add(
            status == 1
              ? this.props.store.language.label741
              : this.props.store.language.label742,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          this.setState({ currentAccount: null });
        });
    });
  };

  handleConfirmationStatusModalOpen = (supervisor) => {
    this.setState({
      showConfirmationStatusModal: true,
      currentSupervisor: supervisor,
    });
  };

  getSupervisors = () => {
    return this.props.store.usersSupervisors.fetchAll();
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  getSearchSupervisors = (supervisors, search) => {
    var arr = [];
    if (supervisors.length != 0) {
      arr = supervisors.filter(
        (e) => e.username.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  getPanel = () => {
    return (
      <>
        <Text
          className={this.props.store.viewMobile ? "mb-3" : "mb-2"}
          size={this.props.store.viewMobile ? "lg" : "md"}
          multiline
          lineBreak
          color="special"
        >
          {this.props.store.language.label863}
        </Text>
        <Columns isMultiline isVCentered className="my-1">
          <Column
            isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
            className="flex"
          >
            <div
              className={this.props.store.viewMobile && "mr-2"}
              style={{
                maxWidth: "700px",
                width: "100%",
                marginRight: !this.props.store.viewMobile && "6px",
              }}
            >
              <TextInput
                backgroundColor="grayDark"
                className="is-fullwidth"
                paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
                onChange={this.handleChangeSearch}
                placeholder={this.props.store.language.label864}
                name="search"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="search"
                colorIcon="gray"
                invert
              />
            </div>

            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className={`px-3`}
              onClick={this.handleShowNewModal}
            >
              <strong>{this.props.store.language.label82}</strong>
            </Button>
          </Column>
        </Columns>
      </>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getContainerStyle = () =>
    this.props.store.viewMobile
      ? {
          width: "100%",
          minHeight: "400px",
          borderRadius: "16px",
        }
      : {};

  renderPaginator = (obj) => {
    if (obj.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={obj.totalPages}
            currentPage={obj.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderRowSupervisors = () => {
    const { groups } = this.props;
    const { supervisors, search } = this.state;
    const arraySupervisors = this.getSearchSupervisors(
      supervisors.toArray(),
      search
    );
    return (
      <React.Fragment>
        <div
          style={this.getContainerStyle()}
          className={this.props.store.viewMobile && "mt-2"}
        >
          {arraySupervisors.length > 0
            ? arraySupervisors.map((obj) => (
                <SupervisorCard
                  supervisor={obj}
                  groups={groups}
                  onEditClick={this.handleShowEditModal}
                  onDeleteClick={this.handleShowDeleteModal}
                  onPermissionsClick={this.handlePermissionsModalOpen}
                  onSuspendClick={this.handleConfirmationStatusModalOpen}
                />
              ))
            : this.renderEmptyContent()}
        </div>
        {this.renderPaginator(supervisors)}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { supervisors } = this.state;
    if (!supervisors) return this.renderEmptyContent();
    return !supervisors.isOk()
      ? this.renderLoader()
      : this.renderRowSupervisors();
  };

  renderScreen() {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label36}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.getPanel()}
        {this.renderHeader()}
      </Screen>
    );
  }

  render() {
    const { groups } = this.props;
    const {
      showEditModal,
      showDeleteModal,
      currentSupervisor,
      showNewModal,
      showPermissionsModal,
      showConfirmationStatusModal,
    } = this.state;
    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "800px" : "90%"}
            height={this.props.store.viewMobile ? "540px" : "auto"}
          >
            <ModalHeader>
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="black"
              >
                {this.props.store.language.label817}
              </Text>
            </ModalHeader>
            <ModalContent>
              {this.getPanel()}
              {this.renderHeader()}
            </ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {showEditModal && (
          <SupervisorEditModal
            supervisor={currentSupervisor}
            groups={groups}
            onSave={this.handleHideEditModalSave}
            onClose={this.handleHideEditModal}
          />
        )}
        {showDeleteModal && (
          <DeleteConfirmationModal
            currentObj={currentSupervisor}
            onClose={this.handleHideDeleteModal}
            onAccept={this.handleDeleteSupervisor}
            messageSuccess={
              this.props.store.language.label228 +
              " " +
              currentSupervisor.username +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label865 +
              " " +
              currentSupervisor.username
            }
            title={
              this.props.store.language.label866 +
              " " +
              currentSupervisor.username +
              "?"
            }
            subtitle={
              this.props.store.language.label867 +
              " " +
              currentSupervisor.username +
              "."
            }
          />
        )}
        {showNewModal && (
          <SupervisorNewModal
            groups={groups}
            onSave={this.handleCloseNewModal}
            onClose={this.handleCloseNewModal}
          />
        )}
        {showPermissionsModal && (
          <SupervisorPermissionsModal
            supervisor={currentSupervisor}
            onClose={this.handlePermissionsModalClose}
          />
        )}
        {showConfirmationStatusModal && (
          <ConfirmationModal
            txtButton={
              currentSupervisor.status === 1
                ? this.props.store.language.label736
                : this.props.store.language.label737
            }
            title={
              currentSupervisor.status === 1
                ? this.props.store.language.label744 +
                  " " +
                  currentSupervisor.username
                : this.props.store.language.label745 +
                  " " +
                  currentSupervisor.username
            }
            onClose={this.handleConfirmationStatusModalClose}
            onAccept={() =>
              this.updateStatus(currentSupervisor.status === 1 ? 2 : 1)
            }
            subtitle={
              currentSupervisor.status == 1
                ? this.props.store.language.label868
                : this.props.store.language.label869
            }
          />
        )}
      </React.Fragment>
    );
  }
}

SupervisorsListModal.propTypes = {
  groups: PropTypes.Array,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
};

SupervisorsListModal.defaultProps = {
  groups: [],
  onReload: null,
  onClose: null,
};

export default withStore(withToastManager(SupervisorsListModal));
