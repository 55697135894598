import { Store } from "ks_storybook";
import { CategoryPlaylist } from "../models";

class CategoriesPlaylistsStore extends Store {
  model = CategoryPlaylist;

  fetchSearchId(category_id, lang) {
    return this.search(
      { per_page: 1000, category_id: category_id, lang: lang },
      "categories_playlist_get_search_id",
      true,
      "/categories_playlist/get_search_id"
    );
  }
}

export default CategoriesPlaylistsStore;
