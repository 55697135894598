import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import {
  Text,
  Button,
  TextInput,
  Loader,
} from "ks_storybook";
import {
  EmptyContent,
  DeleteConfirmationModal,
} from "../../../../Components/Common/";
import { AdsRow, AdsUploadModal } from "../../../../Components/Ads";

@observer
class AdsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showDeleteModal: false,
      ads: this.getAdAll(),
      currentAd: null,
      search: "",
    };
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label29;
  }

  handleStartPlayingClick(ad) {
    var that = this;
    that.props.store.ui.startPlayingAds(ad, 2, true);
  }

  handleTogglePlay() {
    if (this.props.store.ui.currentAdId) {
      if (!this.props.store.ui.isPlaying) {
        this.props.store.ui.play();
      } else {
        this.props.store.ui.pause();
      }
    }
  }

  getAdAll = () => {
    this.props.store.ui.destroy();
    return this.props.store.ads.fetchAll();
  };

  handleShowDeleteModal = (ad) => {
    this.setState({ showDeleteModal: true, currentAd: ad });
  };

  handleHideDeleteModal = () => {
    this.props.store.ui.destroy();
    this.setState({ showDeleteModal: false, currentAd: null });
  };

  handleModalOpen = () => {
    this.props.store.ui.destroy();
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleReload = () => {
    this.props.store.ui.destroy();
    this.setState({ ads: this.getAdAll() });
  };

  handleDeleted = () => {
    this.handleReload();
    this.handleHideDeleteModal();
  };

  handleSave = () => {
    this.setState({ ads: this.getAdAll(), showModal: false });
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
    this.props.store.ui.destroy();
  };

  getSearch = (obj, search) => {
    var arr = [];
    if (obj.length != 0) {
      arr = obj.filter(
        (e) => e.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  getPanel = () => {
    return (
      <Columns
        className={this.props.store.viewMobile ? "my-2" : "my-1"}
        isMultiline
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile ? "mr-2" : "mr-1"}
            style={{ maxWidth: "700px", width: "100%" }}
          >
            <TextInput
              onChange={this.handleChangeSearch}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              placeholder={this.props.store.language.label179}
              invert
            />
          </div>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleModalOpen}
            onClick={() =>
              this.props.store.getPermission(47, 47, () => {
                this.handleModalOpen()
              })
            }
          >
            <strong>{this.props.store.language.label180.split(" ")[0]}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.handleReload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderEmptyContent() {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  }

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeaderOk = () => {
    const { ads, search } = this.state;
    const currentAdId = this.props.store.ui.currentAdId;
    const arrayAds = this.getSearch(ads.toArray(), search);
    return (
      <React.Fragment>
        {arrayAds.length === 0 ? (
          <EmptyContent />
        ) : (
          arrayAds.map((ad) => (
            <AdsRow
              ad={ad}
              handleStartPlayingClick={this.handleStartPlayingClick}
              handleTogglePlay={this.handleTogglePlay}
              active={currentAdId && ad.id == currentAdId}
              //onDeleteClick={this.handleShowDeleteModal}
              onDeleteClick={(ad) =>
                this.props.store.getPermission(47, 47, () => {
                  this.handleShowDeleteModal(ad)
                })
              }
            />
          ))
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { ads } = this.state;
    if (!ads) return this.renderEmptyContent();
    return !ads.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  render() {
    const { showModal, showDeleteModal, currentAd } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label29}
              </Text>
            )}
            {/* <div
              style={{ maxWidth: "580px" }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label178}
              </Paragraph>
            </div> */}
            {this.getPanel()}
            {this.renderHeader()}
          </Column>
        </Columns>
        {showModal && (
          <AdsUploadModal
            onClose={this.handleModalClose}
            onSave={this.handleSave}
          />
        )}
        {showDeleteModal && (
          <DeleteConfirmationModal
            currentObj={currentAd}
            onClose={this.handleHideDeleteModal}
            onAccept={this.handleDeleted}
            messageSuccess={
              this.props.store.language.label345 +
              " " +
              currentAd.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label346 + " " + currentAd.name + "."
            }
            title={
              this.props.store.language.label347 + " " + currentAd.name + "?"
            }
            subtitle={
              this.props.store.language.label348 + " " + currentAd.name + "."
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStore(AdsListPage);
