import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Text, Button, TextInput, Loader, Columns, Column } from "ks_storybook";
import {
  EmptyContent,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  UsersStylesRow,
  UsersStylesNewModal,
  UsersStylesEditModal,
} from "../../../Components/ContentManager";

@observer
class PlaylistsManageStyles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      usersStyles: this.getUsersStylesAll(),
      currentUsersStyles: null,
      lastUsersStyles: null,
      search: "",
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label522;
  }

  getUsersStylesAll = () => {
    return this.props.store.usersStyles.fetchAll();
  };

  handleShowNewModal = () => {
    const { usersStyles } = this.state;
    const arrayStyles = usersStyles.toArray();
    this.setState({
      showNewModal: true,
      lastUsersStyles: arrayStyles[arrayStyles.length - 1],
    });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleSaveNewModal = () => {
    this.setState({
      usersStyles: this.getUsersStylesAll(),
      showNewModal: false,
    });
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  handleShowEditModal = (styles) => {
    this.setState({ showEditModal: true, currentUsersStyles: styles });
  };

  handleCloseEditModal = () => {
    this.setState({ showEditModal: false, currentUsersStyles: null });
  };

  handleSaveEditModal = () => {
    this.setState({
      usersStyles: this.getUsersStylesAll(),
      showEditModal: false,
      currentUsersStyles: null,
    });
  };

  handleShowDeleteModal = (styles) => {
    this.setState({ showDeleteModal: true, currentUsersStyles: styles });
  };

  handleCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, currentUsersStyles: null });
  };

  handleDelete = () => {
    this.handleCloseDeleteModal();
    this.handleReload();
  };

  handleReload = () => {
    this.setState({ usersStyles: this.getUsersStylesAll() });
  };

  getSearch = (obj, search) => {
    var arr = [];
    if (obj.length != 0) {
      arr = obj.filter(
        (e) => e.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getPanel = () => {
    return (
      <Columns
        className={this.props.store.viewMobile && "my-2"}
        isMultiline
        style={{
          margin: !this.props.viewMobile && "6px 0px",
        }}
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile && "mr-2"}
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: !this.props.store.viewMobile && "6px",
            }}
          >
            <TextInput
              onChange={this.handleChangeSearch}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              placeholder={this.props.store.language.label524}
            />
          </div>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleShowNewModal}
            onClick={() =>
              this.props.store.getPermission(42, 42, () => {
                this.handleShowNewModal();
              })
            }
          >
            <strong>{this.props.store.language.label82}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.handleReload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderHeaderOk = () => {
    const { usersStyles, search } = this.state;
    const arrayStyles = this.getSearch(usersStyles.toArray(), search);
    return (
      <React.Fragment>
        {arrayStyles.length === 0 ? (
          <EmptyContent />
        ) : (
          arrayStyles.map((style) => (
            <UsersStylesRow
              style={style}
              onEditClick={(e) =>
                this.props.store.getPermission(42, 42, () => {
                  this.handleShowEditModal(e);
                })
              }
              onDeleteClick={(e) =>
                this.props.store.getPermission(42, 42, () => {
                  this.handleShowDeleteModal(e);
                })
              }
            />
          ))
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { usersStyles } = this.state;
    if (!usersStyles) return this.renderEmptyContent();
    return !usersStyles.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  render() {
    const {
      showEditModal,
      showDeleteModal,
      currentUsersStyles,
      showNewModal,
      lastUsersStyles,
    } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                multiline
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label522}
              </Text>
            )}
            {/* <div
              style={{
                maxWidth: "730px",
              }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label523}
              </Paragraph>
            </div> */}
            {this.getPanel()}
            {this.renderHeader()}
          </Column>
          {showNewModal && (
            <UsersStylesNewModal
              lastStyles={lastUsersStyles}
              onClose={this.handleCloseNewModal}
              onSave={this.handleSaveNewModal}
            />
          )}
          {showEditModal && (
            <UsersStylesEditModal
              styles={currentUsersStyles}
              onClose={this.handleCloseEditModal}
              onSave={this.handleSaveEditModal}
            />
          )}
          {showDeleteModal && (
            <DeleteConfirmationModal
              currentObj={currentUsersStyles}
              onClose={this.handleCloseDeleteModal}
              onAccept={this.handleDelete}
              messageSuccess={
                this.props.store.language.label525 +
                " " +
                currentUsersStyles.name +
                " " +
                this.props.store.language.label229
              }
              messageError={
                this.props.store.language.label526 +
                " " +
                currentUsersStyles.name
              }
              title={
                this.props.store.language.label527 +
                " " +
                currentUsersStyles.name +
                "?"
              }
              subtitle={
                this.props.store.language.label528 +
                " " +
                currentUsersStyles.name +
                "."
              }
            />
          )}
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(PlaylistsManageStyles);
