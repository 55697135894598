import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import { Select } from "../Select";
import {
  Field,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Radio,
  RadioGroup,
  Text,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class PlaylistsFavoriteNewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radioValue: 1,
      playlists_kasimu: [],
      my_playlists: [],
      isSaving: false,
      categories_playlists_id: null,
      label: "",
      description: "",
      owner: 0,
      _static: 0,
    };
  }

  handleSelectChange = (value, sender) => {
    const name = sender.name;
    const val = !value ? null : value.value;
    const label = !value ? null : value.label;
    const description = !value ? null : value.description;
    const owner = !value ? null : value.owner;
    const _static = !value ? null : value._static;

    switch (name) {
      case "playlist":
        this.setState({
          isSaving: false,
          categories_playlists_id: val,
          label: label,
          description: description,
          owner: owner,
          _static: _static,
        });
        break;
      default:
        break;
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getMessager(value) {
    const messager = {
      1: this.props.store.language.label760,
      2: this.props.store.language.label761
    }
    return messager[value];
  };

  getAppearance(value) {
    const appearance = {
      1: 'success',
      2: 'error'
    }
    return appearance[value];
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { owner, categories_playlists_id } = this.state;
    const params = {
      user_id: this.props.user_id,
      categories_playlists_id: categories_playlists_id,
      owner: owner,
    };
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/users_favorite_playlists/"+this.props.url, params)
        .then(
          (res) => {
            toastManager.add(this.getMessager(res.results.answer_code), {
              appearance: this.getAppearance(res.results.answer_code),
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          },
          (error) => {
            toastManager.add(this.props.store.language.label762, {
              appearance: "error",
              autoDismiss: true,
            });
            this.handleClose();
          }
        )
        .catch((error) => {
          toastManager.add(this.props.store.language.label762, {
            appearance: "error",
            autoDismiss: true,
          });
          this.handleClose();
        });
    });
  };

  loadOptionsKasimu = (inputValue, callback) => {
    if (inputValue == "") {
      var category_id = this.props.store.getKasimuCategorie();
      return this.props.store.categoriesPlaylists
        .fetchSearchId(category_id, this.props.store.lang)
        .andThen((res, error) => {
          if (res) {
            const options = res.toArray().map((o) => ({
              value: o.id,
              label: o.alias,
              description: o.description,
              owner: 1,
              _static: 0,
            }));
            this.setState({ playlists_kasimu: options });
            callback(options);
          }
        });
    } else {
      const options = this.state.playlists_kasimu
        .filter(
          (el) => el.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )
        .map((o) => ({
          value: o.value,
          label: o.label,
          description: o.description,
          owner: o.owner,
          _static: 0,
        }));
      callback(options);
    }
  };

  loadOptionsUserPlaylists = (inputValue, callback) => {
    if (inputValue == "") {
      return this.props.store.userPlaylists
        .fetchAll()
        .andThen((res, error) => {
          if (res) {
            const options = res.toArray().map((o) => ({
              value: o.playlist_id,
              label: this.props.store.language.label630 + o.name,
              description: o.description
                ? o.description
                : this.props.store.language.label686,
              owner: 3,
              _static: o.static,
            }));
            this.setState({ my_playlists: options, messageError: null });
            callback(options);
          }
        });
    } else {
      const options = this.state.my_playlists
        .filter(
          (el) => el.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )
        .map((o) => ({
          value: o.value,
          label: o.label,
          description: o.description
            ? o.description
            : this.props.store.language.label686,
          owner: o.owner,
          _static: o._static,
        }));
      callback(options);
    }
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong> {this.props.store.language.label763}</strong>
            </Button>
          ) : (
            <Button
              disabled={!this.state.categories_playlists_id}
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label759}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderSelectPlaylists = (options) => {
    return (
      <Select
        isAsync
        isClearable
        cacheOptions
        defaultOptions
        required
        onChange={this.handleSelectChange}
        name="playlist"
        autocomplete="nope"
        loadOptions={options}
        backgroundColor="#141414"
        className="pt-1"
        placeholder={this.props.store.language.label574}
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
      />
    );
  };

  handleChangeRadio = (sender, value) => {
    this.setState({
      radioValue: value,
      categories_playlists_id: null,
      messageError: null,
      description: "",
    });
  };

  render() {
    const { radioValue } = this.state;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label764}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Panel
            className={this.props.store.viewMobile && "my-1"}
            color="gray"
            padding={this.props.store.viewMobile ? "28px 20px" : "22px 15px"}
            style={{
              marginBottom: !this.props.store.viewMobile && "6px",
            }}
          >
            <div
              className="flex"
              style={{
                gap: "2px",
                flexDirection: "column",
              }}
            >
              <RadioGroup
                group="sizes"
                size={this.props.store.viewMobile ? "md" : "sm"}
                onChange={this.handleChangeRadio}
                value={radioValue}
                classNames=" group"
              >
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="1"
                  class="radio"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight={Number(radioValue) === 1 ? "regular" : "medium"}
                    color={Number(radioValue) === 1 ? "white" : "gray"}
                  >
                    {"Playlists, " + this.props.store.getServiceName()}
                  </Text>
                </Radio>
                <Radio
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  value="2"
                  class="radio"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight={Number(radioValue) === 2 ? "regular" : "medium"}
                    color={Number(radioValue) === 2 ? "white" : "gray"}
                  >
                    {this.props.store.language.label323}
                  </Text>
                </Radio>
              </RadioGroup>
            </div>


            <Field
                label="Playlist"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                className="pt-1"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                {this.state.radioValue == 1 &&
                  this.renderSelectPlaylists(this.loadOptionsKasimu)}
                {this.state.radioValue == 2 &&
                  this.renderSelectPlaylists(this.loadOptionsUserPlaylists)}
            </Field>
          </Panel>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

PlaylistsFavoriteNewModal.propTypes = {
  user_id: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PlaylistsFavoriteNewModal.defaultProps = {
  user_id: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PlaylistsFavoriteNewModal));
