import { Store } from "ks_storybook";
import { SongStyles } from "../models";

class SongStylesStore extends Store {
  model = SongStyles;

  fetchParams(free_rights, lang) {
    return this.search(
      { per_page: 1000, free_rights: free_rights, lang: lang },
      "song_styles_params",
      true,
      "/song_styles/get_style_for_params"
    );
  }

  fetchAllParams(free_rights, lang) {
    return this.search(
      { per_page: 1000, free_rights: free_rights, lang: lang },
      "song_styles_all_params",
      true,
      "/song_styles/get_style_for_params_all"
    );
  }
}

export default SongStylesStore;
