import { Store } from "ks_storybook";
import { AdPlan } from "../models";

class AdsPlansStore extends Store {
  model = AdPlan;

  getAdsPlansUser(value) {
    return this.search(
      { per_page: 1000 },
      "actions_advertisements_plans",
      true,
      `/actions_advertisements_plans/${value}/get_ads_planner_user`
    );
  }
}

export default AdsPlansStore;
