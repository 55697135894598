import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import { Select } from "../Select";
import {
  Field,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  DateTimePicker,
  Checkbox,
  TextInput,
  Radio,
  RadioGroup,
  Text,
  Panel,
} from "ks_storybook";
import { AdBatchesModal } from ".";
import { withToastManager } from "react-toast-notifications";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

@observer
class AdPlanEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdBatchesModel: false,
      isSaving: false,
      isValueRadioGroup1: this.props.currentAdPlans.exact_day != "NULL" ? 2 : 1,
      isValueRadioGroup2:
        this.props.currentAdPlans.exact_hour != "NULL" ? 1 : 2,
      isValueRadioGroup3:
        this.props.currentAdPlans.frequency_songs == 0 ? 2 : 1,
      isCheckboxStart:
        this.props.currentAdPlans.start_date != "NULL" ? true : false,
      isCheckboxFinish:
        this.props.currentAdPlans.finish_date != "NULL" ? true : false,
      user_id: this.props.userId,
      name: this.props.currentAdPlans.name,
      launcher_limiter: this.props.currentAdPlans.launcher_limiter,
      advertisements: this.getItemsData(this.props.currentAdPlans.ad_batches),
      week_day:
        this.props.currentAdPlans.week_day == 0
          ? 8
          : this.props.currentAdPlans.week_day,
      start_date:
        this.props.currentAdPlans.start_date != "NULL"
          ? moment(this.props.currentAdPlans.start_date, "DD/MM/YYYY").toDate()
          : moment(new Date(), "DD/MM/YYYY").toDate(),
      finish_date:
        this.props.currentAdPlans.finish_date != "NULL"
          ? moment(this.props.currentAdPlans.finish_date, "DD/MM/YYYY").toDate()
          : moment(new Date(2099, 11, 31), "DD/MM/YYYY").toDate(),
      s_hour:
        this.props.currentAdPlans.start_date != "NULL"
          ? this.getHoursMin(
              moment(
                this.props.currentAdPlans.start_date,
                "DD/MM/YYYY HH:mm"
              ).format("HH:mm"),
              0
            )
          : "00",
      s_min:
        this.props.currentAdPlans.start_date != "NULL"
          ? this.getHoursMin(
              moment(
                this.props.currentAdPlans.start_date,
                "DD/MM/YYYY HH:mm"
              ).format("HH:mm"),
              1
            )
          : "00",
      f_hour:
        this.props.currentAdPlans.finish_date != "NULL"
          ? this.getHoursMin(
              moment(
                this.props.currentAdPlans.finish_date,
                "DD/MM/YYYY HH:mm"
              ).format("HH:mm"),
              0
            )
          : "23",
      f_min:
        this.props.currentAdPlans.finish_date != "NULL"
          ? this.getHoursMin(
              moment(
                this.props.currentAdPlans.finish_date,
                "DD/MM/YYYY HH:mm"
              ).format("HH:mm"),
              1
            )
          : "59",
      exact_day:
        this.props.currentAdPlans.exact_day != "NULL"
          ? moment(this.props.currentAdPlans.exact_day, "DD/MM/YYYY").toDate()
          : moment(new Date(), "DD/MM/YYYY").toDate(),
      exact_h:
        this.props.currentAdPlans.exact_hour != "NULL"
          ? this.getHoursMin(this.props.currentAdPlans.exact_hour, 0)
          : "00",
      exact_m:
        this.props.currentAdPlans.exact_hour != "NULL"
          ? this.getHoursMin(this.props.currentAdPlans.exact_hour, 1)
          : "00",
      from_h:
        this.props.currentAdPlans.from_hour != "NULL"
          ? this.getHoursMin(this.props.currentAdPlans.from_hour, 0)
          : "00",
      from_m:
        this.props.currentAdPlans.from_hour != "NULL"
          ? this.getHoursMin(this.props.currentAdPlans.from_hour, 1)
          : "00",
      to_h:
        this.props.currentAdPlans.to_hour != "NULL"
          ? this.getHoursMin(this.props.currentAdPlans.to_hour, 0)
          : "23",
      to_m:
        this.props.currentAdPlans.to_hour != "NULL"
          ? this.getHoursMin(this.props.currentAdPlans.to_hour, 1)
          : "59",
      frequency_songs:
        this.props.currentAdPlans.frequency_songs == 0
          ? 1
          : this.props.currentAdPlans.frequency_songs,
      frequency_minutes:
        this.props.currentAdPlans.frequency_minutes == 0
          ? 1
          : this.props.currentAdPlans.frequency_minutes,
    };

    this.user_id = this.props.userId;
    this.weekdays = this.props.store.language.label61.split(",");
  }

  getHoursMin = (hour, index) => {
    const myArray = hour.split(":", 2);
    return myArray[index];
  };

  getIndexInterval = (e, str) => {
    const interval = this.getIntervalNum(e);
    let index = interval.indexOf(str);
    return index;
  };

  getIntervalNum = (e) => {
    let items = [];
    for (let i = 0; i <= e; i++) {
      items.push(this.valid(i));
    }
    return items;
  };

  getInterval = (e) => {
    let items = [];
    for (let i = 0; i <= e; i++) {
      items.push({
        value: this.valid(i),
        label: this.valid(i),
      });
    }
    return items;
  };

  getItemsData = (obj) => {
    var arr = [];
    obj.map((a) =>
      arr.push({
        key: uuidv4(),
        id: a.advertisements.id,
        name: a.advertisements.name,
        ad_path: a.advertisements.ad_path,
      })
    );
    return arr;
  };

  handleCheckboxStartChange = (sender, value) => {
    this.setState({ isCheckboxStart: value });
  };

  handleCheckboxFinishChange = (sender, value) => {
    if (value) {
      this.setState({
        finish_date: moment(
          moment(this.state.start_date).format("DD/MM/YYYY"),
          "DD/MM/YYYY"
        )
          .add(1, "days")
          .toDate(),
      });
    } else {
      this.setState({
        finish_date: moment(new Date(2099, 11, 31), "DD/MM/YYYY").toDate(),
      });
    }
    this.setState({ isCheckboxFinish: value });
  };

  handleChange = (value, sender) => {
    const name = sender.name;
    const val = !value ? null : value.value;
    switch (name) {
      case "launcher_limiter":
        this.setState({ launcher_limiter: val });
        break;
      case "week_day":
        this.setState({ week_day: val });
        break;
      case "s_hour":
        this.setState({ s_hour: val });
        break;
      case "s_min":
        this.setState({ s_min: val });
        break;
      case "f_hour":
        this.setState({ f_hour: val });
        break;
      case "f_min":
        this.setState({ f_min: val });
        break;
      case "exact_h":
        this.setState({ exact_h: val });
        break;
      case "exact_m":
        this.setState({ exact_m: val });
        break;
      case "from_h":
        this.setState({ from_h: val });
        break;
      case "from_m":
        this.setState({ from_m: val });
        break;
      case "to_h":
        this.setState({ to_h: val });
        break;
      case "to_m":
        this.setState({ to_m: val });
        break;
      case "frequency_songs":
        this.setState({ frequency_songs: val });
        break;
      case "frequency_minutes":
        this.setState({ frequency_minutes: val });
        break;
      default:
        break;
    }
  };

  handleSelectedChange = (value) => {
    if (!value) {
      this.setState({ advertisements: [] });
    } else {
      this.setState({ advertisements: value });
    }
  };

  handleChangeDate = (sender, value, name, validation) => {
    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "start_date":
        this.setState({ start_date: value });
        break;
      case "finish_date":
        this.setState({ finish_date: value });
        break;
      case "exact_day":
        this.setState({ exact_day: value });
        break;
      default:
        break;
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleBatchesModelOpen = () => {
    this.setState({ showAdBatchesModel: true });
  };

  handleBatchesModelClose = () => {
    this.setState({ showAdBatchesModel: false });
  };

  handleBatchesModelSave = (ads) => {
    this.setState({ showAdBatchesModel: false, advertisements: ads });
  };

  getParams = () => {
    const {
      isValueRadioGroup1,
      isValueRadioGroup2,
      isValueRadioGroup3,
      isCheckboxStart,
      isCheckboxFinish,
      name,
      launcher_limiter,
      advertisements,
      week_day,
      start_date,
      finish_date,
      s_hour,
      s_min,
      f_hour,
      f_min,
      exact_day,
      exact_h,
      exact_m,
      from_h,
      from_m,
      to_h,
      to_m,
      frequency_songs,
      frequency_minutes,
    } = this.state;

    const params = {
      id: this.props.currentAdPlans.id,
      user_id: this.user_id,
      advertising_guidelines_id:
        this.props.currentAdPlans.advertising_guidelines_id,
      advertisements: advertisements.map((s) => s.id),
      name: name,
      launcher_limiter: 1,
      week_day: 0,
      start_date: "NULL",
      finish_date: "NULL",
      exact_day: "NULL",
      exact_hour: "NULL",
      from_hour: "NULL",
      to_hour: "NULL",
      frequency_songs: 0,
      frequency_minutes: 0,
      priority: 0,
    };

    if (isValueRadioGroup1 == 1) {
      params.week_day = parseInt(week_day);
      if (params.week_day == 0) {
        params.week_day = 8;
      }
      if (isCheckboxStart) {
        params.start_date =
          moment(start_date).format("DD/MM/YYYY") + " " + s_hour + ":" + s_min;
        params.finish_date =
          moment(finish_date).format("DD/MM/YYYY") + " " + f_hour + ":" + f_min;
      }
      if (isCheckboxFinish) {
        params.start_date =
          moment(start_date).format("DD/MM/YYYY") + " " + s_hour + ":" + s_min;
        params.finish_date =
          moment(finish_date).format("DD/MM/YYYY") + " " + f_hour + ":" + f_min;
      }
      if (isValueRadioGroup2 == 1) {
        params.exact_hour = exact_h + ":" + exact_m;
        params.launcher_limiter = params.advertisements.length;
        params.priority = 2;
      } else if (isValueRadioGroup2 == 2) {
        params.from_hour = from_h + ":" + from_m;
        params.to_hour = to_h + ":" + to_m;
        if (isValueRadioGroup3 == 1) {
          params.frequency_songs = frequency_songs;
          params.priority = 3;
        } else if (isValueRadioGroup3 == 2) {
          params.frequency_minutes = frequency_minutes;
          params.priority = 4;
        }
        params.launcher_limiter = launcher_limiter;
      }
    } else if (isValueRadioGroup1 == 2) {
      params.exact_day = moment(exact_day).format("DD/MM/YYYY");
      if (isValueRadioGroup2 == 1) {
        params.exact_hour = exact_h + ":" + exact_m;
        params.launcher_limiter = params.advertisements.length;
        params.priority = 1;
      } else if (isValueRadioGroup2 == 2) {
        params.from_hour = from_h + ":" + from_m;
        params.to_hour = to_h + ":" + to_m;
        if (isValueRadioGroup3 == 1) {
          params.frequency_songs = frequency_songs;
          params.priority = 5;
        } else if (isValueRadioGroup3 == 2) {
          params.frequency_minutes = frequency_minutes;
          params.priority = 6;
        }
        params.launcher_limiter = launcher_limiter;
      }
    }
    return params;
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const params = this.getParams();
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .put("/advertising_guidelines/update_guidelines_panel", params)
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label518, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          },
          (error) => {
            let str = error.toString();
            str = str.replace("Error: ", "");
            let mydatas = JSON.parse(str);
            toastManager.add(this.getErrorMessager(mydatas["codError"]), {
              appearance: "error",
              autoDismiss: true,
            });
            this.handleClose();
          }
        )
        .catch((error) => {
          toastManager.add(this.props.store.language.label517, {
            appearance: "error",
            autoDismiss: true,
          });
          this.handleClose();
        });
    });
  };

  handleRadioGroup1 = (sender, value) => {
    this.setState({ isValueRadioGroup1: value });
  };

  handleRadioGroup2 = (sender, value) => {
    if (value == 1) {
      this.setState({ isValueRadioGroup3: 0 });
    }
    this.setState({ isValueRadioGroup2: value });
  };

  handleRadioGroup3 = (sender, value) => {
    this.setState({ isValueRadioGroup3: value });
  };

  getErrorMessager = (codError) => {
    const messager = {
      1: this.props.store.language.label327,
      2: this.props.store.language.label373,
      3: this.props.store.language.label374,
      4: this.props.store.language.label375,
    };
    return messager[codError];
  };

  loadOptions = (inputValue, callback) => {
    return this.props.store.ads
      .fetchSearch(inputValue || " ")
      .andThen((res, error) => {
        if (error) {
        } else {
          const options = res
            .toArray()
            .map((o) => ({ value: o.id, label: o.name }));
          callback(options);
        }
      });
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mb-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3 mb-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderDaySelect = () => {
    const { week_day } = this.state;
    const options = [
      { value: 1, label: this.weekdays[1] },
      { value: 2, label: this.weekdays[2] },
      { value: 3, label: this.weekdays[3] },
      { value: 4, label: this.weekdays[4] },
      { value: 5, label: this.weekdays[5] },
      { value: 6, label: this.weekdays[6] },
      { value: 7, label: this.weekdays[0] },
      { value: 8, label: this.props.store.language.label349 },
      { value: 9, label: this.props.store.language.label350 },
      { value: 10, label: this.props.store.language.label351 },
    ];
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "100px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label368}
          </Text>
        </div>
        <Select
          onChange={this.handleChange}
          name="week_day"
          autocomplete="nope"
          required
          options={options}
          defaultValue={
            week_day
              ? options[
                  options
                    .map(function (e) {
                      return e.value;
                    })
                    .indexOf(week_day)
                ]
              : options[7]
          }
          className="is-fullwidth"
          backgroundColor="black"
          placeholder
          size={this.props.store.viewMobile ? "lg" : "md"}
          isMobile={!this.props.store.viewMobile}
        />
      </div>
    );
  };

  renderDefineStartDateAndFinishDate = () => {
    const { isCheckboxStart, isCheckboxFinish } = this.state;
    return (
      <Field className="py-1">
        <div
          className="is-row is-fullwidth"
          style={{
            alignItems: "center",
            flexWrap: !this.props.store.viewMobile && "wrap",
          }}
        >
          <Checkbox
            size={this.props.store.viewMobile ? "lg" : "md"}
            name="checkbox_start"
            onChange={this.handleCheckboxStartChange}
            invert
            isChecked={isCheckboxStart}
          >
            <div style={{ minWidth: "105px" }}>
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                weight="medium"
              >
                {this.props.store.language.label371}
              </Text>
            </div>
          </Checkbox>
          {this.renderStartDateSelect()}
          <div
            style={{ margin: this.props.store.viewMobile ? "0 8px" : "8px" }}
          />
          {this.renderStartHourSelect()}
        </div>
        <div
          className="is-row is-fullwidth mt-1"
          style={{
            alignItems: "center",
            flexWrap: !this.props.store.viewMobile && "wrap",
          }}
        >
          <Checkbox
            size={this.props.store.viewMobile ? "lg" : "md"}
            name="checkbox_finish"
            onChange={this.handleCheckboxFinishChange}
            invert
            isChecked={isCheckboxFinish}
          >
            <div style={{ minWidth: "105px" }}>
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                weight="medium"
              >
                {this.props.store.language.label372}
              </Text>
            </div>
          </Checkbox>
          {this.renderFinishDateSelect()}
          <div
            style={{ margin: this.props.store.viewMobile ? "0 8px" : "8px" }}
          />
          {this.renderFinishHourSelect()}
        </div>
      </Field>
    );
  };

  renderStartDateSelect = () => {
    const { isCheckboxStart } = this.state;
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "35px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label370}
          </Text>
        </div>
        <DateTimePicker
          name="start_date"
          value={moment(this.state.start_date, "DD/MM/YYYY").toDate()}
          dateFormat="DD/MM/YYYY"
          onChange={this.handleChangeDate}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          disabled={!isCheckboxStart}
          iconKasimu="arrow-down"
          reversed
          invert
          backgroundColor="blackDark"
          colorIcon="#999999"
          color="#999999"
          fullWidth
          className="is-fullwidth"
        />
      </div>
    );
  };

  renderStartHourSelect = () => {
    const { s_hour, s_min } = this.state;
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "45px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label369}
          </Text>
        </div>
        <div className="flex is-fullwidth">
          {this.renderSelectHours(
            "s_hour",
            this.getInterval(23),
            this.getIndexInterval(23, s_hour)
          )}
          <div style={{ marginLeft: "5px", minWidth: "10px" }}>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {":"}
            </Text>
          </div>
          {this.renderSelectHours(
            "s_min",
            this.getInterval(59),
            this.getIndexInterval(59, s_min)
          )}
        </div>
      </div>
    );
  };

  renderFinishDateSelect = () => {
    const { isCheckboxFinish } = this.state;
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "35px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label370}
          </Text>
        </div>
        <DateTimePicker
          name="finish_date"
          value={moment(this.state.finish_date, "DD/MM/YYYY").toDate()}
          dateFormat="DD/MM/YYYY"
          onChange={this.handleChangeDate}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          disabled={!isCheckboxFinish}
          iconKasimu="arrow-down"
          reversed
          invert
          backgroundColor="blackDark"
          colorIcon="#999999"
          color="#999999"
          fullWidth
          className="is-fullwidth"
        />
      </div>
    );
  };

  renderFinishHourSelect = () => {
    const { f_hour, f_min } = this.state;
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "45px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label369}
          </Text>
        </div>
        <div className="flex is-fullwidth">
          {this.renderSelectHours(
            "f_hour",
            this.getInterval(23),
            this.getIndexInterval(23, f_hour)
          )}
          <div style={{ marginLeft: "5px", minWidth: "10px" }}>
            <Text
              upper
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {":"}
            </Text>
          </div>
          {this.renderSelectHours(
            "f_min",
            this.getInterval(59),
            this.getIndexInterval(59, f_min)
          )}
        </div>
      </div>
    );
  };

  renderDayExactSelect = () => {
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "100px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label352}
          </Text>
        </div>
        <DateTimePicker
          name="exact_day"
          value={moment(this.state.exact_day, "DD/MM/YYYY").toDate()}
          dateFormat="DD/MM/YYYY"
          onChange={this.handleChangeDate}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          iconKasimu="arrow-down"
          reversed
          invert
          backgroundColor="blackDark"
          colorIcon="#999999"
          color="#999999"
          fullWidth
          className="is-fullwidth"
        />
      </div>
    );
  };

  valid = (num) => {
    switch (num) {
      case 0:
        return "00";
      case 1:
        return "01";
      case 2:
        return "02";
      case 3:
        return "03";
      case 4:
        return "04";
      case 5:
        return "05";
      case 6:
        return "06";
      case 7:
        return "07";
      case 8:
        return "08";
      case 9:
        return "09";
      default:
        return num.toString();
    }
  };

  getNum = (value) => {
    let items = [];
    for (let i = 1; i <= value; i++) {
      items.push({ value: i, label: i });
    }
    return items;
  };

  renderSelectExactHour = () => {
    const { exact_h, exact_m } = this.state;
    return (
      <div
        className="is-row"
        style={{
          width: this.props.store.viewMobile && "350px",
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "100px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
            className="my-1"
          >
            {this.props.store.language.label353}
          </Text>
        </div>
        <div className="flex is-fullwidth">
          {this.renderSelectHours(
            "exact_h",
            this.getInterval(23),
            this.getIndexInterval(23, exact_h)
          )}
          <div style={{ marginLeft: "5px", minWidth: "10px" }}>
            <Text
              upper
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {":"}
            </Text>
          </div>
          {this.renderSelectHours(
            "exact_m",
            this.getInterval(59),
            this.getIndexInterval(59, exact_m)
          )}
        </div>
      </div>
    );
  };

  getHoursInterval = (e) => {
    let items = [];
    for (let i = 0; i <= 23; i++) {
      items.push({
        value: this.valid(i) + ":" + e,
        label: this.valid(i) + ":" + e,
      });
    }
    return items;
  };

  renderSelectHours = (name, options, index) => {
    return (
      <Select
        className="is-fullwidth"
        onChange={this.handleChange}
        name={name}
        required
        autocomplete="nope"
        options={options}
        defaultValue={options[index]}
        isSearchable={false}
        isClearable={false}
        backgroundColor="black"
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
      />
    );
  };

  /*renderSelectHours = (label, name, options, index, id) => {
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "45px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {label}
          </Text>
        </div>
        <Select
          onChange={this.handleChange}
          name={name}
          required
          autocomplete="nope"
          options={options}
          defaultValue={
            id
              ? options[
                  options
                    .map(function(e) {
                      return e.value;
                    })
                    .indexOf(id)
                ]
              : options[index]
          }
          isSearchable={false}
          isClearable={false}
          className="is-fullwidth"
          backgroundColor="#e8e8e8"
          separatorColor="#e8e8e8"
          iconColor="black"
          textColor="#000000"
          placeholder
          size={this.props.store.viewMobile ? "lg" : "md"}
          isMobile={!this.props.store.viewMobile}
        />
      </div>
    );
  };*/

  renderFrequency = () => {
    return (
      <React.Fragment>
        <Field>
          <RadioGroup
            group="sizes"
            onChange={this.handleRadioGroup3}
            value={this.state.isValueRadioGroup3}
            size={this.props.store.viewMobile ? "md" : "sm"}
          >
            <Radio size={this.props.store.viewMobile ? "md" : "sm"} value={1}>
              {this.renderFrequencyXsongs()}
            </Radio>
            <Radio size={this.props.store.viewMobile ? "md" : "sm"} value={2}>
              {this.renderFrequencyXmin()}
            </Radio>
          </RadioGroup>
          {this.renderLauncherLimiter()}
        </Field>
      </React.Fragment>
    );
  };

  renderFrequencyXsongs = () => {
    const { frequency_songs } = this.state;
    const options = this.getNum(30);
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "185px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "sm"}
            weight="medium"
          >
            {this.props.store.language.label367}
          </Text>
        </div>
        <div style={{ minWidth: "139px" }}>
          <Select
            onChange={this.handleChange}
            name="frequency_songs"
            required
            autocomplete="nope"
            options={options}
            defaultValue={
              frequency_songs
                ? options[
                    options
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(frequency_songs)
                  ]
                : options[0]
            }
            isSearchable={false}
            isClearable={false}
            className="is-fullwidth"
            backgroundColor="black"
            placeholder
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </div>
      </div>
    );
  };

  renderFrequencyXmin() {
    const { frequency_minutes } = this.state;
    const options = this.getNum(60);
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "185px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label366}
          </Text>
        </div>
        <div style={{ minWidth: "139px" }}>
          <Select
            onChange={this.handleChange}
            name="frequency_minutes"
            required
            autocomplete="nope"
            options={options}
            defaultValue={
              frequency_minutes
                ? options[
                    options
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(frequency_minutes)
                  ]
                : options[0]
            }
            isSearchable={false}
            isClearable={false}
            className="is-fullwidth"
            backgroundColor="black"
            placeholder
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </div>
      </div>
    );
  }

  renderLauncherLimiter() {
    const { launcher_limiter } = this.state;
    const options = this.getNum(10);
    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
          marginTop: "5px",
        }}
      >
        <div style={{ minWidth: "205px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
          >
            {this.props.store.language.label365}
          </Text>
        </div>
        <div style={{ minWidth: "139px" }}>
          <Select
            onChange={this.handleChange}
            name="launcher_limiter"
            required
            autocomplete="nope"
            options={options}
            defaultValue={
              launcher_limiter
                ? options[
                    options
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(launcher_limiter)
                  ]
                : options[0]
            }
            isSearchable={false}
            isClearable={false}
            className="is-fullwidth"
            backgroundColor="black"
            placeholder
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </div>
      </div>
    );
  }

  renderHourRangeSelect = () => {
    const { from_h, from_m, to_h, to_m } = this.state;

    return (
      <div
        className="is-row is-fullwidth"
        style={{
          alignItems: this.props.store.viewMobile && "center",
          flexDirection: this.props.store.viewMobile ? "row" : "column",
        }}
      >
        <div style={{ minWidth: "140px" }}>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="medium"
            className="my-1"
          >
            {this.props.store.language.label364}
          </Text>
        </div>
        <div
          className="is-row is-fullwidth"
          style={{
            alignItems: this.props.store.viewMobile && "center",
            flexDirection: this.props.store.viewMobile ? "row" : "column",
          }}
        >
          <div style={{ minWidth: "45px" }}>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              weight="medium"
            >
              {this.props.store.language.label71}
            </Text>
          </div>
          <div className="flex is-fullwidth">
            {this.renderSelectHours(
              "from_h",
              this.getInterval(23),
              this.getIndexInterval(23, from_h)
            )}
            <div style={{ marginLeft: "5px", minWidth: "10px" }}>
              <Text
                upper
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="strong"
              >
                {":"}
              </Text>
            </div>
            {this.renderSelectHours(
              "from_m",
              this.getInterval(59),
              this.getIndexInterval(59, from_m)
            )}
          </div>
        </div>
        <div style={{ margin: "0 16px" }} />
        <div
          className="is-row is-fullwidth"
          style={{
            alignItems: this.props.store.viewMobile && "center",
            flexDirection: this.props.store.viewMobile ? "row" : "column",
          }}
        >
          <div style={{ minWidth: "45px" }}>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              weight="medium"
            >
              {this.props.store.language.label193}
            </Text>
          </div>
          <div className="flex is-fullwidth">
            {this.renderSelectHours(
              "to_h",
              this.getInterval(23),
              this.getIndexInterval(23, to_h)
            )}
            <div style={{ marginLeft: "5px", minWidth: "10px" }}>
              <Text
                upper
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="strong"
              >
                {":"}
              </Text>
            </div>
            {this.renderSelectHours(
              "to_m",
              this.getInterval(59),
              this.getIndexInterval(59, to_m)
            )}
          </div>
        </div>
      </div>
    );
  };

  @computed
  get canSave() {
    if (
      !this.state.name ||
      this.state.name == "NULL" ||
      this.state.advertisements.length == 0 ||
      this.state.isValueRadioGroup1 == 0 ||
      this.state.isValueRadioGroup2 == 0
    )
      return false;
    if (this.state.isValueRadioGroup2 == 2) {
      if (
        this.state.isValueRadioGroup3 == 0 ||
        this.state.isValueRadioGroup3 == 0
      )
        return false;
    }
    return true;
  }

  render() {
    const {
      showAdBatchesModel,
      isValueRadioGroup1,
      isValueRadioGroup2,
      advertisements,
      name,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "840px" : "90%"}
        >
          <ModalHeader>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {this.props.store.language.label140} {name}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Panel
              className={this.props.store.viewMobile && "my-1"}
              color="gray"
              padding={this.props.store.viewMobile ? "28px 20px" : "22px 15px"}
              style={{
                marginBottom: !this.props.store.viewMobile && "6px",
              }}
            >
              <Field
                label={this.props.store.language.label53}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                <TextInput
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  autocomplete="nope"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  name="name"
                  value={name}
                  onChange={this.handleChangeDate}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
              <Field
                label={this.props.store.language.label29}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                <div className="flex items-center">
                  <Button
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-3 my-1 mr-2"
                    onClick={this.handleBatchesModelOpen}
                  >
                    <strong>{this.props.store.language.label508}</strong>
                  </Button>
                  <small>
                    {this.props.store.language.label509 +
                      ": " +
                      advertisements.length}
                  </small>
                </div>
              </Field>
            </Panel>
            <Panel
              className={this.props.store.viewMobile && "my-1"}
              color="gray"
              padding={this.props.store.viewMobile ? "28px 20px" : "22px 15px"}
              style={{
                marginBottom: !this.props.store.viewMobile && "6px",
              }}
            >
              <Field
                label={this.props.store.language.label362}
                size={this.props.store.viewMobile ? "xl" : "md"}
                NoteSize="xs"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                <RadioGroup
                  group="sizes"
                  onChange={this.handleRadioGroup1}
                  value={isValueRadioGroup1}
                  size={this.props.store.viewMobile ? "md" : "sm"}
                >
                  <Radio
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    value={2}
                  >
                    {this.renderDayExactSelect()}
                  </Radio>
                  <Radio
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    value={1}
                  >
                    {this.renderDaySelect()}
                  </Radio>
                </RadioGroup>
              </Field>
              {isValueRadioGroup1 == 1
                ? this.renderDefineStartDateAndFinishDate()
                : null}
            </Panel>
            <Panel
              className={this.props.store.viewMobile && "my-1"}
              color="gray"
              padding={this.props.store.viewMobile ? "28px 20px" : "25px 20px"}
              style={{
                marginBottom: !this.props.store.viewMobile && "6px",
              }}
            >
              <Field
                label={this.props.store.language.label363}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                <RadioGroup
                  group="sizes"
                  onChange={this.handleRadioGroup2}
                  value={isValueRadioGroup2}
                  size={this.props.store.viewMobile ? "md" : "sm"}
                >
                  <Radio
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    value={1}
                  >
                    {this.renderSelectExactHour()}
                  </Radio>
                  <Radio
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    value={2}
                  >
                    {this.renderHourRangeSelect()}
                  </Radio>
                </RadioGroup>
              </Field>
              {isValueRadioGroup2 == 2 ? this.renderFrequency() : null}
            </Panel>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showAdBatchesModel && (
          <AdBatchesModal
            ads={this.props.ads}
            selected={advertisements}
            onClose={this.handleBatchesModelClose}
            onSave={this.handleBatchesModelSave}
          />
        )}
      </React.Fragment>
    );
  }
}

AdPlanEditModal.propTypes = {
  userId: PropTypes.number,
  currentAdPlans: PropTypes.object,
  ads: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

AdPlanEditModal.defaultProps = {
  userId: null,
  currentAdPlans: null,
  ads: [],
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(AdPlanEditModal));
