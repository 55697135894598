import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { PPTrademarks } from "../../models";

@observer
class PPTrademarksNewModal extends Component {
  @observable ppTrademarksNew;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      name: null,
      messageError: null,
    };

    this.ppTrademarksNew = this.newPPTrademarks();
  }

  newPPTrademarks() {
    const ppTrademarksStore = this.props.store.ppTrademarks;
    const attrs = {
      name: null,
      origin_type: 1,
      deleted: 0,
    };
    return new PPTrademarks(attrs, ppTrademarksStore);
  }

  handleChange = (sender, value, name, validation) => {
    this.setState({ name: value });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { name } = this.state;
    if (this.validLength(name)) {
      this.ppTrademarksNew.name = name;
      this.setState({ isSaving: true }, () => {
        this.ppTrademarksNew.save().andThen((res, responseError) => {
          if (responseError) {
            toastManager.add(this.props.store.language.label419, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            toastManager.add(this.props.store.language.label420, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          }
        });
      });
    } else {
      this.setState({ messageError: this.props.store.language.label421 });
    }
  };

  @computed
  get canSave() {
    if (this.state.name) return false;
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              upper
              disabled={this.canSave}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render = () => {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        invert
      >
        <ModalHeader>
          <Text
            upper
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
          >
            {this.props.store.language.label417}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns>
            <Column>
              <Field
                label={this.props.store.language.label53}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                upper
                labelNote={this.props.store.language.label76}
              >
                <TextInput
                  className="is-fullwidth"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  name="name"
                  onChange={this.handleChange}
                  required
                  autocomplete="nope"
                  backgroundColor="grayLight"
                  color="black"
                  invert
                />
              </Field>
            </Column>
          </Columns>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  };
}

PPTrademarksNewModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PPTrademarksNewModal.defaultProps = {
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PPTrademarksNewModal));
