import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Text,
  Button,
  TextInput,
  CardPlaylists,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import {
  CreatePlaylistsModal,
  PlaylistAutomaticNewModal,
  PlaylistAutomaticEditModal,
  PlaylistManualNewModal,
  PlaylistManualEditModal,
  PlaylistsManualEyeModal,
  PlaylistsAutomaticEyeModal,
  ModifyStyleModal,
} from "../../../Components/Playlists";
import {
  EmptyContent,
  DeleteConfirmationModal,
} from "../../../Components/Common";

@observer
class PlaylistsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userPlaylists: this.getUserPlaylistsAll(),
      usersStyles: this.getUsersStylesAll(),
      songsStyles: this.getSongStyles(),
      showModalCreate: false,
      showModalAutomatic: false,
      showModalAutomaticEdit: false,
      showModalManual: false,
      showModalManualEdit: false,
      showModalModifyStyle: false,
      showDeleteModal: false,
      showModalManualEye: false,
      showModalAutomaticEye: false,
      currentUsersPlaylist: null,
      modifyStyle: null,
      info: null,
      search: "",
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label323;
  }

  getUserPlaylistsAll = () => {
    return this.props.store.userPlaylists.fetchAll();
  };

  getUsersStylesAll = () => {
    return this.props.store.usersStyles.fetchValidatedAll();
  };

  getSongStyles = () => {
    return this.props.store.songStyles.fetchParams(
      this.props.store.getFreeRights(),
      this.props.store.lang
    );
  };

  handleReload = () => {
    this.setState({ userPlaylists: this.getUserPlaylistsAll() });
  };

  handleShowModal = () => {
    this.setState({ showModalCreate: true });
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  getSearch = (obj, search) => {
    var arr = [];
    if (obj.length != 0) {
      arr = obj.filter(
        (e) => e.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  handleCloseModalCreate = () => {
    this.setState({ showModalCreate: false, info: null, modifyStyle: null });
  };

  handleShowModalAutomatic = () => {
    this.setState({ showModalAutomatic: true });
  };

  handleCloseModalAutomatic = () => {
    this.setState({ showModalAutomatic: false });
  };

  handleSaveModalAutomatic = () => {
    this.handleCloseModalCreate();
    this.handleCloseModalAutomatic();
    this.handleReload();
  };

  handleShowModalManual = () => {
    this.props.store.ui.destroy();
    this.setState({ showModalManual: true });
  };

  handleCloseModalManual = () => {
    this.setState({ showModalManual: false });
  };

  handleSaveModalManual = () => {
    this.handleCloseModalCreate();
    this.handleCloseModalManual();
    this.handleReload();
  };

  handleShowModalManualEdit = (playlist) => {
    this.props.store.ui.destroy();
    this.setState({
      showModalManualEdit: true,
      currentUsersPlaylist: playlist,
    });
  };

  handleCloseModalManualEdit = () => {
    this.setState({ showModalManualEdit: false, currentUsersPlaylist: null });
  };

  handleSaveModalManualEdit = () => {
    this.handleCloseModalManualEdit();
    this.handleReload();
  };

  handleShowModalAutomaticEdit = (playlist) => {
    this.setState({
      showModalAutomaticEdit: true,
      currentUsersPlaylist: playlist,
    });
  };

  handleCloseModalAutomaticEdit = () => {
    this.setState({
      showModalAutomaticEdit: false,
      currentUsersPlaylist: null,
    });
  };

  handleSaveModalAutomaticEdit = () => {
    this.handleCloseModalAutomaticEdit();
    this.handleReload();
  };

  handleShowDeleteModal = (playlist) => {
    this.setState({ showDeleteModal: true, currentUsersPlaylist: playlist });
  };

  handleCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, currentUsersPlaylist: null });
  };

  handleShowModalModifyStyle = (modifyStyle) => {
    this.setState({ showModalModifyStyle: true, modifyStyle: modifyStyle });
  };

  handleCloseModalModifyStyle = () => {
    this.setState({ showModalModifyStyle: false, modifyStyle: null });
  };

  handleSaveModalModifyStyle = (modifyStyle) => {
    this.setState({ showModalModifyStyle: false, modifyStyle: modifyStyle });
  };

  handleShowModalManualEye = (playlist) => {
    this.setState({ showModalManualEye: true, currentUsersPlaylist: playlist });
  };

  handleCloseModalManualEye = () => {
    this.setState({ showModalManualEye: false, currentUsersPlaylist: null });
  };

  handleShowModalAutomaticEye = (playlist) => {
    this.setState({
      showModalAutomaticEye: true,
      currentUsersPlaylist: playlist,
    });
  };

  handleCloseModalAutomaticEye = () => {
    this.setState({ showModalAutomaticEye: false, currentUsersPlaylist: null });
  };

  handleDelete = () => {
    this.handleCloseDeleteModal();
    this.handleReload();
  };

  handleNext = (value) => {
    this.setState({ info: value });
    switch (value.playlistType) {
      case 1:
        this.handleShowModalAutomatic();
        break;
      case 2:
        this.handleShowModalManual();
        break;
      default:
        break;
    }
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getPanel = () => {
    return (
      <Columns
        className={this.props.store.viewMobile && "my-2"}
        style={{
          margin: !this.props.store.viewMobile && "6px 0",
        }}
        isMultiline
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile && "mr-2"}
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: !this.props.store.viewMobile && "6px",
            }}
          >
            <TextInput
              onChange={this.handleChangeSearch}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              placeholder={this.props.store.language.label586}
            />
          </div>

          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleShowModal}
            onClick={() =>
              this.props.store.getPermission(38, 38, () => {
                this.handleShowModal()
              })
            }
          >
            {/* <strong>{this.props.store.language.label587}</strong> */}
            <strong>{this.props.store.language.label82}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.handleReload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderHeaderOk = () => {
    const { userPlaylists, search } = this.state;
    const arrayPlaylists = this.getSearch(userPlaylists.toArray(), search);

    return (
      <React.Fragment>
        {arrayPlaylists.length === 0 ? (
          <EmptyContent />
        ) : (
          arrayPlaylists.map((p) => (
            <CardPlaylists
              name={p.name}
              description={p.description}
              label={
                p.static == 1
                  ? this.props.store.language.label584
                  : this.props.store.language.label585
              }
              iconEye={this.props.store.viewMobile ? "eye" : "eye-solid"}
              iconEdit={this.props.store.viewMobile ? "pen" : "pen-solid"}
              iconDeleted={
                this.props.store.viewMobile ? "trash" : "trash-solid"
              }
              eyeClick={() =>
                p.static == 1
                  ? this.handleShowModalManualEye(p)
                  : this.handleShowModalAutomaticEye(p)
              }
              /*editClick={() =>
                p.static == 1
                  ? this.handleShowModalManualEdit(p)
                  : this.handleShowModalAutomaticEdit(p)
              }*/
              editClick={() =>
                this.props.store.getPermission(38, 38, () => {
                  p.static == 1
                  ? this.handleShowModalManualEdit(p)
                  : this.handleShowModalAutomaticEdit(p)
                })
              }
              //deletedClick={() => this.handleShowDeleteModal(p)}
              deletedClick={() =>
                this.props.store.getPermission(38, 38, () => {
                  this.handleShowDeleteModal(p)
                })
              }
              isMobile={!this.props.store.viewMobile}
            />
          ))
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { userPlaylists } = this.state;
    if (!userPlaylists) return this.renderEmptyContent();
    return !userPlaylists.isOk()
      ? this.renderLoader()
      : this.renderSongsStyles();
  };

  renderSongsStyles = () => {
    const { songsStyles } = this.state;
    if (!songsStyles) return this.renderEmptyContent();
    return !songsStyles.isOk() ? this.renderLoader() : this.renderUsersStyles();
  };

  renderUsersStyles = () => {
    const { usersStyles } = this.state;
    if (!usersStyles) return this.renderEmptyContent();
    return !usersStyles.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getOptionsStyles = (obj) => {
    return obj.toArray().map((o) => ({
      value: o.id,
      label: o.translation ? o.translation[0].description : o.description,
    }));
  };

  getOptionsUser = (obj) => {
    return obj.toArray().map((o) => ({ value: o.style_id, label: o.name }));
  };

  render() {
    const {
      showModalCreate,
      showModalAutomatic,
      showModalManual,
      info,
      showDeleteModal,
      currentUsersPlaylist,
      songsStyles,
      usersStyles,
      showModalModifyStyle,
      modifyStyle,
      showModalManualEdit,
      showModalManualEye,
      showModalAutomaticEye,
      showModalAutomaticEdit,
    } = this.state;

    const stylesOptions = this.getOptionsStyles(songsStyles);
    const stylesUserOptions = this.getOptionsUser(usersStyles);

    return (
      <React.Fragment>
        <Columns isCentered isGapless className="is-multiline is-mobile">
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                multiline
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label323}
              </Text>
            )}
            {/* <div
              style={{
                maxWidth: "730px",
              }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label579}
              </Paragraph>
            </div> */}
            {this.getPanel()}
            {this.renderHeader()}
          </Column>
        </Columns>
        {showModalCreate && (
          <CreatePlaylistsModal
            onClose={this.handleCloseModalCreate}
            onNext={this.handleNext}
          />
        )}
        {showModalManualEye && (
          <PlaylistsManualEyeModal
            currentUsersPlaylist={currentUsersPlaylist}
            onClose={this.handleCloseModalManualEye}
          />
        )}
        {showModalAutomaticEye && (
          <PlaylistsAutomaticEyeModal
            currentUsersPlaylist={currentUsersPlaylist}
            onClose={this.handleCloseModalAutomaticEye}
          />
        )}
        {showModalAutomatic && (
          <PlaylistAutomaticNewModal
            name={info && info.name}
            description={info && info.description}
            songsStyles={songsStyles.toArray()}
            usersStyles={usersStyles.toArray()}
            onClose={this.handleCloseModalAutomatic}
            onOpend={this.handleShowModalModifyStyle}
            onSave={this.handleSaveModalAutomatic}
          />
        )}
        {showModalAutomaticEdit && (
          <PlaylistAutomaticEditModal
            playlists={currentUsersPlaylist}
            songsStyles={songsStyles.toArray()}
            usersStyles={usersStyles.toArray()}
            onClose={this.handleCloseModalAutomaticEdit}
            onOpend={this.handleShowModalModifyStyle}
            onSave={this.handleSaveModalAutomaticEdit}
          />
        )}
        {showModalModifyStyle && (
          <ModifyStyleModal
            item={modifyStyle}
            onClose={this.handleCloseModalModifyStyle}
            onSave={this.handleSaveModalModifyStyle}
          />
        )}
        {showModalManual && (
          <PlaylistManualNewModal
            stylesOptions={stylesOptions}
            stylesUserOptions={stylesUserOptions}
            name={info && info.name}
            description={info && info.description}
            onClose={this.handleCloseModalManual}
            onSave={this.handleSaveModalManual}
          />
        )}
        {showModalManualEdit && (
          <PlaylistManualEditModal
            playlists={currentUsersPlaylist}
            stylesOptions={stylesOptions}
            stylesUserOptions={stylesUserOptions}
            onClose={this.handleCloseModalManualEdit}
            onSave={this.handleSaveModalManualEdit}
          />
        )}
        {showDeleteModal && (
          <DeleteConfirmationModal
            currentObj={currentUsersPlaylist}
            onClose={this.handleCloseDeleteModal}
            onAccept={this.handleDelete}
            messageSuccess={
              this.props.store.language.label580 +
              " " +
              currentUsersPlaylist.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label581 +
              " " +
              currentUsersPlaylist.name
            }
            title={
              this.props.store.language.label582 +
              " " +
              currentUsersPlaylist.name +
              "?"
            }
            subtitle={
              this.props.store.language.label583 +
              " " +
              currentUsersPlaylist.name +
              "."
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStore(PlaylistsListPage);
