import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelLeft, LevelRight } from "bloomer";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class DeleteAllDayPlansConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false,
    };
  }

  handleAccept = () => {
    const { messageSuccess, messageError, toastManager, userId, weekDay } =
      this.props;
    this.setState({ isDeleting: true }, () => {
      this.props.store.kasimuAPIClient
        .delete(
          "/actions_playlist_plans/deleted_all_plan_day",
          "?user_id=" + userId + "&day=" + weekDay
        )
        .then(
          (res) => {
            toastManager.add(messageSuccess, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onAccept && this.props.onAccept();
          },
          (error) => {
            toastManager.add(messageError, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { title, subtitle } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "240px" : "auto"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              lineBreak
            >
              {title}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              multiline
              lineBreak
              color="special"
              className="pt-2 pb-3"
            >
              {subtitle}
            </Text>
            <div>
              {this.state.isDeleting ? (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  disabled
                  icon="spinner"
                  pulse
                >
                  <strong>{this.props.store.language.label130}</strong>
                </Button>
              ) : (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  onClick={this.handleAccept}
                >
                  <strong>{this.props.store.language.label129}</strong>
                </Button>
              )}
              {this.state.isDeleting ? null : (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  onClick={this.handleClose}
                >
                  <strong> {this.props.store.language.label427}</strong>
                </Button>
              )}
            </div>
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

DeleteAllDayPlansConfirmationModal.propTypes = {
  weekDay: PropTypes.number,
  userId: PropTypes.number,
  messageSuccess: PropTypes.string,
  messageError: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
};

DeleteAllDayPlansConfirmationModal.defaultProps = {
  weekDay: 0,
  userId: null,
  messageSuccess: null,
  messageError: null,
  title: null,
  subtitle: null,
  onAccept: null,
  onClose: null,
};

export default withStore(withToastManager(DeleteAllDayPlansConfirmationModal));
