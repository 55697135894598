import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Field,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Panel,
  TextInput,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import { SingersRow } from "./"
import { EmptyContent, Pagination } from "../Common/";
import { withToastManager } from "react-toast-notifications";

@observer
class BlockedSingersNewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
        singers: this.getSingersAll(25,null),
        search: "",
        per_page: 25,
        page: 1,
    };
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getSingersAll = (per_page,search) => {
    return this.props.store.singers.fetchAllParams(per_page,search);
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onPageChange = (page) => {
    const { per_page, search } = this.state;
    this.setState({
        singers: this.props.store.singers.fetchPage(
        per_page,
        page,
        search
      ),
      page: page,
    });
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  handleReload = () => {
    const { per_page, search, page } = this.state;
    this.setState({
        singers: this.props.store.singers.fetchPage(
        per_page,
        page,
        search
      ),
      page: page,
    });
  };

  handleReloadAll = () => {
    const { onReload } = this.props;
    this.handleReload();
    onReload && onReload();
  };

  onSearch = () => {
    const { search, per_page } = this.state;
    if (search && search != " ") {
        this.setState({
            singers: this.getSingersAll(
            per_page,
            search
          ),
          page: 1,
        });
    }
  };

  getPanel = () => {
    return (
      <Columns
        className={this.props.store.viewMobile && "my-2"}
        isMultiline
        style={{
          margin: !this.props.viewMobile && "6px 0px",
        }}
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile && "mr-2"}
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: !this.props.store.viewMobile && "6px",
            }}
          >
            <TextInput
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              placeholder={this.props.store.language.label775}
            />
          </div>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            onClick={this.onSearch}
          >
            <strong>{this.props.store.language.label540}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.handleReload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderPaginator = (obj) => {
    if (obj.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={obj.totalPages}
            currentPage={obj.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeaderOk = () => {
    const { singers } = this.state;
    const arraySingers = singers.toArray();
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
            {arraySingers.length === 0 ? (
            <EmptyContent />
            ) : (
            arraySingers.map((singer) => (
                <SingersRow
                singer={singer}
                onReload={this.handleReloadAll}
                />
            ))
            )}    
        </div>
        {singers.count > 0 && (
          <Text className="is-pulled-right mt-1" size="xl" lineBreak>
            {this.props.store.language.label642 + singers.count}
          </Text>
        )}
        <br/>
        {this.renderPaginator(singers)}       
      </React.Fragment>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { singers } = this.state;
    if (!singers) return this.renderEmptyContent();
    return !singers.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  render() {
    const {  } = this.state;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "740px" : "90%"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label774}
          </Text>          
        </ModalHeader>
        <ModalContent>
            {this.getPanel()}
            {this.renderHeader()}
        </ModalContent>
        <ModalFooter>

        </ModalFooter>
      </Modal>
    );
  }
}

BlockedSingersNewModal.propTypes = {
  user_id: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
};

BlockedSingersNewModal.defaultProps = {
  user_id: null,
  onReload: null,
  onClose: null,
};

export default withToastManager(withStore(BlockedSingersNewModal));
