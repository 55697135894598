import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class UserLicensesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };


  render() {
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "380px" : "90%"}
          height={this.props.store.viewMobile ? "240px" : "auto"}
        >
          <ModalHeader>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {this.props.store.language.label769}
            </Text>
          </ModalHeader>
          <ModalContent>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
              >
                {this.props.store.language.label46 + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {this.props.store.loggedInLicenses.count_licenses}
              </Text>
            </div>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
              >
                {this.props.store.language.label48 + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {this.props.store.loggedInLicenses.licenses_available}
              </Text>
            </div>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
              >
                {this.props.store.language.label47 + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {this.props.store.loggedInLicenses.active_users}
              </Text>
            </div>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
              >
                {this.props.store.language.label770 + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {this.props.store.loggedInLicenses.suspended_users}
              </Text>
            </div>
          </ModalContent>
          <ModalFooter />
        </Modal>
      </React.Fragment>
    );
  }
}

UserLicensesModal.propTypes = {
  onClose: PropTypes.func,
};

UserLicensesModal.defaultProps = {
  onClose: null,
};

export default withStore(withToastManager(UserLicensesModal));
