import { Store } from "ks_storybook";
import { UsersStyles } from "../models";

class UsersStylesStore extends Store {
  model = UsersStyles;

  fetchAll() {
    return this.search(
      { per_page: 1000 },
      "users_styles_all",
      true,
      "/users_styles/get_users_styles_all"
    );
  }

  fetchValidatedAll() {
    return this.search(
      { per_page: 1000 },
      "users_styles_validated_all",
      true,
      "/users_styles/get_users_styles_validated_all"
    );
  }
}

export default UsersStylesStore;
