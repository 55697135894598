import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import UserPersonalizationDeletedModal from "./UserPersonalizationDeletedModal";
import SupervisorsListModal from "../../Supervisors/SupervisorsListModal"

@observer
class UserMoreOptionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeletedAllModal: false,
      showCreateSupervisorsModal: false,
    };
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDeletedAllOpen = () => {
    this.setState({ showDeletedAllModal: true });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedAllModal: false });
  };

  handleCreateSupervisorsModalOpen = () => {
    this.setState({ showCreateSupervisorsModal: true });
  };

  handleCreateSupervisorsModalClose = () => {
    this.setState({ showCreateSupervisorsModal: false });
  };

  render() {
    const { groups } = this.props;
    const { showDeletedAllModal, showCreateSupervisorsModal } = this.state;
    const supervisors = this.props.store.getSupervisors();
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "380px" : "90%"}
          height={this.props.store.viewMobile ? "240px" : "auto"}
        >
          <ModalHeader>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {this.props.store.language.label720}
            </Text>
          </ModalHeader>
          <ModalContent>
            <br />
            <ul>
              <li>
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="is-fullwidth"
                  kind="outline"
                  color="basic"
                  //onClick={this.handleDeletedAllOpen}
                  onClick={() =>
                    this.props.store.getPermission(25, 25, () => {
                      this.handleDeletedAllOpen()
                    })
                  }
                >
                  <strong>{this.props.store.language.label681}</strong>
                </Button>
                <Text
                  className="has-text-centered is-fullwidth mt-1"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  color="special"
                  lineBreak
                >
                  {this.props.store.language.label726}
                </Text>
              </li>
              {!supervisors && <li>
                <Button
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="is-fullwidth mt-2"
                    kind="outline"
                    color="basic"
                    onClick={this.handleCreateSupervisorsModalOpen}
                  >
                      {this.props.store.language.label817}
                </Button>
                <Text 
                  className="has-text-centered is-fullwidth mt-1" 
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  color="special"
                  lineBreak
                >
                  {this.props.store.language.label818}
                </Text>
              </li>}
            </ul>
          </ModalContent>
          <ModalFooter />
        </Modal>
        {showDeletedAllModal && (
          <UserPersonalizationDeletedModal
            currentUserPerson={null}
            onClose={this.handleDeleteConfirmationModalClose}
            onAccept={this.handleDeleteConfirmationModalClose}
            messageSuccess={this.props.store.language.label682}
            messageError={this.props.store.language.label683}
            title={this.props.store.language.label684}
            subtitle={this.props.store.language.label685}
            all={true}
          />
        )}
        {showCreateSupervisorsModal && (
          <SupervisorsListModal
            groups={groups}
            onClose={this.handleCreateSupervisorsModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

UserMoreOptionsModal.propTypes = {
  groups: PropTypes.Array,
  onClose: PropTypes.func,
};

UserMoreOptionsModal.defaultProps = {
  groups: [],
  onClose: null,
};

export default withStore(withToastManager(UserMoreOptionsModal));
