import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { UsersStyles } from "../../models";

@observer
class UsersStylesNewModal extends Component {
  @observable usersStylesNew;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      name: null,
      messageError: null,
    };

    this.usersStylesNew = this.newUsersStyles();
  }

  newUsersStyles = () => {
    const styleStore = this.props.store.usersStyles;
    const attrs = {
      style_id: this.props.lastStyles
        ? this.props.lastStyles.style_id + 1
        : 5001,
      song_lenguages_id: 1,
      song_mood_id: 10,
      name: null,
      enabled: 1,
      free_rights: this.props.store.getFreeRights(),
      mood_min: 1,
      mood_max: 10,
      mood_pre: 10,
      origin_type: 1,
      deleted: 0,
    };

    return new UsersStyles(attrs, styleStore);
  };

  handleChange = (sender, value, name, validation) => {
    this.setState({ name: value });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { name } = this.state;
    if (this.validLength(name)) {
      this.usersStylesNew.name = name;
      this.setState({ isSaving: true }, () => {
        this.usersStylesNew.save().andThen((res, responseError) => {
          if (responseError) {
            toastManager.add(this.props.store.language.label531, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            toastManager.add(this.props.store.language.label532, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          }
        });
      });
    } else {
      this.setState({ messageError: this.props.store.language.label533 });
    }
  };

  @computed
  get canSave() {
    if (this.state.name) return false;
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              disabled={this.canSave}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginRight: "10px" }}>
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="black"
              >
                {this.props.store.language.label529}
              </Text>
            </div>
            <div style={{ width: "80%" }}>
              <Field
                label={this.props.store.language.label53}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                marginH={this.props.store.viewMobile && "4px"}
                NoteSize="xs"
                labelNote={this.props.store.language.label76}
                weight="medium"
              >
                <TextInput
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  name="name"
                  onChange={this.handleChange}
                  required
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  invert
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </div>
          </div>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

UsersStylesNewModal.propTypes = {
  lastStyles: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

UsersStylesNewModal.defaultProps = {
  lastStyles: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(UsersStylesNewModal));
