import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Text,
  Panel,
  Button,
  TextInput,
  Checkbox,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../../Components/Select";
import { EmptyContent, Pagination } from "../../../Components/Common";
import { SongsCard } from "../../../Components/Songs";
import {
  UsersSongsEditModal,
  FavClickModal,
  BanClickModal,
  AddSongsToPlaylistsModal,
  EditSelectionModal,
} from "../../../Components/ContentManager";

@observer
class PlaylistsManageSong extends Component {
  constructor(props) {
    super(props);
    this.state = {
      songs: this.getSongsAll(
        25,
        null,
        null,
        this.props.store.getFreeRights(),
        null
      ),
      usersStyles: this.getUsersStylesAll(),
      songsStyles: this.getSongStyles(),
      songsStylesAll: this.getSongStylesAll(),
      showModalEdit: false,
      showModalAdd: false,
      showModalFav: false,
      showModalBan: false,
      showModalEditSelection: false,
      currentSong: null,
      per_page: 25,
      page: 1,
      filtersStyleKasimu: false,
      filtersStyleUser: false,
      filtersMood: false,
      filtersExplicit: false,
      filtersUserCriteria: false,
      filters: {
        styles_id: null,
        user_styles_id: null,
        mood_id: null,
        explicit: null,
        user_criteria: null,
      },
      open: false,
      arraySongId: [],
      selectAll: false,
    };
    this.search = null;
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label537;
  }

  handleStartPlayingClick(indice, song_path) {
    var that = this;
    that.props.store.ui.startPlaying(song_path, 1, indice);
  }

  handleTogglePlay(song_path) {
    var that = this;
    if (song_path) {
      if (that.props.store.ui.currentSongPath) {
        if (!this.props.store.ui.isPlaying) {
          this.props.store.ui.play();
        } else {
          this.props.store.ui.pause();
        }
      }
    }
  }

  reload = () => {
    const { per_page, page, filters } = this.state;
    this.setState({
      songs: this.props.store.songs.fetchPage(
        per_page,
        page,
        JSON.stringify(filters),
        null,
        this.props.store.getFreeRights(),
        this.search,
        ""
      ),
      page: 1,
      showModalEditSelection: false,
      arraySongId: [],
    });
  };

  getSongsAll = (per_page, filters, orders, free_rights, search) => {
    this.props.store.ui.destroy();
    return this.props.store.songs.fetchAllParams(
      per_page,
      JSON.stringify(filters),
      JSON.stringify(orders),
      free_rights,
      search,
      ""
    );
  };

  getSongStyles = () => {
    return this.props.store.songStyles.fetchParams(
      this.props.store.getFreeRights(),
      this.props.store.lang
    );
  };

  getSongStylesAll = () => {
    return this.props.store.songStyles.fetchAllParams(
      this.props.store.getFreeRights(),
      this.props.store.lang
    );
  };

  getUsersStylesAll = () => {
    return this.props.store.usersStyles.fetchAll();
  };

  getOptionsStyles = (obj) => {
    return obj.toArray().map((o) => ({
      value: o.id,
      label: o.translation ? o.translation[0].description : o.description,
    }));
  };

  getOptionsUser = (obj) => {
    return obj.toArray().map((o) => ({ value: o.style_id, label: o.name }));
  };

  setArraySongId = (array) => {
    this.setState({ arraySongId: array });
  };

  getOptionsShow = () => {
    const options = [
      { value: 1, label: this.props.store.language.label543 },
      { value: 2, label: this.props.store.language.label544 },
      { value: 3, label: this.props.store.language.label545 },
    ];
    return options;
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  onSearch = () => {
    const { filters, per_page } = this.state;
    if (this.search && this.search != " ") {
      if (
        !filters.styles_id &&
        !filters.user_styles_id &&
        !filters.mood_id &&
        !filters.explicit &&
        !filters.user_criteria
      ) {
        this.setState({
          songs: this.getSongsAll(
            per_page,
            null,
            null,
            this.props.store.getFreeRights(),
            this.search
          ),
          page: 1,
          arraySongId: [],
        });
      } else {
        this.setState({
          songs: this.getSongsAll(
            per_page,
            filters,
            null,
            this.props.store.getFreeRights(),
            this.search
          ),
          page: 1,
          arraySongId: [],
        });
      }
    }
  };

  onPageChange = (page) => {
    const { per_page, filters } = this.state;
    this.setState({
      songs: this.props.store.songs.fetchPage(
        per_page,
        page,
        JSON.stringify(filters),
        null,
        this.props.store.getFreeRights(),
        this.search,
        ""
      ),
      page: page,
      arraySongId: [],
    });
  };

  refreshMemorySongEdit = () => {
    const { page, per_page, filters } = this.state;
    this.setState({
      songs: this.props.store.songs.fetchPage(
        per_page,
        page,
        JSON.stringify(filters),
        null,
        this.props.store.getFreeRights(),
        this.search,
        ""
      ),
      currentSong: null,
    });
  };

  handleShowModalFav = (song) => {
    this.setState({ showModalFav: true, currentSong: song });
  };

  handleCloseModalFav = () => {
    this.setState({ showModalFav: false });
  };

  handleAcceptModalFav = () => {
    this.handleCloseModalFav();
    this.refreshMemorySongEdit();
  };

  handleShowModalBan = (song) => {
    this.setState({ showModalBan: true, currentSong: song });
  };

  handleCloseModalBan = () => {
    this.setState({ showModalBan: false });
  };

  handleAcceptModalBan = () => {
    this.handleCloseModalBan();
    this.refreshMemorySongEdit();
  };

  handleShowModalEdit = (song) => {
    this.setState({ showModalEdit: true, currentSong: song });
  };

  handleCloseEditModal = () => {
    this.setState({ showModalEdit: false });
  };

  handleSaveEditModal = () => {
    this.handleCloseEditModal();
    this.refreshMemorySongEdit();
  };

  handleShowModalAdd = (song) => {
    this.setState({ showModalAdd: true, currentSong: song });
  };

  handleHideModalAdd = () => {
    this.setState({ showModalAdd: false, currentSong: null });
  };

  handleShowEditSelectionModal = () => {
    this.setState({ showModalEditSelection: true });
  };

  handleApplyEditSelectionModal = () => {
    this.reload();
    this.setState({ showModalEditSelection: false });
  };

  handleHideEditSelectionModal = () => {
    this.setState({ showModalEditSelection: false });
  };

  handleFiltersChange = (sender, value, name) => {
    const state = this.state;
    const newFilters = this.state.filters;
    if (name == "filtersStyleKasimu") {
      newFilters.styles_id = null;
    }
    if (name == "filtersStyleUser") {
      newFilters.user_styles_id = null;
    }
    if (name == "filtersMood") {
      newFilters.mood_id = null;
    }
    if (name == "filtersExplicit") {
      newFilters.explicit = null;
    }
    if (name == "filtersUserCriteria") {
      newFilters.user_criteria = null;
    }
    state[name] = value;
    this.setState({ state: state, filters: newFilters });
  };

  handleSelectAllChange = (sender, value, name) => {
    const { songs } = this.state;
    let arraySongId = [];
    if (value) {
      const songsMemmory = songs.toArray();
      {
        songsMemmory.map((song) => arraySongId.push(song.id));
      }
    }
    this.setState({ arraySongId: arraySongId });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    const newFilters = this.state.filters;
    switch (sender.name) {
      case "selectStyleKasimu":
        newFilters.styles_id = val;
        break;
      case "selectStyleUser":
        newFilters.user_styles_id = val;
        break;
      case "selectMood":
        newFilters.mood_id = val;
        break;
      case "selectExplicit":
        newFilters.explicit = val;
        break;
      case "selectUserCriteria":
        newFilters.user_criteria = val;
        break;
      default:
        break;
    }
    this.setState({ filters: newFilters });
  };

  handleFilters = () => {
    const {
      per_page,
      filters,
      filtersStyleKasimu,
      filtersStyleUser,
      filtersMood,
      filtersExplicit,
      filtersUserCriteria,
    } = this.state;

    let newFilters = filters;

    if (!filtersStyleKasimu) {
      newFilters.styles_id = null;
    }
    if (!filtersStyleUser) {
      newFilters.user_styles_id = null;
    }
    if (!filtersMood) {
      newFilters.mood_id = null;
    }
    if (!filtersExplicit) {
      newFilters.explicit = null;
    }
    if (!filtersUserCriteria) {
      newFilters.user_criteria = null;
    }
    this.setState({
      songs: this.getSongsAll(
        per_page,
        newFilters,
        null,
        this.props.store.getFreeRights(),
        this.search
      ),
      arraySongId: [],
    });
  };

  getOptionsMood = () => {
    const options = [
      { value: 1, label: this.props.store.language.mood[1] },
      { value: 2, label: this.props.store.language.mood[2] },
      { value: 3, label: this.props.store.language.mood[3] },
      { value: 4, label: this.props.store.language.mood[4] },
      { value: 5, label: this.props.store.language.mood[5] },
    ];
    return options;
  };

  getPanel = (stylesOptions, stylesUserOptions, result) => {
    const {
      filtersStyleKasimu,
      filtersStyleUser,
      filtersMood,
      filtersExplicit,
      filtersUserCriteria,
      arraySongId,
    } = this.state;
    const explicitOptions = [
      { value: 2, label: this.props.store.language.label645 },
      { value: 1, label: this.props.store.language.label646 },
    ];
    const optionsMood = this.getOptionsMood();
    const optionsUserCriteria = this.getOptionsShow();

    return (
      <Columns
        isMultiline
        className={this.props.store.viewMobile && "my-2"}
        style={{
          gap: !this.props.store.viewMobile && "0px",
        }}
      >
        <Column isSize={12} className="flex">
          {/* <div className="is-row mb-1" style={{ alignItems: "center" }}>
            <Text size={this.props.store.viewMobile ? "xl" : "lg"} upper>
              {this.props.store.language.label641}{" "}
            </Text>
            <Text
              className="ml-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              maxLength={this.props.mobile ? 50 : 250}
            >
              {this.props.store.language.label642 + result}
            </Text>
          </div> */}
          <div
            className={this.props.store.viewMobile ? "mr-2" : "mr-1"}
            style={{ maxWidth: "700px", width: "100%" }}
          >
            <TextInput
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              placeholder={this.props.store.language.label539}
              value={this.search && this.search}
            />
          </div>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            onClick={this.onSearch}
          >
            <strong>{this.props.store.language.label540}</strong>
          </Button>
        </Column>

        <Column isSize={12}>
          <Panel
            color="gray"
            padding={this.props.store.viewMobile ? "25px 34px" : "8px 20px"}
            className="my-1"
            style={{
              borderRadius: !this.props.store.viewMobile && "30px",
              backgroundColor: !this.props.store.viewMobile && "#373636",
            }}
          >
            <Columns
              isVCentered
              style={{ gap: !this.props.store.viewMobile && 0 }}
            >
              {this.props.store.viewMobile || this.state.open ? (
                <>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 7 }}>
                    <div
                      className="flex"
                      style={{
                        justifyContent:
                          !this.props.store.viewMobile && "space-between",
                      }}
                      onClick={() => this.setState({ open: !this.state.open })}
                    >
                      <div></div>

                      <Text
                        size={this.props.store.viewMobile ? "md" : "lg"}
                        weight="strong"
                        lead={this.props.store.viewMobile}
                      >
                        {this.props.store.language.label405}
                      </Text>
                      {!this.props.store.viewMobile && (
                        <Button
                          kind="link"
                          color="basic"
                          iconKasimu={
                            this.state.open ? "arrow-up" : "arrow-down"
                          }
                          onClick={() =>
                            this.setState({ open: !this.state.open })
                          }
                          space
                        />
                      )}
                    </div>

                    <div className="my-2">
                      <Columns
                        fill="200"
                        style={{
                          gap: this.props.store.viewMobile ? "1rem" : "0.5rem",
                        }}
                      >
                        <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                          <Checkbox
                            invert
                            key={"styleKasimu"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            onChange={this.handleFiltersChange}
                            isChecked={filtersStyleKasimu}
                            value="filtersStyleKasimu"
                            className="items-center"
                          >
                            <Text
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="medium"
                            >
                              {this.props.store.language.label602 +
                                ", " +
                                this.props.store.getServiceName()}
                            </Text>
                          </Checkbox>
                          {filtersStyleKasimu && (
                            <Select
                              name="selectStyleKasimu"
                              onChange={this.handleSelectChange}
                              className="is-fullwidth my-1"
                              placeholder={this.props.store.language.label647}
                              options={stylesOptions}
                              defaultValue={
                                this.state.filters.styles_id
                                  ? stylesOptions[
                                      stylesOptions
                                        .map(function (e) {
                                          return e.value;
                                        })
                                        .indexOf(this.state.filters.styles_id)
                                    ]
                                  : stylesOptions[-1]
                              }
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              isMobile={!this.props.store.viewMobile}
                              backgroundColor="#2E2E2E"
                            />
                          )}
                        </Column>
                        <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                          <Checkbox
                            invert
                            key={"styleUser"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            onChange={this.handleFiltersChange}
                            isChecked={filtersStyleUser}
                            value="filtersStyleUser"
                            className="items-center"
                          >
                            <Text
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="medium"
                            >
                              {this.props.store.language.label653}
                            </Text>
                          </Checkbox>
                          {filtersStyleUser && (
                            <Select
                              name="selectStyleUser"
                              onChange={this.handleSelectChange}
                              className="is-fullwidth my-1"
                              placeholder={this.props.store.language.label648}
                              options={stylesUserOptions}
                              defaultValue={
                                this.state.filters.user_styles_id
                                  ? stylesUserOptions[
                                      stylesUserOptions
                                        .map(function (e) {
                                          return e.value;
                                        })
                                        .indexOf(
                                          this.state.filters.user_styles_id
                                        )
                                    ]
                                  : stylesUserOptions[-1]
                              }
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              isMobile={!this.props.store.viewMobile}
                              backgroundColor="#2E2E2E"
                            />
                          )}
                        </Column>
                        <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                          <Checkbox
                            invert
                            key={"mood"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            onChange={this.handleFiltersChange}
                            isChecked={filtersMood}
                            value="filtersMood"
                            className="items-center"
                          >
                            <Text
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="medium"
                            >
                              {this.props.store.language.label551}
                            </Text>
                          </Checkbox>
                          {filtersMood && (
                            <Select
                              name="selectMood"
                              onChange={this.handleSelectChange}
                              className="is-fullwidth my-1"
                              placeholder={this.props.store.language.label649}
                              options={optionsMood}
                              defaultValue={
                                this.state.filters.mood_id
                                  ? optionsMood[
                                      optionsMood
                                        .map(function (e) {
                                          return e.value;
                                        })
                                        .indexOf(this.state.filters.mood_id)
                                    ]
                                  : optionsMood[-1]
                              }
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              isMobile={!this.props.store.viewMobile}
                              backgroundColor="#2E2E2E"
                            />
                          )}
                        </Column>
                        <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                          <Checkbox
                            invert
                            key={"explicit"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            onChange={this.handleFiltersChange}
                            isChecked={filtersExplicit}
                            value="filtersExplicit"
                            className="items-center"
                          >
                            <Text
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="medium"
                            >
                              {this.props.store.language.label650}
                            </Text>
                          </Checkbox>
                          {filtersExplicit && (
                            <Select
                              name="selectExplicit"
                              onChange={this.handleSelectChange}
                              className="is-fullwidth my-1"
                              placeholder={this.props.store.language.label651}
                              options={explicitOptions}
                              defaultValue={
                                this.state.filters.explicit != null
                                  ? explicitOptions[
                                      explicitOptions
                                        .map(function (e) {
                                          return e.value;
                                        })
                                        .indexOf(this.state.filters.explicit)
                                    ]
                                  : explicitOptions[-1]
                              }
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              isMobile={!this.props.store.viewMobile}
                              backgroundColor="#2E2E2E"
                            />
                          )}
                        </Column>
                        <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                          <Checkbox
                            invert
                            key={"user_criteria"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            onChange={this.handleFiltersChange}
                            isChecked={filtersUserCriteria}
                            value="filtersUserCriteria"
                            className="items-center"
                          >
                            <Text
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="medium"
                            >
                              {this.props.store.language.label652}
                            </Text>
                          </Checkbox>
                          {filtersUserCriteria && (
                            <Select
                              name="selectUserCriteria"
                              onChange={this.handleSelectChange}
                              className="is-fullwidth my-1"
                              placeholder={this.props.store.language.label385}
                              options={optionsUserCriteria}
                              defaultValue={
                                this.state.filters.user_criteria != null
                                  ? optionsUserCriteria[
                                      optionsUserCriteria
                                        .map(function (e) {
                                          return e.value;
                                        })
                                        .indexOf(
                                          this.state.filters.user_criteria
                                        )
                                    ]
                                  : optionsUserCriteria[-1]
                              }
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              isMobile={!this.props.store.viewMobile}
                              backgroundColor="#2E2E2E"
                            />
                          )}
                        </Column>
                      </Columns>
                    </div>
                  </Column>
                  <Column
                    isSize={{ mobile: 12, tablet: 12, desktop: 5 }}
                    className="is-pulled-right"
                  >
                    <Button
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-3"
                      onClick={this.handleFilters}
                      disabled={
                        !(
                          filtersStyleKasimu ||
                          filtersStyleUser ||
                          filtersMood ||
                          filtersExplicit ||
                          filtersUserCriteria
                        )
                      }
                      kind="outline"
                      color="basic"
                    >
                      <strong>{this.props.store.language.label643}</strong>
                    </Button>
                    <Button
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-3"
                      onClick={this.handleShowEditSelectionModal}
                      disabled={arraySongId.length === 0}
                      kind="outline"
                      color="basic"
                    >
                      <strong>{this.props.store.language.label815}</strong>
                    </Button>
                  </Column>
                </>
              ) : (
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 7 }}>
                  <div
                    className="flex"
                    style={{
                      justifyContent:
                        !this.props.store.viewMobile && "space-between",
                    }}
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    <div></div>

                    <Text
                      size={this.props.store.viewMobile ? "md" : "lg"}
                      weight="strong"
                      lead={this.props.store.viewMobile}
                    >
                      {this.props.store.language.label405}
                    </Text>
                    <Button
                      kind="link"
                      color="basic"
                      iconKasimu={this.state.open ? "arrow-up" : "arrow-down"}
                      onClick={() => this.setState({ open: !this.state.open })}
                      space
                    />
                  </div>
                </Column>
              )}
            </Columns>
          </Panel>
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeader = () => {
    const { songs } = this.state;
    if (!songs) return this.renderEmptyContent();
    return !songs.isOk() ? this.renderLoader() : this.renderSongs();
  };

  getUserFav = (song) => {
    if (song.users_songs_edit) {
      if (song.users_songs_edit.preference == 0) return true;
    }
    return false;
  };

  getUserBan = (song) => {
    if (song.users_songs_edit) {
      if (song.users_songs_edit.preference == 10) return true;
    }
    return false;
  };

  renderSongs = () => {
    const { songs, songsStyles, usersStyles, arraySongId, selectAll } =
      this.state;
    const songsMemmory = songs.toArray();
    const currentSongPath = this.props.store.ui.currentSongPath;
    const stylesOptions = this.getOptionsStyles(songsStyles);
    const stylesUserOptions = this.getOptionsUser(usersStyles);
    return (
      <React.Fragment>
        {this.getPanel(stylesOptions, stylesUserOptions, songs.count)}
        <div className="flex mb-1" style={{ justifyContent: "flex-end" }}>
          <Checkbox
            key={"selectAll"}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onChange={this.handleSelectAllChange}
            isChecked={false}
            value="selectAll"
            className="items-center"
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              weight="medium"
            >
              {this.props.store.language.label816}
            </Text>
          </Checkbox>
        </div>
        {songsMemmory.map((song, idx) => (
          <SongsCard
            indice={idx + 1}
            song={song}
            arraySongId={arraySongId}
            handleStartPlayingClick={this.handleStartPlayingClick}
            handleTogglePlay={this.handleTogglePlay}
            active={currentSongPath && song.song_path == currentSongPath}
            userEdit={song.users_songs_edit ? true : false}
            userFav={this.getUserFav(song)}
            userBan={this.getUserBan(song)}
            addClick={this.handleShowModalAdd}
            editClick={this.handleShowModalEdit}
            favClick={this.handleShowModalFav}
            banClick={this.handleShowModalBan}
            arrayClick={this.setArraySongId}
          />
        ))}
        <br />
        {this.renderPaginator(songs)}
      </React.Fragment>
    );
  };

  renderPaginator = (songs) => {
    if (songs.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={songs.totalPages}
            currentPage={songs.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      showModalEdit,
      currentSong,
      songsStylesAll,
      usersStyles,
      showModalFav,
      showModalBan,
      showModalAdd,
      showModalEditSelection,
      arraySongId,
    } = this.state;
    const stylesAllOptions = this.getOptionsStyles(songsStylesAll);

    return (
      <React.Fragment>
        <Columns isCentered isGapless className="is-multiline is-mobile">
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                multiline
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label537}
              </Text>
            )}
            {this.renderHeader()}
          </Column>
        </Columns>
        {showModalEdit && (
          <UsersSongsEditModal
            songs={currentSong}
            usersStyles={usersStyles}
            stylesOptions={stylesAllOptions}
            onClose={this.handleCloseEditModal}
            onSave={this.handleSaveEditModal}
          />
        )}

        {showModalFav && (
          <FavClickModal
            songs={currentSong}
            onClose={this.handleCloseModalFav}
            onAccept={this.handleAcceptModalFav}
          />
        )}

        {showModalBan && (
          <BanClickModal
            songs={currentSong}
            onClose={this.handleCloseModalBan}
            onAccept={this.handleAcceptModalBan}
          />
        )}

        {showModalAdd && (
          <AddSongsToPlaylistsModal
            songs={currentSong}
            onClose={this.handleHideModalAdd}
          />
        )}
        {showModalEditSelection && (
          <EditSelectionModal
            arraySongId={arraySongId}
            usersStyles={usersStyles}
            onApply={this.handleApplyEditSelectionModal}
            onClose={this.handleHideEditSelectionModal}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStore(PlaylistsManageSong);
