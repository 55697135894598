import React, { lazy, Component, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "react-jss";
import { Themes, Loader, Text, Panel, Field } from "ks_storybook";
import AppStore from "./stores/AppStore";
import AppContext from "./AppContext";
import Select from "./Components/Select/Select.jsx";
import es_ES from "./lang/es_ES.js";
import en_EN from "./lang/en_EN.js";
import fr_FR from "./lang/fr_FR.js";
import pt_PT from "./lang/pt_PT.js";
import et_EE from "./lang/et_EE.js";
import de_DE from "./lang/de_DE.js";
import it_IT from "./lang/it_IT.js";

import AppRouter from "./views/AppRouter/AppRouter";
import MobileAppRouter from "./views/AppRouter/MobileAppRouter";
import SessionRouter from "./views/SessionRouter/SessionRouter";
import PublicRouter from "./views/PublicRouter/PublicRouter";
import DownloadsPage from "./views/Downloads/DownloadsPage";

import MediaQuery from "react-responsive";
import injectSheet from "react-jss";

import styles from "./styles/styles.css";
import GlobalStyle from "./styles/globalStyle.js";

const MyCustomToast = ({ appearance, children }) => (
  <div
    style={{
      margin: "0px auto",
      marginBottom: 5,
      background: appearance === "error" ? "#df0101" : "#01a9db",
      padding: "4px 10px",
      borderRadius: 15,
      fontSize: 14,
      color: "white",
      textAlign: "center",
      width: "fit-content",
    }}
  >
    {children}
  </div>
);

@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.appStore = new AppStore();
    this.state = {};
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(value, sender) {
    const val = !value ? 0 : value.value;
    this.appStore.lang = val;

    switch (val) {
      case 2:
        this.appStore.language = en_EN;
        break;
      case 1:
        this.appStore.language = es_ES;
        break;
      case 3:
        this.appStore.language = fr_FR;
        break;
      case 4:
        this.appStore.language = pt_PT;
        break;
      case 5:
        this.appStore.language = et_EE;
        break;
      case 6:
        this.appStore.language = de_DE;
        break;
      case 7:
        this.appStore.language = it_IT;
        break;
    }
  }

  renderSelectLang(value) {
    const options = [
      { value: 2, label: this.appStore.language.languages[2] },
      { value: 1, label: this.appStore.language.languages[1] },
      { value: 3, label: this.appStore.language.languages[3] },
      { value: 4, label: this.appStore.language.languages[4] },
      { value: 5, label: this.appStore.language.languages[5] },
      { value: 6, label: this.appStore.language.languages[6] },
      { value: 7, label: this.appStore.language.languages[7] },
    ];
    return (
      <Field label={this.appStore.language.label1}>
        <Select
          onChange={this.handleSelectChange}
          name="lang"
          required
          autocomplete="nope"
          options={options}
          defaultValue={options[value]}
        />
      </Field>
    );
  }

  renderValue = (value) => {
    return value >= 0 ? this.renderFrame() : this.renderFrame();
  };

  renderSeeableLang = () => {
    return (
      <div
        className="columns level-right"
        style={{ position: "absolute", width: "100%", margin: "0px" }}
      >
        <div className="column is-12-mobile is-12-tablet is-2-desktop is-2">
          {this.renderSelectLang(1)}
        </div>
      </div>
    );
  };

  handleName = (value) => {
    document.title = `${this.appStore.currentCompany.name}`;
  };

  handleFavicon = (value) => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = this.appStore.currentCompany.favicon;
  };

  renderFrame() {
    const myArray = window.location.pathname.split("/");
    if (myArray[3]) {
      if (myArray[2] == "signIn") {
        if (myArray[3] >= this.appStore.companies.length)
          this.appStore.setTypeView(1);
        else this.appStore.setTypeView(myArray[3]);
        this.appStore.autoUsername = myArray[4];
        this.appStore.autoPws = myArray[5];
      }
    } else {
      this.appStore.loadTypeView();
    }
    this.handleFavicon(this.appStore.typeView);
    this.handleName(this.appStore.typeView);

    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={1025}>
          {(desk) => {
            return (
              <BrowserRouter>
                <Switch>
                  <Route path={"/session"} component={SessionRouter} />
                  {/* <Route  path = {'/reset_password' } component={ PasswordRecoveryRouter } /> */}
                  {/*<Route path={'/public'} component={PublicRouter} />*/}
                  {/*<Route path={"/en_EN"} component={DownloadsPage}/>*/}
                  {/*<Route path={"/es_ES"} component={DownloadsPage}/>*/}
                  {/*<Route path={"/fr_FR"} component={DownloadsPage}/>*/}
                  {/*<Route path={"/pt_PT"} component={DownloadsPage}/>*/}
                  {/*<Route path={"/et_EE"} component={DownloadsPage}/>*/}
                  {/*<Route path={"/de_DE"} component={DownloadsPage}/>*/}
                  {/*<Route path={"/it_IT"} component={DownloadsPage}/>*/}
                  {(this.appStore.viewMobile = desk)}
                  <Route
                    path={"/app"}
                    component={desk ? AppRouter : MobileAppRouter}
                  />
                  {/* Replace this by a 404... maybe.... */}
                  <Redirect
                    to={"/app"}
                    component={desk ? AppRouter : MobileAppRouter}
                  />
                </Switch>
              </BrowserRouter>
            );
          }}
        </MediaQuery>
        {this.appStore.serviceErrors.length > 0
          ? this.renderServiceErrors()
          : null}
      </React.Fragment>
    );
  }

  renderServiceErrors() {
    return (
      <Panel
        color="error"
        style={{ position: "fixed", bottom: "8px", left: "8px", right: "8px" }}
      >
        {this.appStore.serviceErrors.map((error) => (
          <Text>
            {error.message}
            <Text
              size="xs"
              color="white"
            >{`${error.service} - ${error.detail}`}</Text>
          </Text>
        ))}
      </Panel>
    );
  }

  render() {
    const loader = (
      <Loader
        className="fullscreen"
        icon="sync-alt"
        label={this.appStore.language.label5}
        animation="spin"
      />
    );
    return (
      <AppContext.Provider value={this.appStore}>
        <GlobalStyle />
        <ThemeProvider theme={Themes[this.appStore.getCurrentTheme()]}>
          <ToastProvider
            placement="bottom-center"
            autoDismissTimeout={6000}
            components={{
              Toast: MyCustomToast,
            }}
          >
            <React.Fragment>
              <Suspense fallback={loader}>
                {/*this.appStore.langSeeable ? this.renderSeeableLang() : null*/}
                {this.appStore.isLoading || this.appStore.isLoggingIn
                  ? loader
                  : this.renderValue(this.appStore.lang)}
              </Suspense>
            </React.Fragment>
          </ToastProvider>
        </ThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default injectSheet(styles)(App);
