import { Store } from "ks_storybook";
import { PPVersionsAds } from "../models";

class PPVersionsAdsStore extends Store {
  model = PPVersionsAds;

  fetchAll() {
    return this.search({}, "pp_versions_ads_all", true, "/pp_versions_ads");
  }
}

export default PPVersionsAdsStore;
