import _logo from "../assets/images/mozartplay.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const MOZARTPLAY = {
    logo: _logo,
    name: 'MozartPlay',
    favicon: AWS_BUCKET+'/favicon/mozartplay.ico',
    web: 'www.funboxmusic.com',
    supportContact: 'info@funboxmusic.com',
    url_downloads: 'https://funboxmusic.com/descargas',
    url_web_player_p: 'https://mozartplay.funboxmusic.com',
    url_web_player_t: 'https://webplayertest.megaplayer.me/session/signIn/9',
    url_install_win: AWS_BUCKET+'/installers/install_mozartplay_',
    url_install_macos: null,
    url_google_play_store: null, //'https://play.google.com/store/apps/details?id=com.funboxmusic.funboxmusic',
    url_aptoide: null,
    url_apk_x32: null, //AWS_BUCKET+'/installers/app_funboxmusic_x32.apk',
    url_apk_x64: null, //AWS_BUCKET+'/installers/app_funboxmusic_x64.apk',
    url_support_i: 'https://funboxmusic.com/descargas/#soporte',
    url_support_m: 'https://funboxmusic.com/descargas/#soporte',
    url_whatsapp: null,
    url_certificate: AWS_BUCKET+'/ks_certificate/funbox_certificado_freedom.pdf'
}

export default MOZARTPLAY;