import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Text,
  CardEvents,
} from "ks_storybook";
import { EmptyContent } from "../Common";
import { withToastManager } from "react-toast-notifications";

@observer
class PlaylistsAutomaticEyeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blocks: this.getBlocksAll(this.props.currentUsersPlaylist.playlist_id),
    };
    this.duration = 0;
    this.numSongBase = 15;
  }

  getBlocksAll = (playlist_id) => {
    return this.props.store.usersBlocks.fetchBlocksPlaylists(
      playlist_id,
      this.props.store.lang
    );
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getDuration = (blocks) => {
    let duration = 0;
    if (blocks.count == 1) {
      const arr = blocks.toArray();
      var res = arr[0].duration / this.numSongBase;
      duration = Math.round(res);
    } else {
      var res = blocks.count / this.numSongBase;
      duration = Math.round(res);
    }
    return duration;
  };

  getContainerStyle = () => ({
    width: "100%",
    minHeight: "250px",
    overflowY: "auto",
    overflowX: "hidden",
    gap: this.props.store.viewMobile ? 8 : 3,
    display: "flex",
    flexDirection: "column",
  });

  renderHeaderOk = (blocks) => {
    const { currentUsersPlaylist } = this.props;
    const duration = this.getDuration(blocks);
    const arr = blocks.toArray();
    return (
      <React.Fragment>
        <div
          className="is-row"
          style={{ flexWrap: "wrap", alignItems: "center" }}
        >
          <Text
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            upper
            className="mr-1"
            weight="medium"
          >
            {this.props.store.language.label604 + ": "}
          </Text>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            multiline
          >
            {duration}
          </Text>
        </div>
        <div
          className="is-row"
          style={{ flexWrap: "wrap", alignItems: "center" }}
        >
          <Text
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            upper
            className="mr-1"
            weight="medium"
          >
            {this.props.store.language.label324 + ": "}
          </Text>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            multiline
          >
            {currentUsersPlaylist.random
              ? this.props.store.language.label85
              : this.props.store.language.label86}
          </Text>
        </div>
        <div
          className="is-row"
          style={{ flexWrap: "wrap", alignItems: "center" }}
        >
          <Text
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            upper
            className="mr-1"
            weight="medium"
          >
            {this.props.store.language.label605 + ": "}
          </Text>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            multiline
          >
            {currentUsersPlaylist.explicit
              ? this.props.store.language.label85
              : this.props.store.language.label86}
          </Text>
        </div>
        <br />
        <Text
          size={this.props.store.viewMobile ? "xxl" : "xl"}
          upper
          className="mr-1 mb-2"
          weight="medium"
        >
          {this.props.store.language.label602 +
            ", " +
            this.props.store.getServiceName() +
            ": "}
        </Text>

        <div style={this.getContainerStyle()}>
          {arr.map((a) => (
            <CardEvents
              upper={false}
              name={
                a.song_styles_id >= 5001
                  ? a.style_name.name
                  : a.style_name.description
              }
            />
          ))}
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { blocks } = this.state;
    if (!blocks) return this.renderEmptyContent();

    return !blocks.isOk() ? this.renderLoader() : this.renderHeaderOk(blocks);
  };

  render() {
    const { currentUsersPlaylist } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "340px" : "auto"}
        >
          <ModalHeader>
            <div className="mb-1">
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="black"
              >
                {currentUsersPlaylist.name}
              </Text>
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
              >
                {currentUsersPlaylist.description}
              </Text>
            </div>
          </ModalHeader>
          <ModalContent>{this.renderHeader()}</ModalContent>
          <ModalFooter />
        </Modal>
      </React.Fragment>
    );
  }
}
PlaylistsAutomaticEyeModal.propTypes = {
  currentUsersPlaylist: PropTypes.object,
  onClose: PropTypes.func,
};

PlaylistsAutomaticEyeModal.defaultProps = {
  currentUsersPlaylist: null,
  onClose: null,
};

export default withToastManager(withStore(PlaylistsAutomaticEyeModal));
