import React, { Component } from "react";
import PropTypes from "prop-types";
import { Columns, Column } from "bloomer";
import { RectSkeleton, Text, Button } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class PPVersionsAdsRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDelete = () => {
    const { ppVersionsAds, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(ppVersionsAds);
  };

  render() {
    const {
      ppVersionsAds,
      currentAdPath,
      isPlaying,
      showPlayer,
      handleTogglePlay,
      handleStartPlayingClick,
    } = this.props;
    if (!ppVersionsAds) return null;
    if (ppVersionsAds.isEmpty()) {
      return this.renderSkelenton();
    }
    return (
      <React.Fragment>
        <div
          style={{
            padding: "8px 24px",
            borderRadius: "16px",
            background: "#e8e8e8",
            marginBottom: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            overflowWrap: "anywhere",
          }}
        >
          <div className="is-row ml-1" style={{ alignItems: "center" }}>
            <Button
              className="mr-3"
              icon={
                currentAdPath &&
                ppVersionsAds.name == currentAdPath.name &&
                isPlaying
                  ? "pause"
                  : "play"
              }
              kind="link"
              color={
                currentAdPath && ppVersionsAds.name == currentAdPath.name
                  ? "primary"
                  : "special"
              }
              size="xl"
              id="play-button"
              onClick={
                currentAdPath &&
                ppVersionsAds.name == currentAdPath.name &&
                showPlayer
                  ? () => {
                      handleTogglePlay();
                    }
                  : () => {
                      let ad_ver = {
                        id: 1,
                        name: ppVersionsAds.name,
                        ad_path: ppVersionsAds.path,
                      };
                      handleStartPlayingClick(ad_ver, 3);
                    }
              }
              lead
              space
            />
            <Text size="xl" lineBreak>
              {ppVersionsAds.name}
            </Text>
          </div>
          <Button
            size="xl"
            kind="link"
            color="black"
            iconKasimu="trash"
            onClick={this.handleDelete}
            space
            className="ml-1"
          />
        </div>
      </React.Fragment>
    );
  }
}

PPVersionsAdsRow.propTypes = {
  ppVersionsAds: PropTypes.object,
  onDeleteClick: PropTypes.func,
};

PPVersionsAdsRow.defaultProps = {
  ppVersionsAds: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(PPVersionsAdsRow));
