import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  Avatar,
  Text,
  Button,
} from "ks_storybook";
import withStore from "../../../hocs/withStore";
import { withRouter } from "react-router";
import {
  AccountManagement,
  SignOutModal,
  ConfigModal,
} from "../../../Components/Account";
import AccountLogoModal from "../../../Components/Account/AccountLogoModal";

@observer
class LoggedUserDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewModal: false,
      showModalSignOut: false,
      showEditLogo: false,
      showLogoModal: false,
      showModalConfig: false,
    };
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleShowModalSignOut = () => {
    this.setState({ showModalSignOut: true });
  };

  handleCloseModalSignOut = () => {
    this.setState({ showModalSignOut: false });
  };

  handleShowModalConfig = () => {
    this.setState({ showModalConfig: true });
  };

  handleCloseModalConfig = () => {
    this.setState({ showModalConfig: false });
  };

  handleSignOut() {
    this.handleShowModalSignOut();
    this.props.store.signOut();
  }

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleAccountNewModalClose = () => {
    this.setState({ showNewModal: false });
  };

  handleShowLogoModal = () => {
    this.setState({ showLogoModal: true });
  };

  handleCloseLogoModal = () => {
    this.setState({ showLogoModal: false });
  };

  renderHeader = () => {
    const { showNewModal, showModalSignOut, showLogoModal, showModalConfig } =
      this.state;
    const user = this.props.store.loggedInUser;
    const supervisors = this.props.store.getSupervisors();
    return (
      <div className="flex items-center">
        <Dropdown direction="bottom-right">
          <DropdownToggle>
            {this.props.mobile ? (
              <Avatar
                image={null}
                size="xs"
                placeholderIcon={
                  this.props.store.viewMobile
                    ? "user-circle"
                    : "user-circle-white"
                }
              />
            ) : (
              <Button
                iconKasimu="user-circle-white"
                size="lg"
                iconSize="28px"
                padding="4px"
                img={null}
                kind="outline"
                color="basic"
              >
                {!this.props.mobile ? supervisors?supervisors.username:user.username : null}
              </Button>
            )}
          </DropdownToggle>
          <DropdownPanel className="has-text-centered">
            <div
              className="flex items-center mb-2"
              style={{ position: "relative", justifyContent: "center" }}
            >
              <div
                className="flex"
                onMouseEnter={() => this.setState({ showEditLogo: true })}
                onMouseLeave={() => this.setState({ showEditLogo: false })}
              >
                <Avatar
                  image={null}
                  size="lg"
                  placeholderIcon={
                    this.props.store.viewMobile
                      ? "user-circle"
                      : "user-circle-white"
                  }
                />
                {this.state.showEditLogo && (
                  <div
                    style={{
                      position: "absolute",
                      background: "rgba(0, 0, 0, 0.6)",
                    }}
                    className="ks--avatar lg"
                  >
                    <div
                      className="flex items-center"
                      style={{
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Button
                        size="xl"
                        kind="link"
                        color="basic"
                        iconKasimu="pen"
                        style={{ position: "inherit" }}
                        padding="0px"
                        onClick={this.handleShowLogoModal}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.props.mobile ? (
              <Text size="sm" className="mb-2" lead>
                {supervisors?supervisors.username:user.username}
              </Text>
            ) : null}
            <Text className="mb-1" size="sm" weight="strong">
              {supervisors?this.props.store.language.root[6]:this.props.store.language.root[user.getRolId()]}
            </Text>
            <Text className="mb-1" size="sm" weight="strong">
              {this.props.store.getServiceName()}
            </Text>
            <Text weight="bold" size="md">
              {this.props.store.version}
            </Text>
            <div>
              <Button
                className="is-fullwidth mt-1"
                onClick={this.handleShowModalConfig}
                size={this.props.store.viewMobile ? "lg" : "md"}
                color="basic"
              >
                <strong>{this.props.store.language.label785}</strong>
              </Button>
            </div>
            {!supervisors && user.getRolId() == 5 || user.getRolId() == 8 ? (
              <Button
                className="is-fullwidth mt-1 mb-2"
                onClick={this.handleShowNewModal}
              >
                {this.props.store.language.label687}
              </Button>
            ) : (
              <div className="my-1" />
            )}

            {this.props.mobile && (
              <Button
                className="mx-0"
                size="lg"
                iconSize="22px"
                iconKasimu="turn-off"
                color="basic"
                onClick={this.handleShowModalSignOut}
                kind="outline"
              />
            )}
          </DropdownPanel>
        </Dropdown>
        {!this.props.mobile && (
          <Button
            className="ml-1"
            size="lg"
            iconSize="22px"
            iconKasimu="turn-off"
            color="basic"
            onClick={this.handleShowModalSignOut}
            kind="outline"
          />
        )}
        {showNewModal && (
          <AccountManagement onClose={this.handleAccountNewModalClose} />
        )}
        {showModalSignOut && (
          <SignOutModal
            onClose={this.handleCloseModalSignOut}
            onAccept={this.handleSignOut}
          />
        )}
        {showLogoModal && (
          <AccountLogoModal onClose={this.handleCloseLogoModal} />
        )}
        {showModalConfig && (
          <ConfigModal onClose={this.handleCloseModalConfig} />
        )}
      </div>
    );
  };

  render() {
    const { showNewModal, showModalSignOut, showLogoModal, showModalConfig } =
      this.state;
    const info = this.props.store.loggedInUserPersonalization;
    const user = this.props.store.loggedInUser;
    const supervisors = this.props.store.getSupervisors();

    if (!info) return this.renderHeader();

    return (
      <div className="flex items-center">
        <Dropdown direction="bottom-right">
          <DropdownToggle>
            {this.props.mobile ? (
              <Avatar
                image={this.props.store.replaceBucketUrl(info.avatar_path)}
                size="xs"
                placeholderIcon={
                  this.props.store.viewMobile
                    ? "user-circle"
                    : "user-circle-white"
                }
              />
            ) : (
              <Button
                iconKasimu="user-circle-white"
                size="lg"
                iconSize="28px"
                padding="4px"
                img={this.props.store.replaceBucketUrl(info.avatar_path)}
                kind="outline"
                color="basic"
              >
                {!this.props.mobile ? supervisors?supervisors.username:user.username : null}
              </Button>
            )}
          </DropdownToggle>
          <DropdownPanel className="has-text-centered">
            <div
              className="flex items-center mb-2"
              style={{ position: "relative", justifyContent: "center" }}
            >
              <div
                className="flex"
                onMouseEnter={() => this.setState({ showEditLogo: true })}
                onMouseLeave={() => this.setState({ showEditLogo: false })}
              >
                <Avatar
                  image={this.props.store.replaceBucketUrl(info.avatar_path)}
                  size="lg"
                  placeholderIcon={
                    this.props.store.viewMobile
                      ? "user-circle"
                      : "user-circle-white"
                  }
                />
                {this.state.showEditLogo && (
                  <div
                    style={{
                      position: "absolute",
                      background: "rgba(0, 0, 0, 0.6)",
                    }}
                    className="ks--avatar lg"
                  >
                    <div
                      className="flex items-center"
                      style={{
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Button
                        size="xl"
                        kind="link"
                        color="basic"
                        iconKasimu="pen"
                        style={{ position: "inherit" }}
                        padding="0px"
                        onClick={this.handleShowLogoModal}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {this.props.mobile ? (
              <Text size="sm" className="mb-2" lead>
                {supervisors?supervisors.username:user.username}
              </Text>
            ) : null}
            <Text className="mb-1" size="sm" weight="strong">
              {supervisors?this.props.store.language.root[6]:this.props.store.language.root[user.getRolId()]}
            </Text>
            <Text className="mb-1" size="sm" weight="strong">
              {this.props.store.getServiceName()}
            </Text>
            <Text weight="bold" size="md">
              {this.props.store.version}
            </Text>

            <div>
              <Button
                className="is-fullwidth mt-1"
                onClick={this.handleShowModalConfig}
                size={this.props.store.viewMobile ? "lg" : "md"}
                color="basic"
              >
                <strong>{this.props.store.language.label785}</strong>
              </Button>
            </div>

            {!supervisors && (user.getRolId() == 5 || user.getRolId() == 8) ? (
              <Button
                // kind="link"
                className="is-fullwidth mt-1 mb-2"
                onClick={this.handleShowNewModal}
                size={this.props.store.viewMobile ? "lg" : "md"}
              >
                <strong>{this.props.store.language.label687}</strong>
              </Button>
            ) : (
              <div className="my-1" />
            )}

            {this.props.mobile && (
              <Button
                className="mx-0"
                size="lg"
                iconSize="22px"
                iconKasimu="turn-off"
                color="basic"
                onClick={this.handleShowModalSignOut}
                kind="outline"
              />
            )}
          </DropdownPanel>
        </Dropdown>
        {!this.props.mobile && (
          <Button
            className="ml-1"
            size="lg"
            iconSize="22px"
            iconKasimu="turn-off"
            color="basic"
            onClick={this.handleShowModalSignOut}
            kind="outline"
          />
        )}
        {showNewModal && (
          <AccountManagement onClose={this.handleAccountNewModalClose} />
        )}
        {showModalSignOut && (
          <SignOutModal
            onClose={this.handleCloseModalSignOut}
            onAccept={this.handleSignOut}
          />
        )}
        {showLogoModal && (
          <AccountLogoModal onClose={this.handleCloseLogoModal} />
        )}
        {showModalConfig && (
          <ConfigModal onClose={this.handleCloseModalConfig} />
        )}
      </div>
    );
  }
}

LoggedUserDropdown.propTypes = {
  mobile: PropTypes.bool,
};

LoggedUserDropdown.defaultProps = {
  mobile: false,
};

export default withRouter(withStore(LoggedUserDropdown));
