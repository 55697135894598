import React, { Component } from "react";
import {
  Panel,
  TextInput,
  Text,
  Button,
  Img,
  Form,
  Loader,
  Field,
  Columns,
  Column,
} from "ks_storybook";
import withStore from "../../hocs/withStore";
import Select from "../../Components/Select/Select.jsx";
import es_ES from "../../lang/es_ES.js";
import en_EN from "../../lang/en_EN.js";
import fr_FR from "../../lang/fr_FR.js";
import pt_PT from "../../lang/pt_PT.js";
import et_EE from "../../lang/et_EE.js";
import de_DE from "../../lang/de_DE.js";
import it_IT from "../../lang/it_IT.js";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      password: "",
      signingIn: false,
      signingError: null,
      eye: false,
    };

    this.signIn = this.signIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(value, sender) {
    const val = !value ? 0 : value.value;
    this.props.store.lang = val;

    switch (val) {
      case 2:
        localStorage.setItem("currentLang", val);
        this.props.store.language = en_EN;
        break;
      case 1:
        localStorage.setItem("currentLang", val);
        this.props.store.language = es_ES;
        break;
      case 3:
        localStorage.setItem("currentLang", val);
        this.props.store.language = fr_FR;
        break;
      case 4:
        localStorage.setItem("currentLang", val);
        this.props.store.language = pt_PT;
        break;
      case 5:
        localStorage.setItem("currentLang", val);
        this.props.store.language = et_EE;
        break;
      case 6:
        localStorage.setItem("currentLang", val);
        this.props.store.language = de_DE;
        break;
      case 7:
        localStorage.setItem("currentLang", val);
        this.props.store.language = it_IT;
        break;
    }
  }
  renderSelectLang(value) {
    const options = [
      { value: 2, label: this.props.store.language.languages[2] }, 
      { value: 1, label: this.props.store.language.languages[1] },           
      { value: 3, label: this.props.store.language.languages[3] },
      { value: 4, label: this.props.store.language.languages[4] },
      { value: 5, label: this.props.store.language.languages[5] },
      { value: 6, label: this.props.store.language.languages[6] },
      { value: 7, label: this.props.store.language.languages[7] },
    ];
    return (
      <Field>
        <Select
          onChange={this.handleSelectChange}
          name="lang"
          required
          autocomplete="nope"
          options={options}
          value={options.filter((e) => e.value === value)}
        />
      </Field>
    );
  }

  signIn(sender, formData) {
    this.setState({ signingIn: true });
    this.props.store
      .signIn(this.state.user, this.state.password)
      .then((result) => {
        if (result.answer_code != 3) {
          this.setState({
            signingIn: false,
            signingError: this.getErrorMessager(result.answer_code),
          });
        }
      })
  }

  /*signIn(sender, formData) {
    this.setState({ signingIn: true });
    this.props.store
      .signIn(this.state.user, this.state.password)
      .catch((err) => {
        let str = err.toString();
        if (str === "TypeError: Failed to fetch") {
          this.setState({
            signingIn: false,
            signingError: this.getErrorMessager(11),
          });
        } else {
          str = str.replace("Error: ", "");
          let mydatas = JSON.parse(str);
          this.setState({
            signingIn: false,
            signingError: this.getErrorMessager(mydatas["codError"]),
          });
        }
      });
  }*/

  handleInputChange(sender, value, name) {
    let newState = {};
    newState[name] = value;
    this.setState(newState);
  }

  getErrorMessager(answer_code) {
    const messager = {
      1: this.props.store.language.label10,
      2: this.props.store.language.label11,
      4:
        this.props.store.language.label12 +
        this.props.store.currentCompany.supportContact +
        ".",
      5: this.props.store.language.label13,
      6:
        this.props.store.language.label14 +
        this.props.store.currentCompany.web +
        ".",
      7: this.props.store.language.label15,
      8: this.props.store.language.label735,
      9: this.props.store.language.label786,
      10: this.props.store.language.label821,
      11: this.props.store.language.label15,
    };
    return messager[answer_code];
  }

  autoSignIn = (autoUsername, autoPws) => {
    if (this.props.store.autoUsername && this.props.store.autoPws) {
      this.props.store
      .signIn(autoUsername, autoPws)
      .then((result) => {
        if (result.answer_code != 3) {
          this.setState({
            signingIn: false,
            signingError: this.getErrorMessager(result.answer_code),
          });
        }
      });
    }
  };

  /*autoSignIn = (autoUsername, autoPws) => {
    if (this.props.store.autoUsername && this.props.store.autoPws) {
      this.props.store.signIn(autoUsername, autoPws).catch((err) => {
        let str = err.toString();
        if (str === "TypeError: Failed to fetch") {
          this.setState({
            signingIn: false,
            signingError: this.getErrorMessager(7),
          });
        } else {
          str = str.replace("Error: ", "");
          let mydatas = JSON.parse(str);
          this.props.store.autoUsername = "";
          this.props.store.autoPws = "";
          this.setState({
            signingIn: false,
            signingError: this.getErrorMessager(mydatas["codError"]),
          });
        }
      });
    }
  };*/

  renderLoad = () => {
    return (
      <Loader
        className="fullscreen"
        icon="sync-alt"
        label={this.props.store.language.label5}
        animation="spin"
      />
    );
  };

  render() {
    this.autoSignIn(this.props.store.autoUsername, this.props.store.autoPws);
    return (
      <Columns
        isMarginless
        isVCentered
        isCentered
        style={{ height: "100vh", overflow: "auto" }}
      >
        <Column
          className="has-text-centered px-2"
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Img
                src={this.props.store.getLogo()}
                className="my-3"
              />
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="strong"
                lineBreak
                upper
              >
                {this.props.store.language.label6}
              </Text>
              <br />
              <div style={{ maxWidth: "545px", width: "100%" }}>
                <Form onSubmit={this.signIn} className="is-fullwidth">
                  {this.renderSelectLang(this.props.store.lang || 1)}
                  <TextInput
                    name="user"
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    lead
                    iconKasimu="user"
                    placeholder={this.props.store.language.label7}
                    type="text"
                    className="is-fullwidth my-2"
                    value={this.state.user}
                    disabled={this.state.signingIn}
                    onChange={this.handleInputChange}
                    backgroundColor="blackDark"
                    color="white"
                    borderColor="white"
                    borderRadius="20px"
                    placeholderColor="white"
                    paddingInput={
                      this.props.store.viewMobile
                        ? "30px 50px !important"
                        : "25px 50px !important"
                    }
                    textAlign="center"
                  />
                  <TextInput
                    double
                    iconDouble={this.state.eye ? "eye-slash" : "eye"}
                    name="password"
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    lead
                    iconKasimu="lock"
                    placeholder={this.props.store.language.label8}
                    type={this.state.eye ? "text" : "password"}
                    className="is-fullwidth my-2"
                    value={this.state.password}
                    disabled={this.state.signingIn}
                    onChange={this.handleInputChange}
                    backgroundColor="blackDark"
                    color="white"
                    borderColor="white"
                    borderRadius="20px"
                    placeholderColor="white"
                    paddingInput={
                      this.props.store.viewMobile
                        ? "30px 50px !important"
                        : "25px 50px !important"
                    }
                    textAlign="center"
                    onClickIcon={() => this.setState({ eye: !this.state.eye })}
                  />

                  {this.state.signingError && (
                    <Panel color="error" className="mt-2" invert>
                      <Text multiline>{this.state.signingError}</Text>
                    </Panel>
                  )}

                  {!this.state.signingIn ? (
                    <Button
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      upper
                      className="is-fullwidth mt-3 mb-2 py-2"
                      borderRadius="20px"
                      isDefault
                    >
                      {this.props.store.language.label9}
                    </Button>
                  ) : (
                    <Button
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      upper
                      className="is-fullwidth mt-3 mb-2 py-2"
                      borderRadius="20px"
                      icon="spinner"
                      pulse
                      disabled
                    />
                  )}
                </Form>
                {/*<Button kind="link" className="is-fullwidth mt-1 mb-2">¿Olvidaste tu contraseña?</Button>*/}
              </div>
            </div>
            <div
              className="flex pb-2"
              style={{
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Text
                weight="bold"
                size={this.props.store.viewMobile ? "xl" : "lg"}
              >
                {this.props.store.version}
              </Text>
            </div>
          </div>
        </Column>
      </Columns>
    );
  }
}

export default withStore(SignIn);
