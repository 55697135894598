import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  Modal,
  Button,
  ModalHeader,
  ModalFooter,
  ModalContent,
  Panel,
  Checkbox,
  Column,
  Columns,
} from "ks_storybook";
import { Select } from "../../Components/Select";
import { withToastManager } from "react-toast-notifications";
import { UsersSongsEdit } from "../../models";

@observer
class UsersSongsEditModal extends Component {
  @observable usersSongsEditNew;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: false,
    };

    this.usersSongsEditNew = this.props.songs.users_songs_edit
      ? this.editUsersSongsEdit()
      : this.newUsersSongsEdit();
  }

  newUsersSongsEdit = () => {
    const usersSongsEditStore = this.props.store.usersSongsEdit;
    const attrs = {
      song_id: this.props.songs.id,
      singer_id: this.props.songs.singer.id,
      song_mood_id: this.props.songs.song_mood_id,
      song_styles_id: this.props.songs.song_styles_id,
      song_language_id: this.props.songs.song_lenguages_id,
      song_name: this.props.songs.name,
      bpm: this.props.songs.bpm,
      preference: this.props.songs.preference,
      remix: this.props.songs.remix,
      explicit: this.props.songs.explicit,
      origin_type: 1,
      deleted: 0,
    };

    return new UsersSongsEdit(attrs, usersSongsEditStore);
  };

  editUsersSongsEdit = () => {
    const usersSongsEditStore = this.props.store.usersSongsEdit;
    const attrs = {
      id: this.props.songs.users_songs_edit.id,
      user_id: this.props.songs.users_songs_edit.user_id,
      song_id: this.props.songs.users_songs_edit.song_id,
      singer_id: this.props.songs.users_songs_edit.singer_id,
      song_mood_id: this.props.songs.users_songs_edit.song_mood_id,
      song_styles_id: this.props.songs.users_songs_edit.song_styles_id,
      song_language_id: this.props.songs.users_songs_edit.song_lenguages_id,
      song_name: this.props.songs.users_songs_edit.song_name,
      bpm: this.props.songs.users_songs_edit.bpm,
      preference: this.props.songs.users_songs_edit.preference,
      remix: this.props.songs.users_songs_edit.remix,
      explicit: this.props.songs.users_songs_edit.explicit,
      origin_type: this.props.store.getSingleUser() ? 1 : 0,
      deleted: 0,
    };
    return new UsersSongsEdit(attrs, usersSongsEditStore);
  };

  getOptions(obj) {
    return obj.toArray().map((o) => ({ value: o.style_id, label: o.name }));
  }

  handleChangeSelect = (value, sender) => {
    let val = !value ? 1 : value.value;
    if (sender.name == "song_styles_id") {
      if (val == 1) {
        this.usersSongsEditNew[sender.name] = this.props.songs.song_styles_id;
      } else {
        this.usersSongsEditNew[sender.name] = val;
      }
    }
  };

  handleCheckboxChange = (sender, value) => {
    this.usersSongsEditNew[sender.props.name] = value ? 1 : 0;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getNameStyles = (style_id, options) => {
    if (style_id < 5000) {
      let style = this.props.stylesOptions.filter((e) => e.value == style_id);
      return style[0].label;
    } else {
      let style = options.filter((e) => e.value == style_id);
      return style[0].label;
    }
  };

  getNameMood = (mood_id) => {
    return this.props.store.language.mood[mood_id];
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.usersSongsEditNew.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label555, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add(this.props.store.language.label556, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.onSave && this.props.onSave();
        }
      });
    });
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const options = this.getOptions(this.props.usersStyles);
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
      >
        <ModalHeader>
          <Text
            multiline
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            className="mb-1"
          >
            {this.props.store.language.label546}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 7 }}>
              <Field
                label={this.props.store.language.label522}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                marginH={this.props.store.viewMobile && "10px"}
                weight="medium"
              >
                <Select
                  name={"song_styles_id"}
                  isClearable
                  onChange={this.handleChangeSelect}
                  options={options}
                  defaultValue={
                    this.usersSongsEditNew.song_styles_id > 5000
                      ? options[
                          options
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(this.usersSongsEditNew.song_styles_id)
                        ]
                      : null
                  }
                  backgroundColor="black"
                  className="my-1"
                  placeholder={this.props.store.language.label547}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </Field>
            </Column>
          </Columns>
          <br />
          <div>
            <div className="is-row my-1">
              <Text
                size={this.props.store.viewMobile ? "xxl" : "xl"}
                weight="medium"
                upper
              >
                {this.props.store.language.label549}:
              </Text>
              <div style={{ margin: "0 2px" }} />
              <Text
                color="special"
                size={this.props.store.viewMobile ? "xxl" : "xl"}
              >
                {this.props.songs.name}
              </Text>
            </div>
            <div className="is-row my-1">
              <Text
                size={this.props.store.viewMobile ? "xxl" : "xl"}
                weight="medium"
                upper
              >
                {this.props.store.language.label550}:
              </Text>
              <div style={{ margin: "0 2px" }} />
              <Text
                color="special"
                size={this.props.store.viewMobile ? "xxl" : "xl"}
              >
                {this.getNameStyles(
                  this.usersSongsEditNew.song_styles_id,
                  options
                )}
              </Text>
            </div>
            <div className="is-row my-1">
              <Text
                size={this.props.store.viewMobile ? "xxl" : "xl"}
                weight="medium"
                upper
              >
                {this.props.store.language.label551}:
              </Text>
              <div style={{ margin: "0 2px" }} />
              <Text
                color="special"
                size={this.props.store.viewMobile ? "xxl" : "xl"}
              >
                {this.getNameMood(this.usersSongsEditNew.song_mood_id)}
              </Text>
            </div>
            <div className="is-row my-1">
              <Text
                size={this.props.store.viewMobile ? "xxl" : "xl"}
                weight="medium"
                upper
              >
                {this.props.store.language.label552}:
              </Text>
              <div style={{ margin: "0 2px" }} />
              <Text
                color="special"
                size={this.props.store.viewMobile ? "xxl" : "xl"}
              >
                {this.usersSongsEditNew.preference}
              </Text>
            </div>
            <div
              className="is-row my-1"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Checkbox
                invert
                name="explicit"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                onChange={this.handleCheckboxChange}
                isChecked={this.usersSongsEditNew.explicit ? true : false}
                className="items-center"
              >
                <Text
                  size={this.props.store.viewMobile ? "xxl" : "xl"}
                  weight="medium"
                  upper
                >
                  {this.props.store.language.label553}
                </Text>
              </Checkbox>
              <div style={{ margin: "0 2px" }} />
              <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                {this.props.store.language.label554}
              </Text>
            </div>
          </div>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

UsersSongsEditModal.propTypes = {
  songs: PropTypes.object,
  usersStyles: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

UsersSongsEditModal.defaultProps = {
  songs: null,
  usersStyles: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(UsersSongsEditModal));
