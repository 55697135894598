import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  Columns,
  Column,
  Img,
} from "ks_storybook";
import {
  AccountDeleteModal,
  AccountEditModal,
  AccountNewModal,
  AccountEyeModal,
} from "../../../../Components/Account";
import { Select } from "../../../../Components/Select";
import { Screen } from "../../../../Components/Screen";
import { EmptyContent, Pagination } from "../../../../Components/Common";
import ConfirmationModal from "../../../../Components/Common/Modal/ConfirmationModal";
import ReactTooltip from "react-tooltip";
import PlaylistsFavoriteModal from "../../Playlists/PlaylistsFavoriteModal";
import PlaylistsPlannerModal from "../../Playlists/Planner/PlaylistsPlannerModal";
import AdsPlanerModal from "../../Ads/Planner/AdsPlanerModal";
import UsersCloneModal from "../../Users/Config/UsersCloneModal";
import UsersPreferencesModal from "../../Users/Config/UsersPreferencesModal";
import UsersPersonalizationModal from "../Config/UsersPersonalizationModal";
import UserMoreOptionsModal from "../Config/UserMoreOptionsModal";
import UserLicensesModal from "../Config/UserLicensesModal";
import UsersPermissionsModal from "../Config/UsersPermissionsModal";
import GroupsModal from "../../Groups/GroupsModal";
import moment from "moment-timezone";

@observer
class UsersListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: this.getAccountsAll(
        25,
        null,
        null,
        null,
        this.props.store.lang
      ),
      groups: this.getGroups(),
      showNewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      showEyeModal: false,
      showPlaylistFavorite: false,
      showPlaylistPlanner: false,
      showAdsPlanerModal: false,
      showUsersCloneModal: false,
      showUsersPreferencesModal: false,
      showUsersPersonalizationModal: false,
      showUsersPermissionsModal: false,
      showGroupsModal: false,
      showUserMoreOptionsModal: false,
      showUserLicensesModal: false,
      showConfirmationStatusModal: false,
      currentAccounts: null,
      page: 1,
      filters: {
        user_group_id: null,
        connection_status: null,
      },
      screen: {
        actions: false,
      },
      titleScreen: "",
      titelScreenActions: "",
      account: null,
    };
    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label17;
  }

  componentDidUpdate() {
    const wavesurfer = this.props.store.ui.wavesurfer;
    if (wavesurfer) {
      wavesurfer.drawer.containerWidth =
        wavesurfer.drawer.container.clientWidth;
      wavesurfer.drawBuffer();
    }
  }

  componentWillUnmount() {
    this.props.store.ui.destroy();
  }

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  reload = () => {
    const { page, filters } = this.state;
    filters.user_group_id = this.props.store.getSupervisorsGroupId();
    this.setState({
      currentAccounts: null,
      filters: filters,
      accounts: this.props.store.users.fetchPage(
        this.per_page,
        page,
        this.validateFilter(filters),
        null,
        this.search
      ),
      groups: this.getGroups(),
    });
    this.props.store.loadUserLicenses();
    this.props.store.ui.destroy();
  };

  reloadNew = () => {
    const { filters } = this.state;
    filters.user_group_id = this.props.store.getSupervisorsGroupId();
    this.search = null;
    this.setState({
      currentAccounts: null,
      filters: filters,
      accounts: this.props.store.users.fetchPage(
        this.per_page,
        1,
        this.validateFilter(filters),
        null,
        null
      ),
      groups: this.getGroups(),
    });
    this.props.store.loadUserLicenses();
    this.props.store.ui.destroy();
  };

  getGroups = () => {
    return this.props.store.groups.fetchAll();
  };

  getAccountsAll = (per_page, filters, orders, search, lang) => {
    if (this.props.store.supervisors) {
      const _filters = {
        user_group_id: this.props.store.getSupervisorsGroupId(),
        connection_status: null,
      };
      filters = _filters;
    }
    return this.props.store.users.fetchAccountsAll(
      per_page,
      filters,
      orders,
      search,
      lang
    );
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleAccountNewModalSave = () => {
    this.reload();
    this.setState({ showNewModal: false });
  };

  handleAccountNewModalClose = () => {
    this.setState({ showNewModal: false });
  };

  handleShowEditModal = (accounts) => {
    this.setState({ showEditModal: true, currentAccounts: accounts });
  };

  handleAccountEditModalSave = () => {
    this.handleHideEditModal();
    this.reload();
  };

  handleHideEditModal = () => {
    this.setState({ showEditModal: false, currentAccounts: null });
  };

  handleShowDeleteModal = (accounts) => {
    this.setState({ showDeleteModal: true, currentAccounts: accounts });
  };

  handleHideDeleteModal = () => {
    this.setState({ showDeleteModal: false, currentAccounts: null });
  };

  handleDeleteAccounts = () => {
    this.handleHideDeleteModal();
    this.reload();
  };

  handleEyeModalOpen = (accounts) => {
    this.setState({ showEyeModal: true, currentAccounts: accounts });
  };

  handleEyeModalClose = () => {
    this.setState({ showEyeModal: false });
  };

  handlePlaylistFavoriteModalOpen = (accounts) => {
    this.setState({ showPlaylistFavorite: true, currentAccounts: accounts });
  };

  handlePlaylistFavoriteModalClose = () => {
    this.setState({ showPlaylistFavorite: false });
  };

  handlePlaylistPlannerModalOpen = (accounts) => {
    this.setState({ showPlaylistPlanner: true, currentAccounts: accounts });
  };

  handlePlaylistPlannerModalClose = () => {
    this.setState({ showPlaylistPlanner: false });
  };

  handleAdsPlanerModalOpen = (accounts) => {
    this.setState({ showAdsPlanerModal: true, currentAccounts: accounts });
  };

  handleAdsPlanerModalClose = () => {
    this.setState({ showAdsPlanerModal: false });
  };

  handleUsersCloneModalOpen = (accounts) => {
    this.setState({ showUsersCloneModal: true, currentAccounts: accounts });
  };

  handleUsersCloneModalClose = () => {
    this.setState({ showUsersCloneModal: false });
  };

  handleUsersPreferencesModalOpen = (accounts) => {
    this.setState({
      showUsersPreferencesModal: true,
      currentAccounts: accounts,
    });
  };

  handleUsersPreferencesModalClose = () => {
    this.setState({ showUsersPreferencesModal: false });
  };

  handleUsersPersonalizationModalOpen = (accounts) => {
    this.setState({
      showUsersPersonalizationModal: true,
      currentAccounts: accounts,
    });
  };

  handleUsersPersonalizationModalClose = () => {
    this.setState({ showUsersPersonalizationModal: false });
  };

  handleUsersPermissionsModalOpen = (accounts) => {
    this.setState({
      showUsersPermissionsModal: true,
      currentAccounts: accounts,
    });
  };

  handleConfirmationStatusModalOpen = (accounts) => {
    this.setState({
      showConfirmationStatusModal: true,
      currentAccounts: accounts,
    });
  };

  handleUsersPermissionsModalClose = () => {
    this.setState({ showUsersPermissionsModal: false });
  };

  handleGroupsModalOpen = () => {
    this.setState({ showGroupsModal: true });
  };

  handleGroupsModalClose = () => {
    this.setState({ showGroupsModal: false });
  };

  handleUserMoreOptionsModalOpen = () => {
    this.setState({ showUserMoreOptionsModal: true });
  };

  handleUserMoreOptionsModalClose = () => {
    this.setState({ showUserMoreOptionsModal: false });
  };

  handleUserLicensesModalOpen = () => {
    this.setState({ showUserLicensesModal: true });
  };

  handleUserLicensesModalClose = () => {
    this.setState({ showUserLicensesModal: false });
  };

  handleConfirmationStatusModalClose = () => {
    this.setState({ showConfirmationStatusModal: false, currentAccount: null });
  };

  updateStatus = (status) => {
    const { currentAccounts } = this.state;
    const { toastManager } = this.props;
    this.setState(
      {
        showConfirmationStatusModal: false,
      },
      () => {
        this.props.store.kasimuAPIClient
          .putOne(
            "/users/change_status_user",
            "?user_id=" + currentAccounts.id + "&status=" + status
          )
          .then(
            (res) => {
              toastManager.add(
                status == 1
                  ? this.props.store.language.label739
                  : this.props.store.language.label740,
                {
                  appearance: "success",
                  autoDismiss: true,
                }
              );
              this.reload();
            },
            (error) => {
              toastManager.add(
                status == 1
                  ? this.props.store.language.label741
                  : this.props.store.language.label742,
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              this.setState({ currentAccounts: null });
            }
          )
          .catch((error) => {
            toastManager.add(
              status == 1
                ? this.props.store.language.label741
                : this.props.store.language.label742,
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ currentAccount: null });
          });
      }
    );
  };

  validateFilter = (filters) => {
    if (filters)
      if (filters.connection_status || filters.user_group_id) return filters;
    return null;
  };

  handleSelectChange = (value, sender) => {
    const { filters } = this.state;
    const val = value.value;
    const newFilters = filters;
    switch (sender.name) {
      case "selectUserGroups":
        newFilters.user_group_id = val;
        break;
      case "selectConnectionStatus":
        newFilters.connection_status = val;
        break;
      default:
        break;
    }
    this.setState({
      filters: newFilters,
      currentAccounts: null,
      accounts: this.getAccountsAll(
        this.per_page,
        this.validateFilter(filters),
        null,
        this.search,
        this.props.store.lang
      ),
    });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { filters } = this.state;
    if (!filters.user_group_id && !filters.connection_status) {
      this.setState({
        accounts: this.getAccountsAll(
          this.per_page,
          null,
          null,
          this.search && this.search != " " ? this.search : null,
          this.props.store.lang
        ),
        page: 1,
      });
    } else {
      this.setState({
        accounts: this.getAccountsAll(
          this.per_page,
          filters,
          null,
          this.search && this.search != " " ? this.search : null,
          this.props.store.lang
        ),
        page: 1,
      });
    }
  };

  getOptions = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  getPanel = (groups) => {
    const { filters } = this.state;
    const connectionOptions = [
      { value: 1, label: this.props.store.language.label56 },
      { value: 2, label: this.props.store.language.label57 },
      { value: 3, label: this.props.store.language.label96 },
      { value: 4, label: this.props.store.language.label58 },
    ];

    const grupsOptions = this.getOptions(groups);
    const supervisors = this.props.store.getSupervisors();
    return (
      <React.Fragment>
        <Columns isMultiline isVCentered className="pb-2">
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
            {!this.props.store.viewMobile && <br />}
            <TextInput
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              placeholder={this.props.store.language.label644}
              value={this.search && this.search}
            />
          </Column>
          <Column
            isSize={{ mobile: 12, tablet: 12, desktop: 8 }}
            style={{
              display: "flex",
              paddingTop: !this.props.store.viewMobile && "0px",
              flexWrap: "wrap",
              gap: this.props.store.viewMobile ? "20px" : "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Text color="white" size="lg" weight="bold">
                {this.props.store.language.label405}
              </Text>
            </div>
            <div
              className="flex items-center"
              style={{
                flex: 1,
                gap: this.props.store.viewMobile ? "20px" : "10px",
              }}
            >
              {!supervisors && (
                <div style={{ maxWidth: "200px", width: "100%" }}>
                  <Select
                    name="selectUserGroups"
                    onChange={this.handleSelectChange}
                    className="is-fullwidth"
                    placeholder={this.props.store.language.label638}
                    options={grupsOptions}
                    defaultValue={
                      filters.user_group_id
                        ? grupsOptions[
                            grupsOptions
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(filters.user_group_id)
                          ]
                        : grupsOptions[-1]
                    }
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    isMobile={!this.props.store.viewMobile}
                  />
                </div>
              )}
              <div style={{ maxWidth: "300px", width: "100%" }}>
                <Select
                  name="selectConnectionStatus"
                  onChange={this.handleSelectChange}
                  className="is-fullwidth "
                  placeholder={this.props.store.language.label640}
                  options={connectionOptions}
                  defaultValue={
                    filters.connection_status
                      ? connectionOptions[
                          connectionOptions
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(filters.connection_status)
                        ]
                      : connectionOptions[-1]
                  }
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </div>
              {this.props.store.viewMobile && (
                <div data-tip data-for="refresh">
                  <Button
                    kind="link"
                    color="basic"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    iconKasimu="refresh"
                    onClick={() => {
                      this.reloadNew();
                    }}
                    space
                  />
                </div>
              )}
            </div>
          </Column>
        </Columns>
        {!this.props.store.viewMobile && (
          <Columns className="mb-2">
            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              <div
                className="button-wrapper flex"
                style={{
                  flexWrap: "no-wrap",
                  gap: 3,
                  overflowX: "auto !important",
                }}
              >
                <Button
                  className="px-2"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  //onClick={this.handleShowNewModal}
                  onClick={() =>
                    this.props.store.getPermission(23, 23, () => {
                      this.handleShowNewModal();
                    })
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  <strong>{this.props.store.language.label49}</strong>
                </Button>
                {!supervisors && (
                  <Button
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    weight="medium"
                    className="px-2 mx-0"
                    kind="outline"
                    color="basic"
                    onClick={() => {
                      this.handleGroupsModalOpen();
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <strong>{this.props.store.language.label36}</strong>
                  </Button>
                )}
                {this.props.store.loggedInLicenses && (
                  <Button
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    weight="medium"
                    className="px-2 mx-0"
                    kind="outline"
                    color="basic"
                    //onClick={() => {this.handleUserLicensesModalOpen()}}
                    onClick={() =>
                      this.props.store.getPermission(24, 24, () => {
                        this.handleUserLicensesModalOpen();
                      })
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <strong>{this.props.store.language.label769}</strong>
                  </Button>
                )}
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-2 mx-0"
                  kind="outline"
                  color="basic"
                  onClick={() => {
                    this.handleUserMoreOptionsModalOpen();
                  }}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <strong>{this.props.store.language.label720}</strong>
                </Button>
              </div>
            </Column>
          </Columns>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="refresh" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label725}</span>
          </ReactTooltip>
        )}
      </React.Fragment>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderGroup = () => {
    const { groups } = this.state;
    if (!groups) return this.renderEmptyContent();
    return !groups.isOk() ? this.renderLoader() : this.renderAccounts();
  };

  renderHeader = () => {
    const { accounts } = this.state;
    if (!accounts) return this.renderEmptyContent();
    return !accounts.isOk() ? this.renderLoader() : this.renderGroup();
  };

  onPageChange = (page) => {
    const { filters } = this.state;
    this.setState({
      accounts: this.props.store.users.fetchPage(
        this.per_page,
        page,
        this.validateFilter(filters),
        null,
        this.search
      ),
      page: page,
    });
  };

  renderPaginator = (accounts) => {
    if (accounts.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={accounts.totalPages}
            currentPage={accounts.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  getIconStatus = (status) => {
    const array = { 1: "user-check", 2: "user-slash" };
    return array[status];
  };

  getColorStatus = (status) => {
    const colors = { 1: "statusPublished", 2: "statusModified" };
    return colors[status];
  };

  getPlaylistsName = (account) => {
    var playlist_owner = null;
    var last_playlist_listened = null;
    if (account.cache_last_connection_app) {
      playlist_owner = account.cache_last_connection_app.playlist_owner;
      last_playlist_listened =
        account.cache_last_connection_app.last_playlist_listened;
    } else if (account.last_activity) {
      playlist_owner = account.last_activity.playlist_owner;
      last_playlist_listened = account.last_activity.last_playlist_listened[0];
    }
    const name = {
      1: last_playlist_listened.alias,
      2: this.props.store.language.label631 + last_playlist_listened.name,
      3: this.props.store.language.label630 + last_playlist_listened.name,
    };
    return name[playlist_owner];
  };

  getIconPath = (account) => {
    var playlist_owner = null;
    var last_playlist_listened = null;
    if (account.cache_last_connection_app) {
      playlist_owner = account.cache_last_connection_app.playlist_owner;
      last_playlist_listened =
        account.cache_last_connection_app.last_playlist_listened;
    } else if (account.last_activity) {
      playlist_owner = account.last_activity.playlist_owner;
      last_playlist_listened = account.last_activity.last_playlist_listened[0];
    }

    const icon_path = {
      1: last_playlist_listened.icon_path,
      2:
        last_playlist_listened.static == 1
          ? this.props.store.getBucketUrl() + "images/cp-m.png"
          : this.props.store.getBucketUrl() + "images/cp-a.png",
      3:
        last_playlist_listened.static == 1
          ? this.props.store.getBucketUrl() + "images/cp-m.png"
          : this.props.store.getBucketUrl() + "images/cp-a.png",
    };
    return this.props.store.replaceBucketUrl(icon_path[playlist_owner]);
  };

  getStatus = (account) => {
    var msg = "";
    if (account.cache_last_connection_app) {
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status === 2
        ? (msg = (
            <span className="danger">{this.props.store.language.label67}</span>
          ))
        : connection_type != null
        ? connection_type === 4
          ? (msg = (
              <span className="hibernating">{`${this.props.store.language.label96} `}</span>
            ))
          : connection_type === 3
          ? account.cache_last_connection_app.offline
            ? (msg = <span className="hibernating">{`${"Offline"}`}</span>)
            : (msg = (
                <span className="success">{`${this.props.store.language.label64} `}</span>
              ))
          : (msg = (
              <span className="danger">{`${this.props.store.language.label65} `}</span>
            ))
        : (msg = (
            <span className="">{`${this.props.store.language.label66} `}</span>
          ));
    } else {
      var conexion_type =
        account.last_activity && account.last_activity.conexion_type;
      account.status === 2
        ? (msg = (
            <span className="danger">{this.props.store.language.label67}</span>
          ))
        : conexion_type != null
        ? conexion_type === 4
          ? (msg = (
              <span className="hibernating">{`${this.props.store.language.label96} `}</span>
            ))
          : conexion_type === 1 || conexion_type === 3
          ? account.last_activity.offline
            ? (msg = <span className="hibernating">{`${"Offline"}`}</span>)
            : (msg = (
                <span className="success">{`${this.props.store.language.label64} `}</span>
              ))
          : (msg = (
              <span className="danger">{`${this.props.store.language.label65} `}</span>
            ))
        : (msg = (
            <span className="">{`${this.props.store.language.label66} `}</span>
          ));
    }

    return msg;
  };

  getStatusColor = (account) => {
    var color = "basic";
    if (account.cache_last_connection_app) {
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status >= 2
        ? (color = "orange")
        : connection_type != null
        ? connection_type === 4
          ? (color = "internal")
          : connection_type === 3
          ? account.cache_last_connection_app.offline
            ? (color = "warning")
            : (color = "confirm")
          : (color = "special")
        : (color = "basic");
    } else {
      var conexion_type =
        account.last_activity && account.last_activity.conexion_type;
      account.status >= 2
        ? (color = "orange")
        : conexion_type != null
        ? conexion_type === 4
          ? (color = "internal")
          : conexion_type === 1 || conexion_type === 3
          ? account.last_activity.offline
            ? (color = "warning")
            : (color = "confirm")
          : (color = "special")
        : (color = "basic");
    }
    return color;
  };

  getTooltipMsg = (account) => {
    var msg = "";
    if (account.cache_last_connection_app) {
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status >= 2
        ? (msg =
            account.status === 2
              ? this.props.store.language.label67
              : this.props.store.language.label738)
        : connection_type != null
        ? connection_type === 4
          ? (msg = this.props.store.language.label96)
          : connection_type === 3
          ? account.cache_last_connection_app.offline
            ? (msg = "Offline")
            : (msg = this.props.store.language.label64)
          : (msg = this.props.store.language.label65)
        : (msg = this.props.store.language.label66);
    } else {
      var conexion_type =
        account.last_activity && account.last_activity.conexion_type;
      account.status >= 2
        ? (msg =
            account.status === 2
              ? this.props.store.language.label67
              : this.props.store.language.label738)
        : conexion_type != null
        ? conexion_type === 4
          ? (msg = this.props.store.language.label96)
          : conexion_type === 1 || conexion_type === 3
          ? account.last_activity.offline
            ? (msg = "Offline")
            : (msg = this.props.store.language.label64)
          : (msg = this.props.store.language.label65)
        : (msg = this.props.store.language.label66);
    }
    return msg;
  };

  getStatusMsg = (account) => {
    var msg = "";
    if (account.cache_last_connection_app) {
      var OS = ["", "Windows", "Android", "WebPlayer"];
      var currentTime = moment
        .utc(account.cache_last_connection_app.updated_at)
        .format();
      var userTimeZone = moment.tz.guess();
      var localTime = moment
        .tz(currentTime, userTimeZone)
        .format("dddd, DD MMMM YYYY, h:mm:ss a");
      var log =
        localTime +
        " | " +
        OS[account.cache_last_connection_app.os_number] +
        ", " +
        this.props.store.companies[
          account.cache_last_connection_app.reseller_companies
        ].name +
        " " +
        account.cache_last_connection_app.version_app_str;
      var connection_type = account.cache_last_connection_app.connection_type;
      account.status >= 2
        ? (msg =
            account.status === 2
              ? this.props.store.language.label67
              : this.props.store.language.label738 + " | " + log)
        : connection_type != null
        ? connection_type === 4
          ? (msg = this.props.store.language.label96 + " | " + log)
          : connection_type === 3
          ? account.cache_last_connection_app.offline
            ? (msg = "Offline")
            : (msg = this.props.store.language.label64 + " | " + log)
          : (msg = this.props.store.language.label65 + " | " + log)
        : (msg = this.props.store.language.label66);
    } else {
      if (account.last_activity) {
        var currentTime = moment.utc(account.last_activity.updated_at).format();
        var userTimeZone = moment.tz.guess();
        var localTime = moment
          .tz(currentTime, userTimeZone)
          .format("dddd, DD MMMM YYYY, h:mm:ss a");
        var log = localTime + " | " + account.last_activity.os;
        var conexion_type =
          account.last_activity && account.last_activity.conexion_type;
        account.status >= 2
          ? (msg =
              account.status === 2
                ? this.props.store.language.label67
                : this.props.store.language.label738 + " | " + log)
          : conexion_type != null
          ? conexion_type === 4
            ? (msg = this.props.store.language.label96 + " | " + log)
            : conexion_type === 1 || conexion_type === 3
            ? account.last_activity.offline
              ? (msg = "Offline")
              : (msg = this.props.store.language.label64 + " | " + log)
            : (msg = this.props.store.language.label65 + " | " + log)
          : (msg = this.props.store.language.label66);
      }
    }
    return msg;
  };

  getGrupName = (values) => {
    return values.map((o) => o.name).join(", ");
  };

  handleStartPlayingClick = (indice, song_path) => {
    var that = this;
    that.props.store.ui.startPlaying(song_path, 1, indice);
  };

  handleTogglePlay = (song_path) => {
    var that = this;
    if (song_path) {
      if (that.props.store.ui.currentSongPath) {
        if (!this.props.store.ui.isPlaying) {
          this.props.store.ui.play();
        } else {
          this.props.store.ui.pause();
        }
      }
    }
  };

  getLastPlaylistListened = (account) => {
    if (account.cache_last_connection_app) {
      return account.cache_last_connection_app.last_playlist_listened;
    }
    return (
      account.last_activity && account.last_activity.last_playlist_listened[0]
    );
  };

  getLastSongListened = (account) => {
    if (account.cache_last_connection_app) {
      return account.cache_last_connection_app.last_song_listened;
    }
    if (account.last_activity)
      return account.last_activity && account.last_activity.last_song_listened;
  };

  getPlaybackStatus = (account) => {
    if (account.cache_last_connection_app) {
      if (account.cache_last_connection_app.playback_status == 1)
        return (
          <span className="success">{`${this.props.store.language.label4} `}</span>
        );
      else if (account.cache_last_connection_app.playback_status == 2)
        return (
          <span className="danger">{`${this.props.store.language.label95} `}</span>
        );
    }
    return null;
  };

  renderItemsRow(account, indice) {
    var last_playlist_listened = this.getLastPlaylistListened(account);
    var last_song_listened = this.getLastSongListened(account);
    var playback_status = this.getPlaybackStatus(account);
    const currentSongPath = this.props.store.ui.currentSongPath;
    const active =
      currentSongPath && last_song_listened
        ? last_song_listened.song.song_path == currentSongPath
        : null;
    const { handleStartPlayingClick, handleTogglePlay } = this;
    const supervisors = this.props.store.getSupervisors();

    return (
      <Panel
        id={indice + 1}
        color={"gray"}
        padding={
          this.props.store.viewMobile
            ? active
              ? "16px 30px 0"
              : "16px 30px"
            : "14px 10px"
        }
        style={{ borderRadius: !this.props.store.viewMobile && 5 }}
      >
        <Columns isMobile isVCentered isMarginless isGapless>
          <Column isSize={{ mobile: 10, desktop: 10, tablet: 10 }}>
            <Columns>
              <Column isSize={{ mobile: 12, desktop: 6, tablet: 6 }}>
                <div className="flex">
                  {this.props.store.viewMobile ? (
                    <div
                      className="flex items-center"
                      data-tip
                      data-for={`user-${account.id}`}
                    >
                      <Button
                        iconKasimu="user-solid"
                        kind="link"
                        color={this.getStatusColor(account)}
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        padding="0"
                        className="mr-2"
                      />
                      {this.props.store.viewMobile && (
                        <ReactTooltip
                          id={`user-${account.id}`}
                          place="bottom"
                          type="dark"
                          effect="float"
                        >
                          <span>{this.getTooltipMsg(account)}</span>
                        </ReactTooltip>
                      )}
                    </div>
                  ) : (
                    <div
                      className="flex items-center"
                      style={{
                        borderRadius: 5,
                        marginRight: 12,
                      }}
                    >
                      <Button
                        iconKasimu="user-solid"
                        kind="link"
                        color={this.getStatusColor(account)}
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        padding="6px 8px"
                      />
                    </div>
                  )}
                  <div>
                    <Text
                      color="white"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      lineBreak
                    >
                      {account.username}
                    </Text>
                    <Text
                      color="whiteLight"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="regular"
                      multiline
                    >
                      {account.description &&
                        this.props.store.language.label765 +
                          ": " +
                          account.description}
                    </Text>
                    <div
                      className="is-row is-fullwidth"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        color="whiteLight"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="regular"
                        multiline
                      >
                        {this.getStatusMsg(account)}
                      </Text>
                    </div>
                    <div
                      className="is-row is-fullwidth"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        color="white"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="regular"
                        multiline
                      >
                        {account.groups.length === 0
                          ? this.props.store.language.label73
                          : this.getGrupName(account.groups)}
                      </Text>
                    </div>
                  </div>
                </div>
              </Column>
              {this.props.store.viewMobile && (
                <Column isSize={{ mobile: 12, desktop: 6, tablet: 6 }}>
                  {last_playlist_listened && (
                    <div
                      className="flex"
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="flex items-center"
                        style={{ marginBottom: 4 }}
                      >
                        {/* <Button
                          iconKasimu="playlist-solid"
                          kind="link"
                          color="basic"
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          padding="0"
                        /> */}
                        <Img
                          height="25px"
                          width="25px"
                          src={this.getIconPath(account)}
                        />
                        <Text
                          className="ml-2"
                          color="white"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                          multiline
                        >
                          {this.getPlaylistsName(account)}
                        </Text>
                      </div>
                      {last_song_listened && (
                        <div className="flex items-center">
                          <Button
                            icon={
                              active && this.props.store.ui.isPlaying
                                ? "pause"
                                : "play"
                            }
                            kind="link"
                            color={active ? "primary" : "basic"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            padding="0"
                            onClick={
                              active && this.props.store.ui.showPlayer
                                ? () =>
                                    handleTogglePlay(
                                      last_song_listened.song.song_path
                                    )
                                : () =>
                                    handleStartPlayingClick(
                                      indice,
                                      last_song_listened.song.song_path
                                    )
                            }
                          />
                          <Text
                            className="ml-2"
                            color="special"
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            weight="medium"
                            multiline
                          >
                            {last_song_listened.song.name +
                              " - " +
                              last_song_listened.song.singer.name}
                          </Text>
                        </div>
                      )}
                      {playback_status && (
                        <div className="flex items-center">
                          <Text
                            color="special"
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            weight="medium"
                            multiline
                          >
                            {playback_status}
                          </Text>
                        </div>
                      )}
                    </div>
                  )}
                </Column>
              )}
            </Columns>
          </Column>
          <Column isSize={{ mobile: 2, desktop: 2, tablet: 2 }}>
            <div className="is-row" style={{ float: "right" }}>
              {this.props.store.viewMobile ? (
                <div style={{ display: "flex" }}>
                  <div data-tip data-for="pf">
                    <Button
                      className="ml-2 star-icon"
                      icon="star"
                      kind="link"
                      color="basic"
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      lead
                      space
                      //onClick={() => {this.handlePlaylistFavoriteModalOpen(account)}}
                      onClick={() =>
                        this.props.store.getPermission(26, 26, () => {
                          this.handlePlaylistFavoriteModalOpen(account);
                        })
                      }
                      disabled={account.status === 1 ? false : true}
                    />
                  </div>
                  <div data-tip data-for="pp">
                    <Button
                      className="ml-2"
                      iconKasimu="playlist"
                      kind="link"
                      color="basic"
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      lead
                      space
                      //onClick={() => {this.handlePlaylistPlannerModalOpen(account)}}
                      onClick={() =>
                        this.props.store.getPermission(28, 28, () => {
                          this.handlePlaylistPlannerModalOpen(account);
                        })
                      }
                      disabled={account.status === 1 ? false : true}
                    />
                  </div>
                  <div data-tip data-for="pa">
                    <Button
                      className=" ml-2"
                      iconKasimu="ads"
                      kind="link"
                      color="basic"
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      lead
                      space
                      //onClick={() => {this.handleAdsPlanerModalOpen(account);}}
                      onClick={() =>
                        this.props.store.getPermission(30, 30, () => {
                          this.handleAdsPlanerModalOpen(account);
                        })
                      }
                      disabled={account.status === 1 ? false : true}
                    />
                  </div>
                  {!supervisors && (
                    <div data-tip data-for="clone">
                      <Button
                        className="ml-2"
                        iconKasimu="clone"
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        lead
                        space
                        onClick={() => {
                          this.handleUsersCloneModalOpen(account);
                        }}
                        disabled={account.status === 1 ? false : true}
                      />
                    </div>
                  )}
                  <div className="dropdown-buttons">
                    <Dropdown
                      direction="right-center"
                      space
                      disabled={account.status === 2 ? true : false}
                    >
                      <DropdownToggle disabledArrow>
                        <div data-tip data-for="mas">
                          <Button
                            className="ml-2"
                            kind="link"
                            color={"basic"}
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            iconKasimu={"three-dots"}
                            space
                            disabled={account.status === 2 ? true : false}
                          />
                        </div>
                      </DropdownToggle>
                      <DropdownPanel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "4px 10px",
                          }}
                        >
                          <div>
                            <div data-tip data-for="confi">
                              <Button
                                className="mt-1"
                                // icon="sliders-h"
                                kind="link"
                                color="basic"
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                lead
                                space
                                //onClick={() => {this.handleUsersPreferencesModalOpen(account);}}
                                onClick={() =>
                                  this.props.store.getPermission(33, 33, () => {
                                    this.handleUsersPreferencesModalOpen(
                                      account
                                    );
                                  })
                                }
                                disabled={account.status === 3 ? true : false}
                              >
                                {this.props.store.language.label23}
                              </Button>
                            </div>
                          </div>
                          <div>
                            <div data-tip data-for="per">
                              <Button
                                className="mt-1"
                                // icon="id-card"
                                kind="link"
                                color="basic"
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                lead
                                space
                                //onClick={() => {this.handleUsersPersonalizationModalOpen(account)}}
                                onClick={() =>
                                  this.props.store.getPermission(34, 34, () => {
                                    this.handleUsersPersonalizationModalOpen(
                                      account
                                    );
                                  })
                                }
                                disabled={account.status === 3 ? true : false}
                              >
                                {this.props.store.language.label38}
                              </Button>
                            </div>
                          </div>
                          <div>
                            <div data-tip data-for="confi">
                              <Button
                                className="mt-1"
                                // icon="sliders-h"
                                kind="link"
                                color="basic"
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                lead
                                space
                                //onClick={() => {this.handleUsersPermissionsModalOpen(account)}}
                                onClick={() =>
                                  this.props.store.getPermission(35, 35, () => {
                                    this.handleUsersPermissionsModalOpen(
                                      account
                                    );
                                  })
                                }
                                disabled={account.status === 3 ? true : false}
                              >
                                {this.props.store.language.label748}
                              </Button>
                            </div>
                          </div>
                          <div>
                            <div data-tip data-for="info">
                              <Button
                                className="mt-1"
                                // icon="search-plus"
                                kind="link"
                                color="basic"
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                lead
                                space
                                onClick={() => {
                                  this.handleEyeModalOpen(account);
                                }}
                              >
                                {this.props.store.language.label729}
                              </Button>
                            </div>
                          </div>
                          <div>
                            <div data-tip data-for="edit">
                              <Button
                                className="mt-1"
                                // icon="pen"
                                kind="link"
                                color="basic"
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                lead
                                space
                                //onClick={() => {this.handleShowEditModal(account)}}
                                onClick={() =>
                                  this.props.store.getPermission(23, 23, () => {
                                    this.handleShowEditModal(account);
                                  })
                                }
                              >
                                {this.props.store.language.label140}
                              </Button>
                            </div>
                          </div>
                          <div>
                            <Button
                              className="mt-1"
                              // icon="trash"
                              kind="link"
                              color="basic"
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              lead
                              space
                              //onClick={() => {this.handleConfirmationStatusModalOpen(account)}}
                              onClick={() =>
                                this.props.store.getPermission(36, 36, () => {
                                  this.handleConfirmationStatusModalOpen(
                                    account
                                  );
                                })
                              }
                            >
                              {account.status === 3
                                ? this.props.store.language.label737
                                : this.props.store.language.label736}
                            </Button>
                          </div>
                          <div>
                            <Button
                              className="mt-1"
                              // icon="trash"
                              kind="link"
                              color="basic"
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              lead
                              space
                              //onClick={() => {this.handleShowDeleteModal(account)}}
                              onClick={() =>
                                this.props.store.getPermission(23, 23, () => {
                                  this.handleShowDeleteModal(account);
                                })
                              }
                            >
                              {this.props.store.language.label129}
                            </Button>
                          </div>
                        </div>
                      </DropdownPanel>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <Button
                  iconKasimu="arrow-right"
                  kind="link"
                  color="special"
                  size={this.props.store.viewMobile ? "lg" : "lg"}
                  lead
                  space
                  onClick={() =>
                    this.setState({
                      screen: { actions: true },
                      indice: indice,
                      account: account,
                      titleScreen: (
                        <div>
                          <Text
                            color="white"
                            size={"xxl"}
                            weight="bold"
                            lineBreak
                          >
                            {account.username}
                          </Text>
                          <div
                            className="is-row is-fullwidth"
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              color="white"
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="regular"
                              multiline
                            >
                              {this.getStatus(account)}
                            </Text>
                          </div>
                        </div>
                      ),
                    })
                  }
                />
              )}
            </div>
          </Column>
          {this.props.store.viewMobile && (
            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              {active && this.props.store.ui.showPlayer && (
                <div
                  className={"mx-1 mt-2"}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentProgressTime}
                  </Text>

                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentDuration}
                  </Text>
                </div>
              )}
              <div
                id={`waveform` + indice}
                style={{
                  height: active && this.props.store.ui.showPlayer ? 100 : 0,
                }}
              />
            </Column>
          )}
        </Columns>
      </Panel>
    );
  }

  renderRow = (accounts) => {
    const arrayAccounts = accounts.toArray();

    return (
      <div
        className="flex flex-col my-1"
        style={{ gap: this.props.store.viewMobile ? 6 : 3 }}
      >
        {arrayAccounts.length === 0 ? (
          <EmptyContent />
        ) : (
          arrayAccounts.map((account, idx) =>
            this.renderItemsRow(account, idx + 1)
          )
        )}
      </div>
    );
  };

  renderTitle = () => {
    const supervisors = this.props.store.getSupervisors();
    return (
      <React.Fragment>
        <Columns className={this.props.store.viewMobile ? "pb-4" : "pb-3"}>
          <Column
            isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
            className="flex"
            style={{
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 14,
            }}
          >
            <div
              className="flex"
              style={{
                flexWrap: "wrap",
                gap: 14,
              }}
            >
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                lineBreak
                lead
                weight="black"
                className="mr-2"
              >
                {this.props.store.language.label17}
              </Text>
              {!supervisors && (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  weight="medium"
                  className="px-3"
                  kind="outline"
                  color="basic"
                  onClick={() => {
                    this.handleGroupsModalOpen();
                  }}
                >
                  <strong>{this.props.store.language.label36}</strong>
                </Button>
              )}
              {this.props.store.loggedInLicenses && (
                <Button
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  weight="medium"
                  className="px-2 mx-0"
                  kind="outline"
                  color="basic"
                  //onClick={() => {this.handleUserLicensesModalOpen()}}
                  onClick={() =>
                    this.props.store.getPermission(24, 24, () => {
                      this.handleUserLicensesModalOpen();
                    })
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  <strong>{this.props.store.language.label769}</strong>
                </Button>
              )}
              <Button
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-3 mx-0"
                kind="outline"
                color="basic"
                onClick={() => {
                  this.handleUserMoreOptionsModalOpen();
                }}
              >
                <strong>{this.props.store.language.label720}</strong>
              </Button>
            </div>

            <div
              className="flex items-center"
              style={{ flexWrap: "wrap", gap: "20px" }}
            >
              <Button
                className="px-3"
                size={this.props.store.viewMobile ? "lg" : "md"}
                //onClick={this.handleShowNewModal}
                onClick={() =>
                  this.props.store.getPermission(23, 23, () => {
                    this.handleShowNewModal();
                  })
                }
              >
                <strong>{this.props.store.language.label49}</strong>
              </Button>
            </div>
          </Column>
        </Columns>
      </React.Fragment>
    );
  };

  renderAccounts = () => {
    const { accounts, groups } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && this.renderTitle()}
            {this.getPanel(groups)}
            <Text
              className="is-pulled-right mr-1"
              size={this.props.store.viewMobile ? "md" : "sm"}
              maxLength={this.props.mobile ? 50 : 250}
            >
              {this.props.store.language.label642 + accounts.count}
            </Text>
            {this.renderRow(accounts)}
          </Column>
        </Columns>
        {this.renderPaginator(accounts)}
        {this.props.store.viewMobile && (
          <ReactTooltip id="pf" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label743}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="pp" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label37}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="pa" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label39}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="clone" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label112}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="mas" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label720}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="confi" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label23}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="per" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label38}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="info" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label721}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip id="edit" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label140}</span>
          </ReactTooltip>
        )}
      </React.Fragment>
    );
  };

  renderScreenActions() {
    const { account, indice } = this.state;
    var last_playlist_listened = this.getLastPlaylistListened(account);
    var last_song_listened = this.getLastSongListened(account);
    var playback_status = this.getPlaybackStatus(account);
    const currentSongPath = this.props.store.ui.currentSongPath;
    const active =
      currentSongPath && last_song_listened
        ? last_song_listened.song.song_path == currentSongPath
        : null;
    const { handleStartPlayingClick, handleTogglePlay } = this;
    const supervisors = this.props.store.getSupervisors();

    const actions = supervisors
      ? [
          {
            text: this.props.store.language.label743,
            onClick: (event) => {
              //this.handlePlaylistFavoriteModalOpen(event)
              this.props.store.getPermission(26, 26, () => {
                this.handlePlaylistFavoriteModalOpen(event);
              });
            },
            icon: "star",
          },
          {
            text: this.props.store.language.label37,
            onClick: (event) => {
              //this.handlePlaylistPlannerModalOpen(event)
              this.props.store.getPermission(28, 28, () => {
                this.handlePlaylistPlannerModalOpen(event);
              });
            },
            iconKasimu: "playlist-round",
          },
          {
            text: this.props.store.language.label39,
            onClick: (event) => {
              //this.handleAdsPlanerModalOpen(event)
              this.props.store.getPermission(30, 30, () => {
                this.handleAdsPlanerModalOpen(event);
              });
            },
            iconKasimu: "ads-round",
          },
          {
            text: this.props.store.language.label23,
            onClick: (event) => {
              //this.handleUsersPreferencesModalOpen(event)
              this.props.store.getPermission(33, 33, () => {
                this.handleUsersPreferencesModalOpen(event);
              });
            },
            iconKasimu: "setting",
          },
          {
            text: this.props.store.language.label38,
            onClick: (event) => {
              //this.handleUsersPersonalizationModalOpen(event)
              this.props.store.getPermission(34, 34, () => {
                this.handleUsersPersonalizationModalOpen(event);
              });
            },
            iconKasimu: "user-solid",
          },
          {
            text: this.props.store.language.label748,
            onClick: (event) => {
              //this.handleUsersPermissionsModalOpen(event)
              this.props.store.getPermission(35, 35, () => {
                this.handleUsersPermissionsModalOpen(event);
              });
            },
            icon: "ban",
          },
          {
            text: this.props.store.language.label729,
            onClick: (event) => this.handleEyeModalOpen(event),
            iconKasimu: "eye-solid-white",
          },
          {
            text: this.props.store.language.label140,
            onClick: (event) => {
              //this.handleShowEditModal(event)
              this.props.store.getPermission(23, 23, () => {
                this.handleShowEditModal(event);
              });
            },
            iconKasimu: "pen-solid-white",
          },
          {
            text:
              account.status === 3
                ? this.props.store.language.label737
                : this.props.store.language.label736,
            onClick: (event) => {
              //this.handleConfirmationStatusModalOpen(event)
              this.props.store.getPermission(36, 36, () => {
                this.handleConfirmationStatusModalOpen(event);
              });
            },
            icon: account.status === 3 ? "user-check" : "user-slash",
          },
          {
            text: this.props.store.language.label129,
            onClick: (event) => {
              //this.handleShowDeleteModal(event)
              this.props.store.getPermission(23, 23, () => {
                this.handleShowDeleteModal(event);
              });
            },
            iconKasimu: "trash-solid",
          },
        ]
      : [
          {
            text: this.props.store.language.label743,
            onClick: (event) => {
              //this.handlePlaylistFavoriteModalOpen(event)
              this.props.store.getPermission(26, 26, () => {
                this.handlePlaylistFavoriteModalOpen(event);
              });
            },
            icon: "star",
          },
          {
            text: this.props.store.language.label37,
            onClick: (event) => {
              //this.handlePlaylistPlannerModalOpen(event)
              this.props.store.getPermission(28, 28, () => {
                this.handlePlaylistPlannerModalOpen(event);
              });
            },
            iconKasimu: "playlist-round",
          },
          {
            text: this.props.store.language.label39,
            onClick: (event) => {
              //this.handleAdsPlanerModalOpen(event)
              this.props.store.getPermission(30, 30, () => {
                this.handleAdsPlanerModalOpen(event);
              });
            },
            iconKasimu: "ads-round",
          },
          {
            text: this.props.store.language.label112,
            onClick: (event) => this.handleUsersCloneModalOpen(event),
            iconKasimu: "clone-solid",
          },
          {
            text: this.props.store.language.label23,
            onClick: (event) => {
              //this.handleUsersPreferencesModalOpen(event)
              this.props.store.getPermission(33, 33, () => {
                this.handleUsersPreferencesModalOpen(event);
              });
            },
            iconKasimu: "setting",
          },
          {
            text: this.props.store.language.label38,
            onClick: (event) => {
              //this.handleUsersPersonalizationModalOpen(event)
              this.props.store.getPermission(34, 34, () => {
                this.handleUsersPersonalizationModalOpen(event);
              });
            },
            iconKasimu: "user-solid",
          },
          {
            text: this.props.store.language.label748,
            onClick: (event) => {
              //this.handleUsersPermissionsModalOpen(event)
              this.props.store.getPermission(35, 35, () => {
                this.handleUsersPermissionsModalOpen(event);
              });
            },
            icon: "ban",
          },
          {
            text: this.props.store.language.label729,
            onClick: (event) => this.handleEyeModalOpen(event),
            iconKasimu: "eye-solid-white",
          },
          {
            text: this.props.store.language.label140,
            onClick: (event) => {
              //this.handleShowEditModal(event)
              this.props.store.getPermission(23, 23, () => {
                this.handleShowEditModal(event);
              });
            },
            iconKasimu: "pen-solid-white",
          },
          {
            text:
              account.status === 3
                ? this.props.store.language.label737
                : this.props.store.language.label736,
            onClick: (event) => {
              //this.handleConfirmationStatusModalOpen(event)
              this.props.store.getPermission(36, 36, () => {
                this.handleConfirmationStatusModalOpen(event);
              });
            },
            icon: account.status === 3 ? "user-check" : "user-slash",
          },
          {
            text: this.props.store.language.label129,
            onClick: (event) => {
              //this.handleShowDeleteModal(event)
              this.props.store.getPermission(23, 23, () => {
                this.handleShowDeleteModal(event);
              });
            },
            iconKasimu: "trash-solid",
          },
        ];
    return (
      <Screen
        onBack={() => {
          this.setState({ screen: { ...this.state.screen, actions: false } });
          this.props.store.ui.destroy();
        }}
        title={this.state.titleScreen}
      >
        <div
          className="flex flex-col my-1"
          style={{ gap: this.props.store.viewMobile ? 6 : 3 }}
        >
          <Panel
            color={"gray"}
            padding={
              this.props.store.viewMobile
                ? active
                  ? "16px 30px 0"
                  : "16px 30px"
                : active
                ? "14px 10px 0"
                : "14px 10px"
            }
            style={{ borderRadius: !this.props.store.viewMobile && 5 }}
          >
            {last_playlist_listened && (
              <div
                className="flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Img
                    height="35px"
                    width="35px"
                    src={this.getIconPath(account)}
                  />
                </div>

                <div
                  className="flex"
                  style={{ flexDirection: "column", flex: "auto" }}
                >
                  <Text
                    className="ml-2"
                    color="white"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                    multiline
                  >
                    {this.getPlaylistsName(account)}
                  </Text>

                  {last_song_listened && (
                    <Text
                      className="ml-2"
                      color="special"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      multiline
                    >
                      {last_song_listened.song.name +
                        " - " +
                        last_song_listened.song.singer.name}
                    </Text>
                  )}
                </div>
                {last_song_listened && (
                  <Button
                    icon={
                      active && this.props.store.ui.isPlaying ? "pause" : "play"
                    }
                    kind="link"
                    color={active ? "primary" : "basic"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    padding="0"
                    onClick={
                      active && this.props.store.ui.showPlayer
                        ? () =>
                            handleTogglePlay(last_song_listened.song.song_path)
                        : () =>
                            handleStartPlayingClick(
                              indice,
                              last_song_listened.song.song_path
                            )
                    }
                  />
                )}
                {playback_status && (
                  <div className="flex items-center">
                    <Text
                      color="special"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      multiline
                    >
                      {playback_status}
                    </Text>
                  </div>
                )}
              </div>
            )}

            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              {active && this.props.store.ui.showPlayer && (
                <div
                  className={"mx-1 mt-1"}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentProgressTime}
                  </Text>

                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00 / 00:00"
                      : this.props.store.ui.currentDuration &&
                        this.props.store.ui.currentProgressTime &&
                        this.props.store.ui.currentDuration}
                  </Text>
                </div>
              )}
              <div
                id={`waveform` + indice}
                style={{
                  height: active && this.props.store.ui.showPlayer ? 100 : 0,
                }}
              />
            </Column>
          </Panel>
          {actions.map((action, idx) => (
            <Panel
              id={idx + 1}
              color={"gray"}
              padding={this.props.store.viewMobile ? "16px 30px" : "14px 10px"}
              style={{ borderRadius: !this.props.store.viewMobile && 5 }}
            >
              <React.Fragment>
                <Columns isMobile isVCentered isMarginless isGapless>
                  <Column isSize={{ mobile: 6, desktop: 10, tablet: 10 }}>
                    <Columns>
                      <Column isSize={{ mobile: 12, desktop: 6, tablet: 6 }}>
                        <div className="flex items-center">
                          <div
                            className="flex items-center"
                            style={{
                              background: "white",
                              borderRadius: 5,
                              marginRight: 12,
                            }}
                          >
                            <Button
                              icon={action.icon || null}
                              iconKasimu={action.iconKasimu || null}
                              kind="link"
                              color="black"
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              padding="6px 8px"
                            />
                          </div>

                          <div>
                            <Text
                              color="white"
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="medium"
                              lineBreak
                            >
                              {action.text}
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Columns>
                  </Column>
                  <Column isSize={{ mobile: 6, desktop: 2, tablet: 2 }}>
                    <div className="is-row" style={{ float: "right" }}>
                      <Button
                        iconKasimu="arrow-right"
                        kind="link"
                        color="special"
                        size={this.props.store.viewMobile ? "lg" : "lg"}
                        lead
                        space
                        onClick={() => action.onClick(account)}
                      />
                    </div>
                  </Column>
                </Columns>
              </React.Fragment>
            </Panel>
          ))}
        </div>
      </Screen>
    );
  }

  render() {
    moment.updateLocale("es", {
      months: this.props.store.language.label59.split(","),
      monthsShort: this.props.store.language.label60.split(","),
      weekdays: this.props.store.language.label61.split(","),
      weekdaysShort: this.props.store.language.label62.split(","),
      weekdaysMin: this.props.store.language.label63.split(","),
    });
    const {
      groups,
      showEditModal,
      showDeleteModal,
      currentAccounts,
      showNewModal,
      showEyeModal,
      showPlaylistFavorite,
      showPlaylistPlanner,
      showAdsPlanerModal,
      showUsersCloneModal,
      showUsersPreferencesModal,
      showUsersPersonalizationModal,
      showUsersPermissionsModal,
      showGroupsModal,
      showUserMoreOptionsModal,
      showUserLicensesModal,
      showConfirmationStatusModal,
    } = this.state;
    const grupsOptions = this.getOptions(groups);
    return (
      <React.Fragment>
        {this.state.screen.actions && this.renderScreenActions()}
        {this.renderHeader()}
        {showGroupsModal && (
          <GroupsModal
            groups={groups}
            onReload={this.reload}
            onClose={this.handleGroupsModalClose}
          />
        )}
        {showUserLicensesModal && (
          <UserLicensesModal onClose={this.handleUserLicensesModalClose} />
        )}
        {showUserMoreOptionsModal && (
          <UserMoreOptionsModal
            groups={grupsOptions}
            onClose={this.handleUserMoreOptionsModalClose}
          />
        )}
        {showEditModal && (
          <AccountEditModal
            account={currentAccounts}
            groups={grupsOptions}
            onClose={this.handleHideEditModal}
            onSave={this.handleAccountEditModalSave}
          />
        )}
        {showDeleteModal && (
          <AccountDeleteModal
            account={currentAccounts}
            onClose={this.handleHideDeleteModal}
            onAccept={this.handleDeleteAccounts}
          />
        )}
        {showNewModal && (
          <AccountNewModal
            groups={grupsOptions}
            onClose={this.handleAccountNewModalClose}
            onSave={this.handleAccountNewModalSave}
          />
        )}
        {showPlaylistFavorite && (
          <PlaylistsFavoriteModal
            account={currentAccounts}
            onClose={this.handlePlaylistFavoriteModalClose}
          />
        )}
        {showPlaylistPlanner && (
          <PlaylistsPlannerModal
            account={currentAccounts}
            onClose={this.handlePlaylistPlannerModalClose}
          />
        )}
        {showAdsPlanerModal && (
          <AdsPlanerModal
            account={currentAccounts}
            onClose={this.handleAdsPlanerModalClose}
          />
        )}
        {showUsersCloneModal && (
          <UsersCloneModal
            account={currentAccounts}
            onClose={this.handleUsersCloneModalClose}
          />
        )}
        {showUsersPreferencesModal && (
          <UsersPreferencesModal
            account={currentAccounts}
            onClose={this.handleUsersPreferencesModalClose}
          />
        )}
        {showUsersPersonalizationModal && (
          <UsersPersonalizationModal
            account={currentAccounts}
            onClose={this.handleUsersPersonalizationModalClose}
          />
        )}
        {showUsersPermissionsModal && (
          <UsersPermissionsModal
            account={currentAccounts}
            onClose={this.handleUsersPermissionsModalClose}
          />
        )}
        {showEyeModal && (
          <AccountEyeModal
            account={currentAccounts}
            onClose={this.handleEyeModalClose}
          />
        )}
        {showConfirmationStatusModal && (
          <ConfirmationModal
            txtButton={
              currentAccounts.status === 1
                ? this.props.store.language.label736
                : this.props.store.language.label737
            }
            title={
              currentAccounts.status === 1
                ? this.props.store.language.label744 +
                  " " +
                  currentAccounts.username
                : this.props.store.language.label745 +
                  " " +
                  currentAccounts.username
            }
            onClose={this.handleConfirmationStatusModalClose}
            onAccept={() =>
              this.updateStatus(currentAccounts.status === 1 ? 3 : 1)
            }
            subtitle={
              currentAccounts.status == 1
                ? this.props.store.language.label746
                : this.props.store.language.label747
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default withToastManager(withStore(UsersListPage));
