import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Radio,
  RadioGroup,
  DateTimePicker,
  Paragraph,
} from "ks_storybook";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";
import { PPCalendarClocks } from "../../models";
import moment from "moment";

@observer
class PPAssignSpecialClockModal extends Component {
  @observable ppCalendarClocksNew;

  constructor(props) {
    super(props);

    this.state = {
      messageError: null,
      isSaving: false,
      radioValue: 1,
      name: null,
      start_date: new Date(),
      end_date: this.getNewDatePlusDay(),
      exact_day: new Date(),
      repetitions: 0,
      blocks: this.getBlocks(),
    };

    this.grid = 4;
    this.ppCalendarClocksNew = this.newPPCalendarClocks();
  }

  newPPCalendarClocks = () => {
    const ppCalendarClocksStore = this.props.store.ppCalendarClocks;
    const attrs = {
      blocks: [],
      name: null,
      start_date: null,
      end_date: null,
      exact_day: null,
      repetitions: 0,
      day_type: 2,
      origin_type: 1,
      deleted: 0,
    };
    return new PPCalendarClocks(attrs, ppCalendarClocksStore);
  };

  getNewDatePlusDay = () => {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  valid = (num) => {
    switch (num) {
      case 0:
        return "00";
      case 1:
        return "01";
      case 2:
        return "02";
      case 3:
        return "03";
      case 4:
        return "04";
      case 5:
        return "05";
      case 6:
        return "06";
      case 7:
        return "07";
      case 8:
        return "08";
      case 9:
        return "09";
      default:
        return num;
    }
  };

  getBlocks = () => {
    let items = [];
    for (let i = 0; i <= 23; i++) {
      items.push({
        start_hours: this.valid(i) + ":00",
        end_hours: this.valid(i) + ":59",
        pp_clocks_id: 0,
      });
    }
    return items;
  };

  getOptions(obj) {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validateExactDay = (strNewDate, strExactDay, newRepetitions, repetitions) => {
    var exactDay = moment(strExactDay, "DD/MM/YYYY");
    var compareDate = moment(strNewDate, "DD/MM/YYYY");
    if (compareDate.diff(exactDay, "days") == 0) return true;
    if (compareDate.day() == exactDay.day()) {
      var endDate1 = moment(strExactDay, "DD/MM/YYYY").add(
        repetitions * 7,
        "days"
      );
      if (this.validateRank(compareDate, exactDay, endDate1)) {
        return true;
      } else {
        var tempDate = moment(strNewDate, "DD/MM/YYYY").add(
          newRepetitions * 7,
          "days"
        );
        if (this.validateRank(tempDate, exactDay, endDate1)) {
          return true;
        } else return this.validateRank(exactDay, compareDate, endDate1);
      }
    } else return false;
  };

  validateRank = (strNewDate, strStartDate, strEndDate) => {
    var compareDate = moment(strNewDate, "DD/MM/YYYY");
    var startDate = moment(strStartDate, "DD/MM/YYYY");
    var endDate = moment(strEndDate, "DD/MM/YYYY");
    if (
      compareDate.diff(startDate, "days") == 0 ||
      compareDate.diff(endDate, "days") == 0
    )
      return true;
    return compareDate.isBetween(startDate, endDate);
  };

  validateRankAndRepetitions = (
    strNewDate,
    strStartDate,
    strEndDate,
    newRepetitions
  ) => {
    var newDate = moment(strNewDate, "DD/MM/YYYY");
    var endDate = moment(strEndDate, "DD/MM/YYYY");
    if (newDate.diff(endDate, "days") == 0) return true;
    if (this.validateRank(strNewDate, strStartDate, strEndDate)) {
      return true;
    } else {
      var strTempDate = moment(strNewDate, "DD/MM/YYYY")
        .add(newRepetitions * 7, "days")
        .format("DD/MM/YYYY");
      if (this.validateRank(strTempDate, strStartDate, strEndDate)) {
        return true;
      } else this.validateRank(strStartDate, strNewDate, strTempDate);
    }
  };

  validatePunctualDay = (specialDays, ppCalendarClocksNew) => {
    var bander = [false];
    bander = specialDays.map((o) =>
      o.exact_day == null
        ? this.validateRankAndRepetitions(
            ppCalendarClocksNew.exact_day,
            o.start_date,
            o.end_date,
            ppCalendarClocksNew.repetitions
          )
        : this.validateExactDay(
            ppCalendarClocksNew.exact_day,
            o.exact_day,
            ppCalendarClocksNew.repetitions,
            o.repetitions
          )
    );
    return bander[0];
  };

  validateFourRanks = (strNewStart, strNewEnd, strStartDate, strEndDate) => {
    if (this.validateRank(strNewStart, strStartDate, strEndDate)) {
      return true;
    } else {
      if (this.validateRank(strNewEnd, strStartDate, strEndDate)) {
        return true;
      } else return this.validateRank(strStartDate, strNewStart, strNewEnd);
    }
  };

  validateRanksStartAndEnd = (specialDays, ppCalendarClocksNew) => {
    var bander = [false];
    bander = specialDays.map((o) =>
      o.exact_day != null
        ? this.validateRankAndRepetitions(
            o.exact_day,
            ppCalendarClocksNew.start_date,
            ppCalendarClocksNew.end_date,
            o.repetitions
          )
        : this.validateFourRanks(
            ppCalendarClocksNew.start_date,
            ppCalendarClocksNew.end_date,
            o.start_date,
            o.end_date
          )
    );
    return bander[0];
  };

  validteStartHigherEnd = (strStartDate, strEndDate) => {
    var startDate = moment(strStartDate, "DD/MM/YYYY");
    var endDate = moment(strEndDate, "DD/MM/YYYY");
    if (endDate.diff(startDate, "days") < 0) {
      return true;
    } else return false;
  };

  handleSave = () => {
    const { toastManager, specialDays } = this.props;
    const {
      name,
      start_date,
      end_date,
      exact_day,
      repetitions,
      radioValue,
      messageError,
    } = this.state;
    var saveOn = true;
    this.ppCalendarClocksNew.name = name;
    this.ppCalendarClocksNew.blocks = JSON.stringify(this.state.blocks);
    if (radioValue == 1) {
      this.ppCalendarClocksNew.start_date =
        moment(start_date).format("DD/MM/YYYY");
      this.ppCalendarClocksNew.end_date = moment(end_date).format("DD/MM/YYYY");
      this.ppCalendarClocksNew.exact_day = null;
      this.ppCalendarClocksNew.repetitions = 0;
      if (specialDays.length != 0) {
        if (
          this.validteStartHigherEnd(
            this.ppCalendarClocksNew.start_date,
            this.ppCalendarClocksNew.end_date
          )
        ) {
          this.setState({ messageError: this.props.store.language.label467 });
          saveOn = false;
        } else {
          if (
            this.validateRanksStartAndEnd(specialDays, this.ppCalendarClocksNew)
          ) {
            this.setState({ messageError: this.props.store.language.label466 });
            saveOn = false;
          }
        }
      }
    } else {
      this.ppCalendarClocksNew.start_date = null;
      this.ppCalendarClocksNew.end_date = null;
      this.ppCalendarClocksNew.exact_day =
        moment(exact_day).format("DD/MM/YYYY");
      this.ppCalendarClocksNew.repetitions = repetitions;
      if (specialDays.length != 0) {
        if (this.validatePunctualDay(specialDays, this.ppCalendarClocksNew)) {
          this.setState({ messageError: this.props.store.language.label465 });
          saveOn = false;
        }
      }
    }

    if (saveOn) {
      this.setState({ isSaving: true }, () => {
        this.ppCalendarClocksNew.save().andThen((res, responseError) => {
          if (responseError) {
            toastManager.add(this.props.store.language.label464, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            toastManager.add(this.props.store.language.label463, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          }
        });
      });
    }
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "500px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  getListStyle = () => ({
    background: "#e8e8e8",
    padding: this.grid,
  });

  getItemStyle = () => ({
    userSelect: "none",
    position: "static",
    padding: "12px 24px",
    margin: "0 0 8px 0",
    alignItems: "flex-start",
    alignContent: "flex-start",
    borderRadius: "3px",
    borderRadius: this.grid * 2,
    background: "#e8e8e8",
  });

  handleChangeFiled = (sender, value, name, validation) => {
    this.setState({ messageError: null, name: value });
  };

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleChangeFiled = (sender, value, name, validation) => {
    switch (name) {
      case "name":
        if (value != null) {
          if (this.validLength(value)) {
            this.setState({ messageError: null, name: value });
          } else {
            this.setState({ messageError: this.props.store.language.label462 });
          }
        } else {
          this.setState({ messageError: this.props.store.language.label462 });
        }
        break;
      case "start_date":
        this.setState({ start_date: value, messageError: null });
        break;
      case "end_date":
        this.setState({ end_date: value, messageError: null });
        break;
      case "exact_day":
        this.setState({ exact_day: value, messageError: null });
        break;
      case "repetitions":
        this.setState({ repetitions: value, messageError: null });
        break;
      default:
        break;
    }
  };

  handleChangeRadio = (sender, value) => {
    this.setState({ messageError: null, radioValue: value });
  };

  @computed
  get canSave() {
    if (!this.state.name) return false;
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label128}
            </Button>
          ) : (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              {this.props.store.language.label127}
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderRadio = () => {
    const { name, start_date, end_date, exact_day } = this.state;
    return (
      <React.Fragment>
        <Field
          label={this.props.store.language.label53}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          NoteSize="xs"
          upper
          labelNote={this.props.store.language.label76}
        >
          <TextInput
            className="is-fullwidth"
            name="name"
            value={name}
            onChange={this.handleChangeFiled}
            required
            autocomplete="nope"
            backgroundColor="grayLight"
            colorIcon="gray"
            color="black"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            invert
          />
        </Field>
        <RadioGroup
          group="sizes"
          size={this.props.store.viewMobile ? "md" : "sm"}
          onChange={this.handleChangeRadio}
          value="1"
          classNames="group"
        >
          <Radio
            size={this.props.store.viewMobile ? "md" : "sm"}
            value="1"
            class="radio"
            invert
            reversed
          >
            <Columns isMultiline>
              <Column
                isSize={{ mobile: 12, tablet: 12, desktop: 3 }}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                  {this.props.store.language.label361}
                </Text>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 9 }}>
                <Columns isMultiline>
                  <Column
                    className="is-row"
                    style={{
                      alignItems: "center",
                      marginLeft: this.props.store.viewMobile ? "-15px" : "0px",
                    }}
                    isSize={{ mobile: 12, tablet: 12, desktop: 6 }}
                  >
                    <div
                      style={{
                        marginRight: "5px",
                        minWidth: "50px",
                        textAlign: "end",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                        {this.props.store.language.label386}
                      </Text>
                    </div>
                    <DateTimePicker
                      name="start_date"
                      value={moment(start_date, "DD/MM/YYYY").toDate()}
                      dateFormat="DD/MM/YYYY"
                      onChange={this.handleChangeFiled}
                      disabled={this.state.radioValue == 1 ? false : true}
                      iconKasimu="arrow-down"
                      reversed
                      invert
                      backgroundColor="#ffffff"
                      colorIcon="#141414"
                      color="black"
                      fullWidth
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      className="is-fullwidth"
                      backgroundColor="grayLight"
                    />
                  </Column>
                  <Column
                    className="is-row"
                    style={{
                      alignItems: "center",
                      marginLeft: this.props.store.viewMobile ? "-15px" : "0px",
                    }}
                    isSize={{ mobile: 12, tablet: 12, desktop: 6 }}
                  >
                    <div
                      style={{
                        marginRight: "5px",
                        minWidth: "50px",
                        textAlign: "end",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                        {this.props.store.language.label387}
                      </Text>
                    </div>
                    <DateTimePicker
                      name="end_date"
                      value={moment(end_date, "DD/MM/YYYY").toDate()}
                      dateFormat="DD/MM/YYYY"
                      onChange={this.handleChangeFiled}
                      disabled={this.state.radioValue == 1 ? false : true}
                      iconKasimu="arrow-down"
                      reversed
                      invert
                      backgroundColor="#ffffff"
                      colorIcon="#141414"
                      color="black"
                      fullWidth
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      className="is-fullwidth"
                      backgroundColor="grayLight"
                    />
                  </Column>
                </Columns>
              </Column>
            </Columns>
          </Radio>
          <div className="my-1" />
          <Radio
            size={this.props.store.viewMobile ? "md" : "sm"}
            value="2"
            class="radio"
            invert
            reversed
          >
            <Columns isMultiline>
              <Column
                className="is-row"
                style={{ alignItems: "center" }}
                isSize={{ mobile: 12, tablet: 12, desktop: 6 }}
              >
                <div style={{ marginRight: "20px" }}>
                  <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                    {this.props.store.language.label460}
                  </Text>
                </div>
                <DateTimePicker
                  name="exact_day"
                  value={moment(exact_day, "DD/MM/YYYY").toDate()}
                  dateFormat="DD/MM/YYYY"
                  onChange={this.handleChangeFiled}
                  disabled={this.state.radioValue == 2 ? false : true}
                  iconKasimu="arrow-down"
                  reversed
                  invert
                  backgroundColor="#ffffff"
                  colorIcon="#141414"
                  color="black"
                  fullWidth
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="is-fullwidth"
                  backgroundColor="grayLight"
                />
              </Column>
              <Column
                className="is-row"
                style={{ alignItems: "center" }}
                isSize={{ mobile: 12, tablet: 12, desktop: 6 }}
              >
                <div style={{ marginRight: "20px" }}>
                  <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                    {this.props.store.language.label459}
                  </Text>
                </div>
                <TextInput
                  type="number"
                  className="is-fullwidth"
                  name="repetitions"
                  value={0}
                  onChange={this.handleChangeFiled}
                  minlength={0}
                  maxlength={60}
                  autocomplete="nope"
                  disabled={this.state.radioValue == 2 ? false : true}
                  backgroundColor="grayLight"
                  color="black"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  invert
                />
              </Column>
            </Columns>
          </Radio>
        </RadioGroup>
        <div className="my-2" />
        <div style={{ maxWidth: "540px" }} className="my-1">
          <Paragraph size={this.props.store.viewMobile ? "xl" : "lg"} lineBreak>
            {this.props.store.language.label454}
          </Paragraph>
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { blocks } = this.state;
    return (
      <React.Fragment>
        {this.renderRadio()}
        <div style={this.getContainerStyle()}>
          {blocks.map((item, index) => (
            <div style={this.getItemStyle()}>
              {
                <Columns isMultiline>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                        {item.start_hours + " - " + item.end_hours}
                      </Text>
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                    <Select
                      onChange={(value, sender) => {
                        const val = !value ? 0 : value.value;
                        const newBlocks = blocks;
                        newBlocks[index].pp_clocks_id = parseInt(val);
                        this.setState({ blocks: newBlocks });
                      }}
                      isClearable
                      options={this.getOptions(this.props.ppClocks)}
                      className="is-fullwidth"
                      backgroundColor="#FFFFFF"
                      separatorColor="#FFFFFF"
                      iconColor="black"
                      textColor="black"
                      placeholderColor="black"
                      placeholder={this.props.store.language.label455}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      isMobile={!this.props.store.viewMobile}
                    />
                  </Column>
                </Columns>
              }
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "60%" : "90%"}
        height={this.props.store.viewMobile ? "90%" : "auto"}
        invert
      >
        <ModalHeader>
          <Text
            upper
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
          >
            {this.props.store.language.label458}
          </Text>
        </ModalHeader>
        <ModalContent>
          {this.renderHeader()}
          {this.state.messageError && (
            <>
              <br />
              <Panel color="error" className="mt-2" invert>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  multiline
                >
                  {this.state.messageError}
                </Text>
              </Panel>
            </>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}
PPAssignSpecialClockModal.propTypes = {
  specialDays: PropTypes.object,
  ppClocks: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PPAssignSpecialClockModal.defaultProps = {
  specialDays: null,
  ppClocks: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PPAssignSpecialClockModal));
