import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class Toast extends Component {
  componentDidMount() {
    const { toastManager } = this.props;
    this.props.store.toastManager = toastManager;
  }

  render() {
    return null;
  }
}

export default withToastManager(withStore(Toast));
