import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Text,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";

@observer
class SignOutModal extends Component {
  constructor(props) {
    super(props);
  }

  handleAccept = () => {
    this.props.onAccept && this.props.onAccept();
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderButtons() {
    return (
      <div>
        <Button
          className="px-3"
          onClick={this.handleAccept}
          size={this.props.store.viewMobile ? "lg" : "md"}
          weight="bold"
        >
          <strong>{this.props.store.language.label85}</strong>
        </Button>
        <Button
          className="px-3"
          onClick={this.handleClose}
          size={this.props.store.viewMobile ? "lg" : "md"}
          weight="bold"
        >
          <strong>{this.props.store.language.label86}</strong>
        </Button>
      </div>
    );
  }

  render() {
    return (
      <Modal
        show
        width={this.props.store.viewMobile ? "380px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
        onClose={this.handleClose}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
              className="text-center"
            >
              {this.props.store.language.label732}
            </Text>

            <br />
            {this.renderButtons()}
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

SignOutModal.propTypes = {};

SignOutModal.defaultProps = {};

export default withStore(SignOutModal);
