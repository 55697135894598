import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../../../hocs/withStore";
import {
  Text,
  Field,
  TextInput,
  Button,
  Paragraph,
  Panel,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent } from "../../../../Components/Common";
import UserPersonalizationDeletedModal from "./UserPersonalizationDeletedModal";
import { Select } from "../../../../Components/Select";
import { UserPersonalization } from "../../../../models";
import moment from "moment";

@observer
class UserPersonalizationPage extends Component {
  @observable userPersonalization;

  constructor(props) {
    super(props);

    this.state = {
      accounts: this.getAccounts(),
      userId: this.props.store.getSingleUser()
        ? this.props.store.loggedInUser.id
        : null,
      currentUserPersonalization: this.props.store.getSingleUser()
        ? this.getUserPerson(this.props.store.loggedInUser.id)
        : null,
      isNull: true,
      isSaving: false,
      messageError: null,
      userPersonalizationEdit: null,
      showDeletedModal: false,
      showDeletedAllModal: false,
      file: null,
      name: "",
      size: 0,
    };

    this.personalization = null;
    this.userPersonalization = this.newUserPersonalization();
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label38;
  }

  getAccounts = () => {
    return this.props.store.users.fetchAccounts();
  };

  getOptions = (accounts) => {
    return accounts
      .toArray()
      .map((account) => ({ value: account.id, label: account.username }));
  };

  handleUserChange = (value) => {
    const originId = value && value.value;
    if (originId) {
      this.setState({
        userId: originId,
        currentUserPersonalization: this.getUserPerson(originId),
        file: null,
        name: "",
        size: 0,
      });
    } else {
      this.setState({
        userId: null,
        currentUserPersonalization: null,
        file: null,
        name: "",
        size: 0,
      });
    }
    this.userPersonalization = this.newUserPersonalization();
  };

  getUserPerson = (value) => {
    if (value)
      return this.props.store.userPersonalization.getPersonalizationUser(value);
    return null;
  };

  newUserPersonalization() {
    const userPersonalizationStore = this.props.store.userPersonalization;
    const attrs = {
      user_id: 0,
      company_name: "",
      text_one: "",
      text_two: "",
      text_three: "",
      avatar_path: "",
      origin_type: 2,
      deleted: 0,
    };
    return new UserPersonalization(attrs, userPersonalizationStore);
  }

  handleDeletedOpen = () => {
    this.setState({ showDeletedModal: true });
  };

  handleDeletedAllOpen = () => {
    this.setState({ showDeletedAllModal: true });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedAllModal: false, showDeletedModal: false });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.userPersonalization = this.newUserPersonalization();
    this.props.store.loadUserPersonalization();
    this.setState({
      isNull: true,
      isSaving: false,
      messageError: null,
      userPersonalizationEdit: this.getUserPerson(this.state.userId),
      showDeletedModal: false,
      showDeletedAllModal: false,
      file: null,
      name: "",
      size: 0,
    });
    this.personalization = null;
  };

  getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  handleFileChange = (e) => {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      //const user = this.props.store.loggedInUser;
      const fileName = file.name;
      const ext = this.getExtension(fileName);
      if (
        ext != "jpeg" &&
        ext != "JPEG" &&
        ext != "jpg" &&
        ext != "JPG" &&
        ext != "png" &&
        ext != "PNG"
      ) {
        this.setState({ messageError: this.props.store.language.label234 });
        return;
      }
      const lastDot = fileName.lastIndexOf(".");
      const name = fileName.substring(0, lastDot);
      let momenStr = moment(new Date()).format("YYYYMMDDHHmmss");
      this.userPersonalization.avatar_path = new File(
        [file],
        `${this.state.userId}-IMG-${momenStr}.${ext}`,
        { type: file.type }
      );
      const size = (file.size / 1048576).toFixed(4);
      this.setState({
        size: size,
        isNull: false,
        name: name,
        file: URL.createObjectURL(this.userPersonalization.avatar_path),
      });
    }
  };

  handleSave = () => {
    this.userPersonalization.user_id = this.state.userId;
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.userPersonalization.saveFormData().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label234, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add(this.props.store.language.label233, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.store.loadUserPersonalization();
          this.setState({
            isNull: true,
            isSaving: false,
            messageError: null,
            userPersonalizationEdit: this.getUserPerson(this.state.userId),
            showDeletedModal: false,
            showDeletedAllModal: false,
            file: null,
            name: "",
            size: 0,
          });
          this.userPersonalization = this.newUserPersonalization();
        }
      });
    });
  };

  handleSaveEdit = () => {
    const params = {
      user_id: this.state.userId,
      company_name: this.personalization[0].company_name
        ? this.personalization[0].company_name
        : "",
      text_one: this.personalization[0].text_one
        ? this.personalization[0].text_one
        : "",
      text_two: this.personalization[0].text_two
        ? this.personalization[0].text_two
        : "",
      text_three: this.personalization[0].text_three
        ? this.personalization[0].text_three
        : "",
      avatar_path: this.userPersonalization.avatar_path,
      origin_type: 2,
      deleted: 0,
    };
    const userPersonalizationStore = this.props.store.userPersonalization;
    const temp = new UserPersonalization(params, userPersonalizationStore);
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      temp.saveFormData().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label234, {
            appearance: "error",
            autoDismiss: true,
          });
          this.setState({ isSaving: false });
        } else {
          toastManager.add(this.props.store.language.label233, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.store.loadUserPersonalization();
          this.setState({
            isNull: true,
            isSaving: false,
            messageError: null,
            userPersonalizationEdit: this.getUserPerson(this.state.userId),
            showDeletedModal: false,
            showDeletedAllModal: false,
            file: null,
            name: "",
            size: 0,
          });
          this.userPersonalization = this.newUserPersonalization();
        }
      });
    });
  };

  handleSaveEditInfo = () => {
    const params = {
      user_id: this.state.userId,
      company_name: this.personalization[0].company_name,
      text_one: this.personalization[0].text_one,
      text_two: this.personalization[0].text_two,
      text_three: this.personalization[0].text_three,
      origin_type: 2,
      deleted: 0,
    };
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .putParams("/users_personalization/put_users_personalization", params)
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label233, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.store.loadUserPersonalization();
            this.setState({
              isNull: true,
              isSaving: false,
              messageError: null,
              userPersonalizationEdit: this.getUserPerson(this.state.userId),
              showDeletedModal: false,
              showDeletedAllModal: false,
              file: null,
              name: "",
              size: 0,
            });
            this.userPersonalization = this.newUserPersonalization();
          },
          (error) => {
            toastManager.add(this.props.store.language.label234, {
              appearance: "error",
              autoDismiss: true,
            });
            this.setState({ isSaving: false });
          }
        );
    });
  };

  handleChange = (sender, value, name, validation) => {
    switch (name) {
      case "companyName":
        this.userPersonalization.company_name = value;
        this.setState({ isNull: false });
        break;
      case "web":
        this.userPersonalization.text_one = value;
        this.setState({ isNull: false });
        break;
      case "textTwo":
        this.userPersonalization.text_two = value;
        this.setState({ isNull: false });
        break;
      case "textThree":
        this.userPersonalization.text_three = value;
        this.setState({ isNull: false });
        break;
      default:
        break;
    }
  };

  handleChangeEdit = (sender, value, name, validation) => {
    switch (name) {
      case "companyName":
        this.personalization[0].company_name = value;
        this.setState({ isNull: false });
        break;
      case "web":
        this.personalization[0].text_one = value;
        this.setState({ isNull: false });
        break;
      case "textTwo":
        this.personalization[0].text_two = value;
        this.setState({ isNull: false });
        break;
      case "textThree":
        this.personalization[0].text_three = value;
        this.setState({ isNull: false });
        break;
      default:
        break;
    }
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  @computed
  get canisNull() {
    if (this.state.isNull) return false;
    return true;
  }

  renderButtons() {
    return (
      <>
        {this.state.isSaving ? (
          <Button
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "my-2" : "my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            {this.props.store.language.label128}
          </Button>
        ) : (
          <Button
            disabled={!this.canSave || !this.canisNull}
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "my-2" : "my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onClick={this.handleSave}
          >
            <strong>{this.props.store.language.label127}</strong>
          </Button>
        )}
      </>
    );
  }

  renderButtonsEdit() {
    return (
      <>
        {this.state.isSaving ? (
          <Button
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "my-2" : "my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            {this.props.store.language.label81}
          </Button>
        ) : (
          <Button
            disabled={!this.canSave || !this.canisNull}
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "my-2" : "my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onClick={
              this.userPersonalization.avatar_path == "" ||
              this.userPersonalization.avatar_path == null
                ? this.handleSaveEditInfo
                : this.handleSaveEdit
            }
          >
            {this.props.store.language.label80}
          </Button>
        )}
      </>
    );
  }

  renderLogoEmpty = () => {
    return (
      <>
        {this.props.store.viewMobile ? (
          <div
            style={{
              minWidth: "140px",
              height: "140px",
              background: "#4A4949",
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderRadius: "7px",
            }}
          >
            {/* <Text
              color="special"
              lineBreak
              size="md"
              lead
              weight="strong"
            >
              {this.props.store.language.label131}
            </Text> */}
          </div>
        ) : (
          <div
            style={{
              minWidth: "80px",
              height: "80px",
              background: "#4A4949",
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderRadius: "7px",
            }}
          >
            {/* <Text upper color="white" lineBreak size="lg" weight="strong">
              {this.props.store.language.label131}
            </Text> */}
          </div>
        )}
      </>
    );
  };

  renderImg = (src) => {
    if (src === null) return this.renderLogoEmpty();
    return (
      <>
        {this.props.store.viewMobile ? (
          <img src={this.props.store.replaceBucketUrl(src)} style={{ width: "130px", height: "130px" }} />
        ) : (
          <img
            src={this.props.store.replaceBucketUrl(src)}
            style={{ width: "90px", height: "90px", borderRadius: 16 }}
          />
        )}
      </>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderSelect = (optionsSelect) => {
    return (
      <React.Fragment>
        <Panel
          className={this.props.store.viewMobile ? "my-2" : "my-1"}
          color="gray"
          padding={!this.props.store.viewMobile && "23px 20px"}
        >
          <div
            className="is-row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "100%" }}>
              <Field
                upper
                marginH={this.props.store.viewMobile && "10px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                label={this.props.store.language.label17}
                labelNote={this.props.store.language.label53}
              >
                <Select
                  onChange={this.handleUserChange}
                  placeholder={this.props.store.language.label175}
                  isClearable
                  options={optionsSelect}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </Field>
            </div>
            <div
              className="is-pulled-right mt-3 ml-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Button
                onClick={this.handleDeletedAllOpen}
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-3"
              >
                <strong>{this.props.store.language.label681}</strong>
              </Button>
            </div>
          </div>
        </Panel>
      </React.Fragment>
    );
  };

  renderPersonalization = () => {
    const { currentUserPersonalization, showDeletedModal } = this.state;
    if (currentUserPersonalization.length == 1)
      this.personalization = currentUserPersonalization.toArray();
    else this.personalization = null;
    return (
      <React.Fragment>
        <Columns isCentered isGapless className="is-multiline is-mobile">
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: this.props.store.viewMobile ? "100%" : "65%",
                  flex: !this.props.store.viewMobile && "1",
                }}
              >
                <Panel
                  className="my-1"
                  color="gray"
                  padding={
                    this.props.store.viewMobile ? "20px 28px" : "15px 20px"
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "md" : "sm"}
                        weight="strong"
                        lead
                        lineBreak
                      >
                        {"Logo"}
                      </Text>
                      <div className="my-1">
                        <Field
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          label={this.props.store.language.label116}
                          labelNoteOption={this.props.store.language.label117}
                        >
                          <Text
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            lineBreak
                          >
                            {this.props.store.language.label118}
                          </Text>
                        </Field>
                      </div>
                      <div
                        className="is-row mt-1"
                        style={{
                          flexWrap: "wrap",
                          flexDirection:
                            !this.props.store.viewMobile && "column",
                        }}
                      >
                        <div
                          style={{
                            margin: this.props.store.viewMobile
                              ? "0 20px 0 0"
                              : "0 0 10px 0",
                          }}
                        >
                          <Button
                            className="px-3"
                            size={this.props.store.viewMobile ? "lg" : "md"}
                          >
                            <label htmlFor="field">
                              <strong>
                                {this.props.store.language.label119}
                              </strong>
                            </label>
                            <input
                              name="field"
                              className="ks--fileUpload--input"
                              type="file"
                              onChange={this.handleFileChange}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                          </Button>
                        </div>
                        {this.personalization ? (
                          <div>
                            <Button
                              onClick={this.handleDeletedOpen}
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              className="px-3"
                            >
                              <strong>
                                {this.props.store.language.label120}
                              </strong>
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {this.props.store.viewMobile && (
                      <>
                        {" "}
                        {this.state.file
                          ? this.renderImg(this.state.file)
                          : this.personalization
                          ? this.renderImg(this.personalization[0].avatar_path)
                          : this.renderLogoEmpty()}
                      </>
                    )}
                  </div>
                </Panel>
              </div>
              {!this.props.store.viewMobile && (
                <div
                  style={{
                    maxWidth: "35%",
                    height: "auto",
                    paddingLeft: "7px",
                  }}
                >
                  <Panel className="my-1" color="gray" padding="0px">
                    {this.state.file
                      ? this.renderImg(this.state.file)
                      : this.personalization
                      ? this.renderImg(this.personalization[0].avatar_path)
                      : this.renderLogoEmpty()}
                  </Panel>
                </div>
              )}
            </div>

            <Panel
              className="my-1"
              color="gray"
              padding={this.props.store.viewMobile ? "28px" : "20px"}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: this.props.store.viewMobile ? "center" : "",
                  flexDirection: this.props.store.viewMobile ? "row" : "column",
                }}
                className="mb-1"
              >
                <Text
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  weight="strong"
                  lead
                  lineBreak
                >
                  {this.props.store.language.label121}
                </Text>
                <Text
                  className={this.props.store.viewMobile && "px-1"}
                  color="special"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  lineBreak
                >
                  {this.props.store.language.label122}
                </Text>
              </div>
              <br />
              <Field
                marginH={this.props.store.viewMobile && "4px"}
                label={this.props.store.language.label123}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNoteOption={this.props.store.language.label78}
                weight="medium"
              >
                <TextInput
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  paddingInput="20px"
                  autocomplete="nope"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  name="companyName"
                  value={
                    this.personalization
                      ? this.personalization[0].company_name
                      : ""
                  }
                  onChange={
                    this.personalization
                      ? this.handleChangeEdit
                      : this.handleChange
                  }
                />
              </Field>
              <Field
                marginH={this.props.store.viewMobile && "4px"}
                label={this.props.store.language.label124}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNoteOption={this.props.store.language.label78}
                weight="medium"
              >
                <TextInput
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  paddingInput="20px"
                  autocomplete="nope"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  name="web"
                  value={
                    this.personalization ? this.personalization[0].text_one : ""
                  }
                  onChange={
                    this.personalization
                      ? this.handleChangeEdit
                      : this.handleChange
                  }
                />
              </Field>
              <Field
                marginH={this.props.store.viewMobile && "4px"}
                label={this.props.store.language.label125 + " 1"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNoteOption={this.props.store.language.label126}
                weight="medium"
              >
                <TextInput
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  paddingInput="20px"
                  autocomplete="nope"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  name="textTwo"
                  value={
                    this.personalization ? this.personalization[0].text_two : ""
                  }
                  onChange={
                    this.personalization
                      ? this.handleChangeEdit
                      : this.handleChange
                  }
                />
              </Field>
              <Field
                marginH={this.props.store.viewMobile && "4px"}
                label={this.props.store.language.label125 + " 2"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                labelNoteOption={this.props.store.language.label126}
                weight="medium"
              >
                <TextInput
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  paddingInput="20px"
                  autocomplete="nope"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  name="textThree"
                  value={
                    this.personalization
                      ? this.personalization[0].text_three
                      : ""
                  }
                  onChange={
                    this.personalization
                      ? this.handleChangeEdit
                      : this.handleChange
                  }
                />
              </Field>
            </Panel>
            <div className="is-pulled-right">
              {this.personalization
                ? this.renderButtonsEdit()
                : this.renderButtons()}
            </div>
          </Column>
        </Columns>
        {showDeletedModal && (
          <UserPersonalizationDeletedModal
            currentUserPerson={this.personalization[0]}
            onClose={this.handleDeleteConfirmationModalClose}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={this.props.store.language.label132}
            messageError={this.props.store.language.label133}
            title={this.props.store.language.label134}
            subtitle={this.props.store.language.label135}
            all={false}
          />
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { currentUserPersonalization } = this.state;
    if (!currentUserPersonalization) return this.renderEmptyContent();
    return !currentUserPersonalization.isOk()
      ? this.renderLoader()
      : this.renderPersonalization();
  };

  render() {
    const { accounts, showDeletedAllModal } = this.state;
    const optionsSelect = this.getOptions(accounts);

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                className="pb-2"
              >
                {this.props.store.language.label38}
              </Text>
            )}
            {/* <div
              style={{ maxWidth: "718px" }}
              className={this.props.store.viewMobile ? "my-2" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label115}
              </Paragraph>
            </div> */}
            {this.props.store.getSingleUser()
              ? null
              : this.renderSelect(optionsSelect)}
            {this.renderHeader()}
            {showDeletedAllModal && (
              <UserPersonalizationDeletedModal
                currentUserPerson={null}
                onClose={this.handleDeleteConfirmationModalClose}
                onAccept={this.handleAcceptDeleteConfirmationModal}
                messageSuccess={this.props.store.language.label682}
                messageError={this.props.store.language.label683}
                title={this.props.store.language.label684}
                subtitle={this.props.store.language.label685}
                all={true}
              />
            )}
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}
UserPersonalizationPage.propTypes = {};

UserPersonalizationPage.defaultProps = {};
export default withToastManager(withStore(UserPersonalizationPage));
