import { Store } from "ks_storybook";
import { Preference } from "../models";

class PreferencesStore extends Store {
  model = Preference;

  getUserPreference(value) {
    return this.search(
      { per_page: 1000 },
      "users_preferences",
      true,
      `/users_preferences/${value}/get_preference_user`
    );
  }
}

export default PreferencesStore;
