import { observable } from "mobx";
import { Store } from "ks_storybook";
import { UISettings } from "../models";
import { vdjToMp3Src, bufferAdsMp3, bufferToSrc } from "../libs/songslib";
import WaveSurfer from "wavesurfer.js";
export default class UIStore extends Store {
  model = UISettings;
  urlRoot = "UI";
  _host = "https://vdj.megaplayer.me/Multimedia/VDJ/";
  _hostad = process.env.REACT_APP_KASIMU_API_URL + "/ad_download?url=";
  _ks_certificate = process.env.REACT_APP_KASIMU_API_URL + "/download_certificate?url=";

  @observable wavesurfer = null;
  @observable showMainSide = false;
  @observable showPlayer = false;
  @observable currentSongPath = null;
  @observable currentAdId = null;
  @observable isPlaying = false;
  @observable isError = false;
  @observable currentDuration = null;
  @observable durationSecs = 0;
  @observable currentProgressTime = null;
  @observable currentProgress = 0;

  constructor(adapter, appStore) {
    super(adapter, appStore);

    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-store");

    this.interval = 1000;
    setInterval(this.updateProgress, this.interval);
  }

  waveAds = (type, ad, color) => {
    this.destroy();
    const that = this;
    that.wavesurfer = WaveSurfer.create({
      container: type == 2 ? `#waveAds${ad.id}` : "#waveAds",
      waveColor: color ? "white" : "gray",
      progressColor: "#FF3F1F",
      responsive: true,
      hideScrollbar: true,
      barWidth: 1,
      barHeight: 0.6,
      cursorWidth: 0,
      height: 100,
      backend: "MediaElement",
    });
  };

  loadCurrentAds = (bufferMp3, type) => {
    if (bufferMp3) {
      if (type == 1) {
        this.wavesurfer.load(bufferAdsMp3(bufferMp3));
      } else {
        this.wavesurfer.load(bufferToSrc(bufferMp3));
      }
      return true;
    } else {
      return false;
    }
  };

  startPlayingAds = (ad, type, color) => {
    var that = this;
    that.waveAds(type, ad, color);
    that.showPlayer = true;
    that.isError = false;
    that.wavesurfer.on("play", () => {
      that.isPlaying = true;
    });
    that.wavesurfer.on("pause", () => {
      that.isPlaying = false;
    });
    if (type == 1) {
      if (that.loadCurrentAds(ad.ad_path, type)) {
        that.play();
      }
    } else {
      that.currentAdId = ad.id;
      that.fetchAd(ad.ad_path).then((buffer) => {
        if (buffer) {
          if (that.loadCurrentAds(buffer, type)) that.play();
        }
      });
    }

    that.wavesurfer.on("error", () => {
      that.isError = true;
    });
  };

  fetchAd = (adPath) =>
    this.timeout(30000, fetch(this._hostad + this.appStore.replaceBucketUrl(adPath))).then((response) => {
      if (response.status === 200) {
        return response.arrayBuffer();
      } else {
        console.log("HTTP error, status = " + response.status);
        return null;
      }
  });

  fetchCertificate = (url,fileName) =>
    this.timeout(30000, fetch(this._ks_certificate + this.appStore.replaceBucketUrl(url))).then((response) => {
      if (response.status === 200) {
        response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = fileName+'.pdf';
          alink.click();
      })
      } else {
        console.log("HTTP error, status = " + response.status);
        return null;
      }
  });

  wave = (type, indice) => {
    this.destroy();
    const that = this;
    that.wavesurfer = WaveSurfer.create({
      container: type == 1 || type == 2 ? `#waveform${indice}` : "#waveform",
      waveColor: type == 1 ? "white" : "gray",
      progressColor: "#FF3F1F",
      responsive: true,
      hideScrollbar: true,
      barWidth: 1,
      barHeight: 0.6,
      cursorWidth: 0,
      height: 100,
      backend: "MediaElement",
    });
    document.body.onkeydown = (event) => {
      let keyCode = event.keyCode;
      switch (keyCode) {
        case 39: // avanzar barra 32
          if (this.showPlayer && this.isPlaying) {
            if (this.wavesurfer.getCurrentTime()) {
              this.wavesurfer.skipForward(30);
            }
            return false;
          }
          break;
        case 37: //  reproceder
          if (this.showPlayer && this.isPlaying) {
            if (this.wavesurfer.getCurrentTime()) {
              this.wavesurfer.skipBackward(30);
            }
            return false;
          }
          break;
      }
    };
  };

  initial = () => {
    this.wavesurfer = null;
    this.showPlayer = false;
    this.currentSongPath = null;
    this.currentAdId = null;
    this.currentProgressTime = null;
    this.currentDuration = null;
  };

  destroy = () => {
    if (this.wavesurfer) {
      this.pause();
      this.wavesurfer.destroy();
      this.wavesurfer = null;
      this.showPlayer = false;
      this.currentSongPath = null;
      this.currentAdId = null;
      this.currentProgressTime = null;
      this.currentDuration = null;
    }
  };

  get settings() {
    if (this.appStore.loggedInUser) {
      return this.get(this.appStore.loggedInUser.id);
    }
    return new UISettings({}, this);
  }

  updateProgress = () => {
    this.updateSongSilder();
    this.showDuration();
  };

  startPlaying = (song_path, type, indice) => {
    var that = this;
    that.wave(type, indice);
    that.showPlayer = true;
    that.wavesurfer.on("play", () => {
      that.isPlaying = true;
    });
    that.wavesurfer.on("pause", () => {
      that.isPlaying = false;
    });
    that.currentSongPath = song_path;
    that.fetchSong(song_path).then((buffer) => {
      if (buffer) {
        if (that.loadCurrentSong(vdjToMp3Src(buffer))) that.play();
      }
    });
  };

  timeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        resolve({ statusCode: 504, status: "504" });
      }, ms);
      promise.then(
        (res) => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        (err) => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    });
  };

  loadCurrentSong = (buffer) => {
    if (buffer) {
      this.wavesurfer.load(buffer);
      return true;
    } else {
      return false;
    }
  };

  fetchSong = (song_path) =>
    this.timeout(30000, fetch(this._host + song_path, this.myHeaders)).then(
      (response) => {
        if (response.status === 200) {
          return response.arrayBuffer();
        } else {
          console.log("HTTP error, status = " + response.status);
          return null;
        }
      }
    );

  play = () => {
    this.wavesurfer.play();
  };

  pause = () => {
    this.wavesurfer.pause();
  };

  updateSongSilder = () => {
    var that = this;
    if (that.wavesurfer) {
      that.currentProgress = Math.floor(that.wavesurfer.getCurrentTime() || 0);
      that.currentProgressTime = that.convertTime(that.currentProgress);
    }
  };

  showDuration = () => {
    var that = this;
    if (that.wavesurfer) {
      this.durationSecs = Math.floor(this.wavesurfer.getDuration() || 0);
      this.currentDuration = this.convertTime(this.durationSecs);
    }
  };

  convertTime = (secs) => {
    var min = Math.floor(secs / 60);
    var sec = secs % 60;
    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;
    return min + ":" + sec;
  };

  signIn = () => {};

  signOut() {
    this.destroy();
    this.showMainSide = false;
  }
}
