import React, { Component } from "react";
import PropTypes from "prop-types";
import injectSheet from "react-jss";
import { Avatar } from "ks_storybook";
import withStore from "../../hocs/withStore";

const styles = (theme) => ({});

class MediaUser extends Component {
  state = {};

  renderDesktop() {
    const { src, userName, os, time, conexionType, playlistName } = this.props;
    return (
      <article
        class="media"
        src={src}
        playlistName={playlistName}
        os={os}
        time={time}
        conexionType={conexionType}
      >
        <div class="media-content">
          <div class="content">
            <p>
              <small class="has-text-grey-lighter">
                {this.props.store.language.label473}
              </small>
              <small class="has-text-grey-lighter">{time}</small>
              <br />
              <small class="has-text-grey-lighter">
                Playlist: {playlistName}
              </small>
              <br />
              <small class="has-text-grey-lighter">
                {this.props.store.language.label71}: {os}
              </small>
            </p>
          </div>
        </div>
        <div class="media-right">
          {conexionType == 4 ? (
            <span style={{ textTransform: "uppercase", color: "yellow" }}>
              {this.props.store.language.label96}
            </span>
          ) : conexionType == 3 ? (
            <span className="success">{this.props.store.language.label64}</span>
          ) : (
            <span className="danger">{this.props.store.language.label65}</span>
          )}
        </div>
      </article>
    );
  }

  renderMobile() {
    const { src, userName, os, time, conexionType, playlistName } = this.props;
    return (
      <article
        class="media"
        src={src}
        playlistName={playlistName}
        os={os}
        time={time}
        conexionType={conexionType}
      >
        <div class="media-content">
          <div class="content">
            {conexionType == 4 ? (
              <span style={{ textTransform: "uppercase", color: "yellow" }}>
                {this.props.store.language.label96}
              </span>
            ) : conexionType == "1" || conexionType == "3" ? (
              <span class="success">{this.props.store.language.label64}</span>
            ) : (
              <span className="danger">
                {this.props.store.language.label65}
              </span>
            )}

            <p>
              <small class="has-text-grey-lighter">
                {this.props.store.language.label473}
              </small>
              <small class="has-text-grey-lighter">{time}</small>
              <br />
              <small class="has-text-grey-lighter">
                Playlist: {playlistName}
              </small>
              <br />
              <small class="has-text-grey-lighter">
                {this.props.store.language.label71}: {os}
              </small>
            </p>
          </div>
        </div>
      </article>
    );
  }

  render() {
    return this.props.store.viewMobile
      ? this.renderDesktop()
      : this.renderMobile();
  }
}

MediaUser.propTypes = {
  classes: PropTypes.any,
  className: PropTypes.string,
  src: PropTypes.string,
  userName: PropTypes.string,
  playlistName: PropTypes.string,
  os: PropTypes.string,
  time: PropTypes.string,
  conexionType: PropTypes.string,
};

MediaUser.defaultProps = {
  classes: null,
  className: null,
  src: null,
  userName: null,
  playlistName: null,
  os: null,
  time: null,
  conexionType: null,
};

export default withStore(injectSheet(styles)(MediaUser));
