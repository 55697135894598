import React, { Component } from "react";
import PropTypes from "prop-types";
import { RectSkeleton, Button, Text, Panel } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class AdsRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDeleteAd = () => {
    const { ad, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(ad);
  };

  render() {
    const { ad, active, handleStartPlayingClick, handleTogglePlay } =
      this.props;
    if (ad.isEmpty()) {
      return this.renderSkelenton();
    }
    return (
      <Panel
        color="gray"
        // padding={this.props.store.viewMobile ? "20px 32px" : "22px 25px"}
        padding="0px"
        className={this.props.store.viewMobile && "mb-1"}
        style={{
          marginBottom: !this.props.store.viewMobile && "6px",
          borderRadius: !this.props.store.viewMobile && "5px",
        }}
      >
        <div
          className="is-row is-fullwidth"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            padding: this.props.store.viewMobile ? "20px 32px" : "22px 25px",
            height: 65,
          }}
        >
          <div className="is-row" style={{ alignItems: "center" }}>
            <Button
              className={this.props.store.viewMobile ? "mr-3" : "mr-2"}
              icon={active && this.props.store.ui.isPlaying ? "pause" : "play"}
              kind="link"
              color={active ? "primary" : "basic"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              onClick={
                active && this.props.store.ui.showPlayer
                  ? () => handleTogglePlay()
                  : () => handleStartPlayingClick(ad)
              }
              id="play-button"
              lead
              space
            />
            <div
              className="is-fullwidth"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {
                <Text
                  multiline
                  weight="medium"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color={active ? "primary" : "white"}
                >
                  {ad.name}
                </Text>
              }
            </div>
          </div>

          {ad.remove && (
            <Button
              kind="link"
              className={this.props.store.viewMobile ? "ml-3" : "ml-2"}
              color="basic"
              size={this.props.store.viewMobile ? "xl" : "xl"}
              iconKasimu={this.props.store.viewMobile ? "trash" : "trash-solid"}
              onClick={this.handleDeleteAd}
              space
            />
          )}
        </div>
        <div
          style={{
            padding: active
              ? this.props.store.viewMobile
                ? "0px 32px"
                : "0px 25px"
              : 0,
          }}
        >
          {active && this.props.store.ui.showPlayer && (
            <div
              className={"mx-1 mt-1"}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                {this.props.store.ui.currentDuration == "NaN:NaN"
                  ? "00:00 / 00:00"
                  : this.props.store.ui.currentDuration &&
                    this.props.store.ui.currentProgressTime &&
                    this.props.store.ui.currentProgressTime}
              </Text>

              <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                {this.props.store.ui.currentDuration == "NaN:NaN"
                  ? "00:00 / 00:00"
                  : this.props.store.ui.currentDuration &&
                    this.props.store.ui.currentProgressTime &&
                    this.props.store.ui.currentDuration}
              </Text>
            </div>
          )}

          <div
            id={`waveAds` + ad.id}
            style={{
              height: active && this.props.store.ui.showPlayer ? 100 : 0,
            }}
          />
        </div>
      </Panel>
    );
  }
}

AdsRow.propTypes = {
  ad: PropTypes.object,
  onDeleteClick: PropTypes.func,
};

AdsRow.defaultProps = {
  ad: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(AdsRow));
