import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class PPGenericEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };

    this.currentGenObj = props.currentGenObj.clone();
  }

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleChange = (sender, value, name) => {
    this.currentGenObj[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getMessageError = (value) => {
    switch (value) {
      case 1:
        return "Error, algo salió mal al editar el tipo de medio.";
      case 2:
        return "Error, algo salió mal al editar la empresa anunciante.";
      case 3:
        return "Error, algo salió mal al editar la marca o producto.";
    }
  };

  getMessageSuccess = (value) => {
    switch (value) {
      case 1:
        return "El Tipo de medio se editó exitosamente.";
      case 2:
        return "La Empresa o anunciante se editó exitosamente.";
      case 3:
        return "La Marca o producto se editó exitosamente.";
    }
  };

  handleSave = () => {
    const { toastManager, currentGenType } = this.props;
    this.setState({ isSaving: true }, () => {
      this.currentGenObj.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.getMessageError(currentGenType), {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add(this.getMessageSuccess(currentGenType), {
            appearance: "success",
            autoDismiss: true,
          });
        }
        this.handleClose();
      });
    });
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              upper
              className="is-pulled-right px-3"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label81}
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              upper
              disabled={this.currentGenObj.name === ""}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "640px" : "auto"}
        invert
      >
        <ModalHeader>
          <Text
            upper
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
          >
            {this.props.store.language.label140}
            {"\n"}
            {this.currentGenObj.name}
          </Text>
        </ModalHeader>
        <ModalContent>
          <br />
          <Field
            label={this.props.store.language.label53}
            labelNote={this.props.store.language.label53}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "10px"}
            upper
          >
            <TextInput
              invert
              backgroundColor="grayLight"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "lg" : "md"}
              name="name"
              onChange={this.handleChange}
              value={this.currentGenObj.name}
              required
            />
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

PPGenericEditModal.propTypes = {
  currentGenObj: PropTypes.object,
  currentGenType: PropTypes.number,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PPGenericEditModal.defaultProps = {
  currentGenObj: null,
  currentGenType: 0,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PPGenericEditModal));
