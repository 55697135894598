import { Store } from "ks_storybook";
import { PPAdvertisements } from "../models";

class PPAdvertisementsStore extends Store {
  model = PPAdvertisements;

  fetchSearch(value) {
    return this.search(
      { per_page: 10000, search: value },
      "pp_advertisements_search",
      true,
      "/pp_advertisements"
    );
  }

  fetchAll() {
    return this.search(
      { per_page: 10000 },
      "pp_advertisements_all",
      true,
      "/pp_advertisements"
    );
  }

  fetchAllAndDeleted() {
    return this.search(
      { per_page: 10000 },
      "get_all_for_report",
      true,
      "/pp_advertisements/get_all_for_report"
    );
  }
}

export default PPAdvertisementsStore;
