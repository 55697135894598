import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Toggle,
  Panel,
  Button,
} from "ks_storybook";
import {
  AdPlanRowModal,
  AdPlanModal,
  AdBatchesEyeModal,
  AdDeletedPlansModal,
  AdPlanEditModal,
} from "../../../../Components/Plans";
import { EmptyContent } from "../../../../Components/Common";
import { withToastManager } from "react-toast-notifications";
import moment from "moment";
import { Screen } from "../../../../Components/Screen";

@observer
class AdsPlanerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ads: this.getAdAll(),
      showModal: false,
      showAdBatchesEyeModel: false,
      showAdDeletedPlansModal: false,
      showAdPlanEditModal: false,
      currentAdPlans: null,
      adsPlans: this.getAdsPlans(this.props.account.id),
      datesSelected: [],
    };
    this.weekdays = this.props.store.language.label61.split(",");
  }

  getAdAll = () => {
    return this.props.store.ads.fetchAll();
  };

  getAdsPlans = (value) => {
    return this.props.store.advertisingGuidelines.getGuidelinesUser(value);
  };

  handleChange = (sender, name, value) => {
    const actionPlaner = value ? 1 : 0;
    const messageSuccess =
      actionPlaner == 1
        ? this.props.store.language.label314
        : this.props.store.language.label315;
    const messageError =
      actionPlaner == 1
        ? this.props.store.language.label316
        : this.props.store.language.label317;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_ads_planner_on_user",
        "?user_id=" + this.props.account.id + "&ads_planner_on=" + actionPlaner
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleEditModalSave = () => {
    this.setState({ showAdPlanEditModal: false, currentAdPlans: null });
    this.handleReload();
  };

  handleEditModalOpen = (plan) => {
    this.setState({ showAdPlanEditModal: true, currentAdPlans: plan });
  };

  handleEditModalClose = () => {
    this.setState({ showAdPlanEditModal: false });
  };

  handleEyeBatchesModelOpen = (plan) => {
    this.setState({ showAdBatchesEyeModel: true, currentAdPlans: plan });
  };

  handleEyeBatchesModelClose = () => {
    this.setState({ showAdBatchesEyeModel: false, currentAdPlans: null });
  };

  handleAdDeletedPlansModalOpen = (plan) => {
    this.setState({ showAdDeletedPlansModal: true, currentAdPlans: plan });
  };

  handleAdDeletedPlansModalClose = () => {
    this.setState({ showAdDeletedPlansModal: false, currentAdPlans: null });
  };

  handleAdDeletedPlansModalAccept = () => {
    this.setState({ showAdDeletedPlansModal: false, currentAdPlans: null });
    this.handleReload();
  };

  handleModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  dateToKasimuDate = (wd) => {
    return wd == 0 ? 7 : wd;
  };

  isWeekDay = (plan, weekDay) => {
    if (plan.week_day && plan.week_day == weekDay) {
      return true;
    }
    if (plan.exact_day) {
      const wd = moment(plan.exact_day, "DD/MM/YYYY");
      const d = wd.day();
      return this.dateToKasimuDate(d) == weekDay;
    }
    return false;
  };

  handleReload = () => {
    this.setState({ adsPlans: this.getAdsPlans(this.props.account.id) });
  };

  handleSave = () => {
    this.setState({
      adsPlans: this.getAdsPlans(this.props.account.id),
      showModal: false,
    });
  };

  getAdsPlans = (value) => {
    return this.props.store.advertisingGuidelines.getGuidelinesUser(value);
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderToogle = (plan) => {
    return this.props.store.viewMobile ? (
      <React.Fragment>
        <div
          className="pb-2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="flex items-center">
            <Toggle
              noIcons
              //onChange={this.handleChange}
              onChange={(...e) =>
                this.props.store.getPermission(31, 31, () => {
                  this.handleChange(...e);
                })
              }
              checked={plan.ads_planner_on ? true : false}
            />
            <div className="ml-2 mr-2">
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                color="special"
              >
                {plan.ads_planner_on
                  ? this.props.store.language.label318
                  : this.props.store.language.label319}
              </Text>
            </div>
          </div>
          {this.renderButton()}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {this.renderButton()}
        <div
          className="pb-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="white"
              weight="medium"
            >
              {plan.ads_planner_on
                ? this.props.store.language.label318
                : this.props.store.language.label319}
            </Text>
          </div>
          <Toggle
            noIcons
            //onChange={this.handleChange}
            onChange={(...e) =>
              this.props.store.getPermission(31, 31, () => {
                this.handleChange(...e);
              })
            }
            checked={plan.ads_planner_on ? true : false}
          />
        </div>
      </React.Fragment>
    );
  };

  renderButton = () => {
    return this.props.store.viewMobile ? (
      <React.Fragment>
        <div className="is-pulled-right my-1">
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleModalOpen}
            onClick={() =>
              this.props.store.getPermission(31, 31, () => {
                this.handleModalOpen();
              })
            }
          >
            <strong>{this.props.store.language.label183}</strong>
          </Button>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="flex my-1" style={{ justifyContent: "center" }}>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleModalOpen}
            onClick={() =>
              this.props.store.getPermission(31, 31, () => {
                this.handleModalOpen();
              })
            }
          >
            <strong>{this.props.store.language.label183}</strong>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  renderHeaderOk = () => {
    const { adsPlans } = this.state;
    const options = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const arrayPlans = adsPlans.toArray();
    return (
      <React.Fragment>
        {arrayPlans.length === 0 && (
          <div className="pb-2">{this.renderButton()}</div>
        )}
        {arrayPlans.length > 0 && this.renderToogle(arrayPlans[0])}

        {arrayPlans.length === 0 && (
          <Panel
            color="gray"
            padding={this.props.store.viewMobile ? "23px 20px" : "12px 10px"}
          >
            {this.renderEmptyContent()}
          </Panel>
        )}

        {this.props.store.viewMobile
          ? arrayPlans.length > 0 && (
              <Panel
                color="gray"
                padding={
                  this.props.store.viewMobile ? "23px 20px" : "12px 10px"
                }
              >
                {options.map((i) => this.renderFrame(arrayPlans, i))}
              </Panel>
            )
          : arrayPlans.length > 0 &&
            options.map((i) => this.renderFrame(arrayPlans, i))}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { adsPlans, ads } = this.state;
    if (!adsPlans && ads) return this.renderEmptyContent();
    return !adsPlans.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getTitleForWeekDay = (weekDay) => {
    const titles = {
      1: this.weekdays[1],
      2: this.weekdays[2],
      3: this.weekdays[3],
      4: this.weekdays[4],
      5: this.weekdays[5],
      6: this.weekdays[6],
      7: this.weekdays[0],
      8: this.props.store.language.label349,
      9: this.props.store.language.label350,
      10: this.props.store.language.label351,
    };
    return titles[weekDay];
  };

  renderFrame = (plans, weekDay) => {
    return (
      <React.Fragment>
        <Panel
          color="grayDark"
          padding={this.props.store.viewMobile ? "10px 25px" : "12px"}
          style={{
            borderRadius: 6,
            marginTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Text
                weight="strong"
                size={this.props.store.viewMobile ? "xl" : "lg"}
              >
                <strong className="has-text-light">
                  {this.getTitleForWeekDay(weekDay)}
                </strong>
              </Text>
            </div>

            <Button
              name="arrow-down"
              kind="link"
              color="basic"
              size={this.props.store.viewMobile ? "lg" : "md"}
              iconKasimu={
                this.state.datesSelected.some((e) => e == weekDay)
                  ? "arrow-down"
                  : "arrow-up"
              }
              space
              onClick={() => {
                let valid = this.state.datesSelected.indexOf(weekDay);

                if (valid >= 0) {
                  this.setState({
                    datesSelected: this.state.datesSelected.filter(
                      (i) => i !== weekDay
                    ),
                  });
                } else {
                  this.setState({
                    datesSelected: [...this.state.datesSelected, weekDay],
                  });
                }
              }}
            />
          </div>
        </Panel>
        {this.state.datesSelected.some((e) => e == weekDay)
          ? null
          : plans
              .filter((p) => this.isWeekDay(p, weekDay))
              .map((p) => (
                <AdPlanRowModal
                  plan={p}
                  onDeleteClick={this.handleAdDeletedPlansModalOpen}
                  onEyeClick={this.handleEyeBatchesModelOpen}
                  onEditClick={this.handleEditModalOpen}
                />
              ))}
      </React.Fragment>
    );
  };

  renderScreen() {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label39}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  }

  render() {
    const { account } = this.props;
    const {
      ads,
      showModal,
      showAdBatchesEyeModel,
      showAdDeletedPlansModal,
      showAdPlanEditModal,
      currentAdPlans,
    } = this.state;
    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "700px" : "90%"}
            height={this.props.store.viewMobile ? "640px" : "auto"}
          >
            <ModalHeader>
              <div className="flex items-center" style={{ flexWrap: "wrap" }}>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="black"
                  className="mr-2"
                >
                  {this.props.store.language.label39}
                </Text>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="regular"
                  className="mr-2"
                >
                  {account.username}
                </Text>
              </div>
            </ModalHeader>
            <ModalContent>{this.renderHeader()}</ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {showModal && (
          <AdPlanModal
            userId={this.props.account.id}
            ads={ads.toArray()}
            onClose={this.handleModalClose}
            onSave={this.handleSave}
          />
        )}
        {showAdBatchesEyeModel && (
          <AdBatchesEyeModal
            currentAdPlans={currentAdPlans}
            onClose={this.handleEyeBatchesModelClose}
          />
        )}
        {showAdPlanEditModal && (
          <AdPlanEditModal
            currentAdPlans={currentAdPlans}
            userId={this.props.account.id}
            ads={ads.toArray()}
            onClose={this.handleEditModalClose}
            onSave={this.handleEditModalSave}
          />
        )}
        {showAdDeletedPlansModal && (
          <AdDeletedPlansModal
            currentAdPlans={currentAdPlans}
            onClose={this.handleAdDeletedPlansModalClose}
            onAccept={this.handleAdDeletedPlansModalAccept}
          />
        )}
      </React.Fragment>
    );
  }
}

AdsPlanerModal.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
};

AdsPlanerModal.defaultProps = {
  account: null,
  onClose: null,
};

export default withStore(withToastManager(AdsPlanerModal));
