import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  RectSkeleton,
  Text,
  Avatar,
  Button,
  Toggle,
  Panel,
  Img,
} from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import icon3 from "../../assets/images/hibernation.png";

@observer
class PlaylistPlanRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDeletePlan = () => {
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .delete(
        "/actions_playlist_plans/deleted_playlist_plan",
        "?id=" + this.props.plan.id + "&type=" + this.props.plan.type
      )
      .then(
        (res) => {
          toastManager.add(this.props.store.language.label330, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.onDeleted && this.props.onDeleted();
        },
        (error) => {
          toastManager.add(this.props.store.language.label331, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(this.props.store.language.label331, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  getPlaylistType = () => {
    switch (this.props.plan.playlist_type) {
      case 0:
        return false;
      case 1:
        return true;
      case 2:
        return false;
      case 3:
        return true;
      case 4:
        return false;
      case 5:
        return true;
      case 6:
        return false;
      case 7:
        return true;
      default:
        break;
    }
  };

  getPlaylistTypeInvest = () => {
    switch (this.props.plan.playlist_type) {
      case 0:
        return 1;
      case 1:
        return 0;
      case 2:
        return 3;
      case 3:
        return 2;
      case 4:
        return 5;
      case 5:
        return 4;
      case 6:
        return 7;
      case 7:
        return 6;
    }
  };

  getIconPlaylistUser = (value) => {
    switch (value) {
      case 4:
        return this.props.store.getBucketUrl() + "images/cp-a.png";
      case 5:
        return this.props.store.getBucketUrl() + "images/cp-a.png";
      case 6:
        return this.props.store.getBucketUrl() + "images/cp-m.png";
      case 7:
        return this.props.store.getBucketUrl() + "images/cp-m.png";
    }
  };

  handleChange = (sender, name, value) => {
    const { toastManager } = this.props;
    const playlist_type = this.getPlaylistTypeInvest();
    const messageSuccess =
      playlist_type == 1 || playlist_type == 5 || playlist_type == 7
        ? this.props.store.language.label332
        : this.props.store.language.label333;
    const messageError =
      playlist_type == 1 || playlist_type == 5 || playlist_type == 7
        ? this.props.store.language.label334
        : this.props.store.language.label335;
    this.props.store.kasimuAPIClient
      .putOne(
        "/actions_playlist_plans/put_playlist_plan_user",
        "?id=" + this.props.plan.id + "&playlist_type=" + playlist_type
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.onDeleted && this.props.onDeleted();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  renderDesktop = (plan) => {
    return (
      <Panel color="gray" className="mt-1" padding="15px 28px">
        <article
          className="ks--media"
          style={{ alignItems: "center", padding: "0px" }}
        >
          <figure className="media-left">
            {plan.type == 1 ? (
              <div className="flex items-center justify-center">
                <Avatar
                  size="lg"
                  image={
                    plan.playlist_type >= 4
                      ? this.getIconPlaylistUser(plan.playlist_type)
                      : this.props.store.replaceBucketUrl(plan.categories_playlists[0].icon_path)
                  }
                  style={{ borderRadius: 10 }}
                />
              </div>
            ) : (
              <Img src={icon3} style={{ width: 48 }} />
            )}
          </figure>
          <div className="media-content">
            <div className="content" style={{ lineBreak: "anywhere" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                color="white"
                lineBreak
              >
                {plan.type == 1 ? (
                  <strong className="has-text-light">
                    {plan.owner == 3
                      ? this.props.store.language.label630
                      : plan.owner == 2
                      ? this.props.store.language.label631
                      : ""}
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].name
                      : plan.categories_playlists[0].alias}
                  </strong>
                ) : (
                  <strong className="has-text-light">
                    {this.props.store.language.label659}
                  </strong>
                )}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="regular"
                color="special"
                lineBreak
              >
                {plan.type == 1 ? (
                  <small>
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].description
                        ? plan.categories_playlists[0].description
                        : this.props.store.language.label686
                      : plan.categories_playlists[0].description}
                  </small>
                ) : null}
                <br />
                <small>
                  {plan.start_hour} - {plan.finish_hour}
                </small>
              </Text>
            </div>
          </div>
          <div className="media-right">
            {plan.type == 1 ? (
              <div className="flex" style={{ alignItems: "center" }}>
                <div>
                  <Text weight="medium" size="xl">
                    {this.props.store.language.label324}
                  </Text>
                </div>
                <div
                  class="pl-2 pr-1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Toggle
                    noIcons
                    onChange={this.handleChange}
                    checked={this.getPlaylistType()}
                  />
                </div>
                <Button
                  kind="link"
                  color="basic"
                  size="xl"
                  iconKasimu={
                    this.props.store.viewMobile ? "trash" : "trash-solid"
                  }
                  onClick={this.handleDeletePlan}
                  space
                />
              </div>
            ) : (
              <div class="columns" style={{ alignItems: "center" }}>
                <p class="column">
                  <Button
                    kind="link"
                    color="basic"
                    size="xl"
                    iconKasimu="trash"
                    onClick={this.handleDeletePlan}
                    space
                  />
                </p>
              </div>
            )}
          </div>
        </article>
      </Panel>
    );
  };

  renderMobile = (plan) => {
    return (
      <Panel color="gray" className="mt-1" padding="10px 15px">
        <article
          className="ks--media"
          style={{ alignItems: "center", padding: "0px", flexWrap: "wrap" }}
        >
          <figure className="media-left">
            {plan.type == 1 ? (
              <div className="flex items-center justify-center">
                <Avatar
                  size="md"
                  image={
                    plan.playlist_type >= 4
                      ? this.getIconPlaylistUser(plan.playlist_type)
                      : this.props.store.replaceBucketUrl(plan.categories_playlists[0].icon_path)
                  }
                  style={{ borderRadius: 10 }}
                />
              </div>
            ) : (
              <Img src={icon3} style={{ width: 30 }} />
            )}
          </figure>
          <div className="media-content">
            <div className="content" style={{ lineBreak: "anywhere" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                color="white"
                lineBreak
              >
                {plan.type == 1 ? (
                  <strong className="has-text-light">
                    {plan.owner == 3
                      ? this.props.store.language.label630
                      : plan.owner == 2
                      ? this.props.store.language.label631
                      : ""}
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].name
                      : plan.categories_playlists[0].alias}
                  </strong>
                ) : (
                  <strong className="has-text-light">
                    {this.props.store.language.label659}
                  </strong>
                )}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="regular"
                color="special"
                lineBreak
              >
                {plan.type == 1 ? (
                  <small>
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].description
                        ? plan.categories_playlists[0].description
                        : this.props.store.language.label686
                      : plan.categories_playlists[0].description}
                  </small>
                ) : null}
                <br />
                <small>
                  {plan.start_hour} - {plan.finish_hour}
                </small>
              </Text>
            </div>
          </div>

          <div
            className="media-right"
            style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
          >
            {plan.type == 1 ? (
              <div className="flex" style={{ alignItems: "center" }}>
                <div>
                  <Text weight="medium" size="lg">
                    {this.props.store.language.label324}
                  </Text>
                </div>
                <div
                  class="pl-2 pr-1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Toggle
                    noIcons
                    onChange={this.handleChange}
                    checked={this.getPlaylistType()}
                  />
                </div>
                <Button
                  kind="link"
                  color="basic"
                  size="lg"
                  iconKasimu={
                    this.props.store.viewMobile ? "trash" : "trash-solid"
                  }
                  onClick={this.handleDeletePlan}
                  space
                />
              </div>
            ) : (
              <div class="columns" style={{ alignItems: "center" }}>
                <p class="column">
                  <Button
                    kind="link"
                    color="basic"
                    size="lg"
                    iconKasimu={
                      this.props.store.viewMobile ? "trash" : "trash-solid"
                    }
                    onClick={this.handleDeletePlan}
                    space
                  />
                </p>
              </div>
            )}
          </div>
        </article>
      </Panel>
    );
  };

  render() {
    const { plan } = this.props;
    if (plan.length === 0) {
      return this.renderSkelenton();
    }
    return this.props.store.viewMobile
      ? this.renderDesktop(plan)
      : this.renderMobile(plan);
  }
}

PlaylistPlanRow.propTypes = {
  plan: PropTypes.object,
};

PlaylistPlanRow.defaultProps = {
  plan: null,
};

export default withStore(withToastManager(PlaylistPlanRow));
