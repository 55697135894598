const et_EE = {
  media_type: {
    1: `Laul`,
    2: `Raadio ID`,
    3: `Laulu vahel`,
    4: `Reklaam`,
    5: `Reklaam algas`,
    6: `Reklaam lõppes`,
  },
  mood: {
    1: "Väga rahulik",
    2: "Rahulik",
    3: "Tavaline",
    4: "Tempokas",
    5: "Väga tempokas",
    6: "Väga rahulik ja rahulik",
    7: "Rahulik ja tavaline",
    8: "Rahulik, tavaline ja tempokas",
    9: "Tavaline ja tempokas",
    10: "Kõik",
  },
  languages: {
    1: "Hispaania", 
    2: "Inglise",       
    3: "Prantsuse",
    4: "Portugali",
    5: "Eesti",
    6: "Saksa",
    7: "Itaalia"
  },  
  label1: "Keel",
  label2: "Teenusepakkuja on loo kustutanud, muutke esitusloendit ja kustutage see",
  label3: "Teenusepakkuja eemaldas loo, eemaldage sellest loendist",
  label4: "Mängib",
  label5: "Rakendust Laaditakse...",
  label6: "Juhtpaneel",
  label7: "Kasutaja (Ära kasuta e-posti)",
  label8: "Parool",
  label9: "Logi sisse",
  label10: "Viga, palun sisestage kasutajanimi ja parool.",
  label11: "Viga, see konto pole administraatorikonto.",
  label12: "Viga, kasutaja on peatatud! Võtke ühendust",
  label13: "Viga, vale kasutajanimi või parool.",
  label14: "Viga, teie tellimus on aegunud. Minge",
  label15: "Viga, serveriga ühendamine ebaõnnestus, kontrollige oma internetiühendust.",
  label16: "Juhtpaneel \n\n Kontroll",
  label17: "Kasutajad",
  label18: "Kasutajate (kohalike) loomine, muutmine ja haldamine",
  label19: "Kasutaja seadete kopeerimine \n ühelt kasutajalt teisele",
  label20: "Logo kohandamine \n ja programmiandmed",
  label21: "Kasutajagruppide loomine ja muutmine",
  label22: "Kasutusaruannete allalaadimine",
  label23: "Eelistused",
  label24: "Kasutaja eelistuste loomine ja muutmine",
  label25: "Kasutajapleilistide loomine ja muutmine",
  label26: "Iga päeva igale ajale pleilistide \n või unerežiimide määramine",
  label27: "Muusikastiilide haldamine",
  label28: "Laulude haldamine",
  label29: "Reklaamid",
  label30: "Meediumite laadimine ja haldamine",
  label31: "Kellade loomine ja muutmine",
  label32: "Reklaamide laadimine ja haldamine",
  label33: "Juhiste loomine ja muutmine",
  label34: "Tegevus",
  label35: "Eelistuste loomine ja muutmine",
  label36: "Kasutajagruppide haldamine",
  label37: "Pleilistide planeerija",
  label38: "Kohandamine",
  label39: "Reklaami planeerija",
  label40: "Kloonimine",
  label41: "Logi välja",
  label42: "Andmete laadimine...",
  label43: "Loe rohkem",
  label44: "Loe vähem",
  label45: 'Selles jaotises saate luua kasutajaid. Igal poel peab olema oma kasutaja, ja iga kasutajat saab korraga kasutada ainult ühes kohas. Samuti saate kasutajaid kustutada või neid muuta (kasutajanime, parooli jne muuta).\nIga kasutajat saab määrata ühe või mitme kasutajagrupiga, et hõlbustada kasutajate masskonfigureerimist. Kasutajagruppe saate luua ja muuta jaotises "KASUTAJAGRUPPIDED LOOMINE JA MUUTMINE".\nTeil on võimalus juhtida ka seda, mida iga poe juures kuulatakse, selle jaotise kaudu.\nPlaylistide või reklaamijuhtude määramiseks kasutajale minge vastavasse jaotisse ("PLEILISTID" või "REKLAAMID")',
  label46: "Palgatud kasutajad",
  label47: "Aktiivsed kasutajad",
  label48: "Saadaolevad kasutajad",
  label49: "Loo kasutaja",
  label50: "Sisestage otsitavate kasutajate või kasutajagruppide nimed...",
  label51: "Filtreeri järgi...",
  label52: "Sorteeri järgi...",
  label53: "Nimi",
  label54: "E-post",
  label55: "Grupp",
  label56: "Ühendatud",
  label57: "Ühenduseta",
  label58: "Kunagi ühendatud",
  label59: "Jaanuar, Veebruar, Märts, Aprill, Mai, Juuni, Juuli, August, September, Oktoober, November, Detsember",
  label60: "Jaan, Veeb, Märts, Apr, Mai, Juuni, Juuli, Aug, Sept, Okt, Nov, Dets",
  label61: "Pühapäev, Esmaspäev, Teisipäev, Kolmapäev, Neljapäev, Reede, Laupäev",
  label62: "P, E, T, K, N, R, L",
  label63: "P, L, T, K, N, R, L",
  label64: "Ühendatud",
  label65: "Ühenduseta",
  label66: "Kasutaja pole kunagi ühendatud",
  label67: "Teenusepakkujast peatatud",
  label68: "Aktiivne",
  label69: "Mängib pleilisti",
  label70: "Mängitud pleilist",
  label71: "Alates",
  label72: "Grupid",
  label73: "Ei ole määratud gruppi",
  label74: "Loo \n kasutaja",
  label75: "Kasutajanimed peavad sisaldama sümbolit @.\n Kui seda ei lisata, lisatakse see automaatselt kasutajanime algusesse.",
  label76: "Kohustuslik",
  label77: "Parooli kinnitamine",
  label78: "Valikuline",
  label79: "Kirjeldus",
  label80: "Salvesta muudatused",
  label81: "Muudatusi salvestatakse...",
  label82: "Loo",
  label83: "Luues...",
  label84: "Kasutajanimed peavad sisaldama sümbolit @.\n Kui seda ei lisata, lisatakse see automaatselt kasutajanime algusesse.",
  label85: "Jah",
  label86: "Ei",
  label87: "Info",
  label88: "Kasutaja",
  label89: "Hetkel pole andmeid",
  label90: "Kasutaja loodud",
  label91: "PIN-kood",
  label92: "Talle pole PIN-koodi määratud",
  label93: "Viimased viis (5) ühendust",
  label94: "Välja logitud",
  label95: "Paus",
  label96: "Hibernatsioon",
  label97: 'Saate kopeerida kasutaja seaded teisele kasutajale või mitmele kasutajale või rühmale. Valige algne kasutaja ja seejärel sihtkasutajad või sihtgrupp või grupid. Valige kopeeritavad seaded ja vajutage nupule "KLONI".\n OLULINE: Kopeeritavad seaded kustutavad sihtkasutajate olemasolevad seaded. Näiteks, kui kopeerite kasutaja pleiliste, kustutatakse sihtkasutaja või sihtgrupi kõik olemasolevad pleilistid.',
  label98: "Vali algne kasutaja...",
  label99: "(Valige kasutaja, kelle seadeid allpool kopeeritakse)",
  label100: "Vali kasutajad",
  label101: "Vali grupid",
  label102: "Kõik kasutajad",
  label103: "Sihtkasutaja või -kasutajad",
  label104: "Sihtgrupp või -grupid",
  label105: "(Vali kasutaja või kasutajad, kellele allpool valitud seaded kopeeritakse)",
  label106: "(Vali grupp või grupid, kellele allpool valitud seaded kopeeritakse)",
  label107: "Vali sihtkasutaja või -kasutajad...",
  label108: "Vali sihtgrupp või -grupid...",
  label109: "Kopeeritavad seaded",
  label110: "Sisuhaldur",
  label111: "(Kasutaja stiilid ja muudatused lauludes)",
  label112: "Klooni",
  label113: "Klooni...",
  label114: "Kohandamine logo \n ja programmide andmete abil",
  label115: 'Saate kohandada rakendust oma logoga ja oma andmetega. Logo kuvatakse sisselogimisel ja menüüs ning teie andmed kuvatakse jaotises "Selle rakenduse kohta".',
  label116: "Valige pilt",
  label117: "Valikuline (Asendab rakenduse logo)",
  label118: "Laadige üles ruudukujuline pilt formaadis .jpg või .png.",
  label119: "Laadige üles pilt",
  label120: "Lähtesta",
  label121: "Andmed",
  label122: '(Kuvatakse programmi jaotises "Selle rakenduse kohta")',
  label123: "Ettevõtte või kaupluse nimi",
  label124: "Veebisait",
  label125: "Tekst",
  label126: "Valikuline (Näiteks telefoninumber või kontaktisiku nimi)",
  label127: "Salvesta",
  label128: "Salvestamine...",
  label129: "Kustuta",
  label130: "Kustutamine...",
  label131: "Kasutaja \n logo",
  label132: "Kohandamine lähtestati edukalt.",
  label133: "Viga, midagi läks valesti kohandamise lähtestamisel.",
  label134: "Kas soovite kohandamise lähtestada?",
  label135: "Kohandamine kustutatakse ja taastatakse vaikeväärtuseks.",
  label136: 'Loo grupid ja seejärel "LOO, SEADISTA JA HALDA KASUTAJAID (KOHADE LÕIK)" valige kasutajad gruppidesse, mida soovite. Üks kasutaja võib kuuluda mitmesse gruppi. Kasutajagrupid hõlbustavad suure hulga kasutajate haldamist, määrates konfiguratsioone, nagu pleilistiplaanid, reklaamijuhtumid jne.',
  label137: "Sisestage grupi nimi, mida otsida...",
  label138: "Loo grupp",
  label139: "Loo \n grupp",
  label140: "Muuda",
  label141: "Saate alla laadida kasutatud pleilistide ja avaldatud reklaamide aruandeid vastavalt soovitud kuupäevadele.",
  label142: "Reklaamiaruanded",
  label143: "Pleilistiaruanded",
  label144: "(Kuvatakse ainult reklaamidega seotud aruanded)",
  label145: "Aruande tüüp",
  label146: "Valige aruande tüüp...",
  label147: "Meedium",
  label148: "Valige meedium...",
  label149: "Üldine aruanne (Detailne)",
  label150: "Reporte discriminado por selección de medio (Resumen)",
  label151: "Reporte discriminado por selección de medio (Detallado)",
  label152: "Fecha inicial",
  label153: "Obligatorio (desde)",
  label154: "Fecha final",
  label155: "Obligatorio (hasta)",
  label156: "(Se mostrarán solo reportes relacionados con el uso de las playlists)",
  label157: "Descargar",
  label158: "Descargando...",
  label159: "Error, Algo salió mal generando el reporte.",
  label160: "El reporte se generó exitosamente.",
  label161: "Error, El reporte no se genero, no hay registros en ese intervalo de tiempo.",
  label162: "Reporte_de_",
  label163: "_(resumen)",
  label164: "_(detallado)",
  label165: "Nombre de usuario",
  label166: "Nombre del medio",
  label167: "Nombre del archivo",
  label168: "Horario desde",
  label169: "Horario hasta",
  label170: "Fecha de reproducción",
  label171: "Numero de reproducción",
  label172: "Rango desde",
  label173: "Rango hasta",
  label174: "Asignar pin de bloqueo,\n Quitar canciones con lenguaje inapropiado,\nConfigurar hirbenación.",
  label175: "Vali kasutaja...",
  label176: 'Saate seadistada erinevaid pleiliste või unerežiime iga nädala päeva erinevate ajaperioodide jaoks. Valige kasutaja ja seejärel klõpsake igal päeval paremal ääres olevat "+" sümbolit oma seadete tegemiseks.',
  label177: "Viga, teil pole aktiivseid kontosid, võtke ühendust teenusepakkujaga.",
  label178: 'Selles jaotises saate laadida üles reklaame ja kustutada või muuta juba üles laaditud reklaame. Seejärel saate "LOO, MUUDA JA SEADISTA JUHISED" all luua reklaamijuhtumeid, et reklaamid saaksid mängida kohtades.',
  label179: "Sisestage otsitava reklaami nimi...",
  label180: "Laadi reklaame",
  label181: "Loo, muuda \n ja seadista juhised",
  label182: 'Saate lisada reklaamijuhtumeid oma üles laaditud reklaamidele, et need saaksid mängida kohtades.',
  label183: "Lisa juhis",
  label184: 'Loo grupid ja seejärel "LOO, SEADISTA JA HALDA KASUTAJAID (KOHADE LÕIK)" valige kasutajad gruppidesse, mida soovite. Üks kasutaja võib kuuluda mitmesse gruppi.',
  label185: "Laadi meedium",
  label186: "Reklaamija",
  label187: "Bränd või toode",
  label188: "Kasutaja (Koht)",
  label189: "Kasutajagrupp",
  label190: "Prioriteet",
  label191: "Avalduste arv",
  label192: "Meediumi tüüp",
  label193: "Kuni",
  label194: "Asukohad",
  label195: "Versioonid",
  label196: "Piiramatu",
  label197: "Loo, muuda \n ja seadista kellad",
  label198: 'Saate lisada reklaamijuhtumeid oma üles laaditud reklaamidele.',
  label199: "Kellad",
  label200: "Kalender",
  label201: "Halda kellasid",
  label202: "Määra kellad tavalisele päevale",
  label203: "Määra kellad erilisele päevale",
  label204: "Kalender aktiveeritud",
  label205: "Kalender deaktiveeritud",
  label206: "Kasutaja kustutatud edukalt.",
  label207: "Viga, midagi läks valesti kasutaja kustutamisel.",
  label208: "Kas soovite kasutaja kustutada",
  label209: "Kasutaja kustutatakse",
  label210: "Viga, kasutajanime sees on tühikud (keelatud).",
  label211: "Viga, kasutaja peab olema 6-40 tähemärki pikk.",
  label212: "Viga, parool peab olema 6-40 tähemärki pikk.",
  label213: "Kasutaja loodi edukalt.",
  label214: "Viga, parool ja selle kinnitus ei ole samad.",
  label215: "Viga, parool on kohustuslik.",
  label216: "Viga, kasutajanimi on kohustuslik.",
  label217: "Muuda kasutajat",
  label218: "Viga, kasutajanimi sisaldab sümbolit # (keelatud).",
  label219: "Kasutaja muudetud edukalt.",
  label220: "Viga, midagi läks valesti kasutaja muutmisel.",
  label221: "Kloonimine õnnestus edukalt.",
  label222: "Viga, midagi läks valesti klooni tehes.",
  label223: "Algkäitaja kasutaja",
  label224: "Viga, midagi läks valesti grupi salvestamisel.",
  label225: "Grupp salvestati edukalt.",
  label226: "Viga, midagi läks valesti grupi muutmisel.",
  label227: "Grupp muudetud edukalt.",
  label228: "Grupp",
  label229: "kustutati edukalt.",
  label230: "Viga, midagi läks valesti grupi kustutamisel.",
  label231: "Kas soovite grupi kustutada",
  label232: "Grupp kustutatakse",
  label233: "Kohandamine salvestati edukalt.",
  label234: "Viga, midagi läks valesti kohandamise salvestamisel.",
  label235: "Kuva lemmikud",
  label236: "Näita või peida lemmikute riba (alumine riba)",
  label237: "Lisa PIN-koodi lukk",
  label238: "Muuda PIN-koodi lukku",
  label239: "Sul pole PIN-koodi",
  label240: "Teie PIN-koodi lukk on",
  label241: "Lisa PIN",
  label242: "Muuda PIN-koodi",
  label243: "Lukusta kohe",
  label244: "Rakenduse lukustamiseks on nüüd vajalik PIN-kood",
  label245: "Lukusta rakendus kohe",
  label246: "Lukusta rakendus käivitamisel",
  label247: "Rakenduse käivitamiseks on alati vajalik PIN-kood",
  label248: "Rakendus käivitub alati lukustatult",
  label249: "Muuda keelt",
  label250: "Muuda rakenduse keelt",
  label251: "Näita nuppe esitamisel",
  label252: "Näita või peida nuppe (mitte kunagi programmeerida, alati programmeerida, muuta, lisada)",
  label253: "Luba piirang eksplitsiitsele sisule",
  label254: "Piirab lugude programmeerimist, mis sisaldavad sobimatut või väärkasutatavat sisu",
  label255: "Uinaku režiim",
  label256: "Muuda uinaku seadistust",
  label257: "Lisa uinaku seadistus",
  label258: "Lisa",
  label259: "Rakenduse keel muudeti edukalt inglise keeleks.",
  label260: "Viga, midagi läks valesti rakenduse keele muutmisel inglise keeleks.",
  label261: "Rakenduse keel muudeti edukalt hispaania keeleks.",
  label262: "Viga, midagi läks valesti rakenduse keele muutmisel hispaania keeleks.",
  label263: "Rakenduse keel muudeti edukalt prantsuse keeleks.",
  label264: "Viga, midagi läks valesti rakenduse keele muutmisel prantsuse keeleks.",
  label265: "Lemmikute riba on edukalt sisse lülitatud.",
  label266: "Lemmikute riba on edukalt välja lülitatud.",
  label267: "Viga, midagi läks valesti lemmikute riba sisselülitamisel.",
  label268: "Viga, midagi läks valesti lemmikute riba väljalülitamisel.",
  label269: "Rakendus on edukalt lukustatud.",
  label270: "Rakendus on edukalt avatud.",
  label271: "Viga, midagi läks valesti rakenduse lukustamisel.",
  label272: "Viga, midagi läks valesti rakenduse avamisel.",
  label273: "Käivitamisel rakenduse lukustamise funktsioon on edukalt sisse lülitatud.",
  label274: "Käivitamisel rakenduse lukustamise funktsioon on edukalt välja lülitatud.",
  label275: "Viga, midagi läks valesti käivitamisel rakenduse lukustamise funktsiooni sisselülitamisel.",
  label276: "Viga, midagi läks valesti käivitamisel rakenduse lukustamise funktsiooni väljalülitamisel.",
  label277: "Esitamise nupud on edukalt sisse lülitatud.",
  label278: "Esitamise nupud on edukalt välja lülitatud.",
  label279: "Viga, midagi läks valesti esitamise nuppude sisselülitamisel.",
  label280: "Viga, midagi läks valesti esitamise nuppude väljalülitamisel.",
  label281: "Eksplitsiitse sisu piiramine on edukalt sisse lülitatud.",
  label282: "Eksplitsiitse sisu piiramine on edukalt välja lülitatud.",
  label283: "Viga, midagi läks valesti eksplitsiitse sisu piirangu sisselülitamisel.",
  label284: "Viga, midagi läks valesti eksplitsiitse sisu piirangu väljalülitamisel.",
  label285: "Uinaku režiim on edukalt sisse lülitatud.",
  label286: "Uinaku režiim on edukalt välja lülitatud.",
  label287: "Viga, midagi läks valesti uinaku režiimi sisselülitamisel.",
  label288: "Viga, midagi läks valesti uinaku režiimi väljalülitamisel.",
  label289: "Lisa \n PIN",
  label290: "Muuda \n PIN-koodi",
  label291: "Praegune PIN-kood",
  label292: "Uus PIN-kood",
  label293: "Kohustuslik (PIN-kood peab olema 4 numbrit)",
  label294: "Kinnita oma uus PIN-kood",
  label295: "Viga, teie PIN-koodis on lubatud ainult numbrid ja 0000 pole lubatud",
  label296: "Viga, PIN-kood peab olema 4 numbrit.",
  label297: "Viga, kinnitus ei ole sama, mis uus PIN-kood.",
  label298: "Viga, PIN-kood on sama mis eelmine. Palun sisestage uus PIN-kood.",
  label299: "PIN-kood salvestati edukalt.",
  label300: "PIN-kood muudeti edukalt.",
  label301: "Viga, midagi läks valesti PIN-koodi salvestamisel.",
  label302: "Viga, midagi läks valesti PIN-koodi muutmisel.",
  label303: "Lisa uinaku seadistus",
  label304: "Muuda uinaku seadistust",
  label305: "Seisund",
  label306: "Uinaku graafik",
  label307: "Peale",
  label308: "Iga päev",
  label309: "Määratud päevadel",
  label310: "Uinaku seadistus salvestati edukalt.",
  label311: "Viga, midagi läks valesti uinaku seadistuse salvestamisel.",
  label312: "Uinaku seadistus muudeti edukalt.",
  label313: "Viga, midagi läks valesti uinaku seadistuse muutmisel.",
  label314: "Plaanikul on edukalt sisse lülitatud.",
  label315: "Plaanikul on edukalt välja lülitatud.",
  label316: "Viga, midagi läks valesti plaaniku sisselülitamisel.",
  label317: "Viga, midagi läks valesti plaaniku väljalülitamisel.",
  label318: "Plaanikul Sisse Lülitatud",
  label319: "Plaanikul Välja Lülitatud",
  label320: "Lisa plaan",
  label323: "Minu esitusloendid",
  label324: "Juhuslik",
  label325: "Plaan salvestati edukalt.",
  label326: "Viga, midagi läks valesti plaani salvestamisel.",
  label327: 'Viga, "Alates" kellaaeg on väiksem kui "Kuni" kellaaeg.',
  label328: "Viga, selles ajavahemikus on juba olemas plaan.",
  label329: "Laadi \n reklaam üles",
  label330: "Plaan kustutati edukalt.",
  label331: "Viga, midagi läks valesti plaani kustutamisel.",
  label332: "Juhuslik on edukalt sisse lülitatud.",
  label333: "Juhuslik on edukalt välja lülitatud.",
  label334: "Viga, midagi läks valesti juhusliku sisselülitamisel.",
  label335: "Viga, midagi läks valesti juhusliku väljalülitamisel.",
  label336: "Loobi \n oma reklaam \n või kliki \n selles piirkonnas",
  label337: "Suurus",
  label338: "(Ainult mp3. Maksimaalne suurus 5 MB).",
  label339: "Laadi reklaam üles",
  label340: "Laadin reklaami üles...",
  label341: "Viga, (AINULT MP3).",
  label342: "Reklaami nimi peab olema 1 kuni 50 märki.",
  label343: "Viga, midagi läks valesti reklaami üleslaadimisel, veendu, et see oleks MP3 fail ja et selle suurus ei ületaks 5 MB.",
  label344: "Reklaam laeti edukalt üles.",
  label345: "Reklaam",
  label346: "Viga, midagi läks valesti reklaami kustutamisel",
  label347: "Kas soovid reklaami kustutada",
  label348: "Reklaam kustutatakse",
  label349: "Iga päev",
  label350: "Esmaspäevast reedeni",
  label351: "Laupäev ja pühapäev",
  label352: "Täpne päev",
  label353: "Täpne kellaaeg",
  label354: "Intervall",
  label355: "Iga",
  label356: "Sagedus",
  label357: "laulu",
  label358: "Reklaamide arv ühe sessiooni kohta",
  label359: "Sessiooni algus",
  label360: "Sessiooni lõpp",
  label361: "Lisa \n reklaamiplaan",
  label362: "Päevad",
  label363: "Kellaaeg",
  label364: "Ajavahemik",
  label365: "Reklaamide arv ühe sessiooni kohta",
  label366: "Iga x minuti tagant",
  label367: "Iga x laulu järel",
  label368: "Päev või päevad",
  label369: "Tund",
  label370: "Päev",
  label371: "Määra algus",
  label372: "Määra lõpp",
  label373: "Viga, selle nimega plaan on juba olemas.",
  label374: "Viga, selles ajavahemikus on juba olemas plaan.",
  label375: "Viga, selles täpse tunni ajal on juba olemas plaan.",
  label376: "Meedium",
  label377: "Viga, midagi läks valesti meediumi kustutamisel",
  label378: "Kas soovid meediumi kustutada",
  label379: "Meedium kustutatakse",
  label380: "kuni",
  label381: "Kõrge",
  label382: "Tavaline",
  label383: "Saate üles laadida igat tüüpi meediat (reklaami, kuulutust, laulu vahele, raadio ID) \n või luua oma tüübi. Valige üles laaditav fail, andke sellele nimi, märkige, milline tüüp see on, \n määrake kõik väärtused, kui on versioone, saate need üles laadida ja märkida kohad, kus see heliseb.",
  label384: "Määrake algus ja lõpp",
  label385: "Vali…",
  label386: "Algus",
  label387: "Lõpp",
  label388: "Bränd või toode",
  label389: "Reklaamija ettevõte",
  label390: "Väärtused",
  label391: "Lõpmatud",
  label392: "Kohad, kus \n avaldatakse",
  label393: "Kõik kasutajad (kohalikud)",
  label394: "Vali kasutajad (kohalikud) või grupid",
  label395: "Kohalikud kasutajad",
  label396: "Grupid",
  label397: "Viga, midagi läks valesti meedia üleslaadimisel.",
  label398: "Meedia salvestati edukalt.",
  label399: `Viga, peate valima prioriteedi.`,
  label400: `Viga, te ei valinud ühtegi avaldamise kohta.`,
  label401: `Viga, peate valima brändi või toote.`,
  label402: `Viga, peate valima reklaamija.`,
  label403: `Viga, peate valima meedia tüübi.`,
  label404: `Viga, peate lisama meedia nime.`,
  label405: `Filtreeri järgi`,
  label406: `Lohista siia \n kasutajad või grupid`,
  label407: `Viga, midagi läks valesti meedia muutmisel.`,
  label408: `Meedia muudeti edukalt.`,
  label409: `Lisa \n meedia tüüp`,
  label410: `Viga, midiatüübi salvestamisel ilmnes viga.`,
  label411: `Meediatüüp on edukalt salvestatud.`,
  label412: `Viga, meediatüübi nimi peab olema 1 kuni 50 tähemärki pikk.`,
  label413: `Lisa \n reklaamija`,
  label414: `Viga, midagi läks valesti reklaamija salvestamisel.`,
  label415: `Reklaamija on edukalt salvestatud.`,
  label416: `Viga, reklaamija nimi peab olema 1 kuni 50 tähemärki pikk.`,
  label417: `Lisa \n kaubamärk või toode`,
  label418: `Otsi siit...`,
  label419: `Viga, midagi läks valesti kaubamärgi või toote salvestamisel.`,
  label420: `Kaubamärk või toode on edukalt salvestatud.`,
  label421: `Viga, kaubamärgi või toote nimi peab olema 1 kuni 50 tähemärki pikk.`,
  label422: `Laadi \n versioonid üles`,
  label423: `Versioon`,
  label424: `Viga, versiooni kustutamisel ilmnes viga`,
  label425: `Kas soovid versiooni kustutada`,
  label426: `Versioon kustutatakse`,
  label427: `Tühista`,
  label428: `Viga, versiooni laadimisel ilmnes viga.`,
  label429: `Versioon on edukalt üles laaditud.`,
  label430: `Viga. Versiooni ei õnnestunud laadida, sulge aken ja proovi uuesti.`,
  label431: `Kalender edukalt aktiveeritud.`,
  label432: `Kalender edukalt deaktiveeritud.`,
  label433: `Viga, kalendri aktiveerimisel ilmnes viga.`,
  label434: `Viga, kalendri deaktiveerimisel ilmnes viga.`,
  label435: `Loo kell`,
  label436: `Kella loomiseks peate lohistama vasakul kuvatavaid meediume paremale. Seejärel saate neid üles või alla lohistada nende järjestamiseks. "REKLAAM" vaikimisi sisaldab 16 reklaami (spoti või reklaami). Ülejäänud sisaldavad vaikimisi ainult 1 meediumi. Mõlemal juhul saab seda numbrit muuta pliiatsi ikooni vajutamisega.`,
  label437: `Meediumi otsing`,
  label438: `Lohista \n meediumid siia`,
  label439: `Viga, kella nimi peab olema 1 kuni 50 tähemärki pikk.`,
  label440: `Viga, kella salvestamisel ilmnes viga.`,
  label441: `Kell on edukalt salvestatud.`,
  label442: `Viga, te ei ole lisanud ühtegi meediumi.`,
  label443: `Halda \n kellu`,
  label444: `Muuda või kustuta, kui kustutate kella, kaob see kalendrist.`,
  label445: `Kell`,
  label446: `Viga, kella kustutamisel ilmnes viga.`,
  label447: `Kas soovite kella kustutada`,
  label448: `Kell kustutatakse`,
  label449: `Kogus`,
  label450: `Blokeerida`,
  label451: `Viga, midagi läks valesti muutmise juures`,
  label452: `Kella muutmiseks peate lohistama vasakul kuvatavaid meediume paremale. Seejärel saate neid üles või alla lohistada nende järjestamiseks. "REKLAAM" vaikimisi sisaldab 16 reklaami (spoti või reklaami). Ülejäänud sisaldavad vaikimisi ainult 1 meediumi. Mõlemal juhul saab seda numbrit muuta pliiatsi ikooni vajutamisega.`,
  label453: `Kell on edukalt muudetud.`,
  label454: `Määrake kell iga ajavahemiku jaoks. Võite jätta mõned ajavahemikud kellata, sel juhul mängitakse ainult muusikat (ilma meediumideta).`,
  label455: `Kella pole määratud`,
  label456: `Viga, kella tavapärase päeva salvestamisel ilmnes viga.`,
  label457: `Kell tavapäeval on edukalt salvestatud.`,
  label458: `Määra kellad eripäeval`,
  label459: `Kordused`,
  label460: `Konkreetne päev`,
  label461: `Päevade vahemik`,
  label462: `Viga, eripäeva nimi peab olema 1 kuni 50 tähemärki pikk.`,
  label463: `Kell eripäeval on edukalt salvestatud.`,
  label464: `Viga, midagi läks valesti kella salvestamisel eripäeval.`,
  label465: `Viga, sel konkreetsel päeval on juba kell määratud ja selle kordus kattub või on mõne teise vahemiku sees.`,
  label466: `Viga, sellel vahemikul on juba kell määratud.`,
  label467: `Viga, lõppkuupäev on suurem kui alguskuupäev.`,
  label468: `Muuda või kustuta \n päeva struktuur`,
  label469: `Muuda või kustuta, igasuguse valiku muutmisel muudetakse seda automaatselt, kui kustutate päeva, kaob see kalendrist.`,
  label470: `See ajavahemik päeva struktuuris on edukalt värskendatud.`,
  label471: `Viga. midagi läks valesti selle ajavahemiku muutmisel.`,
  label472: `Viimane ühendus`,
  label473: `Ühendatud `,
  label474: `Lõpeta`,
  label475: `Halda \n meediume`,
  label476: `Halda \n reklaamiettevõtteid`,
  label477: `Halda \n kaubamärke või tooteid`,
  label478: `Muuda või kustuta, ei saa kustutada meediatüüpi, mis on juba mõnele meediumile määratud.`,
  label479: `Muuda või kustuta, ei saa kustutada reklaamiettevõtet, mis on juba mõnele meediumile määratud.`,
  label480: `Muuda või kustuta, ei saa kustutada kaubamärki või toodet, mis on juba mõnele meediumile määratud.`,
  label481: `Meediatüüp`,
  label482: `Reklaamiettevõte`,
  label483: `Kaubamärk või toode`,
  label484: `Viga, midagi läks valesti meediatüübi kustutamisel`,
  label485: `Viga, midagi läks valesti reklaamiettevõtte kustutamisel`,
  label486: `Viga, midagi läks valesti kaubamärgi või toote kustutamisel`,
  label487: `Kas soovite kustutada meediatüübi`,
  label488: `Kas soovite kustutada reklaamiettevõtte`,
  label489: `Kas soovite kustutada kaubamärgi või toote`,
  label490: `Meediatüüp kustutatakse`,
  label491: `Reklaamiettevõte kustutatakse`,
  label492: `Kaubamärk või toode kustutatakse`,
  label493: `Ok`,
  label494: `Hoiatus`,
  label495: `Seda meediatüüpi ei saa kustutada, kuna see on määratud ühele või mitmele juba üles laaditud meediumile. Kui soovite selle kustutada, peate kõigepealt kustutama sellele määratud meediumid.`,
  label496: `Seda ettevõtet või reklaamijat ei saa kustutada, kuna see on määratud ühele või mitmele juba üles laaditud meediumile. Kui soovite selle kustutada, peate kõigepealt kustutama sellele määratud meediumid.`,
  label497: `Seda kaubamärki või toodet ei saa kustutada, kuna see on määratud ühele või mitmele juba üles laaditud meediumile. Kui soovite selle kustutada, peate kõigepealt kustutama sellele määratud meediumid.`,
  label498: `Olete ületanud oma tellimuse jaoks saadaval olevate meediumide üleslaadimise piirangu. Võtke meiega ühendust oma tellimuse piirangute laiendamiseks.`,
  label499: `Olete ületanud oma tellimuse jaoks saadaval olevate meediumiversioonide piirangu. Võtke meiega ühendust oma tellimuse piirangute laiendamiseks.`,
  label500: `Olete ületanud oma tellimuse jaoks saadaval oleva reklaamimahu piirangu. Võtke meiega ühendust oma tellimuse piirangute laiendamiseks.`,
  label501: `Olete ületanud oma tellimuse jaoks saadaval olevate meediatüüpide loomise piirangu. Võtke meiega ühendust oma tellimuse piirangute laiendamiseks.`,
  label502: `Teie meediatüüpide loomise piirang on hetkel: `,
  label503: `Teie meediumide üleslaadimise piirang on hetkel: `,
  label504: `Teie meediumiversioonide üleslaadimise piirang on hetkel: `,
  label505: `Teie reklaamimahu piirang on hetkel: `,
  label506: `Kas soovite kustutada reklaamiplaani`,
  label507: `Reklaamiplaan kustutatakse`,
  label508: `Lisa / eemalda`,
  label509: `Laaditud reklaamide arv`,
  label510: `Lisa või eemalda reklaame`,
  label511: `Lisa või eemalda reklaame, peate lohistama vasakul kuvatavaid reklaame paremale. Seejärel saate neid üles või alla lohistada nende järjestamiseks.`,
  label512: `Reklaamid reklaamiplaani jaoks`,
  label513: `Reklaamide otsing`,
  label514: `Lohista \n reklaamid siia`,
  label515: `Viga, reklaamiplaani salvestamisel ilmnes viga.`,
  label516: `Reklaamiplaan on edukalt salvestatud.`,
  label517: `Viga, reklaamiplaani muutmisel ilmnes viga.`,
  label518: `Reklaamiplaan on edukalt muudetud.`,
  label519: `Aruanne valiku alusel kustutatud meediumist (Kokkuvõte)`,
  label520: `Aruanne valiku alusel kustutatud meediumist (Üksikasjalik)`,
  label521: `Kustutatud meedium`,
  label522: `Minu stiilid`,
  label523: `Saate luua oma muusikastiile ja seejärel minna "HALLA LUGUSID" ja määrata neile laulud, mida soovite, et need kuuluksid sellesse või nendesse stiilidesse.`,
  label524: `Kirjuta stiili nimi otsingusse...`,
  label525: `Stiil`,
  label526: `Viga, stiili kustutamisel ilmnes viga.`,
  label527: `Kas soovite stiili kustutada`,
  label528: `Stiil kustutatakse`,
  label529: `Loo \n stiil`,
  label530: `Loo stiil`,
  label531: `Viga, stiili salvestamisel ilmnes viga.`,
  label532: `Stiil on edukalt salvestatud.`,
  label533: `Viga, stiili nimi peab olema 1 kuni 50 tähemärki pikk.`,
  label534: `Viga, stiili muutmisel ilmnes viga.`,
  label535: `Stiil on edukalt muudetud.`,
  label536: `Muuda \n stiili`,
  label537: `Laulude haldur`,
  label538: `Saate muuta laulu stiili kasutaja loodud stiiliks, muuta laulu nime, märkida selle eksplitsiidiks (ebasobivaks keeleks) või märkida lemmikuks või mitte soovitud. Samuti saate selle määrata manuaalsele esitusloendile.`,
  label539: `Sisesta siia laulu või esitaja nimi, mida otsid...`,
  label540: `Otsi`,
  label541: `Näita`,
  label542: `Kõik`,
  label543: `Ainult muudetud`,
  label544: `Lemmikud`,
  label545: `Mittelemmikud`,
  label546: `Muuda \n laulu`,
  label547: `Vaikimisi`,
  label548: `Viga, nime ja parooli väljad on kohustuslikud.`,
  label549: `Originaalnimi`,
  label550: `Muusika stiil`,
  label551: `Tuju`,
  label552: `Eelistus`,
  label553: `Laul on eksplitsiitne`,
  label554: `(ebasobiv või ahistav sisu)`,
  label555: `Viga, midagi läks valesti laulu muutmisel.`,
  label556: `Laul on edukalt muudetud.`,
  label557: `Muusikarakenduse kasutamise õigused, vajutage muudatuste rakendamiseks salvestamisnuppu.`,
  label558: `Kas soovite selle laulu lemmikuks tühistada`,
  label559: `Kas soovite selle laulu lemmikuks märkida`,
  label560: `see ei lisata alati, ainult tavaliselt.`,
  label561: `see lisatakse alati selle laulu stiiliga seotud esitusloenditesse.`,
  label562: `Viga, midagi läks valesti selle laulu lemmikuks tühistamisel.`,
  label563: `Viga, midagi läks valesti selle laulu lemmikuks märkimisel.`,
  label564: `Laul on edukalt tühistatud lemmikuks märkimisest.`,
  label565: `Laul on edukalt märgitud lemmikuks.`,
  label566: `Kas soovite selle laulu mitte soovitavaks tühistada`,
  label567: `Kas soovite selle laulu mitte soovitavaks märkida`,
  label568: `see mängitakse uuesti tavaliselt.`,
  label569: `see ei mängi uuesti.`,
  label570: `Viga, midagi läks valesti selle laulu mitte soovitavaks tühistamisel.`,
  label571: `Viga, midagi läks valesti selle laulu mitte soovitavaks märkimisel.`,
  label572: `Laul on edukalt tühistatud mitte soovitavaks märkimisest.`,
  label573: `Laul on edukalt märgitud mitte soovitavaks.`,
  label574: `Vali esitusloend...`,
  label575: `Riik`,
  label576: `Linn`,
  label577: `Maakond`,
  label578: `Viga, nimi on juba kasutusel, palun valige teine ​​nimi.`,
  label579: `Saate luua oma esitusloendeid kahte tüüpi. Automaatne: valite stiilid ja programm valib laulud. Käsitsi: valite laulud ükshaaval. Samuti saate esitusloendeid kustutada või olemasolevaid muuta.`,
  label580: `Esitusloend`,
  label581: `Viga, midagi läks valesti esitusloendi kustutamisel.`,
  label582: `Kas soovite selle esitusloendi kustutada`,
  label583: `Esitusloend kustutatakse`,
  label584: `Käsitsi loodud esitusloend`,
  label585: `Automatiseeritud esitusloend`,
  label586: `Kirjuta siia esitusloendi nimi, mida otsid...`,
  label587: `Loo esitusloend`,
  label588: `Reklaamid reklaamiplaanis`,
  label589: `Loo \n esitusloend`,
  label590: `Vali võimalus.`,
  label591: `Automatiseeritud`,
  label592: `Käsitsi`,
  label593: `valite stiilid ja programm valib laulud.`,
  label594: `valite laulud ükshaaval.`,
  label595: `Vali esitusloendi tüüp...`,
  label596: `Uue esitusloendi nimi (kohustuslik)...`,
  label597: `Kirjeldus (valikuline)...`,
  label598: `Edasi`,
  label599: `Lohista stiile, mida soovid vasakult paremale. Saate stiili mitu korda lohistada, et see programmeeriks rohkem laule selles stiilis. Seejärel saate parempoolses paneelis stiile üles või alla lohistada, et neid järjestada. Samuti saate muuta stiili sätteid, et see programmeeriks rahulikumaid või tempokamaid laule jne.`,
  label600: `Kirjuta siia stiili nimi, mida otsid...`,
  label601: `Muusikastiilide otsing`,
  label602: `Stiilid`,
  label603: `Minu stiilid`,
  label604: `Kestvus (tundides)`,
  label605: `Keela eksplitsiitsed laulud`,
  label606: `Lohista stiilid siia`,
  label607: `Viga, midagi läks valesti automaatse esitusloendi loomisel.`,
  label608: `Automaatne esitusloend loodi edukalt.`,
  label609: `Viga, automaatse esitusloendi loomiseks pole ühtegi stiili valitud.`,
  label610: `Muuda \n automaatset esitusloendit`,
  label611: `Lohista vasakult paremale stiile, mida soovid. Saate stiili mitu korda lohistada, et see programmeeriks rohkem laule selles stiilis. Seejärel saate parempoolses paneelis stiile üles või alla lohistada, et neid järjestada. Samuti saate muuta stiili sätteid, et see programmeeriks rahulikumaid või tempokamaid laule jne.`,
  label612: `Viga, midagi läks valesti automaatse esitusloendi muutmisel.`,
  label613: `Automaatne esitusloend muudeti edukalt.`,
  label614: `Viga, esitusloendi nimi peab sisaldama vähemalt ühte tähemärki.`,
  label615: `Muuda`,
  label616: `Ainult hittidele`,
  label617: `Hittidele ja soovitatavatele`,
  label618: `Hittidele, soovitatavatele ja mitmekesistele`,
  label619: `Otsige laule nime või esitaja nime järgi. Seejärel lohistage iga laul vasakult paremale. Parempoolses paneelis saate laule üles või alla lohistada, et neid järjestada.`,
  label620: `Laulude otsing`,
  label621: `Lohista laulud siia`,
  label622: `Viga, midagi läks valesti käsitsi loodud esitusloendi loomisel.`,
  label623: `Käsitsi loodud esitusloend loodi edukalt.`,
  label624: `Viga, loendi kohta saab lisada kuni`,
  label625: `Muuda \n käsitsi loodud esitusloendi`,
  label626: `Viga, midagi läks valesti käsitsi loodud esitusloendi muutmisel.`,
  label627: `Käsitsi loodud esitusloend muudeti edukalt.`,
  label629: `Laulude arv`,
  label630: `(Administraator) - `,
  label631: `(Kasutaja) - `,
  label632: `käsitsi loodud esitusloendid`,
  label633: `Neid saab lisada ainult teie loodud esitusloenditesse.`,
  label634: `Sihtkoha esitusloendid`,
  label635: `Lisamine...`,
  label636: `Laul lisati edukalt.`,
  label637: `Viga, midagi läks valesti laulu lisamisel.`,
  label638: `Vali grupp...`,
  label639: `Ühenduse tüüp`,
  label640: `Vali ühenduse tüüp...`,
  label641: `Täpne otsing`,
  label642: `Tulemused: `,
  label643: `Rakenda filter`,
  label644: `Kirjuta siia kasutajanime, mida otsid...`,
  label645: `Ei eksplitsiitseid laule`,
  label646: `Eksplitsiitsed laulud`,
  label647: `Vali stiil...`,
  label648: `Vali kasutaja stiil...`,
  label649: `Vali tuju...`,
  label650: `Eksplitsiitne sisu`,
  label651: `Vali sisutüüp...`,
  label652: `Kasutaja kriteerium`,
  label653: `Kasutaja stiilid`,
  label654: `Heli faili viga.`,
  label655: `Praegune parool`,
  label656: `Riik`,
  label657: `Maakond`,
  label658: `Linn`,
  label659: `Suspendeerimine`,
  label660: `Viga, kellaaeg peab olema suurem kui lõpuaeg.`,
  label661: `Viga, tundide erinevus peab olema vähemalt 5 minutit.`,
  label662: `Viga, pead valima vähemalt ühe päeva.`,
  label663: `Viga, on (`,
  label664: `) plaani(d), mis segavad soovitud ajavahemikku valitud päevadel.`,
  label665: `Kõik päeva plaanid on kustutatud, `,
  label666: `Viga, midagi läks valesti kõikide päeva plaanide kustutamisel, `,
  label667: `kas soovid kõik päeva plaanid kustutada?`,
  label668: `Kõik plaanid selle päeva jaoks kustutatakse, `,
  label669: `Rakenduse üldhelitugevus`,
  label670: `Rakenduse helitugevuse skaala ulatub 0 kuni 100.`,
  label671: `Rakenduse helitugevus on edukalt värskendatud.`,
  label672: `Viga rakenduse helitugevuse värskendamisel.`,
  label673: `Vaigista rakendus`,
  label674: `Heli rakendus`,
  label675: `Lülita rakenduse heli välja`,
  label676: `Lülita rakenduse heli sisse`,
  label677: "Rakendus on vaigistatud edukalt.",
  label678: "Rakenduse heli on edukalt taastatud.",
  label679: "Viga, midagi läks valesti rakenduse vaigistamisel.",
  label680: "Viga, midagi läks valesti rakenduse vaigistamisel.",
  label681: "Lähtesta kõik",
  label682: "Sinu kasutajate kohandused on edukalt lähtestatud.",
  label683: "Viga, midagi läks valesti kasutajate kohanduste lähtestamisel.",
  label684: "Kas soovid lähtestada oma kasutajate kohandused?",
  label685: "Sinu kasutajate kohandused kustutatakse ja neile taastatakse vaikesätted.",
  label686: "Ilma kirjelduseta",
  root: {
      1: "JUMAL",
      2: "SUPER-JUUR",
      3: "JUUR",
      4: "SUPER-ADMINISTRAATOR",
      5: "ADMINISTRAATOR",
      6: "JÄRELEVALVE",
      7: "KASUTAJA-HALDUR",
      8: "KASUTAJA-INDIVIDUAALNE",
    },
  label687: "Konto",
  label688: "Kontohaldus",
  label689: "Parooli muutmine",
  label690: "Kontoandmete muutmine (ettevõtte nimi, aadress, CIF/NIF jne)",
  label691: "Arvete allalaadimine või deebet-/krediitkaardiandmete uuendamine",
  label692: "Tellimuse muutmine või tühistamine",
  label693: "Kehtiva konto parooli muutmine.",
  label694: "Uus parool",
  label695: "Parooli muutmine õnnestus.",
  label696: "Viga, midagi läks valesti parooli muutmisel amemberis.",
  label697: "Viga, midagi läks valesti parooli muutmisel.",
  label698: "Installeeri administraatorõigustega. Windows 10 puhul sisesta administraatori kasutajanime ja parool rakenduse paigaldamisel. Windows 7 korral peab paigaldamist teostama administraatoriõigustega kasutajakonto. Kui soovid installida standardkasutajakontole (mitte administraator), muuda kasutaja administraatoriks, paigalda programm ja muuda seejärel tagasi standardkasutajaks. Igasugusel juhul veendu, et ükski programm ei blokeeri rakendusel internetiühendust (antiviirus, tulemüür jne).",
  label699: 'Programmi installimiseks Mac OS arvutis tee järgmist: Mine Süsteemi Eelistused Turvalisus ja Privaatsus. Klõpsa alumises vasakus nurgas olevat lukusümbolit ja sisesta oma kasutajasalasõna Maci lukust avamiseks. Märgi "Iga sait" ruut "Kolmanda osapoole rakendustele lubamine" piirkonnas.',
  label700: "Soovitame installida rakenduse ja lubada automaatsed uuendused otse Google Play poest; kui installimine toimub poest väljaspool, pead ise haldama rakenduse värskendusi, kontrollides selle lehele postitatud viimast rakenduse versiooni.",
  label701: "Allalaadimised",
  label702: "Oluline",
  label703: "See ala on avalik, siia pole vaja parooli, kopeeri allpool oleva lehe link ja jaga seda kõigile kasutajatele, kes peavad programmi alla laadima.",
  label704: "Viimane rakenduse versioon",
  label705: "Mine",
  label706: "Juhendid",
  label707: "Tehnilised nõuded",
  label708: 'Operatsioonisüsteem Windows 7 või uuem (soovitatav Windows 10), Windows N versioonis rakendust ei saa kasutada, kuna sellel puudub "Media Feature Pack", vaata sel juhul toetusala.',
  label709: "16 GB vaba kõvakettaruumi (soovitatav 32 GB või rohkem)",
  label710: "4 GB RAM-i või rohkem",
  label711: "Heli kaart",
  label712: "Internetiühendus",
  label713: "Protsessori arhitektuur ARM või ARM64",
  label714: "2 GB RAM-i (soovitatav 4 GB või rohkem)",
  label715: "Allalaadimise ala URL-i kopeerimine",
  label716: "Veebirakenduse URL-i kopeerimine",
  label717: "Toetus",
  label718: "Juhiseid platvormi kasutamiseks ja sageli esitatavate küsimuste saamiseks palun vaadake juhiseid <font color=#FFFFFFF'>siin</font>",
  label719: "Abi ja sagedased küsimused",
  label720: "Lisavalikud",
  label721: "Lisainfo",
  label722: "Sertifikaat",
  label723: "Allalaadimisala URL, edukalt kopeeritud.",
  label724: "Veebirakenduse URL, edukalt kopeeritud.",
  label725: "Värskenda, tühista muudatused",
  label726: "Lähtesta kõigi kasutajate isikupärastamine",
  label727: "Laadi alla sertifikaat",
  label728: "certificado_freedom",
  label729: "Teave",
  label730: "Mac OS Catalina (10.15.7) või uuem operatsioonisüsteem (toetab ka vanemaid Catalina versioone, kuid nõuab programmi käsitsi värskendamist)",
  label731: "Muusika",
  label732: "Kas soovite välja logida?",
  label733: "Võta ühendust piletiga",
  label734: "Võta ühendust WhatsAppiga",
  label735: "Administraatori poolt peatatud kasutaja",
  label736: "Peata",
  label737: "Aktiveeri",
  label738: "Administraatori poolt peatatud",
  label739: "Kasutaja on edukalt aktiveeritud",
  label740: "Kasutaja on edukalt peatatud",
  label741: "Viga, midagi läks valesti kasutaja aktiveerimisel",
  label742: "Viga, midagi läks valesti kasutaja peatamisel",
  label743: "Lemmikmängu loendid",
  label744: "Peata",
  label745: "Aktiveeri",
  label746: "Kas soovite selle kasutaja ajutiselt peatada?",
  label747: "Kas soovite selle kasutaja aktiveerida?",
  label748: "Rakenduse load",
  label749: "Õigus on edukalt aktiveeritud",
  label750: "Õigus on edukalt deaktiveeritud",
  label751: "Viga õiguse aktiveerimisel",
  label752: "Viga õiguse deaktiveerimisel",
  label753: "PIN-i päring on aktiveeritud",
  label754: "PIN-i päring on deaktiveeritud",
  label755: "Viga PIN-i päringu aktiveerimisel",
  label756: "Viga PIN-i päringu deaktiveerimisel",
  label757: "PIN-i päring, aktiveeritud",
  label758: "PIN-i päring, deaktiveeritud",
  label759: "Lisa",
  label760: "Mänguloend on edukalt lisatud lemmikute hulka",
  label761: "Ei lisatud, sest loend on juba lemmikutes olemas",
  label762: "Viga, midagi läks valesti loendi lisamisel lemmikutesse",
  label763: "Lisamine...",
  label764: "Lisa lemmikmänguloenditesse",
  label765: "Alias",
  label766: "Viga, parool sisaldab tühikuid (pole lubatud).",
  label767: "Viga, parooli tuleb kinnitada.",
  label768: "Rakenduse õigused",
  label769: "Litsentsid",
  label770: "Peatatud kasutajad",
  label771: "Rakenduse keel on edukalt muudetud portugali keeleks.",
  label772: "Viga, midagi läks valesti rakenduse keele muutmisel portugali keeleks.",
  label773: "Blokeeritud esitajad",
  label774: "Lisa blokeeritud esitajatesse",
  label775: "Sisesta otsitava esitaja nimi...",
  label776: "Esitaja on edukalt lisatud blokeeritud esitajate hulka",
  label777: "Esitajat ei lisatud, sest see on juba blokeeritud esitajate nimekirjas",
  label778: "Viga, midagi läks valesti esitaja lisamisel blokeeritud esitajatesse",
  label779: "Esitaja",
  label780: "on edukalt eemaldatud blokeeritud esitajate nimekirjast.",
  label781: "Viga, midagi läks valesti esitaja eemaldamisel sellest nimekirjast",
  label782: "Kas soovite blokeeritud esitajate nimekirjast eemaldada",
  label783: "Esitaja eemaldatakse",
  label784: "blokeeritud esitajate hulgast.",
  label785: "Seadistamine",
  label786: "Kehtetu tellimus, võtke ühendust teenusepakkujaga",
  label787: `Viga, olete saavutanud lepinguliste kontode limiidi.`,
  label788: `Viga, kasutaja loomisel läks midagi valesti.`,
  label789: "Teil ei ole luba kasutajaid luua, redigeerida ega kustutada.",
  label790: "Teil ei ole luba litsentsidele juurde pääseda ega neid vaadata.",
  label791: "Teil ei ole luba lähtestada kõigi kasutajate isikupärastamist.",
  label792: "Teil ei ole luba vaadata kasutaja lemmikpleilist.",
  label793: "Teil ei ole luba lisada või eemaldada kasutaja lemmikpleilisti.",
  label794: "Teil ei ole luba vaadata kasutaja pleilisti planeerijat.",
  label795: "Teil ei ole luba lisada, redigeerida ega kustutada pleilisti kasutaja pleilisti planeerijas.",
  label796: "Teil ei ole luba vaadata kasutaja reklaamide planeerijat.",
  label797: "Teil ei ole luba lisada, redigeerida ega kustutada reklaame kasutaja reklaamide planeerijas.",
  label798: "Teil ei ole luba kasutajaid kloonida.",
  label799: "Teil ei ole luba kasutaja eelistustele juurde pääseda.",
  label800: "Teil ei ole luba kasutaja isikupärastamisele juurde pääseda.",
  label801: "Teil ei ole luba kasutaja rakenduste lubadele juurde pääseda.",
  label802: "Teil ei ole luba kasutajat peatada.",
  label803: "Teil ei ole luba vaadata administraatori pleiliste.",
  label804: "Teil ei ole luba administraatori pleiliste luua, redigeerida ega kustutada.",
  label805: "Teil ei ole luba vaadata administraatori lemmikpleiliste.",
  label806: "Teil ei ole luba lisada ega eemaldada administraatori lemmikpleiliste.",
  label807: "Teil ei ole luba vaadata administraatori stiile.",
  label808: "Teil ei ole luba administraatori stiile luua, redigeerida ega kustutada.",
  label809: "Teil ei ole luba muusika haldurile juurde pääseda.",
  label810: "Teil ei ole luba vaadata blokeeritud esinejaid.",
  label811: "Teil ei ole luba lisada ega eemaldada blokeeritud esinejaid.",
  label812: "Teil ei ole luba vaadata üleslaaditud reklaame.",
  label813: "Teil ei ole luba reklaame üles laadida ega kustutada.",
  label814: "Teil ei ole luba tugiteenustele ja allalaadimistele juurde pääseda.",
  label815: "Redigeeri valikut",
  label816: "Vali kõik",
  label817: "Järelevaatajad",
  label818: "Loo või muuda järelevaatajaid ja piira nende tegevusi.",
  label819: "Õigused salvestati edukalt.",
  label820: "Viga, õiguste salvestamisel läks midagi valesti.",
  label821: "Järelevaataja peatatud administraatori poolt.",
  label822: "Valitud lugude stiil on muudetud.",
  label823: "Valitud lood muudeti vaikimisi stiiliks.",
  label824: "Valitud lood lisati valitud esitusloenditesse.",
  label825: "Valitud lood märgiti edukalt selgesõnaliseks.",
  label826: "Valitud lood märgiti edukalt mitte-selgesõnaliseks.",
  label827: "Valitud lood märgiti edukalt kui 'meeldib'.",
  label828: "Valitud lood märgiti edukalt kui 'ei meeldi'.",
  label829: "Valitud lood märgiti edukalt kui 'mitte soovitud'.",
  label830: "Valitud lugude muudatused kustutati.",
  label831: "Viga, lugude stiili muutmisel läks midagi valesti.",
  label832: "Viga, lugude muutmisel vaikimisi stiiliks läks midagi valesti.",
  label833: "Viga, lugude lisamisel esitusloenditesse läks midagi valesti.",
  label834: "Viga, lugude märkimisel selgesõnaliseks läks midagi valesti.",
  label835: "Viga, lugude märkimisel mitte-selgesõnaliseks läks midagi valesti.",
  label836: "Viga, lugude märkimisel kui 'meeldib' läks midagi valesti.",
  label837: "Viga, lugude märkimisel kui 'ei meeldi' läks midagi valesti.",
  label838: "Viga, lugude märkimisel kui 'mitte soovitud' läks midagi valesti.",
  label839: "Viga, lugude muudatuste kustutamisel läks midagi valesti.",
  label840: "Valige tegevus...",
  label841: "Rakendamine...",
  label842: "Rakenda",
  label843: "Muuda kasutaja stiiliks",
  label844: "Muuda vaikimisi stiiliks",
  label845: "Lisa käsitsi esitusloendisse",
  label846: "Märgi selgesõnaliseks",
  label847: "Eemalda selgesõnalisena märkimine",
  label848: "Märgi kui meeldib",
  label849: "Eemalda meeldimise märkimine",
  label850: "Märgi kui mitte soovitud",
  label851: "Kustuta muudatused",
  label852: "Valitud laulud",
  label853: "Teostav tegevus",
  label854: "Õigused",
  label855: "Viga, järelevaataja muutmisel läks midagi valesti",
  label856: "Muuda järelevaatajat",
  label857: "Märkus",
  label858: "Viga, kasutajanime väli on kohustuslik",
  label859: "Viga, olete jõudnud lubatud järelevaatajate piirini.",
  label860: "Viga, järelevaataja loomisel läks midagi valesti.",
  label861: "Loo järelevaataja",
  label862: "Juhtpaneeli kasutamise õigused, muudatuste rakendamiseks vajutage salvestamise nuppu.",
  label863: "Järelevaatajate loomise piirang on 5.",
  label864: "Sisesta otsitava järelevaataja nimi...",
  label865: "Viga, järelevaataja kustutamisel läks midagi valesti",
  label866: "Kas soovite kustutada järelevaataja",
  label867: "Järelevaataja kustutatakse",
  label868: "Kas soovite selle järelevaataja ajutiselt peatada?",
  label869: "Kas soovite selle järelevaataja aktiveerida?",
  label870: `Kuulutuse ettevalmistamine...` 
};

export default et_EE;