import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import {
  Text,
  TextInput,
  Panel,
  Button,
  Loader,
  Paragraph,
} from "ks_storybook";
import {
  EmptyContent,
  DeleteConfirmationModal,
  WarningModal,
} from "../../../../Components/Common";
import {
  PPAdsRow,
  PPAdsUploadModal,
  PPAdsUploadEditModal,
  PPVersionsAdsModal,
} from "../../../../Components/Ads";
import { Select } from "../../../../Components/Select";
import { withToastManager } from "react-toast-notifications";

@observer
class PPMediaManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: this.getGroupsAll(),
      accounts: this.getAccounts(),
      ppAdvertisements: this.getPPAdsAll(),
      filter: this.getOptionsFilter(),
      indexFilter: 1,
      order: this.getOptionsOrder(),
      mediaType: this.getPPMediaTypeAll(),
      companies: this.getPPCompaniesAll(),
      trademarks: this.getPPTrademarksAll(),
      indexOrder: 1,
      descending: false,
      showModalLoadPPAd: false,
      search: "",
      showEditModal: false,
      showDeletePPAdModal: false,
      showWarningModal: false,
      currentPPAd: null,
      showVersionsModal: false,
    };
  }

  getPPMediaTypeAll = () => {
    return this.props.store.ppMediaType.fetchAll();
  };

  getPPCompaniesAll = () => {
    return this.props.store.ppCompanies.fetchAll();
  };

  getPPTrademarksAll = () => {
    return this.props.store.ppTrademarks.fetchAll();
  };

  getPPCompaniesAll = () => {
    return this.props.store.ppCompanies.fetchAll();
  };

  getPPTrademarksAll = () => {
    return this.props.store.ppTrademarks.fetchAll();
  };

  getAccounts = () => {
    return this.props.store.users.fetchAccounts();
  };

  getGroupsAll = () => {
    return this.props.store.groups.fetchAll();
  };

  getPPAdsAll = () => {
    return this.props.store.ppAds.fetchAll();
  };

  getOptionsFilter = () => {
    const options = [
      { value: 1, label: this.props.store.language.label53 },
      { value: 2, label: this.props.store.language.label186 },
      { value: 3, label: this.props.store.language.label187 },
      { value: 4, label: this.props.store.language.label188 },
      { value: 5, label: this.props.store.language.label189 },
    ];
    return options;
  };

  getOptionsOrder = () => {
    const options = [
      { value: 1, label: this.props.store.language.label53 },
      { value: 2, label: this.props.store.language.label186 },
      { value: 3, label: this.props.store.language.label187 },
      { value: 4, label: this.props.store.language.label190 },
      { value: 5, label: this.props.store.language.label191 },
    ];
    return options;
  };

  handleWarningModalOpen = () => {
    this.setState({ showWarningModal: true });
  };

  handleWarningModalClose = () => {
    this.setState({ showWarningModal: false });
  };

  handleReload = () => {
    this.setState({ ppAdvertisements: this.getPPAdsAll() });
  };

  handleMediaTypeReload = () => {
    this.setState({ mediaType: this.getPPMediaTypeAll() });
  };

  handleCompaniesReload = () => {
    this.setState({ companies: this.getPPCompaniesAll() });
  };

  handleTrademarksReload = () => {
    this.setState({ trademarks: this.getPPTrademarksAll() });
  };

  handleModalVersionsOpen = (ppAd) => {
    this.setState({ showVersionsModal: true, currentPPAd: ppAd });
  };

  handleModalVersionsClose = () => {
    this.setState({ showVersionsModal: false });
  };

  handleShowDeleteConfirmationModal = (ppAd) => {
    this.setState({ showDeletePPAdModal: true, currentPPAd: ppAd });
  };

  handleCloseDeletePPAdModal = () => {
    this.setState({ showDeletePPAdModal: false, currentPPAd: null });
  };

  handleAcceptDeletePPAdModal = () => {
    this.setState({
      showDeletePPAdModal: false,
      currentPPAd: null,
      ppAdvertisements: this.getPPAdsAll(),
    });
  };

  handleModalLoadAdOpen = () => {
    if (
      this.state.ppAdvertisements.length >=
      this.props.store.loggedInUser.ks_users_settings.maximum_tracks
    ) {
      this.handleWarningModalOpen();
    } else {
      this.setState({ showModalLoadPPAd: true });
    }
  };

  handleSaveLoadAd = () => {
    this.setState({
      ppAdvertisements: this.getPPAdsAll(),
      showEditModal: false,
    });
  };

  handleSaveLoadAdAndVersions = (ppAd) => {
    this.setState({
      ppAdvertisements: this.getPPAdsAll(),
      showModalLoadPPAd: false,
      showVersionsModal: true,
      currentPPAd: ppAd,
    });
  };

  handleModalLoadAdClose = () => {
    this.setState({ showModalLoadPPAd: false });
  };

  handleEditModalOpen = (ppAd) => {
    this.setState({ showEditModal: true, currentPPAd: ppAd });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
  };

  removeItemFromArr = (arr, item) => {
    var lists = arr.filter((x) => {
      return x != item;
    });
    return lists;
  };

  getLocationsUser = (locations, search) => {
    var arr = [];
    var reformattedArray = locations.map((o) => !o.group_id && o.info.name);
    reformattedArray = this.removeItemFromArr(reformattedArray, false);
    if (reformattedArray.length != 0) {
      arr = reformattedArray.filter(
        (e) => e.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  getLocationsGroup = (locations, search) => {
    var arr = [];
    var reformattedArray = locations.map((o) => !o.accounts_id && o.info.name);
    reformattedArray = this.removeItemFromArr(reformattedArray, false);
    if (reformattedArray.length != 0) {
      arr = reformattedArray.filter(
        (e) => e.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  filterItems = (array, search, indexFilter, indexOrder, descending) => {
    switch (indexFilter) {
      case 2: // por Anunciante
        //if (search != null) {
        array = array.filter(
          (el) =>
            el.companies.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        //}
        break;
      case 3: // por Marca
        //if (search != null) {
        array = array.filter(
          (el) =>
            el.trademarks.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        //}
        break;
      case 4: // por Usuario (Local)
        //if (search != null) {
        array = array.filter(
          (el) => this.getLocationsUser(el.locations, search).length > 0
        );
        //}
        break;
      case 5: // por Grupo de usuario
        //if (search != null) {
        array = array.filter(
          (el) => this.getLocationsGroup(el.locations, search).length > 0
        );
        //}
        break;
      default:
        // por Nombre
        //if (search != null){
        array = array.filter(
          (el) => el.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        //}
        break;
    }

    switch (indexOrder) {
      case 2: // por Anunciante
        array = array.sort((a, b) =>
          a.companies.name > b.companies.name
            ? 1
            : b.companies.name > a.companies.name
            ? -1
            : 0
        );
        break;
      case 3: // por Marca
        array = array.sort((a, b) =>
          a.trademarks.name > b.trademarks.name
            ? 1
            : b.trademarks.name > a.trademarks.name
            ? -1
            : 0
        );
        break;
      case 4: // por Prioridad
        array = array.sort((a, b) =>
          a.priority > b.priority ? 1 : b.prioritye > a.priority ? -1 : 0
        );
        break;
      case 5: // por Posts
        array = array.sort((a, b) =>
          a.posts > b.posts ? 1 : b.posts > a.posts ? -1 : 0
        );
        break;
      default:
        // por Nombre
        array = array.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        break;
    }
    if (descending) array.reverse();

    return array;
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  handleChangeSelect = (value, sender) => {
    const val = !value ? 1 : value.value;
    if (sender.name == "selectFilter") {
      this.setState({ indexFilter: val });
    } else {
      this.setState({ indexOrder: val });
    }
  };

  renderButtom = () => {
    return (
      <React.Fragment>
        <Button
          upper
          size="lg"
          // size={this.props.store.viewMobile ? "lg" : "md"}
          className="px-3"
          onClick={this.handleModalLoadAdOpen}
        >
          <strong>{this.props.store.language.label185}</strong>
        </Button>
      </React.Fragment>
    );
  };

  getPanel = () => {
    return (
      <Panel
        className="my-1"
        color="gray"
        padding={!this.props.store.viewMobile && "22px 19px"}
      >
        <Columns isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
            <TextInput
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="black"
              colorIcon="gray"
              onChange={this.handleChangeSearch}
              placeholder={this.props.store.language.label418}
              invert
              reversed
            />
          </Column>
        </Columns>
        <Columns isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 5 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="is-fullwidth">
                <Select
                  name="selectFilter"
                  onChange={this.handleChangeSelect}
                  required
                  autocomplete="nope"
                  options={this.state.filter}
                  placeholder={this.props.store.language.label51}
                  backgroundColor="#454545"
                  separatorColor="#454545"
                  iconColor="basic"
                  textColor="#ffffff"
                  isSearchable={false}
                  className="is-fullwidth"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </div>
            </div>
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="is-fullwidth">
                <Select
                  name="selectOrder"
                  onChange={this.handleChangeSelect}
                  required
                  autocomplete="nope"
                  options={this.state.order}
                  placeholder={this.props.store.language.label52}
                  backgroundColor="#454545"
                  separatorColor="#454545"
                  iconColor="basic"
                  textColor="#ffffff"
                  isSearchable={false}
                  className="is-fullwidth"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </div>
            </div>
          </Column>
          <Column
            isSize={{ mobile: 12, tablet: 12, desktop: 3 }}
            style={{ paddingTop: !this.props.store.viewMobile && "0px" }}
          >
            {this.renderButtom()}
          </Column>
        </Columns>
      </Panel>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderPPAdsRow = () => {
    const {
      ppAdvertisements,
      search,
      indexFilter,
      indexOrder,
      descending,
      showDeletePPAdModal,
    } = this.state;
    const arrayPPAds = this.filterItems(
      ppAdvertisements.toArray(),
      search,
      indexFilter,
      indexOrder,
      descending
    );
    return (
      <React.Fragment>
        {arrayPPAds.length !== 0
          ? arrayPPAds.map((ppAd) => (
              <PPAdsRow
                ppAd={ppAd}
                onEditClick={this.handleEditModalOpen}
                onDeleteClick={this.handleShowDeleteConfirmationModal}
                onVersionsClick={this.handleModalVersionsOpen}
              />
            ))
          : this.renderEmptyContent()}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { ppAdvertisements } = this.state;
    if (!ppAdvertisements) return this.renderEmptyContent();
    return !ppAdvertisements.isOk()
      ? this.renderLoader()
      : this.renderPPAdsRow();
  };

  render() {
    const {
      accounts,
      groups,
      showWarningModal,
      showModalLoadPPAd,
      showDeletePPAdModal,
      currentPPAd,
      showVersionsModal,
      showEditModal,
      mediaType,
      companies,
      trademarks,
      ppAdvertisements,
    } = this.state;

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Text
              upper
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
              lineBreak
            >
              {this.props.store.language.label30}
            </Text>
            <div
              style={{ maxWidth: "650px" }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label184}
              </Paragraph>
            </div>
            {this.getPanel()}
            <br />
            {this.renderHeader()}
          </Column>
        </Columns>
        {showModalLoadPPAd && (
          <PPAdsUploadModal
            ppAds={ppAdvertisements}
            mediaType={mediaType}
            companies={companies}
            trademarks={trademarks}
            accounts={accounts.toArray()}
            groups={groups.toArray()}
            onClose={this.handleModalLoadAdClose}
            onSave={this.handleSaveLoadAdAndVersions}
            onMediaTypeReload={this.handleMediaTypeReload}
            onCompaniesReload={this.handleCompaniesReload}
            onTrademarksReload={this.handleTrademarksReload}
          />
        )}
        {showEditModal && (
          <PPAdsUploadEditModal
            ppAds={ppAdvertisements}
            mediaType={mediaType}
            companies={companies}
            trademarks={trademarks}
            currentPPAd={currentPPAd}
            accounts={accounts.toArray()}
            groups={groups.toArray()}
            onClose={this.handleEditModalClose}
            onSave={this.handleSaveLoadAd}
            onMediaTypeReload={this.handleMediaTypeReload}
            onCompaniesReload={this.handleCompaniesReload}
            onTrademarksReload={this.handleTrademarksReload}
          />
        )}
        {showDeletePPAdModal && (
          <DeleteConfirmationModal
            currentObj={currentPPAd}
            onClose={this.handleCloseDeletePPAdModal}
            onAccept={this.handleAcceptDeletePPAdModal}
            messageSuccess={
              this.props.store.language.label376 +
              " " +
              currentPPAd.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label377 + " " + currentPPAd.name + "."
            }
            title={
              this.props.store.language.label378 + " " + currentPPAd.name + "?"
            }
            subtitle={
              this.props.store.language.label379 + " " + currentPPAd.name + "."
            }
          />
        )}
        {showVersionsModal && (
          <PPVersionsAdsModal
            currentPPAd={currentPPAd}
            onClose={this.handleModalVersionsClose}
            onReload={this.handleReload}
          />
        )}
        {showWarningModal && (
          <WarningModal
            title={this.props.store.language.label494}
            subtitle={
              this.props.store.language.label498 +
              "\n\n" +
              this.props.store.language.label503 +
              this.props.store.loggedInUser.ks_users_settings.maximum_tracks
            }
            onClose={this.handleWarningModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withToastManager(withStore(PPMediaManager));
