import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Checkbox,
  Columns,
  Column,
  Panel,
  Button,
} from "ks_storybook";
import { EmptyContent } from "../../Components/Common";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../Components/Screen";

@observer
class CardPermission extends Component {
  handleClick(e) {
    if (this.props.disabled) return;
    this.props.onClick &&
      this.props.onClick(this, this.props.value, this.props.name, e);
  }

  render() {
    const {
      name,
      description,
      children,
      pdEvent,
      upper,
      isMobile,
      style,
      padding,
      renderBottom,
    } = this.props;
    return (
      <Panel
        color="gray"
        padding={padding || (isMobile ? "16px" : "20px 32px")}
        style={{
          flexDirection: "column",
          justifyContent: "center",
          ...style,
        }}
      >
        <div className="flex" style={{ width: "100%", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <Text
              size={isMobile ? "lg" : "xl"}
              upper={upper}
              weight="medium"
              lineBreak
            >
              {name}
            </Text>
            <Text size={isMobile ? "md" : "xl"} color="special" lineBreak>
              <p>{description}</p>
            </Text>
          </div>
          <div style={{ display: "flex", padding: `0px ${pdEvent || "0px"}` }}>
            {children}
          </div>
        </div>
      </Panel>
    );
  }
}

@observer
class SupervisorPermissionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isChanges: false,
      arrayPermissions: [],
    };
  }

  componentDidMount() {
    let permissions = this.getKSPermissions(this.props.supervisor.id);
    permissions.andThen((res) => {
      let array = this.getPermissions(res);
      this.setState({ arrayPermissions: array });
    });
  }

  getKSPermissions = (child_user_id) => {
    return this.props.store.ksPermissions.fetchAllParams(
      this.props.store.lang,
      child_user_id,
      1,
      true
    );
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getPermissions = (ksPermissions) => {
    return ksPermissions.toArray().map((permission) => ({
      id: permission.id,
      name: permission.translation.name,
      description: permission.translation.description,
      apply_in: permission.apply_in,
      permission_activated: permission.user_permission.permission_activated,
      requires_pin: permission.user_permission.requires_pin,
    }));
  };

  renderHeaderOk = () => {
    const { arrayPermissions } = this.state;
    return this.renderPermissions(arrayPermissions);
  };

  updateIsPermissionActivated = (array, id, newValue) => {
    const item = array.find((obj) => obj.id === id);
    if (item) item.permission_activated = newValue;
    this.setState({ arrayPermissions: array, isChanges: true });
  };

  updateIsRequiresPin = (array, id, newValue) => {
    const item = array.find((obj) => obj.id === id);
    if (item) item.requires_pin = newValue;
    this.setState({ arrayPermissions: array, isChanges: true });
  };

  handleCheckboxChange = (sender, value, id) => {
    const { arrayPermissions } = this.state;
    this.updateIsPermissionActivated(arrayPermissions, id, value);
  };

  handleChangeToggle = (sender, id, value) => {
    const { arrayPermissions } = this.state;
    this.updateIsRequiresPin(arrayPermissions, id, value);
  };

  handleSave = () => {
    const { arrayPermissions } = this.state;
    const { toastManager } = this.props;

    const params = {
      user_supervisor_id: this.props.supervisor.id,
      permissions: arrayPermissions,
    };
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .putParams(
          "/users_supervisors_ks_permissions/change_permissions",
          params
        )
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label819, {
              appearance: "success",
              autoDismiss: true,
            });
            this.setState({ isSaving: false, isChanges: false });
          },
          (error) => {
            toastManager.add(this.props.store.language.label820, {
              appearance: "error",
              autoDismiss: true,
            });
            this.setState({ isSaving: false });
          }
        );
    });
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "550px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  getContainerStyleMobile = () => ({
    width: "100%",
    height: "auto",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderPermissions = (arrayPermissions) => {
    return (
      <>
        <Text
          size={this.props.store.viewMobile ? "lg" : "md"}
          multiline
          lineBreak
          color="special"
        >
          {this.props.store.language.label862}
        </Text>
        <br></br>
        <div
          style={
            this.props.store.viewMobile
              ? this.getContainerStyle()
              : this.getContainerStyleMobile()
          }
        >
          <div className="flex flex-col my-1">
            <Columns
              style={{ gap: this.props.store.viewMobile ? "10px" : "3px" }}
            >
              {arrayPermissions.map((permssion) => (
                <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                  <CardPermission
                    name={permssion.name}
                    description={permssion.description}
                    isMobile={!this.props.store.viewMobile}
                    padding={"18px 22px"}
                    style={{ height: "100%" }}
                  >
                    <Checkbox
                      value={permssion.id}
                      className="pl-1"
                      size="xxl"
                      isChecked={permssion.permission_activated}
                      onChange={this.handleCheckboxChange}
                      invert
                    />
                  </CardPermission>
                </Column>
              ))}
            </Columns>
          </div>
        </div>
        <div className="is-pulled-right">{this.renderButtons()}</div>
      </>
    );
  };

  renderHeader = () => {
    const { arrayPermissions } = this.state;
    return arrayPermissions == [] ? this.renderLoader() : this.renderHeaderOk();
  };

  renderScreen = () => {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {"Permisos"}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  };

  renderButtons() {
    return (
      <>
        {this.state.isSaving ? (
          <Button
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "mt-2" : "is-fullwidth my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            {this.props.store.language.label128}
          </Button>
        ) : (
          <Button
            disabled={!this.state.isChanges}
            className={`is-pulled-right px-3 ${
              this.props.store.viewMobile ? "mt-2" : "is-fullwidth my-1"
            }`}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onClick={this.handleSave}
          >
            <strong>{this.props.store.language.label127}</strong>
          </Button>
        )}
      </>
    );
  }

  render() {
    const { supervisor } = this.props;

    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "740px" : "90%"}
            height={this.props.store.viewMobile ? "740px" : "auto"}
          >
            <ModalHeader>
              <div className="flex items-center" style={{ flexWrap: "wrap" }}>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  lead
                  weight="strong"
                  className="mr-2"
                >
                  {this.props.store.language.label854}
                </Text>

                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="regular"
                  className="mr-2"
                >
                  {supervisor.username}
                </Text>
              </div>
            </ModalHeader>
            <ModalContent>{this.renderHeader()}</ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
      </React.Fragment>
    );
  }
}

SupervisorPermissionsModal.propTypes = {
  supervisor: PropTypes.object,
  onClose: PropTypes.func,
};

SupervisorPermissionsModal.defaultProps = {
  supervisor: null,
  onClose: null,
};

export default withStore(withToastManager(SupervisorPermissionsModal));
