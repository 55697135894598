import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Text,
  Paragraph,
} from "ks_storybook";
import { EmptyContent, DeleteConfirmationModal, WarningModal } from "../Common";
import { PPGenericRow, PPGenericEditModal } from ".";
import { withToastManager } from "react-toast-notifications";

@observer
class PPGenericManagerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: null,
      currentObj: null,
      showDeletedModal: false,
      showEditModal: false,
      showWarningModal: false,
    };
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleModalEditOpen = (obj) => {
    this.setState({ showEditModal: true, currentObj: obj });
  };

  handleModalEditClose = () => {
    this.setState({ showEditModal: false, currentObj: null });
  };

  handleModalEditSave = () => {
    this.setState({ showEditModal: false, currentObj: null });
    this.props.onReload && this.props.onReload();
  };

  handleShowDeleteConfirmationModal = (obj) => {
    if (
      this.validateExistisMediaType(obj.id) &&
      this.props.currentGenType == 1
    ) {
      this.handleWarningModalOpen();
    } else {
      if (
        this.validateExistisCompanies(obj.id) &&
        this.props.currentGenType == 2
      ) {
        this.handleWarningModalOpen();
      } else {
        if (
          this.validateExistisTrademarks(obj.id) &&
          this.props.currentGenType == 3
        ) {
          this.handleWarningModalOpen();
        } else {
          this.setState({ showDeletedModal: true, currentObj: obj });
        }
      }
    }
  };

  handleCloseDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentObj: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentObj: null });
    this.props.onReload && this.props.onReload();
  };

  handleWarningModalOpen = () => {
    this.setState({ showWarningModal: true });
  };

  handleWarningModalClose = () => {
    this.setState({ showWarningModal: false });
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  validateExistisMediaType = (id) => {
    return this.props.ppAds.toArray().filter((e) => e.media_type.id == id)[0]
      ? true
      : false;
  };

  validateExistisCompanies = (id) => {
    return this.props.ppAds.toArray().filter((e) => e.companies.id == id)[0]
      ? true
      : false;
  };

  validateExistisTrademarks = (id) => {
    return this.props.ppAds.toArray().filter((e) => e.trademarks.id == id)[0]
      ? true
      : false;
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  getOptionsMediaType = (obj) => {
    return obj.toArray().filter((f) => f.mt_type != 1);
  };

  renderRow = () => {
    let array = [];
    const { currentGenObj, currentGenType } = this.props;
    if (currentGenType == 1) {
      array = this.getOptionsMediaType(currentGenObj);
    } else {
      array = currentGenObj.toArray();
    }
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {array.length !== 0
            ? array.map((obj) => (
                <PPGenericRow
                  currentGenObj={obj}
                  onDeleteClick={this.handleShowDeleteConfirmationModal}
                  onEditClick={this.handleModalEditOpen}
                />
              ))
            : this.renderEmptyContent()}
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { currentGenObj } = this.props;
    if (!currentGenObj) return this.renderEmptyContent();
    return !currentGenObj.isOk() ? this.renderLoader() : this.renderRow();
  };

  getTitle = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label475;
      case 2:
        return this.props.store.language.label476;
      case 3:
        return this.props.store.language.label477;
    }
  };

  getSubTitle = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label478;
      case 2:
        return this.props.store.language.label479;
      case 3:
        return this.props.store.language.label480;
    }
  };

  getLabel1 = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label481;
      case 2:
        return this.props.store.language.label482;
      case 3:
        return this.props.store.language.label483;
    }
  };

  getLabel2 = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label484;
      case 2:
        return this.props.store.language.label485;
      case 3:
        return this.props.store.language.label486;
    }
  };

  getLabel3 = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label487;
      case 2:
        return this.props.store.language.label488;
      case 3:
        return this.props.store.language.label489;
    }
  };

  getLabel4 = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label490;
      case 2:
        return this.props.store.language.label491;
      case 3:
        return this.props.store.language.label492;
    }
  };

  getLabel5 = (value) => {
    switch (value) {
      case 1:
        return this.props.store.language.label495;
      case 2:
        return this.props.store.language.label496;
      case 3:
        return this.props.store.language.label497;
    }
  };

  render() {
    const { currentGenType, ppAds } = this.props;
    const { currentObj, showDeletedModal, showEditModal, showWarningModal } =
      this.state;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "50%" : "auto"}
          invert
        >
          <ModalHeader>
            <Text
              upper
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {this.getTitle(currentGenType)}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="my-1"
              lineBreak
            >
              {this.getSubTitle(currentGenType)}
            </Text>
            {this.renderHeader()}
          </ModalContent>
          <ModalFooter />
        </Modal>
        {showDeletedModal && (
          <DeleteConfirmationModal
            currentObj={currentObj}
            onClose={this.handleCloseDeleteConfirmationModal}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={
              this.getLabel1(currentGenType) +
              " " +
              currentObj.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.getLabel2(currentGenType) + " " + currentObj.name + "."
            }
            title={this.getLabel3(currentGenType) + " " + currentObj.name + "?"}
            subtitle={
              this.getLabel4(currentGenType) + " " + currentObj.name + "."
            }
          />
        )}
        {showEditModal && (
          <PPGenericEditModal
            currentGenObj={currentObj}
            currentGenType={currentGenType}
            onSave={this.handleModalEditSave}
            onClose={this.handleModalEditClose}
          />
        )}
        {showWarningModal && (
          <WarningModal
            title={this.props.store.language.label494}
            subtitle={this.getLabel5(currentGenType)}
            onClose={this.handleWarningModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}
PPGenericManagerModal.propTypes = {
  ppAds: PropTypes.object,
  currentGenObj: PropTypes.object,
  currentGenType: PropTypes.number,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
};

PPGenericManagerModal.defaultProps = {
  ppAds: null,
  currentGenObj: null,
  currentGenType: 0,
  onClose: null,
  onReload: null,
};

export default withToastManager(withStore(PPGenericManagerModal));
