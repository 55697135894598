import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import {
  Field,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  TextInput,
  Text,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class PinModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      newPin: null,
      confiPin: null,
      messageError: null,
    };
    this.user_id = this.props.userId;
    this.Preference = this.props.preferences.toArray();
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validarSiNumero = (value) => {
    if (value === "0000") return true;
    if (!/^([0-9])*$/.test(value)) return true;
    else return false;
  };

  handleChange = (sender, value, name, validation) => {
    if (name === "confipin") {
      if (this.validarSiNumero(value)) {
        this.setState({ messageError: this.props.store.language.label295 });
      } else {
        this.setState({ messageError: null });
        this.setState({ confiPin: value });
      }
    }
    if (name === "newpin") {
      if (this.validarSiNumero(value)) {
        this.setState({ messageError: this.props.store.language.label295 });
      } else {
        this.setState({ messageError: null });
        this.setState({ newPin: value });
      }
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { newPin, confiPin } = this.state;
    if (
      newPin.length < 4 ||
      confiPin.length < 4 ||
      newPin.length > 4 ||
      confiPin.length > 4
    ) {
      this.setState({ messageError: this.props.store.language.label296 });
    } else {
      this.setState({ messageError: null });
      if (newPin != confiPin) {
        this.setState({ messageError: this.props.store.language.label297 });
      } else {
        this.setState({ messageError: null });
        if (this.Preference[0].pin != 0 && this.Preference[0].pin == newPin) {
          this.setState({ messageError: this.props.store.language.label298 });
        } else {
          this.setState({ messageError: null });
          const meSuccess =
            this.Preference[0].pin == 0
              ? this.props.store.language.label299
              : this.props.store.language.label300;
          const meError =
            this.Preference[0].pin == 0
              ? this.props.store.language.label301
              : this.props.store.language.label302;
          this.setState({ isSaving: true }, () => {
            this.props.store.kasimuAPIClient
              .putOne(
                "/users_preferences/put_pin_user",
                "?user_id=" + this.user_id + "&pin=" + newPin
              )
              .then(
                (res) => {
                  toastManager.add(meSuccess, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  this.props.onSave && this.props.onSave();
                },
                (error) => {
                  toastManager.add(meError, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                  this.handleClose();
                }
              )
              .catch((error) => {
                toastManager.add(meError, {
                  appearance: "error",
                  autoDismiss: true,
                });
                this.handleClose();
              });
          });
        }
      }
    }
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mb-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>
                {this.Preference[0].pin == 0
                  ? this.props.store.language.label128
                  : this.props.store.language.label81}
              </strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3 mb-2"
              onClick={this.handleSave}
            >
              <strong>
                {this.Preference[0].pin == 0
                  ? this.props.store.language.label127
                  : this.props.store.language.label80}
              </strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  @computed
  get canSave() {
    if (this.state.messageError || !(this.state.newPin && this.state.confiPin))
      return false;
    return true;
  }

  renderNew = () => {
    return (
      <>
        <Field
          label={this.props.store.language.label292}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          marginH={this.props.store.viewMobile && "4px"}
          NoteSize="xs"
          labelNote={this.props.store.language.label293}
          weight="medium"
        >
          <TextInput
            backgroundColor="blackDark"
            borderColor="black"
            paddingInput="20px"
            className="is-fullwidth my-1"
            autocomplete="nope"
            name="newpin"
            onChange={this.handleChange}
            type="text"
            size={this.props.store.viewMobile ? "xl" : "lg"}
          />
        </Field>
        <Field
          label={this.props.store.language.label294}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          marginH={this.props.store.viewMobile && "4px"}
          NoteSize="xs"
          labelNote={this.props.store.language.label293}
          weight="medium"
        >
          <TextInput
            backgroundColor="blackDark"
            borderColor="black"
            paddingInput="20px"
            className="is-fullwidth my-1"
            autocomplete="nope"
            name="confipin"
            onChange={this.handleChange}
            type="text"
            size={this.props.store.viewMobile && "xl"}
          />
        </Field>
      </>
    );
  };

  renderEdit = (pin) => {
    return (
      <>
        <Field
          label={this.props.store.language.label291}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          marginH={this.props.store.viewMobile && "4px"}
          weight="medium"
        >
          <TextInput
            backgroundColor="blackDark"
            borderColor="black"
            paddingInput="20px"
            className="is-fullwidth my-1"
            autocomplete="nope"
            name="pin"
            value={pin}
            type="text"
            disabled
            size={this.props.store.viewMobile ? "xl" : "lg"}
          />
        </Field>
        <Field
          label={this.props.store.language.label292}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          marginH={this.props.store.viewMobile && "4px"}
          NoteSize="xs"
          labelNote={this.props.store.language.label293}
          weight="medium"
        >
          <TextInput
            backgroundColor="blackDark"
            borderColor="black"
            paddingInput="20px"
            className="is-fullwidth my-1"
            autocomplete="nope"
            name="newpin"
            onChange={this.handleChange}
            type="text"
            size={this.props.store.viewMobile ? "xl" : "lg"}
          />
        </Field>
        <Field
          label={this.props.store.language.label294}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          marginH={this.props.store.viewMobile && "4px"}
          NoteSize="xs"
          labelNote={this.props.store.language.label293}
          weight="medium"
        >
          <TextInput
            backgroundColor="blackDark"
            borderColor="black"
            paddingInput="20px"
            className="is-fullwidth my-1"
            autocomplete="nope"
            name="confipin"
            onChange={this.handleChange}
            type="text"
            size={this.props.store.viewMobile ? "xl" : "lg"}
          />
        </Field>
      </>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "340px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            className="mb-1"
          >
            {this.Preference[0].pin == 0
              ? this.props.store.language.label289
              : this.props.store.language.label290}
          </Text>
        </ModalHeader>
        <ModalContent>
          {this.Preference[0].pin == 0
            ? this.renderNew()
            : this.renderEdit(this.Preference[0].pin)}

          {this.state.messageError && (
            <Panel color="error" className="mt-1" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

PinModal.propTypes = {
  preferences: PropTypes.object,
  userId: PropTypes.number,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

PinModal.defaultProps = {
  preferences: null,
  userId: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(PinModal));
