import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import { Select } from "../Select";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../Screen";

@observer
class SupervisorEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      password: "",
      password_confirm: "",
      messageError: null,
      eye: false,
      confirmEye: false,
    };

    this.supervisor = props.supervisor.clone();
  };

  componentDidMount() {
    this.setState({password_confirm: this.supervisor.password});
  };

  validLengthPwsOusername = (value) => {
    if (value.length >= 6 && value.length <= 40) {
      return true;
    } else {
      return false;
    }
  };

  validNoEquals = (pws, pwsConfi) => {
    if (pws === pwsConfi) {
      return true;
    } else {
      return false;
    }
  };

  validChar = (username, char) => {
    if (username)
      if (username.indexOf(char) === -1) return true;
    return false;
  };

  handleChange = (sender, value, name, validation) => {
    switch (name) {
      case "username":
        this.setState({ messageError: null });
          if (this.validChar(value, " ")) {
            if (this.validChar(value, "#")) {
              this.setState({ messageError: null });
            } else {
              this.setState({
                messageError: this.props.store.language.label218,
              });
            }
          } else {
            this.setState({ messageError: this.props.store.language.label210 });
          }
          this.supervisor[name] = value;
        break;
      case "password":
        this.setState({ messageError: null });
        if (this.validChar(value, " ")) {
          this.setState({ messageError: null });
        } else {
          this.setState({ messageError: this.props.store.language.label766 });
        }
        this.supervisor[name] = value;
        break;
      case "password_confirm":
        if (this.validChar(value, " ")) {
          this.setState({ messageError: null });
        } else {
          this.setState({ messageError: this.props.store.language.label766 });
        }
        this.setState({ password_confirm: value });
        break;
      case "groups":
        this.supervisor[name] = value;
        break;  
      case "observations":
        this.supervisor[name] = value;
        break;
      default:
        break;
    }
  }

  handleChangeGroups = (value) => {
    this.supervisor.group_id = value ? `[${value.value}]` : "[]";
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  }

  validUsername = (username) => {
    var pos = username.indexOf("@");
    if (pos === -1) {
      username = `@${username}`;
    }
    return username;
  };

  getErrorMessager(answer_code) {
    const messager = {
      1: this.props.store.language.label855,
      2: this.props.store.language.label548,
      3: this.props.store.language.label578,
    };
    return messager[answer_code];
  }

  handleSave = () => {
    const { toastManager } = this.props;
    const { password_confirm } = this.state;
    this.supervisor.username = this.validUsername(this.supervisor.username)
    const params = {
      id:  this.supervisor.id,
      user_id: this.supervisor.user_id,
      username: this.supervisor.username,
      password: this.supervisor.password,
      group_id: this.supervisor.group_id,
      observations: this.supervisor.observations,
      
    };
    if (this.supervisor.username != "") {
      if (this.validChar(this.supervisor.username, " ")) {
        if (this.validChar(this.supervisor.username, "#")) {
          if (params.password != "") {
            if (password_confirm != "") {
              if (this.validLengthPwsOusername(this.supervisor.username)) {
                if (this.validLengthPwsOusername(params.password)) {
                  if (this.validNoEquals(params.password, password_confirm)) {
                    this.setState({ messageError: null });
                    this.setState({ isSaving: true }, () => {
                      this.props.store.kasimuAPIClient
                        .putParams("/users_supervisors/put_supervisor", params)
                        .then(
                          (res) => {
                            toastManager.add(this.props.store.language.label219, {
                              appearance: "success",
                              autoDismiss: true,
                            });
                            this.props.onSave && this.props.onSave();
                          },
                          (error) => {
                            let str = error.toString();
                            str = str.replace("Error: ", "");
                            let mydatas = JSON.parse(str);
                            toastManager.add(this.getErrorMessager(mydatas['answer_code']), {
                              appearance: "error",
                              autoDismiss: true,
                            });
                            this.setState({ isSaving: false })
                          }
                        )
                        .catch((error) => {
                          toastManager.add(this.props.store.language.label220, {
                            appearance: "error",
                            autoDismiss: true,
                          });
                        });
                    });
                  } else {
                    this.setState({ messageError: this.props.store.language.label214 });
                  }
                } else {
                  this.setState({ messageError: this.props.store.language.label212 });
                }
              } else {
                this.setState({ messageError: this.props.store.language.label211 });
              }
            } else {
              this.setState({ messageError: this.props.store.language.label767 });
            }
          } else {
            this.setState({ messageError: this.props.store.language.label215 });
          }
        } else {
          this.setState({ messageError: this.props.store.language.label218 });
        }
      } else {
        this.setState({ messageError: this.props.store.language.label210 });
      }
    } else {
      this.setState({ messageError: this.props.store.language.label216 });
    }
  }

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile={this.props.store.viewMobile}>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className={`is-pulled-right px-3 ${
                this.props.store.viewMobile ? "mb-2" : "is-fullwidth my-1"
              }`}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label81}
            </Button>
          ) : (
            <Button
              disabled={!this.canSave}
              className={`is-pulled-right px-3 ${
                this.props.store.viewMobile ? "mb-2" : "is-fullwidth my-1"
              }`}
              size={this.props.store.viewMobile ? "lg" : "md"}
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderScreen = () => {
    const { groups } = this.props;
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label140}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        <Panel color="gray" className="mb-1" padding="22px 16px">
          <div>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              color="special"
              multiline
              lineBreak
            >
              {this.props.store.language.label84}
            </Text>
          </div>
        </Panel>
        <br />
        <Field
          marginH={this.props.store.viewMobile && "4px"}
          label={this.props.store.language.label53}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          noteSize={this.props.store.viewMobile ? "sm" : "xs"}
          weight="medium"
          labelNote={this.props.store.language.label76}
        >
          <TextInput
            backgroundColor={
              this.props.store.viewMobile ? "blackDark" : "black"
            }
            borderColor="black"
            className="is-fullwidth my-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            name="username"
            paddingInput={"20px"}
            onChange={this.handleChange}
            value={this.supervisor.username}
            autocomplete="nope"
          />
        </Field>
        <Field
          marginH={this.props.store.viewMobile && "4px"}
          label={this.props.store.language.label8}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          noteSize={this.props.store.viewMobile ? "sm" : "xs"}
          weight="medium"
          labelNote={this.props.store.language.label76}
        >
          <TextInput
            backgroundColor={
              this.props.store.viewMobile ? "blackDark" : "black"
            }
            borderColor="black"
            className="is-fullwidth my-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            type={this.state.eye ? "text" : "password"}
            name="password"
            value={this.supervisor.password}
            autocomplete="nope"
            onChange={this.handleChange}
            paddingInput={"20px 50px 20px 20px !important"}
            iconDouble={this.state.eye ? "eye-slash" : "eye"}
            onClickIcon={() => this.setState({ eye: !this.state.eye })}
            double
          />
        </Field>
        <Field
          marginH={this.props.store.viewMobile && "4px"}
          label={this.props.store.language.label77}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          noteSize={this.props.store.viewMobile ? "sm" : "xs"}
          weight="medium"
          labelNote={this.props.store.language.label76}
        >
          <TextInput
            backgroundColor={
              this.props.store.viewMobile ? "blackDark" : "black"
            }
            borderColor="black"
            className="is-fullwidth my-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            type={this.state.confirmEye ? "text" : "password"}
            name="password_confirm"
            value={this.state.password_confirm}
            autocomplete="nope"
            onChange={this.handleChange}
            paddingInput={"20px 50px 20px 20px !important"}
            iconDouble={this.state.confirmEye ? "eye-slash" : "eye"}
            onClickIcon={() =>
              this.setState({ confirmEye: !this.state.confirmEye })
            }
            double
          />
        </Field>
        <Field
          marginH={this.props.store.viewMobile && "4px"}
          label={this.props.store.language.label55}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          noteSize={this.props.store.viewMobile ? "sm" : "xs"}
          weight="medium"
          labelNoteOption={this.props.store.language.label78}
        >
          <Select
            key={this.supervisor.id}
            isClearable
            onChange={this.handleChangeGroups}
            name="groups"
            autocomplete="nope"
            defaultValue={
              this.supervisor.group_id &&
              groups.find((e) => e.value == JSON.parse(this.supervisor.group_id)[0])
            }
            options={groups}
            backgroundColor={this.props.store.viewMobile ? "black" : "#141414"}
            separatorColor="black"
            placeholder
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
            className="my-1"
          />
        </Field>
        <Field
          marginH={this.props.store.viewMobile && "4px"}
          label={this.props.store.language.label857}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          noteSize={this.props.store.viewMobile ? "sm" : "xs"}
          weight="medium"
          labelNoteOption={this.props.store.language.label78}
        >
          <TextInput
            backgroundColor={
              this.props.store.viewMobile ? "blackDark" : "black"
            }
            borderColor="black"
            paddingInput="20px"
            className="is-fullwidth my-1"
            name="observations"
            value={this.supervisor.observations}
            onChange={this.handleChange}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            autocomplete="nope"
            type="text"
          />
        </Field>
        {this.state.messageError && (
          <Panel color="error" className="mt-2" invert>
            <Text multiline>{this.state.messageError}</Text>
          </Panel>
        )}
        {this.renderButtons()}
      </Screen>
    );
  };

  render() {
    const { groups } = this.props;
    return this.props.store.viewMobile ? (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "540px" : "auto"}
      >
        <ModalHeader>
          <div className="flex items-center" style={{ flexWrap: "wrap" }}>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="bold"
              className="mr-2"
            >
              {this.props.store.language.label856}
            </Text>

            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="regular"
              className="mr-2"
            >
              {this.supervisor.username}
            </Text>
          </div>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            color="special"
            multiline
            lineBreak
          >
            {this.props.store.language.label84}
          </Text>
          <br />
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label53}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              name="username"
              paddingInput={"20px"}
              onChange={this.handleChange}
              value={this.supervisor.username}
              autocomplete="nope"
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label8}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type={this.state.eye ? "text" : "password"}
              name="password"
              value={this.supervisor.password}
              autocomplete="nope"
              onChange={this.handleChange}
              paddingInput={"20px 50px 20px 20px !important"}
              iconDouble={this.state.eye ? "eye-slash" : "eye"}
              onClickIcon={() => this.setState({ eye: !this.state.eye })}
              double
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label77}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type={this.state.confirmEye ? "text" : "password"}
              name="password_confirm"
              value={this.state.password_confirm}
              autocomplete="nope"
              onChange={this.handleChange}
              paddingInput={"20px 50px 20px 20px !important"}
              iconDouble={this.state.confirmEye ? "eye-slash" : "eye"}
              onClickIcon={() =>
                this.setState({ confirmEye: !this.state.confirmEye })
              }
              double
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label55}
            size="xl"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNoteOption={this.props.store.language.label78}
          >
            <Select
              key={this.supervisor.id}
              isClearable
              onChange={this.handleChangeGroups}
              name="groups"
              autocomplete="nope"
              defaultValue={
                this.supervisor.group_id &&
                groups.find((e) => e.value == JSON.parse(this.supervisor.group_id)[0])
              }
              options={groups}
              backgroundColor="black"
              separatorColor="black"
              placeholder
              size={this.props.store.viewMobile ? "lg" : "md"}
              isMobile={!this.props.store.viewMobile}
              className="my-1"
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label857}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNoteOption={this.props.store.language.label78}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput="20px"
              className="is-fullwidth my-1"
              name="observations"
              value={this.supervisor.observations}
              onChange={this.handleChange}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              autocomplete="nope"
              type="text"
            />
          </Field>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text multiline>{this.state.messageError}</Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    ) : (
      this.renderScreen()
    );
  }
}

SupervisorEditModal.propTypes = {
  supervisor: PropTypes.object,
  groups: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

SupervisorEditModal.defaultProps = {
  supervisor: null,
  groups: [],
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(SupervisorEditModal));
