import { Store } from "ks_storybook";
import { UserFavoritePlaylists } from "../models";

class UserFavoritePlaylistsStore extends Store {
  model = UserFavoritePlaylists;

  fetchAllParams(lang, user_id, single_user) {
    return this.search(
      { per_page: 1000, lang: lang, user_id: user_id, single_user: single_user },
      "get_all_params",
      true,
      "/users_favorite_playlists/get_all_params"
    );
  };
};

export default UserFavoritePlaylistsStore;