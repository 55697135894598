import React, { Component, useRef, useLayoutEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Paragraph,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Column,
  Columns,
} from "ks_storybook";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withToastManager } from "react-toast-notifications";
import { FixedSizeList } from "react-window";

function getStyle({ provided, style, isDragging, columnId }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const columnOne = columnId == "column-1";

  const grid = 8;
  const marginBottom = 5;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    background: isDragging ? "#12C889" : columnOne ? "#2E2E2E" : "#1F1F1F",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${grid * 3}px`,
    borderRadius: grid,
    marginBottom,
  };
  return withSpacing;
}

const Item = ({
  provided,
  item,
  style,
  isDragging,
  index,
  columnId,
  func,
  currentItems,
  isPlaying,
  showPlayer,
  handleTogglePlay,
  handleStartPlayingClick,
}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging, columnId })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      <div
        className="is-row is-fullwidth"
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="is-row ml-1" style={{ alignItems: "center" }}>
          <Button
            className="mr-3"
            icon={
              currentItems && item.id == currentItems.id && isPlaying
                ? "pause"
                : "play"
            }
            kind="link"
            color={
              currentItems && item.id == currentItems.id ? "primary" : "basic"
            }
            size="xl"
            id="play-button"
            onClick={
              currentItems && item.id == currentItems.id && showPlayer
                ? () => {
                    handleTogglePlay(item);
                  }
                : () => {
                    handleStartPlayingClick(item);
                  }
            }
            lead
            space
          />
          <Text size="xl" multiline>
            {currentItems && item.id == currentItems.id ? (
              <span style={{ fontWeight: "bold", color: "#FF3F1F" }}>
                {item.name}
              </span>
            ) : (
              item.name
            )}
          </Text>
        </div>
        {columnId == "column-1" ? (
          <div className="is-row ml-1" style={{ alignItems: "center" }}>
            <Button
              kind="link"
              color="basic"
              size="xl"
              iconKasimu="trash"
              onClick={() => {
                func(item.key);
              }}
              space
              className="ml-1"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Row = (props) => {
  const { data, index, style } = props;
  const item = data.items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.key}`} index={index} key={item.key}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          columnId={data.columnId}
          func={data.func}
          currentItems={data.currentItems}
          isPlaying={data.isPlaying}
          showPlayer={data.showPlayer}
          handleTogglePlay={data.handleTogglePlay}
          handleStartPlayingClick={data.handleStartPlayingClick}
        />
      )}
    </Draggable>
  );
};

const ItemList = ({
  column,
  index,
  func,
  currentItems,
  isPlaying,
  showPlayer,
  handleTogglePlay,
  handleStartPlayingClick,
}) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          columnId={column.id}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        return (
          <FixedSizeList
            height={420}
            itemCount={itemCount}
            itemSize={60}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={{
              items: column.items,
              columnId: column.id,
              func: func,
              currentItems: currentItems,
              isPlaying: isPlaying,
              showPlayer: showPlayer,
              handleTogglePlay: handleTogglePlay,
              handleStartPlayingClick: handleStartPlayingClick,
            }}
            className="task-list"
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
};

class ColumnDraggable extends Component {
  getColumnStyle = (index) => ({
    border: index == 1 && "2px solid #e8e8e8",
    borderRadius: "8px",
    ".item": {
      background: "red",
    },
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  render() {
    const {
      column,
      index,
      func,
      currentItems,
      isPlaying,
      showPlayer,
      handleTogglePlay,
      handleStartPlayingClick,
      label,
    } = this.props;
    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <div
            className={`ks--column is-6-desktop is-12-tablet is-12-mobile px-1 py-1`}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={this.getColumnStyle(index)}
          >
            <div {...provided.dragHandleProps} />
            {index == 1 && (
              <div
                style={{
                  position: "absolute",
                  border: "2px dashed #ccc",
                  padding: "10px 20px",
                  borderRadius: "16px",
                }}
              >
                <Text upper size="xl" weight="strong" lineBreak color="gray13">
                  <span style={{ color: "#ccc" }}>{label}</span>
                </Text>
              </div>
            )}
            <ItemList
              column={column}
              index={index}
              func={func}
              currentItems={currentItems}
              isPlaying={isPlaying}
              showPlayer={showPlayer}
              handleTogglePlay={handleTogglePlay}
              handleStartPlayingClick={handleStartPlayingClick}
            />
          </div>
        )}
      </Draggable>
    );
  }
}

@observer
class AdsBatchesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      name: null,
      messageError: null,
      isSaving: false,
      isPlaying: true,
      currentItems: null,
      items: this.getItemsData(this.props.ads),
      state: {},
    };
    this.grid = 4;
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  handleStartPlayingClick(items) {
    this.props.store.ui.startPlayingAds(items, 3, false);
    this.setState({
      isPlaying: true,
      currentItems: items,
    });
  }

  handleTogglePlay(items) {
    let isPlaying = false;
    if (items) {
      if (!this.props.store.ui.isPlaying) {
        this.props.store.ui.play();
        isPlaying = true;
      } else {
        this.props.store.ui.pause();
        isPlaying = false;
      }
      this.setState({
        isPlaying: isPlaying,
        currentItems: items,
      });
    }
  }

  componentDidMount() {
    const { items, search } = this.state;
    const arrayItems = this.filterItems(items, search);
    const state = {
      columns: {
        "column-0": {
          id: "column-0",
          title: "First column",
          items: arrayItems,
        },
        "column-1": {
          id: "column-1",
          title: "Second column",
          items: this.getItemsData(this.props.selected),
        },
      },
      columnOrder: ["column-0", "column-1"],
    };
    this.setState({ state: state });
  }

  getItemsData = (obj) => {
    var arr = [];
    obj.map((a) =>
      arr.push({ key: uuidv4(), id: a.id, name: a.name, ad_path: a.ad_path })
    );
    return arr;
  };

  handleClose = () => {
    this.props.store.ui.destroy();
    this.props.onClose && this.props.onClose();
  };

  deleted = (key) => {
    const lists = this.state.state.columns["column-1"].items;
    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    lists.splice(index, 1);

    const newSate = this.state.state;
    newSate.columns["column-1"].items = lists;

    this.setState({
      state: newSate,
      messageError: null,
    });
  };

  /*updatedQuantity = (key,value) => {
        const lists = this.state.state.columns['column-1'].items
        var index = lists.map(x => {
            return x.key;
        }).indexOf(key);

        lists[index].quantity=value;
        
        const newSate = this.state.state
        newSate.columns['column-1'].items = lists;

        this.setState({
            state: newSate,
            messageError: null
        });
    };*/

  handleSave = () => {
    const { toastManager } = this.props;
    const { state } = this.state;
    this.props.store.ui.destroy();
    this.props.onSave && this.props.onSave(state.columns["column-1"].items);
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  getListOriginStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#1b1b1b" : "#1b1b1b",
    padding: this.grid,
  });

  getListDestinationStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#1b1b1b" : "#1b1b1b",
    padding: this.grid,
    border: "1px dashed #F7F7F7",
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    // estilos que necesitamos aplicar en draggables
    ...draggableStyle,
    // algunos estilos básicos para que los artículos se vean un poco más bonitos
    userSelect: "none",
    position: "static",
    padding: 8 * 2,
    margin: "0 0 8px 0",
    alignItems: "flex-start",
    alignContent: "flex-start",
    borderRadius: "3px",
    border:
      "1px ${(props) => (props.isDragging ? 'dashed #000' : 'solid #ddd')}",
    // cambiar el color de fondo si arrastra
    background: isDragging ? "#12C889" : "#1F1F1F",
  });

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];
    destClone.splice(droppableDestination.index, 0, { ...item, key: uuidv4() });
    return destClone;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    const { state } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = this.reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      this.setState({
        state: {
          ...state,
          columnOrder,
        },
      });
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = this.reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      this.setState({ state: newState });
      return;
    }

    // moving between lists
    const sourceColumn = state.columns[result.source.droppableId];
    const destinationColumn = state.columns[result.destination.droppableId];
    const { source, destination } = result;

    // eliminado de la lista
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case "column-0":
        const result = this.copy(
          sourceColumn.items,
          destinationColumn.items,
          source,
          destination
        );

        const newSate = this.state.state;
        newSate.columns["column-1"].items = result;

        this.setState({
          state: newSate,
          messageError: null,
        });
        break;
    }
  };

  validLength = (value) => {
    if (value != null) {
      if (value.length >= 1 && value.length <= 50) {
        return true;
      } else {
        return false;
      }
    } else {
      this.setState({ messageError: this.props.store.language.label439 });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    const newSate = this.state.state;
    const arrayItems = this.filterItems(this.state.items, value);
    newSate.columns["column-0"].items = arrayItems;
    this.setState({ state: newSate, search: value });
  };

  filterItems = (array, search) => {
    array = array.filter(
      (el) => el.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
    return array;
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mb-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3 mb-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderDragDrop = () => {
    const { state, currentItems, isPlaying } = this.state;
    return (
      <React.Fragment>
        {state && state.columnOrder && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="app">
              <Droppable
                droppableId="all-droppables"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="ks--columns"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.columnOrder.map((columnId, index) => (
                      <>
                        <ColumnDraggable
                          key={columnId}
                          column={state.columns[columnId]}
                          index={index}
                          func={this.deleted}
                          currentItems={currentItems}
                          isPlaying={isPlaying}
                          showPlayer={this.props.store.ui.showPlayer}
                          handleTogglePlay={this.handleTogglePlay}
                          handleStartPlayingClick={this.handleStartPlayingClick}
                          label={this.props.store.language.label514}
                        />
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </React.Fragment>
    );
  };

  renderSearch = () => {
    return (
      <>
        <Columns className="my-1" isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
            <Field
              label={this.props.store.language.label513}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                name="search"
                iconKasimu="search"
                onChange={this.handleChangeSearch}
                placeholder={this.props.store.language.label418}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={this.props.store.viewMobile && "20px"}
              />
            </Field>
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
            <Text
              lineBreak
              className="mt-3"
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {this.props.store.language.label512}
            </Text>
          </Column>
        </Columns>
      </>
    );
  };

  render() {
    const { currentItems } = this.state;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "940px" : "90%"}
        height={this.props.store.viewMobile ? "640px" : "auto"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label510}
          </Text>
        </ModalHeader>
        <ModalContent>
          <div style={{ maxWidth: "710px" }}>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              color="special"
              lineBreak
            >
              {this.props.store.language.label511}
            </Text>
          </div>
          <br />

          <div
            className="is-row"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.props.store.ui.showPlayer && (
              <div className="is-row">
                <Button
                  icon={this.props.store.ui.isPlaying ? "pause" : "play"}
                  kind="link"
                  color="primary"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  onClick={() => this.handleTogglePlay(currentItems)}
                  id="play-button"
                  space
                />
                <div
                  className={`${
                    this.props.store.viewMobile ? "ml-4" : "ml-2"
                  } is-row`}
                  style={{ alignItems: "center" }}
                  maxLength={this.props.mobile ? 50 : 250}
                >
                  {currentItems && (
                    <Text
                      multiline
                      weight="normal"
                      lead={this.props.store.viewMobile}
                      size={this.props.store.viewMobile ? "sm" : "xl"}
                      color={"primary"}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {currentItems.name}
                      </span>
                    </Text>
                  )}
                </div>
              </div>
            )}
            {this.props.store.ui.showPlayer && (
              <div>
                <Text
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  maxLength={this.props.mobile ? 50 : 250}
                >
                  {this.props.store.ui.currentDuration == "NaN:NaN"
                    ? "00:00 / 00:00"
                    : this.props.store.ui.currentDuration &&
                      this.props.store.ui.currentProgressTime &&
                      this.props.store.ui.currentProgressTime +
                        " / " +
                        this.props.store.ui.currentDuration}
                </Text>
              </div>
            )}
          </div>
          <div
            id={"waveAds"}
            style={{ height: this.props.store.ui.showPlayer ? 100 : 0 }}
          />
          {this.renderSearch()}
          {this.renderDragDrop()}
          <div className="my-1"></div>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}
AdsBatchesModal.propTypes = {
  ads: PropTypes.Array,
  selected: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

AdsBatchesModal.defaultProps = {
  ads: [],
  selected: [],
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(AdsBatchesModal));
