import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import {
  LayoutSideMenuLink,
  Text,
  SidebarMenu,
  SidebarMenuLink,
} from "ks_storybook";
import withStore from "../../hocs/withStore";

@observer
class ControlPanelMenu extends Component {
  state = {
    value: "",
  };

  handleChangeShow = (val) => {
    if (this.state.value !== val) this.setState({ value: val });
    else this.setState({ value: "" });
  };

  renderRadio = (path) => {
    const { handleToogle } = this.props;
    return (
      <>
        <li>
          {this.props.store.viewMobile ? (
            <LayoutSideMenuLink size="lg" to={`${path}pp_media_manager`}>
              {this.props.store.language.label30}
            </LayoutSideMenuLink>
          ) : (
            <LayoutSideMenuLink
              size="lg"
              to={`${path}pp_media_manager`}
              onClick={handleToogle}
            >
              {this.props.store.language.label30}
            </LayoutSideMenuLink>
          )}
        </li>
        <li>
          <LayoutSideMenuLink
            size="lg"
            to={`${path}calendar_manager`}
            onClick={handleToogle}
          >
            {this.props.store.language.label31}
          </LayoutSideMenuLink>
        </li>
      </>
    );
  };

  renderAdsPlanns = (path) => {
    const { handleToogle } = this.props;
    return (
      <>
        <li>
          {this.props.store.viewMobile ? (
            <LayoutSideMenuLink size="lg" to={`${path}ads/list`}>
              {this.props.store.language.label32}
            </LayoutSideMenuLink>
          ) : (
            <LayoutSideMenuLink
              size="lg"
              to={`${path}ads/list`}
              onClick={handleToogle}
            >
              {this.props.store.language.label32}
            </LayoutSideMenuLink>
          )}
        </li>
      </>
    );
  };

  seeCertificate = () => {
    if (this.props.store.getFreeRights() > 0)
        return true;
    return false;
  };

  verifyPermissions = (arr) => {
    let bool = true;
    let i = 0;
    while (i < arr.length && bool) {
      if (this.props.store.verifyPermission(arr[i])) {
        bool = false;
      }
      i++;
    }

    return !bool;
  };

  getRoutePermissions = (arr, routes) => {
    let route = "";
    let i = 0;
    while (i < arr.length && route.length <= 0) {
      if (this.props.store.verifyPermission(arr[i])) {
        route = routes[i];
      }
      i++;
    }

    return route;
  };

  renderMultiUserMenu = () => {
    const { handleChangeShow } = this;
    const { value } = this.state;
    const path = this.props.match.path;
    const location = this.props.location.pathname;

    return [
      <React.Fragment>
        <div className="media-content pb-4 px-2">
          <Text size="md" color="white" weight="strong" lead lineBreak upper>
            {this.props.store.language.label16}
          </Text>
        </div>
        <SidebarMenu
          title={this.props.store.language.label17}
          to={`${path}users/accounts`}
          titleClick={() => handleChangeShow("usuarios")}
          show={value == "usuarios"}
          iconKasimu="user-solid"
          activation
        ></SidebarMenu>

        {this.verifyPermissions([37, 39, 41, 43, 44]) && (
          <SidebarMenu
            title={this.props.store.language.label731}
            to={this.getRoutePermissions(
              [37, 39, 41, 43, 44],
              [
                `${path}music/list`,
                `${path}music/favorites`,
                `${path}music/manage_styles`,
                `${path}music/songs`,
                `${path}music/blocked_singers`,
              ]
            )}
            titleClick={() => handleChangeShow("playlist")}
            show={value == "playlist"}
            iconKasimu="playlist-solid"
            arrowRight
            activation
          >
            <div className="media-content">
              {this.props.store.verifyPermission(37) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/list`}
                  active={`${path}music/list` === location}
                  iconKasimu="playlist-solid"
                >
                  {this.props.store.language.label323}
                </SidebarMenuLink>
              )}
              {this.props.store.verifyPermission(39) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/favorites`}
                  active={`${path}music/favorites` === location}
                  icon="star"
                >
                  {this.props.store.language.label743}
                </SidebarMenuLink>
              )}
              {this.props.store.verifyPermission(41) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/manage_styles`}
                  active={`${path}music/manage_styles` === location}
                  iconKasimu="music"
                >
                  {this.props.store.language.label522}
                </SidebarMenuLink>
              )}
              {this.props.store.verifyPermission(43) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/songs`}
                  active={`${path}music/songs` === location}
                  iconKasimu="song"
                >
                  {this.props.store.language.label537}
                </SidebarMenuLink>
              )}
              {this.props.store.verifyPermission(44) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/blocked_singers`}
                  active={`${path}music/blocked_singers` === location}
                  icon="ban"
                >
                  {this.props.store.language.label773}
                </SidebarMenuLink>
              )}
            </div>
          </SidebarMenu>
        )}
        {this.props.store.verifyPermission(46) && (
          <SidebarMenu
            title={this.props.store.language.label29}
            to={
              this.props.store.getUserRadio()
                ? `${path}pp_media_manager`
                : `${path}ads/list`
            }
            titleClick={() => handleChangeShow("anuncios")}
            show={value == "anuncios"}
            iconKasimu="ads-solid"
            activation
          ></SidebarMenu>
        )}

        {this.props.store.verifyPermission(48) && (
          <SidebarMenu
            title={
              this.props.store.language.label717 +
              " / " +
              this.props.store.language.label701
            }
            to={`${path}support/support`}
            titleClick={() => handleChangeShow("support")}
            show={value == "support"}
            iconKasimu="download"
            arrowRight
            activation
          >
            <div className="media-content">
              <SidebarMenuLink
                size="lg"
                to={`${path}support/support`}
                active={`${path}support/support` === location}
                iconKasimu="phone"
              >
                {this.props.store.language.label717}
              </SidebarMenuLink>

              <SidebarMenuLink
                size="lg"
                to={`${path}support/downloads`}
                active={`${path}support/downloads` === location}
                iconKasimu="download"
              >
                {this.props.store.language.label701}
              </SidebarMenuLink>

              {this.seeCertificate() && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}support/certificate`}
                  active={`${path}support/certificate` === location}
                  iconKasimu="certificate"
                >
                  {this.props.store.language.label722}
                </SidebarMenuLink>
              )}
            </div>
          </SidebarMenu>
        )}
      </React.Fragment>,
    ];
  };

  renderMobileMultiUserMenu = () => {
    const { handleChangeShow } = this;
    const { value } = this.state;
    const { handleToogle } = this.props;
    const path = this.props.match.path;
    const location = this.props.location.pathname;

    return [
      <React.Fragment>
        <div className="media-content pb-3 pt-3 px-2">
          <Text size="md" color="white" weight="strong" lead lineBreak upper>
            {this.props.store.language.label16}
          </Text>
        </div>
        <SidebarMenu
          title={this.props.store.language.label17}
          to={`${path}users/accounts`}
          titleClick={() => {
            handleChangeShow("usuarios");
            handleToogle();
          }}
          show={value == "usuarios"}
          iconKasimu="user-solid"
          activation
        ></SidebarMenu>

        {this.verifyPermissions([37, 39, 41, 43, 44]) && (
          <SidebarMenu
            title={this.props.store.language.label731}
            to={this.getRoutePermissions(
              [37, 39, 41, 43, 44],
              [
                `${path}music/list`,
                `${path}music/favorites`,
                `${path}music/manage_styles`,
                `${path}music/songs`,
                `${path}music/blocked_singers`,
              ]
            )}
            titleClick={() => handleChangeShow("playlist")}
            show={value == "playlist"}
            iconKasimu="playlist-solid"
            arrowRight
            activation
          >
            <div className="media-content">
              {this.props.store.verifyPermission(37) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/list`}
                  active={`${path}music/list` === location}
                  iconKasimu="playlist-solid"
                  onClick={handleToogle}
                >
                  {this.props.store.language.label323}
                </SidebarMenuLink>
              )}

              {this.props.store.verifyPermission(39) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/favorites`}
                  active={`${path}music/favorites` === location}
                  icon="star"
                  onClick={handleToogle}
                >
                  {this.props.store.language.label743}
                </SidebarMenuLink>
              )}

              {this.props.store.verifyPermission(41) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/manage_styles`}
                  active={`${path}music/manage_styles` === location}
                  iconKasimu="music"
                  onClick={handleToogle}
                >
                  {this.props.store.language.label522}
                </SidebarMenuLink>
              )}

              {this.props.store.verifyPermission(43) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/songs`}
                  active={`${path}music/songs` === location}
                  iconKasimu="song"
                  onClick={handleToogle}
                >
                  {this.props.store.language.label537}
                </SidebarMenuLink>
              )}

              {this.props.store.verifyPermission(44) && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}music/blocked_singers`}
                  active={`${path}music/blocked_singers` === location}
                  icon="ban"
                  onClick={handleToogle}
                >
                  {this.props.store.language.label773}
                </SidebarMenuLink>
              )}
            </div>
          </SidebarMenu>
        )}

        {this.props.store.verifyPermission(46) && (
          <SidebarMenu
            title={this.props.store.language.label29}
            to={
              this.props.store.getUserRadio()
                ? `${path}pp_media_manager`
                : `${path}ads/list`
            }
            titleClick={() => {
              handleChangeShow("anuncios");
              handleToogle();
            }}
            show={value == "anuncios"}
            iconKasimu="ads-solid"
            activation
          ></SidebarMenu>
        )}

        {this.props.store.verifyPermission(48) && (
          <SidebarMenu
            title={
              this.props.store.language.label717 +
              " / " +
              this.props.store.language.label701
            }
            to={`${path}support/support`}
            titleClick={() => handleChangeShow("support")}
            show={value == "support"}
            iconKasimu="download"
            arrowRight
            activation
          >
            <div className="media-content">
              <SidebarMenuLink
                size="lg"
                to={`${path}support/support`}
                active={`${path}support/support` === location}
                iconKasimu="phone"
                onClick={handleToogle}
              >
                {this.props.store.language.label717}
              </SidebarMenuLink>

              <SidebarMenuLink
                size="lg"
                to={`${path}support/downloads`}
                active={`${path}support/downloads` === location}
                iconKasimu="download"
                onClick={handleToogle}
              >
                {this.props.store.language.label701}
              </SidebarMenuLink>

              {this.seeCertificate() && (
                <SidebarMenuLink
                  size="lg"
                  to={`${path}support/certificate`}
                  active={`${path}support/certificate` === location}
                  iconKasimu="certificate"
                  onClick={handleToogle}
                >
                  {this.props.store.language.label722}
                </SidebarMenuLink>
              )}
            </div>
          </SidebarMenu>
        )}
      </React.Fragment>,
    ];
  };

  renderSingleUserMenu = () => {
    const { handleChangeShow } = this;
    const { value } = this.state;
    const path = this.props.match.path;
    const location = this.props.location.pathname;

    return [
      <React.Fragment>
        <div className="media-content pb-4 px-2">
          <Text size="md" color="white" weight="strong" lead lineBreak upper>
            {this.props.store.language.label16}
          </Text>
        </div>
        <SidebarMenu
          title={this.props.store.language.label88}
          to={`${path}users/activities`}
          titleClick={() => handleChangeShow("usuarios")}
          show={value == "usuarios"}
          iconKasimu="user-solid"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={`${path}users/activities`}
              active={`${path}users/activities` === location}
              iconKasimu="user-solid"
            >
              {this.props.store.language.label34}
            </SidebarMenuLink>

            {!this.props.store.loggedInUser.franquisor_user_id && (
              <SidebarMenuLink
                size="lg"
                to={`${path}users/personalization`}
                active={`${path}users/personalization` === location}
                icon="id-card"
              >
                {this.props.store.language.label38}
              </SidebarMenuLink>
            )}

            <SidebarMenuLink
              size="lg"
              to={`${path}users/preferences`}
              active={`${path}users/preferences` === location}
              icon="sliders-h"
            >
              {this.props.store.language.label23}
            </SidebarMenuLink>

            {!this.props.store.loggedInUser.franquisor_user_id && (
              <SidebarMenuLink
                size="lg"
                to={`${path}users/permissions`}
                active={`${path}users/permissions` === location}
                icon="ban"
              >
                {this.props.store.language.label748}
              </SidebarMenuLink>
            )}
          </div>
        </SidebarMenu>

        <SidebarMenu
          title={this.props.store.language.label731}
          to={`${path}music/list`}
          titleClick={() => handleChangeShow("playlist")}
          show={value == "playlist"}
          iconKasimu="playlist-solid"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={`${path}music/list`}
              active={`${path}music/list` === location}
              iconKasimu="playlist-solid"
            >
              {this.props.store.language.label323}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}music/favorites`}
              active={`${path}music/favorites` === location}
              icon="star"
            >
              {this.props.store.language.label743}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}playlist_plans`}
              active={`${path}playlist_plans` === location}
              iconKasimu="playlist"
            >
              {this.props.store.language.label37}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}music/manage_styles`}
              active={`${path}music/manage_styles` === location}
              iconKasimu="music"
            >
              {this.props.store.language.label522}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}music/songs`}
              active={`${path}music/songs` === location}
              iconKasimu="song"
            >
              {this.props.store.language.label537}
            </SidebarMenuLink>
            {/*<SidebarMenuLink
              size="lg"
              to={`${path}music/blocked_singers`}
              active={`${path}music/blocked_singers` === location}
              iconKasimu="song"
            >
              {this.props.store.language.label773}
            </SidebarMenuLink>*/}
          </div>
        </SidebarMenu>

        <SidebarMenu
          title={this.props.store.language.label29}
          to={
            this.props.store.getUserRadio()
              ? `${path}pp_media_manager`
              : `${path}ads/list`
          }
          titleClick={() => handleChangeShow("anuncios")}
          show={value == "anuncios"}
          iconKasimu="ads-solid"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={
                this.props.store.getUserRadio()
                  ? `${path}pp_media_manager`
                  : `${path}ads/list`
              }
              active={`${path}ads/list` === location}
              iconKasimu="ads-solid"
            >
              {this.props.store.language.label29}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}ads_plans`}
              active={`${path}ads_plans` === location}
              iconKasimu="ads"
            >
              {this.props.store.language.label39}
            </SidebarMenuLink>
          </div>
        </SidebarMenu>

        <SidebarMenu
          title={
            this.props.store.language.label717 +
            " / " +
            this.props.store.language.label701
          }
          to={`${path}support/support`}
          titleClick={() => handleChangeShow("support")}
          show={value == "support"}
          iconKasimu="download"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={`${path}support/support`}
              active={`${path}support/support` === location}
              iconKasimu="phone"
            >
              {this.props.store.language.label717}
            </SidebarMenuLink>

            <SidebarMenuLink
              size="lg"
              to={`${path}support/downloads`}
              active={`${path}support/downloads` === location}
              iconKasimu="download"
            >
              {this.props.store.language.label701}
            </SidebarMenuLink>

            {this.seeCertificate() && (
              <SidebarMenuLink
                size="lg"
                to={`${path}support/certificate`}
                active={`${path}support/certificate` === location}
                iconKasimu="certificate"
              >
                {this.props.store.language.label722}
              </SidebarMenuLink>
            )}
          </div>
        </SidebarMenu>
      </React.Fragment>,
    ];
  };

  renderMobileSingleUserMenu = () => {
    const { handleChangeShow } = this;
    const { value } = this.state;
    const { handleToogle } = this.props;
    const path = this.props.match.path;
    const location = this.props.location.pathname;

    return [
      <React.Fragment>
        <div className="media-content pb-3 pt-3 px-2">
          <Text size="md" color="white" weight="strong" lead lineBreak upper>
            {this.props.store.language.label16}
          </Text>
        </div>
        <SidebarMenu
          title={this.props.store.language.label88}
          to={`${path}users/activities`}
          titleClick={() => handleChangeShow("usuarios")}
          show={value == "usuarios"}
          iconKasimu="user-solid"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={`${path}users/activities`}
              active={`${path}users/activities` === location}
              iconKasimu="user-solid"
              onClick={handleToogle}
            >
              {this.props.store.language.label34}
            </SidebarMenuLink>

            {!this.props.store.loggedInUser.franquisor_user_id && (
              <SidebarMenuLink
                size="lg"
                to={`${path}users/personalization`}
                active={`${path}users/personalization` === location}
                icon="id-card"
                onClick={handleToogle}
              >
                {this.props.store.language.label38}
              </SidebarMenuLink>
            )}

            <SidebarMenuLink
              size="lg"
              to={`${path}users/preferences`}
              active={`${path}users/preferences` === location}
              iconKasimu="setting"
              onClick={handleToogle}
            >
              {this.props.store.language.label23}
            </SidebarMenuLink>

            {!this.props.store.loggedInUser.franquisor_user_id && (
              <SidebarMenuLink
                size="lg"
                to={`${path}users/permissions`}
                active={`${path}users/permissions` === location}
                icon="ban"
                onClick={handleToogle}
              >
                {this.props.store.language.label748}
              </SidebarMenuLink>
            )}
          </div>
        </SidebarMenu>

        <SidebarMenu
          title={this.props.store.language.label731}
          to={`${path}music/list`}
          titleClick={() => handleChangeShow("playlist")}
          show={value == "playlist"}
          iconKasimu="playlist-solid"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={`${path}music/list`}
              active={`${path}music/list` === location}
              iconKasimu="playlist-solid"
              onClick={handleToogle}
            >
              {this.props.store.language.label323}
            </SidebarMenuLink>
            {this.props.store.verifyPermission(39) && (
              <SidebarMenuLink
                size="lg"
                to={`${path}music/favorites`}
                active={`${path}music/favorites` === location}
                icon="star"
                onClick={handleToogle}
              >
                {this.props.store.language.label743}
              </SidebarMenuLink>
            )}
            <SidebarMenuLink
              size="lg"
              to={`${path}playlist_plans`}
              active={`${path}playlist_plans` === location}
              iconKasimu="playlist"
              onClick={handleToogle}
            >
              {this.props.store.language.label37}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}music/manage_styles`}
              active={`${path}music/manage_styles` === location}
              iconKasimu="music"
              onClick={handleToogle}
            >
              {this.props.store.language.label522}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}music/songs`}
              active={`${path}music/songs` === location}
              iconKasimu="song"
              onClick={handleToogle}
            >
              {this.props.store.language.label537}
            </SidebarMenuLink>
            {/*<SidebarMenuLink
              size="lg"
              to={`${path}music/blocked_singers`}
              active={`${path}music/blocked_singers` === location}
              iconKasimu="song"
            >
              {this.props.store.language.label773}
            </SidebarMenuLink>*/}
          </div>
        </SidebarMenu>

        <SidebarMenu
          title={this.props.store.language.label29}
          to={
            this.props.store.getUserRadio()
              ? `${path}pp_media_manager`
              : `${path}ads/list`
          }
          titleClick={() => handleChangeShow("anuncios")}
          show={value == "anuncios"}
          iconKasimu="ads-solid"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={
                this.props.store.getUserRadio()
                  ? `${path}pp_media_manager`
                  : `${path}ads/list`
              }
              active={`${path}ads/list` === location}
              iconKasimu="ads-solid"
              onClick={handleToogle}
            >
              {this.props.store.language.label29}
            </SidebarMenuLink>
            <SidebarMenuLink
              size="lg"
              to={`${path}ads_plans`}
              active={`${path}ads_plans` === location}
              iconKasimu="ads"
              onClick={handleToogle}
            >
              {this.props.store.language.label39}
            </SidebarMenuLink>
          </div>
        </SidebarMenu>

        <SidebarMenu
          title={
            this.props.store.language.label717 +
            " / " +
            this.props.store.language.label701
          }
          to={`${path}support/support`}
          titleClick={() => handleChangeShow("support")}
          show={value == "support"}
          iconKasimu="download"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              size="lg"
              to={`${path}support/support`}
              active={`${path}support/support` === location}
              iconKasimu="phone"
              onClick={handleToogle}
            >
              {this.props.store.language.label717}
            </SidebarMenuLink>

            <SidebarMenuLink
              size="lg"
              to={`${path}support/downloads`}
              active={`${path}support/downloads` === location}
              iconKasimu="download"
              onClick={handleToogle}
            >
              {this.props.store.language.label701}
            </SidebarMenuLink>

            {this.seeCertificate() && (
              <SidebarMenuLink
                size="lg"
                to={`${path}support/certificate`}
                active={`${path}support/certificate` === location}
                iconKasimu="certificate"
                onClick={handleToogle}
              >
                {this.props.store.language.label722}
              </SidebarMenuLink>
            )}
          </div>
        </SidebarMenu>
      </React.Fragment>,
    ];
  };

  render() {
    var menu = [];
    menu.push(
      this.props.store.viewMobile
        ? this.props.store.getSingleUser()
          ? this.renderSingleUserMenu()
          : this.renderMultiUserMenu()
        : this.props.store.getSingleUser()
        ? this.renderMobileSingleUserMenu()
        : this.renderMobileMultiUserMenu()
    );

    return <React.Fragment>{menu}</React.Fragment>;
  }
}

export default withStore(withRouter(ControlPanelMenu));
