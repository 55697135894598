import { Model } from "ks_storybook";

class UsersSupervisors extends Model {
  urlRoot = "/users_supervisors";

  saveAsSupervisor() {
    return this._store.saveAsSupervisor(this);
  }
}

export default UsersSupervisors;