import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Text, Button, TextInput, Loader, Columns, Column } from "ks_storybook";
import {
  EmptyContent,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  PlaylistsFavoriteNewModal,
  PlaylistsFavoriteRow,
} from "../../../Components/Playlists";

@observer
class PlaylistsFavorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
      favoritePlaylists: this.getUserFavoritePlaylists(),
      currentPlaylist: null,
      search: "",
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label743;
  }

  getUserFavoritePlaylists = () => {
    return this.props.store.userFavoritePlaylists.fetchAllParams(
      this.props.store.lang,
      null,
      true
    );
  };

  handleShowNewModal = () => {
    this.setState({
      showNewModal: true,
    });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleSaveNewModal = () => {
    this.handleReload();
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  handleShowDeleteModal = (playlist) => {
    this.setState({ showDeleteModal: true, currentPlaylist: playlist });
  };

  handleCloseDeleteModal = () => {
    this.handleReload();
  };

  handleDelete = () => {
    this.handleCloseDeleteModal();
  };

  handleReload = () => {
    this.setState({
      showNewModal: false,
      showDeleteModal: false,
      favoritePlaylists: this.getUserFavoritePlaylists(),
      currentPlaylist: null,
    });
  };

  getSearch = (obj, search) => {
    var arr = [];
    if (obj.length != 0) {
      arr = obj
      .toArray()
      .filter((e) =>
        e &&
        e.categories_playlists &&
        e.categories_playlists.alias &&
        e.categories_playlists.alias.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
      arr.sort(function (a, b) {
        var A = a.categories_playlists.alias.toUpperCase(); // Ignorar mayúsculas y minúsculas
        var B = b.categories_playlists.alias.toUpperCase(); // Ignorar mayúsculas y minúsculas
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        // los nombres son iguales
        return 0;
      });
    }
    return arr;
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getPanel = () => {
    return (
      <Columns
        className={this.props.store.viewMobile && "my-2"}
        isMultiline
        style={{
          margin: !this.props.viewMobile && "6px 0px",
        }}
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile && "mr-2"}
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: !this.props.store.viewMobile && "6px",
            }}
          >
            <TextInput
              onChange={this.handleChangeSearch}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              placeholder={this.props.store.language.label586}
            />
          </div>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleShowNewModal}
            onClick={() =>
              this.props.store.getPermission(40, 40, () => {
                this.handleShowNewModal();
              })
            }
          >
            <strong>{this.props.store.language.label759}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.handleReload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderHeaderOk = () => {
    const { search, favoritePlaylists } = this.state;
    const arrayFavorites = this.getSearch(favoritePlaylists, search);
    return (
      <React.Fragment>
        {arrayFavorites.length === 0 ? (
          <EmptyContent />
        ) : (
          arrayFavorites.map((playlist) => (
            <PlaylistsFavoriteRow
              playlist={playlist}
              //onDeleteClick={this.handleShowDeleteModal}
              onDeleteClick={(e) =>
                this.props.store.getPermission(40, 40, () => {
                  this.handleShowDeleteModal(e);
                })
              }
            />
          ))
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { favoritePlaylists } = this.state;
    if (!favoritePlaylists) return this.renderEmptyContent();
    return !favoritePlaylists.isOk()
      ? this.renderLoader()
      : this.renderHeaderOk();
  };

  render() {
    const { showNewModal, showDeleteModal, currentPlaylist } = this.state;

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                multiline
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label743}
              </Text>
            )}
            {this.getPanel()}
            {this.renderHeader()}
          </Column>
          {showNewModal && (
            <PlaylistsFavoriteNewModal
              user_id={this.props.store.loggedInUser.id}
              url={"add_playlist_to_favorite"}
              onClose={this.handleCloseNewModal}
              onSave={this.handleSaveNewModal}
            />
          )}
          {showDeleteModal && (
            <DeleteConfirmationModal
              currentObj={currentPlaylist}
              onClose={this.handleCloseDeleteModal}
              onAccept={this.handleDelete}
              messageSuccess={
                this.props.store.language.label580 +
                " " +
                currentPlaylist.categories_playlists.alias +
                " " +
                this.props.store.language.label229
              }
              messageError={
                this.props.store.language.label581 +
                " " +
                currentPlaylist.categories_playlists.alias
              }
              title={
                this.props.store.language.label582 +
                " " +
                currentPlaylist.categories_playlists.alias +
                "?"
              }
              subtitle={
                this.props.store.language.label583 +
                " " +
                currentPlaylist.categories_playlists.alias +
                "."
              }
            />
          )}
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(PlaylistsFavorites);
