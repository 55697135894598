import { Store } from "ks_storybook";
import { UserPersonalization } from "../models";

class UserPersonalizationStore extends Store {
  model = UserPersonalization;

  fetchAll() {
    return this.search(
      {},
      "users_personalization_all",
      true,
      "/users_personalization"
    );
  }

  getPersonalizationUser(value) {
    return this.search(
      { per_page: 1000 },
      "users_personalization_user",
      true,
      `/users_personalization/${value}/get_personalization_user`
    );
  }
}

export default UserPersonalizationStore;
