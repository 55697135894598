import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Paragraph,
  Text,
  Field,
  Button,
} from "ks_storybook";
import { Level, LevelRight, LevelLeft, Columns, Column } from "bloomer";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";

@observer
class EditSelectionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      selectedAction: 0,
      selectedPlaylist: [],
      selectedUserStyle: 0,
    };
  }

  getOkMessager(index) {
    const messager = {
      1: this.props.store.language.label822,
      2: this.props.store.language.label823,
      3: this.props.store.language.label824,
      4: this.props.store.language.label825,
      5: this.props.store.language.label826,
      6: this.props.store.language.label827,
      7: this.props.store.language.label828,
      8: this.props.store.language.label829,
      9: this.props.store.language.label830
    };
    return messager[index];
  }

  getErrorMessager(index) {
    const messager = {
      1: this.props.store.language.label831,
      2: this.props.store.language.label832,
      3: this.props.store.language.label833,
      4: this.props.store.language.label834,
      5: this.props.store.language.label835,
      6: this.props.store.language.label836,
      7: this.props.store.language.label837,
      8: this.props.store.language.label838,
      9: this.props.store.language.label839
    };
    return messager[index];
  }

  handleSave = () => {
    const { selectedAction, selectedPlaylist, selectedUserStyle } = this.state;
    const { toastManager, arraySongId } = this.props;
    const params = {
      action: selectedAction,
      songs_ids: arraySongId,
      user_style_id: selectedAction == 1?selectedUserStyle:null,
      playlists_ids: selectedAction == 3?selectedPlaylist.map((s) => s.value):null,      
    };
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/users_songs_edit/create_or_edit_song_preference", params)
        .then(
          (res) => {
            toastManager.add(this.getOkMessager(selectedAction), {
              appearance: "success",
              autoDismiss: true,
            });
            this.handleApply();
          },
          (error) => {
            toastManager.add(this.getErrorMessager(selectedAction), {
              appearance: "error",
              autoDismiss: true,
            });
            this.handleClose();
          }
        )
        .catch((error) => {
          toastManager.add(this.getErrorMessager(selectedAction), {
            appearance: "error",
            autoDismiss: true,
          });
          this.handleClose();
        });
    });
  };

  handleApply = () => {
    this.props.onApply && this.props.onApply();
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSelectChange = (value, sender) => {
    switch (sender.name) {
      case "selectAction":
        if (!value) {          
          this.setState({ selectedAction: 0, selectedPlaylist: [], selectedUserStyle: 0 });
        } else {
          this.setState({ selectedAction: value.value, selectedPlaylist: [], selectedUserStyle: 0 });
        }
        break;
      case "selectPlaylist":
        if (!value) {
          this.setState({ selectedPlaylist: [] });
        } else {
          this.setState({ selectedPlaylist: value });
        }
        break;
        case "selectStyle":
          if (!value) {
            this.setState({ selectedUserStyle: 0});
          } else {
            this.setState({ selectedUserStyle: value.value });
          }
          break;
      default:
        break;
    }
  };

  getOptions(obj) {
    return obj.toArray().map((o) => ({ value: o.style_id, label: o.name }));
  };

  loadOptionsUserPlaylists = (inputValue, callback) => {
    if (inputValue == "") {
      return this.props.store.userPlaylists
        .getUserPlaylistsType(1)
        .andThen((res, error) => {
          if (res) {
            const options = res
              .toArray()
              .map((o) => ({ value: o.playlist_id, label: o.name }));
            this.setState({ my_playlists: options });
            callback(options);
          }
        });
    } else {
      const options = this.state.my_playlists
        .filter(
          (el) => el.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )
        .map((o) => ({ value: o.value, label: o.label }));
      callback(options);
    }
  };

  renderSelectPlaylists = (options) => {
    return (
      <Select
        isAsync
        isMulti
        isClearable
        cacheOptions
        defaultOptions
        required
        onChange={this.handleSelectChange}
        name="selectPlaylist"
        autocomplete="nope"
        loadOptions={options}
        backgroundColor="black"
        placeholder={this.props.store.language.label574}
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
        className="my-1"
      />
    );
  };

  renderSelectAction = (options) => {
    return (
      <Select
        isClearable
        cacheOptions
        defaultOptions
        required
        onChange={this.handleSelectChange}
        name="selectAction"
        autocomplete="nope"
        options={options}
        backgroundColor="black"
        placeholder={this.props.store.language.label840}
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
        className="my-1"
      />
    );
  };

  
  renderSelectStyle = (options) => {
    return (
      <Select
        isClearable
        cacheOptions
        defaultOptions
        required
        onChange={this.handleSelectChange}
        name="selectStyle"
        autocomplete="nope"
        options={options}
        backgroundColor="black"
        placeholder={this.props.store.language.label547}
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
        className="my-1"
      />
    );
  };

  renderButtons = () => {
    const { selectedAction, selectedPlaylist, selectedUserStyle } = this.state;
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label841}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={(selectedAction == 1 || selectedAction == 3)?(selectedUserStyle == 0)?(selectedPlaylist.length === 0)?true:false:false:(selectedAction == 0)?true:false}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label842}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { selectedAction } = this.state;
    const { arraySongId, usersStyles } = this.props;
    const typeOfAction = [
      { value: 1, label: this.props.store.language.label843 },
      { value: 2, label: this.props.store.language.label844 },
      { value: 3, label: this.props.store.language.label845 },
      { value: 4, label: this.props.store.language.label846 },
      { value: 5, label: this.props.store.language.label847 },
      { value: 6, label: this.props.store.language.label848 },
      { value: 7, label: this.props.store.language.label849 },
      { value: 8, label: this.props.store.language.label850 },
      { value: 9, label: this.props.store.language.label851 }
    ];
    const optionsStyle = this.getOptions(usersStyles);
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
        >
          <ModalHeader>
            <Text
              multiline
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {this.props.store.language.label815}
            </Text>
          </ModalHeader>
          <ModalContent>
            <div style={{ maxWidth: "710px" }}>
              <Text
                color="special"
                size={this.props.store.viewMobile ? "lg" : "md"}
                lineBreak
              >
                {this.props.store.language.label852+': '+arraySongId.length}
              </Text>
            </div>
            <br />
            <Field
              label={this.props.store.language.label853}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              labelNote={this.props.store.language.label76}
              weight="medium"
            >
              {this.renderSelectAction(typeOfAction)}
            </Field>
            <br />
            {(selectedAction == 1) ? (<Field
              label={this.props.store.language.label522}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              labelNote={this.props.store.language.label76}
              weight="medium"
            >
              {this.renderSelectStyle(optionsStyle)}
            </Field>):null}
            <br />
            {(selectedAction == 3) ? (<Field
              label={this.props.store.language.label634}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              labelNote={this.props.store.language.label76}
              weight="medium"
            >
              {this.renderSelectPlaylists(this.loadOptionsUserPlaylists)}
            </Field>):null}
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
EditSelectionModal.propTypes = {
  arraySongId: PropTypes.array,
  usersStyles: PropTypes.object,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
};

EditSelectionModal.defaultProps = {
  arraySongId: [],
  usersStyles: null,
  onApply: null,
  onClose: null,
};

export default withToastManager(withStore(EditSelectionModal));
