import { Store } from "ks_storybook";
import { PPMediaType } from "../models";

class PPMediaTypeStore extends Store {
  model = PPMediaType;

  fetchAll() {
    return this.search({}, "pp_media_type_all", true, "/pp_media_type");
  }
}

export default PPMediaTypeStore;
