import { Store } from "ks_storybook";
import { User } from "../models";

class UsersStore extends Store {
  model = User;

  fetchSearch(value) {
    return this.search(
      { per_page: 10000, search: value },
      "accounts_search",
      true,
      "/users"
    );
  }

  fetchAccounts() {
    return this.search(
      { per_page: 10000 },
      "accounts",
      true,
      "/users/accounts"
    );
  }

  fetchAccountsAll(per_page, filters, orders, search, lang) {
    return this.search(
      { per_page: per_page, 
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders), 
        search: search,
        lang: lang },
      "accounts_get_all_params",
      true,
      "/users/get_all_params"
    );
  }

  fetchPage(per_page, page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "accounts_page",
      true,
      "/users/get_all_params"
    );
  }

  saveAsAccount(model) {
    var str = model.name;
    var pos = str.indexOf("@");
    if (pos === -1) {
      model.name = `@${model.name}`;
    }

    return this.save(model, "/users/create_account");
  }

  fetchAccountLastConnection(lang) {
    return this.search(
      {per_page: 1, lang: lang},
      "get_last_connection",
      true,
      "/users/get_last_connection"
    );
  }
}

export default UsersStore;
