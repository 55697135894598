import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Text,
  CardEvents,
} from "ks_storybook";
import { EmptyContent } from "../Common";
import { withToastManager } from "react-toast-notifications";

@observer
class PPBlocksModal extends Component {
  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  filterItems = (array, search) => {
    if (search != null) {
      array = array.filter(
        (el) => el.content.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return array;
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  renderHeader = (currentPPClock) => {
    return (
      <div style={this.getContainerStyle()}>
        {currentPPClock.blocks.map((block) => (
          <React.Fragment>
            <CardEvents name={block.media_type.name} invert>
              <div style={{ padding: "0 10px" }}>
                <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                  {this.props.store.language.label449 + ": " + block.quantity}
                </Text>
              </div>
            </CardEvents>
          </React.Fragment>
        ))}
      </div>
    );
  };

  getBlocks = (values) => {
    return values.map((o) => o.id);
  };

  render() {
    const { currentPPClock } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "50%" : "auto"}
          invert
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              weight="strong"
              upper
              lineBreak
              lead
            >
              {this.props.store.language.label450 + "\n" + currentPPClock.name}
            </Text>
          </ModalHeader>
          <ModalContent>
            <br />
            {this.renderHeader(currentPPClock)}
          </ModalContent>
          <ModalFooter />
        </Modal>
      </React.Fragment>
    );
  }
}
PPBlocksModal.propTypes = {
  currentPPClock: PropTypes.object,
  onClose: PropTypes.func,
};

PPBlocksModal.defaultProps = {
  currentPPClock: null,
  onClose: null,
};

export default withToastManager(withStore(PPBlocksModal));
