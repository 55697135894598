import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Columns,
  Column,
  Text,
  Panel,
  Button,
  Field,
} from "ks_storybook";
import ReactTooltip from "react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { withToastManager } from "react-toast-notifications";
import es_ES from "../../lang/es_ES.js";
import en_EN from "../../lang/en_EN.js";
import fr_FR from "../../lang/fr_FR.js";
import pt_PT from "../../lang/pt_PT.js";
import et_EE from "../../lang/et_EE.js";
import de_DE from "../../lang/de_DE.js";
import it_IT from "../../lang/it_IT.js";

const PanelContent = (props) => {
  const { children, isMobile, style } = props;
  if (isMobile) {
    return (
      <Panel color="gray" padding="25px 22px" style={style}>
        {children}
      </Panel>
    );
  }
  return children;
};

@observer
class DownloadsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: null,
      url_downloads: null,
      url_web_player: null,
      url_install_win: null,
      url_install_macos: null,
      url_google_play_store: null,
      url_aptoide: null,
      url_apk_x32: null,
      url_apk_x64: null,
    };
    this._language = en_EN;
    this._lang = 2;
  }

  componentDidMount() {
    this.setLang();
    this.props.store.menuTitle = this._language.label701;
  }

  setLang = () => {
    const myArray = window.location.pathname.split("/");
    if (myArray[1]) {
      if (myArray[1] == "en_EN") {
        this._language = en_EN;
        this._lang = 2;
      }
      if (myArray[1] == "es_ES") {
        this._language = es_ES;
        this._lang = 1;
      }
      if (myArray[1] == "fr_FR") {
        this._language = fr_FR;
        this._lang = 3;
      }
      if (myArray[1] == "pt_PT") {
        this._language = pt_PT;
        this._lang = 4;
      }
      if (myArray[1] == "et_EE") {
        this._language = et_EE;
        this._lang = 5;
      }
      if (myArray[1] == "de_DE") {
        this._language = de_DE;
        this._lang = 6;
      }
      if (myArray[1] == "it_IT") {
        this._language = it_IT;
        this._lang = 7;
      }
    }
    this.genURL();
    this.getVersion(1);
  };

  getVersion = (id) => {
    this.props.store.getVersion(id).then((res) => {
      if (res) {
        this.setState({ version: res.version_app });
      }
    });
  };

  openLink = (link, type) => {
    return window.open(link, type);
  };

  getURLDownloadsLang = () => {
    if (this._lang == 2) return "en_EN";
    if (this._lang == 1) return "es_ES";
    if (this._lang == 3) return "fr_FR";
    if (this._lang == 4) return "pt_PT";
    if (this._lang == 5) return "et_EE";
    if (this._lang == 6) return "de_DE";
    if (this._lang == 7) return "it_IT";
  };

  getURLDownloads = () => {
    return this.props.store.getProduction() == 1
      ? "https://panel.kasimu.me/" + this.getURLDownloadsLang()
      : "https://paneltest.megaplayer.me/" + this.getURLDownloadsLang();
  };

  getURLWebPlayer = () => {
    return this.props.store.getProduction() == 1
      ? "https://webplayer.kasimu.me"
      : "https://webplayertest.megaplayer.me";
  };

  getInstallWinLang = () => {
    if (this._lang == 2) return "en";
    if (this._lang == 1) return "es";
    if (this._lang == 3) return "en";
    if (this._lang == 4) return "en";
    if (this._lang == 5) return "en";
    if (this._lang == 6) return "en";
    if (this._lang == 7) return "en";
  };

  genURL = () => {
    const URLS = {
      0: () => {
        this.setState({
          url_downloads: this.getURLDownloads(),
          url_web_player: this.getURLWebPlayer(),
          url_install_win:
            "https://sources.megaplayer.me/installers/install_kasimu_" +
            this.getInstallWinLang() +
            ".exe",
          url_install_macos:
            "https://sources.megaplayer.me/installers/install_kasimu_macos.dmg",
          url_google_play_store:
            "https://play.google.com/store/apps/details?id=com.virtualdj.Kasimu",
          url_aptoide: "https://kasimu-virtual-d-j-sl.es.aptoide.com/app",
          url_apk_x32:
            "https://sources.megaplayer.me/installers/app_kasimu_x32.apk",
          url_apk_x64:
            "https://sources.megaplayer.me/installers/app_kasimu_x64.apk",
        });
      },
      1: () => {
        this.setState({
          url_downloads: "https://audioplayer.me",
          url_web_player: "https://webplayer.audioplayer.me",
          url_install_win:
            "https://sources.megaplayer.me/installers/install_audioplayer_" +
            this.getInstallWinLang() +
            ".exe",
          url_install_macos: null,
          url_google_play_store:
            "https://play.google.com/store/apps/details?id=com.audioplayer.audioplayer",
          url_aptoide: null,
          url_apk_x32:
            "https://sources.megaplayer.me/installers/app_audioplayer_x32.apk",
          url_apk_x64:
            "https://sources.megaplayer.me/installers/app_audioplayer_x64.apk",
        });
      },
      2: () => {
        this.setState({
          url_downloads: "https://funboxmusic.com/descargas",
          url_web_player: "https://player.funboxmusic.com",
          url_install_win:
            "https://sources.megaplayer.me/installers/install_funboxmusic_" +
            this.getInstallWinLang() +
            ".exe",
          url_install_macos: null,
          url_google_play_store:
            "https://play.google.com/store/apps/details?id=com.funboxmusic.funboxmusic",
          url_aptoide: null,
          url_apk_x32:
            "https://sources.megaplayer.me/installers/app_funboxmusic_x32.apk",
          url_apk_x64:
            "https://sources.megaplayer.me/installers/app_funboxmusic_x64.apk",
        });
      },
    };
    URLS[0]();
  };

  render() {
    const {
      version,
      url_downloads,
      url_web_player,
      url_install_win,
      url_install_macos,
      url_google_play_store,
      url_aptoide,
      url_apk_x32,
      url_apk_x64,
    } = this.state;
    const { toastManager } = this.props;
    const mobileStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    };
    return (
      <div
        className={
          this.props.store.viewMobile ? "ks--layout" : "ks--mobile-layout"
        }
      >
        {!this.props.store.viewMobile && (
          <div
            className="flex items-center"
            style={{
              position: "sticky",
              top: 0,
              height: 72,
              justifyContent: "center",
            }}
          >
            <Text size={"xxl"} weight="strong" lineBreak>
              {this._language.label701}
            </Text>
          </div>
        )}
        <div
          className={
            this.props.store.viewMobile ? "ks--layout-container" : "columns"
          }
          style={{ paddingBottom: !this.props.store.viewMobile && "72px" }}
        >
          <div className="ks--layout-content-column">
            <div
              className={
                this.props.store.viewMobile
                  ? "ks--layout-content"
                  : "ks--mobile-layout-content"
              }
            >
              <Columns isMarginless>
                <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                  {this.props.store.viewMobile && (
                    <>
                      <Text
                        size={this.props.store.viewMobile ? "lg" : "sm"}
                        lead
                        weight="strong"
                        lineBreak
                        className="my-3"
                      >
                        {this._language.label701}
                      </Text>
                    </>
                  )}
                  <Columns
                    style={{
                      gap: !this.props.store.viewMobile && "8px",
                    }}
                    className={this.props.store.viewMobile ? "mb-3" : "mb-1"}
                  >
                    <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                      <PanelContent isMobile={!this.props.store.viewMobile}>
                        <Columns>
                          <Column
                            isSize={{ mobile: 12, desktop: 2, tablet: 12 }}
                          >
                            <Text
                              size={this.props.store.viewMobile ? "md" : "sm"}
                              lead
                              weight="strong"
                              lineBreak
                            >
                              {this._language.label702}
                            </Text>
                          </Column>

                          <Column
                            isSize={{ mobile: 12, desktop: 8, tablet: 12 }}
                          >
                            <Text
                              color="special"
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              multiline
                            >
                              {this._language.label703}
                            </Text>
                            <Text
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              multiline
                            >
                              <div className="is-row is-fullwidth items-center mt-1">
                                {"\n" + url_downloads}
                                <div data-tip data-for="copy">
                                  <CopyToClipboard
                                    text={url_downloads}
                                    onCopy={() =>
                                      toastManager.add(
                                        this._language.label723,
                                        {
                                          appearance: "success",
                                          autoDismiss: true,
                                        }
                                      )
                                    }
                                  >
                                    <Button
                                      className="ml-2"
                                      icon="copy"
                                      kind="link"
                                      color="special"
                                      size={
                                        this.props.store.viewMobile
                                          ? "lg"
                                          : "md"
                                      }
                                      lead
                                      space
                                    />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </Text>
                          </Column>
                        </Columns>
                      </PanelContent>
                      <br />
                    </Column>

                    <Column isSize={{ mobile: 12, desktop: 9, tablet: 12 }}>
                      <PanelContent isMobile={!this.props.store.viewMobile}>
                        <Text
                          size={this.props.store.viewMobile ? "md" : "sm"}
                          lead
                          weight="strong"
                          lineBreak
                        >
                          {this._language.label704 +
                            " (" +
                            version +
                            ")"}
                        </Text>
                      </PanelContent>
                      <Columns
                        className="mt-1"
                        style={{
                          gap: !this.props.store.viewMobile && "0px",
                        }}
                      >
                        <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                          <Field className="mb-1">
                            <PanelContent
                              isMobile={!this.props.store.viewMobile}
                              style={
                                !this.props.store.viewMobile && mobileStyle
                              }
                            >
                              <Text
                                color="special"
                                size={this.props.store.viewMobile ? "xl" : "lg"}
                                className={
                                  this.props.store.viewMobile && "mb-1"
                                }
                              >
                                {"Windows"}
                              </Text>
                              <Button
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                className="px-3"
                                onClick={() => {
                                  this.openLink(url_install_win, "_self");
                                }}
                              >
                                {this._language.label157}
                              </Button>
                            </PanelContent>
                          </Field>
                          {url_install_macos && (
                            <Field className="mb-1">
                              <PanelContent
                                isMobile={!this.props.store.viewMobile}
                                style={
                                  !this.props.store.viewMobile && mobileStyle
                                }
                              >
                                <Text
                                  color="special"
                                  size={
                                    this.props.store.viewMobile ? "xl" : "lg"
                                  }
                                  className={
                                    this.props.store.viewMobile && "mb-1"
                                  }
                                >
                                  {"MacOs"}
                                </Text>
                                <Button
                                  size={
                                    this.props.store.viewMobile ? "lg" : "md"
                                  }
                                  className="px-3"
                                  onClick={() => {
                                    this.openLink(url_install_macos, "_self");
                                  }}
                                >
                                  {"Beta"}
                                </Button>
                              </PanelContent>
                            </Field>
                          )}
                        </Column>
                        <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                          <Field className="mb-1">
                            <PanelContent
                              isMobile={!this.props.store.viewMobile}
                              style={
                                !this.props.store.viewMobile && mobileStyle
                              }
                            >
                              <Text
                                color="special"
                                size={this.props.store.viewMobile ? "xl" : "lg"}
                                className={
                                  this.props.store.viewMobile && "mb-1"
                                }
                              >
                                {"Android"}
                              </Text>
                              <Button
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                className="px-3"
                                onClick={() => {
                                  this.openLink(url_google_play_store, "");
                                }}
                              >
                                {"Play Store"}
                              </Button>
                            </PanelContent>
                          </Field>
                          {url_aptoide && (
                            <Field className="mb-1">
                              <PanelContent
                                isMobile={!this.props.store.viewMobile}
                                style={
                                  !this.props.store.viewMobile && mobileStyle
                                }
                              >
                                <Text
                                  color="special"
                                  size={
                                    this.props.store.viewMobile ? "xl" : "lg"
                                  }
                                  className={
                                    this.props.store.viewMobile && "mb-1"
                                  }
                                >
                                  {"Android"}
                                </Text>
                                <Button
                                  size={
                                    this.props.store.viewMobile ? "lg" : "md"
                                  }
                                  className="px-3"
                                  onClick={() => {
                                    this.openLink(url_aptoide, "");
                                  }}
                                >
                                  {"Aptoide"}
                                </Button>
                              </PanelContent>
                            </Field>
                          )}
                        </Column>
                        <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                          {url_apk_x32 && (<Field className="mb-1">
                            <PanelContent
                              isMobile={!this.props.store.viewMobile}
                              style={
                                !this.props.store.viewMobile && mobileStyle
                              }
                            >
                              <Text
                                color="special"
                                size={this.props.store.viewMobile ? "xl" : "lg"}
                                className={
                                  this.props.store.viewMobile && "mb-1"
                                }
                              >
                                {"Android"}
                              </Text>
                              <Button
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                className="px-3"
                                onClick={() => {
                                  this.openLink(url_apk_x32, "_self");
                                }}
                              >
                                {"32 bits"}
                              </Button>
                            </PanelContent>
                          </Field>)}
                          <Field className="mb-1">
                            <PanelContent
                              isMobile={!this.props.store.viewMobile}
                              style={
                                !this.props.store.viewMobile && mobileStyle
                              }
                            >
                              <Text
                                color="special"
                                size={this.props.store.viewMobile ? "xl" : "lg"}
                                className={
                                  this.props.store.viewMobile && "mb-1"
                                }
                              >
                                {"Android"}
                              </Text>
                              <Button
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                className="px-3"
                                onClick={() => {
                                  this.openLink(url_apk_x64, "_self");
                                }}
                              >
                                {"64 bits"}
                              </Button>
                            </PanelContent>
                          </Field>
                        </Column>
                        <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                          <Field NoteSize="xs">
                            <PanelContent
                              isMobile={!this.props.store.viewMobile}
                              style={
                                !this.props.store.viewMobile && mobileStyle
                              }
                            >
                              <Text
                                color="special"
                                size={this.props.store.viewMobile ? "xl" : "lg"}
                                className={
                                  this.props.store.viewMobile && "mb-1"
                                }
                              >
                                {"Web Player"}
                              </Text>
                              <div className="flex items-center">
                                <Button
                                  size={
                                    this.props.store.viewMobile ? "lg" : "md"
                                  }
                                  className="px-3"
                                  onClick={() => {
                                    this.openLink(url_web_player, "");
                                  }}
                                >
                                  {this._language.label705}
                                </Button>
                                <div data-tip data-for="copy-webplayer">
                                  <CopyToClipboard
                                    text={url_web_player}
                                    onCopy={() =>
                                      toastManager.add(
                                        this._language.label724,
                                        {
                                          appearance: "success",
                                          autoDismiss: true,
                                        }
                                      )
                                    }
                                  >
                                    <Button
                                      className="ml-2"
                                      icon="copy"
                                      kind="link"
                                      color="special"
                                      size="lg"
                                      lead
                                      space
                                    />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </PanelContent>
                          </Field>
                        </Column>
                      </Columns>
                    </Column>
                  </Columns>
                </Column>
              </Columns>
              <PanelContent isMobile={!this.props.store.viewMobile}>
                <Columns>
                  <Column isSize={{ mobile: 12, desktop: 11, tablet: 12 }}>
                    <Text
                      size={this.props.store.viewMobile ? "md" : "md"}
                      lead
                      weight="strong"
                      lineBreak
                    >
                      {this._language.label706}
                    </Text>
                    <Text
                      className="mt-2"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="strong"
                      lineBreak
                    >
                      {"Windows"}
                    </Text>
                    <Text
                      className="mt-1"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      color="special"
                      multiline
                    >
                      {this._language.label698}
                      <Text
                        className="ml-2 mt-2"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="strong"
                        lineBreak
                      >
                        {this._language.label707}
                      </Text>
                      <ul className="ml-4 mt-1">
                        <li>{"- " + this._language.label708}</li>
                        <li>{"- " + this._language.label709}</li>
                        <li>{"- " + this._language.label710}</li>
                        <li>{"- " + this._language.label711}</li>
                        <li>{"- " + this._language.label712}</li>
                      </ul>
                    </Text>
                    {url_install_macos && (
                      <Text
                        className="mt-2"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="strong"
                        lineBreak
                      >
                        {"MacOS"}
                      </Text>
                    )}
                    {url_install_macos && (
                      <Text
                        color="special"
                        className="mt-1"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                        multiline
                      >
                        {this._language.label699}

                        <Text
                          className="ml-2 mt-2"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="strong"
                          lineBreak
                        >
                          {this._language.label707}
                        </Text>
                        <ul className="ml-4 mt-1">
                          <li>{"- " + this._language.label730}</li>
                          <li>{"- " + this._language.label709}</li>
                          <li>{"- " + this._language.label711}</li>
                          <li>{"- " + this._language.label712}</li>
                        </ul>
                      </Text>
                    )}
                    <Text
                      className="mt-2"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="strong"
                      lineBreak
                    >
                      {"Android"}
                    </Text>
                    <Text
                      className="mt-1"
                      color="special"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      multiline
                    >
                      {this._language.label700}
                      <Text
                        className="ml-2 mt-2"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="strong"
                        lineBreak
                      >
                        {this._language.label707}
                      </Text>
                      <ul className="ml-4 mt-1">
                        <li>{"- " + this._language.label713}</li>
                        <li>{"- " + this._language.label709}</li>
                        <li>{"- " + this._language.label714}</li>
                        <li>{"- " + this._language.label712}</li>
                      </ul>
                    </Text>
                    <br />
                  </Column>
                </Columns>
              </PanelContent>
              {this.props.store.viewMobile && (
                <ReactTooltip
                  id="copy"
                  place="bottom"
                  type="dark"
                  effect="float"
                >
                  <span>{this._language.label715}</span>
                </ReactTooltip>
              )}
              {this.props.store.viewMobile && (
                <ReactTooltip
                  id="copy-webplayer"
                  place="bottom"
                  type="dark"
                  effect="float"
                >
                  <span>{this._language.label716}</span>
                </ReactTooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStore(withToastManager(DownloadsPage));
