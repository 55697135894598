import React, { Component, useRef, useLayoutEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { FixedSizeList } from "react-window";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  DateTimePicker,
  Text,
  Radio,
  RadioGroup,
  Checkbox,
  Panel,
  Paragraph,
} from "ks_storybook";
import { WarningModal } from "../Common";
import { PPMediaTypeNewModal } from "../MediaType";
import { PPCompaniesNewModal } from "../Companies";
import { PPTrademarksNewModal } from "../Trademarks";
import { Select } from "../Select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withToastManager } from "react-toast-notifications";
import { PPGenericManagerModal } from ".";
import moment from "moment";

function getStyle({ provided, style, isDragging }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };
  const grid = 8;
  const marginBottom = 5;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    background: isDragging ? "#12C889" : "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${grid * 3}px`,
    borderRadius: grid,
    marginBottom,
  };
  return withSpacing;
}

const Item = ({ provided, item, style, isDragging, index }) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      <Text size="xl">{item.content}</Text>
    </div>
  );
};

const Row = (props) => {
  const { data: items, index, style } = props;
  const item = items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
      {(provided) => <Item provided={provided} item={item} style={style} />}
    </Draggable>
  );
};

const ItemList = ({ column, index }) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        return (
          <FixedSizeList
            height={250}
            itemCount={itemCount}
            itemSize={55}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={column.items}
            className="task-list"
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
};

class ColumnDraggable extends Component {
  getColumnStyle = (index) => ({
    border: index == 1 && "2px solid #e8e8e8",
    borderRadius: "8px",
    ".item": {
      background: "red",
    },
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  render() {
    const { column, index, label } = this.props;
    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <div
            className={`column ${index == 0 && "columnOne"}`}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={this.getColumnStyle(index)}
          >
            <div {...provided.dragHandleProps} />
            {index == 1 && (
              <div
                style={{
                  position: "absolute",
                  border: "2px dashed #ccc",
                  padding: "10px 30px",
                  borderRadius: "16px",
                }}
              >
                <Text size="xl" weight="strong" lineBreak color="gray13">
                  <span style={{ color: "#ccc" }}>{label}</span>
                </Text>
              </div>
            )}
            <ItemList column={column} index={index} />
          </div>
        )}
      </Draggable>
    );
  }
}

@observer
class PPAdsUploadEditModal extends Component {
  @observable ppAdNew;

  constructor(props) {
    super(props);

    this.state = {
      trampa: false,
      radioValue: 1,
      isSaving: false,
      messageError: null,
      filter: this.getOptionsFilter(),
      indexFilter: 1,
      filterUser: this.getOptionsFilterUser(),
      indexFilterUser: 1,
      items: this.getItems(this.getItemsData()),
      isCheckboxStart: this.props.currentPPAd.start_date ? true : false,
      isCheckboxFinish: this.props.currentPPAd.end_date ? true : false,
      showModalNewMediaType: false,
      showModalNewCompanies: false,
      showModalNewTrademarks: false,
      showModalPPGenericManager: false,
      showWarningModal: false,
      currentObj: null,
      currentGenType: 0,
      search: null,
      state: {},
      media_type_id: this.props.currentPPAd.media_type.id,
      trademarks_id: this.props.currentPPAd.trademarks.id,
      companies_id: this.props.currentPPAd.companies.id,
      name: this.props.currentPPAd.name,
      posts: this.props.currentPPAd.posts,
      priority: this.props.currentPPAd.priority,
      schedule_from: this.props.currentPPAd.schedule_from,
      schedule_to: this.props.currentPPAd.schedule_to,
      start_date: this.props.currentPPAd.start_date
        ? moment(this.props.currentPPAd.start_date, "DD/MM/YYYY").toDate()
        : moment(new Date(), "DD/MM/YYYY").toDate(),
      end_date: this.props.currentPPAd.end_date
        ? moment(this.props.currentPPAd.end_date, "DD/MM/YYYY").toDate()
        : moment(new Date(2099, 11, 31), "DD/MM/YYYY").toDate(),
      start_time: this.props.currentPPAd.start_time,
      end_time: this.props.currentPPAd.end_time,
    };

    this.ppAdNew = this.props.currentPPAd.clone();
    this.grid = 4;
    this.id2List = {
      droppable: "items",
      droppable2: "selected",
    };
  }

  componentDidMount() {
    const { items, search, indexFilter, indexFilterUser } = this.state;
    const arrayItems = this.filterItems(
      items,
      search,
      indexFilter,
      indexFilterUser
    );
    const state = {
      columns: {
        "column-0": {
          id: "column-0",
          title: "First column",
          items: arrayItems,
        },
        "column-1": {
          id: "column-1",
          title: "Second column",
          items: this.getItemsLocations(this.getItemsData()),
        },
      },
      columnOrder: ["column-0", "column-1"],
    };
    this.setState({
      state: state,
      radioValue: this.props.currentPPAd.locations_type,
    });
  }

  handlePPGenericModalOpenType1 = () => {
    this.setState({
      showModalPPGenericManager: true,
      currentObj: this.props.mediaType,
      currentGenType: 1,
    });
  };

  handlePPGenericModalOpenType2 = () => {
    this.setState({
      showModalPPGenericManager: true,
      currentObj: this.props.companies,
      currentGenType: 2,
    });
  };

  handlePPGenericModalOpenType3 = () => {
    this.setState({
      showModalPPGenericManager: true,
      currentObj: this.props.trademarks,
      currentGenType: 3,
    });
  };

  handlePPGenericModalClose = () => {
    this.setState({
      showModalPPGenericManager: false,
      currentObj: null,
      currentGenType: 0,
    });
  };

  handlePPGenericModalReload = () => {
    this.props.onMediaTypeReload && this.props.onMediaTypeReload();
    this.props.onCompaniesReload && this.props.onCompaniesReload();
    this.props.onTrademarksReload && this.props.onTrademarksReload();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const {
      isCheckboxStart,
      isCheckboxFinish,
      radioValue,
      media_type_id,
      trademarks_id,
      companies_id,
      name,
      state,
      posts,
      priority,
      schedule_from,
      schedule_to,
      start_date,
      end_date,
      start_time,
      end_time,
    } = this.state;

    this.ppAdNew.media_type = media_type_id;
    this.ppAdNew.trademarks = trademarks_id;
    this.ppAdNew.companies = companies_id;
    this.ppAdNew.name = name;
    this.ppAdNew.posts = posts;
    this.ppAdNew.priority = priority;
    this.ppAdNew.schedule_from = schedule_from;
    this.ppAdNew.schedule_to = schedule_to;
    this.ppAdNew.locations_type = parseInt(radioValue);

    if (isCheckboxStart || isCheckboxFinish) {
      this.ppAdNew.start_date = moment(start_date).format("DD/MM/YYYY");
      this.ppAdNew.end_date = moment(end_date).format("DD/MM/YYYY");
      this.ppAdNew.start_time = start_time ? start_time : "00:00";
      this.ppAdNew.end_time = end_time ? end_time : "23:29";
    } else {
      this.ppAdNew.start_date = null;
      this.ppAdNew.end_date = null;
      this.ppAdNew.start_time = null;
      this.ppAdNew.end_time = null;
    }

    if (this.ppAdNew.name) {
      if (this.ppAdNew.media_type) {
        if (this.ppAdNew.companies) {
          if (this.ppAdNew.trademarks) {
            if (
              (state.columns["column-1"].items.length !== 0 &&
                this.ppAdNew.locations_type == 2) ||
              this.ppAdNew.locations_type == 1
            ) {
              //this.ppAdNew.locations = JSON.stringify(state.columns["column-1"].items);
              this.ppAdNew.locations = state.columns["column-1"].items.map(
                (i) => i
              );
              if (this.ppAdNew.priority) {
                this.setState({ isSaving: true }, () => {
                  this.ppAdNew.save().andThen((res, responseError) => {
                    if (responseError) {
                      toastManager.add(this.props.store.language.label407, {
                        appearance: "error",
                        autoDismiss: true,
                      });
                    } else {
                      toastManager.add(this.props.store.language.label408, {
                        appearance: "success",
                        autoDismiss: true,
                      });
                      this.props.onSave && this.props.onSave();
                    }
                  });
                });
              } else
                this.setState({
                  messageError: this.props.store.language.label399,
                });
            } else
              this.setState({
                messageError: this.props.store.language.label400,
              });
          } else
            this.setState({ messageError: this.props.store.language.label401 });
        } else
          this.setState({ messageError: this.props.store.language.label402 });
      } else
        this.setState({ messageError: this.props.store.language.label403 });
    } else this.setState({ messageError: this.props.store.language.label404 });
  };

  getNameMedia = (mt_code) => {
    return this.props.store.language.media_type[mt_code];
  };

  getOptionsMediaType = (obj) => {
    obj = obj.toArray().filter((f) => f.mt_code != 1);
    return obj.map((o) => ({
      value: o.id,
      label:
        o.mt_type == 1
          ? this.getNameMedia(o.mt_code).toUpperCase()
          : o.name.toUpperCase(),
    }));
  };

  getOptions(obj) {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  }

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleWarningModalOpen = () => {
    this.setState({ showWarningModal: true });
  };

  handleWarningModalClose = () => {
    this.setState({ showWarningModal: false });
  };

  handleModalMedioTypeOpen = () => {
    if (
      this.props.mediaType.length >=
      this.props.store.loggedInUser.ks_users_settings.maximum_media_type
    ) {
      this.handleWarningModalOpen();
    } else {
      this.setState({ showModalNewMediaType: true });
    }
  };

  handleModalMedioTypeClose = () => {
    this.setState({ showModalNewMediaType: false });
  };

  handleModalMedioTypeSave = () => {
    this.setState({ showModalNewMediaType: false });
    this.props.onMediaTypeReload && this.props.onMediaTypeReload();
  };

  handleModalCompaniesOpen = () => {
    this.setState({ showModalNewCompanies: true });
  };

  handleModalCompaniesClose = () => {
    this.setState({ showModalNewCompanies: false });
  };

  handleModalCompaniesSave = () => {
    this.setState({ showModalNewCompanies: false });
    this.props.onCompaniesReload && this.props.onCompaniesReload();
  };

  handleModalTrademarksOpen = () => {
    this.setState({ showModalNewTrademarks: true });
  };

  handleModalTrademarksClose = () => {
    this.setState({ showModalNewTrademarks: false });
  };

  handleModalTrademarksSave = () => {
    this.setState({ showModalNewTrademarks: false });
    this.props.onTrademarksReload && this.props.onTrademarksReload();
  };

  validExt = (ext, type) => {
    if (type !== "audio/mpeg") {
      this.setState({ messageError: this.props.store.language.label341 });
      return false;
    } else {
      this.setState({ messageError: null });
    }
    if (ext !== "mp3") {
      this.setState({ messageError: this.props.store.language.label341 });
      return false;
    } else {
      this.setState({ messageError: null });
      return true;
    }
  };

  getOptionsFilterUser = () => {
    const options = [
      { value: 1, label: this.props.store.language.label53 },
      { value: 2, label: this.props.store.language.label575 },
      { value: 3, label: this.props.store.language.label576 },
      { value: 4, label: this.props.store.language.label577 },
    ];
    return options;
  };

  getOptionsFilter = () => {
    const options = [
      { value: 1, label: this.props.store.language.label395 },
      { value: 2, label: this.props.store.language.label396 },
    ];
    return options;
  };

  handleChangeSearch = (sender, value, name) => {
    const newSate = this.state.state;
    const arrayItems = this.filterItems(
      this.state.items,
      value,
      this.state.indexFilter,
      this.state.indexFilterUser
    );
    newSate.columns["column-0"].items = arrayItems;
    this.setState({ state: newSate, search: value });
  };

  valid = (num) => {
    switch (num) {
      case 0:
        return "00";
      case 1:
        return "01";
      case 2:
        return "02";
      case 3:
        return "03";
      case 4:
        return "04";
      case 5:
        return "05";
      case 6:
        return "06";
      case 7:
        return "07";
      case 8:
        return "08";
      case 9:
        return "09";
      default:
        return num;
    }
  };

  getHours = () => {
    let items = [];
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j <= 59; j++) {
        items.push({
          value: this.valid(i) + ":" + this.valid(j),
          label: this.valid(i) + ":" + this.valid(j),
        });
      }
    }
    return items;
  };

  getHoursInterval = (e) => {
    let items = [];
    for (let i = 0; i <= 23; i++) {
      items.push({
        value: this.valid(i) + ":" + e,
        label: this.valid(i) + ":" + e,
      });
    }
    return items;
  };

  handleCheckboxChange = (sender, value) => {
    this.setState({ isCheckboxStart: value });
  };

  handleCheckboxFinishChange = (sender, value) => {
    if (value) {
      this.setState({
        end_date: moment(
          moment(this.state.start_date).format("DD/MM/YYYY"),
          "DD/MM/YYYY"
        )
          .add(1, "days")
          .toDate(),
      });
    } else {
      this.setState({
        end_date: moment(new Date(2099, 11, 31), "DD/MM/YYYY").toDate(),
      });
    }
    this.setState({ isCheckboxFinish: value });
  };

  handleChangeSelect = (value, sender) => {
    const val = !value ? 1 : value.value;
    switch (sender.name) {
      case "selectFilter":
        const newSate = this.state.state;
        const arrayItems = this.filterItems(
          this.state.items,
          this.state.search,
          val,
          this.state.indexFilterUser
        );
        newSate.columns["column-0"].items = arrayItems;
        this.setState({ state: newSate, indexFilter: val });
        break;
      case "selectFilterUser":
        this.setState({ indexFilterUser: val });
        break;
      case "selectMediaType":
        this.setState({ messageError: null, media_type_id: val });
        break;
      case "selectCompanies":
        this.setState({ companies_id: val });
        break;
      case "selectTrademarks":
        this.setState({ messageError: null, trademarks_id: val });
        break;
      case "start_time":
        this.setState({ start_time: val });
        break;
      case "end_time":
        this.setState({ end_time: val });
        break;
      case "priority":
        this.setState({ messageError: null, priority: val });
        break;
      case "schedule_from":
        this.setState({ schedule_from: val });
        break;
      case "schedule_to":
        this.setState({ schedule_to: val });
        break;
      default:
        break;
    }
  };

  filterItems = (array, search, indexFilter, indexFilterUser) => {
    switch (indexFilter) {
      case 2: // por name groups
        if (search != null) {
          array = array.filter(
            (el) =>
              el.content.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
              el.type == 2
          );
        } else {
          array = array.filter((el) => el.type == 2);
        }
        break;
      default:
        // por name users
        if (search != null) {
          switch (indexFilterUser) {
            case 1: // por Username
              array = array.filter(
                (el) =>
                  el.content.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
                  el.type == 1
              );
              break;
            case 2: // por country
              array = array.filter(
                (el) =>
                  el.country &&
                  el.country.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
                  el.type == 1
              );
              break;
            case 3: // por city
              array = array.filter(
                (el) =>
                  el.city &&
                  el.city.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
                  el.type == 1
              );
              break;
            case 4: // por province
              array = array.filter(
                (el) =>
                  el.province &&
                  el.province.toLowerCase().indexOf(search.toLowerCase()) >
                    -1 &&
                  el.type == 1
              );
              break;
            default:
              array = array.filter(
                (el) =>
                  el.content.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
                  el.type == 1
              );
              break;
          }
        } else {
          array = array.filter((el) => el.type == 1);
        }
        break;
    }
    return array;
  };

  handleChangeRadio = (sender, value) => {
    this.setState({ messageError: null, radioValue: value });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleChangeFiled = (sender, value, name, validation) => {
    switch (name) {
      case "name":
        if (value != null) {
          if (this.validLength(value)) {
            this.setState({ messageError: null, name: value });
          } else {
            this.setState({ messageError: this.props.store.language.label342 });
          }
        } else {
          this.setState({ messageError: this.props.store.language.label341 });
        }
        break;
      case "start_date":
        this.setState({ start_date: value });
        break;
      case "end_date":
        this.setState({ end_date: value });
        break;
      case "posts":
        if (value) this.setState({ posts: value });
        break;
      default:
        break;
    }
  };

  getItemsData = () => {
    var arr = [];
    this.props.accounts.map((a, k) =>
      arr.push({
        key: `U-${k}`,
        id: a.id,
        content: a.name,
        country: a.country,
        city: a.city,
        province: a.province,
        type: 1,
      })
    );
    this.props.groups.map((g, k) =>
      arr.push({ key: `G-${k}`, id: g.id, content: g.name, type: 2 })
    );
    return arr;
  };

  getItems = (arr) => {
    var a = arr.filter(
      function (e) {
        return this.indexOf(e.id) < 0 && e.type == 1;
      },
      this.props.currentPPAd.locations
        .filter((e) => !!e.accounts_id)
        .map((e) => e.accounts_id)
    );
    var g = arr.filter(
      function (e) {
        return this.indexOf(e.id) < 0 && e.type == 2;
      },
      this.props.currentPPAd.locations
        .filter((e) => !!e.group_id)
        .map((e) => e.group_id)
    );
    Array.prototype.push.apply(a, g);
    return a;
  };

  getItemsLocations = (arr) => {
    var a = arr.filter(
      function (e) {
        return this.indexOf(e.id) > -1 && e.type == 1;
      },
      this.props.currentPPAd.locations
        .filter((e) => !!e.accounts_id)
        .map((e) => e.accounts_id)
    );
    var g = arr.filter(
      function (e) {
        return this.indexOf(e.id) > -1 && e.type == 2;
      },
      this.props.currentPPAd.locations
        .filter((e) => !!e.group_id)
        .map((e) => e.group_id)
    );
    Array.prototype.push.apply(a, g);
    return a;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // estilos que necesitamos aplicar en draggables
    ...draggableStyle,
    // algunos estilos básicos para que los artículos se vean un poco más bonitos
    userSelect: "none",
    position: "static",
    padding: 8 * 2,
    margin: "0 0 8px 0",
    alignItems: "flex-start",
    alignContent: "flex-start",
    borderRadius: "3px",
    border:
      "1px ${(props) => (props.isDragging ? 'dashed #000' : 'solid #ddd')}",
    // cambiar el color de fondo si arrastra
    background: isDragging ? "#12C889" : "#ffffff", //'#141414'
  });

  getListOriginStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#1b1b1b" : "transparent", //'#1b1b1b',//->
    padding: this.grid,
  });

  getListDestinationStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#1b1b1b" : "#1b1b1b", //-> Derecha
    padding: this.grid,
    border: "1px dashed #F7F7F7",
  });

  getContainerStyle = () => ({
    width: "100%",
    height: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  // una pequeña función para ayudarnos a reordenar el resultado
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * Una forma semi-genérica de manejar múltiples listas. Partidos
   * los ID del contenedor desplegable a los nombres del
   * matrices de origen almacenadas en el estado.
   */

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  getList = (key) => this.state[this.id2List[key]];

  reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = (result) => {
    const { state } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = this.reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      this.setState({
        state: {
          ...state,
          columnOrder,
        },
      });
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = this.reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      this.setState({ state: newState });
      return;
    }

    // moving between lists
    const sourceColumn = state.columns[result.source.droppableId];
    const destinationColumn = state.columns[result.destination.droppableId];
    const item = sourceColumn.items[result.source.index];

    // 1. remove item from source column
    const newSourceColumn = {
      ...sourceColumn,
      items: [...sourceColumn.items],
    };
    newSourceColumn.items.splice(result.source.index, 1);

    // 2. insert into destination column
    const newDestinationColumn = {
      ...destinationColumn,
      items: [...destinationColumn.items],
    };
    // in line modification of items
    newDestinationColumn.items.splice(result.destination.index, 0, item);

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newSourceColumn.id]: newSourceColumn,
        [newDestinationColumn.id]: newDestinationColumn,
      },
    };

    const lists = this.state.items;
    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(item.key);

    if (index == -1) {
      lists.push(item);
    } else {
      lists.splice(index, 1);
    }
    this.setState({ state: newState, items: lists, messageError: null });
  };

  @computed
  get canSave() {
    if (!this.state.name) return false;
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label81}
            </Button>
          ) : (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              {this.props.store.language.label80}
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderSearch = () => {
    return (
      <>
        <Columns isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 7 }}>
            <TextInput
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              onChange={this.handleChangeSearch}
              backgroundColor="basic"
              colorIcon="gray"
              placeholder={this.props.store.language.label50}
              invert
            />
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 5 }}>
            <div className="is-row" style={{ alignItems: "center" }}>
              <div>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="mr-1"
                >
                  {this.props.store.language.label541 + ":"}
                </Text>
              </div>
              <Select
                name="selectFilter"
                onChange={this.handleChangeSelect}
                required
                autocomplete="nope"
                options={this.state.filter}
                defaultValue={this.state.filter[0]}
                className="is-fullwidth"
                backgroundColor="#FFFFFF"
                separatorColor="#FFFFFF"
                iconColor="black"
                textColor="#000000"
                size={this.props.store.viewMobile ? "lg" : "md"}
                isMobile={!this.props.store.viewMobile}
              />
            </div>
          </Column>
          {this.state.indexFilter == 1 && (
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 5 }}>
              <div className="is-row" style={{ alignItems: "center" }}>
                <div>
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="mr-1"
                  >
                    {this.props.store.language.label405 + ":"}
                  </Text>
                </div>
                <Select
                  name="selectFilterUser"
                  onChange={this.handleChangeSelect}
                  required
                  autocomplete="nope"
                  options={this.state.filterUser}
                  defaultValue={this.state.filterUser[0]}
                  className="is-fullwidth"
                  backgroundColor="#FFFFFF"
                  separatorColor="#FFFFFF"
                  iconColor="black"
                  textColor="#000000"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  isMobile={!this.props.store.viewMobile}
                />
              </div>
            </Column>
          )}
        </Columns>
      </>
    );
  };

  renderRadio = () => {
    return (
      <React.Fragment>
        <Columns isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
            <div style={{ margin: "0 10px" }}>
              <Text
                size={this.props.store.viewMobile ? "sm" : "xl"}
                upper
                lead={this.props.store.viewMobile}
                lineBreak
                weight="strong"
              >
                {this.props.store.language.label392}
              </Text>
            </div>
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 8 }}>
            <RadioGroup
              group="sizes"
              size={this.props.store.viewMobile ? "md" : "sm"}
              onChange={this.handleChangeRadio}
              value={this.props.currentPPAd.locations_type.toString()}
              classNames="group"
            >
              <Radio
                size={this.props.store.viewMobile ? "md" : "sm"}
                value="1"
                class="radio"
                invert
                reversed
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                  {this.props.store.language.label393}
                </Text>
              </Radio>
              <div className="my-1" />
              <Radio
                size={this.props.store.viewMobile ? "md" : "sm"}
                value="2"
                class="radio"
                invert
                reversed
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                  {this.props.store.language.label394}
                </Text>
              </Radio>
            </RadioGroup>
          </Column>
        </Columns>
        {this.state.radioValue == 2 ? this.renderSearch() : null}
        {this.state.radioValue == 2 ? this.renderDragDrop() : null}
      </React.Fragment>
    );
  };

  renderDragDrop = () => {
    const { state } = this.state;
    return (
      <React.Fragment>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="app">
            <Droppable
              droppableId="all-droppables"
              direction="horizontal"
              type="column"
            >
              {(provided) => (
                <div
                  className="columns"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {state.columnOrder.map((columnId, index) => (
                    <>
                      <ColumnDraggable
                        key={columnId}
                        column={state.columns[columnId]}
                        index={index}
                        label={this.props.store.language.label406}
                      />
                    </>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </React.Fragment>
    );
  };

  renderObjSelect = (name, options, label, func1, func2, id) => {
    return (
      <div style={{ position: "relative" }}>
        <Field
          marginH={this.props.store.viewMobile && "10px"}
          label={label}
          size={this.props.store.viewMobile ? "xl" : "lg"}
          NoteSize="xs"
          upper
          labelNote={this.props.store.language.label76}
        >
          <Select
            name={name}
            onChange={this.handleChangeSelect}
            isClearable
            defaultValue={
              options[
                options
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(id)
              ]
            }
            options={options}
            placeholder={this.props.store.language.label385}
            backgroundColor="#FFFFFF"
            separatorColor="#f7f7f7"
            iconColor="black"
            textColor="#000000"
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </Field>
        <div style={{ position: "absolute", right: "0px", top: "0px" }}>
          <Button
            size={this.props.store.viewMobile ? "md" : "sm"}
            icon="pen"
            onClick={func2}
            kind="link"
            color="black"
            space
          />
          <Button
            size={this.props.store.viewMobile ? "md" : "sm"}
            icon="plus"
            onClick={func1}
            kind="link"
            color="black"
            space
            marginSeparator="10px"
          />
        </div>
      </div>
    );
  };

  getOptionsPriority = () => {
    const options = [
      { value: 1, label: this.props.store.language.label381 },
      { value: 2, label: this.props.store.language.label382 },
    ];
    return options;
  };

  renderField = () => {
    const {
      isCheckboxStart,
      isCheckboxFinish,
      radioValue,
      media_type_id,
      trademarks_id,
      companies_id,
      name,
      posts,
      priority,
      schedule_from,
      schedule_to,
      start_date,
      end_date,
      start_time,
      end_time,
    } = this.state;
    const { mediaType, companies, trademarks } = this.props;
    let options = this.getHours();
    let optionsPriority = this.getOptionsPriority();
    return (
      <React.Fragment>
        <Columns isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
            <Field
              marginH={this.props.store.viewMobile && "10px"}
              label={this.props.store.language.label53}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              NoteSize="xs"
              upper
              labelNote={this.props.store.language.label76}
            >
              <TextInput
                backgroundColor="#FFFFFF"
                className="is-fullwidth"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                name="name"
                value={name}
                onChange={this.handleChangeFiled}
                required
                autocomplete="nope"
                color="black"
                invert
              />
            </Field>
            {this.renderObjSelect(
              "selectMediaType",
              this.getOptionsMediaType(mediaType),
              this.props.store.language.label192,
              this.handleModalMedioTypeOpen,
              this.handlePPGenericModalOpenType1,
              media_type_id
            )}
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 8 }}>
            <Field
              label={this.props.store.language.label384}
              labelNoteOption={this.props.store.language.label78}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              upper
            >
              <Columns className="my-1" isMultiline>
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 2 }}>
                  <Checkbox
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    name="checkbox_start"
                    onChange={this.handleCheckboxChange}
                    className="my-1"
                    isChecked={isCheckboxStart}
                    reversed
                    invert
                  >
                    <Text
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      weight="strong"
                      upper
                    >
                      {this.props.store.language.label386}:
                    </Text>
                  </Checkbox>
                </Column>
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                  <div className="is-row" style={{ alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "10px",
                        minWidth: "30px",
                        textAlign: "end",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {"Día"}
                      </Text>
                    </div>
                    <DateTimePicker
                      name="start_date"
                      value={start_date}
                      dateFormat="DD/MM/YYYY"
                      onChange={this.handleChangeFiled}
                      disabled={!isCheckboxStart}
                      iconKasimu="arrow-down"
                      reversed
                      invert
                      backgroundColor="#ffffff"
                      colorIcon="black"
                      color="black"
                      fullWidth
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      className="is-fullwidth"
                    />
                  </div>
                  <div className="my-2" />
                  <div className="is-row" style={{ alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "10px",
                        minWidth: "30px",
                        textAlign: "end",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {"Hora"}
                      </Text>
                    </div>
                    <Select
                      isClearable
                      onChange={this.handleChangeSelect}
                      name="start_time"
                      required
                      autocomplete="nope"
                      options={options}
                      defaultValue={
                        start_time
                          ? options[
                              options
                                .map(function (e) {
                                  return e.value;
                                })
                                .indexOf(start_time)
                            ]
                          : options[0]
                      }
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={!isCheckboxStart}
                      className="is-fullwidth"
                      backgroundColor="#FFFFFF"
                      separatorColor="#FFFFFF"
                      iconColor="black"
                      textColor="#000000"
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      isMobile={!this.props.store.viewMobile}
                    />
                  </div>
                </Column>
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 2 }}>
                  <Checkbox
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    name="checkbox_end"
                    onChange={this.handleCheckboxFinishChange}
                    className="my-1"
                    isChecked={isCheckboxFinish}
                    reversed
                    invert
                  >
                    <Text
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      weight="strong"
                      upper
                    >
                      {this.props.store.language.label387}:
                    </Text>
                  </Checkbox>
                </Column>
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                  <div className="is-row" style={{ alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "10px",
                        minWidth: "30px",
                        textAlign: "end",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {"Día"}
                      </Text>
                    </div>
                    <DateTimePicker
                      name="end_date"
                      value={end_date}
                      dateFormat="DD/MM/YYYY"
                      onChange={this.handleChangeFiled}
                      disabled={!isCheckboxFinish}
                      iconKasimu="arrow-down"
                      reversed
                      invert
                      backgroundColor="#ffffff"
                      colorIcon="black"
                      color="black"
                      fullWidth
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      className="is-fullwidth"
                    />
                  </div>
                  <div className="my-2" />
                  <div className="is-row" style={{ alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "10px",
                        minWidth: "30px",
                        textAlign: "end",
                      }}
                    >
                      <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                        {"Hora"}
                      </Text>
                    </div>
                    <Select
                      isClearable
                      onChange={this.handleChangeSelect}
                      name="end_time"
                      required
                      autocomplete="nope"
                      options={options}
                      defaultValue={
                        end_time
                          ? options[
                              options
                                .map(function (e) {
                                  return e.value;
                                })
                                .indexOf(end_time)
                            ]
                          : options[1439]
                      }
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={!isCheckboxFinish}
                      className="is-fullwidth"
                      backgroundColor="#FFFFFF"
                      separatorColor="#FFFFFF"
                      iconColor="black"
                      textColor="#000000"
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      isMobile={!this.props.store.viewMobile}
                    />
                  </div>
                </Column>
              </Columns>
            </Field>
          </Column>
        </Columns>
        <div style={{ margin: "-20px 10px 10px" }}>
          <Text
            upper
            size={this.props.store.viewMobile ? "sm" : "xl"}
            lead={this.props.store.viewMobile}
            weight="strong"
          >
            {this.props.store.language.label390}
          </Text>
        </div>
        <Columns isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
            {this.renderObjSelect(
              "selectCompanies",
              this.getOptions(companies),
              this.props.store.language.label389,
              this.handleModalCompaniesOpen,
              this.handlePPGenericModalOpenType2,
              companies_id
            )}
            {this.renderObjSelect(
              "selectTrademarks",
              this.getOptions(trademarks),
              this.props.store.language.label388,
              this.handleModalTrademarksOpen,
              this.handlePPGenericModalOpenType3,
              trademarks_id
            )}
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
            <Field
              label={this.props.store.language.label191}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              marginH={this.props.store.viewMobile && "10px"}
              upper
            >
              <TextInput
                type="number"
                className="is-fullwidth"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                name="posts"
                placeholderColor="gray"
                value={posts == -1 ? "" : posts}
                placeholder={this.props.store.language.label391}
                onChange={this.handleChangeFiled}
                minlength={-1}
                autocomplete="nope"
                backgroundColor="#FFFFFF"
                className="is-fullwidth"
                color="black"
                invert
              />
            </Field>
            <Field
              label={this.props.store.language.label190}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              marginH={this.props.store.viewMobile && "10px"}
              upper
            >
              <Select
                name={"priority"}
                onChange={this.handleChangeSelect}
                isClearable
                options={optionsPriority}
                defaultValue={
                  optionsPriority[
                    optionsPriority
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(priority)
                  ]
                }
                className="is-fullwidth"
                backgroundColor="#FFFFFF"
                separatorColor="#FFFFFF"
                iconColor="black"
                placeholder={this.props.store.language.label385}
                textColor="#000000"
                size={this.props.store.viewMobile ? "lg" : "md"}
                isMobile={!this.props.store.viewMobile}
              />
            </Field>
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
            <Field
              label={this.props.store.language.label168}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              marginH={this.props.store.viewMobile && "10px"}
              upper
            >
              <Select
                name={"schedule_from"}
                onChange={this.handleChangeSelect}
                isClearable
                options={options}
                defaultValue={
                  options[
                    options
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(schedule_from)
                  ]
                }
                className="is-fullwidth"
                backgroundColor="#FFFFFF"
                separatorColor="#FFFFFF"
                iconColor="black"
                textColor="#000000"
                size={this.props.store.viewMobile ? "lg" : "md"}
                isMobile={!this.props.store.viewMobile}
              />
            </Field>
            <Field
              label={this.props.store.language.label169}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              marginH={this.props.store.viewMobile && "10px"}
              upper
            >
              <Select
                name={"schedule_to"}
                onChange={this.handleChangeSelect}
                isClearable
                options={options}
                defaultValue={
                  options[
                    options
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(schedule_to)
                  ]
                }
                className="is-fullwidth"
                backgroundColor="#FFFFFF"
                separatorColor="#FFFFFF"
                iconColor="black"
                textColor="#000000"
                size={this.props.store.viewMobile ? "lg" : "md"}
                isMobile={!this.props.store.viewMobile}
              />
            </Field>
          </Column>
        </Columns>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    return (
      <React.Fragment>
        {this.renderField()}
        {this.renderRadio()}
      </React.Fragment>
    );
  };

  render() {
    const {
      showModalPPGenericManager,
      showModalNewMediaType,
      showModalNewCompanies,
      showModalNewTrademarks,
      showWarningModal,
      messageError,
      currentObj,
      currentGenType,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "1060px" : "90%"}
          height={this.props.store.viewMobile ? "80%" : "auto"}
          invert
        >
          <ModalHeader>
            <Text
              upper
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {this.props.store.language.label140 +
                "\n" +
                this.props.currentPPAd.name}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Text size={this.props.store.viewMobile ? "lg" : "md"} lineBreak>
              {this.props.store.language.label383}
            </Text>
            <br />
            {this.renderHeader()}
            {messageError && (
              <Panel color="error" className="mt-2" invert>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  multiline
                >
                  {messageError}
                </Text>
              </Panel>
            )}
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showModalNewMediaType && (
          <PPMediaTypeNewModal
            onClose={this.handleModalMedioTypeClose}
            onSave={this.handleModalMedioTypeSave}
          />
        )}
        {showModalNewCompanies && (
          <PPCompaniesNewModal
            onClose={this.handleModalCompaniesClose}
            onSave={this.handleModalCompaniesSave}
          />
        )}
        {showModalNewTrademarks && (
          <PPTrademarksNewModal
            onClose={this.handleModalTrademarksClose}
            onSave={this.handleModalTrademarksSave}
          />
        )}
        {showModalPPGenericManager && (
          <PPGenericManagerModal
            ppAds={this.props.ppAds}
            currentGenObj={currentObj}
            currentGenType={currentGenType}
            onClose={this.handlePPGenericModalClose}
            onReload={this.handlePPGenericModalReload}
          />
        )}
        {showWarningModal && (
          <WarningModal
            title={this.props.store.language.label494}
            subtitle={
              this.props.store.language.label501 +
              "\n\n" +
              this.props.store.language.label502 +
              this.props.store.loggedInUser.ks_users_settings.maximum_media_type
            }
            onClose={this.handleWarningModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

PPAdsUploadEditModal.propTypes = {
  ppAds: PropTypes.object,
  currentPPAd: PropTypes.object,
  mediaType: PropTypes.object,
  companies: PropTypes.object,
  trademarks: PropTypes.object,
  accounts: PropTypes.Array,
  groups: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onMediaTypeReload: PropTypes.func,
  onCompaniesReload: PropTypes.func,
  onTrademarksReload: PropTypes.func,
};

PPAdsUploadEditModal.defaultProps = {
  ppAds: null,
  currentPPAd: null,
  mediaType: null,
  companies: null,
  trademarks: null,
  accounts: [],
  groups: [],
  onSave: null,
  onClose: null,
  onMediaTypeReload: null,
  onCompaniesReload: null,
  onTrademarksReload: null,
};

export default withToastManager(withStore(PPAdsUploadEditModal));
