import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { Select } from "../Select";
import { User } from "../../models";
import { observable, computed } from "mobx";
import { withToastManager } from "react-toast-notifications";

@observer
class AccountNewModal extends Component {
  @observable user;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      password_confirm: null,
      messageError: null,
      eye: false,
      confirmEye: false,
    };

    this.user = this.newUser();
  }

  newUser = () => {
    const userStore = this.props.store.users;
    const attrs = {
      user_group_id: [],
      name: "",
      email: "",
      password: "",
      description: "",
      country: "",
      city: "",
      province: "",
      observaciones: "",
    };
    attrs.user_group_id = this.addSupervisorsGroupId(attrs.user_group_id);
    return new User(attrs, userStore);
  };

  addSupervisorsGroupId = (array) => {
    if (this.props.store.supervisors) {
      if (array == "[]") {
        array = []
      }
      let id = this.props.store.getSupervisorsGroupId();
      if (!array.includes(id)) {
        array.push(id);
      }
    }
    return array;
  }

  validLengthPwsOusername = (value) => {
    if (value.length >= 6 && value.length <= 40) {
      return true;
    } else {
      return false;
    }
  };

  validNoEquals = (pws, pwsConfi) => {
    if (pws === pwsConfi) {
      return true;
    } else {
      return false;
    }
  };

  validChar = (username, char) => {
    if (username.indexOf(char) === -1) return true;
    return false;
  };

  handleChange = (sender, value, name, validation) => {
    switch (name) {
      case "name":
        this.setState({ messageError: null });
          if (this.validChar(value, " ")) {
            if (this.validChar(value, "#")) {
              this.setState({ messageError: null });
            } else {
              this.setState({
                messageError: this.props.store.language.label218,
              });
            }
          } else {
            this.setState({ messageError: this.props.store.language.label210 });
          }
          this.user[name] = value;
        break;
      case "password":
        this.setState({ messageError: null });
        if (this.validChar(value, " ")) {
          this.setState({ messageError: null });
        } else {
          this.setState({ messageError: this.props.store.language.label766 });
        }
        this.user[name] = value;
        break;
      case "password_confirm":
        if (this.validChar(value, " ")) {
          this.setState({ messageError: null });
        } else {
          this.setState({ messageError: this.props.store.language.label766 });
        }
        this.setState({ password_confirm: value });
        break;
      case "email":
        this.user[name] = value;
        break;
      case "description":
        this.user[name] = value;
        break;
      case "country":
        this.user[name] = value;
        break;
      case "city":
        this.user[name] = value;
        break;
      case "province":
        this.user[name] = value;
        break;
      case "groups":
        this.user[name] = value;
        break;
      default:
        break;
    }
  };

  handleChangeGroups = (value) => {
    this.user.user_group_id = value ? `[${value.map((v) => v.value)}]` : "[]";
  };

  getErrorMessager(answer_code) {
    const messager = {
      1: this.props.store.language.label548,
      2: this.props.store.language.label578,
      3: this.props.store.language.label177,
      4: this.props.store.language.label787,
      5: this.props.store.language.label788
    };
    return messager[answer_code];
  }

  handleSave = () => {
    const { toastManager } = this.props;
    const { password_confirm } = this.state;
    this.user.user_group_id = this.addSupervisorsGroupId(this.user.user_group_id);
    if (this.user.name != "") {
      if (this.validChar(this.user.name, " ")) {
        if (this.validChar(this.user.name, "#")) {
          if (this.user.password != "") {
            if (password_confirm != "") {
              if (this.validLengthPwsOusername(this.user.name)) {
                if (this.validLengthPwsOusername(this.user.password)) {
                  if (this.validNoEquals(this.user.password, password_confirm)) {
                      this.setState({ messageError: null });
                      this.user.saveAsAccount().andThen((res, responseError) => {
                        if (responseError) {
                          let str = responseError.toString();
                          str = str.replace("Error: ", "");
                          let mydatas = JSON.parse(str);
                          this.setState({ messageError: this.getErrorMessager(mydatas['answer_code']) });
                        } else {
                          toastManager.add(this.props.store.language.label213, {
                            appearance: "success",
                            autoDismiss: true,
                          });
                          this.props.onSave && this.props.onSave();
                        }
                      });
                  } else {
                    this.setState({ messageError: this.props.store.language.label214 });
                  }
                } else {
                  this.setState({ messageError: this.props.store.language.label212 });
                }
              } else {
                this.setState({ messageError: this.props.store.language.label211 });
              }
            } else {
              this.setState({ messageError: this.props.store.language.label767 });
            }
          } else {
            this.setState({ messageError: this.props.store.language.label215 });
          }
        } else {
          this.setState({ messageError: this.props.store.language.label218 });
        }
      } else {
        this.setState({ messageError: this.props.store.language.label210 });
      }
    } else {
      this.setState({ messageError: this.props.store.language.label216 });
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label83}
            </Button>
          ) : (
            <Button
              disabled={!this.canSave}
              className="is-pulled-right px-3 mb-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label82}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { groups } = this.props;

    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "540px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label74}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            color="special"
            multiline
            lineBreak
          >
            {this.props.store.language.label75}
          </Text>
          <br />
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label53}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              name="name"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label765}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              name="description"
              type="text"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label8}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type={this.state.eye ? "text" : "password"}
              name="password"
              onChange={this.handleChange}
              paddingInput={"20px 50px 20px 20px !important"}
              iconDouble={this.state.eye ? "eye-slash" : "eye"}
              onClickIcon={() => this.setState({ eye: !this.state.eye })}
              double
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label77}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNote={this.props.store.language.label76}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              type={this.state.confirmEye ? "text" : "password"}
              name="password_confirm"
              onChange={this.handleChange}
              paddingInput={"20px 50px 20px 20px !important"}
              iconDouble={this.state.confirmEye ? "eye-slash" : "eye"}
              onClickIcon={() =>
                this.setState({ confirmEye: !this.state.confirmEye })
              }
              double
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label54}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              name="email"
              type="email"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label656}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              type="text"
              name="country"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label657}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              name="province"
              type="text"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label658}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <TextInput
              backgroundColor="blackDark"
              borderColor="black"
              autocomplete="nope"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={"20px"}
              name="city"
              type="text"
              onChange={this.handleChange}
            />
          </Field>
          <Field
            marginH={this.props.store.viewMobile && "4px"}
            label={this.props.store.language.label55}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            weight="medium"
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            labelNoteOption={this.props.store.language.label78}
          >
            <Select
              placeholder
              key={this.user.id}
              isMulti
              isClearable
              onChange={this.handleChangeGroups}
              name="groups"
              options={groups}
              defaultValue={
                this.user.user_group_id
                  ? groups[
                    groups
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.user.user_group_id[0])
                    ]
                  : groups[-1]
              }
              backgroundColor="black"
              separatorColor="black"
              size={this.props.store.viewMobile ? "lg" : "md"}
              isMobile={!this.props.store.viewMobile}
              className="my-1"
            />
          </Field>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

AccountNewModal.propTypes = {
  groups: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

AccountNewModal.defaultProps = {
  groups: [],
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(AccountNewModal));
