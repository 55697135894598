import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import {
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Paragraph,
} from "ks_storybook";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";
import { DeleteConfirmationModal } from "../Common";
import { PPArrayClocks } from "../../models";

@observer
class PPDayManagerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeletedModal: false,
    };

    this.grid = 4;
  }

  updatePPArrayClocks = (obj) => {
    const ppArrayClocksStore = this.props.store.ppArrayClocks;
    const attrs = {
      id: obj.id,
      user_id: obj.user_id,
      calendar_clocks_id: obj.calendar_clocks_id,
      clocks_id: obj.pp_clocks_id,
      start_hours: obj.start_hours,
      end_hours: obj.end_hours,
      order_view: obj.order_view,
      origin_type: 1,
      deleted: 0,
    };
    return new PPArrayClocks(attrs, ppArrayClocksStore);
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleShowDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: true });
  };

  handleCloseDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false });
    this.props.onReload && this.props.onReload();
    this.props.onClose && this.props.onClose();
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "500px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  getListStyle = () => ({
    background: "#e8e8e8",
    padding: this.grid,
  });

  getItemStyle = () => ({
    userSelect: "none",
    position: "static",
    padding: "12px 24px",
    margin: "0 0 8px 0",
    alignItems: "flex-start",
    alignContent: "flex-start",
    borderRadius: "3px",
    borderRadius: this.grid * 2,
    background: "#e8e8e8",
  });

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          <Button
            upper
            kind="outline"
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="is-pulled-right px-3"
            onClick={this.handleShowDeleteConfirmationModal}
          >
            <strong>{this.props.store.language.label129}</strong>
          </Button>
          <Button
            upper
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="is-pulled-right px-3"
            onClick={this.handleClose}
          >
            {this.props.store.language.label80}
          </Button>
        </LevelRight>
      </Level>
    );
  };

  getOptions = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  handleSave = (obj) => {
    const { toastManager, onReload } = this.props;
    obj.save().andThen((res, responseError) => {
      if (responseError) {
        toastManager.add(this.props.store.language.label471, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        toastManager.add(this.props.store.language.label470, {
          appearance: "success",
          autoDismiss: true,
        });
        onReload && onReload();
      }
    });
  };

  renderHeader = (blocks) => {
    var options = this.getOptions(this.props.ppClocks);
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {blocks.map((item, index) => (
            <div style={this.getItemStyle()}>
              {
                <Columns isMultiline>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                    <Text size={this.props.store.viewMobile ? "xl" : "lg"}>
                      {item.start_hours + " - " + item.end_hours}
                    </Text>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                    <Select
                      onChange={(value, sender) => {
                        var val = !value ? 0 : value.value;
                        var newBlocks = blocks;
                        newBlocks[index].pp_clocks_id = parseInt(val);
                        const updateBlock = this.updatePPArrayClocks(
                          newBlocks[index]
                        );
                        this.handleSave(updateBlock);
                      }}
                      isClearable
                      defaultValue={
                        options[
                          options
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(item.clocks_id)
                        ]
                      }
                      options={options}
                      className="is-fullwidth"
                      backgroundColor="#FFFFFF"
                      separatorColor="#FFFFFF"
                      iconColor="black"
                      textColor="black"
                      placeholderColor="black"
                      placeholder={this.props.store.language.label455}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      isMobile={!this.props.store.viewMobile}
                    />
                  </Column>
                </Columns>
              }
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { currentPPCalendarClocks } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "60%" : "90%"}
          height={this.props.store.viewMobile ? "90%" : "auto"}
          invert
        >
          <ModalHeader>
            <Text
              upper
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
              lineBreak
            >
              {this.props.store.language.label468 +
                "\n" +
                currentPPCalendarClocks.name}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="my-1"
              lineBreak
            >
              {this.props.store.language.label469}
            </Text>
            {this.renderHeader(currentPPCalendarClocks.blocks)}
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {this.state.showDeletedModal && (
          <DeleteConfirmationModal
            currentObj={currentPPCalendarClocks}
            onClose={this.handleCloseDeleteConfirmationModal}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={
              this.props.store.language.label445 +
              " " +
              currentPPCalendarClocks.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label446 +
              " " +
              currentPPCalendarClocks.name +
              "."
            }
            title={
              this.props.store.language.label447 +
              " " +
              currentPPCalendarClocks.name +
              "?"
            }
            subtitle={
              this.props.store.language.label448 +
              " " +
              currentPPCalendarClocks.name +
              "."
            }
          />
        )}
      </React.Fragment>
    );
  }
}
PPDayManagerModal.propTypes = {
  currentPPCalendarClocks: PropTypes.object,
  ppClocks: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
};

PPDayManagerModal.defaultProps = {
  currentPPCalendarClocks: null,
  ppClocks: null,
  onReload: null,
  onClose: null,
};

export default withToastManager(withStore(PPDayManagerModal));
