import { createGlobalStyle } from "styled-components";
import Roboto from "../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../assets/fonts/Roboto-Bold.ttf";
import SatoshiRegular from "../assets/fonts/Satoshi/Satoshi-Regular.otf";
import SatoshiMedium from "../assets/fonts/Satoshi/Satoshi-Medium.otf";
import SatoshiBold from "../assets/fonts/Satoshi/Satoshi-Bold.otf";
import SatoshiBlack from "../assets/fonts/Satoshi/Satoshi-Black.otf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Roboto;
    src: url(${Roboto}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoBold}) format("truetype");
    font-weight: bold;
    font-style: bold;
  }

  @font-face {
    font-family: Satoshi;
    font-weight: 400;
    font-style: normal;
    src: url(${SatoshiRegular});
    font-display: block;
  }
  
  @font-face {
    font-family: Satoshi;
    font-weight: 500;
    font-style: normal;
    src: url(${SatoshiMedium});
    font-display: block;
  }
  
  @font-face {
    font-family: Satoshi;
    font-weight: 700;
    font-style: normal;
    src: url(${SatoshiBold});
    font-display: block;
  }
  @font-face {
    font-family: Satoshi;
    font-weight: 900;
    font-style: normal;
    src: url(${SatoshiBlack});
    font-display: block;
  }
  

  html{
    // overflow:hidden;    
  }

  *::selection{
    background-color: #ff3f08;
    color: #fff;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: hsla(0,0%,100%,.4);
    border-radius: 20px;
    width: 10px;
  }
  
  ::-webkit-scrollbar-corner {
    color: #aaa;
    background: #121212;
  }
  
  ::-webkit-scrollbar-track {
    background: tranparent;
  }
  
  .ks--layout-content-column{
    overflow: auto !important;
  }

  .ks--mobile-layout{
    position: fixed;
    height: auto;
    min-height: 100vh;
    inset: 0;
    display: block !important;
  }

  .ks--mobile-layout > .columns {
    height: 100%;
    overflow: auto;
    padding-bottom: calc(72px + 64px);
  }

  .ks--mobile-layout .ks--mobile-layout-menu{
    position: fixed;
    left: 0;
    right: 0;
  }

  .button-wrapper::-webkit-scrollbar {
    height: 0px;
  }

  .button-wrapper {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    overflow: auto;
  }

  .star-icon{
    path {
      fill: none;
      stroke: white;
      stroke-width: 35px;
    }
  }

  .react-toast-notifications__container{
    bottom: 50px;
    width: 100%;
  }

  @media (max-width: 1025px) {
    .react-toast-notifications__container{
      bottom: 50px;
      width: 100%;
    }
  }

`;

export default GlobalStyle;

// const GlobalStyle = createGlobalStyle`
//   @font-face {
//     font-family: Roboto;
//     src: url(${Roboto}) format('truetype');
//     font-weight: normal;
//     font-style: normal;
//   }

//   @font-face {
//     font-family: Roboto;
//     src: url(${RobotoBold}) format("truetype");
//     font-weight: bold;
//     font-style: bold;
//   }

//   html{
//     overflow:hidden;
//   }

//   *::selection{
//     background-color: #ff3f08;
//     color: #fff;
//   }

//   .ks--layout .ks--layout-side-column{
//     width: auto !important;
//   }

//   .ks--layout .ks--container{
//     max-width: 100% !important;
//   }

//   .dropdown-buttons .ks--panel{
//     padding: 4px 0;
//     background-color: #FFFFFF;
//     border-color: #E7E8EA;
//   }
// `;

// export default GlobalStyle;
