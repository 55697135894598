import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Panel,
  Button,
  Toggle,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import {
  PlaylistPlanRowModal,
  PlaylistPlanModal,
  DeleteAllDayPlansConfirmationModal,
} from "../../../../Components/Plans";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../../../Components/Screen";

@observer
class PlaylistsPlannerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showDeletedAllModal: false,
      weekDay: 1,
      playlistsPlans: this.getPlaylistsPlans(this.props.account.id),
      hibernationPlans: this.getHibernationPlans(this.props.account.id),
      datesSelected: [],
    };
    this.plans = [];
    this.weekdays = this.props.store.language.label61.split(",");
  }

  getPPlans = (plans) => {
    return plans.toArray().map((plan) => ({
      id: plan.id,
      day: plan.day,
      start_hour: plan.start_hour,
      finish_hour: plan.finish_hour,
      categories_playlists: plan.categories_playlists,
      playlist_type: plan.playlist_type,
      playlist_planner_on: plan.playlist_planner_on,
      owner: plan.owner,
      type: 1,
    }));
  };

  getHPlans = (plans) => {
    return plans.toArray().map((plan) => ({
      id: plan.id,
      day: plan.day,
      start_hour: plan.start_hour,
      finish_hour: plan.finish_hour,
      categories_playlists: null,
      playlist_type: null,
      playlist_planner_on: plan.playlist_planner_on,
      owner: null,
      type: 2,
    }));
  };

  getPlaylistsPlans = (value) => {
    return this.props.store.playlistsPlans.getPlaylistPlansUser(
      value,
      this.props.store.lang
    );
  };

  getHibernationPlans = (value) => {
    return this.props.store.userHibernation.getHibernationPlansUser(value);
  };

  validHours = (startHour, finishHour, start_hour, finish_hour) => {
    if (start_hour <= finishHour && finish_hour >= startHour) return true;
    return false;
  };

  handleValid = (days, start_hour, finish_hour) => {
    let arr = this.plans.filter((plan) => days.includes(plan.day));
    let res = arr.filter((plan) =>
      this.validHours(
        start_hour,
        finish_hour,
        plan.start_hour,
        plan.finish_hour
      )
        ? plan
        : null
    );
    return res;
  };

  validLength = (day) => {
    let arr = this.plans.filter((plan) => plan.day === day);
    if (arr.length === 0) return true;
    return false;
  };

  handleUpdating = () => {
    this.setState({
      playlistsPlans: this.getPlaylistsPlans(this.props.account.id),
      hibernationPlans: this.getHibernationPlans(this.props.account.id),
    });
  };

  handleModalOpen = (sender) => {
    this.setState({ showModal: true, weekDay: sender.props.name });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleDeleteAllModalOpen = (sender) => {
    this.setState({ showDeletedAllModal: true, weekDay: sender.props.name });
  };

  handleDeleteAllModalClose = () => {
    this.setState({ showDeletedAllModal: false });
  };

  handleReload = () => {
    this.setState({
      playlistsPlans: this.getPlaylistsPlans(this.props.account.id),
      hibernationPlans: this.getHibernationPlans(this.props.account.id),
      showDeletedAllModal: false,
    });
  };

  handleSave = () => {
    this.setState({
      playlistsPlans: this.getPlaylistsPlans(this.props.account.id),
      hibernationPlans: this.getHibernationPlans(this.props.account.id),
      showModal: false,
    });
  };

  handleUserChange = (value) => {
    if (this.props.account.id) {
      this.setState({
        playlistsPlans: this.getPlaylistsPlans(this.props.account.id),
        hibernationPlans: this.getHibernationPlans(this.props.account.id),
      });
    } else {
      this.setState({ playlistsPlans: null });
    }
    this.setState({ datesSelected: [] });
  };

  handleChange = (sender, name, value) => {
    const actionPlaner = value ? 1 : 0;
    const messageSuccess =
      actionPlaner == 1
        ? this.props.store.language.label314
        : this.props.store.language.label315;
    const messageError =
      actionPlaner == 1
        ? this.props.store.language.label316
        : this.props.store.language.label317;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_playlist_planner_on_user",
        "?user_id=" +
          this.props.account.id +
          "&playlist_planner_on=" +
          actionPlaner
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderToogle = (plan) => {
    return this.props.store.viewMobile ? (
      <React.Fragment>
        <div
          className="is-pulled-left pb-2"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Toggle
            noIcons
            //onChange={this.handleChange}
            onChange={(...e) =>
              this.props.store.getPermission(29, 29, () => {
                this.handleChange(...e);
              })
            }
            checked={plan.playlist_planner_on ? true : false}
          />
          <div className="ml-2">
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              weight="medium"
            >
              {plan.playlist_planner_on
                ? this.props.store.language.label318
                : this.props.store.language.label319}
            </Text>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div
          className="is-pulled-left pb-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="white"
              weight="medium"
            >
              {plan.playlist_planner_on
                ? this.props.store.language.label318
                : this.props.store.language.label319}
            </Text>
          </div>
          <Toggle
            noIcons
            //onChange={this.handleChange}
            onChange={(...e) =>
              this.props.store.getPermission(29, 29, () => {
                this.handleChange(...e);
              })
            }
            checked={plan.playlist_planner_on ? true : false}
          />
        </div>
      </React.Fragment>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderFrame = (plans, weekDay) => {
    return (
      <React.Fragment>
        <Panel
          color="grayDark"
          padding={this.props.store.viewMobile ? "10px 25px" : "12px"}
          style={{
            borderRadius: 6,
            marginTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                name="arrow-down"
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "lg" : "md"}
                iconKasimu={
                  this.state.datesSelected.some((e) => e == weekDay)
                    ? "arrow-down"
                    : "arrow-up"
                }
                space
                onClick={() => {
                  let valid = this.state.datesSelected.indexOf(weekDay);

                  if (valid >= 0) {
                    this.setState({
                      datesSelected: this.state.datesSelected.filter(
                        (i) => i !== weekDay
                      ),
                    });
                  } else {
                    this.setState({
                      datesSelected: [...this.state.datesSelected, weekDay],
                    });
                  }
                }}
              />
              <div className="ml-2">
                <Text
                  weight="strong"
                  color="white"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                >
                  {this.getTitleForWeekDay(weekDay)}
                </Text>
              </div>
            </div>
            <div className="flex">
              <Button
                name={weekDay}
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="sum"
                space
                //onClick={this.handleModalOpen}
                onClick={(...e) =>
                  this.props.store.getPermission(29, 29, () => {
                    this.handleModalOpen(...e);
                  })
                }
              />
              <Button
                disabled={this.validLength(weekDay)}
                name={weekDay}
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu={
                  this.props.store.viewMobile ? "trash" : "trash-solid"
                }
                //onClick={this.handleDeleteAllModalOpen}
                onClick={(...e) =>
                  this.props.store.getPermission(29, 29, () => {
                    this.handleDeleteAllModalOpen(...e);
                  })
                }
                space
              />
            </div>
          </div>
        </Panel>
        {this.state.datesSelected.some((e) => e == weekDay) ? null : (
          <>
            {plans.length > 0
              ? plans
                  .filter((p) => p.day === weekDay)
                  .map((p) => (
                    <PlaylistPlanRowModal
                      plan={p}
                      onDeleted={this.handleReload}
                    />
                  ))
              : null}
          </>
        )}
      </React.Fragment>
    );
  };

  renderHeaderOk = () => {
    const { playlistsPlans, hibernationPlans } = this.state;
    const options = ["1", "2", "3", "4", "5", "6", "7"];
    const pplans = this.getPPlans(playlistsPlans);
    const hplans = this.getHPlans(hibernationPlans);
    const arrayPlans = pplans.concat(hplans);
    arrayPlans.sort(function (a, b) {
      return parseInt(a.start_hour) - parseInt(b.start_hour);
    });
    this.plans = arrayPlans;
    return (
      <React.Fragment>
        {this.plans.length > 0 && this.renderToogle(this.plans[0])}
        {options.map((i) => this.renderFrame(this.plans, i))}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { playlistsPlans } = this.state;
    if (!playlistsPlans) return this.renderEmptyContent();
    return !playlistsPlans.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getTitleForWeekDay = (weekDay) => {
    const titles = {
      1: this.weekdays[1],
      2: this.weekdays[2],
      3: this.weekdays[3],
      4: this.weekdays[4],
      5: this.weekdays[5],
      6: this.weekdays[6],
      7: this.weekdays[0],
    };
    return titles[weekDay];
  };

  renderScreen() {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label37}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  }

  render() {
    const { weekDay } = this.state;
    const { account } = this.props;

    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "800px" : "90%"}
            height={this.props.store.viewMobile ? "640px" : "auto"}
          >
            <ModalHeader>
              <div className="flex items-center" style={{ flexWrap: "wrap" }}>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="black"
                  className="mr-2"
                >
                  {this.props.store.language.label37}
                </Text>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="regular"
                  className="mr-2"
                >
                  {account.username}
                </Text>
              </div>
            </ModalHeader>
            <ModalContent>{this.renderHeader()}</ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {this.state.showModal && (
          <PlaylistPlanModal
            weekDay={weekDay}
            userId={account.id}
            onClose={this.handleModalClose}
            onSave={this.handleSave}
            onValid={this.handleValid}
          />
        )}
        {this.state.showDeletedAllModal && (
          <DeleteAllDayPlansConfirmationModal
            weekDay={weekDay}
            userId={account.id}
            messageSuccess={
              this.props.store.language.label665 +
              this.getTitleForWeekDay(weekDay) +
              "."
            }
            messageError={
              this.props.store.language.label666 +
              this.getTitleForWeekDay(weekDay) +
              "."
            }
            title={
              this.getTitleForWeekDay(weekDay) +
              ", " +
              this.props.store.language.label667
            }
            subtitle={
              this.props.store.language.label668 +
              this.getTitleForWeekDay(weekDay) +
              "."
            }
            onAccept={this.handleReload}
            onClose={this.handleDeleteAllModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

PlaylistsPlannerModal.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
};

PlaylistsPlannerModal.defaultProps = {
  account: null,
  onClose: null,
};

export default withStore(withToastManager(PlaylistsPlannerModal));
