import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelLeft, LevelRight, Columns, Column } from "bloomer";
import {
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Panel,
  Text,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, DeleteConfirmationModal, WarningModal } from "../Common";
import { PPVersionsAdsRow } from ".";
import { PPVersionsAds } from "../../models";
import moment from "moment";

@observer
class PPVersionsAdsModal extends Component {
  @observable ppVersionsAdsNew;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: null,
      versions: this.getPPVersionsAdsAll(),
      path: null,
      size: null,
      showDeletePPVersionsModal: false,
      showWarningModal: false,
      message: null,
      currentPPVersionsAds: null,
    };
    this.ad_ver = null;
    this.ppVersionsAdsNew = this.newPPVersionsAds();
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  handleStartPlayingClick(ad_ver, type) {
    this.ad_ver = ad_ver;
    this.props.store.ui.startPlayingAds(ad_ver, type, false);
  }

  handleTogglePlay() {
    if (!this.props.store.ui.isPlaying) {
      this.props.store.ui.play();
    } else {
      this.props.store.ui.pause();
    }
  }

  handleWarningModalOpen = (message) => {
    this.setState({ showWarningModal: true, message: message });
  };

  handleWarningModalClose = () => {
    this.setState({ showWarningModal: false, message: null });
  };

  handleShowDeleteConfirmationModal = (ppVersionsAds) => {
    this.setState({
      showDeletePPVersionsModal: true,
      currentPPVersionsAds: ppVersionsAds,
    });
  };

  handleCloseDeleteVersionsModal = () => {
    this.setState({
      showDeletePPVersionsModal: false,
      currentPPVersionsAds: null,
    });
  };

  handleAcceptDeleteVersionsModal = () => {
    this.setState({
      showDeletePPVersionsModal: false,
      currentPPVersionsAds: null,
      versions: this.getPPVersionsAdsAll(),
    });
    this.props.store.ui.destroy();
    this.props.onReload && this.props.onReload();
  };

  newPPVersionsAds = () => {
    const ppVersionsAdsStore = this.props.store.ppVersionsAds;
    const attrs = {
      advertisements_id: null,
      size: 0,
      path: null,
      origin_type: 1,
      deleted: 0,
    };
    return new PPVersionsAds(attrs, ppVersionsAdsStore);
  };

  getPPVersionsAdsAll = () => {
    return this.props.store.ppVersionsAds.fetchAll();
  };

  handleSave = () => {
    const { toastManager, currentPPAd } = this.props;
    const { path, size } = this.state;
    this.ppVersionsAdsNew.advertisements_id = currentPPAd.id;
    this.ppVersionsAdsNew.path = path;
    this.ppVersionsAdsNew.size = size;

    if (
      currentPPAd.versions_ads.length >=
      this.props.store.loggedInUser.ks_users_settings.maximum_versiones_ads
    ) {
      this.handleWarningModalOpen(
        this.props.store.language.label499 +
          "\n\n" +
          this.props.store.language.label504 +
          this.props.store.loggedInUser.ks_users_settings.maximum_versiones_ads
      );
    } else {
      if (
        this.ppVersionsAdsNew.size >=
        this.props.store.loggedInUser.ks_users_settings.maximum_ad_size
      ) {
        this.handleWarningModalOpen(
          this.props.store.language.label500 +
            "\n\n" +
            this.props.store.language.label505 +
            this.props.store.loggedInUser.ks_users_settings.maximum_ad_size +
            "MB"
        );
      } else {
        if (
          this.ppVersionsAdsNew.advertisements_id &&
          this.ppVersionsAdsNew.path &&
          this.ppVersionsAdsNew.size
        ) {
          this.setState({ isSaving: true }, () => {
            this.ppVersionsAdsNew
              .saveFormData()
              .andThen((res, responseError) => {
                if (responseError) {
                  toastManager.add(this.props.store.language.label428, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                } else {
                  toastManager.add(this.props.store.language.label429, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  this.setState({
                    isSaving: false,
                    messageError: null,
                    versions: this.getPPVersionsAdsAll(),
                    path: null,
                    size: null,
                  });
                  this.ad_ver = null;
                  this.props.store.ui.destroy();
                  this.props.onReload && this.props.onReload();
                  this.ppVersionsAdsNew = this.newPPVersionsAds();
                }
              });
          });
        } else {
          this.setState({ messageError: this.props.store.language.label430 });
        }
      }
    }
  };

  handleClose = () => {
    this.props.store.ui.destroy();
    this.props.onClose && this.props.onClose();
  };

  @computed
  get canSave() {
    if (!this.state.path) return false;
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft>
          {this.state.isSaving ? (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label340}
            </Button>
          ) : (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.props.store.ui.isError || !this.canSave}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              {this.props.store.language.label339}
            </Button>
          )}
        </LevelLeft>
        <LevelRight>
          <Button
            upper
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="is-pulled-right px-3"
            kind="outline"
            onClick={this.handleClose}
          >
            {this.props.store.language.label474}
          </Button>
        </LevelRight>
      </Level>
    );
  };

  getDivUploadStyle = () => ({
    minHeight: "300px",
    border: "2px dashed #ccc",
    borderRadius: "16px",
    position: "relative",
  });

  getInputStyle = () => ({
    position: "absolute",
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    opacity: 0,
  });

  getPStyle = () => ({
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "28px",
    whiteSpace: "pre-line",
    color: "#ccc",
    fontSize: "32px",
    lineHeight: "32px",
    fontWeight: "bold",
  });

  validExt = (ext, type) => {
    if (type !== "audio/mpeg") {
      this.setState({ messageError: this.props.store.language.label341 });
      return false;
    } else {
      this.setState({ messageError: null });
    }
    if (ext !== "mp3") {
      this.setState({ messageError: this.props.store.language.label341 });
      return false;
    } else {
      this.setState({ messageError: null });
      return true;
    }
  };

  handleFileChange = (e) => {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const user = this.props.store.loggedInUser;
      const fileName = file.name;
      const ext = fileName.split(".").pop().toLowerCase();
      if (this.validExt(ext, file.type)) {
        let momenStr = moment(new Date()).format("YYYYMMDDHHmmss");
        this.ad_ver = {
          id: 1,
          name: `${user.id}-AD-VER-${momenStr}.mp3`,
          ad_path: new File([file], `${user.id}-AD-VER-${momenStr}.mp3`, {
            type: file.type,
          }),
        };
        this.setState({
          path: this.ad_ver.ad_path,
          size: (file.size / 1048576).toFixed(2),
        });
      } else {
        this.setState({ path: null, size: null });
        e.target.value = null;
      }
      this.handleStartPlayingClick(this.ad_ver, 1);
    }
  };

  renderUploadAd = () => {
    const { size, path } = this.state;
    return (
      <React.Fragment>
        <div style={this.getDivUploadStyle()}>
          <input
            style={this.getInputStyle()}
            type="file"
            onChange={this.handleFileChange}
            accept=".mp3"
          />
          <p id="p" style={this.getPStyle()}>
            {path === null
              ? ("+\n" + this.props.store.language.label336).toUpperCase()
              : (
                  this.props.store.language.label53 +
                  "\n" +
                  path.name +
                  "\n\n" +
                  this.props.store.language.label337 +
                  "\n" +
                  size +
                  "MB"
                ).toUpperCase()}
            <span
              className="ml-1"
              style={{ fontSize: "18px", fontWeight: "normal" }}
            >
              {"\n"}
              {path === null ? this.props.store.language.label338 : null}
            </span>
          </p>
        </div>
      </React.Fragment>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "420px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderPPVersionsAdsRow = () => {
    const { versions } = this.state;
    const arrayPPVersionsAds = versions.toArray();
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {arrayPPVersionsAds.length !== 0
            ? arrayPPVersionsAds
                .filter((v) => v.advertisements_id == this.props.currentPPAd.id)
                .map((ppVersionsAds) => (
                  <PPVersionsAdsRow
                    ppVersionsAds={ppVersionsAds}
                    currentAdPath={this.ad_ver}
                    isPlaying={this.props.store.ui.isPlaying}
                    showPlayer={this.props.store.ui.showPlayer}
                    handleTogglePlay={this.handleTogglePlay}
                    handleStartPlayingClick={this.handleStartPlayingClick}
                    onDeleteClick={this.handleShowDeleteConfirmationModal}
                  />
                ))
            : this.renderEmptyContent()}
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { versions } = this.state;
    if (!versions) return this.renderEmptyContent();
    return !versions.isOk()
      ? this.renderLoader()
      : this.renderPPVersionsAdsRow();
  };

  render() {
    const {
      currentPPVersionsAds,
      showDeletePPVersionsModal,
      showWarningModal,
      message,
      path,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "50%" : "90%"}
          height={this.props.store.viewMobile ? "70%" : "auto"}
          invert
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              upper
              lineBreak
              lead
              weight="strong"
            >
              {this.props.store.language.label422 +
                "\n " +
                this.props.currentPPAd.name}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Columns style={{ marginTop: 0, marginBottom: 0 }}>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="is-row"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {this.props.store.ui.showPlayer && (
                    <div className="is-row">
                      {!this.props.store.ui.isError && (
                        <Button
                          icon={
                            this.props.store.ui.isPlaying ? "pause" : "play"
                          }
                          kind="link"
                          color="primary"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          onClick={() => this.handleTogglePlay()}
                          id="play-button"
                          space
                        />
                      )}
                      <div
                        className={`${
                          this.props.store.viewMobile ? "ml-4" : "ml-2"
                        } is-row`}
                        style={{ alignItems: "center" }}
                        maxLength={this.props.mobile ? 50 : 250}
                      >
                        {this.ad_ver && (
                          <Text
                            multiline
                            weight="normal"
                            lead={this.props.store.viewMobile}
                            size={this.props.store.viewMobile ? "sm" : "xl"}
                            color={"primary"}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {this.props.store.ui.isError
                                ? this.props.store.language.label654
                                : this.ad_ver.name}
                            </span>
                          </Text>
                        )}
                      </div>
                    </div>
                  )}
                  {!this.props.store.ui.isError &&
                    this.props.store.ui.showPlayer && (
                      <div>
                        <Text
                          size={this.props.store.viewMobile ? "md" : "sm"}
                          maxLength={this.props.mobile ? 50 : 250}
                        >
                          {this.props.store.ui.currentDuration == "NaN:NaN"
                            ? "00:00 / 00:00"
                            : this.props.store.ui.currentDuration &&
                              this.props.store.ui.currentProgressTime &&
                              this.props.store.ui.currentProgressTime +
                                " / " +
                                this.props.store.ui.currentDuration}
                        </Text>
                      </div>
                    )}
                </div>
              </Column>
            </Columns>
            <div
              id={"waveAds"}
              style={{ height: this.props.store.ui.showPlayer ? 100 : 0 }}
            />
            <Columns isMultiline>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                {this.renderUploadAd()}
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                {this.renderHeader()}
              </Column>
            </Columns>
            {this.state.messageError && (
              <Panel color="error" className="mt-2" invert>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  multiline
                >
                  {this.state.messageError}
                </Text>
              </Panel>
            )}
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showDeletePPVersionsModal && (
          <DeleteConfirmationModal
            currentObj={currentPPVersionsAds}
            onClose={this.handleCloseDeleteVersionsModal}
            onAccept={this.handleAcceptDeleteVersionsModal}
            messageSuccess={
              this.props.store.language.label423 +
              " " +
              currentPPVersionsAds.name +
              " " +
              this.props.store.language.label229
            }
            messageError={
              this.props.store.language.label424 +
              " " +
              currentPPVersionsAds.name
            }
            title={
              this.props.store.language.label425 +
              " " +
              currentPPVersionsAds.name +
              "?"
            }
            subtitle={
              this.props.store.language.label426 +
              " " +
              currentPPVersionsAds.name
            }
          />
        )}
        {showWarningModal && (
          <WarningModal
            title={this.props.store.language.label494}
            subtitle={message}
            onClose={this.handleWarningModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

PPVersionsAdsModal.propTypes = {
  currentPPAd: PropTypes.object,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
};

PPVersionsAdsModal.defaultProps = {
  currentPPAd: null,
  onClose: null,
  onReload: null,
};

export default withStore(withToastManager(PPVersionsAdsModal));
