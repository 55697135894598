import { Store } from "ks_storybook";
import { Singers } from "../models";

class SingersStore extends Store {
  model = Singers;

  fetchAllParams(per_page, search) {
    return this.search(
      {
        per_page: per_page,
        search: search,
      },
      "search_singers",
      true,
      "/singers/search_singers"
    );
  }

  fetchPage(per_page, page, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        search: search,
      },
      "search_singers",
      true,
      "/singers/search_singers"
    );
  }

}

export default SingersStore;