import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Modal,
  Text,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Button,
  Toggle,
  Field,
  CardEvents,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common";
import { PinModal } from "../../../../Components/Preference";
import { Select } from "../../../../Components/Select";
import { withToastManager } from "react-toast-notifications";
import { Screen } from "../../../../Components/Screen";

@observer
class UsersPreferencesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.account.id,
      showModal: false,
      showModalHibernation: false,
      preferences: this.getPreferences(this.props.account.id),
    };
  }

  getPreferences(value) {
    return this.props.store.preferences.getUserPreference(value);
  }

  handleSelectChangeVolume = (value, sender) => {
    const { toastManager } = this.props;
    let messageSuccess = this.props.store.language.label671;
    let messageError = this.props.store.language.label672;
    const val = !value ? 0 : value.value;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_volume_app_user",
        "?user_id=" + this.props.account.id + "&volume=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeMute = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label677
        : this.props.store.language.label678;
    const messageError =
      val == 1
        ? this.props.store.language.label679
        : this.props.store.language.label680;
    const { toastManager } = this.props;

    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_show_favorites_user",
        "?user_id=" + this.props.account.id + "&show_favorites=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeBlockInterface = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label269
        : this.props.store.language.label270;
    const messageError =
      val == 1
        ? this.props.store.language.label271
        : this.props.store.language.label272;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_block_interface_user",
        "?user_id=" + this.props.account.id + "&block_interface=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeLockStart = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label273
        : this.props.store.language.label274;
    const messageError =
      val == 1
        ? this.props.store.language.label275
        : this.props.store.language.label276;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_lock_start_user",
        "?user_id=" + this.props.account.id + "&lock_start=" + val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleChangeInappropriateLanguageOn = (sender, name, value) => {
    const val = value ? 1 : 0;
    const messageSuccess =
      val == 1
        ? this.props.store.language.label281
        : this.props.store.language.label282;
    const messageError =
      val == 1
        ? this.props.store.language.label283
        : this.props.store.language.label284;
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .putOne(
        "/users_preferences/put_inappropriate_language_on",
        "?user_id=" +
          this.props.account.id +
          "&inappropriate_language_on=" +
          val
      )
      .then(
        (res) => {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.handleReload();
        },
        (error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      )
      .catch((error) => {
        toastManager.add(messageError, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleReload = () => {
    this.setState({ preferences: this.getPreferences(this.state.userId) });
  };

  handleSave = () => {
    this.setState({
      preferences: this.getPreferences(this.state.userId),
      showModal: false,
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeaderOk = () => {
    const { preferences } = this.state;
    if (!preferences) return null;
    const arrayPreferences = preferences.toArray();
    return (
      <React.Fragment>
        {arrayPreferences.length === 0 && <EmptyContent />}
        {arrayPreferences.length > 0
          ? this.renderPreferences(arrayPreferences[0])
          : arrayPreferences.length > 0 &&
            this.renderPreferences(arrayPreferences[0])}
      </React.Fragment>
    );
  };

  renderPreferences = (preferences) => {
    return (
      <div
        className="flex flex-col"
        style={{ gap: this.props.store.viewMobile ? "10px" : "3px" }}
      >
        <CardEvents
          name={
            preferences.pin == 0
              ? this.props.store.language.label237
              : this.props.store.language.label238
          }
          description={
            preferences.pin == 0
              ? this.props.store.language.label239
              : this.props.store.language.label240 + ": " + preferences.pin
          }
          pdEvent={this.props.store.viewMobile ? "20px" : "0px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            kind="outline"
            color="basic"
            onClick={this.handleModalOpen}
          >
            <strong>
              {preferences.pin == 0
                ? this.props.store.language.label241
                : this.props.store.language.label242}
            </strong>
          </Button>
        </CardEvents>
        <CardEvents
          name={this.props.store.language.label243}
          description={
            preferences.pin == 0
              ? this.props.store.language.label244
              : this.props.store.language.label245
          }
          pdEvent={this.props.store.viewMobile ? "20px" : "0px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Toggle
            noIcons
            onChange={this.handleChangeBlockInterface}
            checked={preferences.block_interface ? true : false}
            disabled={preferences.pin == 0 ? true : false}
          />
        </CardEvents>
        <CardEvents
          name={this.props.store.language.label246}
          description={
            preferences.pin == 0
              ? this.props.store.language.label247
              : this.props.store.language.label248
          }
          pdEvent={this.props.store.viewMobile ? "20px" : "0px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Toggle
            noIcons
            onChange={this.handleChangeLockStart}
            checked={preferences.lock_start ? true : false}
            disabled={preferences.pin == 0 ? true : false}
          />
        </CardEvents>
        <CardEvents
          name={this.props.store.language.label253}
          description={this.props.store.language.label254}
          pdEvent={this.props.store.viewMobile ? "20px" : "0px"}
          isMobile={!this.props.store.viewMobile}
        >
          <Toggle
            noIcons
            onChange={this.handleChangeInappropriateLanguageOn}
            checked={preferences.inappropriate_language_on ? true : false}
          />
        </CardEvents>
        {this.props.store.getUserRadio() ? null : (
          <CardEvents
            name={
              preferences.show_favorites
                ? this.props.store.language.label674
                : this.props.store.language.label673
            }
            description={
              preferences.show_favorites
                ? this.props.store.language.label676
                : this.props.store.language.label675
            }
            pdEvent={this.props.store.viewMobile ? "20px" : "0px"}
            isMobile={!this.props.store.viewMobile}
          >
            <Toggle
              noIcons
              onChange={this.handleChangeMute}
              checked={preferences.show_favorites ? true : false} //mutear
            />
          </CardEvents>
        )}
        <CardEvents
          name={this.props.store.language.label669}
          description={this.props.store.language.label670}
          pdEvent={this.props.store.viewMobile ? "20px" : "0px"}
          isMobile={!this.props.store.viewMobile}
        >
          <div style={{ width: "120px" }}>
            {this.renderSelectVolumen(preferences.volume)}
          </div>
        </CardEvents>
      </div>
    );
  };

  getVolumen = () => {
    let items = [];
    for (let i = 100; i >= 0; i--) {
      items.push({
        value: i,
        label: i,
      });
    }
    return items;
  };

  renderSelectVolumen = (value) => {
    const options = this.getVolumen();
    return (
      <Field isSize={25}>
        <Select
          onChange={this.handleSelectChangeVolume}
          name="vol"
          required
          autocomplete="nope"
          options={options}
          defaultValue={
            options[
              options
                .map(function (e) {
                  return e.value;
                })
                .indexOf(value)
            ]
          }
          backgroundColor="transparent"
          textColor="#ffffff"
          isSearchable={false}
          className="is-fullwidth"
          space
          size={this.props.store.viewMobile ? "lg" : "md"}
          isMobile={!this.props.store.viewMobile}
        />
      </Field>
    );
  };

  renderHeader = () => {
    const { preferences } = this.state;
    if (!preferences) return this.renderEmptyContent();
    return !preferences.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  renderScreen = () => {
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {this.props.store.language.label23}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  };

  render() {
    const { account } = this.props;
    const { preferences, showModal } = this.state;
    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "640px" : "90%"}
            height={this.props.store.viewMobile ? "540px" : "auto"}
          >
            <ModalHeader>
              <div className="flex items-center" style={{ flexWrap: "wrap" }}>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  lead
                  weight="strong"
                  className="mr-2"
                >
                  {this.props.store.language.label23}
                </Text>

                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="regular"
                  className="mr-2"
                >
                  {account.username}
                </Text>
              </div>
            </ModalHeader>
            <ModalContent>{this.renderHeader()}</ModalContent>
            <ModalFooter />
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {showModal && (
          <PinModal
            preferences={preferences}
            userId={account.id}
            onClose={this.handleModalClose}
            onSave={this.handleSave}
          />
        )}
      </React.Fragment>
    );
  }
}

UsersPreferencesModal.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
};

UsersPreferencesModal.defaultProps = {
  account: null,
  onClose: null,
};

export default withStore(withToastManager(UsersPreferencesModal));
