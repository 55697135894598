import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Columns, Column } from "bloomer";
import { Text, Button, Paragraph } from "ks_storybook";

@observer
class CertificatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url_downloads: null,
    };
  }

  componentDidMount() {
    this.genURL();
    this.props.store.menuTitle = this.props.store.language.label722;
  }

  unload = (link, fileName) => {
    this.props.store.ui.fetchCertificate(link, fileName);
  };

  genURL = () => {
    this.setState({ url_downloads: this.props.store.currentCompany.url_certificate });
  };

  render() {
    const desktopStyle = {
      maxWidth: "730px",
    };

    const mobileStyle = {
      maxWidth: "350px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 auto",
    };
    return (
      <React.Fragment>
        <Columns>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label722}
              </Text>
            )}
            <div
              style={this.props.store.viewMobile ? desktopStyle : mobileStyle}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Button
                size={this.props.store.viewMobile ? "lg" : "md"}
                className={
                  this.props.store.viewMobile ? "px-3" : "is-fullwidth"
                }
                onClick={() => {
                  this.unload(
                    this.state.url_downloads,
                    this.props.store.language.label728
                  );
                }}
              >
                <strong>{this.props.store.language.label727}</strong>
              </Button>
            </div>
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(CertificatePage);
