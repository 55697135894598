import { Store } from "ks_storybook";
import { PPCompanies } from "../models";

class PPCompaniesStore extends Store {
  model = PPCompanies;

  fetchAll() {
    return this.search({}, "pp_companies_all", true, "/pp_companies");
  }
}

export default PPCompaniesStore;
