import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Paragraph,
  Text,
  Field,
  Button,
} from "ks_storybook";
import { Level, LevelRight, LevelLeft, Columns, Column } from "bloomer";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";

@observer
class AddSongsToPlaylistsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      selected: [],
    };
  }

  handleSave = () => {
    const { selected } = this.state;
    const { toastManager, songs } = this.props;
    const params = {
      song_id: songs.id,
      playlists_ids: selected.map((s) => s.value),
    };
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/users_playlist_songs/add_song_to_playlists", params)
        .then(
          (res) => {
            toastManager.add(this.props.store.language.label636, {
              appearance: "success",
              autoDismiss: true,
            });
            this.handleClose();
          },
          (error) => {
            toastManager.add(this.props.store.language.label637, {
              appearance: "error",
              autoDismiss: true,
            });
            this.handleClose();
          }
        )
        .catch((error) => {
          toastManager.add(this.props.store.language.label637, {
            appearance: "error",
            autoDismiss: true,
          });
          this.handleClose();
        });
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSelectedChange = (value) => {
    if (!value) {
      this.setState({ selected: [] });
    } else {
      this.setState({ selected: value });
    }
  };

  loadOptionsUserPlaylists = (inputValue, callback) => {
    if (inputValue == "") {
      return this.props.store.userPlaylists
        .getUserPlaylistsType(1)
        .andThen((res, error) => {
          if (res) {
            const options = res
              .toArray()
              .map((o) => ({ value: o.playlist_id, label: o.name }));
            this.setState({ my_playlists: options });
            callback(options);
          }
        });
    } else {
      const options = this.state.my_playlists
        .filter(
          (el) => el.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )
        .map((o) => ({ value: o.value, label: o.label }));
      callback(options);
    }
  };

  renderSelectPlaylists = (options) => {
    return (
      <Select
        isAsync
        isMulti
        isClearable
        cacheOptions
        defaultOptions
        required
        onChange={this.handleSelectedChange}
        name="playlist"
        autocomplete="nope"
        loadOptions={options}
        backgroundColor="black"
        placeholder={this.props.store.language.label574}
        size={this.props.store.viewMobile ? "lg" : "md"}
        isMobile={!this.props.store.viewMobile}
        className="my-1"
      />
    );
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label635}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.state.selected.length === 0}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label258}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { songs } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
        >
          <ModalHeader>
            <Text
              multiline
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {this.props.store.language.label258}
              {'\n"'}
              {songs.name}
              {'"\n'}
              {this.props.store.language.label632}
            </Text>
          </ModalHeader>
          <ModalContent>
            <div style={{ maxWidth: "710px" }}>
              <Text
                color="special"
                size={this.props.store.viewMobile ? "lg" : "md"}
                lineBreak
              >
                {this.props.store.language.label633}
              </Text>
            </div>
            <br />
            <Field
              label={this.props.store.language.label634}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              labelNote={this.props.store.language.label76}
              weight="medium"
            >
              {this.renderSelectPlaylists(this.loadOptionsUserPlaylists)}
            </Field>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
AddSongsToPlaylistsModal.propTypes = {
  songs: PropTypes.object,
  onClose: PropTypes.func,
};

AddSongsToPlaylistsModal.defaultProps = {
  songs: null,
  onClose: null,
};

export default withToastManager(withStore(AddSongsToPlaylistsModal));
