import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Loader,
  Text,
  CardEvents,
  Button,
  Panel,
} from "ks_storybook";
import { Level, LevelLeft, LevelRight } from "bloomer";
import { EmptyContent } from "../Common";
import { withToastManager } from "react-toast-notifications";

@observer
class PlaylistsManualEyeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: this.getSongsAll(this.props.currentUsersPlaylist.playlist_id),
    };
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  getSongsAll = (playlist_id) => {
    return this.props.store.usersPlaylistSongs.fetchSongsPlaylists(playlist_id);
  };

  handleClose = () => {
    this.props.store.ui.destroy();
    this.props.onClose && this.props.onClose();
  };

  handleStartPlayingClick(indice, song_path) {
    var that = this;
    that.props.store.ui.startPlaying(song_path, 2, indice);
  }

  handleTogglePlay() {
    if (this.props.store.ui.currentSongPath) {
      if (!this.props.store.ui.isPlaying) {
        this.props.store.ui.play();
      } else {
        this.props.store.ui.pause();
      }
    }
  }

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    minHeight: "255px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  renderHeaderOk = (songs) => {
    const { currentUsersPlaylist } = this.props;
    return (
      <React.Fragment>
        <div
          className="is-row"
          style={{ flexWrap: "wrap", alignItems: "center" }}
        >
          <Text
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            upper
            className="mr-1"
            weight="medium"
          >
            {this.props.store.language.label324 + ": "}
          </Text>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "xxl" : "xxl"}
            multiline
          >
            {currentUsersPlaylist.random
              ? this.props.store.language.label85
              : this.props.store.language.label86}
          </Text>
        </div>
        <div
          className="is-row"
          style={{ flexWrap: "wrap", alignItems: "center" }}
        >
          <Text
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            upper
            className="mr-1"
            weight="medium"
          >
            {this.props.store.language.label629 + ": "}
          </Text>
          <Text
            color="special"
            size={this.props.store.viewMobile ? "xxl" : "xl"}
            multiline
          >
            {songs.length +
              "/" +
              this.props.store.loggedInUser.ks_users_settings
                .limit_songs_playlist}
          </Text>
        </div>
        <br />
        <Text
          size={this.props.store.viewMobile ? "xxl" : "xl"}
          className="mr-1 mb-2"
          upper
          weight="medium"
        >
          {this.props.store.language.label357 + ": "}
        </Text>
        <div style={this.getContainerStyle()}>
          {songs.map((song, idx) => (
            <Panel
              color="gray"
              padding="0px"
              className={this.props.store.viewMobile && "mb-1"}
              style={{
                marginBottom: !this.props.store.viewMobile && "6px",
                borderRadius: !this.props.store.viewMobile && "5px",
              }}
            >
              <div
                className="is-row is-fullwidth"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: this.props.store.viewMobile
                    ? "20px 32px"
                    : "22px 25px",
                  height: 65,
                }}
              >
                {song.song_info ? <div
                  className="is-row is-fullwidth"
                  style={{ alignItems: "center" }}
                >
                  <Button
                    className={this.props.store.viewMobile ? "mr-3" : "mr-2"}
                    icon={
                      this.props.store.ui.currentSongPath &&
                      this.props.store.ui.currentSongPath ==
                        song.song_info.song_path &&
                      this.props.store.ui.isPlaying
                        ? "pause"
                        : "play"
                    }
                    kind="link"
                    color={
                      this.props.store.ui.currentSongPath &&
                      this.props.store.ui.currentSongPath ==
                        song.song_info.song_path
                        ? "primary"
                        : "special"
                    }
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    onClick={
                      this.props.store.ui.currentSongPath &&
                      this.props.store.ui.currentSongPath ==
                        song.song_info.song_path &&
                      this.props.store.ui.showPlayer
                        ? () => this.handleTogglePlay()
                        : () =>
                            this.handleStartPlayingClick(
                              idx,
                              song.song_info.song_path
                            )
                    }
                    id="play-button"
                    lead
                    space
                  />
                  <div
                    className="is-fullwidth"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {
                      <Text
                        multiline
                        weight="normal"
                        size={this.props.store.viewMobile ? "sm" : "xl"}
                        lead={this.props.store.viewMobile}
                        color={
                          this.props.store.ui.currentSongPath &&
                          this.props.store.ui.currentSongPath ==
                            song.song_info.song_path
                            ? "primary"
                            : "white"
                        }
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {song.song_info.users_songs_edit
                            ? song.song_info.users_songs_edit.song_name
                            : song.song_info.name}{" "}
                          -{" "}
                        </span>
                        <span style={{ fontWeight: "lighter" }}>
                          {song.song_info.singer.name}
                        </span>
                      </Text>
                    }
                  </div>
                </div>: <div
                    className="is-row is-fullwidth"
                    style={{ alignItems: "center" }}
                  >
                  <Text
                    multiline
                    weight="normal"
                    size={this.props.store.viewMobile ? "sm" : "xl"}
                    lead={this.props.store.viewMobile}
                    color={"white"}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.store.language.label2}
                    </span>
                  </Text>
                </div>}
              </div>
              <div
                style={{
                  padding: this.props.store.viewMobile
                    ? "0px 32px"
                    : "0px 25px",
                }}
              >
                {this.props.store.ui.currentSongPath &&
                  this.props.store.ui.currentSongPath ==
                    song.song_info.song_path &&
                  this.props.store.ui.showPlayer && (
                    <div
                      className={"mx-1 mt-1"}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "md" : "sm"}
                        maxLength={this.props.mobile ? 50 : 250}
                      >
                        {this.props.store.ui.currentDuration == "NaN:NaN"
                          ? "00:00 / 00:00"
                          : this.props.store.ui.currentDuration &&
                            this.props.store.ui.currentProgressTime &&
                            this.props.store.ui.currentProgressTime}
                      </Text>
                      <Text
                        size={this.props.store.viewMobile ? "md" : "sm"}
                        maxLength={this.props.mobile ? 50 : 250}
                      >
                        {this.props.store.ui.currentDuration == "NaN:NaN"
                          ? "00:00 / 00:00"
                          : this.props.store.ui.currentDuration &&
                            this.props.store.ui.currentProgressTime &&
                            this.props.store.ui.currentDuration}
                      </Text>
                    </div>
                  )}
                <div
                  id={`waveform` + idx}
                  style={{
                    height:
                      this.props.store.ui.currentSongPath &&
                      this.props.store.ui.currentSongPath ==
                        song.song_info.song_path &&
                      this.props.store.ui.showPlayer
                        ? 100
                        : 0,
                  }}
                />
              </div>
            </Panel>
          ))}
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { songs } = this.state;
    if (!songs) return this.renderEmptyContent();

    return !songs.isOk()
      ? this.renderLoader()
      : this.renderHeaderOk(songs.toArray());
  };

  render() {
    const { currentUsersPlaylist } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "340px" : "auto"}
        >
          <ModalHeader>
            <div className="mb-1">
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
                weight="black"
              >
                {currentUsersPlaylist.name}
              </Text>
              <Text
                lineBreak
                size={this.props.store.viewMobile ? "md" : "sm"}
                lead
              >
                {currentUsersPlaylist.description}
              </Text>
            </div>
          </ModalHeader>
          <ModalContent>{this.renderHeader()}</ModalContent>
          <ModalFooter />
        </Modal>
      </React.Fragment>
    );
  }
}
PlaylistsManualEyeModal.propTypes = {
  currentUsersPlaylist: PropTypes.object,
  onClose: PropTypes.func,
};

PlaylistsManualEyeModal.defaultProps = {
  currentUsersPlaylist: null,
  onClose: null,
};

export default withToastManager(withStore(PlaylistsManualEyeModal));
