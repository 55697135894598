import { Store } from "ks_storybook";
import { PlaylistPlan } from "../models";

class PlaylistsPlansStore extends Store {
  model = PlaylistPlan;

  getPlaylistPlansUser(value, lang) {
    return this.search(
      { per_page: 1000, lang: lang },
      "actions_playlist_plans",
      true,
      `/actions_playlist_plans/${value}/get_playlist_planner_user`
    );
  }
}

export default PlaylistsPlansStore;
