import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  MobileLayout,
  MobileLayoutHeader,
  MobileLayoutHeaderEnd,
  MobileLayoutHeaderStart,
  MobileLayoutHeaderContent,
  MobileLayoutContent,
  MobileLayoutMenu,
  MobileLayoutMenuLink,
  Button,
  Dropdown,
  DropdownSideDrawer,
  LayoutSide,
  Sidebar,
  Text,
} from "ks_storybook";
import { SignIn } from "../Session";
import {
  ControlPanelRouter,
  ControlPanelMenu,
  ControlPanelBottomMenu,
} from "../ControlPanel";
import LoggedUserDropdown from "./components/LoggedUserDropdown";

// remove this later, when all routes exist -----------
//const FakeContent = withRouter(props => <div>{props.location.pathname}</div>);

@observer
class MobileAppRouter extends Component {
  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.checkInformation();
    }, this.props.store.refreshTime);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  checkInformation = () => {
    if (!this.props.store.failsafeMode) {
      const supervisors = this.props.store.getSupervisors();
      let supervisor_id = 0;
      if (supervisors) supervisor_id = supervisors.id;
      this.props.store.kasimuAPIClient
        .search("/ks_connection_log_webpanel/check_information_webpanel", {
          supervisor_id: supervisor_id,
        })
        .then(
          (res) => {
            if (res.results.answer_code > 1) {
              this.handleSignOut();
            } else {
              this.props.store.loadPermissions();
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleSignOut = () => {
    this.props.store.signOut();
  };

  handleClick = () => {
    if (this.props.store.toggleMenu) {
      this.props.store.toggleMenu = false;
    } else {
      this.props.store.toggleMenu = true;
    }
  };

  handleSignOut = () => {
    this.props.store.signOut();
  };

  render() {
    const path = this.props.match.path;
    const toggle = this.props.store.toggleMenu;
    const root = "/controlpanel/";
    if (this.props.store.isLoggedIn || this.props.store.isLoggingIn) {
      const uiSettings = this.props.store.ui.settings;

      if (uiSettings.isEmpty()) return null;

      return (
        <React.Fragment>
          <div className="ks--layout">
            <MobileLayout showSide={this.props.store.ui.showMainSide}>
              <MobileLayoutHeader>
                <MobileLayoutHeaderStart>
                  <Dropdown
                    isOpen={toggle}
                    handleToggle={this.handleClick}
                    menu
                  >
                    <Button
                      kind="link"
                      onClick={this.handleClick}
                      color="basic"
                      iconKasimu="bars"
                      size="xl"
                      space
                    ></Button>
                    <DropdownSideDrawer
                      panel={false}
                      style={{
                        height: "100%",
                        position: "fixed",
                        top: "0",
                        left: "0",
                        maxWidth: "80%",
                      }}
                    >
                      <Sidebar mobile>
                        <Switch>
                          <Route
                            path={`${path}${root}`}
                            render={(props) => (
                              <ControlPanelMenu
                                {...props}
                                handleToogle={this.handleClick}
                              />
                            )}
                          />
                        </Switch>
                      </Sidebar>
                    </DropdownSideDrawer>
                  </Dropdown>
                </MobileLayoutHeaderStart>
                <MobileLayoutHeaderContent>
                  <Text size="xxl" weight="bold">
                    {this.props.store.menuTitle}
                  </Text>
                </MobileLayoutHeaderContent>
                <MobileLayoutHeaderEnd>
                  <LoggedUserDropdown mobile />
                </MobileLayoutHeaderEnd>
              </MobileLayoutHeader>
              <MobileLayoutContent>
                <Switch>
                  <Route
                    exact
                    path={`${path}${root}`}
                    component={ControlPanelRouter}
                  />
                  <ControlPanelRouter defaultComponent={ControlPanelRouter} />
                  <Redirect
                    to={`${path}${root}`}
                    component={ControlPanelRouter}
                  />
                </Switch>
                <MobileLayoutMenu>
                  <Switch>
                    <Route
                      path={`${path}${root}`}
                      render={(props) => (
                        <ControlPanelBottomMenu
                          {...props}
                          handleToogle={this.handleClick}
                        />
                      )}
                    />
                  </Switch>
                </MobileLayoutMenu>
              </MobileLayoutContent>
              {/* <MobileLayoutMenu>
                <Switch>
                  <Route
                    path={`${path}${root}`}
                    render={(props) => (
                      <ControlPanelBottomMenu
                        {...props}
                        handleToogle={this.handleClick}
                      />
                    )}
                  />
                </Switch> */}
              {/* <MobileLayoutMenuLink to="/app" iconKasimu="user-solid" />
                <MobileLayoutMenuLink
                  to="/app/controlpanel/users/accounts"
                  iconKasimu="playlist-solid"
                />

                <MobileLayoutMenuLink
                  to="/app/controlpanel/playlist_plans"
                  iconKasimu="ads-solid"
                />
                <MobileLayoutMenuLink
                  to="/app/controlpanel/users/preferences"
                  iconKasimu="download"
                /> */}
              {/* </MobileLayoutMenu> */}
            </MobileLayout>
          </div>
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Redirect to={`/session/signIn`} component={SignIn} />
      </Switch>
    );
  }
}

export default withStore(withRouter(MobileAppRouter));
