import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  RectSkeleton,
  Button,
  CardEvents,
} from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class SingersRow extends Component {
  state = {
    open: false,
  };

  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  getMessager(value) {
    const messager = {
      1: this.props.store.language.label776,
      2: this.props.store.language.label777,
    }
    return messager[value];
  };

  getAppearance(value) {
    const appearance = {
      1: 'success',
      2: 'error'
    }
    return appearance[value];
  };

  handleAdd = () => {
    const { toastManager } = this.props;
    const { singer, onReload } = this.props;
    const params = {
      user_id: this.props.user_id,
      singer_id: singer.id,
    };
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/users_blocked_singers/add_users_blocked_singers", params)
        .then(
          (res) => {
            toastManager.add(this.getMessager(res.results.answer_code), {
              appearance: this.getAppearance(res.results.answer_code),
              autoDismiss: true,
            });
            onReload && onReload();
          },
          (error) => {
            toastManager.add(this.props.store.language.label778, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          toastManager.add(this.props.store.language.label778, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  render() {
    const { singer } = this.props;
    if (singer.isEmpty()) {
      return this.renderSkelenton();
    }
    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <CardEvents
          name={singer.name}
          isMobile={!this.props.store.viewMobile}
          padding={!this.props.store.viewMobile && "22px 20px"}
          style={{
            height: 65,
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
          content={
            this.state.open && (
              <div
                style={{
                  display: "flex",
                  gap: 35,
                }}
              >
                <div>
                  <Button
                    kind="link"
                    color="basic"
                    size={"xl"}
                    iconKasimu="sum"
                    onClick={this.handleAdd}
                    space
                  />
                </div>
              </div>
            )
          }
        >
          {!this.props.store.viewMobile ? (
            <>
              {this.state.open ? (
                <Button
                  kind="link"
                  color="basic"
                  size={"lg"}
                  iconKasimu={"arrow-left"}
                  space
                  onClick={() => this.setState({ open: false })}
                />
              ) : (
                <Button
                  kind="link"
                  color="special"
                  size={"lg"}
                  iconKasimu={"arrow-right"}
                  space
                  onClick={() => this.setState({ open: true })}
                />
              )}
            </>
          ) : (
            <div style={{ display: "flex" }} className="ml-2">
              <Button
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                iconKasimu="sum"
                onClick={this.handleAdd}
                space
              />
            </div>
          )}
        </CardEvents>
      </div>
    );
  }
}

SingersRow.propTypes = {
  singer: PropTypes.object,
  onReload: PropTypes.func,
};

SingersRow.defaultProps = {
  singer: null,
  onReload: null,
};

export default withStore(withToastManager(SingersRow));
