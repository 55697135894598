import { Store } from "ks_storybook";
import { PPCalendarClocks } from "../models";

class PPCalendarClocksStore extends Store {
  model = PPCalendarClocks;

  fetchAll() {
    return this.search(
      {},
      "pp_calendar_clocks_all",
      true,
      "/pp_calendar_clocks"
    );
  }
}

export default PPCalendarClocksStore;
