import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import { Level, LevelLeft, LevelRight } from "bloomer";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";

@observer
class UserPersonalizationDeletedModal extends Component {
  constructor(props) {
    super(props);
  }

  handleAccept = () => {
    const {
      all,
      currentUserPerson,
      messageSuccess,
      messageError,
      toastManager,
    } = this.props;

    if (all) {
      this.props.store.kasimuAPIClient
        .delete("/users_personalization/deleted_personalization_all")
        .then(
          (res) => {
            toastManager.add(messageSuccess, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onAccept && this.props.onAccept();
          },
          (error) => {
            toastManager.add(messageError, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      this.props.store.kasimuAPIClient
        .delete(
          "/users_personalization/deleted_personalization_user",
          "?id=" +
            currentUserPerson.id +
            "&user_id=" +
            currentUserPerson.user_id
        )
        .then(
          (res) => {
            toastManager.add(messageSuccess, {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onAccept && this.props.onAccept();
          },
          (error) => {
            toastManager.add(messageError, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { title, subtitle } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "240px" : "auto"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {title}
            </Text>

            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              multiline
              lineBreak
              color="special"
              className="pt-2 pb-3"
            >
              {subtitle}
            </Text>
            <div>
              <Button
                className="px-3"
                onClick={this.handleAccept}
                size={this.props.store.viewMobile ? "lg" : "md"}
              >
                <strong>{this.props.store.language.label129}</strong>
              </Button>
              <Button
                className="px-3"
                onClick={this.handleClose}
                size={this.props.store.viewMobile ? "lg" : "md"}
              >
                <strong>{this.props.store.language.label427}</strong>
              </Button>
            </div>
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

UserPersonalizationDeletedModal.propTypes = {
  currentUserPerson: PropTypes.object,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  all: PropTypes.bool,
};

UserPersonalizationDeletedModal.defaultProps = {
  currentUserPerson: null,
  messageSuccess: null,
  messageError: null,
  title: null,
  subtitle: null,
  onAccept: null,
  onClose: null,
  all: false,
};

export default withStore(withToastManager(UserPersonalizationDeletedModal));
