import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Column, Columns } from "bloomer";
import { Select } from "../Select";
import {
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Panel,
  Toggle,
  Checkbox,
  Radio,
  RadioGroup,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class HibernationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      status: 0,
      mon: 1,
      tues: 1,
      wed: 1,
      thurs: 1,
      fri: 1,
      sat: 1,
      sun: 1,
      start_time: "00:00",
      finish_time: "00:00",
      messageError: null,
      disabledOn: this.props.disabledValue,
    };
    this.weekdays = this.props.store.language.label61.split(",");
    this.user_id = this.props.userId;
    this.Settings = this.props.settings.toArray();
    this._disabled = false;
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleChangeStatus = (sender, name, value) => {
    const val = value ? 1 : 0;
    if (this.Settings.length == 0) {
      this.setState({ status: val });
    } else {
      this.Settings[0].status = val;
    }
  };

  handleChangeSelect = (value, sender) => {
    const name = sender.name;
    const val = !value ? null : value.value;
    if (this.Settings.length == 0) {
      switch (name) {
        case "start_time":
          this.setState({ start_time: val });
          break;
        case "finish_time":
          this.setState({ finish_time: val });
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case "start_time":
          this.Settings[0].start_time = val;
          break;
        case "finish_time":
          this.Settings[0].finish_time = val;
          break;
        default:
          break;
      }
    }
  };

  handleSaveHibernation = () => {
    const { toastManager } = this.props;
    const {
      status,
      mon,
      tues,
      wed,
      thurs,
      fri,
      sat,
      sun,
      start_time,
      finish_time,
    } = this.state;
    if (this.Settings.length == 0) {
      const params = {
        user_id: this.user_id,
        status: status,
        mon: mon,
        tues: tues,
        wed: wed,
        thurs: thurs,
        fri: fri,
        sat: sat,
        sun: sun,
        start_time: start_time,
        finish_time: finish_time,
      };
      this.setState({ messageError: null });
      this.setState({ isSaving: true }, () => {
        this.props.store.kasimuAPIClient
          .post("/users_settings/save_user_setting", params)
          .then(
            (res) => {
              toastManager.add(this.props.store.language.label310, {
                appearance: "success",
                autoDismiss: true,
              });
              this.props.onSave && this.props.onSave();
            },
            (error) => {
              let str = error.toString();
              str = str.replace("Error: ", "");
              let mydatas = JSON.parse(str);
              toastManager.add(mydatas["error"], {
                appearance: "error",
                autoDismiss: true,
              });
              this.handleClose();
            }
          )
          .catch((error) => {
            toastManager.add(this.props.store.language.label311, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      });
    } else {
      const params = {
        user_id: this.user_id,
        status: this.Settings[0].status,
        mon: this.Settings[0].mon,
        tues: this.Settings[0].tues,
        wed: this.Settings[0].wed,
        thurs: this.Settings[0].thurs,
        fri: this.Settings[0].fri,
        sat: this.Settings[0].sat,
        sun: this.Settings[0].sun,
        start_time: this.Settings[0].start_time,
        finish_time: this.Settings[0].finish_time,
      };
      this.setState({ messageError: null });
      this.setState({ isSaving: true }, () => {
        this.props.store.kasimuAPIClient
          .putParams("/users_settings/put_settings_user", params)
          .then(
            (res) => {
              toastManager.add(this.props.store.language.label312, {
                appearance: "success",
                autoDismiss: true,
              });
              this.props.onSave && this.props.onSave();
            },
            (error) => {
              let str = error.toString();
              str = str.replace("Error: ", "");
              let mydatas = JSON.parse(str);
              toastManager.add(mydatas["error"], {
                appearance: "error",
                autoDismiss: true,
              });
              this.handleClose();
            }
          )
          .catch((error) => {
            toastManager.add(this.props.store.language.label313, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      });
    }
  };

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>
                {this.Settings.length == 0
                  ? this.props.store.language.label128
                  : this.props.store.language.label81}
              </strong>
            </Button>
          ) : (
            <Button
              upper
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3"
              onClick={this.handleSaveHibernation}
            >
              <strong>
                {this.Settings.length == 0
                  ? this.props.store.language.label127
                  : this.props.store.language.label80}
              </strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  valid = (num) => {
    switch (num) {
      case 0:
        return "00";
      case 1:
        return "01";
      case 2:
        return "02";
      case 3:
        return "03";
      case 4:
        return "04";
      case 5:
        return "05";
      case 6:
        return "06";
      case 7:
        return "07";
      case 8:
        return "08";
      case 9:
        return "09";
      default:
        return num;
    }
  };

  getHours = () => {
    let items = [];
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j <= 59; j++) {
        items.push({
          value: this.valid(i) + ":" + this.valid(j),
          label: this.valid(i) + ":" + this.valid(j),
        });
      }
    }
    return items;
  };

  renderSelectStartTime = () => {
    const options = this.getHours();
    if (this.Settings.length == 0) {
      return (
        <div className="is-row" style={{ alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>
            <Text size={this.props.store.viewMobile ? "lg" : "md"}>
              {this.props.store.language.label71}
            </Text>
          </div>
          <Select
            isClearable
            onChange={this.handleChangeSelect}
            name="start_time"
            required
            autocomplete="nope"
            options={options}
            defaultValue={options[0]}
            isSearchable={false}
            isClearable={false}
            className="is-fullwidth"
            iconColor="#141414"
            separatorColor="#FFFFFF"
            backgroundColor="#FFFFFF"
            textColor="#141414"
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </div>
      );
    } else {
      return (
        <div className="is-row" style={{ alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>
            <Text size={this.props.store.viewMobile ? "lg" : "md"}>
              {this.props.store.language.label71}
            </Text>
          </div>
          <Select
            isClearable
            onChange={this.handleChangeSelect}
            name="start_time"
            required
            autocomplete="nope"
            options={options}
            defaultValue={
              options[
                options
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(this.Settings[0].start_time)
              ]
            }
            isSearchable={false}
            isClearable={false}
            className="is-fullwidth"
            iconColor="#141414"
            separatorColor="#FFFFFF"
            backgroundColor="#FFFFFF"
            textColor="#141414"
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </div>
      );
    }
  };

  renderSelectFinishTime = () => {
    const options = this.getHours();
    if (this.Settings.length == 0) {
      return (
        <div className="is-row" style={{ alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>
            <Text size={this.props.store.viewMobile ? "lg" : "md"}>
              {this.props.store.language.label193}
            </Text>
          </div>
          <Select
            onChange={this.handleChangeSelect}
            name="finish_time"
            required
            autocomplete="nope"
            options={options}
            defaultValue={options[0]}
            isSearchable={false}
            isClearable={false}
            className="is-fullwidth"
            iconColor="#141414"
            separatorColor="#FFFFFF"
            backgroundColor="#FFFFFF"
            textColor="#141414"
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </div>
      );
    } else {
      return (
        <div className="is-row" style={{ alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>
            <Text size={this.props.store.viewMobile ? "lg" : "md"}>
              {this.props.store.language.label193}
            </Text>
          </div>
          <Select
            isClearable
            onChange={this.handleChangeSelect}
            name="finish_time"
            required
            autocomplete="nope"
            options={options}
            defaultValue={
              options[
                options
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(this.Settings[0].finish_time)
              ]
            }
            isSearchable={false}
            isClearable={false}
            className="is-fullwidth"
            iconColor="#141414"
            separatorColor="#FFFFFF"
            backgroundColor="#FFFFFF"
            textColor="#141414"
            size={this.props.store.viewMobile ? "lg" : "md"}
            isMobile={!this.props.store.viewMobile}
          />
        </div>
      );
    }
  };

  handleChangeRadio = (sender, value) => {
    if (value == 1) this.setState({ disabledOn: true });
    else this.setState({ disabledOn: false });
  };

  handleCheckboxChange = (sender, value) => {
    const name = sender.props.name;
    if (this.Settings.length == 0) {
      switch (name) {
        case "mon":
          this.setState({ mon: value ? 1 : 0 });
          break;
        case "tue":
          this.setState({ tues: value ? 1 : 0 });
          break;
        case "wed":
          this.setState({ wed: value ? 1 : 0 });
          break;
        case "thu":
          this.setState({ thurs: value ? 1 : 0 });
          break;
        case "fri":
          this.setState({ fri: value ? 1 : 0 });
          break;
        case "sat":
          this.setState({ sat: value ? 1 : 0 });
          break;
        case "sun":
          this.setState({ sun: value ? 1 : 0 });
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case "mon":
          this.Settings[0].mon = value ? 1 : 0;
          break;
        case "tue":
          this.Settings[0].tues = value ? 1 : 0;
          break;
        case "wed":
          this.Settings[0].wed = value ? 1 : 0;
          break;
        case "thu":
          this.Settings[0].thurs = value ? 1 : 0;
          break;
        case "fri":
          this.Settings[0].fri = value ? 1 : 0;
          break;
        case "sat":
          this.Settings[0].sat = value ? 1 : 0;
          break;
        case "sun":
          this.Settings[0].sun = value ? 1 : 0;
          break;
        default:
          break;
      }
    }
  };

  renderNew = () => {
    return (
      <Column>
        <Columns>
          <Column>
            <div className="is-row" style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>
                <Text
                  upper
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="strong"
                >
                  {this.props.store.language.label305}
                </Text>
              </div>
              <Toggle
                onChange={this.handleChangeStatus}
                checked={false}
                invert
                noIcons
              />
            </div>
          </Column>
        </Columns>
        <Columns>
          <Column isSize={12}>
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Text
                upper
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="strong"
              >
                {this.props.store.language.label306}
              </Text>
            </div>
          </Column>
        </Columns>
        <Columns>
          <Column>{this.renderSelectStartTime()}</Column>
          <Column>{this.renderSelectFinishTime()}</Column>
        </Columns>
        <Columns style={{ marginBottom: "0px" }}>
          <Column>
            <Text
              upper
              size={this.props.store.viewMobile ? "xl" : "lg"}
              weight="strong"
            >
              {this.props.store.language.label307}
            </Text>
          </Column>
        </Columns>
        <Columns
          isGapless={this.props.store.viewMobile ? true : false}
          style={{ marginBottom: "0px" }}
        >
          <Column isSize={6}>
            <RadioGroup
              group="sizes"
              size={this.props.store.viewMobile ? "md" : "sm"}
              onChange={this.handleChangeRadio}
              value={"1"}
              classNames="group"
              horizontal
            >
              <Radio
                size={this.props.store.viewMobile ? "md" : "sm"}
                value="1"
                class="radio"
                invert
                reversed
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                  {this.props.store.language.label308}
                </Text>
              </Radio>
              <div className="my-1" />
              <Radio
                size={this.props.store.viewMobile ? "md" : "sm"}
                value="2"
                class="radio"
                invert
                reversed
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                  {this.props.store.language.label309}
                </Text>
              </Radio>
            </RadioGroup>
          </Column>
        </Columns>
        <Columns
          isGapless
          style={{ marginLeft: "18px" }}
          className="my-1"
          isMultiline
        >
          <Column isSize={{ mobile: 12, tablet: 7, desktop: 7 }}>
            <Columns isMultiline isMobile>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="mon"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={true}
                  invert
                  reversed
                >
                  {this.weekdays[1]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="tue"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={true}
                  invert
                  reversed
                >
                  {this.weekdays[2]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="wed"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={true}
                  invert
                  reversed
                >
                  {this.weekdays[3]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="thu"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={true}
                  invert
                  reversed
                >
                  {this.weekdays[4]}
                </Checkbox>
              </Column>
            </Columns>
            <Columns isMultiline isMobile>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="fri"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={true}
                  invert
                  reversed
                >
                  {this.weekdays[5]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="sat"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={true}
                  invert
                  reversed
                >
                  {this.weekdays[6]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="sun"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={true}
                  invert
                  reversed
                >
                  {this.weekdays[0]}
                </Checkbox>
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Column>
    );
  };

  renderEdit = (s) => {
    return (
      <Column>
        <Columns>
          <Column>
            <div className="is-row" style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>
                <Text
                  upper
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="strong"
                >
                  {this.props.store.language.label305}
                </Text>
              </div>
              <Toggle
                onChange={this.handleChangeStatus}
                checked={s[0].status ? true : false}
                invert
                noIcons
              />
            </div>
          </Column>
        </Columns>
        <Columns>
          <Column isSize={12}>
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Text
                upper
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="strong"
              >
                {this.props.store.language.label306}
              </Text>
            </div>
          </Column>
        </Columns>
        <Columns>
          <Column>{this.renderSelectStartTime()}</Column>
          <Column>{this.renderSelectFinishTime()}</Column>
        </Columns>
        <Columns style={{ marginBottom: "0px" }}>
          <Column>
            <Text
              upper
              size={this.props.store.viewMobile ? "xl" : "lg"}
              weight="strong"
            >
              {this.props.store.language.label307}
            </Text>
          </Column>
        </Columns>
        <Columns
          isGapless={this.props.store.viewMobile ? true : false}
          style={{ marginBottom: "0px" }}
        >
          <Column isSize={6}>
            <RadioGroup
              group="sizes"
              size={this.props.store.viewMobile ? "md" : "sm"}
              onChange={this.handleChangeRadio}
              value={this.state.disabledOn ? "1" : "2"}
              classNames="group"
              horizontal
            >
              <Radio
                size={this.props.store.viewMobile ? "md" : "sm"}
                value="1"
                class="radio"
                invert
                reversed
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                  {this.props.store.language.label308}
                </Text>
              </Radio>
              <div className="my-1" />
              <Radio
                size={this.props.store.viewMobile ? "md" : "sm"}
                value="2"
                class="radio"
                invert
                reversed
              >
                <Text size={this.props.store.viewMobile ? "lg" : "md"}>
                  {this.props.store.language.label309}
                </Text>
              </Radio>
            </RadioGroup>
          </Column>
        </Columns>
        <Columns
          isGapless
          style={{ marginLeft: "18px" }}
          className="my-1"
          isMultiline
        >
          <Column isSize={{ mobile: 12, tablet: 7, desktop: 7 }}>
            <Columns isMultiline isMobile>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="mon"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={s[0].mon == 1 ? true : false}
                  invert
                  reversed
                >
                  {this.weekdays[1]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="tue"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={s[0].tues == 1 ? true : false}
                  invert
                  reversed
                >
                  {this.weekdays[2]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="wed"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={s[0].wed == 1 ? true : false}
                  invert
                  reversed
                >
                  {this.weekdays[3]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="thu"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={s[0].thurs == 1 ? true : false}
                  invert
                  reversed
                >
                  {this.weekdays[4]}
                </Checkbox>
              </Column>
            </Columns>
            <Columns isMultiline isMobile>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="fri"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={s[0].fri == 1 ? true : false}
                  invert
                  reversed
                >
                  {this.weekdays[5]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="sat"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={s[0].sat == 1 ? true : false}
                  invert
                  reversed
                >
                  {this.weekdays[6]}
                </Checkbox>
              </Column>
              <Column
                isSize={{ mobile: 6, tablet: 3, desktop: 3 }}
                style={{ paddingBottom: "0px" }}
              >
                <Checkbox
                  name="sun"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.disabledOn}
                  isChecked={s[0].sun == 1 ? true : false}
                  invert
                  reversed
                >
                  {this.weekdays[0]}
                </Checkbox>
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Column>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "700px" : "90%"}
        height={this.props.store.viewMobile ? "450px" : "auto"}
        invert
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
            upper
            lineBreak
          >
            {this.Settings.length == 0
              ? this.props.store.language.label303
              : this.props.store.language.label304}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns>
            {this.Settings.length == 0
              ? this.renderNew()
              : this.renderEdit(this.Settings)}
          </Columns>
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

HibernationModal.propTypes = {
  settings: PropTypes.object,
  disabledValue: PropTypes.bool,
  userId: PropTypes.number,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

HibernationModal.defaultProps = {
  settings: null,
  disabledValue: false,
  userId: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(HibernationModal));
