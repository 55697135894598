import React, { Component } from "react";
import PropTypes from "prop-types";
import { RectSkeleton, Button, CardEvents } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class PPGenericRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  handleDeleteModal = () => {
    const { currentGenObj, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(currentGenObj);
  };

  handleEditModal = () => {
    const { currentGenObj, onEditClick } = this.props;
    onEditClick && onEditClick(currentGenObj);
  };

  render() {
    const { currentGenObj } = this.props;
    if (!currentGenObj) return null;
    if (currentGenObj.isEmpty()) {
      return this.renderSkelenton();
    }

    return (
      <React.Fragment>
        <CardEvents name={currentGenObj.name} invert>
          <div style={{ padding: "0px 10px" }}>
            <Button
              kind="link"
              color="black"
              size="xl"
              iconKasimu="pen"
              onClick={this.handleEditModal}
              space
            />
            <Button
              kind="link"
              color="black"
              size="xl"
              iconKasimu="trash"
              onClick={this.handleDeleteModal}
              space
            />
          </div>
        </CardEvents>
      </React.Fragment>
    );
  }
}

PPGenericRow.propTypes = {
  currentGenObj: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onEyeClick: PropTypes.func,
};

PPGenericRow.defaultProps = {
  currentGenObj: null,
  onDeleteClick: null,
  onEditClick: null,
  onEyeClick: null,
};

export default withStore(withToastManager(PPGenericRow));
