import { Store } from "ks_storybook";
import { UserPlaylists } from "../models";

class UserPlaylistsStore extends Store {
  model = UserPlaylists;

  fetchAll() {
    return this.search(
      {},
      "users_playlists_all",
      true,
      "/users_playlist/get_all_playlists"
    );
  }

  getUserPlaylists(user_id, single_user) {
    return this.search(
      { user_id: user_id, single_user: single_user },
      "users_myplaylists_all",
      true,
      `/users_playlist/get_myplaylists_all`
    );
  }

  getUserPlaylistsType(value) {
    return this.search(
      { static: value },
      "users_myplaylists_type",
      true,
      `/users_playlist/get_myplaylists_type`
    );
  }
}

export default UserPlaylistsStore;
