import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Text, Button, TextInput, Loader, Columns, Column } from "ks_storybook";
import {
  EmptyContent,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  BlockedSingersRow,
  BlockedSingersNewModal,
} from "../../../Components/Singers";

@observer
class BlockedSingers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
      showDeleteModal: false,
      usersBlockedSingers: this.getUsersBlockedSingersAll(),
      currentSinger: null,
      search: "",
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label773;
  }

  getUsersBlockedSingersAll = () => {
    return this.props.store.userBlockedSingers.fetchAllParams(null, true);
  };

  handleShowNewModal = () => {
    this.setState({
      showNewModal: true,
    });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleSaveNewModal = () => {
    this.setState({
      usersBlockedSingers: this.getUsersBlockedSingersAll(),
      showNewModal: false,
    });
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
  };

  handleShowDeleteModal = (singer) => {
    this.setState({ showDeleteModal: true, currentSinger: singer });
  };

  handleCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, currentSinger: null });
  };

  handleDelete = () => {
    this.handleCloseDeleteModal();
    this.handleReload();
  };

  handleReload = () => {
    this.setState({ usersBlockedSingers: this.getUsersBlockedSingersAll() });
  };

  getSearch = (obj, search) => {
    var arr = [];
    if (obj.length != 0) {
      arr = obj.filter(
        (e) => e.singer_name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getPanel = () => {
    return (
      <Columns
        className={this.props.store.viewMobile && "my-2"}
        isMultiline
        style={{
          margin: !this.props.viewMobile && "6px 0px",
        }}
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile && "mr-2"}
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: !this.props.store.viewMobile && "6px",
            }}
          >
            <TextInput
              onChange={this.handleChangeSearch}
              name="search"
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              placeholder={this.props.store.language.label775}
            />
          </div>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            //onClick={this.handleShowNewModal}
            onClick={() =>
              this.props.store.getPermission(45, 45, () => {
                this.handleShowNewModal();
              })
            }
          >
            <strong>{this.props.store.language.label759}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.handleReload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderHeaderOk = () => {
    const { usersBlockedSingers, search } = this.state;
    const arraySingers = this.getSearch(usersBlockedSingers.toArray(), search);
    return (
      <React.Fragment>
        {arraySingers.length === 0 ? (
          <EmptyContent />
        ) : (
          arraySingers.map((singer) => (
            <BlockedSingersRow
              singer={singer}
              //onDeleteClick={this.handleShowDeleteModal}
              onDeleteClick={(...e) =>
                this.props.store.getPermission(45, 45, () => {
                  this.handleShowDeleteModal(...e);
                })
              }
            />
          ))
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { usersBlockedSingers } = this.state;
    if (!usersBlockedSingers) return this.renderEmptyContent();
    return !usersBlockedSingers.isOk()
      ? this.renderLoader()
      : this.renderHeaderOk();
  };

  render() {
    const { showDeleteModal, currentSinger, showNewModal } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                multiline
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="black"
                lineBreak
              >
                {this.props.store.language.label773}
              </Text>
            )}
            {/* <div
              style={{
                maxWidth: "730px",
              }}
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            >
              <Paragraph size="lg">
                {this.props.store.language.label523}
              </Paragraph>
            </div> */}
            {this.getPanel()}
            {this.renderHeader()}
          </Column>
          {showNewModal && (
            <BlockedSingersNewModal
              onClose={this.handleCloseNewModal}
              onReload={this.handleReload}
            />
          )}
          {showDeleteModal && (
            <DeleteConfirmationModal
              currentObj={currentSinger}
              onClose={this.handleCloseDeleteModal}
              onAccept={this.handleDelete}
              messageSuccess={
                this.props.store.language.label779 +
                " " +
                currentSinger.singer_name +
                " " +
                this.props.store.language.label780
              }
              messageError={
                this.props.store.language.label781 +
                " " +
                currentSinger.singer_name
              }
              title={
                this.props.store.language.label782 +
                " " +
                currentSinger.singer_name +
                "?"
              }
              subtitle={
                this.props.store.language.label783 +
                " " +
                currentSinger.singer_name +
                " " +
                this.props.store.language.label784
              }
            />
          )}
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(BlockedSingers);
