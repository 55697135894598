import { Store } from "ks_storybook";
import { PPClocks } from "../models";

class PPClocksStore extends Store {
  model = PPClocks;

  fetchAll() {
    return this.search(
      { per_page: 10000 },
      "pp_clocks_all",
      true,
      "/pp_clocks"
    );
  }
}

export default PPClocksStore;
